import React from "react";

const WarningBear = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="5" r="3" fill="currentColor" />
    <circle cx="4" cy="5" r="3" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11.995 7C12.5446 7 12.99 7.44772 12.99 8V12C12.99 12.5523 12.5446 13 11.995 13C11.4455 13 11 12.5523 11 12V8C11 7.44772 11.4455 7 11.995 7ZM11 16C11 15.4477 11.4455 15 11.995 15H12.005C12.5545 15 13 15.4477 13 16C13 16.5523 12.5545 17 12.005 17H11.995C11.4455 17 11 16.5523 11 16Z"
      fill="currentColor"
    />
  </svg>
);

export default WarningBear;
