export default {
  deleteSuccessToast: "Delete successfully",
  editBtnText: "Edit",
  timeSeries: "Time dimension",
  deleteBtnText: "Delete",
  deleteConfirmTitle: "Confirm to delete this time dimension? This operation cannot be undone",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  id: "ID",
  keyword: "Keywords",
  alias: "Alias",
  minTimeUnit: "Minimum time unit",
  timeUnit: "Default time unit",
  dataType: "Data type",
  operations: "Settings",
  searchInputPlaceholder: "Please enter the name of the time dimension you want to search",
  addBtnText: "Add time dimension",
  current: "Current time dimension: ",
  tableDeleteConfirmTitle: "Confirm to delete the time dimension",
  tableDeleteConfirmContent1: "The time dimension already has an applied model.",
  tableDeleteConfirmContent2: "After confirmation, it will be removed from all models that include this time dimension.",
  tableDeleteConfirmContent3: "This operation cannot be undone",
  pruneButtonText: "Clear Invalid Dimensions",
  pruneButtonTip: "Clear time dimensions not used by the model",
  pruneConfirmTitle: "Confirming the deletion of invalid time dimensions",
  pruneConfirmContent: "After confirmation, time dimensions that are not applied by the model will be cleared.",
};
