export default {
  basicSettings: "基础设置",
  model: "模型",
  downloadConfigurationFile: "下载配置文件",
  uploadConfigurationFile: "上传配置文件",
  uploadModalTitle: "上传配置",
  uploadModalTitleTip: "请选择需要更新的配置项",
  uploadModalOkText: "确定并上传",
  downloadModalTitle: "下载配置",
  downloadModalTitleTip: "请选择需要下载的配置项",
  downloadModalOkText: "确定并下载",
  basicSettingOption: "基础设置信息",
  basicSettingOptionTip: "基本信息、搜索设置及展示设置",
  modelSettingOption: "模型相关信息",
  modelSettingOptionTip: "模型、维度及指标",
  synonymSettingOption: "同义词",
  synonymSettingOptionTip: "全量维度值同义词",
  feedbackSettingOption: "语义校准规则",
  feedbackSettingOptionTip: "全量语义校准规则",
  ruleSettingOption: "场景鹿",
  ruleSettingOptionTip: "全量搜索问题解析规则和分词结果整理规则",
  pinboardLibSettingOption: "报告模版",
  pinboardLibSettingOptionTip: "本工作区内所有报告模板",
  downloadSettingOptionTipPrefix: "包含",
  uploadSettingOptionTipPrefix: "覆盖",
  downloadTaskErrorNoSectionsMessage: "未选择任何配置项",
  downloadTaskErrorExportFailMessage: "打包配置项失败",
  downloadTaskErrorUploadFailMessage: "上传文件失败",
  downloadProgressFailText: "配置文件打包失败",
  downloadProgressSuccessText: "配置文件打包完成",
  downloadProgressPendingText: "正在打包配置文件...",
  uploadProgressFailText: "配置文件上传失败",
  uploadProgressSuccessText: "配置文件上传完成",
  uploadProgressPendingText: "正在上传配置文件...",
  uploadSuccessToast: "配置上传成功，自动开启配置任务",
  uploadFailToast: "配置上传成功，自动开启配置任务失败",
  uploadConfigurationFileAlert: "上传后已选配置任务将自动开启，新配置将覆盖当前配置",
};
