import React from "react";
import { uuidV4 } from "@/utils/common";

const AutoInsights: React.FC = props => {
  // 给该 svg 元素指定唯一 id ，防止更改渐变颜色后影响使用该渐变的所有元素的颜色。
  const startId = `${uuidV4()}_1`;
  const endId = `${uuidV4()}_2`;

  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.31919 1.78762C5.09504 1.18188 4.23829 1.18188 4.01415 1.78762L3.70387 2.62612C3.6334 2.81656 3.48325 2.96672 3.29281 3.03719L2.45431 3.34746C1.84857 3.57161 1.84856 4.42835 2.45431 4.6525L3.29281 4.96277C3.48325 5.03324 3.6334 5.1834 3.70387 5.37384L4.01415 6.21234C4.23829 6.81808 5.09504 6.81808 5.31919 6.21234L5.62946 5.37384C5.69993 5.1834 5.85008 5.03324 6.04052 4.96277L6.87903 4.6525C7.48477 4.42835 7.48477 3.57161 6.87903 3.34746L6.04052 3.03719C5.85008 2.96672 5.69993 2.81656 5.62946 2.62612L5.31919 1.78762Z"
        fill={`url(#${startId})`}
      />
      <path
        d="M10.8901 5.51847C10.4699 4.38271 8.86347 4.38271 8.4432 5.51847L7.86143 7.09066C7.7293 7.44774 7.44776 7.72928 7.09068 7.86141L5.51849 8.44317C4.38273 8.86344 4.38273 10.4698 5.51849 10.8901L7.09068 11.4719C7.44776 11.604 7.7293 11.8856 7.86143 12.2426L8.44319 13.8148C8.86346 14.9506 10.4699 14.9506 10.8901 13.8148L11.4719 12.2426C11.604 11.8856 11.8856 11.604 12.2427 11.4719L13.8148 10.8901C14.9506 10.4698 14.9506 8.86344 13.8148 8.44317L12.2427 7.86141C11.8856 7.72928 11.604 7.44774 11.4719 7.09066L10.8901 5.51847Z"
        fill={`url(#${endId})`}
      />
      <defs>
        <linearGradient id={startId} x1="6" y1="13" x2="12.5" y2="6.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--color-start, #4759FD)" />
          <stop offset="1" stopColor="var(--color-end, #BD30FF)" />
        </linearGradient>
        <linearGradient id={endId} x1="6" y1="13" x2="12.5" y2="6.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--color-start, #4759FD)" />
          <stop offset="1" stopColor="var(--color-end, #BD30FF)" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AutoInsights;
