export default {
  notApplicable: "Not applicable",
  other: "Other",
  rawDataType: "Original data type",
  fieldName: "Field name:",
  dataColumns: "Data column:",
  notConfigured: "Not configured",
  expandRemaining: "Expand the remainder",
  items: "Items",
  currentErrorField: "Current error field:",
  errorType: ", Error type:",
  thePrevious: "Previous",
  next: "Next",
  noSet: "Not set",
};
