export default {
  selectProject: "选择工作区",
  imErrorTip: "你不在该链接所在工作区中，如有需要请联系管理员授权。",
  removeErrorTip: "你可能被管理员移出了工作区，或当前工作区已解散，请联系管理员",
  pleaseSelectYourProjectTip: "请选择工作区：",
  youHaveNotJoinedAnyProjectTip: "尚未加入任何工作区，请联系管理员",
  logout: "退出登录",
  backToProject: "返回所在工作区",
  searchNoContentTip: "找不到对应工作区，请更换关键字尝试",
  searchPlaceholder: "输入关键字查找",
};
