import ClickHouseStatus from "./ClickHouseStatus";
import common from "./common";
import MySQLStatus from "./MySQLStatus";
import OracleStatus from "./OracleStatus";
import PostgreSQLStatus from "./PostgreSQLStatus";

export default {
  MySQLStatus,
  PostgreSQLStatus,
  ClickHouseStatus,
  OracleStatus,
  common,
};
