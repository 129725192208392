export default {
  home: "首页",
  search: "数据探索",
  chat: "对话(测试)",
  pinboard: "报告",
  my: "我的",
  feedback: "反馈",
  help: "帮助",
  projectManage: "工作区配置",
  settings: "设置",
  logout: "退出登录",
  configuration: "后台配置",
  workspace: "工作区",
  theme: "外观",
  language: "语言",
  noWorkspaces: "暂无工作区",
  moreWorkspaces: "更多工作区",
  allWorkspaces: "全部工作区",
  switchWorkspace: "切换工作区",
  goToMessageCenter: "打开消息面板",
  openSettings: "打开设置",
  goToConfiguration: "前往后台配置",
  all: "全部",
  helpCenter: "帮助中心",
  messageCenter: "消息中心",
  confirm: "确认",
  cancel: "取消",
  logoutConfirmTitle: "确认退出登录？",
  systemSettings: "系统设置",
  databaseConnectFailTip: "数据连接尚未设置或连接失败，工作区搜索功能无法正常工作，请联系负责人设置数据源",
  databaseConnectFailOwnerTip: "数据连接尚未设置或连接失败，工作区搜索功能无法正常工作，请先前往",
  appearance: "外观",
  helpDoc: "帮助文档",
  licenceExpireTipAffix: "许可证已过期，本工作区数据将在 ",
  licenceExpireTipSuffix: " 彻底删除，如有需要请联系销售续签",
  expireFormat: "YYYY年MM月DD日",
  goWorkspacesText: "不同的工作区拥有不同的数据，你可以在此处查看或切换工作区。",
  okBtn: "好的",
  nextBtn: "立即查看",
  signOut: "退出登录",
  instructionalVideo: "教学视频",
};
