export default {
  skipExceptionLineText: "Skip exception line",
  onlySkipLineText: "Just skip the line",
  stopImportText: "Terminate import",
  stopImportExtraText: "This import failed, no tables will be created or anything will be updated",
  exceptionDataText: "Exception data set to null",
  exceptionDataExtraText: "Content that cannot be converted is set to null",
  replaceAllTableLabel: "Replace full table",
  addToFooterLabel: "Add to footer",
  updateFileTitle: "Update file",
  uploadFilesTitle: "Upload files",
  cancelBtn: "Cancel",
  nextStepBtn: "Next step",
  updateMethodLabel: "Update method",
  exceptionHandlingLabel: "Exception handling",
  selectFileText: "Select a document",
  selectFileExtraText1: "Support .csv .xls .xlsx files with a size not exceeding 50M",
  selectFileExtraText2: ". csv, only utf-8,gbk,ascii,utf-16 encoding is supported",
  confirmUploadBtn: "Confirm upload",
  updateDataOptionsTitle: "Update data table options",
  selectSheetLabel: "Please select the sheet page to be uploaded",
  delimiterSelectLabel: "Select delimiter",
  skipLineLabel: "Upload options",
  delimiterSelectHelp: "Only effective for. csv files",
};
