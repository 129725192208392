enum UserAgent {
  MOBILE = 1, // 手机浏览器端

  DESKTOP = 2, // PC 浏览器端
}

enum Login {
  DEFAULT = 1, // 用户账号密码登录

  CODE = 2, // 验证码登录

  CAS = 3, // Cas

  OAUTH2 = 4, // Oauth2.0

  SAML2 = 5, // Saml2.0

  DINGTALK = 6, // 钉钉

  LARK = 7, // 飞书

  WECOM = 8, // 企业微信

  YUFU = 9, // 千帆玉符
}

export default class LoginTOkenSource {
  static getUserAgent = (isMobile: boolean) => (isMobile ? UserAgent.MOBILE : UserAgent.DESKTOP); // 登录平台

  static LOGIN = Login; // 登录方式
}
