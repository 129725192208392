import React from "react";

const FeedbackEntryIcon = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="none" />
    <path
      opacity="0.6"
      d="M16.5 30C18.79 29.35 21.21 29.35 23.5 30"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6392 10.9226C16.975 9.90696 12.86 13.4209 12.86 17.9C12.86 20.466 14.5648 22.955 16.6879 24.2381C16.9126 24.3739 17.05 24.6174 17.05 24.88V26.04C17.05 26.1068 17.0481 26.1734 17.0466 26.2258C17.0465 26.2287 17.0465 26.2315 17.0464 26.2342C17.1017 26.2384 17.1699 26.2408 17.2532 26.24L17.26 26.24L22.77 26.24C22.871 26.24 22.9395 26.2316 22.9831 26.2229C22.9833 26.2077 22.9832 26.1902 22.9828 26.1695C22.9824 26.1495 22.9819 26.1326 22.9812 26.1082C22.9807 26.0866 22.98 26.0586 22.98 26.03V24.87C22.98 24.6073 23.1175 24.3637 23.3424 24.2279C26.3217 22.4293 28.2741 18.3633 26.3985 14.5941C25.4737 12.7355 23.6865 11.3759 21.6392 10.9226ZM17.0475 26.4219C17.0475 26.422 17.0474 26.4212 17.0471 26.4196L17.0475 26.4219ZM11.36 17.9C11.36 12.4795 16.3443 8.23361 21.9597 9.4572L21.962 9.45771C24.4342 10.0047 26.6064 11.6448 27.7415 13.9259C29.965 18.3943 27.7642 23.0704 24.48 25.2809V26.03C24.48 26.031 24.4802 26.034 24.4805 26.0388C24.4855 26.1099 24.5183 26.5781 24.3162 26.9655C24.0325 27.5096 23.462 27.74 22.77 27.74H17.2633C16.7026 27.7447 16.0542 27.6584 15.7227 27.1006C15.5752 26.8523 15.5514 26.5978 15.5458 26.44C15.5428 26.3571 15.5447 26.2762 15.5464 26.2132C15.5467 26.2009 15.547 26.1892 15.5474 26.178C15.5488 26.1258 15.55 26.0838 15.55 26.04V25.2909C13.2362 23.7363 11.36 20.9371 11.36 17.9Z"
      fill="currentColor"
    />
    <path
      d="M17.6139 17.2202C17.2776 17.2202 17.0034 16.942 17.0388 16.6076C17.0776 16.2411 17.1426 15.9453 17.2339 15.7202C17.3715 15.3716 17.5917 15.0642 17.8945 14.7982C18.1972 14.5229 18.5321 14.3211 18.8991 14.1927C19.2752 14.0642 19.6697 14 20.0826 14C20.9174 14 21.6101 14.2477 22.1605 14.7431C22.7202 15.2294 23 15.8807 23 16.6972C23 17.0642 22.9266 17.3991 22.7798 17.7018C22.6422 17.9954 22.3394 18.367 21.8715 18.8165C21.4128 19.2569 21.1055 19.5734 20.9495 19.7661C20.8027 19.9495 20.6881 20.1743 20.6055 20.4404C20.5702 20.564 20.5444 20.7089 20.5282 20.875C20.4956 21.2092 20.2332 21.4862 19.8973 21.4862C19.5664 21.4862 19.2932 21.2174 19.3206 20.8876C19.3408 20.6441 19.3746 20.4354 19.422 20.2615C19.5046 19.9495 19.6284 19.6789 19.7936 19.4495C19.9587 19.2202 20.2569 18.8853 20.6881 18.445C21.1193 17.9954 21.3945 17.6697 21.5137 17.4679C21.6422 17.2661 21.7064 16.9679 21.7064 16.5734C21.7064 16.1697 21.5642 15.8165 21.2798 15.5138C20.9954 15.2018 20.5826 15.0459 20.0413 15.0459C19.0016 15.0459 18.4048 15.5721 18.251 16.6246C18.2034 16.9509 17.9437 17.2202 17.6139 17.2202ZM20.6055 23.3372C20.6055 23.7058 20.3067 24.0046 19.9381 24.0046C19.5694 24.0046 19.2706 23.7058 19.2706 23.3372V23.2683C19.2706 22.8997 19.5694 22.6009 19.9381 22.6009C20.3067 22.6009 20.6055 22.8997 20.6055 23.2683V23.3372Z"
      fill="currentColor"
    />
  </svg>
);
export default FeedbackEntryIcon;
