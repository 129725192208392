export default {
  addToPinboard: "加入报告",
  searchPinboardInputPlaceholder: "输入关键词查找报告",
  chartNameLabel: "图表名称",
  chartNameInputPlaceholder: "请输入图表名称",
  selectPinboardLabel: "选择报告",
  cancel: "取消",
  confirm: "确定",
  pinboardNameInputPlaceholder: "请输入报告名称",
  allPinboardsLabel: "所有报告",
  createPinboardBtnText: "新建报告",
  noPinboardPrompt: "暂无报告，请",
  addSuccessToast: "加入成功",
  viewThisPinboardLinkText: "查看报告",
  noResultTip: "搜索无结果",
  samePinboardNameErrorTip: "与现有报告重名，请更换名称",
  allSpacePinboardNameErrorTip: "报告名称不能是纯空格",
  pinboardNameTooLongErrorTip: "最多可输入 50 个字",
  create: "新建",
  next: "下一步",
  cardApplicationTitle: "卡片应用",
  saveSuccessToast: "保存成功，前往",
  view: "查看",
  mySavedCardLinkText: "我的-我保存的卡片",
  updateCardSuccessToast: "更新卡片成功",
  saveCard: "保存卡片",
  saveAsNewCard: "另存为新卡",
  update: "更新",
  saveRepeatCardConfirm: "当前卡片已保存，是否更新卡片或另存为新卡片？",
  save: "保存",
  noPinboard: "暂无报告",
  changeKeywordTip: "请更换关键词，或",
  addDescPlaceholder: "添加简介",
  chartNameErrorTip: "输入有效图表名称",
};
