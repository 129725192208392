import React from "react";

const MessageAvatarApproval = props => {
  const { iconBackgroundColor, style } = props;
  return (
    <svg style={style} width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill={iconBackgroundColor} />
      <path
        d="M21.9798 18.6667H20.0198C19.1732 18.6667 18.6665 19.1734 18.6665 20.02V21.98C18.6665 22.8267 19.1732 23.3334 20.0198 23.3334H21.9798C22.8265 23.3334 23.3332 22.8267 23.3332 21.98V20.02C23.3332 19.1734 22.8265 18.6667 21.9798 18.6667ZM22.4198 20.3L20.8398 22.1467C20.7798 22.22 20.6865 22.2667 20.5932 22.2667C20.5865 22.2667 20.5865 22.2667 20.5798 22.2667C20.4865 22.2667 20.3998 22.2334 20.3332 22.1667L19.6065 21.4467C19.4732 21.3134 19.4732 21.0934 19.6065 20.96C19.7398 20.8267 19.9598 20.82 20.0932 20.96L20.5598 21.42L21.8932 19.86C22.0198 19.7134 22.2332 19.7 22.3798 19.82C22.5265 19.94 22.5398 20.16 22.4198 20.3Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        d="M18.6668 21.98V20.02C18.6668 19.1733 19.1735 18.6666 20.0202 18.6666H21.9802C22.2468 18.6666 22.4735 18.72 22.6668 18.8133C22.6668 18.8066 22.6668 18.8 22.6668 18.7866V13.2066C22.6668 10.78 21.2202 9.33331 18.7935 9.33331H13.2068C10.7802 9.33331 9.3335 10.78 9.3335 13.2066V18.7866C9.3335 21.22 10.7802 22.6666 13.2068 22.6666H18.7868C18.7935 22.6666 18.8002 22.6666 18.8135 22.6666C18.7202 22.4733 18.6668 22.2466 18.6668 21.98Z"
        fill="currentColor"
      />
      <path
        d="M12.6665 17.5C12.3932 17.5 12.1665 17.2733 12.1665 17V15C12.1665 14.7267 12.3932 14.5 12.6665 14.5C12.9398 14.5 13.1665 14.7267 13.1665 15V17C13.1665 17.2733 12.9398 17.5 12.6665 17.5Z"
        fill="currentColor"
      />
      <path
        d="M16 17.5C15.7267 17.5 15.5 17.2733 15.5 17V15C15.5 14.7267 15.7267 14.5 16 14.5C16.2733 14.5 16.5 14.7267 16.5 15V17C16.5 17.2733 16.2733 17.5 16 17.5Z"
        fill="currentColor"
      />
      <path
        d="M19.3335 17.5C19.0602 17.5 18.8335 17.2733 18.8335 17V15C18.8335 14.7267 19.0602 14.5 19.3335 14.5C19.6068 14.5 19.8335 14.7267 19.8335 15V17C19.8335 17.2733 19.6068 17.5 19.3335 17.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessageAvatarApproval;
