import { legacyLogicalPropertiesTransformer, StyleProvider } from "@ant-design/cssinjs";
import { ConfigProvider } from "antd";
import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import ReleaseUpdatedTip from "@/components/ReleaseUpdatedTip";
import { getHealthUrl } from "@/components/Urls";
import { AsyncTaskProvider } from "@/contexts/AsyncTaskContext";
import { GlobalContextProvider } from "@/contexts/GlobalContext";
import InitialContext, { InitialContextProvider } from "@/contexts/InitialContext";
import { TourContextProvider } from "@/contexts/TourContext";
import Loading from "./components/Loading";
import routerConfig from "./routerConfig";
import antdTheme from "./theme/antd";
import { antdLocaleConfig } from "./utils/locale";

const ManageLayout = React.lazy(() => import("@/layouts/ManageLayout"));
const BlankLayout = React.lazy(() => import("@/layouts/BlankLayout"));
const BaseLayout = React.lazy(() => import("@/layouts/BaseLayout"));
const AdminLayout = React.lazy(() => import("@/layouts/AdminLayout"));
const AuditLayout = React.lazy(() => import("@/layouts/AuditLayout"));
const Setup = React.lazy(() => import("@/pages/Setup"));
const RenewLicence = React.lazy(() => import("@/pages/RenewLicence"));

const layouts = {
  blank: BlankLayout,
  base: BaseLayout,
};

// 渲染激活页面
const renderSetup = clientID =>
  clientID === null && (
    <Switch>
      <Route component={Setup} />
    </Switch>
  );

// 渲染续签页面
const renderRenew = renew =>
  renew && (
    <Switch>
      <Route component={RenewLicence} />
    </Switch>
  );

const renderSwitch = () => (
  <Switch>
    <Route path="/admin">
      <AdminLayout />
    </Route>
    <Route path="/audit">
      <AuditLayout />
    </Route>
    <Route path="/manage">
      <AsyncTaskProvider>
        <ManageLayout />
      </AsyncTaskProvider>
    </Route>
    {routerConfig.map((item, idx) => (
      <Route key={idx} path={item.path} component={layouts[item.layout]} />
    ))}
  </Switch>
);

const BasePage = ({ clientID, renew }) => {
  const [tipShow, setTipShow] = useState(false);

  useEffect(() => {
    const release = window.release;
    if (release) {
      const timer = setInterval(() => {
        axios.get(getHealthUrl()).then(res => {
          // 版本不一致，显示版本不一致消息提醒，并清除定时请求
          if (res.data?.version !== release) {
            setTipShow(true);
            clearInterval(timer);
          }
        });
      }, 60000);
      return () => {
        clearInterval(timer);
      };
    }
  }, []);
  return (
    <>
      {clientID !== undefined && (renderSetup(clientID) || renderRenew(renew) || renderSwitch())}
      {tipShow && createPortal(<ReleaseUpdatedTip />, document.body)}
    </>
  );
};

const Router = () => {
  return (
    <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
      <ConfigProvider locale={antdLocaleConfig[window.locale]} theme={antdTheme}>
        <GlobalContextProvider>
          <TourContextProvider>
            <HashRouter>
              <Suspense fallback={<Loading style={{ height: "100vh" }} />}>
                <InitialContextProvider>
                  {/* 确保 conf 接口已经调用并获取返回值，之后再开始渲染页面 */}
                  {/* clientID 初始化值为 undefined, 新部署环境返回 null, 已激活情况返回对应客户的 aud */}
                  <InitialContext.Consumer>
                    {({ clientID, renew }) => <BasePage clientID={clientID} renew={renew} />}
                  </InitialContext.Consumer>
                </InitialContextProvider>
              </Suspense>
            </HashRouter>
          </TourContextProvider>
        </GlobalContextProvider>
      </ConfigProvider>
    </StyleProvider>
  );
};

export default Router;
