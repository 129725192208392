export default {
  basicSettings: "Basic Settings",
  model: "Model",
  downloadConfigurationFile: "Download configuration file",
  uploadConfigurationFile: "Upload configuration file",
  uploadModalTitle: "Upload configuration",
  uploadModalTitleTip: "Please select the configuration item you would like to update",
  uploadModalOkText: "Upload",
  downloadModalTitle: "Download configuration",
  downloadModalTitleTip: "Please select the configuration item you would like to download",
  downloadModalOkText: "Download",
  basicSettingOption: "Basic settings information",
  basicSettingOptionTip: "basic information, search settings, and display settings",
  modelSettingOption: "Model related information",
  modelSettingOptionTip: "model, dimensions, and metrics",
  synonymSettingOption: "Synonym",
  synonymSettingOptionTip: "synonyms for all dimension values",
  feedbackSettingOption: "Semantic Alignment Rules",
  feedbackSettingOptionTip: "full set of semantic alignment rules",
  ruleSettingOption: "Scene Giraffe",
  ruleSettingOptionTip: "full search problem parsing rules and tokenization result organization rules",
  pinboardLibSettingOption: "Pinboard Templates",
  pinboardLibSettingOptionTip: "all pinboard templates within this workspace",
  downloadSettingOptionTipPrefix: "Including ",
  uploadSettingOptionTipPrefix: "Overwrite ",
  downloadTaskErrorNoSectionsMessage: "No configuration sections selected",
  downloadTaskErrorExportFailMessage: "Failed to package configuration sections",
  downloadTaskErrorUploadFailMessage: "Failed to upload the file",
  downloadProgressFailText: "Failed to packaging the configuration file",
  downloadProgressSuccessText: "Configuration file packaging completed",
  downloadProgressPendingText: "Packaging configuration files in progress...",
  uploadProgressFailText: "Failed to upload the configuration file",
  uploadProgressSuccessText: "Configuration file uploading completed",
  uploadProgressPendingText: "Uploading configuration files in progress...",
  uploadSuccessToast: "The configuration upload was successful, and the configuration task will be automatically initiated",
  uploadFailToast: "The configuration upload was successful, but the automatic initiation of the configuration task has failed.",
  uploadConfigurationFileAlert:
    "After the upload, the selected configuration task will be automatically initiated, and the new configuration will overwrite the current configuration.",
};
