export default {
  formerDeleteWorkspaceConfirmText: "Are you sure you want to delete",
  latterDeleteWorkspaceConfirmText: "workspace?",
  deleteWorkspaceTip: "This action cannot be undone. Please proceed with caution!",
  delete: "Delete",
  cancel: "Cancel",
  deleteSuccessToast: "Successfully deleted",
  id: "ID",
  workspaceName: "Workspace Name",
  workspaceIntro: "Workspace Description",
  owner: "Owner",
  databaseType: "Database Type",
  autoAddMember: "Auto Join",
  createdAt: "Created At",
  updatedAt: "Modified",
  operation: "Settings",
  edit: "Edit",
  accountExcessAlert:
    "Your account has exceeded the limit (i.e., the number of accounts exceeds the quota), and various system functions cannot be used properly. Please reduce the number of accounts of each type (including inactive, active, disabled, and dormant accounts) to match the quota.",
  createWorkspaceBtnText: "Create Workspace",
  workspaceInfoInputPlaceholder: "Enter the workspace information you want to search",
  add: "Add",
  workspace: "Workspace",
};
