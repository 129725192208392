export default {
  detectedLastAbnormalExit: "Last abnormal exit detected",
  lastConfigurationExitNotSaved: "Not saved when last configuration exited,",
  restoreLastConfiguration: "Do you want to restore the contents of the last configuration?",
  resume: "Recovery",
  cancel: "Cancel",
  saveSuccessToast: "Save Successfully",
  modelDeletedSuccessfully: "Model deleted successfully",
  configurationSaved: "Configuration saved",
};
