export default {
  canSearch: "Searchable",
  canNotSearch: "Not searchable",
  modelNameConfigHelp:
    "When the model name is selected as searchable, the user can enter the model name in the search box to force the model to hit; this operation cannot be performed when selected as not searchable",
  modelNameEmptyErrorMessage: "Model name cannot be empty",
  modelName: "Model name",
  changeModelNameModelTitle: "Modify model name",
  modelNameInputPlaceholder: "It is recommended to name it after the model scene or fact table name",
  createModelModalTitle: "Create model",
  cancelBtnText: "Cancel",
  loadingBtnText: "Loading...",
  loadMoreBtnText: "Load more",
  noDataTableFoundTip: "The corresponding data table was not found, please try another keyword.",
  howToCreateBtnText: "How to create?",
  startToCreateBtnText: "Start creating",
  selectTableTip: "Please select 1 or more tables to start",
  syncDataTable: "Data database & table synchronizing",
  syncDatabase: "Database synchronization",
  synchronizingDatabaseTable: "Data database & table synchronization...",
  dbConnectFail: "Database could not be connected",
  dbConnectFailButtonText: "View Details",
  searchDataTablePlaceholder: "Find data table",
};
