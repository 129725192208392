import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.SyncKeywordTaskStatus.${id}`);

export default class SyncKeywordTaskStatus {
  static PENDING = 0;

  static STARTED = 1;

  static SUCCESS = 2;

  static FAILURE = 3;

  static getText = t => {
    if (t === SyncKeywordTaskStatus.PENDING) return getTextById("pending");
    if (t === SyncKeywordTaskStatus.STARTED) return getTextById("inProgress");
    if (t === SyncKeywordTaskStatus.SUCCESS) return getTextById("success");
    if (t === SyncKeywordTaskStatus.FAILURE) return getTextById("failure");
    return "";
  };
}
