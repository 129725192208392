export default {
  shortMessageSetting: "短信设置",
  region: "服务器区域",
  secretId: "登录名",
  secretKey: "密码",
  sdkAppId: "SDK AppID",
  pincodeTemplateId: "模板ID",
  selectServer: "服务选择",
  beiJiJiuZhang: "北极九章",
  tencentCloud: "腾讯云短信",
  buttonTextSet: "设置",
  unsetPlaceholder: "未设置",
  serverStatus: "服务状态",
  statusHelp: "开启后用户可以通过手机号验证登录和修改密码",
  testTitle: "测试收信",
  testPlaceholder: "请输入测试短信的收信号码",
  modalTitle: "短信服务参数设置",
  modalRegionTitle: "服务器区域（Region）",
  modalRegionPlaceholder: "请输入服务器区域，如 ap-beijing",
  modalSecretIdTitle: "登录名（Secret ID）",
  modalSecretIdPlaceholder: "请输入短信服务的登录名",
  modalSecretKeyTitle: "密码（Secret Key）",
  modalSecretKeyPlaceholder: "请输入短信服务的密码",
  modalSdkAppIdTitle: "短信 SDK AppID",
  modalSdkAppIdPlaceholder: "请输入SDK AppID",
  modalPincodeTemplateIdTitle: "短信模板ID（Pincode Template ID）",
  modalPincodeTemplateIdPlaceholder: "请输入短信模板ID",
  cancel: "取消",
  save: "保存",
  sendSuccessToast: "测试短信已发送，请及时查收",
  pwdChange: "修改",
  pwdUnChange: "不改动",
};
