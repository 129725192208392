export default {
  customTimePeriod: "Custom time period",
  customTimePeriodTip: "The custom time period is different from Natural Week and can be triggered by defined keywords",
  addBtnText: "Add time period",
  addSuccessToast: "Add successfully",
  saveSuccessToast: "Modified successfully",
  deleteConfirmFormerText: "Confirm to delete",
  deleteConfirmLatterText: "Yes?",
  deleteConfirmTip: "This operation is irrevocable, please operate with caution!",
  confirmBtnText: "Delete",
  deleteSuccessToast: "Delete successfully",
  cancelBtnText: "Cancel",
  keyword: "Keywords",
  timeUnit: "Time unit",
  offset: "Start time",
  offsetFormerText: "Forward offset",
  operations: "Settings",
  editBtnText: "Edit",
  deleteBtnText: "Remove",
};
