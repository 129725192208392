export default class BaseDataType {
  static FLOAT = "float";

  static INT = "int";

  static BOOL = "bool";

  static DATE = "date";

  static DATETIME = "datetime";

  static STR = "str";

  static UNSUPPORTED = "unsupported";

  static TIMESTAMP = "timestamp";

  // 原始数据类型，范围
  static choices = [this.FLOAT, this.INT, this.BOOL, this.DATE, this.DATETIME, this.STR, this.TIMESTAMP, this.UNSUPPORTED];

  // Excel & CSV 上传数据预览，可选数据类型
  static uploadPreviewDataType = [this.INT, this.FLOAT, this.STR, this.DATE, this.DATETIME];

  static getText = value => {
    if (value === this.STR) return "文本";
    if (value === this.INT) return "整数";
    if (value === this.BOOL) return "布尔值";
    if (value === this.FLOAT) return "浮点数";
    if (value === this.DATETIME) return "时间";
    if (value === this.DATE) return "日期";
    if (value === this.TIMESTAMP) return "时间戳";
    if (value === this.UNSUPPORTED) return "不支持的数据类型";
    return `${value}`;
  };
}
