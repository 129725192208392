import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.BatchUploadAccountError.${id}`);

export default class BatchUploadAccountError {
  static HAS_RUNNING_TAK = 1;

  static LACK_FILE_KEY = 2;

  static DOWNLOAD_FILE_FAILED = 3;

  static DECODE_FILE_FAILED = 4;

  static FILE_COLUMNS_ERROR = 5;

  static DEAL_FILE_FAILED = 6;

  static CREATE_MEMBER_GROUP_FAILED = 7;

  static getText = status => {
    if (status === this.HAS_RUNNING_TAK) return getText("error1");
    if (status === this.LACK_FILE_KEY) return getText("error2");
    if (status === this.DOWNLOAD_FILE_FAILED) return getText("error3");
    if (status === this.DECODE_FILE_FAILED) return getText("error4");
    if (status === this.FILE_COLUMNS_ERROR) return getText("error5");
    if (status === this.DEAL_FILE_FAILED) return getText("error6");
    if (status === this.CREATE_MEMBER_GROUP_FAILED) return getText("error7");
    return "";
  };
}
