import React from "react";

const WPSChart = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="18" width="22" height="1" rx="0.5" fill="black" fillOpacity="0.45" />
    <path
      d="M8 6.4C8 6.17909 8.17909 6 8.4 6H10.6C10.8209 6 11 6.17909 11 6.4V16.6C11 16.8209 10.8209 17 10.6 17H8.4C8.17909 17 8 16.8209 8 16.6V6.4Z"
      fill="currentColor"
    />
    <path
      d="M13 4.4C13 4.17909 13.1791 4 13.4 4H15.6C15.8209 4 16 4.17909 16 4.4V16.6C16 16.8209 15.8209 17 15.6 17H13.4C13.1791 17 13 16.8209 13 16.6V4.4Z"
      fill="currentColor"
    />
    <path
      d="M3 11.4C3 11.1791 3.17909 11 3.4 11H5.6C5.82091 11 6 11.1791 6 11.4V16.6C6 16.8209 5.82091 17 5.6 17H3.4C3.17909 17 3 16.8209 3 16.6V11.4Z"
      fill="currentColor"
    />
    <path
      d="M18 8.4C18 8.17909 18.1791 8 18.4 8H20.6C20.8209 8 21 8.17909 21 8.4V16.6C21 16.8209 20.8209 17 20.6 17H18.4C18.1791 17 18 16.8209 18 16.6V8.4Z"
      fill="currentColor"
    />
  </svg>
);

export default WPSChart;
