export default {
  selectPlaceholder: "请选择",
  pleaseFillInTheAlias: "请填写别名，逗号区隔",
  pleaseNameTheField: "请命名字段",
  copy: "复制",
  delete: "删除",
  edit: "编辑",
  optionalItems: "选填项",
  notApplicable: "不适用",
};
