export default {
  leaveModelConfigurationPage: "是否离开模型配置页面？",
  configurationWillNotBeSaved: "当前配置将不会被保存。",
  viewDraft: "查看草稿",
  saveDraft: "保存草稿",
  saveAsDraft: "存为草稿",
  cancel: "取消",
  confirm: "确认",
  confirmDeletionOfModel: "确定删除模型",
  deletedModelCannotBeRestored: "被删除的模型不可恢复。",
  contentWillReplaceOriginalModel: "发布后当前内容将替换原模型",
  operationCannotBeUndone: "的内容，该操作无法撤回，请谨慎操作。",
  draftDoesNotReferenceModel: "当前草稿没有引用模型，发布该草稿将创建新的模型",
  confirmPublishing: "确认发布当前内容？",
  deleteDraft: "删除草稿",
  publish: "发布",
  save: "保存",
  deleteModel: "删除模型",
};
