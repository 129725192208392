export default {
  cycleByYearFormerText: "每年",
  monthSuffix: "月",
  daySuffix: "日",
  hourSuffix: "时",
  cycleLatterText: "分同步关键词",
  monthSeparator: "月，",
  daySeparator: "日，",
  weekSeparator: "，",
  monthDaySeparator: "月的",
  dayHourSeparator: "日的",
  weekHourSeparator: "的",
  cycleByMonthFormerText: "每间隔",
  cycleByMonthMiddleText: "个月的",
  cycleByDayFormerText: "每日",
  syncKeywords: "同步关键词",
  instructions: "设置说明",
  operations: "操作",
  deleteBtnText: "删除",
  deleteConfirmTitle: "确认删除此同步任务？",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  deleteSuccessToast: "删除成功",
  lastTimeFormerText: "最后同步成功时间：",
  addBtnText: "添加同步任务",
};
