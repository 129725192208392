export default class ColorScheme {
  static presetColor = [
    {
      key: "1",
      name: "经典",
      color: [
        "#3f66cd",
        "#ffce1d",
        "#79aaac",
        "#f44029",
        "#5377c4",
        "#fb7821",
        "#a0d9a2",
        "#f85f25",
        "#6288bb",
        "#ffba1a",
        "#84baa7",
        "#fe8f1d",
        "#f0002d",
        "#6e99b3",
        "#ffa51a",
        "#91caa3",
      ],
    },
    {
      key: "2",
      name: "素雅",
      color: [
        "#003895",
        "#917b64",
        "#5e4b7b",
        "#733926",
        "#2f3c8f",
        "#8b6a52",
        "#685075",
        "#69291a",
        "#434189",
        "#855a42",
        "#524682",
        "#5f170f",
        "#71566e",
        "#795c67",
        "#540000",
        "#7d4933",
      ],
    },
    {
      key: "3",
      name: "活力",
      color: [
        "#00d9ff",
        "#de53a0",
        "#ffbe42",
        "#80f4b1",
        "#3ee1e7",
        "#ffa360",
        "#c939b4",
        "#96f8a6",
        "#ee6e8c",
        "#58e8d2",
        "#8300de",
        "#c9fe9d",
        "#f98877",
        "#6ceec0",
        "#ac1dc9",
        "#aefc9f",
      ],
    },
    {
      key: "4",
      name: "海洋",
      color: [
        "#005eb7",
        "#3a4997",
        "#4390bd",
        "#1d64ba",
        "#2b6abc",
        "#3e298d",
        "#4587bf",
        "#3571be",
        "#3e56a0",
        "#3d78bf",
        "#46008e",
        "#427fc0",
        "#4562ab",
        "#5b77c4",
        "#4f6db7",
        "#3a3b91",
      ],
    },
    {
      key: "5",
      name: "缤纷",
      color: [
        "#FFD60A",
        "#4361EE",
        "#E210B4",
        "#4CC9F0",
        "#7209B7",
        "#52AC0B",
        "#1F21FF",
        "#0AC4FF",
        "#6424EE",
        "#FFBE27",
        "#094DB2",
        "#0AB2C4",
        "#FF7733",
        "#29A678",
        "#FF4C6A",
        "#4086FF",
      ],
    },
  ];

  static getColorsArray = (colors = this.presetColor) => colors.map(item => item.color);

  static isPresetColor = key => key <= 5;

  static defaultMapBubbleColor = "#FFBE27";

  // 选择自定义且List不为空时不使用默认
  static isNotUseDefault = colorSchemes => colorSchemes && !colorSchemes.useDefault && colorSchemes?.themeList?.length !== 0;
}
