import React from "react";

const Question = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="currentColor" />
    <g clipPath="url(#clip0_4813_27125)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0006 18.7815C15.7456 18.7815 18.7815 15.7456 18.7815 12.0006C18.7815 8.25561 15.7456 5.21969 12.0006 5.21969C8.25561 5.21969 5.21969 8.25561 5.21969 12.0006C5.21969 15.7456 8.25561 18.7815 12.0006 18.7815ZM20.001 12.0006C20.001 16.4191 16.4191 20.001 12.0006 20.001C7.58212 20.001 4.00024 16.4191 4.00024 12.0006C4.00024 7.58212 7.58212 4.00023 12.0006 4.00023C16.4191 4.00023 20.001 7.58212 20.001 12.0006Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
      <path
        d="M11.2531 9.472C11.0935 9.5784 10.9803 9.72417 10.8992 9.8865C10.7646 10.1556 10.4375 10.2646 10.1684 10.1301C9.89934 9.99556 9.79028 9.66839 9.92481 9.39932C10.0706 9.10776 10.2978 8.79962 10.6489 8.5656C11.0033 8.3293 11.4514 8.19043 12.0006 8.19043C12.4783 8.19043 12.9948 8.33182 13.4055 8.63979C13.8306 8.95867 14.134 9.45402 14.134 10.0968C14.134 10.8473 13.7616 11.3457 13.3883 11.7057C13.2509 11.8383 13.099 11.9652 12.9679 12.0746C12.9305 12.1059 12.8945 12.136 12.8613 12.1641C12.7288 12.2765 12.6252 12.3707 12.5453 12.4607V13.1227C12.5453 13.4235 12.3015 13.6674 12.0006 13.6674C11.6998 13.6674 11.456 13.4235 11.456 13.1227V12.3856C11.456 12.2141 11.5045 12.0196 11.6355 11.8517C11.7945 11.6478 11.988 11.4763 12.1569 11.3331C12.2031 11.294 12.2472 11.2571 12.2897 11.2216C12.4143 11.1174 12.5253 11.0246 12.6321 10.9217C12.8989 10.6643 13.0446 10.4307 13.0446 10.0968C13.0446 9.83181 12.9319 9.64631 12.7519 9.51128C12.5573 9.36535 12.2795 9.27979 12.0006 9.27979C11.6421 9.27979 11.4093 9.36788 11.2531 9.472Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3818 14.9525C11.3818 14.6179 11.6531 14.3467 11.9877 14.3467L11.996 14.3467C12.3306 14.3467 12.6018 14.6179 12.6018 14.9525C12.6018 15.2872 12.3306 15.5584 11.996 15.5584L11.9877 15.5584C11.6531 15.5584 11.3818 15.2872 11.3818 14.9525Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </g>
    <defs>
      <clipPath id="clip0_4813_27125">
        <rect width="16" height="16" fill="white" transform="translate(20 20) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);

export default Question;
