export default {
  str: "文本",
  provinceStr: "文本（地理 - 省）",
  cityStr: "文本（地理 - 市）",
  countyStr: "文本（地理 - 区/县）",
  int: "整数",
  bool: "布尔值",
  decimal: "数字",
  float: "浮点数",
  datetime: "时间",
  date: "日期",
  YYYYInt: "整型（YYYY）",
  YYYYMMInt: "整型（YYYYMM）",
  YYYYMMDDInt: "整型（YYYYMMDD）",
  timestampInt: "整型（时间戳）",
  timestampFloat: "浮点数（时间戳）",
  choice: "映射",
  timestamp: "时间戳",
  YYYYMMDD_STR: "文本（YYYYMMDD）",
  YYYYMM_STR: "文本（YYYYMM）",
  YYYY_STR: "文本（YYYY）",
  YYYYMMDD_HYPHEN_STR: "文本（YYYY-MM-DD）",
  YYYYMM_HYPHEN_STR: "文本（YYYY-MM）",
  YYYYMMDD_SLASH_STR: "文本（YYYY/MM/DD）",
  YYYYMM_SLASH_STR: "文本（YYYY/MM）",
};
