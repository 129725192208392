export default {
  matchedForYou: "为你匹配了",
  changeInitialPasswordTitle: "修改初始密码",
  inputNewPasswordMessage: "请输入新密码",
  inputNewPasswordAgainMessage: "再次输入新密码",
  passwordNotMatchErrorText: "两次输入密码不一致",
  cancelBtnText: "取消",
  oKAndLoginBtnText: "确定并登录",
  noLimit: "无限制",
  today: "今天",
  yesterday: "昨天",
  dayBeforeYesterday: "前天",
  feedbackAndCalibration: "校准反馈",
  semanticCalibrationRule: "语义校准规则",
  makeParticipleMatchingMoreAccurate: "使分词匹配更准确",
  timeout: "请求超时",
  selectDataFailed: "查询数据失败",
  errorStatusTipPrefix: "请求发生",
  errorStatusTipSuffix: "错误",
  defaultErrorTip: "发生未知错误",
  determineDeleteDraft: "确定删除草稿?",
  confirm: "确认",
  draftDeletedSuccessfully: "草稿删除成功",
  saveConfirmTitle: "保存修改将影响所有已应用的模型",
  saveConfirmContent1: "该",
  saveConfirmContent2: "已有应用模型。",
  saveConfirmContent3: "确认后将会同步改动到所有已应用的模型。",
  saveConfirmContent4: "该操作无法撤销。",
  confirmBtnText: "确认",
  cancelConfirmTitle: "当前维度有未保存的修改，确认退出编辑状态？",
  cancelConfirmContent: "该维度表为已配置的维度表，需要单独保存修改，取消修改后，未保存的修改将被清空。",
  formItemErrMsg: "此项必填",
  nameErrMsg1: "命名错误，命名长度不可超过 120 字",
  nameErrMsg2: "命名错误，命名必须含有一个中文字符或英文字母",
  nameErrMsg3: "命名错误，命名中的特殊符号仅支持_ /\\-",
  leftBracket: "【",
  colon: "：",
  rightBracket: "】",
  none: "无",
  on: "启用",
  off: "禁用",
  limitPrefix: "取",
  limitSuffix: "个",
  versionUpdatedTip: "版本有更新，请点击升级体验",
  versionUpdatedTipRefresh: "刷新",
  synchronizeKeywordsTip: "工作区有关键词相关变动，请手动同步关键词",
  synchronizeKeywordsTipBtn: "同步",
  nextStepBtn: "下一步",
  uploadLicenseTitle: "上传许可证",
  uploadFilesBtn: "上传文件",
  activationCodeTitle: "激活码",
  activationCodePlaceholder: "请输入激活码",
  confirmLicenseTitle: "确认许可证",
  cancelBtn: "取消",
  confirmBtn: "确认",
  deleteConfirmBtn: "确认删除",
  deleteSuccessToast: "删除成功",
  userDeleted: "已注销",
  selectPlaceHolder: "请选择",
  lockPinboardWarningButtonText: "知道了",
  pinboardLockedWarningTitle: "当前报告正在被编辑",
  pinboardLockAuthor: "当前编辑用户：",
  pinboardLockTip: "请稍后再尝试进入编辑",
  lockOvertimeWarningTitle: "当前编辑页面时间过长已经失效",
  lockOvertimeWarningContent: "请刷新后重新进入编辑",
};
