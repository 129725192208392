import * as React from "react";
import { SVGProps } from "react";

const FoldedArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.91175 3.7763L1.5006 0.094701C1.31512 -0.105482 0.997986 0.0362959 0.997986 0.319397V7.68259C0.997986 7.96569 1.31512 8.10746 1.5006 7.90728L4.91175 4.22569C5.02673 4.10159 5.02673 3.90039 4.91175 3.7763Z"
      fill="currentColor"
    />
  </svg>
);

export default FoldedArrow;
