export default {
  upTo: "up to ",
  editChart: "Edit chart",
  numberViewMode: "Number view mode",
  numberViewModeLabel: "Number view settings",
  dataTab: "Data",
  showTab: "Format chart area",
  showMore: "More",
  seeMore: "See More",
  closeGauge: "Close gauge",
  openGauge: "Open gauge",
  switchChartType: "Switch chart type",
  shareChart: "Share",
  saveConfigBtnText: "Save",
  addToPinboardBtnText: "Add to dashboard",
  addToWPSBtnText: "Add do WPS Writer",
  addToOfficeBtnText: "Add to Microsoft Word",
  loadingText: "Loading...",
  addToWordLoadingText: "Word loading...",
  forecastRange: "Forecasting range",
  forecastPromptPrefix: "",
  aboveForecastPrompt: "Times higher than predicted value, with a total of",
  belowForecastPrompt: "Times lower than predicted value, with a total of",
  noSearchResultText: "Your search did not match any data, please try other search",
  ViewAIAnalysisBtn: "AI interprets errors",
  aiAnalysisTitle: "AI misinterpretation",
  saveToMySavedCardToast: "Saved to My Saved Cards",
  updateCardSuccessToast: "Card updated",
  shareCard: "Share card",
  saveCard: "Save card",
  sendEmail: "Send email",
  downloadCSV: "Export as CSV",
  downloadPNG: "Export as PNG",
  cardAlarm: "Card Reminder",
  updateBtnText: "Update",
  saveAsNewCardBtnText: "Save as new card",
  updateOrSaveCardConfirmText: "Card has already been saved, do you want to update card or save as new card?",
  shareLinkBtnText: "Share Link",
  sendToEmailBtnText: "Send to email",
  dataPointMarkLabel: "Data markers",
  dataPointNumTagLabel: "Data labels",
  dimensionTagLabel: "Dimension labels",
  indicatorFormatLabel: "Value format",
  proportionFormatLabel: "Percentage format",
  smoothCurveLabel: "Smooth line",
  polymerizeMethod: "Polymerize Method",
  tableThemeLabel: "Table Theme",
  rowHeightLabel: "Row height",
  totalLabel: "Total",
  subTotalLabel: "subtotals",
  subTotalDimensionsLabel: "Enable subtotal dimensions",
  tablePageFooter: "Table Page Footer",
  traditionLabel: "Traditional",
  classicsLabel: "Classics",
  stripesLabel: "Modern",
  showText: "Show",
  rowsText: "Rows",
  totalText: "Total",
  gaugeAxisTickLabel: "Axis tick",
  gaugeLabel: "Gauge",
  pointerLabel: "Pointer",
  titleFontSizeLabel: "Title font size",
  maxValueLabel: "Maximum value",
  minValueLabel: "Minimum value",
  maxValueTypeLabel: "Maximum value setting",
  minValueTypeLabel: "Minimum value setting",
  barLabel: "Column",
  lineLabel: "Line",
  displayTypeLabel: "Display type",
  referenceLineLabel: "Reference line",
  add: "Add",
  addReferenceLine: "Add reference line",
  editReferenceLine: "Edit reference line",
  colorLabel: "Color",
  mapColorLabel: "Map Color",
  bubbleColorLabel: "Bubble Color",
  countDimensionOrMeasureLabel: "Count Dimension/Measure",
  histogramCountType: "Calculation Method For Interval Width",
  histogramCountTypeAverage: "Isometric Segmentation",
  histogramCountTypeCustom: "Manual Segmentation",
  histogramIntervalCount: "Number of Intervals",
  histogramIntervalMax: "Maximum Value of Interval",
  histogramIntervalMin: "Minimum Value of Interval",
  histogramIntervalWidth: "Width of Intervals",
  histogramIntervals: "Range of Interval",
  addHistogramInterval: "Add Interval",
  dataOfInterest: "Data of interest",
  yoyGroupShow: "Combined Display Of YoY And MoM Comparisons",
  colorLabelInfo: "Low → Height",
  dataPointSettingLabel: "Data point settings",
  typeLabel: "Type",
  selectPlaceholder: "Please choose",
  showReferenceLineInfoLabel: "Show reference line options",
  nameLabel: "Label",
  cardFilterCollapseLabel: "Subtitle of the card",
  cardFilterOptionShow: "Show",
  cardFilterOptionCollapse: "Collapse",
  cardBorderLabel: "Card Border",
  cardBorderShow: "Show",
  cardBorderHidden: "Hidden",
  EditPanelInputPlaceholder: "Default label",
  fixedValueLabel: "Fixed value",
  fixedValueInfo: "Please enter integer between -1 trillion to 1 trillion",
  fixedValueInputPlaceholder: "2 decimal places",
  percentileLabel: "Percentile",
  percentileInputPlaceholder: "Please enter integer between 1 to 100",
  rankingLabel: "Sort",
  rankingInfo: "Please enter integer between 1 to number of dimensions",
  rankingInputPlaceholder: "Please enter",
  rankingAddonBefore: "Go to",
  confirm: "Confirm",
  colorSlice: "Color",
  sliceLabel: "Slice",
  colorText: "Color",
  scaleLabelFormat: "Format axis",
  editXAxis: "Edit X-axis ",
  editYAxis: "Edit Y-axis",
  editZAxis: "Edit Z-axis",
  xAxisGridLinesLabel: "X-axis gridlines",
  yAxisGridLinesLabel: "Y-axis gridlines",
  dimensionNameLabel: "Dimension label",
  indicatorLabel: "Value",
  proportionLabel: "Percentage",
  allValueLabels: "All data labels",
  valueLabels: "Data labels",
  dragDimensionAndMeasureHint: "Drag dimensions or measures here",
  size: "Size",
  xAxis: "X-axis",
  dragDimensionHint: "Drag dimensions here",
  yAxis: "Y-axis",
  zAxis: "Z-axis",
  timeline: "Timeline",
  dragMeasureHint: "Drag measures here",
  sizeMustShowOne: "One measure required for size",
  sizeMustShowOnlyOne: "Only one measure for size",
  sizeMustOne: "Size measure can not be empty",
  dimensionMustOne: "X-axis can not be empty",
  measureMustOne: "Y-axis can not be empty",
  measureMustShowOne: "At least 1 measure required for this chart",
  mapMustShowOneMeasure: "The chart requires at least one display metric (colored indicator or bubble indicator)",
  measureOnlyShowOne: "Only 1 measure required for this chart",
  cannotNull: "X-axis or y-axis can not be empty",
  oneAxisOneType: "Measure and dimension can not be added to the same axis",
  oneAxisOnlyOneShowMeasure: "Only 1 measure required for x-axis or y-axis",
  oneAxisMustShowOneMeasure: "At least 1 measure required for x-axis or y-axis",
  cannotShowNegatives: "Can not add measure which contain positive and negative to this chart",
  cannotHideUniqueMeasure: "Can not hide the only measure for this chart",
  edit: "Edit",
  cancel: "Cancel",
  colorDepth: "Color shades",
  displayDimension: "Show dimension",
  displayMeasure: "Show measure",
  colorMeasure: "Staining measure",
  bubbleMeasure: "Bubble Measure",
  otherMeasure: "Other measure(Show only in floating window)",
  row: "Rows",
  column: "Columns",
  dragRowAndColumnHint: "Drag rows or columns here",
  value: "Values",
  dragChangeValueHint: "Drag between row and column to change display",
  valueDisplay: "Value display",
  columnEmptyPrompt: "Column can not be empty",
  rowEmptyPrompt: "Row can not be empty",
  atLeastOneMeasure: "This chart need at least 1 measure",
  dragHint: "Hold and drag",
  showDataHint: "Show measure",
  hideDataHint: "Hide measure",
  editDataHint: "Edit measure",
  left: "Left",
  right: "Right",
  top: "Top",
  bottom: "Bottom",
  hidden: "Hide legends",
  default: "Default",
  custom: "Customize",
  otherMeasures: "Other measures",
  number: "Number",
  percentage: "Percentage",
  defaultStyle: "Default",
  scientificNotation: "Scientific",
  null: "None",
  tenThousand: "Ten Thousand",
  hundredMillion: "Hundred Million",
  trillion: "Trillion",
  locationLabel: "Location",
  categoryLabel: "Category",
  legendOptionsLabel: "Customize legends",
  numDisplayStyleLabel: "Number format",
  decimalDigitsLabel: "Decimal places",
  inputDecimalDigitsPrompt: "Enter integer between 0 to 10",
  splitNumberLabel: "Number of split segments",
  splitNumberTip:
    "Two decimal places will be kept for tick values. For example, tick values will be 0, 33.33, 66.67, and 100, given that the number of split segments is 3 with minimum equal to 0 and maximum equal to 100.",
  FillIntPrompt: "Please enter an integer",
  choiceMeasuresPrompt: "Select a measure",
  negativeDisplayFormatLabel: "Negative format",
  thousandthSeparatorLabel: "1000 Separator (,)",
  thousandthSeparatorInfo: "100000 will show as 100,000",
  monetaryUnitLabel: "Currency",
  typeNotSupportToast: "Sorry, can not switch to this chart type with current data",
  wordCloudNotSupportToast: "Sorry, this type is currently not supported on mobile devices",
  sankeyLimitTip:
    "You search contain same value for different dimensions, can not switch to this chart type, please try other chart types or ",
  sankeyLimitSubTip: "Table",
  shareContentTitle: "Share",
  rollup: "Roll up",
  drillDown: "Drill down",
  noFieldTip: "Keyword not found",
  copyText: "Copy to clipboard",
  copySuccessToast: "Copied successfully",
  copyFailToast: "Copy failed, please try again later",
  backToPreviousLevel: "Return to previous level",
  drillInputPlaceholder: "Find dimension",
  noDrillItemTip: "No available drill dimension",
  timeScale: "Time Scale",
  actionFilter: "Action Filter",
  disableActionFilterTip: "There are no relevant filters applied in this report for this card.",
  disableActionFilterTip1: "The chart does not currently support filtering.",
  selectOneTip: "Please select a single cell for drilling",
  cellNoDataTip: "The current cell has no content",
  chartOverMaxDataPointTip:
    "Your search result has over max data points of chart, try add more filters to arrow down your search result, you can try smaller time range or less dimensions.",
  formerOverMaxDataPointTip: "Your search result has more than ",
  pivotTableOverMaxColCountTip:
    "The chart contains too many data columns (over 200 columns). Please consider moving some dimensions from columns to rows or further filter to reduce the data points.",
  latterOverMaxDataPointTip:
    " data points, try add more filters to arrow down your search result, you can try smaller time range or less dimensions or ",
  legendOverMaxTip: "This chart contains too many legends (more than 100), please try to further filter to reduce data points.",
  legendOverMaxHelpTip: "Such as reducing the number of measures, reducing the number of dimension values, or",
  switchTable: " switch to table ",
  view: "View",
  changeColor: "Change colors",
  formerTotalLegendNum: "",
  latterTotalLegendNum: "Legends",
  axisSortMenusDefault: "Default",
  axisSortMenusUp: "Sort A to Z",
  axisSortMenusDown: "Sort Z to A",
  axisSortMenusName: "Sort",
  axisPositionMenusTop: "Top",
  axisPositionMenusBottom: "Bottom",
  axisPositionMenusName: "Location",
  axisPositionMenusLeft: "Left",
  axisPositionMenusRight: "Right",
  axisGroupMenusName: "Group",
  userThresholdValueTip: "Search result exceeded limit, data will show in table, please contact workspace admin",
  ownerThresholdValueTip: "Search result exceeded limit, data will show in table",
  thresholdValueSetLink: "Chart threshold",
  increase: "Increase",
  decrease: "Decrease",
  captionCurrentKey: "Current time period",
  change: "Change",
  changeAccountsFor: "Share of change",
  tagDateRangeError: "Time range parameter error",
  unsupportedDateTypeError: "Unsupported date type",
  switch: "Switch",
  replaceCardBtnText: "Replace Card",
  forecastBtnText: "Forecast",
  cardExceedContext:
    "The chart exceeds the maximum display limit of the trend chart horizontal axis, please try to further filter to reduce data points.",
  cardExceedTip: "Such as changing the time unit to weeks, or narrowing the time range.",
  trendLine: "Trend Line",
  linear: "Linear",
  exponential: "Exponential",
  logarithmic: "Logarithmic",
  polynomial: "Polynomial",
  heatMapModel: "Heat map model",
  heatMapModelTip: "Enabled measures for heat map view",
  barViewModel: "Bar View model",
  barViewModelTip: "Enabled measures for data bar view",
  sort: "Sort",
  position: "Position",
  total: "Total",
  remove: "Remove",
  funnelPercentLabel: "Percentage",
  funnelCalcTypeLabel: "Percentage Calculation Method",
  axisFillRangePlaceholder: "-10 billion and 10 billion",
  axisFillRangeLabel: "Display range",
  axisMaxValueLabel: "Maximum value",
  axisMinValueLabel: "Minimum value",
  axisIntervalTypeLabel: "Tick interval",
  axisIntervalValueLabel: "Tick interval value",
  axisLogarithmicLabel: "Logarithmic scale",
  axisReverseLabel: "Reverse tick value",
  axisFillRangePrompt: "Enter a number between -10 trillion and 10 trillion",
  axisMinValuePrompt: "Must be less than or the maximum value",
  axisMaxValuePrompt: "Must be greater than the minimum value",
  axisLogPrompt: "On a logarithmic scale, the minimum value must be greater than 0",
  insightsBtnTip: "Intelligent Analysis",
  disableAddBtnTip: "You can add to the report after disabling intelligent analysis",
  disableSelectBtnTip: "You can switch chart types after disabling intelligent analysis",
  disableEditBtnTip: "You can edit the chart after disabling intelligent analysis",
  disableMoreBtnTip: "You can perform more operations after disabling intelligent analysis",
  insightsPanelTitle: "Intelligent Analysis",
  insightParetoTitle: "Conforming to the 80/20 composition",
  insightSeasonalityTitle: "Period fluctuations",
  insightSeasonalityChartXAxisName: "Data interpretation",
  insightSeasonalityChartYAxisName: "Average volatility",
  insightTrendTitle: "Overall Trend",
  insightVolatilityTitle: "Volatility increases",
  insightAverageTitle: "Average",
  insightStatisticsTitle: "Variance",
  insightTextTitle: "Data interpretation",
  insightsErrorMsg: "Failed to retrieve analysis, please try again later",
  insightCorrelationTitle: "Correlation",
  insightRecentChangesTitle: "Recent changes",
  insightMixedTrendsTitle: "Mixed trends",
  retryBtnText: "Retry",
  viewBtnText: "View Analysis",
  viewDetailBtnText: "View Detail",
  viewAllBtnText: "View All Analysis",
  cumulativeProportion: "Cumulative proportion",
  yAxisCannotNull: "Y-axis cannot be empty",
  oneYAxisMustShowOneMeasure: "At least one measure needs to be displayed on the Y-axis",
  allDataPoint: "All data points",
  internalPoint: "Internal point",
  outlier: "Outlier",
  cabinet: "Box",
  dataPoint: "Data point",
  gridLinesLabel: "Grid lines",
  min: "Min",
  Q1: "Q1",
  median: "Median",
  Q3: "Q3",
  max: "Max",
  unicode: ":",
  cardTour: "The content on this card is data that is strongly related to the immediate outcome of the problem at hand",
  selectChartTour: "Change the type of chart display here",
  attributionTour:
    "🌟You can get data insights in this card. The system automatically compares the year-on-year changes, finds the most influential factors, and displays abnormal data.",
  attributionBtnTour: "Click the More button to view detailed analysis",
  nextStepBtn: "Next step",
  tryBtn: "Try",
  okBtn: "OK",
  dataLoadingText: "Fetching data...",
  chartLoadingText: "Generating chart...",
  chartRenderingText: "Rendering chart...",
  checkBtnText: "Check Out",
  insightsTourContent:
    "✨Click here to view intelligent analysis, which automatically summarizes important information in the charts for you, such as outliers, periodicity, volatility, etc.",
  otherLabel: "Other",
  dimensionConfigLabel: "Dimension display settings",
  defaultSelectLabel: "Show all",
  numberSelectLabel: "Set the number of impressions",
  percentSelectLabel: "Set display ratio",
  thresholdSelectLabel: "Set impression threshold",
  numberLabel: "Dimension Impression Quantity",
  percentLabel: "Dimension display percentage",
  thresholdLabel: "Dimension Display Threshold",
  enterIntPlaceholder: "Enter an integer",
  addonAfterText: "piece",
  compare: "compare to",
  inspectionPanelTitle: "Insight Analysis",
  unableSortOrTotal: "Unable to sort/total due to too many rows",
  highLabel: "High",
  middleLabel: "Middle",
  lowLabel: "Low",
  downloadFailedToast: "Download failed",
  calcLabel: "Calculation",
  solidColorLabel: "Solid color",
  gradientColorLabel: "Gradient color",
  viewForecastBtnText: "View Forecast",
  okBtnText: "OK",
  forecastTourContent: "🔮 If you want to see the forecast of this indicator, click here to generate.",
  animationBarMessage: "The animation bar only displays the first 20 rows of data",
};
