export default {
  timedReminderSetSuccess: "定时提醒设置成功",
  timedReminderConfig: "定时提醒设置",
  timedReminderIsOpen: "定时提醒已开启",
  timedReminderIsClose: "定时提醒已关闭",
  pleaseInputMessageTitle: "请输入消息名称",
  pleaseInputMessageDesc: "请输入消息简介",
  titleAndDesc: "消息和简介",
  viewBy: "查看方式",
  link: "链接",
  reminderFrequency: "提醒频率",
  pleaseChoose: "请选择",
  useAtLeastOne: "至少使用一种",
  reminderMethod: "提醒方式",
  reminderRecipient: "提醒接收人",
  allCollaborators: "全部协作者",
  messagePrompt: "消息提示",
  messageCenterPush: "消息中心推送",
  mailPush: "邮件推送",
  requireBindMail: "需要用户绑定邮箱",
  larkReminder: "飞书提醒",
  requireBindLark: "需要用户绑定飞书账号",
  dingtalkReminder: "钉钉提醒",
  requireBindDingtalk: "需要用户绑定钉钉账号",
  wecomReminder: "企业微信提醒",
  requireBindWecom: "需要用户绑定企业微信账号",
  selectUser: "选择用户",
  downloadRecord: "下载最近发送记录",
  pinboardNotExists: "报告不存在",
  noData: "暂无推送日志",
  generateFileFail: "生成文件失败",
  uploadFail: "上传文件失败",
};
