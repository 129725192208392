export default class ColumnProps {
  static DATA = "_d";

  static NESTED_FIELD = "_nes";

  static LIMIT = "_l";

  static OFFSET = "_o";

  static FORMAT = "_f";

  static MONEY_UNIT = "_cc";

  static DIMENSION_VALUE_COUNT = "dimensionValueCount";

  static NAME = "_n";

  static TIME_GROUP_OFFSET = "_tgo";

  static HAS_CUSTOM_SORT = "_hcs";

  static LINE_POSITION = "_ilp";

  static BAR_POSITION = "_ibp";

  static ANIMATION_POSITION = "_iap";

  static BAR_3D_POSITION = "_ihp";

  static BUBBLE_POSITION = "_ibup";

  static BOX_PLOT_POSITION = "_ibpp";

  static HEATMAP_POSITION = "_ihp";

  static MIXED_BAR_LINE_POSITION = "_imblp";

  static MIXED_STACK_LINE_POSITION = "_imslp";

  static PIE_POSITION = "_ipp";

  static PARETO_POSITION = "_iptp";

  static SANKEY_POSITION = "_isanp";

  static STACK_POSITION = "_istp";

  static PERCENT_STACK_POSITION = "_ipstp";

  static STACK_AREA_POSITION = "_isap";

  static VALUE_SCATTER_POSITION = "_iscp";

  static PIVOTTABLE_POSITION = "_ptp";

  static SUB_DIMENSIONS = "subDimensions";

  static PIVOTTABLE_ORDER = "_pto";

  static GEO_TYPE = "_t";

  static IS_TOTAL_DIMENSION = "isTotalDimension";

  static ID = "_id";

  static HIDE_SUM = "_hgts"; // 表格包含占比的部分指标不显示总计求和

  static CHART_POSITIONS = [
    ColumnProps.LINE_POSITION,
    ColumnProps.BAR_POSITION,
    ColumnProps.BUBBLE_POSITION,
    ColumnProps.BOX_PLOT_POSITION,
    ColumnProps.HEATMAP_POSITION,
    ColumnProps.MIXED_BAR_LINE_POSITION,
    ColumnProps.MIXED_STACK_LINE_POSITION,
    ColumnProps.PIE_POSITION,
    ColumnProps.PARETO_POSITION,
    ColumnProps.SANKEY_POSITION,
    ColumnProps.STACK_POSITION,
    ColumnProps.STACK_AREA_POSITION,
    ColumnProps.VALUE_SCATTER_POSITION,
    ColumnProps.PIVOTTABLE_POSITION,
    ColumnProps.BAR_3D_POSITION,
  ];
}
