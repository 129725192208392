import React from "react";

const GroupMember = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33333 3H9.66667V5.66667L6.33333 5.66667V3ZM5.33333 3C5.33333 2.44771 5.78105 2 6.33333 2H9.66667C10.219 2 10.6667 2.44772 10.6667 3V5.66667C10.6667 6.21895 10.219 6.66667 9.66667 6.66667H8.5V7.83333H12.5166C13.0689 7.83333 13.5166 8.28105 13.5166 8.83333V10.5H13.6667C14.219 10.5 14.6667 10.9477 14.6667 11.5V13C14.6667 13.5523 14.219 14 13.6667 14H12.1667C11.6144 14 11.1667 13.5523 11.1667 13V11.5C11.1667 10.9477 11.6144 10.5 12.1667 10.5H12.5166V8.83333H8.5V10.5H8.75C9.30228 10.5 9.75 10.9477 9.75 11.5V13C9.75 13.5523 9.30228 14 8.75 14H7.25C6.69772 14 6.25 13.5523 6.25 13V11.5C6.25 10.9477 6.69772 10.5 7.25 10.5H7.5V8.83333H3.54997V10.5H3.83333C4.38562 10.5 4.83333 10.9477 4.83333 11.5V13C4.83333 13.5523 4.38562 14 3.83333 14H2.33333C1.78105 14 1.33333 13.5523 1.33333 13V11.5C1.33333 10.9477 1.78105 10.5 2.33333 10.5H2.54997V8.83333C2.54997 8.28105 2.99768 7.83333 3.54997 7.83333H7.5V6.66667H6.33333C5.78105 6.66667 5.33333 6.21895 5.33333 5.66667V3ZM2.33333 11.5H3.83333V13H2.33333L2.33333 11.5ZM13.6667 11.5H12.1667V13H13.6667V11.5ZM7.25 11.5H8.75V13H7.25V11.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default GroupMember;
