export default {
  factTable: "事实表",
  dimensionTable: "维度表",
  confirm: "确认",
  cancel: "取消",
  removeConfirmText: "确定移除",
  okBtnText: "好的",
  closeBtnText: "收起",
  cancelBtnText: "取消修改",
  saveBtnText: "保存修改",
  removeTableBtnText: "移除该表",
  learnMoreBtnText: "了解更多",
  formerNameTableTip: "请为",
  latterNameTableTip: "命名，该命名将成为工作区搜索词；同时便于为您保存所做配置，在其他模型中复用。",
  addCustomFields: "自定义字段",
  edit: "编辑",
  add: "添加",
  globalFilter: "全局过滤",
  colNameInputPlaceholder: "请输入列名、关键词或别名查找",
  switchStatus: "点击切换状态",
  allCol: "全部列",
  selected: "已选",
  formerInputName: "请输入",
  latterInputName: "名称",
  autoFillBtnText: "快速填充",
  autoFillModalTitle: "快速填充",
  autoFillModalAlert: "快速填充只会对未勾选的行进行覆盖填充",
  addFilterTitle: "添加全局过滤条件",
  editFilterTitle: "编辑全局过滤条件",
  globalFilterDesc: "事实表的过滤条件，配置后对所有使用该模型的查询生效",
  addNewCustomFields: "添加自定义字段",
  templateDownloadBtn: "模板下载",
  uploadFilesBtn: "选择文件",
  reselectFileToast: "所选文件过大，请重新选择",
  uploadFailedToast: "上传失败",
};
