export default {
  currentPreviewTable: "Current PreviewTable:",
  currentTableNotSelected: "Current table: not selected",
  sampleData: "Data sample",
  metadata: "Metadata",
  fieldName: "Field name",
  originalDataType: "Original data type",
  fieldContent: "Field content",
  databaseHasChanged: "The database has changed, please re-edit the custom table",
  loadingFailed: "Loading failed,",
  reload: "Reload",
  loadingPleaseWait: "Loading, please wait...",
  canSwitchToMetadata: "You can also switch the preview mode to [Metadata]",
  toPreview: "Please click the table name on the left to preview",
  loadError: "Custom table query error",
  retryBtnText: "Retry",
  editCustomTableBtnText: "Edit custom tables",
  checkSQLTip: "Please retry or check and modify SQL",
};
