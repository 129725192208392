export default {
  triggerConditions: "触发条件",
  alertDescription: "实际变化",
  dateRangeDescription: "具体监控周期",
  createdAt: "告警发送时间",
  alertLogTitle: "告警记录",
  alertTimeTitle: "告警发送时间",
  noData: "暂无数据",
  filterTip: "点击查看全部",
  filterInputTip: "请输入自然数",
};
