export default {
  text: "Text",
  longText: "Long Text",
  list: "List",
  float: "Float",
  second: "Second",
  minute: "Minute",
  hour: "Hours",
  date: "Date",
  datetime: "Time",
  day: "Day",
  week: "Week",
  month: "Month",
  quarter: "Quarter",
  year: "Year",
  percentage: "Percentage",
  phone: "Phone number",
  id: "ID",
  integer: "Integer",
  decimal: "Number",
};
