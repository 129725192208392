export default {
  autoCreateType: "Automatically generate field types",
  autoCreateName: "Automatically generate field names",
  autoCreateAlias: "Automatically generate aliases",
  autoConfigData: "Automatically configure data",
  cancelConfirmTitle: "The completed AI configuration will be retained",
  cancelConfirmContent1: "Generated field names and aliases will not be cleared",
  cancelConfirmContent2: "Confirm termination of AI configuration?",
  okText: "Confirm",
  modalTitle: "AI Configuration",
  modalTitleTip:
    "DataGPT will automatically generate corresponding field names and match suitable search aliases based on the names of data columns",
  errorRequestConfigLoopText: "AI configuration request error, please try again later",
  emptyColumnToast: "There are no data columns that need AI configuration at present",
  waiting: "Waiting",
  errorEmptyAliasList: "Failed to automatically generate aliases",
  fail: "Failed",
};
