export default {
  overLimit: "Over limit",
  noLimit: "Unlimited",
  headerTitle: "Resource Management",
  nameLabel: "Customer Name",
  expiryDate: "Valid period",
  expired: "Expired",
  restPrefix: "Only left",
  restSuffix: "Day",
  pinboardLabel: "Number of reports",
  accountResourcesLabel: "Account Resources",
  accountNormalLabel: "Normal",
};
