export default {
  licence: "许可证：",
  url: "站点网址：",
  urlExtra: "用户访问的网站 URL，同时用于外链的生成，配置错误会导致外链无法正确访问",
  urlRequired: "站点网址不能为空",
  allowSynchro: "允许同步用户：",
  allowSynchroExtra: "当新用户通过单点或授权登录时，系统会在同步信息时自动为其注册账户",
  watermarkTip: "用户勾选上添加水印后，会在全局界面生效，生成水印。",
  basicSettingsSuccessfullyUpdated: "基础设置更新成功",
  updateConfig: "更新设置",
  sessionTimeout: "会话超时时间",
  inactivityLogoutTime: "无操作自动登出时间",
  inactivityLogoutTimeTip: "最小为 60（可以填 0，0 表示关闭无操作自动登出功能）",
  inactivityLogoutTimeErrorTip: "请输入0，或者最小为60的数字",
  freqWorkspaceNum: "常用工作区数量",
  username: "客户标识",
  expireTime: "到期时间：",
  viewBtnText: "查看",
  updateBtnText: "更新",
  formerDaysRemain: "仅剩",
  latterDaysRemain: "天",
  second: "秒",
  warningMessage:
    "当前账号超额（即账号数量＞配额数量），系统各类功能无法正常使用，请将各类型的账号数量（包含未激活、正常、禁用和休眠状态的账号）减至配额数量",
  urlSuccessfullyUpdated: "站点网址更新成功",
  urlUnsuccessfullyUpdated: "站点网址更新失败",
  urlTooltipFormer: "站点网址和服务器设置的网址",
  urlConsistentTooltipLatter: "一致",
  urlInconsistentTooltipLatter: "不一致，可能导致外链无法正确访问",
  tier1Label: "数据专家：工作区全部功能（包括工作区管理）",
  tier2Label: "全民数据科学家：搜索查看、数据下载及报告功能",
  tier3Label: "全民数据探索家：仅享有搜索和查看功能",
  defaultAccountTierLabel: "默认同步账号类型:",
  defaultAccountTierHelp: "控制单点或授权登录时自动注册的用户账号类型",
};
