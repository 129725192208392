export default {
  defaultRowColumnErrorMessage: "配置异常，请删除该行或重新配置",
  modelNeedAFactTable: "模型未配置事实表",
  modelNeedConfigs: "事实表未配置任何内容",
  modelFactTableNeedATimeDimension: "模型事实表缺乏时间维度",
  modelFactTableNeedAMeasure: "模型事实表缺乏指标",
  formerFactTableAndDimensionTableNeedLine: "事实表与维度表",
  latterFactTableAndDimensionTableNeedLine: "间未设置关联列",
  connectedDimensionTableNeedAConfig: "已连接的维度表至少配置一项",
  20004: "事实表外链的维度表需要至少配置一项",
  20101: "关键词不能为空",
  20102: "关键词字段需要为列表类型",
  20201: "指标设置了无效的聚合计算方式，请查阅文档并重新配置",
  20103: "命名错误，命名必须含有一个中文字符或英文字母",
  20104: "命名错误，命名中的特殊符号仅支持_ / \\ -",
  20105: "命名错误，关键词名称需要为字符串类型",
  former20106: "已存在于时间维度名称 ",
  latter20106: " 中，请选择其他名称或使用该关键词",
  20107: "已存在于自定义时间周期名称中，请选择其他名称或使用该关键词",
  former20108: "已存在于事实表维度名称 ",
  latter20108: " 中，请使用其他名称或修改事实表维度名称",
  former20109: "已存在于维度表 ",
  latter20109: " 维度名称中，请选择其他名称或使用该关键词",
  former20110: "已存在于指标名称 ",
  latter20110: " 中，请选择其他名称或使用该关键词",
  20111: "已存在于模型名称中，请选择其他名称或使用该关键词",
  20112: "命名错误，命名长度不可超过50字",
  20113: "已不存在，请重新创建或选择其他已有关键词",
  20114: "在当前事实表中被重复新建，请修改关键词名称或选择其他已有关键词",
  20115: "与事实表新建关键词重复，请修改关键词名称",
  20116: "在当前维度表中被重复新建，请修改关键词名称",
  former20117: "在维度表",
  latter20117: "中存在重复新建，请修改关键词名称",
  20118: "与当前模型名重复",
  needNames: "不能为空",
  20005: "在当前事实表中被重复选择，请选择其他已有关键词或新建关键词。 ",
  20119: "在当前维度表中被重复创建，请选择任一处新建或选择其他关键词",
  20121: "在其他维度表中同时被创建，请选择任一处新建或选择其他关键词",
  20120: "在当前维度表中被重复选择，请选择任一处新建或选择其他关键词",
  20122: "在其他维度表中同时被选择，请选择任一处新建或选择其他关键词",
  customSqlNeedFieldType: "自定义字段需要选择字段类型。",
  needSql: "缺少数据列配置",
  needChoiceJson: "映射内容未配置",
  20202: "未设置数据类型",
  id: "关键词",
  sql: "数据列",
  fieldType: "字段类型",
  type: "数据类型",
  format: "展示类型",
  names: "关键词",
  synonym: "别名",
};
