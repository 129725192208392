enum CardFilterUnitType {
  TIME_RANGE = "timeRange",

  DIMENSION = "dimension",

  MEASURE = "measure",

  CUSTOM_DATASET = "dataset",

  OTHER = "other",
}

export default CardFilterUnitType;
