export default {
  inputOneValue: "输入 1 个数值",
  mobileNumberInputPlaceholder: "输入整数",
  minValueText: "最小值",
  maxValueText: "最大值",
  minValue: "最小值(≥)",
  maxValue: "最大值(≤)",
  NoIntersection: "筛选条件无交集",
  AddCondition: "添加条件",
  meetAllConditions: "将显示满足所有条件的数据",
  inputInt: "请输入正整数",
  loading: "加载中，请稍候……",
  batchAddValue: "批量添加包含的值",
  findNoResults: "查找无结果，请更换关键字。",
  notInDatabase: "此维度值可能不在数据库中",
  selectStartTimeTitle: "选择开始时间",
  selectEndTimeTitle: "选择结束时间",
  startTimeLabel: "开始时间",
  endTimeLabel: "结束时间",
  customRangeTimeTitle: "自定义时间范围",
  selectAfterTimeTitle: "选择在...之后",
  selectFormerTimeTitle: "选择时间范围",
  applyButtonText: "应用",
  okButtonText: "确定",
  dateTimeFormatter: "YYYY 年 MM 月 DD 日 HH 时 mm 分 ss 秒",
  dateFormatter: "YYYY 年 MM 月 DD 日",
  childListLimitToast: "展示前 500 个维度值，可通过查找选择未展示的值",
  textFilterTip: "*将显示满足其一条件的数据",
  multiSelect: "多选",
  batchAdd: "批量输入",
  cancel: "取消",
  days: "天",
  weeks: "周",
  months: "月",
  quarters: "季度",
  years: "年",
  seconds: "秒",
  minutes: "分",
  hours: "时",
  timeUnit: "时间颗粒度",
  timeQuickFilter: "快捷筛选",
  optionsNull: "无",
  thisDay: "本日",
  this: "本",
  timeRange: "具体时间",
};
