import { formatUrl } from "@/utils/common";
import Auth from "./Auth";

/*
整理前端获取 API URL 的函数名称
https://datarc.feishu.cn/wiki/wikcn5JUXvve811RXVLQ0NLFgAh
*/

/* 公共函数 */
export const getHostUrl = () => window.devHostUrl || formatUrl(window.location.pathname);
export const getResourceUrl = () => formatUrl(window.resourceUrl || window.origin);
export const getZaiChiUrl = () => window.zaichiUrl;

export const getAuthDataByTokenUrl = () => `${getHostUrl()}yili/xiaotuo/`;

/* 匿名接口 */
export const getConfUrl = () => `${getHostUrl()}react-init/`;
export const getSetupUrl = () => `${getHostUrl()}setup/`;
export const getSetupValidateUrl = () => `${getHostUrl()}setup-validate/`;
export const getRenewLicenceUrl = () => `${getHostUrl()}renew-licence/`;
export const getV2Url = uri => formatUrl(`${getHostUrl()}api/v2/${uri}`);
export const getV3Url = uri => formatUrl(`${getHostUrl()}api/v3/${uri}`);
export const getWeComOAuthUrl = () => `${getHostUrl()}wechat-work/oauth/`;
export const getLarkOAuthUrl = () => `${getHostUrl()}lark/oauth/`;
export const getDingTalkOAuthUrl = () => `${getHostUrl()}dingtalk/oauth/`;
export const getOAuth2LoginUrl = () => `${getHostUrl()}sso/${encodeURI(Auth.getClientID())}/oauth2/1/login/`;
export const getSAML2LoginUrl = () => `${getHostUrl()}sso/${encodeURI(Auth.getClientID())}/saml2/login/`;
export const getYufuOAuthUrl = () => `${getHostUrl()}sso/${encodeURI(Auth.getClientID())}/yufu/login/`;
export const getV2ResetInitialPasswordUrl = () => getV2Url("auth/reset-initial-password/");
export const getV2Validate2fa = () => getV2Url("auth/validate-2fa/");
export const getActivationCodesUrl = () => "https://hexagram.datarc.cn/activate/activation_codes/";
export const getActivateTokensUrl = () => "https://hexagram.datarc.cn/activate/tokens/";
export const getHealthUrl = () => `${getHostUrl()}health/`;
export const getV2SystemStatusUrl = () => getV2Url("system-status/");

/* 登录用户可以调用的接口 */
export const getV2GeoUrl = () => getV2Url("geo");
export const getV2AccountUrl = () => getV2Url(`accounts/${Auth.getUid()}/`);
export const getV2ChangeProjectUrl = () => `${getV2AccountUrl()}change-project/`;
export const getV3ChatUrl = id => getV3Url(`chats/${id ? `${id}` : ""}/`);
export const getV3LogoutUrl = () => getV3Url("auth/logout/");

// 离线地图接口
export const getGeoJsonUrl = code => `${getResourceUrl()}geo_data/${code}.json`;
export const getV2GeoOfflineDataUrl = () => `${getV2GeoUrl()}geo-offline/`;

// adminProject和DimensionBlock中url是在页面中定义的，未改
/* 系统管理员接口 */
export const getV2ClientUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/`);
export const getV2WeComUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/wechat-work/`);
export const getV2WeComAddMenuUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/add-wecom-menu/`);
export const getV2WeComRemoveMenuUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/remove-wecom-menu/`);
export const getV2DingTalkUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/dingtalk/`);
export const getV2LarkUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/lark/`);
export const getV2CASUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/cas/`);
export const getV2UploadVerificationUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/upload-verification/`);
export const getV2MemberUrlSA = (aid, uid) =>
  getV2Url(`clients/${encodeURI(Auth.getClientID())}/accounts/${aid}/members${uid ? `/${uid}` : ""}/`);
export const getV2YufuUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/yufu/`);
export const getV2OAuth2UrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/oauth2/`);
export const getV2SAML2UrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/saml2/`);
export const getV2ClientAccountUrlSA = uid => getV2Url(`clients/${encodeURI(Auth.getClientID())}/accounts${uid ? `/${uid}` : ""}/`);
export const getV2ClientAccountBindInfoUrlSA = uid => getV2Url(`clients/${encodeURI(Auth.getClientID())}/accounts/${uid}/bind-info/`);
export const getV2ClientAccountUnBindUrlSA = uid => getV2Url(`clients/${encodeURI(Auth.getClientID())}/accounts/${uid}/unbind/`);
export const getV2ClientProjectUrlSA = id => getV2Url(`clients/${encodeURI(Auth.getClientID())}/projects${id ? `/${id}` : ""}/`);
export const getV2ClientDocUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/docs/`);
export const getV2QuotaUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/accounts/quota/`);
export const getV2LicenceUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/licence`);
export const getV2AsyncTaskUrlSA = (projectId, uid) =>
  getV2Url(`clients/${encodeURI(Auth.getClientID())}/async-tasks/${uid ? `${uid}/` : ""}`);
export const getV2PresignedPutUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/presigned-put/`);
export const getV2SystemStatusUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/system-status/`);
export const getV2SystemStatusResetBeatUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/system-status/reset-beat/`);
export const getCheckHTTPMethodsUrlSA = () => `${getHostUrl()}http-methods/`;
export const getV2AllOwnerProjectUrlSA = uid =>
  getV2Url(`clients/${encodeURI(Auth.getClientID())}/accounts/${uid ? `${uid}/` : ""}projects/`);
export const getV2TransferProjectUrlSA = uid =>
  getV2Url(`clients/${encodeURI(Auth.getClientID())}/accounts/${uid ? `${uid}/` : ""}projects/transfer/`);
export const getV2AppearanceUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/appearance/`);
export const getV2AccountTemplateUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/accounts/account-template/`);
export const getV2ClientEmailUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/email/`);
export const getV2ClientCheckEmailUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/check-email/`);
export const getV2ClientSMSUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/sms/`);
export const getV2ClientCheckSMSUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/check-sms/`);
export const getV2AccountAssetsUrlSA = accountUid => getV2Url(`clients/${encodeURI(Auth.getClientID())}/accounts/${accountUid}/assets/`);
export const getV2AccountMemberAssetsUrlSA = (accountUid, memberUid) =>
  getV2Url(`clients/${encodeURI(Auth.getClientID())}/accounts/${accountUid}/members/${memberUid}/assets/`);
export const getV2ClientLLMUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/llm/`);
export const getV2ClientPingLLMUrlSA = () => getV2Url(`clients/${encodeURI(Auth.getClientID())}/ping-llm/`);

/* 工作区负责人可以调用的接口 */
export const getV2AllManageProjectUrlMA = () => getV2Url("admin-projects/");
export const getV3AllManageProjectUrlMA = () => getV3Url("admin-projects/");

/* 自定义数据集管理接口 */
export const getV3ManageProjectCustomDatasetUrlMa = id =>
  `${getV3AllManageProjectUrlMA()}${Auth.getProjectId()}/dataset/${id ? `${id}/` : ""}`;

/* 自定义数据集用户接口 */
export const getV3ProjectCustomDatasetUrlMa = id => `${getV3ProjectUrl()}datasets/${id ? `${id}/` : ""}`;

export const getV2ManageProjectUrlFullMA = id => `${getV2AllManageProjectUrlMA()}${encodeURI(id)}/`;
export const getV3ManageProjectUrlFullMA = id => `${getV3AllManageProjectUrlMA()}${encodeURI(id)}/`;
export const getV2CanvasModelFullUrlMA = (id, uid) => `${getV2AllManageProjectUrlMA()}${id}/canvas-models/${uid ? `${uid}/` : ""}`;
export const getV2CopyModelFullUrlMA = (id, uid) => `${getV2AllManageProjectUrlMA()}${id}/canvas-models/${uid}/fork/`;
export const getV2NameValidationFullUrlMA = id => `${getV2AllManageProjectUrlMA()}${id}/name-validation/`;
export const getV2DimensionCustomSortFullUrlMA = (id, uid) => `${getV2AllManageProjectUrlMA()}${id}/dimensions/${uid}/custom-sort/`;
export const getV2DimensionAttrCustomSortFullUrlMA = (id, uid, aid) =>
  `${getV2AllManageProjectUrlMA()}${id}/dimensions/${uid}/attrs/${aid}/custom-sort/`;
export const getV2TableStatusFullUrlMA = id => `${getV2AllManageProjectUrlMA()}${id}/table-status/`;
export const getV2CheckModelStatusFullUrlMA = id => `${getV2ManageProjectUrlFullMA(id)}check-model-status/`;
export const getV2AbortTaskFullUrlMA = (id, uid) => `${getV2AllManageProjectUrlMA()}${id}/async-tasks/${uid}/kill/`;
export const getV2SynonymTemplateUrlMA = () => `${getV2AllManageProjectUrlMA()}${Auth.getProjectId()}/synonym-words/synonym-template/`;
export const getV2ModelDraftFullUrlMA = (id, uid) => `${getV2AllManageProjectUrlMA()}${id}/model-drafts/${uid ? `${uid}/` : ""}`;
export const getV2CheckDimensionTableStatusFullUrlMA = (id, uid) =>
  `${getV2ManageProjectUrlFullMA(id)}dimension-tables/${uid}/check-table-status/`;
export const getV3CheckDimensionTableStatusFullUrlMA = (id, uid) =>
  `${getV3ManageProjectUrlFullMA(id)}dimension-tables/${uid}/check-table-status/`;
export const getV2DimensionTableMigrateFullUrlMA = (id, uid) => `${getV2ManageProjectUrlFullMA(id)}dimension-tables/${uid}/migrate/`;

// AI 配置
export const getOpenAiFiledType = () => `${getV2AllManageProjectUrlMA()}${Auth.getProjectId()}/fields/`;
export const getOpenAIKeywords = () => `${getV2AllManageProjectUrlMA()}${Auth.getProjectId()}/keywords/`;
export const getOpenAISynonyms = () => `${getV2AllManageProjectUrlMA()}${Auth.getProjectId()}/synonyms/`;

// AI 错误解析
export const getAiCorrectionUrlMA = () => `${getV2ProjectsUrl()}${Auth.getProjectId()}/sql-correction/`;

const getV2ManageUriFullUrlMA = (id, uri, uid) =>
  uid ? `${getV2ManageProjectUrlFullMA(id)}${uri}/${encodeURI(uid)}/` : `${getV2ManageProjectUrlFullMA(id)}${uri}/`;
export const getV2DebounceTaskMA = projectId => `${getV2AllManageProjectUrlMA()}${projectId}/debounce-tasks/`;

const getV3ManageUriFullUrlMA = (id, uri, uid) =>
  uid ? `${getV3ManageProjectUrlFullMA(id)}${uri}/${encodeURI(uid)}/` : `${getV3ManageProjectUrlFullMA(id)}${uri}/`;

export const getV2MemberFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "members", uid);
export const getV2MemberBatchUploadModelUrlMA = () => getV2ManageUriFullUrlMA(Auth.getProjectId(), "members/template");
export const getV2MemberModelPermissionSwitchFullUrlMA = (id, uid) => `${getV2MemberFullUrlMA(id, uid)}enable-model-permission/`;
export const getV2MemberModelPermissionFullUrlMA = (id, uid) => `${getV2MemberFullUrlMA(id, uid)}model-permission/`;
export const getV2MemberColPermissionSwitchFullUrlMA = (id, uid) => `${getV2MemberFullUrlMA(id, uid)}enable-col-permission/`;
export const getV2MemberColPermissionFullUrlMA = (id, uid) => `${getV2MemberFullUrlMA(id, uid)}col-permission/`;
export const getV2MemberRowPermissionSwitchFullUrlMA = (id, uid) => `${getV2MemberFullUrlMA(id, uid)}enable-row-permission/`;
export const getV2MemberRowPermissionFullUrlMA = (id, uid) => `${getV2MemberFullUrlMA(id, uid)}row-permission/`;
export const getV2MemberPinboardsFullUrlMA = (id, uid) => `${getV2MemberFullUrlMA(id, uid)}pinboards/`;
export const getV2MemberTransferFullUrlMA = (id, uid) => `${getV2MemberFullUrlMA(id, uid)}transfer/`;
export const getV2MemberTransferTargetFullUrlMA = (id, uid) => `${getV2MemberFullUrlMA(id, uid)}transfer-target/`;
export const getV2MemberAssetsFullUrlMA = (id, uid) => `${getV2MemberFullUrlMA(id, uid)}assets/`;

export const getV2MemberGroupFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "member-groups", uid);
export const getV2MemberGroupModelPermissionSwitchFullUrlMA = (id, uid) => `${getV2MemberGroupFullUrlMA(id, uid)}enable-model-permission/`;
export const getV2MemberGroupModelPermissionFullUrlMA = (id, uid) => `${getV2MemberGroupFullUrlMA(id, uid)}model-permission/`;
export const getV2MemberGroupColPermissionSwitchFullUrlMA = (id, uid) => `${getV2MemberGroupFullUrlMA(id, uid)}enable-col-permission/`;
export const getV2MemberGroupColPermissionFullUrlMA = (id, uid) => `${getV2MemberGroupFullUrlMA(id, uid)}col-permission/`;
export const getV2MemberGroupRowPermissionSwitchFullUrlMA = (id, uid) => `${getV2MemberGroupFullUrlMA(id, uid)}enable-row-permission/`;
export const getV2MemberGroupRowPermissionFullUrlMA = (id, uid) => `${getV2MemberGroupFullUrlMA(id, uid)}row-permission/`;
export const getV2MemberGroupDataMaskingPermissionSwitchFullUrlMA = (id, uid) =>
  `${getV2MemberGroupFullUrlMA(id, uid)}enable-data-masking/`;
export const getV2MemberGroupDataMaskingPermissionFullUrlMA = (id, uid) => `${getV2MemberGroupFullUrlMA(id, uid)}data-masking/`;

export const getV2ModelPruneFullUrlMA = id => getV2ManageUriFullUrlMA(id, "prune");
export const getV2ModelFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "canvas-models", uid);
export const getV2MeasureFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "measures", uid);
export const getV2MeasureCheckFormulaFullUrlMA = id => getV2ManageUriFullUrlMA(id, "measures/check-formula");
export const getV2TimeSeriesFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "time-series", uid);
export const getV2DimensionTablesFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "dimension-tables", uid);
export const getV2DimensionFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "dimensions", uid);
export const getV2DimensionAttrFullUrlMA = (id, uid, aid) =>
  aid ? `${getV2DimensionFullUrlMA(id, uid)}attrs/${encodeURI(aid)}/` : `${getV2DimensionFullUrlMA(id, uid)}attrs/`;
export const getV2DimensionParentDimensionFullUrlMA = (id, uid, aid) =>
  `${getV2DimensionFullUrlMA(id, uid)}${aid ? `attrs/${encodeURI(aid)}/` : ""}parent-dimensions/`;

export const getV3DimensionTablesFullUrlMA = (id, uid) => getV3ManageUriFullUrlMA(id, "dimension-tables", uid);
export const getV3DeleteTableDimensionFullUrlMA = (id, uid) => `${getV3DimensionTablesFullUrlMA(id, uid)}delete-dimension/`;

// 自定义 sql 创建表相关
export const getV2CustomTableListFullUrlMA = id => `${getV2ManageProjectUrlFullMA(id)}custom-tables/`;
export const getV2UpdateCustomTableFullUrlMA = (id, uid) => `${getV2ManageProjectUrlFullMA(id)}custom-tables/${uid}/`;
export const getV2CustomTableColumnsFullUrlMA = (id, uid) => `${getV2CustomTableListFullUrlMA(id)}${uid}/columns/`;
export const getV2CustomTableDataFullUrlMA = (id, uid) => `${getV2CustomTableListFullUrlMA(id)}${uid}/data/`;
export const getV2CustomTableOneColumnDataMA = (id, uid) => `${getV2CustomTableListFullUrlMA(id)}${uid}/column-preview/`; // 获取某一列的预览数据

// 日期标签相关
export const getV2CalendarDateTagFullUrlMA = (id, rid) => getV2ManageUriFullUrlMA(id, "date-tag-records", rid);
export const getV2CustomDateTagFullUrlMA = (id, tid) => getV2ManageUriFullUrlMA(id, "date-tag-details", tid);
export const getV2DayDateTagFullUrlMA = id => getV2ManageUriFullUrlMA(id, "date-tag-details/bulk-get");
export const getV2UpdateDayDateTagFullUrlMA = id => getV2ManageUriFullUrlMA(id, "date-tag-details/bulk-update");
export const getV2SystemDateTagFullUrlMA = (id, tid) => getV2ManageUriFullUrlMA(id, "system-date-tags", tid);
export const getV2ResetCalendarFullUrlMA = id => getV2ManageUriFullUrlMA(id, "date-tag-details/reset");

export const getV2AccountsFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "accounts", uid);
export const getV2DataSourceFullUrlMA = id => getV2ManageUriFullUrlMA(id, "datasource");
export const getV2DatabaseVersionFullUrlMA = id => getV2ManageUriFullUrlMA(id, "database-version");

// 自有数据源相关接口
export const getV2InternalResourceUrlMA = () => getV2ManageUriFullUrlMA(Auth.getProjectId(), "internal-datasources");
export const getV2InternalDBInfoUrlMA = () => getV2ManageUriFullUrlMA(Auth.getProjectId(), "resources/db-information");
export const getV2InternalResetPasswordUrlMA = () => getV2ManageUriFullUrlMA(Auth.getProjectId(), "internal-datasources/reset-password");
export const getV2InternalDBTablesUrlMA = () => getV2ManageUriFullUrlMA(Auth.getProjectId(), "internal-datasources/tables");
export const getV2DatabaseInternalTableCheckNamesFullUrlMA = id => getV2ManageUriFullUrlMA(id, "internal-datasources/check-names");

export const getV2AsyncTaskFullUrlMA = (id, tid) => getV2ManageUriFullUrlMA(id, "async-tasks", tid);
export const getV2KillAsyncTaskFullUrlMA = (id, tid) => `${getV2ManageUriFullUrlMA(id, "async-tasks", tid)}kill/`;
export const getV2RefreshQueryCacheFullUrlMA = id => getV2ManageUriFullUrlMA(id, "refresh-query-cache");
export const getV2S3PreSignedFullUrlMA = id => getV2ManageUriFullUrlMA(id, "presigned-put");
export const getV2RowPermissionResourceFullUrlMA = id => getV2ManageUriFullUrlMA(id, "row-permission-resource");

export const getV2SchemaTableListMA = id => getV2ManageUriFullUrlMA(id, "schemas"); // 获取数据库表 list
export const getV2SchemaTableDataMA = id => getV2ManageUriFullUrlMA(id, "schemas/table-data"); // 获取数据示例
export const getV2SchemaTableColumnsMA = id => getV2ManageUriFullUrlMA(id, "schemas/columns"); // 获取元数据
export const getV2SchemaTableOneColumnDataMA = id => getV2ManageUriFullUrlMA(id, "schemas/column-preview"); // 获取某一列的预览数据

export const getV2ProjectSyncKeywordTaskFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "sync-keyword-tasks", uid);
export const getV2RuleFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "rules", uid);
export const getV2TestRuleFullUrlMA = id => getV2ManageUriFullUrlMA(id, "rules/dry-run");
export const getV2TimeFuncOffsetFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "time-group-offsets", uid);
export const getV2SynonymWordsFullUrlMA = (id, tid) => getV2ManageUriFullUrlMA(id, "synonym-words", tid);
export const getV2SynonymWordsGenerateSynonymFullUrlMA = id => getV2ManageUriFullUrlMA(id, "synonym-words/generate-synonym-url");
export const getV2ClearAliasFullUrlMA = id => getV2ManageUriFullUrlMA(id, "remove-all-alias");
export const getV2BatchUpdateAliasFullUrlMA = id => getV2ManageUriFullUrlMA(id, "batch-update-alias");
export const getV2FeedbackFullUrlMA = (id, uid) => getV2ManageUriFullUrlMA(id, "feedbacks", uid);
export const getV2ExternalResourceUrlMA = id =>
  `${getV2ManageProjectUrlFullMA(Auth.getProjectId())}external-resources/${id ? `${id}/` : ""}`;

// 团队版
export const getV2TeamResourceUrlMA = id => `${getV2ManageProjectUrlFullMA(id)}resources/`;

/* 工作区普通用户调用的接口 */

export const getV2TrackPointUrl = () => getV2Url("events");

export const getV2ProjectsUrl = () => getV2Url("account-projects/");
export const getV2ProjectFullUrl = id => getV2Url(`account-projects/${encodeURI(id)}/`);
export const getV3ProjectFullUrl = id => getV3Url(`account-projects/${encodeURI(id)}/`);
const getV2ProjectUrl = () => getV2ProjectFullUrl(Auth.getProjectId());
export const getV3ProjectUrl = () => getV3ProjectFullUrl(Auth.getProjectId());
const getV2BasePatternUrl = (uri, uid) => (uid ? `${getV2ProjectUrl()}${uri}/${encodeURI(uid)}/` : `${getV2ProjectUrl()}${uri}/`);

export const getV2PropCustomSortFullUrl = id => `${getV2ProjectFullUrl(id)}custom-sort/`;
export const getV2AccountAsyncTaskFullUrl = (id, uid) =>
  uid ? `${getV2ProjectFullUrl(id)}async-tasks/${encodeURI(uid)}/` : `${getV2ProjectFullUrl(id)}async-tasks/`;
export const getV2MemberGroupMembersFullUrl = (id, uid, mid) =>
  `${getV2ProjectFullUrl(id)}member-groups/${encodeURI(uid)}/members/${mid ? `${mid}/` : ""}`;
export const getV2MemberGroupAccountsFullUrl = (id, uid) => `${getV2ProjectFullUrl(id)}member-groups/${encodeURI(uid)}/accounts/`;
export const getV2KillAccountAsyncTaskUrl = uid => `${getV2ProjectUrl()}async-tasks/${encodeURI(uid)}/kill/`;
export const getV3HasWatchUrl = id => `${getV3ProjectUrl()}watch/${id}/has-watch/`;
export const getV2SynonymKeywordUrlMA = id => `${getV2AllManageProjectUrlMA()}${encodeURI(id)}/synonym-words/keywords/`;

// 搜索反馈接口
export const getV3FeedbackTextMatchUrl = () => `${getV3ProjectUrl()}shortcuts/search/`;
export const getV3CreateFeedbackUrl = () => `${getV3ProjectUrl()}feedbacks/`;
export const getV3CheckTextUrl = () => `${getV3ProjectUrl()}feedbacks/check-text/`;
export const getV2BatchModelsUrl = () => `${getV2ProjectUrl()}tokens/models/`;

// 搜索框 - 筛选器新接口
export const getV3SearchFeedbackTokensUrl = () => `${getV3ProjectUrl()}feedbacks/`;
export const getV2SearchAnalysisTokensUrl = () => `${getV2ProjectUrl()}analysis-tokens/`;
export const getV2SearchDimensionKeywordsUrl = id => `${getV2ProjectFullUrl(id || Auth.getProjectId())}dimension-keywords/`;
const getV2DimensionsUrl = () => `${getV2ProjectUrl()}dimensions/`;
export const getV2SearchDimensionTokensUrl = () => `${getV2DimensionsUrl()}dimension-tokens/`;
export const getV2SearchTimeSeriesTokensUrl = () => `${getV2DimensionsUrl()}time-series/`;
export const getV2SearchTimeSeriesTokenKeywordsUrl = () => `${getV2DimensionsUrl()}time-keywords/`;
export const getV2SearchMeasureTokensUrl = () => `${getV2ProjectUrl()}measures/measure-tokens/`;

export const getV2ModelUrl = s => getV2BasePatternUrl("models", s);

export const getV2AskTutorialUrl = () => getV2BasePatternUrl("ask-tutorial");
export const getV3PinboardForkUrl = uid => `${getV3PinboardUrl(uid)}fork/`;
export const getV3CardUrl = () => `${getV3ProjectUrl()}card/`;
export const getV2ProjectConfig = () => getV2BasePatternUrl("config");
export const getV3CardShareFullUrl = (id, uid) => `${getV3ProjectFullUrl(id)}card-shares/${uid ? `${encodeURI(uid)}/` : ""}`;
export const getV3CardShareUrl = uid => getV3CardShareFullUrl(Auth.getProjectId(), uid);
export const getV2ProjectMemberUrl = () => getV2BasePatternUrl("members", Auth.getProjectMuId());

// 搜索框交互接口
export const getV3CutUrl = () => `${getV3ProjectUrl()}cut/`;
export const getV3AskUrl = id => `${getV3ProjectFullUrl(id || Auth.getProjectId())}ask/`;
export const getV3AskFiltersUrl = id => `${getV3ProjectFullUrl(id || Auth.getProjectId())}question-filters/`;
export const getV3AskPinboardsUrl = id => `${getV3ProjectFullUrl(id || Auth.getProjectId())}question-pinboards/`;
export const getV3AskQuestionPolysemyUrl = id => `${getV3ProjectFullUrl(id || Auth.getProjectId())}question-polysemy/`;
export const getV3AskQuestionPolysemyActionUrl = id => `${getV3ProjectFullUrl(id || Auth.getProjectId())}polysemy-selection/`;
export const getV3AskQuestionFuzzyActionUrl = id => `${getV3ProjectFullUrl(id || Auth.getProjectId())}fuzzy-selection/`;
export const getV2SearchTypeSettingsUrl = () => `${getV2ProjectUrl()}members/${encodeURI(Auth.getProjectMuId())}/`;
export const getV3SearchAutoCompleteTipsUrl = () => `${getV3ProjectUrl()}auto-complete/`;
export const getV3QuestionStarsUrl = qId => `${getV3ProjectUrl()}question-stars${qId ? `/${qId}/` : "/"}`;
export const getV3DeleteRecordUrl = () => `${getV3ProjectUrl()}remove-history/`;
export const getV3RelatedQuestionUrl = () => `${getV3ProjectUrl()}related-questions/`;
export const getV3QuestionVoteUrl = () => `${getV3ProjectUrl()}question-votes/`;
export const getV3AttributionComparisonsUrl = id => `${getV3ProjectFullUrl(id)}attributions/comparisons/`;
export const getV3AttributionDimensionsUrl = id => `${getV3ProjectFullUrl(id)}attributions/dimensions/`;
export const getV3AttributionTrendingUrl = id => `${getV3ProjectFullUrl(id)}attributions/trending/`;
export const getV3AttributionAttributesUrl = id => `${getV3ProjectFullUrl(id)}attributions/attributes/`;
export const getV3AttributionInspectionsUrl = id => `${getV3ProjectFullUrl(id)}attributions/inspections/`;
export const getV3AttributionAnomaliesUrl = id => `${getV3ProjectFullUrl(id)}attributions/anomalies/`;
export const getV3AttributionCardsUrl = id => `${getV3ProjectFullUrl(id)}attributions/cards/`;
export const getV3StreamVoiceAsrUrl = () => "https://datarc.cn/services/asr/api/v1/stream-asr/";

// 普通成员接口
export const getV2DocUrl = docType => getV2Url(docType ? `docs/${docType}/` : "docs/");

// 我的卡片接口
export const getV3MyCardUrl = id => `${getV3ProjectUrl()}cards/${id ? `${id}/` : ""}`;

// 我的历史记录
export const getV3MyHistoryUrl = () => `${getV3ProjectUrl()}history/`;

// 关注问题
export const getV3QuestionStarUrl = id => `${getV3ProjectUrl()}question-stars/${id ? `${id}/` : ""}`;

export const getV3PinboardShareUrl = (uid, id) => `${id ? getV3ProjectFullUrl(id) : getV3ProjectUrl()}pinboard-shares/${encodeURI(uid)}/`;
export const getV3PinboardShareShortUrlUrl = uid => `${getV3ProjectUrl()}pinboard-shares/${encodeURI(uid)}/create-share-url/`;
// 可分享用户和用户组
export const getV3MembersAndGroupsForShareUrl = uid => `${getV3PinboardShareUrl(uid)}get-allow-share/`;
// 发起报告访问申请
export const getV3PinboardShareRequestUrl = (uid, id) => `${getV3PinboardShareUrl(uid, id)}create-share-request/`;
// 已经设置的访问者列表
export const getV3PinboardVisitorsUrl = uid => `${getV3PinboardShareUrl(uid)}get-shared/`;
// 修改分享者信息
export const getV3UpdateVisitorsUrl = uid => `${getV3PinboardShareUrl(uid)}update-visit/`;
// 修改权限开关信息
export const getV3UpdatePermissionSwitchUrl = uid => `${getV3PinboardUrl(uid)}set-permission/`;
// 恢复报告
export const getV3PinboardRestoreUrl = uid => `${getV3PinboardUrl(uid)}restore/`;
// 彻底删除
export const getV3PinboardCompleteDeleteUrl = uid => `${getV3PinboardUrl(uid)}remove-permanently/`;
// 分享页获取报告详情
export const getV3SharePinboardDetailUrl = (uid, id) => `${getV3PinboardUrl(uid, id)}pinboard-share-detail/`;
// 报告缓存设置获取接口
export const getV3PinboardCacheTaskUrl = uid => `${getV3PinboardUrl(uid)}cache-task/`;
// 报告定时提醒设置获取接口
export const getV3PinboardSubscriptionUrl = uid => `${getV3PinboardUrl(uid)}subscription/`;

// 热门问题
export const getV3TopQuestionsUrl = () => `${getV3ProjectUrl()}top-questions/`;
// 我的观察
export const getV3MyWatchUrl = id => `${getV3ProjectUrl()}watch${id ? `/${id}` : ""}/`;
// 我常搜索
export const getV3FrequentQuestionsUrl = () => `${getV3ProjectUrl()}frequent-questions/`;
// 你可以试试
export const getV3QuestionGuideUrl = () => `${getV3ProjectUrl()}question-guide/`;
// 批量删除历史记录
export const getV3BulkRemoveHistoryUrl = () => `${getV3ProjectUrl()}bulk-remove-history/`;
// 获取用户熟练度
export const getV3UserSkillLevelUrl = () => `${getV3ProjectUrl()}user-skill-level/`;

// 消息
export const getV3MessagesUrl = () => getV3Url("messages/");
export const getV3BulkDeleteUrl = () => `${getV3MessagesUrl()}bulk-delete/`;
export const getV3BulkUpdateUrl = () => `${getV3MessagesUrl()}bulk-update/`;
export const getV3ReadAllUrl = () => `${getV3MessagesUrl()}read-all/`;
// 审批消息
export const getV3ApprovalFullUrl = (id, uid) => `${getV3ProjectFullUrl(id)}pinboard-shares/${uid}/approval/`;
export const getV3FeedbacksFullUrl = (id, uid) => `${getV3ProjectFullUrl(id)}feedbacks/${uid}/`;

// 新增搜索
export const getV3NewQuestionsUrl = () => `${getV3ProjectUrl()}new-questions/`;

// 下钻
const getV2TimeSeriesUrl = () => `${getV2ProjectUrl()}time-series/`;
export const getV2TierDrillDownUrl = () => `${getV2DimensionsUrl()}tier-drills/`;
export const getV2DrillDownUrl = () => `${getV2DimensionsUrl()}drills/`;
export const getV2TimeDrillDownUrl = () => `${getV2TimeSeriesUrl()}drills/`;
export const getV3DrillDownQuestionUrl = () => `${getV3ProjectUrl()}drill-question/`;
// 上钻
export const getV2RollUpUrl = () => `${getV2DimensionsUrl()}up-drills/`;
export const getV3RollUpQuestionUrl = () => `${getV3ProjectUrl()}up-drill-question/`;

// base64 转可下载的图片地址
export const getV3Base64ConverterUrl = () => getV3Url("images/base64/");

// 获取 token 数据
export const getV2TokenUrl = () => `${getV2ProjectUrl()}tokens/`;
// 获取有效 token
export const getV3CheckTokensFullUrl = id => `${getV3ProjectFullUrl(id)}check-tokens/`;
// 前台获取展示设置的配置
export const getV2DisplayConfigsUrl = () => `${getV2ProjectUrl()}config/`;

// 告警
export const getV2MembersUrl = () => `${getV2ProjectUrl()}members/`;
export const getV2MemberGroupsUrl = () => `${getV2ProjectUrl()}member-groups/`;
export const getV3AlertsPermissionsUrl = () => `${getV3ProjectUrl()}alerts/permissions/`;
export const getV3AlertsUnitsUrl = () => `${getV3ProjectUrl()}alerts/units/`;
export const getV3AlertsUrl = id => `${getV3ProjectUrl()}alerts${id ? `/${id}` : ""}/`;
export const getV3CardAlertsUrl = id => `${getV3ProjectUrl()}card-alerts${id ? `/${id}` : ""}/`;
export const getV3AlertsLogsUrl = id => `${getV3ProjectUrl()}alerts/${id}/logs/`;
export const getV3CardAlertsLogsUrl = id => `${getV3ProjectUrl()}card-alerts/${id}/logs/`;

// 获取 zaichi activeKey
export const getZaiChiActiveKeyUrl = () => `${getZaiChiUrl()}api/v1/key/`;

// 获取 summary 总览
export const getModelSummaryOverviewFullUrlMA = (id, uid) => `${getV2CanvasModelFullUrlMA(id, uid)}overview/`;
// 获取 summary 时间维度统计，维度统计，指标统计
export const getModelSummaryFullUrlMA = (id, uid) => `${getV2CanvasModelFullUrlMA(id, uid)}summary/`;
// 刷新概览数据
export const getRefreshModelSummaryCacheFullUrlMA = (id, uid) => `${getV2CanvasModelFullUrlMA(id, uid)}refresh-summary/`;

// 获取主题颜色列表
export const getColorSchemesUrl = () => `${getV3ProjectUrl()}color-schemes/`;

// 审计日志记录
export const getV3AuditTrackPointUrl = () => `${getV3ProjectUrl()}audit/`;

// 根据问题获取大语言模型答案
export const getV3AskChatUrl = () => `${getV3ProjectUrl()}chat/`;

// 获取其他工作区答案
export const getV3AskRecentUrl = () => `${getV3ProjectUrl()}ask-recent-project/`;

// ********************* 报告相关 *************
export const getV3PinboardUrl = (uid, id) =>
  `${id ? getV3ProjectFullUrl(id) : getV3ProjectUrl()}pinboards/${uid ? `${encodeURI(uid)}/` : ""}`;
export const getV3NewPinboardsFullUrl = key => `${getV3ProjectUrl()}pinboards/${key}/`;
export const getV3PinboardCardFullUrl = (id, uid) => `${getV3ProjectFullUrl(id)}pinboards/${encodeURI(uid)}/card/`;
// 报告标签
export const getV3PinboardTagUrl = id => `${getV3ProjectUrl()}pinboard-tags${id ? `/${id}` : ""}/`;
// 获取报告图片上传 url
export const getV3PinboardImageUploadFullUrl = (projectId, pinboardUid) =>
  `${getV3ProjectFullUrl(projectId)}pinboards/${pinboardUid}/presigned/`;
// 获取报告关注状态
export const getPinboardStarUrl = uid => `${getV3ProjectUrl()}pinboards/${encodeURI(uid)}/star/`;
// 获取报告锁
export const getPinboardLockUrl = uid => `${getV3ProjectUrl()}pinboards/${encodeURI(uid)}/acquire-lock/`;
// 释放报告锁
export const getPinboardUnLockUrl = uid => `${getV3ProjectUrl()}pinboards/${encodeURI(uid)}/release-lock/`;
export const getPinboardStatUrl = uid => `${getV3ProjectUrl()}pinboards/${encodeURI(uid)}/stat/`;
