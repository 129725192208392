export default {
  addTaskModalTitle: "添加同步任务",
  addTaskTip: "系统将在设定的时间自动进行关键词同步任务",
  confirmBtnText: "确认",
  simpleCycle: "简单循环",
  complexCycle: "复杂循环",
  dayCycle: "按日期循环",
  weekCycle: "按星期循环",
  selectMonthPrefix: "每",
  monthSuffix: "月",
  daySuffix: "日",
  hourSuffix: "时",
  minuteSuffix: "分",
};
