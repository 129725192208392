import React from "react";

const PinboardFilterTagIcon = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09161 4.08191C2.25485 3.72723 2.60958 3.5 3.00001 3.5H21C21.3904 3.5 21.7452 3.72723 21.9084 4.08191C22.0717 4.43658 22.0135 4.85382 21.7596 5.15039L14.8 13.2785V21C14.8 21.3457 14.6215 21.6669 14.3278 21.8493C14.0342 22.0318 13.6672 22.0497 13.3572 21.8966L9.75723 20.1189C9.41602 19.9503 9.20001 19.6028 9.20001 19.2222V13.2785L2.24042 5.15039C1.98648 4.85382 1.92837 4.43658 2.09161 4.08191ZM5.17274 5.5L10.9596 12.2585C11.1147 12.4397 11.2 12.6704 11.2 12.9089V18.6008L12.8 19.3909V12.9089C12.8 12.6704 12.8853 12.4397 13.0404 12.2585L18.8273 5.5H5.17274Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default PinboardFilterTagIcon;
