import React from "react";

const CardFolder = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M14.8843 3.11485H11.9413C11.2081 3.11969 10.512 2.79355 10.0474 2.22751L9.0782 0.887624C8.62137 0.316613 7.9253 -0.0110553 7.19321 0.000284957H5.11261C1.37819 0.000284957 1.05022e-05 2.19201 1.05022e-05 5.91884V9.94736C-0.00463617 10.3904 19.9956 10.3898 19.9969 9.94736V8.77607C20.0147 5.04924 18.6721 3.11485 14.8843 3.11485Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8322 4.54341C19.1521 4.91749 19.3993 5.34781 19.5613 5.81231C19.8798 6.76699 20.0273 7.77024 19.9969 8.77602V14.0291C19.9957 14.4715 19.963 14.9133 19.8991 15.3512C19.7775 16.1239 19.5057 16.8655 19.0989 17.5341C18.9119 17.857 18.6849 18.1551 18.4231 18.4214C17.2383 19.5088 15.665 20.0748 14.0574 19.992H5.93063C4.32051 20.0742 2.74463 19.5084 1.55602 18.4214C1.29742 18.1546 1.07338 17.8565 0.889164 17.5341C0.484766 16.8659 0.218706 16.1237 0.106715 15.3512C0.0355047 14.9141 -0.000177434 14.4719 1.62015e-05 14.0291V8.77602C-0.000157189 8.3373 0.0235885 7.8989 0.0711489 7.46276C0.0811502 7.38624 0.0961512 7.31097 0.110997 7.23647C0.135746 7.11228 0.160064 6.99026 0.160064 6.86824C0.250322 6.34192 0.414974 5.83104 0.649095 5.35089C1.34263 3.86904 2.76526 3.1148 5.09483 3.1148H14.8754C16.1802 3.01389 17.4753 3.40668 18.5032 4.2151C18.6215 4.31548 18.7316 4.42528 18.8322 4.54341ZM4.97035 13.541H15.0355H15.0533C15.2741 13.5506 15.4896 13.4716 15.6517 13.3215C15.8137 13.1715 15.9088 12.9629 15.9157 12.7424C15.9282 12.5486 15.8644 12.3576 15.7379 12.21C15.5924 12.0117 15.3618 11.8934 15.1155 11.8906H4.97035C4.51366 11.8906 4.14344 12.26 4.14344 12.7158C4.14344 13.1716 4.51366 13.541 4.97035 13.541Z"
      fill="currentColor"
    />
  </svg>
);

export default CardFolder;
