export default {
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  /* 起点 */
  preRuleStartNodeLabel: "用户搜索的问题",
  ruleStartNodeLabel: "解析后的问题",
  /* 条件 */
  deleteConditionConfirmTitle: "确认删除此条件？",
  emptyConditionNodeLabel: "条件未设置",
  conditionNodeFormerLabel: "满足",
  matchAllLatterLabel: "所有条件",
  matchAnyLatterLabel: "任一条件",
  conditionDrawerTitle: "条件设置",
  matchTypeLabel: "匹配类型",
  selectPlaceholder: "请选择",
  andLabel: "满足所有条件",
  orLabel: "满足任一条件",
  expressionsLabel: "匹配条件",
  questionLabel: "输入问题",
  dateRangeLabel: "时间范围天数",
  dimensionsLabel: "维度数量",
  dimensionsMatchLabel: "维度值数量",
  measuresLabel: "指标数量",
  timeFuncLabel: "时间聚合数量",
  timeRangeLabel: "时间范围数量",
  keywordsLabel: "关键词数量",
  orderLabel: "排序数量",
  limitLabel: "限制词数量",
  sumLabel: "指标过滤数量",
  gtLabel: "大于",
  gteLabel: "不低于",
  ltLabel: "小于",
  lteLabel: "不超过",
  eqLabel: "等于",
  neqLabel: "不等于",
  containLabel: "包含",
  notContainLabel: "不包含",
  numberInputErrMsg: "请输入数字",
  textInputErrMsg: "请输入关键词",
  deleteExpressionConfirmTitle: "确认删除此条件？",
  addExpressionBtnText: "添加条件",
  saveConditionBtnText: "保存",
  /* 操作 */
  deleteActionConfirmTitle: "确认删除此操作？",
  emptyActionNodeLabel: "操作未设置",
  actionNodeLabel: "则执行以下操作",
  actionFormerLabel: "操作",
  addLabel: "增加",
  replaceLabel: "替换",
  deleteLabel: "删除",
  addFormerText: "增加“",
  addMiddleText: "”至问题",
  addBeforeLatterText: "前",
  addAfterLatterText: "后",
  replaceFormerText: "替换“",
  replaceMiddleText: "”成“",
  replaceLatterText: "”",
  deleteFormerText: "删除",
};
