export default {
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  on: "Enable",
  off: "Prohibited",
  enableBtnText: "Enable",
  disableBtnText: "Disabled",
  editBtnText: "Edit",
  deleteBtnText: "Delete",
  enableSuccessToast: "Enable successfully",
  disableSuccessToast: "Disable successfully",
  enableConfirmTitle: "Confirm to enable this rule?",
  disableConfirmTitle: "Confirm disabling this rule?",
  deleteConfirmTitle: "Confirm to delete this rule?",
  ruleName: "Rule name",
  creator: "Creator",
  status: "Status",
  createdAt: "Creation time",
  updatedAt: "Modified",
  operations: "Settings",
  addBtnText: "Add rule",
  addSuccessToast: "Add successfully",
  addRuleModalTitle: "Add rule",
  ruleNameErrMsg: "Rule name cannot be empty",
  ruleNameInputPlaceholder: "Please enter a rule name",
  operation: "Settings",
  inputPlaceholder: "Please enter a keyword",
  preText: "Include",
  condition: "Condition",
  output: "Output",
};
