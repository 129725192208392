import React from "react";

const DimensionSvg = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8332 0.75H1.1665L5.83317 6.26833V10.0833L8.1665 11.25V6.26833L12.8332 0.75Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DimensionSvg;
