import React from "react";

const CardAlarmIcon = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 4.31004C2.25 3.58653 2.83653 3 3.56004 3H14.0404C14.7639 3 15.3504 3.58653 15.3504 4.31004V7.59685C14.786 7.34191 14.1596 7.2 13.5 7.2C11.0147 7.2 9 9.21472 9 11.7C9 12.8967 9.46712 13.9843 10.229 14.7904H3.56004C2.83653 14.7904 2.25 14.2039 2.25 13.4803V4.31004Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4739 8.61502C14.4739 8.33059 14.2433 8.10001 13.9589 8.10001H13.9427C13.6583 8.10001 13.4277 8.33059 13.4277 8.61502C12.2104 8.84512 11.2933 9.86199 11.2933 11.0819V12.6072C11.0213 12.6072 10.8008 12.829 10.8008 13.101C10.8008 13.3745 11.0225 13.5976 11.296 13.5976H16.6056C16.8791 13.5976 17.1008 13.3745 17.1008 13.101C17.1008 12.829 16.8803 12.6072 16.6082 12.6072V11.0819C16.6082 9.86199 15.6911 8.84512 14.4739 8.61502ZM12.3395 11.0819C12.3395 10.2395 13.0609 9.55665 13.9508 9.55665C14.8406 9.55665 15.562 10.2395 15.562 11.0819V12.6072H12.3395V11.0819Z"
      fill="black"
    />
    <path
      d="M14.7054 14.8048C14.7054 14.5313 14.4837 14.3096 14.2102 14.3096H13.7126C13.4391 14.3096 13.2174 14.5313 13.2174 14.8048C13.2174 15.0783 13.4391 15.3 13.7126 15.3H14.2102C14.4837 15.3 14.7054 15.0783 14.7054 14.8048Z"
      fill="black"
    />
    <rect x="4.5" y="6.84" width="4.5" height="1.26" rx="0.63" fill="black" />
    <rect x="4.5" y="9.89999" width="2.7" height="1.26" rx="0.63" fill="black" />
  </svg>
);

export default CardAlarmIcon;
