import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`pages.Manage.Rules.${id}`);

export default class RuleType {
  static PRE_RULE = 1;

  static RULE = 2;

  static getText = t => {
    switch (t) {
      case this.PRE_RULE:
        return getTextById("preRuleSettings");
      case this.RULE:
        return getTextById("ruleSettings");
      default:
        return "";
    }
  };
}
