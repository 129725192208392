export default {
  cancel: "Cancel",
  save: "Save",
  confirm: "Confirm",
  saveFormulaOkText: "Trial Calculation And Save",
  add: "Add",
  edit: "Edit",
  addSuccessToast: "Add successfully",
  dimension: "Dimensions",
  editSuccessToast: "Edit successfully",
  validateFailToast: "Error filling data, please check",
  contentKeywordDivider: "Dimension related",
  drillDimensionDivider: "Drill down dimension",
  parentDimensionLabel: "Parent dimension",
  parentDimensionHelp: "Show drill down dimensions, you can set drill down dimension relations in settings",
  none: "None",
  subDimensionsLabel: "Child dimension",
  subDimensionsNeedValidValuesErrorText: "Please set valid values for child dimension",
  keywordFiltersNeedValidValuesErrorText:
    "When the limit of searchable dimension values is turned on, at least one condition must be added.",
  measure: "Measures",
  derivativeMeasure: "Derivative Measure",
  formatType: "Display Format",
  formula: "Calculation Formula",
  formulaExtra: "Supports basic operations (addition, subtraction, multiplication, division) between indicators of existing models",
  formulaPlaceholder: "Please click the edit button on the right to create or modify the formula",
  editFormulaContentTitle: "Edit Formula",
  formulaTextareaPlaceholder: "Please click on the indicators on the left and add operators and numbers to compose the formula",
  formulaSearchInputPlaceholder: "Please enter keywords",
  formulaTip1: "Supports formulas that contain both indicators and cumulative indicators",
  formulaTip2: "Supports formulas that contain indicators from different models, but not more than 3 models",
  formulaTip3: "Supports simple arithmetic operations (+, -, *, /, ())",
  formulaTip4: "Supports operations with numbers (including floating point numbers)",
  formulaTip5: "eg1. Store Cost + Purchase Cost",
  formulaTip6: "eg2. Turnover / (0.9 * Target Turnover)",
  loadMore: "Click to Load More",
  timeDimension: "Time Dimension",
  otherSettingsDivider: "Other settings",
  timeAggregationMethodLabel: "Time aggregation",
  checkedItemNoContentErrorText: "There are checked items that have not been filled in, please fill them in.",
  editTimeDimension: "Edit time dimensions",
  addTimeDimension: "Add time dimensions",
  userNameLabel: "User Name",
  userNameRequiredMessage: "Enter user name",
  userNameInputPlaceholder: "Enter user name",
  saveSuccessToast: "Saved successfully",
  passwordLabel: "Password",
  modify: "Modify",
  timeDataIntelligentAnalysisLabel: "Auto ML time series analysis",
  autoDisplayDimensionYoyAnalysisLabel: "Set YoY as default",
  autoDisplayDimensionRingAnalysisLabel: "Set Previous Period as default",
  insightsTypeSwitch: "Configure which auto insights to display",
  followWorkspaceSettings: "Follow workspace settings",
  alwaysOpen: "Always open",
  alwaysClose: "Always close",
  smartTip: "AI tips",
  differentTipBoxOfSearchTip: "Auto switch search suggestions based on your behavior",
  defaultFieldTip: "Default field suggestion",
  defaultQuestionTip: "Default question suggestion",
  switchToQuestionTipManuallyTip: "Show keyword suggestion at all times, switch to question suggestion manually",
  switchToFieldTipManuallyTip: "Show question suggestion at all times, switch to keyword suggestion manually",
  saveConfigBtnText: "Save settings",
  unitLabel: "Unit",
  unitNameInputPlaceholder: "Unit name",
  currencyUnitSelectPlaceholder: "Currency",
  currencyLabel: "Currency",
  customLabel: "Custom",
  lastSearch: "and finally searches",
  replaceWith: "Replaced with",
  formerNoViewDimensionAuthText: "Permission denied for dimension",
  latterNoViewAuthText: ".",
  formerNoViewMeasureAuthText: "Permission denied for measure",
  missMeasure: "The search is lack of effective indicators",
  missDimensionOrTimeDimension: "The search is missing a time range",
  timeRange: "Time range",
  timeDimensionCombination: "Time dimension combination",
  invalid: "Invalid.",
  period: ". ",
  resultPunctuation1: ". ",
  resultPunctuation2: ", ",
  supply: "The system replenishes",
  tryTip: "You can try",
  viewDetails: "View details",
  searchTips: " search suggestions",
  manualInputLabel: "Input manually",
  selectPlaceholder: "Search or create keyword",
  invalidSelectPlaceholder: "Invalid, please reselect or create a new one.",
  noMoreSubDimensions: "No more dimensions",
  exploreBtnText: "Search",
  thisPeriod: "Current period",
  create: "New",
  formerNegativeRelatedText: "And",
  latterNegativeRelatedText: "Negative relationship",
  applyBtnText: "Apply",
  dimensionTextAreaPlaceholder: "Please separate different keywords with line breaks.",
  configureOptionsModalTitle: "Configure optional items",
  passwordRequiredMessage: "Enter your password",
  passwordCharLimitMessage: "The password must contain uppercase and lowercase letters, as well as special characters",
  passwordNoSpaceRuleMessage: "Password cannot contain spaces",
  checkMinLen: "Password must longer than 8 characters",
  checkMaxLen: "Password must shorter than 32 characters",
  dataLoadingText: "Fetching data...",
  customOtherLine: "Other Settings",
  defaultTimeFunc: "Time Aggregation Method",
  defaultTimeFuncErr: "Content is not filled in for the selected item(s), please fill in",
};
