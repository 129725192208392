import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.MessageStatusTab.${id}`);
export default class MessageStatusTab {
  //  全部
  static ALL = "1";

  // 未处理
  static UN_PROCESSED = "2";

  static getText = status => {
    if (status === this.ALL) return getText("all");
    if (status === this.UN_PROCESSED) return getText("untreated");
  };
}
