export default class ErrorCode {
  // 卡片没有权限
  static NO_AUTH_CODE = "10401";
  // 卡片唯一指标或维度失效
  static NO_MEASURE_OR_DIMENSION_CODE = "10404";
  // 卡片没有命中模型
  static NO_MODEL_CODE = "10405";

  // 许可证已失效
  static LICENCE_EXPIRED = "11000";
  //可分配的计算资源不足，请购买更多核心或稍后再试
  static LACK_OF_CORE = "11001";
  //该等级配额已用完，请联系销售增购
  static QUOTA_EXCEEDED = "11012";
}
