import React from "react";

const Share = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6666 6.16667C12.403 6.16667 13 5.56971 13 4.83333C13 4.09695 12.403 3.5 11.6666 3.5C10.9303 3.5 10.3333 4.09695 10.3333 4.83333C10.3333 5.56971 10.9303 6.16667 11.6666 6.16667ZM11.6666 7.16667C12.9553 7.16667 14 6.122 14 4.83333C14 3.54467 12.9553 2.5 11.6666 2.5C10.378 2.5 9.3333 3.54467 9.3333 4.83333C9.3333 4.97659 9.34621 5.11683 9.37093 5.25296L6.20017 6.76653C5.77447 6.19979 5.09671 5.8332 4.33333 5.8332C3.04467 5.8332 2 6.87787 2 8.16654C2 9.4552 3.04467 10.4999 4.33333 10.4999C5.04205 10.4999 5.67696 10.1839 6.1049 9.68516L9.41732 11.5606C9.36256 11.7588 9.3333 11.9677 9.3333 12.1833C9.3333 13.472 10.378 14.5167 11.6666 14.5167C12.9553 14.5167 14 13.472 14 12.1833C14 10.8947 12.9553 9.85 11.6666 9.85C10.9524 9.85 10.3132 10.1709 9.88516 10.6763L6.57849 8.80414C6.63593 8.60148 6.66667 8.3876 6.66667 8.16654C6.66667 7.99846 6.6489 7.83454 6.61513 7.67654L9.7582 6.17618C10.1805 6.77531 10.8779 7.16667 11.6666 7.16667ZM11.6666 13.5167C12.403 13.5167 13 12.9197 13 12.1833C13 11.447 12.403 10.85 11.6666 10.85C10.9303 10.85 10.3333 11.447 10.3333 12.1833C10.3333 12.9197 10.9303 13.5167 11.6666 13.5167ZM5.66667 8.16654C5.66667 8.90292 5.06971 9.49987 4.33333 9.49987C3.59695 9.49987 3 8.90292 3 8.16654C3 7.43016 3.59695 6.8332 4.33333 6.8332C5.06971 6.8332 5.66667 7.43016 5.66667 8.16654Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default Share;
