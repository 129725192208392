enum Licence {
  STATUS_INPUT = "input", // 上传许可证/输入激活码

  STATUS_SHOW = "show", // 输入激活码后展示许可证信息

  STATUS_SUCCESS = "success", // 激活成功

  CONNECT_INIT = "loading", // 正在连接

  CONNECT_SUCCESS = "success", // 能连外网 显示激活码

  CONNECT_FAIL = "fail", // 不能连外网 显示上传文件
}

export default Licence;
