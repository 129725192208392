export default {
  canOnlyConnectFactTables: "维度表仅能与事实表相连",
  saveSuccessToast: "保存成功",
  onlyOneFactTableIsAllowed: "星型模型仅允许一张事实表作为中心，操作区内已存在一张事实表。",
  needFactTableAsTheCenter: "星型模型需一张事实表作为中心，您可先添加1张事实表，或将其中1张表设置为事实表。",
  dimensionTable: "维度表",
  findDimensionTable: "查找维度表",
  canBeReusedInModel: "您可在该工作区的模型中复用这些已配置好的表格。",
  learnMore: "了解更多",
  loading: "加载中...",
  loadMore: "加载更多",
  dataTableWasNotFound: "未找到对应数据表，请尝试其他关键词。",
  dataTable: "数据表",
  findDataTable: "查找数据表",
  customTable: "自定义表",
  autoJoining: "自动关联中",
};
