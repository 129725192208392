// $ 为参数占位符
export default {
  // 通用错误码, 前缀为 00
  "0010000": "Unknown error",
  "0010001": "Workspace does not exist, user has other workspaces",
  "0010002": "Workspace does not exist, user has no other workspaces",
  "0010004": "Insufficient permissions for user in workspace",
  "0010005": "Incorrect phone number",
  "0010006": "Incorrect email",
  "0010007":
    "Incorrect login format: login should be a combination of numbers and letters, cannot be pure numbers, cannot contain @ special characters",
  "0010008": "Incorrect question parameter type",
  "0010009": "Question parameter cannot be empty",
  "0010010": "Incorrect offset and limit",
  "0020000": "Unknown error occurred",
  "0020001": "Parameter not passed",
  "0020002": "Parameter cannot be empty",
  "0020003": "Parameter value must be at least $",
  "0020004": "Parameter value must be at most $",
  "0020005": "Parameter cannot contain URI reserved characters",
  "0020006": "Parameter length cannot be less than $",
  "0020007": "Parameter length cannot exceed $",
  "0020008": "Parameter length must be $",
  "0020009": "Parameter must be a list type",
  "0020010": "Parameter is incorrect",

  // 异步任务错误码, 前缀为 01
  "0110000": "Unknown error",
  "0110001": "Request too frequent, please try again later",
  "0110002": "Disallowed task type",
  "0110003": "Unsupported asynchronous task",
  "0120000": "Unknown error occurred with $",
  "0120001": "Asynchronous task [$] does not exist",
  "0120002": "Failed to update asynchronous task [$]",

  // 登录认证错误码, 前缀为 02
  "0210000": "Unknown error",
  "0210001": "User limit exceeded, system cannot be used, please contact administrator",
  "0210002": "Phone number does not exist",
  "0210003": "Email does not exist",
  "0210004": "Login name does not exist",
  "0210005": "Login name and password do not match",
  "0210006": "Invalid verification code",
  "0210007": "Incorrect verification code",
  "0210008": "Login failed",
  "0210009": "Please enter a correct phone number or email",
  "0210010": "Email and phone number not set, unable to perform login verification",
  "0210011": "User does not exist",
  "0220000": "$Unknown error occurred",

  // 卡片相关错误码, 前缀为 03
  "0310000": "Unknown error",
  "0310001": "Link error",
  "0310002": "Card sharing does not exist",
  "0310003": "Card sharing has expired",
  "0310004": "Card already exists",
  "0310005": "You do not have permission to access the card",
  "0310006": "Card does not exist",
  "0310007": "You do not have card permissions",
  "0310008": "You are not the creator of this card, non-creators cannot modify the card",
  "0320000": "$Unknown error occurred",

  // 消息中心相关错误码, 前缀为 04
  "0410000": "Unknown error",
  "0410001": "Scroll and anchor must exist at the same time",
  "0410002": "Message does not exist at anchor point",
  "0410003": "Message does not exist",
  "0410004": "Unapproved messages cannot be deleted",
  "0420000": "Unknown error occurred with $",

  // 报告相关错误码, 前缀为 05
  "0510000": "Unknown error",
  "0510001": "Report limit reached, please contact system administrator to increase",
  "0510003": "Layout data error, please re-edit",
  "0510004": "Report does not exist",
  "0510005": "User does not exist",
  "0510006": "Message does not exist",
  "0510007": "You do not have permission to read the report",
  "0510008": "You do not have permission to edit the report",
  "0510009": "You are not in this workspace and cannot apply for report permission",
  "0510010": "This report has not enabled link sharing",
  "0510011": "There is no corresponding subcategory under this report category",
  "0510012": "Your account does not have permission to create a copy, please contact the administrator",
  "0510013": "Search account, not allowed to create report copies",
  "0510014": "Workspace does not exist",
  "0510015": "Problem is not in the report",
  "0510016": "At least one of the question and uid parameters must be passed",
  "0510017": "Error occurred when creating report copy",
  "0510018": "You do not have sufficient permissions",
  "0510019": "You have not configured the service URL",
  "0510020": "You do not have permission to share the report",
  "0510021": "You do not have permission to transfer ownership",
  "0510022": "Workspace does not exist",
  "0510023": "You do not have approval permission",
  "0510024": "User group does not exist",
  "0510025": "View report application already exists",
  "0510026": "Report approval status error",
  "0520000": "$Unknown error occurred",
  "0520001": "The approval message [$] request did not match the corresponding request",

  // 贡献度相关错误码, 前缀为06
  "0610000": "Unknown error",
  "0610001": "The question does not include a time dimension, please try asking in a different way",
  "0620000": "$ encountered an unknown error",

  // SQL 查询数据相关错误码, 前缀为07
  "0710000": "Unknown error",
  "0710001": "Workspace has not configured data source connection",
  "0710002": "Database address Host has not been configured",
  "0720000": "$Unknown error occurred",

  // 模型 summary 查询错误
  1210001: "The workspace does not exist",
  1220000: "Model does not exist",
  1221000: "Model overview acquisition failed",
};
