export default {
  1: "Unsupported method",
  2: "Unsupported parameter",
  3: "File format error: no correct end mark detected",
  6: "Unparseable text",
  7: "Wrong number of fields",
  8: "Field does not exist",
  9: "Field size does not match",
  10: "Field not found in block",
  12: "Parameter overflow",
  15: "Duplicated field",
  16: "Field not defined in table",
  18: "Cannot insert elements into constant field",
  20: "Field count does not match",
  26: "Unparseable reference string",
  31: "Cannot read compressed data",
  32: "Attempt to read content after end mark",
  33: "Cannot read all data",
  34: "Too many parameters",
  35: "Too few parameters",
  36: "Parameter does not exist",
  38: "Unparseable time",
  43: "Illegal parameter type",
  44: "Illegal field",
  45: "Illegal number of result fields",
  46: "Unknown function",
  49: "Logical error",
  50: "Unknown type",
  51: "Field query list is empty",
  52: "Duplicate field query",
  57: "Table already exists",
  59: "Illegal filter field type",
  60: "Unknown table",
  62: "Syntax error",
  66: "Not a field",
  71: "Cache is full, unable to write",
  72: "Unparseable number",
  73: "Unknown format",
  76: "Cannot open file",
  77: "Cannot close file",
  79: "Incorrect file name",
  81: "Unknown database",
  82: "Database already exists",
  83: "Directory does not exist",
  85: "Incorrect input format",
  86: "Remote read/write service error",
  104: "Cache space too small",
  105: "Unable to read history information",
  107: "File does not exist",
  117: "Incorrect data",
  120: "Cannot insert values of different sizes into tuple",
  122: "Incompatible fields",
  126: "Unable to get returned type",
  131: "String too long",
  140: "Missing all requested fields",
  145: "Unknown pattern overflow",
  151: "Field index out of range",
  158: "Too many rows",
  161: "Too many fields",
  164: "Permission read-only, cannot write",
  165: "Too many temporary fields",
  172: "Cannot create directory",
  173: "Cannot allocate memory",
  191: "Size setting exceeds limit",
  192: "Unknown user",
  193: "Incorrect password",
  203: "Link not released",
  209: "Network connection timed out",
  210: "Network error",
  217: "Client link port error",
  218: "Table has been destroyed",
  219: "Database not empty",
  230: "Data unit not found",
  233: "Invalid data unit name",
  238: "Format version too old",
  241: "Exceeded memory limit",
  242: "Table is read-only, cannot write",
  253: "Duplicate replica",
  263: "Uncompilable code",
  268: "Processor number error",
  269: "Stuck in infinite loop",
  279: "All connection attempts have failed",
  290: "Exceeded limit",
  291: "Database access denied",
  293: "MONGODB authentication failed",
  301: "Unable to create child process",
  303: "Unable to find",
  318: "Invalid configuration parameter",
  321: "Numeric value out of range for data type",
  341: "Unfinished",
  347: "Unable to load configuration",
  349: "Cannot insert NULL value into regular field",
  351: "Ambiguous table name",
  352: "Ambiguous field name",
  363: "Unable to create IO buffer",
  377: "Syntax is illegal for this data type",
  389: "Duplicate insertion",
  391: "External dependency error",
  1002: "Unknown exception",
};
