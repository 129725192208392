export default {
  editModalTitle: "Edit Date Label",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  datePickerLabel: "Date",
  settingsLabel: "Label settings",
  addBtnText: "New",
  cancelAddBtnText: "Delete add",
  tagName: "Tag name",
  selectTagPlaceholder: "Select Tag",
  addTagBtnText: "Add",
  invalidNameErrorToast: "Please check if the tag name format is correct",
  repeatedNameErrorToast: "Label name duplicate",
  cycleType: "Whether to loop",
  noCycle: "No cycle",
  monthCycle: "Month Cycle",
  yearCycle: "Year Cycle",
  timeRange: "Time range",
  yearInputPlaceholder: "Integer up to 50",
  monthInputPlaceholder: "Integer up to 600",
  yearSuffix: "Year",
  monthSuffix: "Month",
  operations: "Settings",
  deleteBtnText: "Delete",
  deleteConfirmTitle:
    "Only delete this label under this date, it will not affect the labels of other dates in the cycle. Confirm to delete this label?",
  deleteCycleBtnText: "Delete loop",
  deleteCycleConfirmTitle:
    "Under this label, all typed labels within the relevant cycle of this date will be deleted. Confirm to delete the cycle?",
  /* 自定义标签弹窗补充部分 */
  addCustomTagModalTitle: "Add custom tags",
  editCustomTagModalTitle: "Edit tag",
  tagNameInputPlaceholder: "Please enter the tag name",
  customTagNameErrorToast: "Please check tag name format",
  timeRangeErrorToast: "Please check if the time range is correct",
  startDate: "Start date",
  customTagSettingsLabel: "Label rules",
};
