export default {
  noAuthPrompt: "暂时没有查看所有字段数据的权限，如有需要请联系管理员开启。",
  searchSuggestions: "搜索建议",
  suggestLabel: "试试：",
  relevantResults: "关联结果",
  forecasting: "预测数据",
  analysis: "分析结果",
  relevantResultsPrompt: "以下内容为与当前搜索强相关的数据或图表",
  feedbackBtnText: "校准反馈",
  noDataPromptFormer: "匹配不到数据",
  noDataPromptLatter: "可进行校准反馈，或查看下方搜索建议获得灵感",
  noDataPrompt: "您的输入匹配不到数据，可尝试输入提示菜单内的字段，或查看下方搜索建议获得灵感。",
  feedbackTourBtn: "没有搜到想要的数据？可以点击这个按钮反馈你认为这个短语应该匹配到的维度和指标。",
  checkBtn: "立即查看",
  skipBtn: "好的",
};
