export default {
  notSet: "Not set",
  carefullyReplaceDataSources: "Please replace data sources with caution",
  allModelsWillFail:
    "All models in the workspace will fail after replacement, which may cause difficulty to use for a short time and inaccurate data. Are you sure you still want to replace the data source?",
  dataCacheRefreshSuccessful: "Data cache refresh successfully",
  excelCarefullyReplaceDataSources: "This database will be deleted after switching",
  excelAllModelsWillFail1: "All current data tables will be deleted!",
  excelAllModelsWillFail2: "Switching back to the original data source will not retrieve them!",
  excelAllModelsWillFail3: "Please proceed with caution!",
  excelSecondCarefullyReplaceDataSources: "Confirm switching to another data source",
  excelSecondAllModelsWillFail1: "All uploaded data tables in the current workspace will be deleted",
  excelSecondAllModelsWillFail2: "Switching back to the original data source will not retrieve them",
  excelSecondAllModelsWillFail3: "Please proceed with caution!",
  dataSource: "Connect Detail",
  edit: "Edit",
  refreshCacheData: "Refresh cache data",
  confirmPopExcel: "Enter switch",
  confirmSecondPopExcel: "Confirm switch",
  confirm: "Confirm",
  cancel: "Cancel",
  replaceDataSource: "Change data source",
  databaseType: "Database type",
  collocationMethod: "Method",
  dataSourceUrl: "Data Source URL",
  dataSourceMessage: "Please enter the data source URL",
  detailedConfiguration: "Detailed Configuration",
  host: "Server (Host) ",
  hostMessage: "Please enter the server address",
  hostPlaceholder: "Database server address, for example 123.4.56.789 or database-address.com ",
  port: "Port",
  portMessage: "Please enter a port",
  user: "User",
  userMessage: "Please enter username",
  password: "Password",
  database: "Database",
  databaseMessage: "Please enter the database",
  SIDMessage: "Please enter SID",
  server: "Instance name (Server) ",
  serverMessage: "Please enter an instance name",
  protocol: "Protocol",
  dbLocale: "Service character set (DB_LOCALE) ",
  clientLocale: "Client Character Set (CLIENT_LOCALE) ",
  connectionType: "Connection type",
  connectionStatus: "Connection status",
  connected: "Connected",
  connectionFailed: "Connection failed",
  authMechanism: "Authentication (Auth_mechanism) ",
  referenceDocumentsIs: "Reference doc:",
  dataSourceConfigurationDescription: "Data source configuration description",
  fileStyleTemplate: "Help Documents",
  suggestAdjustingTheModel: "It is recommended to adjust the model after data source switching",
  mayCauseModelFailure:
    "Data source replacement may cause tables in the model to fail, invalidating the entire model. It is recommended to adjust the model accordingly.",
  goToConfigurationModel: "Go to configure model",
  dataFileFormatError: "Data file format error",
  uploadedFileOnlySupports: "Currently uploaded files only support .csv .xls .xlsx format",
  exceededUploadLimit: "Upload limit exceeded",
  fileCapacityExceedsTheMaximumLimit: "The file size exceeds the upper limit of 50M.",
  dataTableUploadError: "Data table upload error",
  uploadFailed: "Upload failed",
  dataTableName: "Data table name",
  recentOperator: "Recent operator",
  updatedAt: "Modified",
  operation: "Settings",
  update: "Update",
  areYouSureToDeleteThisDataTable: "Are you sure to delete this data table?",
  appliedToTheModel:
    "This data table has been applied to the model. After deletion, the tables in the corresponding model will also be deleted, which may cause the model to become invalid.",
  remove: "Remove",
  pleaseEnterTheTableName: "Please enter the name of the table you want to find",
  uploadFiles: "Upload files",
  dataSourceSettings: "Data Source Settings",
  pleaseSelectDataSource:
    "Please select the data source you want to connect to, you can upload files or directly click the database type you want to connect to set the connection",
  none: "None",
  databaseConnectionSuccessful: "Database connection successful",
  noModification: "No change",
  modify: "Modify",
  fillOutTheConfigurationDocument: "Please fill in the correct configuration doc according to the selected database type",
  referenceDocuments: "Reference doc",
  currentDatabaseType: "Current database type:",
  pleaseInput: "Please enter",
  optional: "Optional",
  required: "Required",
  optionalRemarks: "Optional, default value will be used if not set",
  save: "Save",
  successfullySet: "Set successfully",
  dataQuerySettings: "Data query settings",
  setUp: "Setup",
  maximumQueryTime: "Query Timeout",
  maximumQueryTimeExtra: "The maximum query time for the database, the actual query will be canceled after this time, up to 12 hours",
  maximumQueryTimePlaceholder: "Please enter an integer between 1 and 43200",
  second: " Second",
  authentication: "Authentication method",
  username: "Username",
  clusterIdentifier: "Cluster identifier",
  accessKey: "IAM Secret Access Key",
  secretAccessKey: "IAM Secret Access Key",
  sessionToken: "Secret Access Key when using temporary credentials",
  region: "AWS Region where the cluster is located",
  clusterIdentifierMessage: "Please enter the cluster identifier",
  regionMessage: "AWS Region where the cluster is located",
  advancedSetting: "Advanced settings",
  advancedSettingTooltip: "Please fill in the setting information in json format",
  selfDBTitle: "Self-owned Data Storage Engine",
  selfDBDetail: "Data service provided by Arctic, allowing for quick setup of your own database, convenient and easy management",
  selfDBDes1: "Supports direct upload of CSV/EXCEL files and management of database tables within this system",
  selfDBDes2: "Supports direct data management on the database side",
  recommendDataSource: "Recommend",
  recommendDataSourceDetail: "Supports use following databases. For any questions, please refer to",
  limitedSupportDataSource: "Limited Support",
  limitedSupportDataSourceDetail:
    "The following databases provide limited support. Some versions or query scenarios of the databases are not fully adapted or tested and are only for trial use. If you need complete support, please contact us.",
  dbLabel: "File upload capacity",
  dbRowLabel: "File Upload Lines",
  dbLabelUsed: "Capacity Used",
  dbRowLabelUsed: "Number of Stored Data Rows",
  tenThousandUnit: "Ten thousand lines",
  rowUnit: "Rows",
  assetManage: "Asset Management",
  copyLinkSuccessToast: "Copy Successful",
  copyLinkFailToast: "Copy Failed",
  watchPassword: "View Password",
  copyText: "Copy",
  resetPasswordModalTitle: "Reset Database Password",
  resetPasswordModalContent: "The password for the current database will be reset",
  resetPasswordModalOkBtnText: "Confirm Reset",
  resetPasswordSuccessToast: "Password Reset Successful",
  checkPasswordBtnText: "Check",
  resetPasswordBtnText: "Reset",
  setExcelConfigTitle: "Configure Data Storage Engine",
  autoConfigExcelProgressTitle: "Automatically Configure Data Connection Information",
  connectInternalDBError1: "Current database connection failed, unable to query resource usage",
  connectInternalDBError2: "Please contact operations for assistance",
  formatMessage: "Please enter a string that conforms to the JSON structure",
};
