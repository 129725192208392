export default {
  sumPrefix: "共",
  sumSuffix: "项",
  unValidTip: "关键词失效，系统词库中无此关键词",
  columnOperation: "操作",
  editBtnText: "编辑",
  deletePopoverTitle: "确认删除该关键词的别名？",
  deletePopoverDes: "关键词不会被删除",
  deleteBtnText: "删除",
  deleteSuccessToast: "删除成功",
  uploadSuccessToast: "有别名或同义词修改，已自动启动系统词典更新任务",
  uploadFailNote: "数据上传错误",
  clearSuccessToast: "清空别名库成功",
  clearConfirmTitle: "确认清空别名库？该操作无法撤销",
  clearConfirmSuggest: "建议先全量下载进行备份",
  downloadAllBtnText: "全量下载",
  cancelBtnText: "取消",
  okBtnText: "确认",
  searchPlaceholder: "请输入想搜索的信息",
  batchUploadBtnText: "批量上传",
  clearBtnText: "清空",
  lastUpdateTime: "最近更新到词典的时间：",
  downloadFileName: "别名模板",
  downloadCSVFileName: "别名列表",
  editSuccessToast: "修改成功",
  batchUploadSuccessToast: "批量上传成功",
};
