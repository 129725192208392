import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.ModelErrorCode.${id}`);
export default class ModelErrorCode {
  // 前端校验的报错字段
  static MODEL_NEED_A_FACT_TABLE = "modelNeedAFactTable";

  static MODEL_NEED_CONFIGS = "modelNeedConfigs";

  static MODEL_FACT_TABLE_NEED_A_TIME_DIMENSION = "modelFactTableNeedATimeDimension";

  static MODEL_FACT_TABLE_NEED_A_MEASURE = "modelFactTableNeedAMeasure";

  static FACT_TABLE_AND_DIMENSION_TABLE_NEED_LINE = "factTableAndDimensionTableNeedLine";

  static CUSTOM_SQL_NEED_FIELD_TYPE = "customSqlNeedFieldType";

  static NEED_SQL = "needSql";

  static CONNECTED_DIMENSION_TABLE_NEED_A_CONFIG = "connectedDimensionTableNeedAConfig";

  static NEED_NAMES = "needNames";

  static NEED_CHOICE_JSON = "needChoiceJson";

  static DEFAULT_ROW_COLUMN_ERROR_MESSAGE = "defaultRowColumnErrorMessage";

  static getText = v => {
    if (v === this.DEFAULT_ROW_COLUMN_ERROR_MESSAGE) return getText(this.DEFAULT_ROW_COLUMN_ERROR_MESSAGE);
  };

  // 全局的 toast 类型的报错
  static getToastErrorText = (errCode, textArr = []) => {
    const msgs = {
      [this.MODEL_NEED_A_FACT_TABLE]: getText("modelNeedAFactTable"),
      [this.MODEL_NEED_CONFIGS]: getText("modelNeedConfigs"),
      [this.MODEL_FACT_TABLE_NEED_A_TIME_DIMENSION]: getText("modelFactTableNeedATimeDimension"),
      [this.MODEL_FACT_TABLE_NEED_A_MEASURE]: getText("modelFactTableNeedAMeasure"),
      [this.FACT_TABLE_AND_DIMENSION_TABLE_NEED_LINE]: `${getText("formerFactTableAndDimensionTableNeedLine")}${textArr[0]}${getText(
        "latterFactTableAndDimensionTableNeedLine"
      )}`,
      [this.CONNECTED_DIMENSION_TABLE_NEED_A_CONFIG]: getText("connectedDimensionTableNeedAConfig"),
      20001: getText("modelFactTableNeedATimeDimension"),
      20002: getText("modelFactTableNeedAMeasure"),
      20004: getText("20004"),
      20101: getText("20101"),
      20102: getText("20102"),
      20201: getText("20201"),
    };

    return msgs[errCode] || "";
  };

  // 列名称报错，关键词错误，后端返回 code 码，下列所有报错的前缀是 关键词 XXX + msg
  static getNameErrorMsg = (code, checkName, repeatTableName = "") => {
    const codeMsgs = {
      20103: getText("20103"),
      20104: getText("20104"),
      20105: getText("20105"),
      20106: `${getText("former20106")}${repeatTableName}${getText("latter20106")}`,
      20107: getText("20107"),
      20108: `${getText("former20108")}${repeatTableName}${getText("latter20108")}`,
      20109: `${getText("former20109")}${repeatTableName}${getText("latter20109")}`,
      20110: `${getText("former20110")}${repeatTableName}${getText("latter20110")}`,
      20111: getText("20111"),
      20112: getText("20112"),
      20113: getText("20113"),
      20114: getText("20114"),
      20115: getText("20115"),
      20116: getText("20116"),
      20117: `${getText("former20117")}${repeatTableName}${getText("latter20117")}`,
      20118: getText("20118"),
      [ModelErrorCode.NEED_NAMES]: getText("needNames"),
      20005: getText("20005"),
      20119: getText("20119"),
      20121: getText("20121"),
      20120: getText("20120"),
      20122: getText("20122"),
    };
    return codeMsgs[code] ? `${checkName}${codeMsgs[code]}` : "";
  };

  // 所有的列报错提示
  static getColumnErrorMsg = (...args) => {
    const [errCode] = args;
    const msgs = {
      [this.CUSTOM_SQL_NEED_FIELD_TYPE]: getText("customSqlNeedFieldType"),
      [this.NEED_SQL]: getText("needSql"),
      [this.NEED_CHOICE_JSON]: getText("needChoiceJson"),
      20202: getText("20202"),
    };
    return this.getNameErrorMsg(...args) || msgs[errCode] || getText(this.DEFAULT_ROW_COLUMN_ERROR_MESSAGE);
  };

  // 根据后台返回的 field 显示报错的列名称
  static getColumnNameByField = field => {
    const columnNames = {
      id: getText("id"),
      sql: getText("sql"),
      fieldType: getText("fieldType"),
      type: getText("type"),
      format: getText("format"),
      names: getText("names"),
      synonym: getText("synonym"),
    };
    return columnNames[field] || "";
  };
}
