import components from "./components";
import errorCode from "./errorCode";
import errors from "./errors";
import layouts from "./layouts";
import pages from "./pages";
import theme from "./theme";
import utils from "./utils";

export default {
  layouts,
  errors,
  pages,
  components,
  errorCode,
  theme,
  utils,
};
