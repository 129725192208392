import React from "react";

const PinboardTabStar = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9466 9.54699C11.7739 9.71433 11.6946 9.95633 11.7339 10.1937L12.3266 13.4737C12.3766 13.7517 12.2592 14.033 12.0266 14.1937C11.7986 14.3603 11.4952 14.3803 11.2466 14.247L8.2939 12.707C8.19124 12.6523 8.07723 12.623 7.96057 12.6197H7.7799C7.71724 12.629 7.6559 12.649 7.5999 12.6797L4.64657 14.227C4.50057 14.3003 4.33524 14.3263 4.17323 14.3003C3.77857 14.2257 3.51523 13.8497 3.5799 13.453L4.17323 10.173C4.21257 9.93366 4.13323 9.69033 3.96057 9.52033L1.55323 7.18699C1.3519 6.99166 1.2819 6.69833 1.3739 6.43366C1.46323 6.16966 1.69123 5.97699 1.96657 5.93366L5.2799 5.45299C5.5319 5.42699 5.75323 5.27366 5.86657 5.04699L7.32657 2.05366C7.36123 1.98699 7.4059 1.92566 7.4599 1.87366L7.5199 1.82699C7.55123 1.79233 7.58723 1.76366 7.62723 1.74033L7.6999 1.71366L7.81324 1.66699H8.0939C8.34457 1.69299 8.56524 1.84299 8.68057 2.06699L10.1599 5.04699C10.2666 5.26499 10.4739 5.41633 10.7132 5.45299L14.0266 5.93366C14.3066 5.97366 14.5406 6.16699 14.6332 6.43366C14.7206 6.70099 14.6452 6.99433 14.4399 7.18699L11.9466 9.54699Z"
      fill="#FFBE27"
    />
  </svg>
);

export default PinboardTabStar;
