import React from "react";

const PauseIcon = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1C4.13437 1 1 4.13437 1 8C1 11.8656 4.13437 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13437 11.8656 1 8 1ZM8 13.8125C4.79062 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79062 4.79062 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79062 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125ZM6.625 5.5H5.875C5.80625 5.5 5.75 5.55625 5.75 5.625V10.375C5.75 10.4437 5.80625 10.5 5.875 10.5H6.625C6.69375 10.5 6.75 10.4437 6.75 10.375V5.625C6.75 5.55625 6.69375 5.5 6.625 5.5ZM10.125 5.5H9.375C9.30625 5.5 9.25 5.55625 9.25 5.625V10.375C9.25 10.4437 9.30625 10.5 9.375 10.5H10.125C10.1937 10.5 10.25 10.4437 10.25 10.375V5.625C10.25 5.55625 10.1937 5.5 10.125 5.5Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default PauseIcon;
