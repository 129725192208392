export default {
  str: "Text",
  provinceStr: "Text (Geo-Province) ",
  cityStr: "Text (Geo-City) ",
  countyStr: "Text (Geo - District/County) ",
  int: "Integer",
  bool: "Boolean",
  decimal: "Number",
  float: "Float",
  datetime: "Time",
  date: "Date",
  YYYYInt: "Integer (YYYY) ",
  YYYYMMInt: "Integer (YYYYMM) ",
  YYYYMMDDInt: "Integer (YYYYMMDD) ",
  timestampInt: "Integer (timestamp) ",
  timestampFloat: "Float (timestamp) ",
  choice: "Mapping",
  timestamp: "Timestamp",
  YYYYMMDD_STR: "Text (YYYYMMDD)",
  YYYYMM_STR: "Text (YYYYMM)",
  YYYY_STR: "Text (YYYY)",
  YYYYMMDD_HYPHEN_STR: "Text (YYYY-MM-DD)",
  YYYYMM_HYPHEN_STR: "Text (YYYY-MM)",
  YYYYMMDD_SLASH_STR: "Text (YYYY/MM/DD)",
  YYYYMM_SLASH_STR: "Text (YYYY/MM)",
};
