import React from "react";

const CopyPwd = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6667 2C11.0349 2 11.3333 2.29848 11.3333 2.66667C11.3333 3.03486 11.0349 3.33333 10.6667 3.33333H3.33333V10.6667C3.33333 11.0349 3.03486 11.3333 2.66667 11.3333C2.29848 11.3333 2 11.0349 2 10.6667V3.33333C2 2.59695 2.59695 2 3.33333 2H10.6667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66667 6C4.66667 5.26362 5.26362 4.66667 6 4.66667H12.6667C13.403 4.66667 14 5.26362 14 6V12.6667C14 13.403 13.403 14 12.6667 14H6C5.26362 14 4.66667 13.403 4.66667 12.6667V6ZM6 6H12.6667V12.6667H6V6Z"
      fill="currentColor"
    />
  </svg>
);

export default CopyPwd;
