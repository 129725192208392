export default {
  modalTitle: "Model Overview",
  dataOverview: "Data Overview ",
  timeDimensionStatistic: "Time Dimension Statistics",
  measureStatistic: "Measure Statistics",
  dimensionStatistic: "Dimension Statistics",
  allTableDataTip: "The results are obtained by scanning the entire table data",
  partTableDataTip: "The results are obtained through sampling and statistics from the first 100000 rows of data",
  refreshButtonText: "Refresh Overview Data",
  loading: "Querying",
  loadingTip: "You can wait for the overview query to be completed before opening it for viewing",
  loadingFailed: "Query failed",
  overviewColumnName: "Statistical Items",
  overviewColumnValue: "Value",
  overviewColumnNameTimeSeries: "Number of time dimensions",
  overviewColumnNameDimension: "Number of dimensions",
  overviewColumnNameMeasure: "Number of measures",
  countColumnTip: "Count/Count (Distinct) does not support statistics",
  nonFieldTip: "Custom columns do not support statistics",
  timeDimensionColumnName: "Name Of Time Dimension",
  timeDimensionColumnMinUnit: "Minimum Granularity",
  timeDimensionColumnMin: "Start Time",
  timeDimensionColumnMax: "End Time",
  timeDimensionColumnTypeError: "Data type error",
  measureColumnName: "Measure name",
  measureColumnMax: "Maximum Value",
  measureColumnMin: "Minimum Value",
  measureColumnMean: "Mean",
  measureColumnStd: "Standard Deviation",
  measureColumnMedian: "Median",
  measureColumnNull: "Null Value Ratio",
  dimensionColumnName: "Dimension Name",
  dimensionColumnTotal: "Number Of Dimension Values/Total Rows",
  dimensionColumnNull: "Null Value Ratio",
  dimensionColumnDisplay: "Dimension Value Examples (Top 5 values by frequency)",
  refreshSuccessToast: "Overview data refresh successful",
  refreshFailedToast: "Failed to refresh overview data, please try again later",
  displaySplit: ", ",
};
