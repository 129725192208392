export default {
  allRoleUsers: "全部用户",
  all: "全部",
  deleteUserSuccessToast: "注销成功",
  modifySuccessToast: "修改成功",
  addSuccessToast: "新增成功",
  username: "用户名称",
  loginName: "登录名",
  userEmail: "用户邮箱/手机号",
  userBind: "绑定情况",
  userDesc: "用户介绍",
  userRole: "用户角色",
  accountType: "账号类型",
  firstAccountTypeIntro: "不同账号能使用工作区的不同功能",
  secondAccountTypeIntro: "数据专家：工作区全部功能（包括工作区管理）",
  thirdAccountTypeIntro: "全民数据科学家：搜索查看、数据下载及报告功能",
  fourthAccountTypeIntro: "全民数据探索家：仅享有搜索和查看功能",
  linkText: "点击查看详情",
  accountProps: "账号属性",
  accountPropsTip: "分为正式和临时，临时账号自激活日起有3个月有效期。超期后将自动进入休眠，可重新激活。",
  status: "状态",
  createdAt: "注册时间",
  lastLoginAt: "最近登录时间",
  operation: "操作",
  workspace: "工作区",
  edit: "编辑",
  activate: "激活",
  enable: "启用",
  disable: "禁用",
  delete: "注销",
  confirm: "确认",
  cancel: "取消",
  activateUserConfirmText: "确认激活此用户？",
  enableUserConfirmText: "确认启用此用户？",
  disableUserConfirmText: "确认禁用此用户？",
  deleteUserConfirmText: "确认注销此用户？",
  deleteUserTip: "注销后所有用户信息不可恢复，仅保留操作记录(审计员在审计日志中可见)",
  allAccountStatus: "全部状态",
  batchUpload: "批量上传",
  batchDelete: "批量删除",
  userSearchInputPlaceholder: "请输入想搜索的用户信息",
  createUser: "创建用户",
  businessUser: "业务用户",
  unbindPrefix: "确认解除",
  unbindSuffix: "账号绑定",
  currentAccount: "当前账号",
  bindTip: "账号实际绑定的第三方登录方式",
  bindTitle: "绑定设置",
  unbindSuccess: "解绑成功",
  null: "无",
  email: "邮箱",
  phone: "手机号",
  separator: "：",
};
