import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Workspace.WorkspaceDisplay.${id}`);

export default class BreakPointDisplayType {
  static DEFAULT = 0;

  static ZERO = 1;

  static SKIP = 2;

  static LINK = 3;

  static choices = [this.DEFAULT, this.ZERO, this.SKIP, this.LINK];

  static getText = type => {
    if (type === this.DEFAULT) return getTextById("defaultDisplay");
    if (type === this.ZERO) return getTextById("showZero");
    if (type === this.SKIP) return getTextById("skipBreakpoints");
    if (type === this.LINK) return getTextById("ignoreBreakpoints");
    return "";
  };
}
