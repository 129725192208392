import React from "react";

const ConfirmInfo: React.FC = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08018 2.26411C5.61314 1.41148 4.70743 0.833344 3.66669 0.833344C2.1479 0.833344 0.916687 2.06456 0.916687 3.58334C0.916687 4.75379 1.6479 5.75344 2.67843 6.15043C2.13605 7.32092 1.83335 8.62511 1.83335 10C1.83335 15.0626 5.93741 19.1667 11 19.1667C16.0626 19.1667 20.1667 15.0626 20.1667 10C20.1667 8.62511 19.864 7.32092 19.3216 6.15043C20.3521 5.75344 21.0834 4.75379 21.0834 3.58334C21.0834 2.06456 19.8521 0.833344 18.3334 0.833344C17.2926 0.833344 16.3869 1.41148 15.9199 2.26411C14.4984 1.35822 12.8105 0.833344 11 0.833344C9.18953 0.833344 7.50163 1.35822 6.08018 2.26411ZM6.87502 8.16668C6.87502 7.66042 7.28543 7.25001 7.79169 7.25001H14.2084C14.7146 7.25001 15.125 7.66042 15.125 8.16668C15.125 8.67294 14.7146 9.08334 14.2084 9.08334H7.79169C7.28543 9.08334 6.87502 8.67294 6.87502 8.16668ZM7.79169 10.9167C7.28543 10.9167 6.87502 11.3271 6.87502 11.8333C6.87502 12.3396 7.28543 12.75 7.79169 12.75H10.5417C11.0479 12.75 11.4584 12.3396 11.4584 11.8333C11.4584 11.3271 11.0479 10.9167 10.5417 10.9167H7.79169Z"
        fill="var(--datarc-color-primary)"
      />
    </svg>
  );
};

export default ConfirmInfo;
