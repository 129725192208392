export default {
  /* 顶部设置规则名称和按钮的部分 */
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  ruleNameInputPlaceholder: "Please enter a rule name",
  goBackConfirmTitle: "Do you want to leave the rule configuration page?",
  goBackConfirmTip: "The current configuration will not be saved.",
  saveRuleBtnText: "Save rules",
  notConnectedErrMsg: "Connection not completed",
  notConnectedTip: "There are unfinished connections, please connect and save",
  incompleteErrMsg: "Configuration not completed",
  incompleteTip: "There are unset conditions or actions",
  deleteRuleConfirmTitle: "Confirm to delete this rule?",
  deleteRuleBtnText: "Delete rule",
  /* 提示菜单栏 */
  menuTitle: "The logic of rule operation",
  preRuleStartingPoint: "Input problem",
  ruleStartingPoint: "Problem after parsing",
  conditionTip: "Judge condition (not required, can be concatenated) ",
  addConditionBtnText: "Add condition",
  actionTip: "Execute the action and output to the question",
  addActionTip: "Add operation",
  tips: "Precautions",
  tipOne: "1. Parallel execution between operations, sequential execution within operations",
  tipTwo: "2. You can directly connect problems and operations without conditions",
  tipThree: "3. Conditions can be connected in series with conditions, multi-layer judgment",
  tipFour: "4. After performing the operation, multiple problems may be output, which will affect the final search results",
};
