export default {
  excess: "Exceed",
  normal: "Normal",
  admin: "System admin",
  auditor: "Auditor",
  user: "User",
  unbind: "Unbind",
  config: "Config",
  success: "Success",
  CASService: "CAS Service",
  loginMethod: "Login Type",
  configured: "Configured",
  notConfigured: "Not configured",
  configuredOthers: ",attribute mapping enabled:",
  confirm: "Confirm",
  cancel: "Cancel",
  unbindConfirmText: "Are you sure you want to unbind?",
  unbindConfirmTip: "Login info will be deleted",
  unlimited: "Unlimited",
  dingDing: "Ding Talk",
  application: "Application",
  lark: "Lark",
  viewMoreBtnText: "More",
  openDrawerBtnText: "Open Sidebar",
  qianFan: "Yufu",
  askRank: "Ranking",
  askTrend: "Trends",
  askContent: "Dimensions",
  askContrast: "Comparison",
  frequentQuestions: "Recent Searches",
  newQuestions: "New Searches",
  hotQuestions: "Top Searches",
  questionGuide: "You can also try",
  questionGuideText: "You can click here to try the question",
  weChat: "weCom",
  verificationFile: "Verification File",
  weComAddMenu: "Add Menu Button",
  weComRemoveMenu: "Remove Menu Button",
  OAuth2: "OAuth 2.0",
  SAML2: "SAML 2.0",
  subscriptionModel: "Subscription Model",
  core: "Core",
  byNumberOfCores: "By Number of Cores",
  byNumberOfCoresNote: "Unlimited number of business user accounts",
  byAccountNumber: "By Account Number",
  byAccountNumberNote: "Subscribe by number of various business user accounts",
  addBtnText: "Add New",
  phone: "phone",
  email: "email",
};
