export default {
  renameConfirmFormerText: "After modification",
  renameConfirmLatterText: "Name error",
  renameConfirmContent1:
    "After modification, the corresponding table still cannot be matched in the database. There is a possibility that the database link fails to cause this situation.",
  renameConfirmContent2: "Confirm that you still want to modify",
  renameConfirmContent3: "Name?",
  confirmBtnText: "Confirm changes",
  cancelBtnText: "Cancel",
  editModalTitlePrefix: "Modify",
  editModalTitleSuffix: "Name",
  oldName: "Original table name",
  newName: "Modified table name",
  customizeLabel: "Custom",
  customizeOptionLabel: "Manual input",
  selectOptionLabel: "Select",
  customizeInputPlaceholder: "Please enter",
  selectPlaceholder: "Please select",
  searchPlaceholder: "Please enter the name of the table you want to search",
  notFoundContent: "The corresponding data table was not found, please try another keyword.",
};
