import { Pagination as AntdPagination, PaginationProps } from "antd";
import React from "react";

import "./index.module.scss";

const Pagination: React.FC<PaginationProps> = props => {
  return <AntdPagination {...props} />;
};

export default Pagination;
