import { indexDarkLogoSrc, indexLogoSrc, logoIconSrc, whiteLogoImgSrc } from "@/assets/common";
import ThemeModeType from "./Constants/ThemeModeType";

/**
 * 版权、Logo 相关的数据从这个文件统一获取
 */
export const getCopyright = () => {
  if (window.hiddenCopyright) return "";
  return window.copyright || "© 北京谊慧信息技术有限公司";
};

export const getCopyrightLink = () => {
  return window.copyrightLink || "https://datarc.cn/";
};

export const getLogoText = () => {
  return window.logoText || "北极九章";
};

export const getLogoImg = () => {
  return logoIconSrc;
};

export const getLogoImgInvert = () => {
  return window.logoInvertImgSrc || whiteLogoImgSrc;
};

export const getIndexLogo = (themeMode = ThemeModeType.LIGHT) => {
  return themeMode === ThemeModeType.LIGHT ? indexLogoSrc : indexDarkLogoSrc;
};

// 未上传时工作区配置、系统设置和审计日志的LOGO与首页一致
export const getTextLogo = () => {
  return window.textLogoImgSrc || getIndexLogo();
};
