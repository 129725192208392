import React from "react";

const EyeOpen = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C8.15271 0 10.0532 1.45945 11.7068 4.28611L11.8303 4.50067L12 4.8L11.7068 5.31389C10.0532 8.14055 8.15271 9.6 6 9.6C3.84729 9.6 1.94678 8.14055 0.293246 5.31389L0.169694 5.09933L0 4.8L0.169694 4.50067C1.85191 1.53333 3.79347 0 6 0ZM5.99999 1.20001C4.37814 1.20001 2.83696 2.34933 1.38318 4.80001C2.83696 7.25069 4.37814 8.40001 5.99999 8.40001C7.57779 8.40001 9.07731 7.31405 10.4922 5.00684L10.6168 4.80009L10.492 4.59321C9.07491 2.28462 7.57551 1.20001 5.99999 1.20001ZM5.99999 3.00001C6.9941 3.00001 7.79999 3.8059 7.79999 4.80001C7.79999 5.79412 6.9941 6.60001 5.99999 6.60001C5.00588 6.60001 4.19999 5.79412 4.19999 4.80001C4.19999 3.8059 5.00588 3.00001 5.99999 3.00001Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeOpen;
