import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.RuleNode.${id}`);

export default class RuleConditionMatchType {
  static AND = "$and";

  static OR = "$or";

  static choices = [RuleConditionMatchType.AND, RuleConditionMatchType.OR];

  static getText = t => {
    if (t === RuleConditionMatchType.AND) return getText("andLabel");
    if (t === RuleConditionMatchType.OR) return getText("orLabel");
  };
}
