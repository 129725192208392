import { getLocaleText, isEnUS } from "@/utils/locale";

import disappearIcon from "@/assets/svg/inspection-disappear.svg";
import downIcon from "@/assets/svg/inspection-down.svg";
import enDownIcon from "@/assets/svg/inspection-down-en.svg";
import negativeIcon from "@/assets/svg/inspection-negative.svg";
import newIcon from "@/assets/svg/inspection-new.svg";
import upIcon from "@/assets/svg/inspection-up.svg";
import enUpIcon from "@/assets/svg/inspection-up-en.svg";

const getText = id => getLocaleText(`pages.Attribution.${id}`);

export default class InspectionType {
  static KEY_DIMENSION = "kd"; // 关键维度

  static LOST_ATTRIBUTE = "la"; // 数据缺失

  static RISE_ATTRIBUTE = "ra"; // 数据新增

  static MEASURE_TREND = "mt"; // 趋势背离

  static ANOMALY = "anomaly"; // 反常数据

  static choices = [this.KEY_DIMENSION, this.LOST_ATTRIBUTE, this.RISE_ATTRIBUTE, this.MEASURE_TREND, this.ANOMALY];

  static isAbnormal = type => type === this.LOST_ATTRIBUTE || type === this.RISE_ATTRIBUTE; // 判断是否是异常数据（缺失或新增）

  static getText = type => {
    if (type === this.KEY_DIMENSION) return getText("keyDimension");
    if (type === this.LOST_ATTRIBUTE) return getText("disappearingDimension");
    if (type === this.RISE_ATTRIBUTE) return getText("newDimension");
    if (type === this.MEASURE_TREND) return getText("negativeMark");
    if (type === this.ANOMALY) return getText("anomalyData");
    return "";
  };

  static getMobileText = type => {
    if (type === this.KEY_DIMENSION) return getText("mobileKeyDimension");
    if (type === this.LOST_ATTRIBUTE) return getText("mobileDisappearingDimension");
    if (type === this.RISE_ATTRIBUTE) return getText("mobileNewDimension");
    if (type === this.MEASURE_TREND) return getText("mobileNegativeMark");
    if (type === this.ANOMALY) return getText("mobileAnomalyData");
    return "";
  };

  static getTrendIcon = sign => (isEnUS() ? (sign ? enUpIcon : enDownIcon) : sign ? upIcon : downIcon);

  static getInspectionCardIconAndTitle = (type, sign) => {
    if (type === this.KEY_DIMENSION) {
      return {
        icon: this.getTrendIcon(sign),
        title: sign ? getText("keyDimensionUpTitle") : getText("keyDimensionDownTitle"),
      };
    }
    if (type === this.LOST_ATTRIBUTE) {
      return {
        icon: disappearIcon,
        title: getText("disappearingDimensionTitle"),
      };
    }
    if (type === this.RISE_ATTRIBUTE) {
      return {
        icon: newIcon,
        title: getText("newDimensionTitle"),
      };
    }
    if (type === this.MEASURE_TREND) {
      return {
        icon: negativeIcon,
        title: getText("negativeMarkTitle"),
      };
    }
    if (type === this.ANOMALY) {
      return {
        title: getText("anomalyData"),
      };
    }
    return {};
  };
}
