import language from "../locale";

const LOCALE_LOCAL_STORAGE_KEY = "locale";
export const ZH_CN = "zh-CN";
export const EN_US = "en-US";

export const getLocale = (): string => {
  const configuredLocale = localStorage.getItem(LOCALE_LOCAL_STORAGE_KEY);
  return configuredLocale || ZH_CN;
};

export const setLocale = (val: string): void => {
  localStorage.setItem("locale", val);
};

const languageKeyMap = {
  [ZH_CN]: "zhCN",
  [EN_US]: "enUS",
};

export const getLocaleText = (id: string): string => language[languageKeyMap[getLocale()] || languageKeyMap[ZH_CN]][id];
