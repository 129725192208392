import React from "react";

const DragBar = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66675 2.3335C2.21903 2.3335 2.66675 1.88578 2.66675 1.3335C2.66675 0.781211 2.21903 0.333496 1.66675 0.333496C1.11446 0.333496 0.666748 0.781211 0.666748 1.3335C0.666748 1.88578 1.11446 2.3335 1.66675 2.3335Z"
      fill="currentColor"
    />
    <path
      d="M2.66675 5.00016C2.66675 5.55245 2.21903 6.00016 1.66675 6.00016C1.11446 6.00016 0.666748 5.55245 0.666748 5.00016C0.666748 4.44788 1.11446 4.00016 1.66675 4.00016C2.21903 4.00016 2.66675 4.44788 2.66675 5.00016Z"
      fill="currentColor"
    />
    <path
      d="M2.66675 8.66683C2.66675 9.21911 2.21903 9.66683 1.66675 9.66683C1.11446 9.66683 0.666748 9.21911 0.666748 8.66683C0.666748 8.11454 1.11446 7.66683 1.66675 7.66683C2.21903 7.66683 2.66675 8.11454 2.66675 8.66683Z"
      fill="currentColor"
    />
    <path
      d="M7.33341 8.66683C7.33341 9.21911 6.8857 9.66683 6.33341 9.66683C5.78113 9.66683 5.33341 9.21911 5.33341 8.66683C5.33341 8.11454 5.78113 7.66683 6.33341 7.66683C6.8857 7.66683 7.33341 8.11454 7.33341 8.66683Z"
      fill="currentColor"
    />
    <path
      d="M6.33341 6.00016C6.8857 6.00016 7.33341 5.55245 7.33341 5.00016C7.33341 4.44788 6.8857 4.00016 6.33341 4.00016C5.78113 4.00016 5.33341 4.44788 5.33341 5.00016C5.33341 5.55245 5.78113 6.00016 6.33341 6.00016Z"
      fill="currentColor"
    />
    <path
      d="M7.33341 1.3335C7.33341 1.88578 6.8857 2.3335 6.33341 2.3335C5.78113 2.3335 5.33341 1.88578 5.33341 1.3335C5.33341 0.781211 5.78113 0.333496 6.33341 0.333496C6.8857 0.333496 7.33341 0.781211 7.33341 1.3335Z"
      fill="currentColor"
    />
  </svg>
);

export default DragBar;
