import React from "react";

const PinboardEditIcon = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0747 6.2973L14.0758 6.29625C14.7918 5.58027 14.6215 4.24917 13.6955 3.32314C12.8263 2.45394 11.6002 2.25059 10.8609 2.821C10.8084 2.8532 10.7595 2.89166 10.7152 2.9359L3.0498 10.7894C2.93586 10.9062 2.87207 11.0628 2.87207 11.226V13.707C2.87207 14.0522 3.15189 14.332 3.49707 14.332H5.96919C6.13758 14.332 6.29884 14.264 6.41646 14.1435L14.0747 6.2973Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M3.66407 15.916C3.22666 15.916 2.87207 16.2706 2.87207 16.708C2.87207 17.1454 3.22666 17.5 3.66407 17.5H16.3361C16.7735 17.5 17.1281 17.1454 17.1281 16.708C17.1281 16.2706 16.7735 15.916 16.3361 15.916H3.66407Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default PinboardEditIcon;
