import React from "react";

const MobileCheck = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.08333 0.25C8.58959 0.25 9 0.660406 9 1.16667C9 1.67293 8.58959 2.08333 8.08333 2.08333H2.58333L2.58333 14.9167H15.4167V9.41667C15.4167 8.91041 15.8271 8.5 16.3333 8.5C16.8396 8.5 17.25 8.91041 17.25 9.41667V14.9167C17.25 15.9292 16.4292 16.75 15.4167 16.75H2.58333C1.57081 16.75 0.75 15.9292 0.75 14.9167V2.08333C0.75 1.07081 1.57081 0.25 2.58333 0.25H8.08333Z"
        fill="currentColor"
      />
      <path
        d="M15.2642 0.810413C15.5912 0.42394 16.1696 0.37574 16.5561 0.702754C16.9426 1.02977 16.9908 1.60817 16.6638 1.99464L10.2448 9.58076C9.91774 9.96723 9.33934 10.0154 8.95287 9.68842C8.87585 9.62325 8.81227 9.5481 8.76241 9.46647C8.69512 9.4274 8.63173 9.37906 8.5741 9.32143L5.98138 6.7287C5.6234 6.37072 5.6234 5.79032 5.98138 5.43234C6.33936 5.07436 6.91976 5.07436 7.27774 5.43234L9.48537 7.63998L15.2642 0.810413Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MobileCheck;
