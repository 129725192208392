export default {
  name: "Username",
  username: "Login Name",
  email: "User mailbox",
  description: "User introduction",
  role: "User role",
  groups: "User group that the user belongs to",
  disableDownload: "Close download",
  notSet: "Not set",
  status: "Status",
  operations: "Settings",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  removeAccountBtnText: "Remove",
  removeAccountConfirmTitle: "Confirm the removal of this user?",
  removeAccountFormerTip: "User has reports for which he is responsible",
  removeAccountLatterTip:
    "Before removing, the report owned by this user needs to be deleted or transferred to other users in this workspace",
  removeAccountConfirmText: "Confirm and process",
  removeAccountSuccessToast: "User removed successfully",
  disableAccountFormerTip: "Confirm disabling this user?",
  disableAccountLatterTip: "Users will not be able to enter this workspace after disabling",
  configModalTitle: "Config",
  configBtnText: "Settings",
  editAccountBtnText: "Management",
  tier: "Account type",
  saveSuccessToast: "Save Successfully",
  allGroups: "All user groups",
  addAccountBtnText: "Add user",
  batchAddAccountBtnText: "Batch Import",
  searchAccountInputPlaceholder: "Please enter the user information you want to search",
  searchGroupInputPlaceholder: "Please enter the information of the user group you want to search for",
  selectGroupPlaceholder: "Please select a user group",
  addAccountModalTitle: "Add user",
  addAccountOkText: "Confirm add",
  addAccountInputPlaceholder: "Please enter the user information you want to add",
  selectFormerText: "Please select",
  addSuccessToast: "Add successfully",
  removeSuccessToast: "Removed successfully",
  createdAt: "Join time",
  uploadModalTitle: "Batch Upload",
  uploadTip: "Please download the template and fill it out according to the template format before uploading",
  formatTip1:
    "Based on the login name, batch add users from the system to this workspace and their corresponding user groups. Supports updating user group information (i.e., automatically creating new user groups and updating members based on the latest file)",
  formatTip2: "Supported format: .csv",
  formatTip3: "Supported encoding formats: utf-8, gbk, ascii, utf-16",
  uploadBtnText: "Select File",
  downloadBtnText: "Download Template",
  downloadMembersFileTitle: "Batch Upload Members Template",
  downloadDataBtnText: "Download Current Members",
  okBtnText: "OK",
  groupsTip: "Separate multiple user groups with commas",
  uploadSuccessToast: "File upload successful",
  uploadFailToast: "File upload failed",
  downloadSuccessToast: "Download successful",
  downloadFailToast: "Download failed",
  uploadErrorToast: "Error uploading user information",
  errorFilename: "Error message",
  errorRow: "Error row",
  errorMessage: "Error message",
  errorField: "Error column",
  uploadErrorTitle: "Upload failed",
  uploadErrorDesc: "Please download the error information, make the necessary modifications based on the information, and upload again.",
  uploadErrorBtnText: "Download error information file",
  fileModelName: "Workspace User Members Template.csv",
  off: "Off",
  on: "Open",
};
