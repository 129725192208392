export default {
  dataCacheRefreshSucceeded: "数据缓存刷新成功",
  enterAModelNameToSearch: "请输入模型名称进行查找",
  enterAModelNameOrKeywordToSearch: "请输入模型名称或关键词进行查找",
  formal: "正式",
  roughDraft: "草稿",
  refreshCachedData: "刷新缓存数据",
  syncKeywordTaskStart: "同步关键词任务开始，请避免同步过程中使用搜索功能，可能会导致数据不准",
  syncKeywords: "同步关键词",
  createModel: "创建模型",
  ifYouNeedHelpPlease: "如需帮助请",
  clickHere: "点击此处",
  learnHowToCreateModel: "了解如何创建模型。",
  orderNumber: "序号",
  name: "名称",
  source: "来源",
  noSource: "无来源",
  updatedAt: "更新时间",
  operator: "操作人",
  operation: "操作",
  overview: "概览",
  edit: "编辑",
  delete: "删除",
  saveSuccessToast: "保存成功",
  modelIsANecessary: "模型是搜索数据的必要条件",
  haveNotCreatedAnyModels: "您还未创建任何模型~",
  learnHowToCreateModelFromVideo: "可以从右侧视频中学习如何创建模型",
  disabledOrLoggedOutUsers: "已禁用或注销的用户",
  default: "（默认）",
  notConfigured: "尚未配置",
  confirmToDeleteTheModel: "确定删除模型？",
  currentModel: "当前模型：",
  formerConfirmToDeleteTheModel: "确定删除模型【",
  latterConfirmToDeleteTheModel: "】？",
  modelDeletedSuccessfully: "模型删除成功",
  deletingMayAffectTheSearchBehavior: "请注意，删除数据表可能影响工作区的搜索行为，该模型的配置不可恢复。",
  delMeasureAndDimension: "同时删除仅在此模型使用的维度与指标",
  deleteConfirmOkText: "确认删除",
  cancel: "取消",
  copy: "复制",
  copySucceeded: "复制成功",
  successfullyModifiedFactTableName: "修改事实表名成功",
  factTable: "事实表",
  startUsing: "启用",
  forbidden: "禁用",
  dateOffsetDefault: "跟随工作区设置",
  dataUpdateTime: "数据更新时间",
  successfullyUpdateTime: "修改数据更新时间成功",
  successfullyUpdateTimeSeries: "修改默认时间维度成功",
};
