import React from "react";

const CardAlarmIcon = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.9556 3.33325C3.1517 3.33325 2.5 3.98495 2.5 4.78886V14.9781C2.5 15.782 3.1517 16.4337 3.9556 16.4337H11.3655C10.9664 16.0113 10.64 15.5194 10.4066 14.9781H4.9556C4.40332 14.9781 3.9556 14.5304 3.9556 13.9781V5.78886C3.9556 5.23657 4.40332 4.78886 4.9556 4.78886L14.6004 4.78886C15.1527 4.78886 15.6004 5.23657 15.6004 5.78886V8.03561C16.1136 8.09702 16.6028 8.23614 17.056 8.44087V4.78886C17.056 3.98495 16.4043 3.33325 15.6004 3.33325H3.9556Z"
      fill="currentColor"
    />
    <rect x="6" y="7.6001" width="5" height="1.4" rx="0.7" fill="currentColor" />
    <rect x="6" y="11" width="3" height="1.4" rx="0.7" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0812 9.57224C16.0812 9.2562 15.825 9 15.509 9H15.491C15.175 9 14.9188 9.2562 14.9188 9.57224C13.5663 9.82791 12.5473 10.9578 12.5473 12.3132V14.008C12.245 14.008 12 14.2544 12 14.5567C12 14.8606 12.2463 15.1084 12.5502 15.1084H18.4498C18.7537 15.1084 19 14.8606 19 14.5567C19 14.2544 18.755 14.008 18.4527 14.008V12.3132C18.4527 10.9578 17.4337 9.82791 16.0812 9.57224ZM13.7097 12.3132C13.7097 11.3773 14.5113 10.6185 15.5 10.6185C16.4887 10.6185 17.2903 11.3773 17.2903 12.3132V14.008H13.7097V12.3132Z"
      fill="currentColor"
    />
    <path
      d="M16.3385 16.4498C16.3385 16.1459 16.0921 15.8996 15.7882 15.8996H15.2354C14.9315 15.8996 14.6852 16.1459 14.6852 16.4498C14.6852 16.7537 14.9315 17 15.2354 17H15.7882C16.0921 17 16.3385 16.7537 16.3385 16.4498Z"
      fill="currentColor"
    />
  </svg>
);

export default CardAlarmIcon;
