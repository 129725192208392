export default {
  triggerConditions: "Conditions",
  alertDescription: "Actual change",
  dateRangeDescription: "Specific monitoring cycle",
  createdAt: "Alarm sending time",
  alertLogTitle: "Alarm Log",
  alertTimeTitle: "Alarm sending time",
  noData: "No data yet",
  filterTip: "Click to view all",
  filterInputTip: "Please enter a natural number",
};
