export default {
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  wrongColorToast: "Incorrect color filling",
  createThemeColorErrorToast: "The theme color needs 8 main colors to be successfully created",
  RGBColorValueColumnName: "RGB color value (Hex) ",
  RGBColorValueInputPlaceholder: "Please enter a 6-digit RGB color value (Hex) ",
  colorValueColumnName: "Color value",
  colorValueInputPlaceholder: "Please enter a color value",
  operation: "Settings",
  resetThemeColorConfirmText: "Confirm to reset this group of theme colors back to the default colors?",
  resetBtnText: "Reset",
  saveBtnText: "Save",
  themeName: "Theme name",
  mainColor: "Main color",
  mainColorTip: "There are 8 main colors, and sorting affects the automatic color selection order and display order of the chart",
  secondaryColor: "Complementary colors",
  secondaryColorTip: "Unlimited number of complementary colors, used for color selection when there are too many labels",
  add: "Add",
  addSuccessToast: "Add successfully",
  searchNoResultAlert: "No search results, please change keywords",
  addWatchingContentDrawerTitle: "Add the content of the observation",
  noAddableContentToast: "No content to add for the time being",
  keywordInputPlaceholder: "Enter keyword to lookup",
  backBtnText: "Back to previous level",
  noAddableContentTip: "There is nothing to add at the moment, please start exploring the data ~",
  date: "Date",
  editBtnText: "Edit",
  deleteBtnText: "Delete",
  deleteDateTagConfirmText:
    "Only delete this label under this date, it will not affect the labels of other dates in the cycle. Are you sure to delete this label?",
  tagDetail: "Tag Detail",
  currentTag: "Current tag:",
  closeBtnText: "Hide",
  beforeQuestion: "Before the question",
  afterQuestion: "After the question",
  actionSettingDrawerTitle: "Operation Settings",
  addOperationBtnText: "Add operation",
  deleteOperationConfirmText: "Confirm to delete this operation?",
  operationNameInputPlaceholder: "Please enter the operation name, optional",
  executeInOrder: "Execute in order",
  operationContentLabel: "Operation content",
  selectPlaceholder: "Please select",
  templateInputPlaceholder: "Please enter a template",
  oldContentInputPlaceholder: "Please enter old content",
  replaceWith: "Into",
  newContentInputPlaceholder: "Please enter new content",
  deleteContentInputPlaceholder: "Please enter the content to be deleted",
  addOperationContentBtnText: "Add action content",
};
