import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.RowPermissionOperator.${id}`);
export default class RowPermissionOperator {
  static CONTAIN = "contain";

  static EQUAL = "equal";

  static STARTS_WITH = "startswith";

  static ENDS_WITH = "endswith";

  static IS_NULL = "isNull";

  static choices = [this.CONTAIN, this.EQUAL, this.STARTS_WITH, this.ENDS_WITH, this.IS_NULL];

  static getText = t => {
    if (t === this.CONTAIN) return getText("contain");
    if (t === this.EQUAL) return getText("equal");
    if (t === this.STARTS_WITH) return getText("startsWith");
    if (t === this.ENDS_WITH) return getText("endsWith");
    if (t === this.IS_NULL) return getText("isNull");
    return "";
  };
}
