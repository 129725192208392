import React from "react";

const RecycleBin = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66699 0.333374C4.2988 0.333374 4.00033 0.631851 4.00033 1.00004C4.00033 1.36823 4.2988 1.66671 4.66699 1.66671H1.33366C0.965469 1.66671 0.666992 1.96518 0.666992 2.33337C0.666992 2.70156 0.965469 3.00004 1.33366 3.00004H10.667C11.0352 3.00004 11.3337 2.70156 11.3337 2.33337C11.3337 1.96518 11.0352 1.66671 10.667 1.66671H7.33366C7.70185 1.66671 8.00033 1.36823 8.00033 1.00004C8.00033 0.631851 7.70185 0.333374 7.33366 0.333374H4.66699Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.667 5.00004C10.667 4.63185 10.3685 4.33337 10.0003 4.33337H2.00033C1.63214 4.33337 1.33366 4.63185 1.33366 5.00004V11.6667C1.33366 12.7713 2.22909 13.6667 3.33366 13.6667H8.66699C9.77156 13.6667 10.667 12.7713 10.667 11.6667V5.00004ZM2.66699 5.66671H9.33366V11.6667C9.33366 12.0349 9.03518 12.3334 8.66699 12.3334H3.33366C2.96547 12.3334 2.66699 12.0349 2.66699 11.6667V5.66671Z"
      fill="currentColor"
    />
  </svg>
);

export default RecycleBin;
