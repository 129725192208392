export default class TrackPointType {
  // 用户点击搜索框，进入焦点
  static ON_QUILL_SEARCH_INPUT_FOCUS = 100;

  // 用户输入完成问题，进行搜索
  static ON_QUILL_SEARCH_INPUT_SEARCH = 101;

  // 用户退出搜索框
  static ON_QUIT_SEARCH = 102;

  // 用户点击单条搜索历史
  static ON_CLICK_SINGLE_HISTORY = 103;

  // 用户清除单条历史记录
  static ON_DELETE_SINGLE_HISTORY = 104;

  // 用户点击热门搜索
  static ON_CLICK_TOP_SEARCH = 105;

  // 用户在搜索框修改搜索提示模式
  static ON_TOGGLE_TIP_TYPE = 107;

  // 用户通过 Modal 修改默认搜索提示
  static ON_TOGGLE_CONFIRM = 108;

  // 用户在个人设置页修改搜索提示模式
  static ON_TOGGLE_SETTING_TIP_TYPE = 109;

  // 用户在搜索历史 / 热门搜索菜单（问题提示模式下）按上下键
  static ON_QUESTION_PROMPT_UP_AND_DOWN = 110;

  // 用户在字段提示菜单（字段提示模式下）按上下键
  static ON_FIELD_PROMPT_UP_AND_DOWN = 111;

  // 用户点击查看所有字段
  static ON_CLICK_VIEW_ALL_FIELDS = 112;

  // 字段模式下选择分词（包括回车选择）
  static ON_SELECT_FIELD = 113;

  // 用户在分词中左右移动光标
  static ON_CURSOR_LEFT_AND_RIGHT = 114;

  // 用户 hover 分词
  static ON_HOVER_WORD = 115;

  // 用户通过分词右上角的清除键删除分词
  static ON_DELETE_KEYWORD = 116;

  // 用户查看搜索提示详情
  static ON_VIEW_TIP_DETAIL = 117;

  // 用户清除搜索提示
  static ON_CLOSE_TIP = 118;

  // 搜索字段来源
  static SEARCH_TOKEN_SOURCE = 201;

  // 开始搜索 platform
  static SEARCH_PLATFORM = 202;

  // 开始搜索的页面
  static SEARCH_PAGE_SOURCE = 203;

  // 搜索反馈（触发）
  static FEEDBACK_SOURCE = 204;

  // 搜索反馈（提交）
  static FEEDBACK_SOURCE_SUBMIT = 205;

  // 语言
  static LANGUAGE = 206;

  // 点击你可以试试的次数
  static YOU_CAM_TRY = 207;

  // --------------- 报告相关---------------------

  // 点击加入报告
  static ADD_TO_PIN = 301;

  // 下载为pdf
  static DOWNLOAD_AS_PDF = 302;

  // 下载为pptx
  static DOWNLOAD_AS_PPTX = 303;

  // 分享按钮
  static SHARE_BTN = 305;

  // 筛选器tag
  static FILTER_TAG = 306;

  // 添加筛选
  static ADD_FILTER = 307;

  // 添加图表
  static ADD_CHART = 308;

  // 替换卡片按钮
  static REPLACE_CARD = 309;

  // 编辑卡片按钮
  static EDIT_CARD = 310;

  // 快速选择卡片大小
  static SELECT_CARD_SIZE = 311;

  // 缓存设置
  static CACHE_SETTING = 312;

  // 定时提醒
  static TIME_REMINDER = 313;

  // 应用模板按钮
  static APPLY_TEMPLATE = 314;

  // 模板库按钮
  static TEMPLATE_LIBRARY_BTN = 315;

  // 搜索建议各卡片点击量
  static SUGGESTION_CARD = 534;

  // --------------- 图表交互相关---------------------

  // 分享卡片
  static SHARE_CARD = 501;

  // 分享链接
  static SHARE_LINK = 502;

  // 保存卡片
  static SAVE_CARD = 503;

  // 发送邮件
  static SEND_EMAIL = 504;

  // 下载CSV
  static DOWNLOAD_AS_CSV = 505;

  // 下载PNG
  static DOWNLOAD_AS_PNG = 506;

  // 打开筛选器
  static OPEN_FILTER = 509;

  // 点击筛选器的展开按钮
  static EXPAND_FILTER = 510;

  // 点击筛选器内的漏斗按钮
  static OPEN_FILTER_CONDITION = 511;

  // 点击了其他图表 icon 进行图表类型切换
  static SWITCH_CHART_TYPE = 512;

  // 切换成表格 or 透视表
  static SWITCH_TO_TABLE = 513;

  // 点击坐标轴进行编辑
  static EDIT_AXIS = 515;

  // 点击查看小 i （你为何看到该图表）
  static VIEW_CARD_TIP = 516;

  // 点击修改卡片标题
  static MODIFY_CARD_TITLE = 517;

  // 点击添加简介
  static ADD_INTRO = 519;

  // 点击更改配色按钮
  static CHANGE_COLOR = 521;

  // 更改了配色，几种配色的选择数据
  static CHANGE_COLOR_DATA = 522;

  // 点击图例色块
  static OPEN_LEGEND_COLOR = 523;

  // 改变了图例颜色
  static CHANGE_LEGEND_COLOR = 524;

  // 点击图例隐藏了数据
  static CLOSE_LEGEND = 525;

  // 点击进入轴编辑
  static OPEN_AXIS_EDIT = 526;

  // 进行拖动操作
  static DRAG_ITEM = 527;

  // 点击隐藏数据
  static HIDE_DATA = 528;

  // 点击进入数据编辑
  static OPEN_MEASURE_EDIT = 529;

  // 点击显示 tab
  static OPEN_DISPLAY_TAB = 530;

  // 点击添加参考线
  static ADD_REFERENCE_LINE = 531;

  // 首页点击打开保存的卡片
  static OPEN_SAVED_CARD = 532;

  // 通过首页我的观察打开报告
  static OPEN_MY_WATCH_PIN = 533;

  // 点击数据解释
  static OPEN_INSIGHTS = 535;

  // 点击查看预测
  static OPEN_TREND_FORECAST = 536;

  // 用户登录
  static LOGIN = 602;

  // 页面浏览量
  static PAGE_VIEW = 603;
}
