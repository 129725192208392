export default {
  notApplicable: "不适用",
  other: "其他",
  rawDataType: "原始数据类型",
  fieldName: "字段名：",
  dataColumns: "数据列：",
  notConfigured: "未配置",
  expandRemaining: "展开剩余",
  items: "项",
  currentErrorField: "当前错误字段：",
  errorType: "，错误类型：",
  thePrevious: "上一个",
  next: "下一个",
  noSet: "未设置",
};
