import React, { ReactNode, useEffect, useRef, useState } from "react";
import Button from "@/components/datarc/Button";
import { getLocaleText } from "@/components/datarc/utils/locale";

import styles from "./index.module.scss";

interface DeployableBlockProps {
  children: ReactNode;
  height?: number;
  buttonClassName?: string;
  buttonText?: string;
  button?: ReactNode;
}

const DeployableBlock: React.FC<DeployableBlockProps> = ({ children, height = 300, buttonClassName, buttonText, button }) => {
  const containerRef = useRef<HTMLDivElement>();
  const contextRef = useRef<HTMLDivElement>();

  const [showButton, setShowButton] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  useEffect(() => {
    const needShowBut = containerRef.current?.clientHeight < contextRef.current?.clientHeight;
    setShowButton(needShowBut);
    setShowAll(!needShowBut);
  }, []);

  const moreButton = button ? button : <Button>{buttonText || getLocaleText("showAll")}</Button>;

  return (
    <div style={{ height: showAll ? "auto" : height }} ref={containerRef} className={styles.container}>
      <div className={styles.contentContainer}>
        <div ref={contextRef}>{children}</div>
      </div>
      {showButton && !showAll && (
        <div onClick={() => setShowAll(true)} className={`${styles.button} ${buttonClassName || ""}`}>
          {moreButton}
        </div>
      )}
    </div>
  );
};

export default DeployableBlock;
