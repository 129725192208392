export default {
  defaultRowColumnErrorMessage: "Configuration exception, please delete the line or reconfigure",
  modelNeedAFactTable: "Model not configured with fact table",
  modelNeedConfigs: "The fact table is not configured with anything",
  modelFactTableNeedATimeDimension: "Model fact table lacks time dimension",
  modelFactTableNeedAMeasure: "Model fact table lacks measures",
  formerFactTableAndDimensionTableNeedLine: "Fact and dimension tables",
  latterFactTableAndDimensionTableNeedLine: "No associated column is set between",
  connectedDimensionTableNeedAConfig: "Configure at least one dimension table connected",
  20004: "The dimension table of the external link of the fact table needs to configure at least one item",
  20101: "Keyword cannot be empty",
  20102: "The keyword field needs to be a list type",
  20201: "The measure has an invalid aggregate calculation method set, please consult the doc and reconfigure",
  20103: "Naming error, the name must contain a Chinese character or English letter",
  20104: "Naming error, the special symbol in the name only supports _ / \\ -",
  20105: "Naming error, keyword name needs to be of type string",
  former20106: "Already exists in the time dimension name ",
  latter20106: ", please choose another name or use a different keyword",
  20107: "Already exists in the custom time period name, please choose another name or use a different keyword",
  former20108: "Already exists in the dimension name of the fact table ",
  latter20108: ", please choose another name or use a different keyword, the keyword is duplicated with the dimension keyword",
  former20109: "Already exists in the dimension table ",
  latter20109: " dimension name, please choose another name or use a different keyword",
  former20110: "Already exists in the metric name ",
  latter20110: ", please choose another name or use a different keyword",
  20111: "Already exists in the model name, please choose another name or use this keyword",
  20112: "Naming error, name length cannot exceed 50 words",
  20113: "No longer exists, please recreate or select another existing keyword",
  20114: "New keyword is duplicated in the current fact table, please modify the keyword name or select another existing keyword",
  20115: "Duplicate the new keyword in the fact table, please modify the keyword name",
  20116: "Duplicate creation in the current dimension table, please modify the keyword name",
  former20117: "In dimension table",
  latter20117: "There is a duplicate new creation, please modify the keyword name",
  20118: "duplicate with current model name",
  needNames: "Cannot be empty",
  20005: "Selected repeatedly in the current fact table, please select another existing keyword or a new keyword.",
  20119:
    "Repeatedly created in the current dimension table, please choose either to create a new one or select another keyword in any location.",
  20121:
    "Simultaneously created in other dimension tables, please choose either to create a new one or select another keyword in any location.",
  20120:
    "Repeatedly selected in the current dimension table, please choose either to create a new one or select another keyword in any location.",
  20122:
    "Simultaneously selected in other dimension tables, please choose either to create a new one or select another keyword in any location.",
  customSqlNeedFieldType: "Custom fields need to select the field type.",
  needSql: "Missing data column configuration",
  needChoiceJson: "Mapping content not configured",
  20202: "Data type not set",
  id: "Keyword",
  sql: "Data column",
  fieldType: "Field type",
  type: "Data type",
  format: "Display type",
  names: "Keywords",
  synonym: "Alias",
};
