import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Workspace.WorkspaceDisplay.${id}`);

export default class ChartLegendPosition {
  static TOP = "top";

  static BOTTOM = "bottom";

  static LEFT = "left";

  static RIGHT = "right";

  static HIDDEN = "hidden";

  static getText = t => {
    if (t === ChartLegendPosition.BOTTOM) return getTextById("bottom");
    if (t === ChartLegendPosition.TOP) return getTextById("top");
    if (t === ChartLegendPosition.LEFT) return getTextById("left");
    if (t === ChartLegendPosition.RIGHT) return getTextById("right");
    if (t === ChartLegendPosition.HIDDEN) return getTextById("hidden");
    return "";
  };

  static choices = [this.TOP, this.BOTTOM, this.LEFT, this.RIGHT, this.HIDDEN];

  static options = this.choices.map(value => ({ label: this.getText(value), value }));
}
