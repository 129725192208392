// y 轴操作
export default class AxisOperation {
  static EDIT = 1;

  static SORT = 2;

  static POSITION = 3;

  static GROUP = 4;
}
