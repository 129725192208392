import React from "react";

const DownloadCSV = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      d="M10.7933 2.66669H5.20665C2.77998 2.66669 1.33331 4.11335 1.33331 6.54002V10.7867C1.33331 13.22 2.77998 14.6667 5.20665 14.6667H10.7866C13.2133 14.6667 14.66 13.22 14.66 10.7934V6.54002C14.6666 4.11335 13.22 2.66669 10.7933 2.66669Z"
      fill="currentColor"
    />
    <path
      d="M3.09797 8.15999L3.54939 8.15997C4.85325 8.15997 5.35337 8.51332 5.64671 9.09999L6.00004 9.99999C6.13337 10.2667 6.33337 10.6667 6.90004 10.64H9.10004C9.66671 10.6667 9.86671 10.2667 10 9.99999L10.3534 9.09999C10.6467 8.51332 11.1468 8.15997 12.4507 8.15997L12.9021 8.15999C14.0837 8.15999 14.6667 9 14.6667 9.66667V11.1867C14.4934 13.4133 13.0867 14.6667 10.8 14.6667H5.20004C2.91337 14.6667 1.50671 13.4133 1.33337 11.1867V9.66667C1.33337 9 1.91641 8.15999 3.09797 8.15999Z"
      fill="currentColor"
    />
    <path
      d="M9.68667 4.31331C9.49333 4.11998 9.17334 4.11998 8.98 4.31331L8.5 4.79331V1.33331C8.5 1.05998 8.27333 0.833313 8 0.833313C7.72667 0.833313 7.5 1.05998 7.5 1.33331V4.79331L7.02 4.31331C6.82667 4.11998 6.50667 4.11998 6.31333 4.31331C6.12 4.50665 6.12 4.82665 6.31333 5.01998L7.64667 6.35331C7.65333 6.35998 7.66 6.35998 7.66 6.36665C7.7 6.40665 7.75333 6.43998 7.80667 6.46665C7.87333 6.48665 7.93333 6.49998 8 6.49998C8.06667 6.49998 8.12667 6.48665 8.18667 6.45998C8.24667 6.43331 8.3 6.39998 8.35333 6.35331L9.68667 5.01998C9.88 4.82665 9.88 4.50665 9.68667 4.31331Z"
      fill="currentColor"
    />
  </svg>
);

export default DownloadCSV;
