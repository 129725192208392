export default {
  tableNameRepeatOthers: "The data table name is duplicated with the corresponding name of other sheets, please rename",
  tableNameRepeatOld: "The data table name has the same name as an existing table, please rename it",
  tableNameHasSpace: "The data table name contains spaces, please rename it",
  tableNameIsNull: "Data table name cannot be empty",
  colNameHasErrorChar: "The column name contains special characters, please rename it and confirm the upload",
  colNameRepeat: "The field name is duplicated with other fields in the table, please rename it",
  colNameHasSpace: "The column name contains spaces, please rename it and confirm the upload",
  colNameIsNull: "Column name cannot be empty",
  colAllEmptySelected: "Uploaded data table, at least one column of data must be selected",
  emptySelected: "At least one data table needs to be selected",
};
