import React from "react";

const Magnifier = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.11765 8.11764C2.11765 4.80394 4.80394 2.11765 8.11764 2.11765C11.4313 2.11765 14.1176 4.80394 14.1176 8.11764C14.1176 9.73134 13.4806 11.1963 12.4443 12.2746C12.4134 12.2992 12.3836 12.3258 12.3551 12.3544C12.3265 12.383 12.2999 12.4128 12.2752 12.4437C11.1968 13.4804 9.73164 14.1176 8.11764 14.1176C4.80394 14.1176 2.11765 11.4313 2.11765 8.11764ZM13.0606 14.5573C11.6917 15.6096 9.97774 16.2353 8.11764 16.2353C3.63439 16.2353 0 12.6009 0 8.11764C0 3.63439 3.63439 0 8.11764 0C12.6009 0 16.2353 3.63439 16.2353 8.11764C16.2353 9.97739 15.6099 11.6911 14.5579 13.0598L17.6907 16.1926C18.1042 16.6061 18.1042 17.2765 17.6907 17.69C17.2772 18.1035 16.6068 18.1035 16.1933 17.69L13.0606 14.5573Z"
      fill="currentColor"
    />
  </svg>
);

export default Magnifier;
