import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.AccountTier.${id}`);

export default class AccountTier {
  static TIER_1 = 1;

  static TIER_2 = 2;

  static TIER_3 = 3;

  static choices = [this.TIER_1, this.TIER_2, this.TIER_3];

  static getText = tier => {
    if (tier === AccountTier.TIER_1) return getTextById("tier1Label");
    if (tier === AccountTier.TIER_2) return getTextById("tier2Label");
    if (tier === AccountTier.TIER_3) return getTextById("tier3Label");
    return "";
  };

  static isUpgrade = (oldTier, tier) => {
    const sortTier = [this.TIER_3, this.TIER_2, this.TIER_1];
    return sortTier.indexOf(oldTier) < sortTier.indexOf(tier);
  };

  static getQuotaTierText = quota => {
    // quota_tier_N
    return this.getText(parseInt(quota.slice(11)));
  };
}
