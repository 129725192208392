export default {
  cardAlarmSetting: "卡片提醒设置",
  triggerConditions: "触发条件",
  thresholdPlaceholder: "请填写数值",
  thresholdSuffix: "时触发提醒",
  calculateFrequency: "计算频率",
  calculateFrequencyTip: "系统按此频率计算卡片数据，满足触发条件将即时发送提醒",
  settingThreshold: "设置提醒阈值",
  settingThresholdTip: "画一条阈值参考线",
  equal: "等于",
  chartTriggerConditionFragment1: "提醒阈值的",
  chartTriggerConditionFragment2: "数据点的个数",
  chartTriggerConditionFragment3: "时触发提醒",
  invalidQuestion: "告警问题失效",
  invalidCard: "告警卡片生成错误",
  invalidCardType: "告警卡片生成类型错误",
  invalidSqlIndex: "告警卡片 sql 数量错误",
  invalidMeasureIndex: "告警卡片指标数量错误",
  invalidMeasureNes: "告警卡片指标失效",
  noMember: "无用户需要推送",
};
