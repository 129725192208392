import React from "react";

const RightOutlined = prop => (
  <svg {...prop} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33321 14C5.50387 14 5.67454 13.9347 5.80454 13.8047L11.1379 8.47138C11.3985 8.21071 11.3985 7.78938 11.1379 7.52871L5.80454 2.19538C5.54387 1.93471 5.12254 1.93471 4.86187 2.19538C4.60121 2.45604 4.60121 2.87738 4.86187 3.13804L9.72387 8.00004L4.86187 12.862C4.60121 13.1227 4.60121 13.544 4.86187 13.8047C4.99187 13.9347 5.16254 14 5.33321 14Z"
      fill="currentColor"
    />
  </svg>
);

export default RightOutlined;
