export default class DocType {
  static HELP = "help";

  static DATA_LINK = "dataLink";

  static CSV_TEMPLATE = "csvTemplate";

  static CANVAS_MODEL = "canvasModel";

  static MODEL = "model";

  static ACCOUNT_TYPE = "accountType";

  static KEYWORD = "keyword";

  static SYSTEM_CONFIG = "systemConfig";

  static FORECAST = "forecast";
}
