import React from "react";

const ProportionOutline: React.FC = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.83333 3H3.5V1.66667H12.5V3H11.1667V5.71244L12.9554 10.4824L13.7583 12.5088C14.1051 13.3841 13.4602 14.3333 12.5188 14.3333H3.59933C2.68484 14.3333 2.04177 13.4337 2.33778 12.5684L3.12237 10.275L4.83333 5.71244V3ZM6.16666 3V5.95422L4.54684 10.2738C4.79273 10.4262 5.11442 10.5659 5.47863 10.6196C6.01699 10.6991 6.68493 10.5984 7.4027 9.99108C8.44618 9.10813 9.58927 8.96467 10.5654 9.14909C10.7427 9.18258 10.9147 9.2268 11.0802 9.27922L9.83333 5.95422V3H6.16666ZM11.7763 11.1261C11.4437 10.8664 10.9151 10.5721 10.3179 10.4592C9.67183 10.3372 8.95381 10.4252 8.26396 11.0089C7.2484 11.8682 6.19412 12.0731 5.28387 11.9387C4.83825 11.8729 4.43621 11.7275 4.09541 11.5499L3.59933 13L12.5188 13L11.7763 11.1261ZM8 7.50333V7.5H9.33333V7.50333H8Z"
      fill="currentColor"
    />
  </svg>
);

export default ProportionOutline;
