import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.AutoFillModelTableOptions.${id}`);

export default class {
  static AUTO_FILL = "autoFill";

  static DESCRIPTION_FILL = "descriptionFill";

  static FILED_FILL = "fieldFill";

  static UPLOAD_FILL = "uploadFill";

  static NO_FILL = "noFill";

  static AI_FILL = "aiFill"; // 单独一块

  static choices = [this.AUTO_FILL, this.DESCRIPTION_FILL, this.FILED_FILL, this.UPLOAD_FILL, this.NO_FILL];

  static getText = v => {
    if (v === this.AUTO_FILL) return getText("autoFill");
    if (v === this.DESCRIPTION_FILL) return getText("descriptionFill");
    if (v === this.FILED_FILL) return getText("fieldFill");
    if (v === this.NO_FILL) return getText("noFill");
    if (v === this.UPLOAD_FILL) return getText("uploadFill");
    if (v === this.AI_FILL) return getText("aiFill");
    return "";
  };

  static getDesc = v => {
    if (v === this.AUTO_FILL) return getText("autoFillDesc");
    if (v === this.DESCRIPTION_FILL) return getText("descriptionFillDesc");
    if (v === this.FILED_FILL) return getText("fieldFillDesc");
    if (v === this.UPLOAD_FILL) return getText("uploadFillDesc");
    if (v === this.NO_FILL) return getText("noFillDesc");
    return "";
  };

  static getOptions = () => this.choices.map(c => ({ label: this.getText(c), desc: this.getDesc(c), value: c }));
}
