import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.MeasureType.${id}`);

export default class MeasureType {
  static MEASURE = false;

  static CUMULATIVE = true;

  static choices = [MeasureType.MEASURE, MeasureType.CUMULATIVE];

  static getText = t => {
    if (t === MeasureType.MEASURE) return getTextById("measure");
    if (t === MeasureType.CUMULATIVE) return getTextById("cumulative");
    return "";
  };

  static getMeasureTypeOptions = () => MeasureType.choices.map(item => ({ label: MeasureType.getText(item), value: item }));
}
