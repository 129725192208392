import React from "react";

const MoreBtn = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 12 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 0.666656C5.73629 0.666656 5.47851 0.744855 5.25924 0.891364C5.03997 1.03787 4.86908 1.24611 4.76816 1.48975C4.66724 1.73338 4.64084 2.00147 4.69229 2.26011C4.74373 2.51875 4.87072 2.75633 5.05719 2.9428C5.24366 3.12927 5.48124 3.25626 5.73988 3.3077C5.99852 3.35915 6.26661 3.33275 6.51024 3.23183C6.75388 3.13091 6.96212 2.96002 7.10863 2.74075C7.25514 2.52149 7.33333 2.2637 7.33333 1.99999C7.33333 1.64637 7.19286 1.30723 6.94281 1.05718C6.69276 0.807133 6.35362 0.666656 6 0.666656ZM1.33333 0.666656C1.06963 0.666656 0.811839 0.744855 0.592574 0.891364C0.373308 1.03787 0.202411 1.24611 0.101495 1.48975C0.000577711 1.73338 -0.0258267 2.00147 0.0256202 2.26011C0.0770672 2.51875 0.204055 2.75633 0.390525 2.9428C0.576995 3.12927 0.814572 3.25626 1.07321 3.3077C1.33185 3.35915 1.59994 3.33275 1.84358 3.23183C2.08721 3.13091 2.29545 2.96002 2.44196 2.74075C2.58847 2.52149 2.66667 2.2637 2.66667 1.99999C2.66667 1.64637 2.52619 1.30723 2.27614 1.05718C2.02609 0.807133 1.68696 0.666656 1.33333 0.666656ZM10.6667 0.666656C10.403 0.666656 10.1452 0.744855 9.92591 0.891364C9.70664 1.03787 9.53574 1.24611 9.43483 1.48975C9.33391 1.73338 9.30751 2.00147 9.35895 2.26011C9.4104 2.51875 9.53739 2.75633 9.72386 2.9428C9.91033 3.12927 10.1479 3.25626 10.4065 3.3077C10.6652 3.35915 10.9333 3.33275 11.1769 3.23183C11.4205 3.13091 11.6288 2.96002 11.7753 2.74075C11.9218 2.52149 12 2.2637 12 1.99999C12 1.64637 11.8595 1.30723 11.6095 1.05718C11.3594 0.807133 11.0203 0.666656 10.6667 0.666656Z"
      fill="currentColor"
    />
  </svg>
);
export default MoreBtn;
