export default {
  shortMessageSetting: "SMS Settings",
  region: "Server Region",
  secretId: "Login ID",
  secretKey: "Password",
  sdkAppId: "SDK AppID",
  pincodeTemplateId: "Template ID",
  selectServer: "Select Service",
  beiJiJiuZhang: "BeiJiJiuZhang",
  tencentCloud: "Tencent Cloud SMS",
  buttonTextSet: "Set",
  unsetPlaceholder: "Not Set",
  serverStatus: "Service Status",
  statusHelp: "When enabled, users can verify their login and change their password using their mobile phone number",
  testTitle: "Test Message",
  testPlaceholder: "Enter the recipient's phone number for testing",
  modalTitle: "SMS Service Parameter Settings",
  modalRegionTitle: "Server Region (Region)",
  modalRegionPlaceholder: "Enter the server region, such as ap-beijing",
  modalSecretIdTitle: "Login ID (Secret ID)",
  modalSecretIdPlaceholder: "Enter the login ID of the SMS service",
  modalSecretKeyTitle: "Password (Secret Key)",
  modalSecretKeyPlaceholder: "Enter the password of the SMS service",
  modalSdkAppIdTitle: "SMS SDK AppID",
  modalSdkAppIdPlaceholder: "Enter the SDK AppID",
  modalPincodeTemplateIdTitle: "SMS Template ID (Pincode Template ID)",
  modalPincodeTemplateIdPlaceholder: "Enter the SMS template ID",
  cancel: "Cancel",
  save: "Save",
  sendSuccessToast: "Test SMS has been sent, please check your messages",
  pwdChange: "Change",
  pwdUnChange: "No Change",
};
