import { Checkbox as AntdCheckbox } from "antd";
import type { CheckboxGroupProps, CheckboxProps } from "antd/lib/checkbox";
import React, { useEffect, useState } from "react";
import { getScreenType } from "@/utils/common";

import styles from "./index.module.scss";

const OriginCheckbox: React.FC<CheckboxProps> = ({ className, ...rest }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(getScreenType().isMobile);
  }, []);

  const checkboxClassName = `${styles.a} ${styles.b} ${styles.checkbox} ${isMobile ? styles.mobileCheckbox : ""} ${className}`;

  return <AntdCheckbox className={checkboxClassName} {...rest} />;
};

const Checkbox = OriginCheckbox as typeof AntdCheckbox;

Checkbox.Group = AntdCheckbox.Group;

export type { CheckboxGroupProps, CheckboxProps };

export default Checkbox;
