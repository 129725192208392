import React from "react";

const PinboardTabRecycle = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66602 1.33301C6.29783 1.33301 5.99935 1.63148 5.99935 1.99967C5.99935 2.36786 6.29783 2.66634 6.66602 2.66634H3.33268C2.96449 2.66634 2.66602 2.96482 2.66602 3.33301C2.66602 3.7012 2.96449 3.99967 3.33268 3.99967H12.666C13.0342 3.99967 13.3327 3.7012 13.3327 3.33301C13.3327 2.96482 13.0342 2.66634 12.666 2.66634H9.33268C9.70087 2.66634 9.99935 2.36786 9.99935 1.99967C9.99935 1.63148 9.70087 1.33301 9.33268 1.33301H6.66602Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.666 5.99967C12.666 5.63148 12.3675 5.33301 11.9993 5.33301H3.99935C3.63116 5.33301 3.33268 5.63148 3.33268 5.99967V12.6663C3.33268 13.7709 4.22811 14.6663 5.33268 14.6663H10.666C11.7706 14.6663 12.666 13.7709 12.666 12.6663V5.99967ZM4.66602 6.66634H11.3327V12.6663C11.3327 13.0345 11.0342 13.333 10.666 13.333H5.33268C4.96449 13.333 4.66602 13.0345 4.66602 12.6663V6.66634Z"
      fill="currentColor"
    />
  </svg>
);

export default PinboardTabRecycle;
