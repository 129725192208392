import Asset from "./Asset";
import BasicConfig from "./BasicConfig";
import CustomDataset from "./CustomDataset";
import CustomTimePeriod from "./CustomTimePeriod";
import DateTags from "./DateTags";
import Dimensions from "./Dimensions";
import ExternalResource from "./ExternalResource";
import Feedback from "./Feedback";
import Measure from "./Measure";
import MemberGroups from "./MemberGroups";
import Members from "./Members";
import Model from "./Model";
import Rules from "./Rules";
import SyncKeywordTasks from "./SyncKeywordTasks";
import Synonym from "./Synonym";

export default {
  Asset,
  BasicConfig,
  CustomTimePeriod,
  DateTags,
  Dimensions,
  Feedback,
  Measure,
  MemberGroups,
  Members,
  Model,
  Rules,
  SyncKeywordTasks,
  Synonym,
  ExternalResource,
  CustomDataset,
};
