export default {
  factTable: "Fact table",
  dimensionTable: "Dimension table",
  confirm: "Confirm",
  cancel: "Cancel",
  removeConfirmText: "Confirm removal",
  okBtnText: "OK",
  closeBtnText: "Hide",
  cancelBtnText: "Cancel changes",
  saveBtnText: "Save changes",
  removeTableBtnText: "Delete Table",
  learnMoreBtnText: "Learn more",
  formerNameTableTip: "Please",
  latterNameTableTip:
    "Name, this name will become the workspace search term; at the same time, it is convenient for you to save the configuration for you to reuse in other models.",
  addCustomFields: "Custom fields",
  edit: "Edit",
  add: "Add ",
  globalFilter: "Global Filter",
  colNameInputPlaceholder: "Please enter a column name, keyword or alias to find",
  switchStatus: "Click to switch status",
  allCol: "All columns",
  selected: "Selected",
  formerInputName: "Please enter",
  latterInputName: "Name",
  autoFillBtnText: "Quick fill",
  autoFillModalTitle: "Quick fill",
  autoFillModalAlert: "Quick fill only overfills unchecked rows",
  addFilterTitle: "Add a global filter",
  editFilterTitle: "Edit global filter",
  globalFilterDesc: "The filter conditions of the fact table will take effect on all queries using this model after configuration.",
  addNewCustomFields: "Add custom fields",
  templateDownloadBtn: "Template download",
  uploadFilesBtn: "Upload files",
  reselectFileToast: "The selected file is too large, please select again",
  uploadFailedToast: "upload failed",
};
