import React from "react";

const ErrorBear = prop => (
  <svg {...prop} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="5" r="3" fill="currentColor" />
    <circle cx="4" cy="5" r="3" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.2071 10.2071C15.5976 9.81658 15.5976 9.18342 15.2071 8.79289C14.8166 8.40237 14.1834 8.40237 13.7929 8.79289L12 10.5858L10.2071 8.79289C9.81658 8.40237 9.18342 8.40237 8.79289 8.79289C8.40237 9.18342 8.40237 9.81658 8.79289 10.2071L10.5858 12L8.79289 13.7929C8.40237 14.1834 8.40237 14.8166 8.79289 15.2071C9.18342 15.5976 9.81658 15.5976 10.2071 15.2071L12 13.4142L13.7929 15.2071C14.1834 15.5976 14.8166 15.5976 15.2071 15.2071C15.5976 14.8166 15.5976 14.1834 15.2071 13.7929L13.4142 12L15.2071 10.2071Z"
      fill="currentColor"
    />
  </svg>
);

export default ErrorBear;
