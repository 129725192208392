export default {
  sumPrefix: "Total",
  sumSuffix: "Term",
  unValidTip: "The keyword is invalid, there is no such keyword in the system thesaurus",
  columnOperation: "Settings",
  editBtnText: "Edit",
  deleteBtnText: "Delete",
  deleteSuccessToast: "Delete successfully",
  uploadSuccessToast: "There is an alias or synonym modification, the system dictionary update task has been automatically started",
  clearSuccessToast: "Clear thesaurus successfully",
  clearConfirmTitle: "Confirm emptying the thesaurus? This operation cannot be undone",
  clearConfirmSuggest: "It is recommended to download the full amount first for backup",
  addSynonym: "Add synonyms",
  downloadAllBtnText: "Full download",
  cancelBtnText: "Cancel",
  okBtnText: "Confirm",
  searchPlaceholder: "Please enter the information you want to search",
  batchUploadBtnText: "Batch upload",
  clearBtnText: "Clear",
  lastUpdateTime: "Last updated to the dictionary:",
  lastUpdateTimeTitle: "Modified",
  deleteConfirmTitlePrefix: "Confirm the deletion",
  deleteConfirmTitleSuffix: "Synonyms of?",
  deleteConfirmContent: "This operation is irrevocable, please operate with caution!",
  deleteConfirmOkText: "Delete",
  deleteConfirmSuccessToast: "Delete successfully",
  filterAll: "All",
  filterValid: "Valid",
  filterUnValid: "Invalid",
  downloadFileName: "Synonym template",
};
