import React from "react";

const Tag = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 2.88765C2.25 2.53548 2.53548 2.25 2.88765 2.25H9.26411C9.43323 2.25 9.59542 2.31718 9.715 2.43676L15.1937 7.91543C15.5501 8.27391 15.75 8.7588 15.75 9.26411C15.75 9.76943 15.5501 10.2543 15.1937 10.6128L15.1924 10.6141L10.6208 15.1857C10.443 15.3636 10.2319 15.5047 10.0002 15.6009C9.76798 15.6971 9.51869 15.7468 9.2673 15.7468C9.01591 15.7468 8.76704 15.6973 8.53486 15.6011C8.30309 15.5048 8.09177 15.3638 7.91398 15.1858L2.43703 9.71526C2.31728 9.59566 2.25 9.43336 2.25 9.26411V2.88765ZM3.52529 3.52529V8.99977L8.81565 14.284C8.87461 14.343 8.94538 14.3906 9.02302 14.4229C9.10049 14.455 9.18349 14.4715 9.2673 14.4715C9.35111 14.4715 9.43412 14.455 9.51159 14.4229C9.58922 14.3906 9.65942 14.3436 9.71837 14.2846L14.2893 9.71359C14.4077 9.59424 14.4747 9.43238 14.4747 9.26411C14.4747 9.09584 14.4083 8.93451 14.2899 8.81517L8.99999 3.52529H3.52529Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.80059 6.07588C4.80059 5.37155 5.37155 4.80059 6.07588 4.80059H6.08226C6.78658 4.80059 7.35755 5.37155 7.35755 6.07588C7.35755 6.7802 6.78658 7.35117 6.08226 7.35117H6.07588C5.37155 7.35117 4.80059 6.7802 4.80059 6.07588Z"
      fill="currentColor"
    />
  </svg>
);

export default Tag;
