export default {
  You: "你",
  yourAccountBecome: "你的账号已从 ",
  updated: " 升级为 ",
  accountBecome: "账号转为",
  account: "账号",
  currentAccount: "你当前账号为",
  accountWill: "账号，将在",
  expired: "过期，账号过期后将无法登录",
  formerWillExpiredOneDay: "你的账号，将在",
  oneDay: "1天后",
  latterWillExpiredOneDay: "过期",
  removeYou: "被移出",
  contactAdmin: "，可联系工作区负责人",
  knowMore: "了解情况",
  pin: "报告",
  deleted: "已被删除",
  formerRemovedPin: "被移出",
  latterRemovedPin: "报告",
  welcome: "欢迎加入",
  join: "工作区的",
  youLose: "失去了工作区",
  managePower: "的管理权限，可联系工作区负责人",
  sharedPin: "分享报告",
  toWorkspace: "至工作区",
  movePin: "已将报告",
  toYou: "的负责人权限转让给",
  inPin: "在报告",
  powerChange: "的权限已改为 ",
  applyTo: "申请给",
  openPin: "开通报告",
  power: "的权限",
  youBecome: "成为了",
  manageEffective: "的管理员，管理权限在下次进入工作区时生效",
  pinApply: "申请报告",
  applyCalibration: "申请校准",
  modifySearchTerms: "修改搜索词",
  deleteSearchTerms: "删除搜索词",
  addSearchTerms: "新增搜索词",
  adopt: "通过",
  failed: "未通过",
  administrators: "管理员",
  reportsSubscribedForYou: "为你订阅的报告",
  deliveredOnTime: "已准时送达",
  setForYou: "为你设定了",
  push: "推送",
  mailboxPush: "邮箱推送",
  dingtalkPush: "钉钉机器人定时推送。",
  larkPush: "飞书机器人定时推送。",
  wecomPush: "企业微信机器人定时推送。",
  loggedOutUsers: "已注销的用户",
  deletedUserGroup: "【已删除的用户组】",
  you: "你",
  yourIn: "你的",
  userGroup: "用户组",
  deletedReports: "已删除的报告",
  reducedTo: " 降为 ",
  alarm: "告警",
  robotPush: "机器人定时推送",
  formerReportLeader: "报告",
  latterReportLeader: "的负责人",
  hasReject: "拒绝了",
  hasAgree: "同意了",
  your: "你",
  permissionApplication: "的权限申请",
  formerCalibrationYouRequested: "",
  middleCalibrationYouRequested: "你",
  latterCalibrationYouRequested: "申请校准 ",
  former2901: "你 为",
  middle2901: "申请的",
  latter2901: "权限",
  former1101: "已解散",
  latter1101: "工作区",
};
