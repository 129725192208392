import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.UploadFileSplitSymbol.${id}`);

export default class UploadFileSplitSymbol {
  static COMMA = ",";

  static SEMICOLON = ";";

  static DELIMITER = "|";

  static SPACE = " ";

  static TAB = "\t";

  static getLabelText = () => getText("label");

  static getText = key => {
    switch (key) {
      case this.COMMA:
        return getText("comma");
      case this.SEMICOLON:
        return getText("semicolon");
      case this.DELIMITER:
        return getText("delimiter");
      case this.SPACE:
        return getText("space");
      case this.TAB:
        return getText("tab");
      default:
        return "";
    }
  };

  static choices = [this.COMMA, this.SEMICOLON, this.DELIMITER, this.SPACE, this.TAB];

  static getOptions = () => this.choices.map(key => ({ label: this.getText(key), value: key }));
}
