export default {
  900: "无效的SQL语句",
  902: "无效的数据类型",
  903: "无效的表名",
  905: "关键词拼写错误",
  906: "缺失左括号",
  907: "缺失右括号",
  909: "参数个数错误",
  918: "模糊的字段使用",
  923: "FROM关键字缺失",
  925: "INTO关键字缺失",
  926: "VALUES关键字缺失",
  928: "SELECT关键字缺失",
  932: "值与目标字段类型不兼容",
  942: "表或视图不存在",
  950: "无效的DROP选项",
  955: "该名字已被存在的对象使用",
  957: "重复的字段名",
  960: "搜索列表中存在模糊的字段名",
  972: "标识符太长",
  999: "无效的视图名",
  1036: "非法的变量名或变量",
  1400: "不可向字段插入NULL值",
  1403: "未找到数据",
  1410: "无效的行编号",
  1435: "用户不存在",
  1438: "值超过该字段的精度限制",
  1476: "除数为0",
  1730: "创建视图时字段数量模糊",
  1745: "无效的主机或绑定变量名",
  1747: "无效的表或字段规范",
  1861: "文字不匹配格式字符串",
  4043: "未找到对象",
  4063: "包的主体存在错误",
  6576: "不是一个有效的函数或者进程名",
  12899: "值对该字段太大",
  20983: "包不存在",
  24333: "SQL语句拼写错误",
  24381: "数组插入或合并错误",
};
