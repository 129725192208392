/* 一些常用的字符串常量 */
export default class Str {
  static ALT_TEXT = "AlternativeText"; // 图片对象的属性，用来保存图表数据

  static DESC = "Descr"; // 表格对象的属性，用来保存图表数据

  static TITLE = "Title"; // 图片和表格对象都有的属性，用来标记图表在更新列表中的索引值

  static CURR_BASE64 = "currentBase64"; // 当前图片的 base64

  static PREV_BASE64 = "previousBase64"; // 替换成新图后，用来保存旧图的 base64，撤回图表时使用

  static CURR_MATRIX = "currentMatrix"; // 当前表格的单元格数据

  static PREV_MATRIX = "previousMatrix"; // 替换成新表格后，用来保存旧表格的单元格数据，撤回表格时使用
}
