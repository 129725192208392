export default {
  dateRangeLabel: "在时间范围内",
  dateRangeHelp: "可直接被算法检索的维度值所在的时间范围",
  dateRangeInputPlaceholder: "请输入时间范围，如2021年-2022年、前3个月等自然语言时间范围",
  idLabel: "限制依据指标",
  idHelp: "用来限制可直接被算法检索的维度值数量",
  idSelectPlaceholder: "请选择添加的指标",
  ascending: "升序",
  descending: "降序",
  limitLabel: "选取关键词的数量",
  limitHelp: "以上条件下可直接被算法检索的维度值最大数量",
  limitInputPlaceholder: "请输入小于100000的整数",
  setConstraintsModalTitle: "添加限制条件",
  confirm: "确认",
  cancel: "取消",
  keywordInputPlaceholder: "搜索关键词",
  showMoreBtnText: "显示更多",
  changeInputPlaceholder: "请输入您想修改的内容",
  reply: "回复",
  comment: "评论",
  commentInputPlaceholder: "您觉得这组数据怎么样呢？",
  maxStrLengthToast: "每行字符串长度不能超过",
  maxNumToast: "参与排序的总数量不能超过",
  setCustomOrder: "设置自定义排序",
  currentSetting: "现有设置",
  clearConfirmInfo: "确认清空当前的排序",
  clearOver: "清空",
  save: "保存",
  loadMore: "加载更多",
  uploading: "上传中...",
  newSetAlert: "搜索结果会默认按此顺序展示",
  multipleUpload: "批量上传",
  noCustomSortTip: "当前没有自定义的展示顺序",
  downloadConfigBtnText: "下载现有配置",
  selectFileToUpload: "请点击选择文件或拖拽文件到此处",
  selectFileToUploadAgain: "重新选择文件上传",
  example: "示例:",
  yourSearch: "你的搜索",
  fromDataSource: "来源模型",
  customField: "自定义字段",
  cumulative: "累计指标",
  timeGroup: "时间序",
  customOrder: "自定义序",
  searchSQL: "搜索SQL",
  explanationTipTitle: "你为何看到该图表",
  setSuccessToast: "设置成功！",
  allUsers: "所有用户",
  loadMoreUsers: "加载更多用户",
  allGroups: "所有群组",
  loadMoreGroups: "加载更多群组",
  userSearchInputPlaceholder: "搜索用户和群组",
  selectAllUsers: "选中工作区所有用户和用户组",
  notSelect: "未选中对象",
  select: "选中",
  firstSearchHint: "有",
  secondSearchHint: "位用户",
  thirdSearchHint: "个群组包含",
  fourthSearchHint: "个群组",
  allowAllUsersCoop: "允许工作区所有用户和用户组协作",
  clear: "清除",
  next: "下一步",
  user: "用户",
  group: "群组",
  allowEdit: "是否允许编辑",
  backToSelectBtnText: "返回重选",
  collaborateBtnText: "协作",
  selectCollaborators: "选择协作对象",
  remove: "移除",
  coopUser: "协作的用户",
  editable: "可编辑",
  coopGroup: "协作的群组",
  selectAll: "全选",
  clearSelect: "移除所选",
  manageCoop: "管理协作",
  manage: "管理",
  batchRemove: "批量移除",
  complete: "完成",
  measurePrefix: "指标【",
  measureSuffix: "】",
  joinText: "的",
  maxNumSuffix: "个。",
  period: "。",
  downloadFileNameSuffix: "维度自定义排序.txt",
  example1: "北京",
  example2: "上海",
  example3: "广州",
  dataRange: "数据范围",
  filter: "筛选",
  calcTitle: "计算逻辑",
  calcExtra: "实际计算时数据范围会参与运算",
  calc: "计算",
  aggregation: "聚合",
  other: "特殊",
  sortTitle: "默认排序",
  sort: "排序",
  desc: "降序",
};
