import { Input as AntdInput } from "antd";
import { InputRef, PasswordProps } from "antd/lib/input";
import React from "react";

import "./Password.module.scss";

const InternalPassword: React.FC<PasswordProps> = React.forwardRef(({ className, ...rest }, ref: React.Ref<InputRef>) => {
  let passwordClassName = "passwordPrefixA passwordPrefixB";
  if (className) passwordClassName += ` ${className}`;

  return <AntdInput.Password ref={ref} className={passwordClassName} {...rest} />;
});

const Password = InternalPassword as typeof AntdInput.Password;

export default Password;
