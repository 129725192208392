import React from "react";

const PinboardShare = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.125 7.5C14.5748 7.5 15.75 6.32475 15.75 4.875C15.75 3.42525 14.5748 2.25 13.125 2.25C11.6753 2.25 10.5 3.42525 10.5 4.875C10.5 5.03611 10.5145 5.19382 10.5423 5.34691L6.97503 7.04976C6.49611 6.4123 5.73371 5.99998 4.875 5.99998C3.42525 5.99998 2.25 7.17523 2.25 8.62498C2.25 10.0747 3.42525 11.25 4.875 11.25C5.6724 11.25 6.38676 10.8944 6.86819 10.3332L10.5945 12.443C10.5329 12.6661 10.5 12.901 10.5 13.1437C10.5 14.5934 11.6753 15.7687 13.125 15.7687C14.5748 15.7687 15.75 14.5934 15.75 13.1437C15.75 11.6939 14.5748 10.5187 13.125 10.5187C12.3216 10.5187 11.6024 10.8796 11.1209 11.4482L7.40087 9.34201C7.46544 9.1141 7.5 8.87357 7.5 8.62498C7.5 8.4358 7.47999 8.2513 7.44197 8.07347L10.9779 6.38557C11.4531 7.05966 12.2376 7.5 13.125 7.5ZM13.125 6.375C13.9534 6.375 14.625 5.70343 14.625 4.875C14.625 4.04657 13.9534 3.375 13.125 3.375C12.2966 3.375 11.625 4.04657 11.625 4.875C11.625 5.70343 12.2966 6.375 13.125 6.375ZM13.125 14.6437C13.9534 14.6437 14.625 13.9721 14.625 13.1437C14.625 12.3152 13.9534 11.6437 13.125 11.6437C12.2966 11.6437 11.625 12.3152 11.625 13.1437C11.625 13.9721 12.2966 14.6437 13.125 14.6437ZM6.375 8.62498C6.375 9.4534 5.70343 10.125 4.875 10.125C4.04657 10.125 3.375 9.4534 3.375 8.62498C3.375 7.79655 4.04657 7.12498 4.875 7.12498C5.70343 7.12498 6.375 7.79655 6.375 8.62498Z"
      fill="currentColor"
    />
  </svg>
);

export default PinboardShare;
