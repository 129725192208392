export default {
  transferPinboardsModalTitle: "User Asset Handling",
  removeAccountConfirmText: "Confirm and remove the original user",
  cancelBtnText: "Cancel",
  selectOwnerPlaceholder: "Please select the person in charge after the file change",
  pinboardName: "Dashboard name",
  editAccess: "Whether to open editing",
  true: "Yes",
  false: "No",
  numberUsers: "Number of editors",
  action: "Treatment method",
  transfer: "Transfer",
  delete: "Delete",
  transferTarget: "Transfer target",
  transferMethod: "Specific processing method",
  pinboard: "Report",
  alert: "Data alert",
};
