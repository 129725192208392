import React from "react";

const CardFolderOutlined = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.29167 10.5835C4.85444 10.5835 4.5 10.9379 4.5 11.3752C4.5 11.8124 4.85444 12.1668 5.29167 12.1668H12.7083C13.1456 12.1668 13.5 11.8124 13.5 11.3752C13.5 10.9379 13.1456 10.5835 12.7083 10.5835H5.29167Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6665 0.5C1.56193 0.5 0.666504 1.39543 0.666504 2.5V13.5002C0.666504 14.6047 1.56193 15.5002 2.6665 15.5002H15.3332C16.4377 15.5002 17.3332 14.6047 17.3332 13.5002V5.5C17.3332 4.39543 16.4377 3.5 15.3332 3.5H11.1136L10.2378 1.49853C9.97238 0.891939 9.37305 0.5 8.71093 0.5H2.6665ZM10.1327 5H15.3332C15.6093 5 15.8332 5.22386 15.8332 5.5V13.5002C15.8332 13.7763 15.6093 14.0002 15.3332 14.0002H2.6665C2.39036 14.0002 2.1665 13.7763 2.1665 13.5002V2.5C2.1665 2.22386 2.39036 2 2.6665 2H8.71093C8.77714 2 8.83708 2.03919 8.86362 2.09985L10.1327 5Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default CardFolderOutlined;
