import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.FormatType.${id}`);

export default class FormatType {
  static TEXT = "text";

  static LONG_TEXT = "longText";

  static LIST = "list";

  static INT = "int";

  static CUSTOM_COUNT = "customCount";

  static PERCENTAGE = "percentage";

  static SECOND = "second";

  static MINUTE = "minute";

  static HOUR = "hour";

  static DATE = "date";

  static DATETIME = "datetime";

  static DAY = "day";

  static WEEK = "week";

  static MONTH = "month";

  static QUARTER = "quarter";

  static YEAR = "year";

  static FLOAT = "float";

  static ORDERED_DAY = "orderedDay";

  static ORDERED_WEEK = "orderedWeek";

  static ORDERED_MONTH = "orderedMonth";

  static ORDERED_QUARTER = "orderedQuarter";

  static PHONE = "phone";

  static ID = "id";

  static NUMBER = "number";

  static THOUSAND_DIVIDE = "thousandDivide";

  static PERCENT = "percent";

  static DECIMAL = "decimal";

  static NEGATIVE = "negative";

  static BASE = "base";

  static UNIT_ICON = "unitIcon";

  static getText = formatType => {
    if (formatType === FormatType.TEXT) return getTextById("text");
    if (formatType === FormatType.LONG_TEXT) return getTextById("longText");
    if (formatType === FormatType.LIST) return getTextById("list");
    if (formatType === FormatType.FLOAT) return getTextById("float");
    if (formatType === FormatType.SECOND) return getTextById("second");
    if (formatType === FormatType.MINUTE) return getTextById("minute");
    if (formatType === FormatType.HOUR) return getTextById("hour");
    if (formatType === FormatType.DATE) return getTextById("date");
    if (formatType === FormatType.DATETIME) return getTextById("datetime");
    if (formatType === FormatType.ORDERED_DAY) return getTextById("day");
    if (formatType === FormatType.WEEK) return getTextById("week");
    if (formatType === FormatType.ORDERED_WEEK) getTextById("week");
    if (formatType === FormatType.MONTH) return getTextById("month");
    if (formatType === FormatType.ORDERED_MONTH) return getTextById("month");
    if (formatType === FormatType.QUARTER) return getTextById("quarter");
    if (formatType === FormatType.ORDERED_QUARTER) return getTextById("quarter");
    if (formatType === FormatType.YEAR) return getTextById("year");
    if (formatType === FormatType.PERCENTAGE) return getTextById("percentage");
    if (formatType === FormatType.PHONE) return getTextById("phone");
    if (formatType === FormatType.ID) return getTextById("id");
    if (formatType === FormatType.INT) return getTextById("integer");
    if (formatType === FormatType.DECIMAL) return getTextById("decimal");
    return `${formatType}`;
  };

  static isNumberType = type => [this.INT, this.NUMBER, this.DECIMAL, this.PERCENTAGE, this.FLOAT].includes(type);

  static isDayFormat = type => [this.DATE, this.WEEK].includes(type);
}
