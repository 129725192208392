export default {
  sumPrefix: "共",
  sumSuffix: "项",
  unValidTip: "关键词失效，系统词库中无此关键词",
  columnOperation: "操作",
  editBtnText: "编辑",
  deleteBtnText: "删除",
  deleteSuccessToast: "删除成功",
  uploadSuccessToast: "有别名或同义词修改，已自动启动系统词典更新任务",
  clearSuccessToast: "清空同义词库成功",
  clearConfirmTitle: "确认清空同义词库？该操作无法撤销",
  clearConfirmSuggest: "建议先全量下载进行备份",
  addSynonym: "添加同义词",
  downloadAllBtnText: "全量下载",
  cancelBtnText: "取消",
  okBtnText: "确认",
  searchPlaceholder: "请输入想搜索的信息",
  batchUploadBtnText: "批量上传",
  clearBtnText: "清空",
  lastUpdateTime: "最近更新到词典的时间：",
  lastUpdateTimeTitle: "更新时间",
  deleteConfirmTitlePrefix: "确定删除",
  deleteConfirmTitleSuffix: "的同义词吗？",
  deleteConfirmContent: "该操作不可撤销，请谨慎操作！",
  deleteConfirmOkText: "删除",
  deleteConfirmSuccessToast: "删除成功",
  filterAll: "全部",
  filterValid: "有效",
  filterUnValid: "无效",
  downloadFileName: "同义词模板",
};
