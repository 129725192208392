export default class SearchPageSource {
  // 搜索页
  static SEARCH_PAGE = 1;

  // 报告中替换卡片
  static REPLACE_CARD = 2;

  // 报告中添加图表
  static ADD_CHART = 3;

  // 首页点击输入框或搜索按钮跳转
  static FRONT_PAGE = 4;

  // 报告卡片页面下钻跳转
  static DRILL_PAGE = 5;
}
