import React from "react";

const WPSRecall = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="currentColor" fillOpacity="0.1" />
    <path
      d="M16.3137 12.5751C16.4546 12.5751 16.5905 12.6268 16.6958 12.7204C16.8011 12.8141 16.8683 12.943 16.8848 13.083L16.8889 13.1502V15.7385C16.8888 15.8794 16.8371 16.0153 16.7435 16.1206C16.6499 16.2259 16.5209 16.2931 16.381 16.3096L16.3137 16.3136H13.1014L13.5569 16.7697C13.656 16.8688 13.7155 17.0006 13.7242 17.1403C13.733 17.2801 13.6905 17.4183 13.6047 17.529L13.5569 17.583C13.4579 17.6821 13.3261 17.7415 13.1863 17.7503C13.0466 17.7591 12.9084 17.7166 12.7977 17.6308L12.7437 17.583L11.3057 16.1451C11.2067 16.0461 11.1472 15.9143 11.1384 15.7745C11.1296 15.6348 11.1722 15.4966 11.258 15.3859L11.3057 15.3318L12.7437 13.8939C12.8472 13.7908 12.986 13.7309 13.1321 13.7264C13.2782 13.722 13.4205 13.7733 13.5301 13.8699C13.6397 13.9666 13.7084 14.1013 13.7222 14.2468C13.7361 14.3923 13.6941 14.5375 13.6047 14.6531L13.5569 14.7072L13.1008 15.1633H15.738L15.7385 13.1502C15.7385 13.0094 15.7903 12.8734 15.8839 12.7681C15.9775 12.6629 16.1065 12.5956 16.2464 12.5791L16.3137 12.5751ZM8.26141 17.1764C7.95632 17.1764 7.66373 17.0552 7.44801 16.8395C7.23228 16.6237 7.11108 16.3311 7.11108 16.0261V7.39862C7.11108 7.24755 7.14084 7.09797 7.19865 6.95841C7.25646 6.81884 7.34119 6.69203 7.44801 6.58521C7.55482 6.4784 7.68164 6.39366 7.8212 6.33585C7.96076 6.27805 8.11035 6.24829 8.26141 6.24829H15.1634C15.3144 6.24829 15.464 6.27805 15.6036 6.33585C15.7431 6.39366 15.87 6.4784 15.9768 6.58521C16.0836 6.69203 16.1683 6.81884 16.2261 6.95841C16.2839 7.09797 16.3137 7.24755 16.3137 7.39862V10.9646C16.3137 11.1172 16.2531 11.2634 16.1452 11.3713C16.0373 11.4791 15.8911 11.5397 15.7385 11.5397C15.586 11.5397 15.4397 11.4791 15.3319 11.3713C15.224 11.2634 15.1634 11.1172 15.1634 10.9646V7.39862H8.26141V16.0261H9.41174C9.56426 16.0261 9.71054 16.0867 9.81838 16.1946C9.92623 16.3024 9.98681 16.4487 9.98681 16.6012C9.98681 16.7538 9.92623 16.9 9.81838 17.0079C9.71054 17.1158 9.56426 17.1764 9.41174 17.1764H8.26141Z"
      fill="currentColor"
      fillOpacity="0.65"
    />
  </svg>
);

export default WPSRecall;
