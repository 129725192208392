export default {
  overLimit: "超额",
  noLimit: "不限",
  headerTitle: "资源管理",
  nameLabel: "客户名称",
  expiryDate: "有效期",
  expired: "已过期",
  restPrefix: "仅剩",
  restSuffix: "天",
  pinboardLabel: "报告数量",
  accountResourcesLabel: "账号资源",
  accountNormalLabel: "正常",
};
