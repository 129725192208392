export default class AsyncTaskType {
  static SYNC_KEYWORD = 0; // 同义词关键词任务，无需上传文件

  static UPLOAD_FILE = 1; // 上传数据源数据

  static GENERATE_DICT = 2; // 生成词典，无需上传文件

  static DEAL_SYNONYM = 3; // 同义词

  static DOWNLOAD_DATA = 4; // 下载表格 CSV

  static UPLOAD_DATA_PREVIEW = 5; // 上传 csv/excel 数据预览

  static UPDATE_DATABASE_SCHEMA = 7; // 更新表结构

  static QUERY_DATA = 8; // 获取查询的数据

  static FORECAST_DATA = 9; // 获取查询的预测数据

  static QUERY_CUSTOM_SQL_DATA = 10; // 自定义 sql 查询

  static AUTO_INSIGHTS = 11; // 获取智能解析的数据

  static INTERNAL_DATABASE_DOWNLOAD = 12; // 自有数据源 table 下载

  static TIME_FORECAST_DATA = 13; // 时序预测

  static DOWNLOAD_WORKSPACE_CONFIGURATION = 14; // 导出工作区配置

  static UPLOAD_WORKSPACE_CONFIGURATION = 15; // 导入工作区配置

  static BATCH_UPLOAD_WORKSPACE_USERS = 16; // 工作区批量导入用户

  static DOWNLOAD_WORKSPACE_USERS = 17; // 工作区下载用户数据

  static DOWNLOAD_PINBOARD_SEND_LOGS = 18; // 下载报告推送日志

  static GENERATE_FUZZY_DICT = 19; // 模糊词典构建任务
}
