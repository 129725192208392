import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.WorkspaceMemberStatus.${id}`);

export default class WorkspaceMemberStatus {
  // 基础设置界面 tabs
  static ENABLE = 1;

  static DISABLE = 2;

  static getText = t => {
    switch (t) {
      case this.ENABLE:
        return getTextById("enable");
      case this.DISABLE:
        return getTextById("disable");
      default:
        return "";
    }
  };
}
