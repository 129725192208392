import { Input as AntdInput } from "antd";
import { InputRef, SearchProps } from "antd/lib/input";
import React from "react";
import { BaseInputClearButton } from "@/components/CloseButton";
import { Search as SearchIcon } from "@/components/SVGIcons";

import "./Search.module.scss";

const InternalSearch: React.FC<SearchProps> = React.forwardRef(({ className, defaultValue, value, ...rest }, ref: React.Ref<InputRef>) => {
  let searchClassName = `searchPrefixA searchPrefixB inputSearch`;
  if (className) searchClassName += ` ${className}`;

  return (
    <AntdInput.Search
      allowClear={{ clearIcon: <BaseInputClearButton /> }}
      ref={ref}
      prefix={<SearchIcon />}
      suffix={null}
      className={searchClassName}
      defaultValue={defaultValue}
      value={value}
      {...rest}
    />
  );
});

const TextArea = InternalSearch as typeof AntdInput.Search;

export default TextArea;
