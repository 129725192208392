export default {
  timedReminderSetSuccess: "Schedule set successfully",
  timedReminderConfig: "Schedule settings",
  timedReminderIsOpen: "Schedule is on",
  timedReminderIsClose: "Schedule is off",
  pleaseInputMessageTitle: "Please enter message name",
  pleaseInputMessageDesc: "Please enter message description",
  titleAndDesc: "Title and description",
  viewBy: "View by",
  link: "Link",
  reminderFrequency: "Schedule frequency",
  pleaseChoose: "Please choose",
  useAtLeastOne: "Use at least one",
  reminderMethod: "Schedule method",
  reminderRecipient: "Schedule recipient",
  allCollaborators: "All collaborators",
  messagePrompt: "Message prompt",
  messageCenterPush: "Message center push",
  mailPush: "Mail push",
  requireBindMail: "User email binding required",
  larkReminder: "Lark schedule",
  requireBindLark: "User Lark account binding required",
  dingtalkReminder: "DingTalk schedule",
  requireBindDingtalk: "User DingTalk account binding required",
  wecomReminder: "WeCom schedule",
  requireBindWecom: "User WeCom account binding required",
  selectUser: "Select user",
  downloadRecord: "Download recent sending records",
  pinboardNotExists: "Pinboard does not exist",
  noData: "No push logs yet",
  generateFileFail: "Failed to generate file",
  uploadFail: "Failed to upload file",
};
