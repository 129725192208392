export default {
  1005: "Failed to create table",
  1006: "Failed to create database",
  1007: "Database already exists: failed to create database",
  1008: "Database does not exist, failed to delete database",
  1009: "Failed to delete database due to inability to delete database file",
  1010: "Failed to delete database due to inability to delete data directory",
  1011: "Failed to delete database file",
  1012: "Failed to read record in system table",
  1020: "Record has been modified by another user",
  1021: "Insufficient disk space, please increase available disk space",
  1022: "Keyword duplication, failed to modify record",
  1023: "Error occurred while closing",
  1024: "File reading error",
  1025: "Error occurred while renaming",
  1026: "File writing error",
  1032: "Record does not exist",
  1036: "The data table is read-only and cannot be modified",
  1037: "Insufficient system memory, please restart the database or server",
  1038: "Insufficient memory for sorting, please increase sorting buffer",
  1040: "Maximum connection to the database has been reached, please increase available connections",
  1041: "Insufficient system memory",
  1042: "Invalid hostname",
  1043: "Invalid connection",
  1044: "Current user does not have permission to access the database",
  1045: "Cannot connect to the database, incorrect username or password",
  1048: "Field cannot be empty",
  1049: "Database does not exist",
  1050: "Data table already exists",
  1051: "Data table does not exist",
  1054: "Field does not exist",
  1065: "Invalid SQL statement, SQL statement is empty",
  1081: "Cannot establish Socket connection",
  1114: "Data table is full and cannot accommodate any more records",
  1116: "Too many data tables are open",
  1129: "Database has encountered an exception, please restart the database",
  1130: "Failed to connect to the database, no permission to connect to the database",
  1133: "Database user does not exist",
  1141: "Current user does not have access to the database",
  1142: "Current user does not have access to the data table",
  1143: "Current user does not have access to the fields in the data table",
  1146: "Data table does not exist",
  1147: "Undefined user access to data table",
  1149: "Syntax error in SQL statement",
  1158: "Network error, read error occurred, please check network connection",
  1159: "Network error, read timeout occurred, please check network connection",
  1160: "Network error, write error occurred, please check network connection",
  1161: "Network error, write timeout occurred, please check network connection",
  1162: "Duplicate field value, failed to add to database",
  1169: "Duplicate field value, failed to update record",
  1177: "Failed to open data table",
  1180: "Failed to commit transaction",
  1181: "Failed to roll back transaction",
  1203: "The current user has reached the maximum number of connections allowed to the database, please increase available database connections or restart the database",
  1205: "Lock timeout",
  1211: "Current user does not have permission to create users",
  1216: "Foreign key constraint check failed, failed to update child table record",
  1217: "Foreign key constraint check failed, failed to delete or modify parent table record",
  1226: "The resources used by the current user have exceeded the allowed resources, please restart the database or server",
  1227: "Insufficient privileges, you do not have permission to perform this operation",
  2013: "Failed to connect to the server, there may be network timeout or port occupancy issues",
};
