export default {
  cardAlarmSetting: "Card reminder settings",
  triggerConditions: "Trigger condition",
  thresholdPlaceholder: "Please fill in a numerical value",
  thresholdSuffix: "will trigger reminder",
  calculateFrequency: "calculation frequency",
  calculateFrequencyTip:
    "The system calculates card data at this frequency and sends instant notifications when the triggering conditions are met",
  settingThreshold: "Set reminder threshold",
  settingThresholdTip: "Draw a threshold reference line",
  equal: "equal",
  chartTriggerConditionFragment1: "Reminder threshold while",
  chartTriggerConditionFragment2: "the number of data points",
  chartTriggerConditionFragment3: "will trigger reminder",
  invalidQuestion: "Invalid alert question",
  invalidCard: "Error generating alert card",
  invalidCardType: "Error in alert card generation type",
  invalidSqlIndex: "Incorrect number of SQL statements in alert card",
  invalidMeasureIndex: "Incorrect number of measures in alert card",
  invalidMeasureNes: "Invalid measures in alert card",
  noMember: "No users to be pushed to",
};
