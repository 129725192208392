export default {
  copySuccessToast: "已复制",
  copyFailToast: "复制不成功，请稍候再试",
  copy: "复制",
  edit: "编辑",
  loseDataColTip: "数据列丢失，您可取消选择该列。",
  firstGuideStep: "连接数据源",
  noDataBaseAuthTip: "您没有数据库连接权限，请联系负责人完成此步骤",
  secondGuideStep: "创建模型",
  secondGuideStepDesc: "创建以事实表为中心的星型模型",
  guide: "教程",
  thirdGuideStep: "推送关键词&构建词典（自动触发）",
  thirdGuideStepDesc: "服务需要同步数据库中的维度值，并构建词典",
  guideFinish: "任务完成就可以搜索数据啦！",
  guideWelcomeText: "欢迎使用！",
  guideBeginText: "完成以下步骤您就可以享受搜索数据的乐趣啦~",
  advancedFunctionGuide: "其他进阶功能可以查阅",
  instruction: "说明手册",
  skipNewGuide: "跳过新人手册",
  newGuide: "新人手册",
  noAuth: "抱歉，您无权限～",
  noAuthTip: "抱歉，您暂无权限，请看看其他页面",
  titleInputPlaceholder: "标题名称不能为空",
  descInputPlaceholder: "请输入简介",
  cannotEditTooltip: "暂不支持修改",
};
