export default {
  token: {
    motion: false, // 关闭动画
    // seed token
    colorPrimary: "#4759fd",
    fontFamily: "Roboto, 'PingFang SC', 'Helvetica Neue', Helvetica, Tahoma, Arial, 'Microsoft YaHei', sans-serif",
    // map token
    colorBgLayout: "var(--datarc-color-bg-1)",
    colorBorder: "var(--datarc-color-border)",
    colorInfoText: "var(--datarc-color-primary)",
    colorInfoTextActive: "var(--datarc-color-primary-active)",
    colorInfoTextHover: "var(--datarc-color-primary-hover)",
    colorPrimaryActive: "var(--datarc-color-primary-active)",
    colorPrimaryText: "var(--datarc-color-primary)",
    colorPrimaryTextActive: "var(--datarc-color-primary-active)",
    colorPrimaryTextHover: "var(--datarc-color-primary-hover)",
    colorSuccessText: "var(--datarc-color-success)",
    colorSuccessTextActive: "var(--datarc-color-success-active)",
    colorSuccessTextHover: "var(--datarc-color-success-hover)",
    colorText: "var(--datarc-color-text-0)",
    colorTextQuaternary: "var(--datarc-color-text-3)",
    colorTextSecondary: "var(--datarc-color-text-1)",
    colorTextTertiary: "var(--datarc-color-text-2)",
    colorWarningText: "var(--datarc-color-warning)",
    colorWarningTextActive: "var(--datarc-color-warning-active)",
    colorWarningTextHover: "var(--datarc-color-warning-hover)",
    colorBgElevated: "var(--datarc-color-white)",

    // alias token
    colorLink: "var(--datarc-color-primary)",
    colorLinkActive: "var(--datarc-color-primary-active)",
    colorLinkHover: "var(--datarc-color-primary-hover)",
  },
  components: {
    Dropdown: {
      colorText: "var(--datarc-color-text-0)",
      controlItemBgHover: "var(--datarc-color-bg-2)",
    },
    Button: {
      borderRadius: 100,
      borderRadiusLG: 100,
      borderRadiusSM: 100,
      colorLink: "var(--datarc-color-primary)",
      colorLinkActive: "var(--datarc-color-primary-hover)",
      colorLinkHover: "var(--datarc-color-primary-hover)",
      colorText: "var(--datarc-color-text-0)",
      colorBgContainer: "var(--datarc-color-fill-0)",
      colorPrimary: "var(--datarc-color-primary)",
      colorPrimaryHover: "var(--datarc-color-primary-hover)",
      colorFillContentHover: "var(--datarc-color-fill-1)",
      colorBgTextHover: "var(--datarc-color-fill-1)",
      colorError: "var(--datarc-color-danger)",
      colorErrorHover: "var(--datarc-color-danger-hover)",
    },
    Radio: {
      borderRadius: 100,
      borderRadiusLG: 100,
      borderRadiusSM: 100,
    },
    Modal: {
      borderRadiusLG: 16,
      colorBgElevated: "var(--datarc-color-white)",
      paddingContentHorizontalLG: 0,
      paddingMD: 0,
    },

    Select: {
      colorTextPlaceholder: "var(--datarc-color-text-2)",
      controlItemBgHover: "var(--datarc-color-bg-2)",
      controlItemBgActive: "var(--datarc-color-bg-3)",
      colorBgContainer: "var(--datarc-color-white)",
      fontWeightStrong: 400,
    },
    Menu: {
      itemSelectedColor: "var(--datarc-color-primary) !important",
      itemBg: "var(--datarc-color-white)",
    },
    Input: {
      colorTextPlaceholder: "var(--datarc-color-text-2)",
      colorBgContainer: "var(--datarc-color-white)",
    },
    Checkbox: {
      colorPrimaryHover: "var(--datarc-color-primary)",
      colorPrimaryBorder: "var(--datarc-color-primary)",
      colorPrimary: "var(--datarc-color-primary)",
    },
    Table: {
      colorBorderSecondary: "var(--datarc-color-border)",
    },
  },
};
