export default {
  editModalTitle: "编辑时间周期",
  addModalTitle: "添加时间周期",
  editConfirmBtnText: "确认",
  addConfirmBtnText: "添加",
  keyword: "关键词",
  keywordTip: "搜索时触发此时间范围的关键词",
  keywordInputErrMsg: "请选择关键词",
  keywordInputPlaceholder: "请输入搜索关键词",
  emptyInputErrMsg: "关键词不能为空",
  invalidInputErrMsg: "关键词不能是纯数字",
  timeUnit: "时间单位",
  timeUnitErrMsg: "请选择时间单位",
  timeUnitSelectPlaceholder: "选择作用的时间单位",
  offset: "起始时间",
  offsetErrMsg: "请选择起始时间",
  offsetSelectPlaceholder: "选择作用的起始时间",
};
