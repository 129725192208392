export default {
  colPermissionUpdateSuccessToast: "Column permission configuration updated successfully",
  openColPermissionConfirmText: "Confirm to enable column permissions?",
  openColPermissionTip:
    "After opening, you need to configure the permission content and update it, otherwise it may cause no data in the search results",
  confirm: "Confirm",
  cancel: "Cancel",
  refreshBtnText: "Refresh",
  dimensionPermission: "Dimension permission",
  allModels: "All models",
  appList: "App List",
  updateBtnText: "Update",
  colPermission: "Column permission",
  noPermissionTip: "Permissions are not enabled, there are no restrictions when users search for column data",
  resourceEffectAllowTip:
    "Please add the resources that need to be added to the list from the left to the right. Only the resources that are added to the list on the right are allowed to be used",
  resourceEffectNotAllowTip:
    "Please add the resources that need to be added to the list from the left to the right, and the resources added to the list on the right will be banned",
  measurePermission: "Measures Permission",
  updateDatabaseToast: "The synchronization data table task will start in 1 minute, please avoid creating and modifying model operations",
  updateDatabaseBtnText: "Sync data tables",
  retryBtnText: "Retry",
  tableNameSearchPlaceholder: "Enter table name or keyword to lookup",
  modelNameInputPlaceholder: "Please enter the model name",
  setModelName: "Click to set the model name",
  addTag: "Add tag",
  tag: "Tag",
  exist: "Already exists",
};
