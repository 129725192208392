import React from "react";

const CollapseArrow = props => (
  <svg {...props} width="1em" height="0.5em" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25391 0.714478L8.5468 0.00737087L5.01127 3.5429L1.47573 0.00737143L0.768626 0.714478L4.65768 4.60353C4.65768 4.60353 4.65771 4.60356 5.01127 4.25001L4.65768 4.60353L5.01127 4.95712L9.25391 0.714478Z"
      fill="currentColor"
    />
  </svg>
);

export default CollapseArrow;
