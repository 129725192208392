import enUS from "antd/es/locale/en_US";
import zhCN from "antd/es/locale/zh_CN";
import _ from "lodash";
import localeLanguage from "@/locale";

const LOCALE_LOCAL_STORAGE_KEY = "locale";
export const ZH_CN = "zh-CN";
export const EN_US = "en-US";

export const languageTextMap = {
  [ZH_CN]: "简体中文",
  [EN_US]: "English",
};

export const languageList = [
  {
    name: languageTextMap[ZH_CN],
    value: ZH_CN,
  },
  {
    name: languageTextMap[EN_US],
    value: EN_US,
  },
];

export const antdLocaleConfig = {
  [ZH_CN]: zhCN,
  [EN_US]: enUS,
};

export const getLocale = () => {
  const configuredLocale = localStorage.getItem(LOCALE_LOCAL_STORAGE_KEY) || window.defaultLanguage;

  // 如果没有设置过语言，跟随浏览器语言，PM 说暂时关闭这个功能
  // if (configuredLocale === null) {
  //   setLocale(window.navigator.language);
  //   return window.navigator.language;
  // }

  // 保存的语言不在支持的语言列表中，设置成默认语言
  if (undefined === languageList.find(item => item.value === configuredLocale)) {
    setLocale(ZH_CN);
    return ZH_CN;
  }

  return configuredLocale;
};

export const setLocale = val => {
  localStorage.setItem("locale", val);
};

export const getAskLocaleParams = () => ({ language: getLocale() });

const languageKeyMap = {
  [ZH_CN]: "zhCN",
  [EN_US]: "enUS",
};

export const isEnUS = () => window.locale === EN_US;

// 根据 id 获取当前语言对应的文字
export const getLocaleText = id => {
  const localeObj = localeLanguage[languageKeyMap[window.locale]] || localeLanguage[languageKeyMap[ZH_CN]];
  if (!_.isString(id)) return localeObj.errors.errorId;
  const keys = id.split(".");
  let text = localeObj;
  for (let i = 0; i < keys.length; i++) {
    if (text[keys[i]] || text[keys[i]] === "") text = text[keys[i]]; // 语气词在英文可能为""
    else return `${localeObj.errors.notFindKey}: ${keys[i]}`;
  }
  return text;
};

// 根据 code 和 params 拼接对应错误码展示文字
export const getLocaleErrorCodeText = (code, params = []) => {
  let text = getLocaleText(`errorCode.${code}`);
  params.forEach(param => {
    text = text.replace("$", param);
  });
  return text;
};

const colorMap = {
  [ZH_CN]: {
    posColor: "var(--red-6)",
    negColor: "var(--green-6)",
    posLight: "var(--red-0)",
    negLight: "var(--green-0)",
  },
  [EN_US]: {
    posColor: "var(--green-6)",
    negColor: "var(--red-6)",
    posLight: "var(--green-0)",
    negLight: "var(--red-0)",
  },
};

export const getLocaleColor = () => colorMap[window.locale];
