export default {
  ruleName: "Rule name",
  setting: "Setting",
  editBtn: "Edit",
  tokenTitle: "The result after scene deer processing",
  tokenDesc: "Enter the question and click Test Run to display the results.",
  preRulesTitle: "Effective pre-participle rules",
  rulesTitle: "Effective post-participle rules",
  rulesDesc: "Click on the test run to display the actual rules in effect.",
  testRun: "Rules test run",
  inputTitle: "Trial run question",
  inputPlaceholder: "Please enter a question",
  testBtn: "Test",
};
