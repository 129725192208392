export default {
  undoChange: "Discard changes",
  cancel: "Cancel",
  continueEdit: "Keep editing",
  saveChange: "Save changes",
  quiting: "Quitting dashboard",
  quitPrompt: "Do you want to save changes?",
  addSuccessToast: "Added to dashboard",
  back: "Return",
  share: "Share",
  save: "Save",
  edit: "Edit",
  addFilter: "Add filter",
  addFilterTooltip: "Global filter for the report",
  globalConfig: "Global Display Settings",
  globalConfigTooltip: "Uniform settings for report colors, styles, etc.",
  reset: "Reset",
  checkMsg: "Audit Message",
  refreshFilter: "Reset Filters",
  textBox: "Text",
  textBoxTooltip: "Add text or image",
  addChart: "Chart",
  addChartToolTip: "Add a new chart",
  heading: "Section Heading",
  headingToolTip: "Add a section for quick navigation",
  more: "More",
  addTag: "Add Tags",
  delete: "Delete",
  removeFromList: "Remove from list",
  noAuth: "No access to dashboard",
  noCard: "No card in dashboard",
  please: ", please ",
  addCard: " add cards.",
  undoChangeConfirm: "Do you want to discard changes?",
  undoChangePrompt: "You changes will not be saved if you click 'Discard changes', you will also quit edit mode.",
  continueToEdit: "Keep editing",
  editPinboardDialogTitle: "Edit title and description",
  undo: "Discard",
  complete: "Finish",
  add: "Add",
  addInstruction: "Add description",
  modifyInstruction: "Edit description",
  disablePrompt: "No more prompt",
  haveLearned: "I understand",
  starSuccessToast: "Bookmarked",
  removeSuccessToast: "Deleted successfully",
  removeFailToast: "Failed to delete, please try again later",
  starFailToast: "Failed to add bookmark, please try again later",
  delSuccessToast: "Deleted successfully",
  modifyPinboardSuccessToast: "Dashboard updated successfully",
  delCardConfirm: "Are you sure you want to delete card?",
  saveErrorToast: "Dashboard name can not be empty",
  copyPinboardSuccessToast: "Dashboard copied",
  open: "Open",
  copyLink: "Copy Link",
  owner: "Owner",
  remove: "Remove",
  haveStarred: "Bookmarked",
  notStar: "Not bookmarked",
  createCopy: "Duplicate",
  manageVisitor: "Manage visitors",
  rename: "Rename",
  pinboardInfo: "Dashboard info",
  renamePinboard: "Rename dashboard",
  noAuthPrompt: "You do not have data access, please contact admin",
  noCardPrompt: "You do not have card data, please contact admin",
  noMeasureAvailable: "No measure available in card, please contact head of pinboard",
  noModelPrompt: "No data available in card, please contact admin",
  exportSuccessText: "Export succeeded",
  exportFailText: "Export failed",
  exportingPDFText: "Exporting PDF",
  exportingPPTXText: "Exporting PPTX",
  exportAs: "Export as",
  exportAsTip: "Export loaded data",
  appendixDocText: "Appendix",
  pinboardIntroDocText: "Dashboard description: ",
  recovery: "Recover",
  exitDetected: "Error quiting last time, ",
  editsNotSaved: "Last edits have not been saved, ",
  whetherToRestore: "Do you want to recover unsaved edits?",
  maxCacheTitle: "Browser cache exceeds upper limit",
  maxCachePrompts1:
    "The last abnormal exit was detected, but the browser cache exceeds the upper limit, and the last edited content cannot be restored.",
  maxCachePrompts2: "It is recommended to compress the size of uploaded pictures when editing.",
  replaceSuccessToast: "Replace successfully",
  editCardTitle: "Edit",
  insightPreviewTitle: "Preview",
  insightPreviewTitleTip: "Intelligent Analysis",
  replaceCardBtn: "Replace card",
  duplicateCardBtn: "Duplicate Card",
  duplicateCardToBottom: "Duplicate to Bottom",
  duplicateCardToBelow: "Duplicate to Below",
  deleteCardBtn: "Delete card",
  editStyle: "Edit style",
  moreOperation: "More",
  saveSuccess: "Saved successfully",
  cacheSettings: "Cache settings",
  cardSizeTitle: "Card size",
  cancelStarSuccessToast: "Unfollowed successfully",
  cancelStarFailToast: "Failed to unfollow",
  filter: "Filter",
  willClearExpiredFilters: "Expired, system will clear expired filters for you",
  gotIt: "Got it",
  transformToTemplate: "Transform to template",
  transformTemplateSuccessToast: "Transformed to template successfully",
  filterInCardPopoverTitle: "Filters",
  templateFrom: "This template is created by",
  clickToApply: ", apply with one click",
  applyTemplate: "Apply",
  from: "From",
  created: "Created",
  selectTemplate: "You can also select a template and add content:",
  moreTemplate: "More templates",
  tag: "Tag",
  timedReminder: "Schedule",
  accomplish: "Accomplish",
  enterTextInfo: "Enter text",
  uploadImageFailedTip: "Upload failed. Please try again.",
  pinboardInfoTitle: "Dashboard Info",
  baseInfoTitle: "Info",
  countInfoTitle: "Traffic Statistics",
  lastModifierLabel: "Last Modifier",
  updatedAtLabel: "Updated Date",
  createdAtLabel: "Creation Date",
  chartNumLabel: "Number Of charts",
  textNumLabel: "Number Of text Boxes",
  totalVisitedNumLabel: "Total Visits",
  totalVisitedUserNumLabel: "Total Visitors",
  rangeVisitedNumLabel: "Number Of Visits Range",
  rangeVisitedUserNumLabel: "Number Of Visitors Range",
  lineSetting: "Line Settings",
  dimensionColorSetting: "Dimension Value Color Override",
  pieLabelSetting: "Pie/Funnel Chart Data Label Settings",
  dimensionColorSettingTip:
    "Adding a limit of 10 will uniformly override the color of the dimension value in the current chart in the report",
  cascaderPlaceHolder: "Search by keyword",
  globalConfigResetConfirmTip:
    "After resetting, all display settings will be restored to their original state before any modifications were made.",
  globalConfigResetConfirmOkText: "Confirm Reset",
  searchFilterApplyNotificationTitle: "The filter has been automatically applied to the report",
  expandTocButtonTooltip: "Expand Navigation",
  unExpandTocButtonTooltip: "To Fold the navigation",
  headingsTitle: "Headings",
  errorWorkspaceTitle: "Switch Workspace to View Report",
  errorWorkspaceContent1: "The workspace you are currently in does not match the workspace where the report is located.",
  errorWorkspaceContent2: "You need to switch workspaces to view it.",
  errorWorkspaceOKText: "Confirm Switch",
  errorWorkspaceCancelText: "Stay in Current",
  errorLimitTitle: "Insufficient Report Permissions",
  errorLimitTitleContent1:
    "Unable to find the report, possibly because the report does not exist or you are not in the workspace where the report is located.",
  errorLimitTitleContent2: "Unable to view this report.",
  errorLimitTitleOKText: "Got It",
  batchDeleteButton: "Batch Delete",
  batchDeleteText1: "Selected ",
  batchDeleteText2: " charts",
  batchDeleteText3: " text boxes",
  batchDeleteButtonOkText: "Confirm Deletion",
  batchDeleteConfirmTitle: "Confirm delete the selected charts?",
  batchDeleteTooltip: "Bulk deletion of charts or text boxes in the pinboard",
};
