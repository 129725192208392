export default {
  modelPermission: "Table permissions",
  permissionOffTip: "Permission is not enabled, users can use this feature without restrictions",
  saveSuccessToast: "Table permission configuration updated successfully",
  enableConfirmTitle: "Confirm open table permissions?",
  enableConfirmTip:
    "After opening, you need to configure the permission content and update it, otherwise it may cause no data in the search results",
  methods: "Control mode",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  refreshBtnText: "Refresh",
  allowAccessTip:
    "Please add the resources that need to be added to the list from the left to the right. Only the resources that are added to the list on the right are allowed to be used",
  denyAccessTip:
    "Please add the resources that need to be added to the list from the left to the right, and the resources added to the list on the right will be prohibited from being used",
  allModels: "All models",
  selectedModels: "App List",
  leftParenthesis: " (",
  rightParenthesis: ")",
  saveBtnText: "Update",
};
