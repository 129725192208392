export default {
  all: "All",
  dataAlarm: "Alerts",
  cardAlarm: "Card Alerts",
  starredQuestions: "Bookmarks",
  savedCards: "Saved Cards",
  searchHistory: "History",
  starredQuestionPlaceholder: "Search",
  savedCardPlaceholder: "Search",
  searchHistoryPlaceholder: "Search",
  allQuestions: "All Questions",
  starredTime: "Modified",
  operation: "Settings",
  cancelStar: "Remove",
  ascendText: "Sort by descending order",
  descendText: "Cancel sort",
  defaultText: "Sort by ascending order",
  cancelStarToastText: "Successfully removed bookmark",
  mobileNoStarPrompt: "You don't have any bookmarks. Go explore the data.",
  mobileNoCardPrompt: "You don't have any saved cards. Go explore the data.",
  mobileNoHistoryPrompt: "You don't have any search history. Go explore the data.",
  hasDeleted: "Deleted",
  cancelSave: "Cancel Save",
  cancelSaveText: "Save Cancelled",
  // 原 MyAttention
  searchNoContentText: "No results found, please try a different keyword",
  cancelStarText: "Follow Cancelled",
  starredAt: "Followed At",
  collectedAt: "Saved At",
  yourStar: "Bookmarks",
  noStarPrompt: "You don't have any bookmarks. Go ",
  search: "explore the data",
  noContentPrompt3: "!",
  result: " results found",
  myAttentionDeleteConfirmTitle: "Remove Bookmark?",
  myAttentionDeleteConfirmTitleWithMyWatch:
    "This question is being used in 'My Observation'. Are you sure you want to remove the bookmark?",
  myAttentionDeleteConfirmContentWithMyWatch: "Once removed, this question will also be removed from 'My Observation'",
  // 原 MyCard
  noCardPrompt: "You don't have any saved cards. Go ",
  modalTitle: "save cards",
  save: "Save",
  updateToastText: "Card updated successfully",
  deleteToastText: "Card deleted successfully",
  cancel: "Cancel",
  noAuthPrompt: "You do not have permission to access this data, please contact the workspace admin",
  find: "",
  results: " results found",
  allCards: "All Cards",
  updatedAt: "Modified",
  myCardDeleteConfirmTitle: "Remove this card?",
  myCardDeleteConfirmTitleWithMyWatch: "This card is being used in 'My Observation'. Are you sure you want to remove this card?",
  myCardDeleteConfirmContentWithMyWatch: "Once removed, this card will also be removed from 'My Observation'",
  saveCards: " and save cards",
  noContent: "No content available,",
  hurry: " hurry",
  // 原 MyHistory
  titlePrompt: "Only records from last month will be kept.",
  cancelStarToast: "Successfully removed bookmark",
  starToast: "Bookmark Added Successfully",
  deleteConfirmText: "Confirm deletion of selected options?",
  mobileDeleteConfirmText: "Confirm deletion of selected options?",
  noHistoryPrompt: "You haven't browsed any content yet, go ",
  complete: "Complete",
  star: "Star",
  starred: "Bookmarks",
  delete: "Delete",
  selectAll: "Select All",
  hasSelectedAll: "All Selected",
  close: "Close",
  my: "My",
  remove: "Remove",
  // DataAlarm
  create: "New",
  confirm: "OK",
  startAlertTitle: "Confirm to enable this alert",
  startAlertContent:
    "The measure will be monitored upon enabling, and an alert message will be sent to the relevant recipients once the alert is triggered",
  startToast: "Enabled successfully",
  pauseAlertTitle: "Confirm to pause this alert",
  pauseAlertContent1: "This measure will not be monitored once paused",
  pauseAlertContent2: "You can still view the alert log",
  pauseToast: "Paused successfully",
  deleteAlertTitle: "Confirm to delete this alert",
  deleteAlertContent:
    "After deletion, this alert and its log will be permanently removed from the system. The system will automatically send the alert log to your mailbox and IM",
  deleteToast: "Delete successfully",
  allStatus: "All",
  activeStatus: "Enabled",
  pauseStatus: "Paused",
  errorStatus: "Invalid",
  measureStatusActive: "Enabled",
  measureStatusError: "Error",
  measureStatusPause: "Paused",
  measureTitle: "Monitoring Measures",
  cardAlarmTitle: "Monitoring Cards",
  typeTitle: "Conditions",
  frequencyTitle: "Monitoring Cycle",
  frequencyCalculate: "Calculate Frequency",
  subscriptionPreviewTitle: "Recipients",
  triggeredAtTitle: "Last Triggered",
  open: "Enable",
  pause: "Pause",
  unableToEnable: "Error monitoring measure, alert cannot be enabled",
  noAlert: "No alert",
  noAlert1: "No alert, please create ",
  createAlert: "new alert",
  canNotCreateAlert: "Email, Lark, DingTalk, and WeCom are currently unavailable. New alert cannot be created.",
  alertLogTooltip: "Log",
  editAlert: "Edit",
  deleteAlert: "Delete",
  insufficientPermissionTip:
    "Insufficient permissions for the current measure. Please reach out to the administrator or delete the alert and recreate it.",
  invalidMeasureTip: "The current measure or the model containing the measure is invalid. Please delete the alert and recreate it.",
  deletedMeasureTooltip: "The current measure has been deleted",
  filterTip: "Click to view all",
  filterDisplayTitle: "Conditions | Filters",
};
