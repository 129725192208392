import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeModeType } from "@/components/Constants";
import { getIndexLogo, getLogoImg, getLogoText } from "@/components/LogoAndCopyright";
import InitialContext from "@/contexts/InitialContext";

// icon 表示只有图标的 logo，index 表示首页中间显示的带有文字的大 logo
const LogoTypes: string[] = ["icon", "index"];

export type LogoType = typeof LogoTypes[number];

export interface LogoProps {
  type?: LogoType;
  height?: number;
  disableLink?: boolean;
  needThemeMode?: boolean;
  linkUrl?: string;
  className?: string;
  isSelfAdaption?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  height = 42,
  type = LogoTypes[0],
  disableLink = false,
  needThemeMode = false,
  linkUrl = "/",
  className,
  isSelfAdaption,
}) => {
  const { themeConfigs, themeMode } = useContext(InitialContext);

  // 根据 themeMode 从 themeConfigs 里取出对应的 logo 信息，如果没有的话就使用页面初始加载时的默认logo
  const imgSrc =
    type === LogoTypes[0]
      ? themeConfigs?.[themeMode]?.logo_img_src || getLogoImg()
      : themeConfigs?.[themeMode]?.index_logo_img_src || getIndexLogo(needThemeMode ? themeMode : ThemeModeType.LIGHT);

  // 图片宽大于屏幕时高度自适应, 否则取原来设置的高度
  const getAdaptionHeight = () => {
    const img = new Image();
    img.src = imgSrc;
    const clientWidth = document.body.clientWidth;
    const nowWidth = (img.width / img.height) * height;
    if (nowWidth < clientWidth) return height;
    return (img.height / img.width) * clientWidth;
  };

  const imgNode = <img height={isSelfAdaption ? getAdaptionHeight() : height} alt={getLogoText()} src={imgSrc} />;

  const logoContent = disableLink ? imgNode : <Link to={linkUrl}>{imgNode}</Link>;

  return <div className={className}>{logoContent}</div>;
};

export default Logo;
