export default {
  all: "全部",
  dataAlarm: "指标告警",
  cardAlarm: "卡片提醒",
  starredQuestions: "关注的问题",
  savedCards: "保存的卡片",
  searchHistory: "搜索历史",
  starredQuestionPlaceholder: "在“关注的问题”内检索",
  savedCardPlaceholder: "在“保存的卡片”内检索",
  searchHistoryPlaceholder: "在“搜索历史”内检索",
  allQuestions: "所有问题",
  starredTime: "关注时间",
  operation: "操作",
  cancelStar: "取消关注",
  ascendText: "点击降序",
  descendText: "取消排序",
  defaultText: "点击升序",
  cancelStarToastText: "取消关注成功",
  mobileNoStarPrompt: "暂未关注任何问题，快去探索数据吧~",
  mobileNoCardPrompt: "暂未保存任何卡片，快去探索数据吧~",
  mobileNoHistoryPrompt: "暂未浏览任何内容，快去探索数据吧~",
  hasDeleted: "已删除",
  cancelSave: "取消保存",
  cancelSaveText: "已取消保存",
  // 原 MyAttention
  searchNoContentText: "搜索无结果，请更换关键词",
  cancelStarText: "已取消关注",
  starredAt: "关注于",
  collectedAt: "收藏于",
  yourStar: "您关注的问题",
  noStarPrompt: "暂未关注任何问题，快去",
  search: "探索数据",
  noContentPrompt3: "吧~",
  result: "个与检索相符的结果",
  myAttentionDeleteConfirmTitle: "是否取消关注该问题",
  myAttentionDeleteConfirmTitleWithMyWatch: "【首页-我的观察】正在使用该问题，是否取消关注该问题",
  myAttentionDeleteConfirmContentWithMyWatch: "取消关注后，【我的观察】中使用的该问题也会被移除",
  // 原 MyCard
  noCardPrompt: "暂未保存任何卡片，赶紧去",
  modalTitle: "保存的卡片",
  save: "保存",
  updateToastText: "更新卡片成功",
  deleteToastText: "删除卡片成功",
  cancel: "取消",
  noAuthPrompt: "您暂无此数据权限，请联系工作区管理员",
  find: "找到",
  results: "个与检索相符的结果",
  allCards: "所有卡片",
  updatedAt: "修改时间",
  myCardDeleteConfirmTitle: "是否移除该卡片",
  myCardDeleteConfirmTitleWithMyWatch: "【首页-我的观察】正在使用该卡片，是否移除该卡片",
  myCardDeleteConfirmContentWithMyWatch: "移除后，【我的观察】中使用的该卡片也会被移除",
  saveCards: "并保存需要卡片吧",
  noContent: "暂无内容，",
  hurry: "赶紧",
  // 原 MyHistory
  titlePrompt: "保留最近1个月的记录",
  cancelStarToast: "取消关注成功",
  starToast: "关注成功",
  deleteConfirmText: "确定删除选中的选项？",
  mobileDeleteConfirmText: "确定删除选项？",
  noHistoryPrompt: "暂未浏览任何内容，赶紧去",
  complete: "完成",
  star: "关注",
  starred: "已关注",
  delete: "删除",
  selectAll: "全选",
  hasSelectedAll: "已选择全部",
  close: "关闭",
  my: "我的",
  remove: "移除",
  // DataAlarm
  create: "新建",
  confirm: "确定",
  startAlertTitle: "确定启用此告警",
  startAlertContent: "启用后将再次监控该指标，并在触发条件后发送告警消息到相关接收人",
  startToast: "开启成功",
  pauseAlertTitle: "确定暂停此告警",
  pauseAlertContent1: "暂停后将不再监控该指标",
  pauseAlertContent2: "你依然可以查看告警记录",
  pauseToast: "暂停成功",
  deleteAlertTitle: "确定删除此告警",
  deleteAlertContent: "删除后此条告警及触发的历史记录都将被删除 系统会自动发送以上信息到你的邮箱和IM作为存档",
  deleteToast: "删除成功",
  allStatus: "全部状态",
  activeStatus: "正常启用",
  pauseStatus: "暂停告警",
  errorStatus: "失效",
  measureStatusActive: "正常启用",
  measureStatusError: "指标出错",
  measureStatusPause: "暂停告警",
  measureTitle: "监控指标",
  cardAlarmTitle: "监控卡片",
  typeTitle: "触发条件",
  frequencyTitle: "监控周期",
  frequencyCalculate: "计算频率",
  subscriptionPreviewTitle: "提醒接收人",
  triggeredAtTitle: "最近触发时间",
  open: "启用",
  pause: "暂停",
  unableToEnable: "监控指标出错无法启用",
  noAlert: "暂无告警",
  noAlert1: "暂无告警，请",
  createAlert: "新建告警",
  canNotCreateAlert: "当前邮箱、飞书、钉钉、企业微信提醒均不可用，无法新建数据告警",
  alertLogTooltip: "告警记录",
  editAlert: "编辑",
  deleteAlert: "删除",
  insufficientPermissionTip: "没有当前指标的权限，请联系管理员或删除告警后重新创建",
  invalidMeasureTip: "当前指标或指标所在模型失效，请删除告警后重新创建",
  deletedMeasureTooltip: "当前指标已被删除",
  filterTip: "点击查看全部",
  filterDisplayTitle: "触发条件 | 维度筛选",
};
