export default {
  noContentTip: "页面空空如也",
  messageCenter: "消息中心",
  clearUnread: "清除未读",
  deleteBtnText: "删除",
  multiSelect: "多选",
  deleteSelectedMessageConfirmTitle: "确定删除选中的消息？",
  agree: "同意",
  refuse: "拒绝",
  noMessageTip: "暂无消息",
  viewNewMessage: "查看新消息",
  cancel: "取消",
  selectAll: "全选",
  noMessage: "暂无消息",
  message: "消息",
  agreed: "已同意",
  rejected: "已拒绝",
  systemMessages: "系统通知",
  reportReminder: "报告通知",
  workspaceReminder: "工作区通知",
  permissionTransfer: "权限转移",
  permissionNotification: "权限通知",
  permissionApplication: "权限申请",
  feedbackAudit: "校准反馈审核",
  workspaceMessages: "工作区消息",
  auditedMessages: "审核消息",
  currentPermissions: "当前权限",
  userDemand: "用户诉求",
  goToTheBackgroundToModify: "前往后台",
  remarks: "备注",
  workspaceUsersWith: "此工作区用户以",
  viewWithPermissions: "的权限查看报告内容。",
  matchNow: "立即匹配",
  ignore: "忽略",
  processed: "已处理",
  ignored: "已忽略",
  existingField: "已有字段",
  currentMatch: "当前匹配",
  requestedMatch: "请求匹配",
  unconfiguredThenAddToWorkspace: "若诉求中含有未配置的指标或维度，请添加至工作区后台，再返回该处匹配。",
  afterAgreeWillBe: "同意后，该字段将成为",
  visibleToHasPermissions: "的同义词，工作区里有权限的用户均可见。",
  createNewRules: "同意后，将创建一个新的语义校准规则，工作区中有权限的用户均可见。",
  afterAgreeToDeleteNowRule: "同意后，将删除当前规则，取消",
  matchingOf: "的匹配",
  afterAgreeToDeleteOldRule: "同意后，将删除原规则，",
  willBe: "会成为",
  afterAgreeToReplaceRule: "同意后，将替换原语义校准规则，工作区中有权限的用户均可见。",
  haveBeenDeleted: "已被删除",
  okText: "好的",
  rejectTheFeedback: "拒绝该反馈",
  agreeAfterAdding: "如有需要前往后台添加后即可同意，或拒绝该条校准反馈。",
  agreeToMatchOther: "同意将匹配当前其他有效字段。",
  agreeToCurrentMatch: "同意当前匹配",
  agreeLater: "稍后同意",
  nowMatch: "现匹配",
  originalMatch: "原匹配",
  nowMatched: "现已匹配",
  matchingField: "匹配字段",
  administrators: "管理员",
  adopt: "通过",
  handle: "处理",
  already: "已",
  FeedbackYourSubmitted: "你提交的「校准反馈」",
  nowUnmatched: "现已取消匹配",
  logOff: "注",
  goToThePCForProcessing: "请前往桌面端处理",
  processedOnPC: "已在桌面端处理",
  notCurrently: "目前无",
  contactTheAdministrator: "数据权限，联系管理员开启后即可搜索。",
  null: "无",
  useThirdPartyLogin: "为了成功接收推送消息，请在下次登录时使用对应的第三方登录",
  bindMailboxForYou: "为了成功接收推送消息，请联系系统管理员为你绑定邮箱",
  canCreateOrEditPin: "可以创建、编辑报告，并能下载图表卡片包含的数据",
  PinFrozen: "无法再创建、编辑报告，现有报告的编辑权限均被冻结为“可读（本人）”；也无法再下载图表卡片包含的数据",
  noMessageText1: "加载时出现问题，请过几分钟后重试",
  formerNoMessageText2: "或前往",
  latterNoMessageText2: "页面",
  clickToViewDetails: "点击查看详情",
  readAll: "全部已读",
  viewAll: "查看全部",
  all: "全部",
  onlyShowPending: "仅显示未处理",
  deleteToast: "删除成功",
};
