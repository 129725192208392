import { Button as AntdButton, ButtonProps as AntdButtonProps } from "antd";
import { ButtonShape, ButtonType } from "antd/es/button";
import _ from "lodash";
import React from "react";

import styles from "./index.module.scss";

const ButtonSizes: string[] = ["middle", "large", "small", "mini"];
const ButtonTypes: ButtonType[] = ["primary", "default", "link", "text"];
const ButtonShapes: ButtonShape[] = ["round", "circle"];

const ButtonTypeClasses = {
  [ButtonTypes[0]]: styles.primaryButton,
  [ButtonTypes[1]]: styles.defaultButton,
  [ButtonTypes[2]]: styles.linkButton,
  [ButtonTypes[3]]: styles.textButton,
};

const DangerButtonTypeClasses = {
  [ButtonTypes[0]]: styles.dangerPrimaryButton,
  [ButtonTypes[1]]: styles.dangerDefaultButton,
  [ButtonTypes[2]]: styles.dangerLinkButton,
  [ButtonTypes[3]]: styles.dangerTextButton,
};

const ButtonSizeClasses = {
  [ButtonSizes[0]]: styles.middleButton,
  [ButtonSizes[1]]: styles.largeButton,
  [ButtonSizes[2]]: styles.smallButton,
  [ButtonSizes[3]]: styles.miniButton,
};

const CircleButtonClasses = {
  [ButtonSizes[0]]: styles.circleMiddleButton,
  [ButtonSizes[1]]: styles.circleLargeButton,
  [ButtonSizes[2]]: styles.circleSmallButton,
  [ButtonSizes[3]]: styles.circleMiniButton,
};

// 从 T 中筛选出除了 K 的其他属性
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;

export type ButtonProps = Merge<
  AntdButtonProps,
  {
    size?: typeof ButtonSizes[number];
    throttle?: boolean;
    wait?: number;
    onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  }
>;

const Button: React.FC<ButtonProps> & {
  Group: typeof AntdButton.Group;
} = ({
  type = ButtonTypes[1],
  shape = ButtonShapes[0],
  danger,
  size = ButtonSizes[0],
  className,
  throttle = true,
  wait = 1000,
  ghost,
  onClick,
  ...rest
}) => {
  // 按类型
  let lastClassName = `${styles.a} ${styles.b} ${styles.basicButtonClass}`;

  lastClassName += ` ${danger ? DangerButtonTypeClasses[type] : ButtonTypeClasses[type]}`;

  if (ghost) lastClassName += ` ${danger ? styles.dangerGhostButton : styles.ghostButton}`;

  // 圆形 button
  if (shape === ButtonShapes[1]) lastClassName += ` ${styles.circleBasic} ${CircleButtonClasses[size]}`;
  else lastClassName += ` ${ButtonSizeClasses[size]}`;

  // 接受 className, 可以覆盖上面的设置
  if (className) lastClassName += ` ${className}`;

  // 如果开启节流模式，点击立即执行函数，一段时间之后才能执行一下次触发
  const buttonClick = throttle && onClick ? _.throttle(onClick, wait) : onClick;

  // fix: [antd: Button] `link` or `text` button can't be a `ghost` button.
  let buttonGhost = ghost;
  if (["link", "text"].includes(type)) buttonGhost = false;

  return (
    <AntdButton onClick={buttonClick} type={type} ghost={buttonGhost} shape={shape} danger={danger} className={lastClassName} {...rest} />
  );
};

Button.Group = AntdButton.Group;

export type { ButtonGroupProps } from "antd/es/button";

export default Button;
