export default {
  detectedLastAbnormalExit: "检测到上次异常退出",
  lastConfigurationExitNotSaved: "上次配置退出时未保存，",
  restoreLastConfiguration: "是否要恢复上次配置的内容？",
  resume: "恢复",
  cancel: "取消",
  saveSuccessToast: "保存成功",
  modelDeletedSuccessfully: "模型删除成功",
  configurationSaved: "配置已保存",
};
