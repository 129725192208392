export default class NumberFormatterNegative {
  static NORMAL = "normal";

  static SPECIAL = "special";

  static getText = t => {
    if (t === NumberFormatterNegative.NORMAL) return "-100";
    if (t === NumberFormatterNegative.SPECIAL) return "(100)";
  };

  static choices = [this.NORMAL, this.SPECIAL];

  static getOptions = () => this.choices.map(value => ({ label: this.getText(value), value }));
}
