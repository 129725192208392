export default class FeedbackSource {
  // 搜索框点击
  static SEARCH_INPUT = 1;

  // 搜索结果点击
  static SEARCH_RESULT = 2;

  // 右下角点击
  static RIGHT_BOTTOM_ICON = 3;
}
