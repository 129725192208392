export default {
  editBtnText: "编辑",
  saveBtnText: "保存",
  cancelBtnText: "取消",
  /* 搜索结果预设 */
  searchResultsSettings: "搜索结果预设",
  defaultMeasure: "默认指标",
  defaultMeasureTip: "搜索问题中提及指标对应的关键词时默认使用的指标",
  selectPlaceholder: "请选择",
  notSet: "未设置",
  hideMeasureCard: "隐藏指标卡片",
  hideMeasureCardTip: "开启后将隐藏搜索结果中的指标卡片",
  measureCard: "指标卡片",
  measureCardTip: "指标在搜索结果时间范围内的汇总，例如",
  /* 默认展示的时间范围 */
  searchDateRangeSettings: "默认展示的时间范围",
  dateRangeKeywordsSettings: "时间搜索词与默认时间范围",
  searchDateRangeTip:
    "在使用自然语言搜索“每天”“每月”的数据时，往往不会添加查询的时间范围，因此为得到搜索结果需要限定一段合理的时间范围。时间范围可填写自然语言，如“前n天/周/月/季度/年”，或“2020年”、“2019年6月至2020年3月”“2020年上半年”等等。",
  dateRangeKeywordsTip: "搜索问题中包含以上时间搜索词时，图表中会展示相应的默认时间范围内的数据",
  labelForDefaultDateRange: "搜索默认展示",
  labelForSecond: "搜索“每秒”",
  labelForMinute: "搜索“每分钟”",
  labelForHour: "搜索“每小时”",
  labelForDay: "搜索“每天”",
  labelForWeek: "搜索“每周”",
  labelForMonth: "搜索“每月”",
  labelForQuarter: "搜索“每季度”",
  labelForYear: "搜索“每年”",
  inputPrefixForDefaultDateRange: "默认展示",
  inputPrefixForSecond: "“每秒”展示",
  inputPrefixForMinute: "“每分钟”展示",
  inputPrefixForHour: "“每小时”展示",
  inputPrefixForDay: "“每天”展示",
  inputPrefixForWeek: "“每周”展示",
  inputPrefixForMonth: "“每月”展示",
  inputPrefixForQuarter: "“每季度”展示",
  inputPrefixForYear: "“每年”展示",
  placeholderForSecond: "前 30 分钟",
  placeholderForMinute: "前 12 小时",
  placeholderForHour: "前 7 天",
  placeholderForDay: "前 30 天",
  placeholderForWeek: "前 25 周",
  placeholderForMonth: "前 12 个月",
  placeholderForQuarter: "前 12 个季度",
  placeholderForYear: "前 3 年",
  dateRangeSuffix: "的数据",
  /* 预测分析 */
  forecastSettings: "预测分析",
  forecasting: "预测分析",
  forecastingTip: "系统根据已有的历史数据进行预测（请在帮助文档中详细了解预测）",
  forecastingExample: "预测：",
  on: "开启",
  off: "关闭",
  onByDefault: "（默认启用）",
  offByDefault: "（默认不启用）",
  checkboxText: "用户设置内默认启用",
  forecastDateRange: "预测模型的训练数据范围",
  defaultForecastDateRange: "未设置",
  atLeast: "至少",
  atMost: "至多",
  dateUnit: "天",
  separator: "，",
  maxLessThanMin: "最大值不能小于最小值",
  minMoreThanMax: "最小值不能大于最大值",
  /* 维度分析 */
  dimensionAnalysisSettings: "维度分析",
  dimensionAnalysisMode: "维度分析模式",
  smartMode: "智能数据洞察",
  attributionModeTip: "系统根据算法智能筛选关键维度，消失/新增维度和负相关维度等进行归因分析",
  comparisonTypes: "分析方式",
  comparisonTypesTip: "请至少勾选一种分析方式；若同时勾选，用户可以自行切换",
  comparisonTypeSuffix: "分析",
  defaultComparisonType: "默认分析方式",
  /* 引导问题 */
  customSearchSuggestions: "引导问题",
  searchSuggestions: "工作区引导问题",
  searchSuggestionsTip: "新用户进入工作区页面看到的引导问题",
  searchQuestionPlaceholder: "请输入问题，不超过 255 字",
  addBtnText: "新增问题",
};
