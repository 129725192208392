import _ from "lodash";
import React, { useState } from "react";
import { CloseIconSvg } from "../SVGIcons";

import styles from "./index.module.scss";

const BaseInputClearButton = ({ onClick = _.noop, style = {}, className = "" }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <CloseIconSvg
      className={`${styles.closeIcon} ${className}`}
      style={style}
      backgroundOpacity={isHover ? "0.1" : "0.05"}
      cursor={isHover ? "pointer" : "auto"}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
  );
};

export default BaseInputClearButton;
