export default {
  betweenStr: "介于 ... 之间",
  greaterThanStr: "大于(>)",
  greaterEqualThanStr: "大于等于(≥)",
  lessThanStr: "小于(<)",
  lessEqualThanStr: "小于等于(≤)",
  isNullStr: "为空",
  isNotNullStr: "不为空",
  equalStr: "等于(=)",
  notEqualStr: "不等于(≠)",
  inStr: "是",
  notInStr: "不是",
  likeInStr: "包含关键字",
  notLikeInStr: "排除关键字",
  startWithStr: "关键字开头",
  endWithStr: "关键字结尾",
  betweenTimeStr: "在...之间",
  afterTimeStr: "在...之后",
  formerStr: "前",
  dateThis: "本",
  in: "是",
  notIn: "不是",
  contain: "包含",
  notContain: "排除",
  with: "以",
  start: "开头",
  end: "结尾",
  to: "至",
  betweenness: "介于",
  betweenTo: "至",
  between: "之间",
  isNull: "为空",
  isNotNull: "不为空",
};
