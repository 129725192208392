export default {
  deleteSuccessToast: "Delete successfully",
  saveSuccessToast: "Modified successfully",
  editBtnText: "Edit",
  sortBtnText: "Sort",
  sortSuccessToast: "Set successfully.",
  deleteBtnText: "Delete",
  dimensionPrefix: "Dimension table",
  dimensionSuffix: "Properties",
  deleteConfirmFormerText: "Confirm the deletion of the dimension table",
  deleteConfirmLatterText: "? The operation cannot be undone",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  name: "Dimension table name/keyword",
  alias: "Alias",
  field: "Field name",
  dataType: "Data type",
  isKeyword: "Dimension value searchable",
  dimension: "Dimension",
  operations: "Settings",
  searchInputPlaceholder: "Please enter the content you want to search",
  dimensionListTip:
    "The dimension table needs to add and associate an actual table in the model, which is a dimension extension table for the fact table",
  dimensionList: "Dimension table",
  current: "Current dimension table: ",
  tableDeleteConfirmTitle: "Confirm to delete the dimension table",
  tableDeleteConfirmContent1: "The dimension table already has an applied model.",
  tableDeleteConfirmContent2: "After confirmation, it will be removed from all models that include this dimension table.",
  tableDeleteConfirmContent3: "This operation cannot be undone",
  pruneButtonText: "Clear Invalid Dimensions",
  pruneButtonTip: "Clear dimension tables not used by the model",
  pruneConfirmTitle: "Confirming the deletion of invalid dimension tables",
  pruneConfirmContent: "After confirmation, dimension tables that are not applied by the model will be cleared.",
  migrateTable: "Migrate to new table",
  migrateTableTitle: "This old dimension table will be automatically migrated to the new dimension table  ",
  migrateTableContent1: "Dimensions are automatically configured to the new format with no data loss.",
  migrateTableContent2: "After the migration is completed, the system will automatically initiate a dictionary reconstruction task.",
  migrateTableSuccessToast: "Migration Success",
};
