export default {
  deleteSuccessToast: "Delete successfully",
  editBtnText: "Edit",
  sortBtnText: "Sort",
  sortSuccessToast: "Set successfully.",
  deleteBtnText: "Delete",
  deleteConfirmTitle: "Confirm to delete this dimension? This operation cannot be undone",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  id: "ID",
  keyword: "Keywords",
  alias: "Alias",
  dataType: "Data type",
  isKeyword: "Dimension value searchable",
  dimension: "Dimension",
  operations: "Settings",
  searchInputPlaceholder: "Please enter the name of the dimension you want to search",
  addBtnText: "Add dimension",
  current: "Current dimension：",
  tableDeleteConfirmTitle: "Confirm to delete the dimension",
  tableDeleteConfirmContent1: "The dimension already has an applied model.",
  tableDeleteConfirmContent2: "After confirmation, it will be removed from all models that include this dimension.",
  tableDeleteConfirmContent3: "This operation cannot be undone",
  pruneButtonText: "Clear Invalid Dimensions",
  pruneButtonTip: "Clear fact table dimensions not used by the model",
  pruneConfirmTitle: "Confirm deletion of invalid fact table dimensions",
  pruneConfirmContent: "After confirmation, the fact table dimensions that are not applied by the model will be cleared.",
};
