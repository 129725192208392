import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.ReminderMethod.${id}`);

export default class ReminderMethod {
  static MESSAGE = "message";

  static EMAIL = "email";

  static DINGTALK = "dingtalk";

  static LARK = "lark";

  static WECOM = "wechat-work";

  static getText = method => {
    if (method === this.LARK) return getText("lark");
    if (method === this.DINGTALK) return getText("dingtalk");
    if (method === this.WECOM) return getText("wecom");
    return "";
  };
}
