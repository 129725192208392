export default {
  loginSloganText: "Search & AI driven analytics platform",
  loginFormTitle: "Enterprise User Login",
  userNameInputPlaceholder: "User Name/Email/Phone Number",
  passwordInputPlaceholder: "Enter your password",
  rememberAccountCheckboxText: "Remember Account",
  loginBtnText: "Login",
  verificationCodeLogin: "OTP",
  phoneInputPlaceholder: "Phone Number",
  EmailInputPlaceholder: "Email",
  usernameRequiredMessage: "Required",
  phoneRequiredMessage: "Enter your phone number or email",
  getVerificationCodeBtnText: "Get verification code",
  resendBtnText: "Resend in seconds",
  verificationCodeInputPlaceholder: "Verification Code",
  verificationCodeRequiredMessage: "Enter your verification code",
  passwordLogin: "Password",
  exceedConfirmTitle: "Viewer accounts limit exceeded",
  exceedConfirmContent: "Access denied, please contact admin to manage accounts",
  confirm: "Confirm",
  forgetPassword: "Forget Password",
  usernameOrEmailOrPhoneRequiredMessage: "Please enter user name , email or phone number",
  moreLoginOptions: "More login options",
  sendCoderSuccessToast: "Verification code sent",
  loginTitle: "Login",
};
