import { getLocaleText } from "@/utils/locale";
import BaseDataType from "./BaseDataType";

const getText = id => getLocaleText(`components.Constants.DataType.${id}`);

export default class DataType {
  static DECIMAL = "decimal";

  static FLOAT = "float";

  static INT = "int";

  static BOOL = "bool";

  static YYYYMMDD_INT = "yyyymmdd_int";

  static YYYYMM_INT = "yyyymm_int";

  static YYYY_INT = "yyyy_int";

  static TIMESTAMP_INT = "timestamp_int";

  static TIMESTAMP_FLOAT = "timestamp_float";

  static DATE = "date";

  static DATETIME = "datetime";

  static STR = "str";

  static CHOICE = "choice";

  static PROVINCE_STR = "province_str";

  static CITY_STR = "city_str";

  static COUNTY_STR = "county_str";

  static TIMESTAMP = "timestamp";

  static YYYYMMDD_STR = "yyyymmdd_str";

  static YYYYMM_STR = "yyyymm_str";

  static YYYY_STR = "yyyy_str";

  static YYYYMMDD_HYPHEN_STR = "yyyy-mm-dd";

  static YYYYMM_HYPHEN_STR = "yyyy-mm";

  static YYYYMMDD_SLASH_STR = "yyyy/mm/dd";

  static YYYYMM_SLASH_STR = "yyyy/mm";

  static STR_TYPES = [this.STR, this.PROVINCE_STR, this.CITY_STR, this.COUNTY_STR];

  // 指标的数据类型，根据原始数据类型返回
  static getMeasureDataTypes = originType => {
    if (originType === BaseDataType.INT) return [this.INT];
    return [this.FLOAT, this.INT]; // 全量
  };

  // 维度
  static getDimensionDataTypes = originType => {
    switch (originType) {
      case BaseDataType.DATE:
        return [this.DATE];
      case BaseDataType.DATETIME:
        return [this.DATE, this.DATETIME];
      case BaseDataType.FLOAT:
        return [this.FLOAT, this.INT, this.CHOICE, this.TIMESTAMP_FLOAT];
      case BaseDataType.INT:
        return [this.INT, this.CHOICE, this.TIMESTAMP_INT];
      case BaseDataType.TIMESTAMP:
        return [this.TIMESTAMP];
      case BaseDataType.STR:
        return [this.STR, this.PROVINCE_STR, this.CITY_STR, this.COUNTY_STR, this.CHOICE];
      case BaseDataType.BOOL:
        return [this.CHOICE];
      default:
        return [
          this.DATE,
          this.DATETIME,
          this.FLOAT,
          this.INT,
          this.CHOICE,
          this.STR,
          this.PROVINCE_STR,
          this.CITY_STR,
          this.COUNTY_STR,
          this.BOOL,
          this.TIMESTAMP,
          this.TIMESTAMP_FLOAT,
          this.TIMESTAMP_INT,
        ]; // 全量
    }
  };

  // 时间维度
  static getTimeDimensionDataTypes = originType => {
    switch (originType) {
      case BaseDataType.DATE:
        return [this.DATE];
      case BaseDataType.DATETIME:
        return [this.DATETIME];
      case BaseDataType.TIMESTAMP:
        return [this.TIMESTAMP];
      case BaseDataType.FLOAT:
        return [this.TIMESTAMP_FLOAT];
      case BaseDataType.INT:
        return [this.YYYYMMDD_INT, this.YYYYMM_INT, this.YYYY_INT, this.TIMESTAMP_INT];
      case BaseDataType.STR:
        return [
          this.YYYYMMDD_HYPHEN_STR,
          this.YYYYMM_HYPHEN_STR,
          this.YYYY_STR,
          this.YYYYMMDD_SLASH_STR,
          this.YYYYMM_SLASH_STR,
          this.YYYYMMDD_STR,
          this.YYYYMM_STR,
        ];
      default:
        return [
          this.DATE,
          this.DATETIME,
          this.TIMESTAMP,
          this.TIMESTAMP_FLOAT,
          this.TIMESTAMP_INT,
          this.YYYY_INT,
          this.YYYYMM_INT,
          this.YYYYMMDD_INT,
          this.YYYY_STR,
          this.YYYYMM_STR,
          this.YYYYMMDD_STR,
          this.YYYYMM_HYPHEN_STR,
          this.YYYYMMDD_HYPHEN_STR,
          this.YYYYMM_SLASH_STR,
          this.YYYYMMDD_SLASH_STR,
        ]; // 全量
    }
  };

  // 映射 可以选择的数据类型
  static choiceListOptions = [this.STR, this.FLOAT, this.INT, this.PROVINCE_STR, this.CITY_STR, this.COUNTY_STR, this.BOOL];

  // 原始数据类型是 bool，映射前只能选 bool
  static choiceBoolBeforeOptions = [this.BOOL];

  // 映射前类型是 bool ，映射后只能选 文本
  static choiceBoolAfterOptions = [this.STR];

  /* 需要设置 baseField */
  static needSetBaseField = value => [this.CHOICE].includes(value);

  static needSetSort = value => {
    return [this.FLOAT, this.INT].includes(value);
  };

  static needSetIsKeyword = value => {
    return [this.STR, this.PROVINCE_STR, this.CITY_STR, this.COUNTY_STR, this.CHOICE].includes(value);
  };

  static getText = value => {
    if (value === this.STR) return getText("str");
    if (value === this.PROVINCE_STR) return getText("provinceStr");
    if (value === this.CITY_STR) return getText("cityStr");
    if (value === this.COUNTY_STR) return getText("countyStr");
    if (value === this.INT) return getText("int");
    if (value === this.BOOL) return getText("bool");
    if (value === this.DECIMAL) return getText("decimal");
    if (value === this.FLOAT) return getText("float");
    if (value === this.DATETIME) return getText("datetime");
    if (value === this.DATE) return getText("date");
    if (value === this.YYYY_INT) return getText("YYYYInt");
    if (value === this.YYYYMM_INT) return getText("YYYYMMInt");
    if (value === this.YYYYMMDD_INT) return getText("YYYYMMDDInt");
    if (value === this.TIMESTAMP_INT) return getText("timestampInt");
    if (value === this.TIMESTAMP_FLOAT) return getText("timestampFloat");
    if (value === this.CHOICE) return getText("choice");
    if (value === this.TIMESTAMP) return getText("timestamp");
    if (value === this.YYYYMMDD_STR) return getText("YYYYMMDD_STR");
    if (value === this.YYYYMM_STR) return getText("YYYYMM_STR");
    if (value === this.YYYY_STR) return getText("YYYY_STR");
    if (value === this.YYYYMMDD_HYPHEN_STR) return getText("YYYYMMDD_HYPHEN_STR");
    if (value === this.YYYYMM_HYPHEN_STR) return getText("YYYYMM_HYPHEN_STR");
    if (value === this.YYYYMMDD_SLASH_STR) return getText("YYYYMMDD_SLASH_STR");
    if (value === this.YYYYMM_SLASH_STR) return getText("YYYYMM_SLASH_STR");

    return `${value}`; // 自定义 sql 无原始数据类型
  };
}
