export default {
  name: "名称",
  link: "网址",
  paramKey: "网址参数",
  paramKeyExplain:
    "网址中参数会被替换为搜索词。例如网址为 https://baidu.com/s?wd=KEY, 网址参数配置为 KEY，则搜索词将会替换 KEY 代表的内容。",
  lastModified: "创建人",
  updatedAt: "更新时间",
  operations: "操作",
  addBtnText: "添加资源",
  addSuccessToast: "添加成功",
  saveSuccessToast: "修改成功",
  deleteConfirmTitle: "确定删除此资源吗？",
  confirmBtnText: "删除",
  deleteSuccessToast: "删除成功",
  cancelBtnText: "取消",
  editBtnText: "编辑",
  deleteBtnText: "删除",
  externalAssetsTitle: "外部资源",
  searchInputPlaceholder: "请输入名称",
  editModalTitle: "编辑资源",
  addModalTitle: "添加资源",
  editConfirmBtnText: "保存",
  addConfirmBtnText: "确认添加",
  inputPlaceHolder: "请输入",
  formHelp: "请输入",
};
