export default {
  second: "秒",
  minute: "分钟",
  hour: "小时",
  day: "天",
  week: "周",
  month: "月",
  quarter: "季",
  year: "年",
  Monday: "周一",
  Tuesday: "周二",
  Wednesday: "周三",
  Thursday: "周四",
  Friday: "周五",
  Saturday: "周六",
  Sunday: "周日",
  secondAbbr: "秒",
  minuteAbbr: "分",
  hourAbbr: "时",
  dayAbbr: "日",
  weekAbbr: "周",
  monthAbbr: "月",
  quarterAbbr: "季",
  yearAbbr: "年",
  everyYear: "每年",
  everyMonth: "每月",
  everyDay: "每日",
  everyWeek: "每周",
};
