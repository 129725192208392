import React from "react";
import { SVGProps } from "react";

const Clock = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99967 1.66666C4.05416 1.66666 1.66634 4.05448 1.66634 7C1.66634 9.94551 4.05416 12.3333 6.99967 12.3333C9.94519 12.3333 12.333 9.94551 12.333 7C12.333 4.05448 9.94519 1.66666 6.99967 1.66666ZM0.333008 7C0.333008 3.3181 3.31778 0.333328 6.99967 0.333328C10.6816 0.333328 13.6663 3.3181 13.6663 7C13.6663 10.6819 10.6816 13.6667 6.99967 13.6667C3.31778 13.6667 0.333008 10.6819 0.333008 7ZM6.33301 4.33333C6.33301 3.96514 6.63148 3.66666 6.99967 3.66666C7.36786 3.66666 7.66634 3.96514 7.66634 4.33333V6.33333H9.49967C9.86786 6.33333 10.1663 6.6318 10.1663 7C10.1663 7.36819 9.86787 7.66666 9.49968 7.66666H6.33301V4.33333Z"
      fill="currentColor"
    />
  </svg>
);

export default Clock;
