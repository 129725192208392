import React from "react";

const AutoInsightInfo = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.0002" r="12" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5814 7.83745H17.0233V14.1723C17.0233 15.2328 16.4651 15.7909 15.4047 15.7909H12.4188V17.2086L14.4169 18.2077C14.629 18.3081 14.7127 18.5593 14.6067 18.7714C14.5341 18.9165 14.3834 19.0002 14.2327 19.0002C14.1713 19.0002 14.1043 18.9835 14.0485 18.9556L12.0001 17.9342L9.95177 18.9556C9.89595 18.9835 9.82899 19.0002 9.76759 19.0002C9.61689 19.0002 9.46619 18.9165 9.39363 18.7714C9.28758 18.5593 9.37132 18.3081 9.58341 18.2077L11.5815 17.2086V15.7909H8.59535C7.53488 15.7909 6.97674 15.2328 6.97674 14.1723V7.83745H6.4186C6.18977 7.83745 6 7.64769 6 7.41885C6 7.19001 6.18977 7.00024 6.4186 7.00024H17.5814C17.8102 7.00024 18 7.19001 18 7.41885C18 7.64769 17.8102 7.83745 17.5814 7.83745ZM9.16516 12.71C9.24888 12.8104 9.37167 12.8607 9.48888 12.8607C9.58376 12.8607 9.67865 12.8272 9.75679 12.7658L11.3921 11.4039L11.9782 12.3807C12.0842 12.5593 12.2573 12.6821 12.4582 12.7155C12.6535 12.749 12.86 12.6932 13.0219 12.5593L14.78 11.0914C14.9587 10.9407 14.9865 10.6783 14.8358 10.4997C14.6851 10.3211 14.4228 10.2932 14.2442 10.4439L12.6089 11.8058L12.0228 10.829C11.9168 10.6504 11.7438 10.5277 11.5428 10.4942C11.3475 10.4607 11.141 10.5165 10.9791 10.6504L9.22096 12.1183C9.04236 12.269 9.01446 12.5314 9.16516 12.71Z"
      fill="white"
    />
  </svg>
);

export default AutoInsightInfo;
