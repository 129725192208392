export default {
  beforeMapping: "映射前",
  afterMapping: "映射后",
  dataType: "数据类型",
  dataTypeTip: "须与数据列类型一致，请谨慎更改。",
  selectMapping: "指定映射关系",
  numRepeat: "数值重复",
  columnInputPlaceholder: "请填写该列所含的内容",
  mappingInputPlaceholder: "请填写映射后内容",
  addOneOptionBtnText: "加1个选项",
  optionDataInputPlaceholder: "请写入一段带有选项数据类型及选项值的 JSON",
  inputMappingDataText: "请选择映射前后的数据类型并填写映射前后的内容",
  manual: "手动输入",
  json: "写入 JSON",
};
