export default {
  selectProject: "Select Workspace",
  imErrorTip: "You are not in the workspace where this link is located. Please contact the administrator for authorization if needed.",
  removeErrorTip:
    "You may have been removed from the workspace by the administrator, or the current workspace has been dissolved. Please contact the administrator.",
  pleaseSelectYourProjectTip: "Please select a workspace:",
  youHaveNotJoinedAnyProjectTip: "You have not joined any workspaces. Please contact the administrator.",
  logout: "Logout",
  backToProject: "Back to Workspace",
  searchNoContentTip: "No corresponding workspace found. Please try changing the keyword.",
  searchPlaceholder: "Enter keyword to search",
};
