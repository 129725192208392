export default {
  factTable: "事实表",
  factTableDes:
    "事实表是观察事物得到的事实数据，事实涉及来自业务过程事件的度量，基本都是以数量值表示；这里的事实表也会包括维度和时间维度这两类描述性属性。事实表通常包含大量数据行，并且数据量会随着时间大量增长。",
  timeDimension: "时间维度",
  timeDimensionDes: "在工作区用于描述时间、日期的字段。如商品销售的日期。",
  dimension: "维度",
  dimensionDes: "除时间维度外，在工作区用于描述业务、属性的字段。如城市、店名。",
  indicator: "指标",
  indicatorDes: "在工作区中，用于衡量业务功能或内容的可量化参数。",
  model: "模型",
  modelDes: "为了使用自然语言搜索和快速数据查找，所建立规则下的数据检索范式。",
  dimensionTable: "维度表",
  dimensionTableDes:
    "维度表是观察事物的角度，提供某一业务过程实践所涉及的用于过滤及分类事实的描述性属性，用于描述于“谁、什么、哪里、为什么、如何”相关的事件。配置过的维度表可以在其他模型中被重复使用；也可以使用同一个数据库的原始表配置多张维度表。",
  workspaceFieldType: "字段类型",
  workspaceFieldTypeDes: "定义该数据列在系统中的属性，分为时间维度、维度和指标。",
  workspaceFieldTypeDesOfTimeDimension: "时间维度：以时间作为描述、表达变量的度量尺度，通常为日期。如，订单的下单日期；",
  workspaceFieldTypeDesOfDimension: "维度：事物“有联系”的抽象概念的数量，也可以理解为描述角度，通常为文字信息。如某连锁企业的门店地址；",
  workspaceFieldTypeDesOfIndicator: "指标：衡量目标的参数，通常为数字。如某品类商品的销量或销售额。",
  dataColumn: "数据列",
  dataColumnDes: "为保证性能，仅有勾选的字段会进入搜索结果的计算与展示",
  fieldNameForSearch: "关键词",
  fieldNameForSearchDes: "请用1个自然语言词语描述概括该列的数据或内容，以便在搜索时使用该词语查找该列的内容。",
  fieldNameForSearchInputTip: "请为时间维度/维度/指标命名，该名称可用于问题搜索。可补充多个别名提高搜索命中率，如“今日”和“今天”。",
  dataType: "数据类型",
  dataTypeDes: "定义系统中该列的数据格式，对应到模型中数据搜索所需的格式。",
  displayFormat: "展示格式",
  displayFormatDes: "定义数据列的数据或内容在搜索结果中展示的样式。如该列数据类型是浮点数，可能需要展示为百分比。",
  currencyCode: "货币单位",
  currencyCodeDes: "当指标与金额相关时可以选择其货币单位，支持多种货币单位",
  defaultTimeUnit: "默认时间单位",
  fieldContentForSearch: "维度值可搜索",
  fieldContentForSearchDes:
    "打开后该列非纯数值内容可直接被算法检索。例如，“省”里包含了“北京”、“上海”、“广东”等，打开此功能，则“北京”、“上海”、“广东”等词则可以被算法直接检索，而“711”由于是纯数值不能被检索。",
  polymerizationWay: "计算方式",
  polymerizationWayDes: "指标的计算整合方式。如，不同时间不同门店的营业额，按门店进行营业额的加和。",
  defaultOrder: "数值类型",
  defaultOrderDes:
    "正面：表示排序时数值越大，表示该指标的效益越好，此时最大值等价于问题中最高、最好、最大等含义，如营业额 100、80、50 中最大收益为 100；负面指标：排序时数值越小，表示该指标的效益越好，此时最大值等价于问题最低、最差、最小等含义，如亏损额 -100、-80、-50 中最小亏损为 -50",
  addGlobalFilter: "添加全局过滤条件",
  addGlobalFilterDes:
    "当前事实表某个或某些字段所构成的SQL，或数据库所对应检索语句构成的条件，作为全局数据检索的过滤条件。即通用SQL查询语句 where 后面所对应的条件（此处的SQL语句请按照客户所使用的数据库检索语法规则来书写）。",
  searchWordsLimits: "搜索词限制",
  searchWordsLimitsDes: "数据列内可能包含大量可被搜索的内容，此处允许添加规则限制内容加载的数量。",
  searchDimensionPlus: "默认搜索维度补全",
  searchDimensionPlusDes:
    "成员输入的自然语言缺少维度词时，会使用该项内容补全。如该项填写“耐克”，成员在输入“本月营业额时”，搜索结果补全为“本月耐克营业额”。注意，该配置仅针对该数据列。",
  autoGroupBySearchResult: "搜索结果自动分组",
  autoGroupBySearchResultDes:
    "开启后成员在输入本维度内多个搜索词时，搜索结果将以分组方式展示。如输入“阿迪达斯耐克彪马的营业额”，品牌名将分别作为x轴1个坐标，展示3个品牌营业额的对比。关闭开关数据将聚合展示（3个品牌营业额的累加）。",
  dataTypeMapping: "映射",
  dataTypeMappingDes: "可在此将原数据表中字段里的某些值进行一些映射。比如，把1映射为“男”，0映射为“女”，等。",
  timelineScaleUnit: "时间轴刻度单位",
  timelineScaleUnitDes:
    "设置一个时间段内，搜索结果图表中时间轴刻度的单位，该时间段内的数据会按照该单位聚合；如设置60天内的数据以“周”聚合，时间轴上的一个刻度即为一周。",
  theTableRelationship: "表的关联关系",
  theTableRelationshipDes: "根据两个表的列之间的关系，从这些表中查询数据",
  relatedColumns: "关联列",
  relatedColumnsDes: "帮助两个表建立关系的一列或多列的键",
  databaseUpdateLoading: "数据库正在刷新，请稍候",
  databaseUpdateLoadingDes: "数据库正在刷新，请稍候。",
  updateDatabase: "刷新数据库",
  updateDatabaseDes: "刷新数据库。",
  databaseConnectSuccess: "数据库已连通",
  databaseConnectSuccessDes: "数据库已连通。",
  databaseConnectLoading: "数据库正在尝试连接。请稍候",
  databaseConnectLoadingDes: "数据库正在尝试连接。请稍候。",
  databaseStartConnect: "点击连接数据库",
  databaseConnectFail: "数据库连接失败",
  databaseConnectUnSet: "数据连接未设置",
  synonyms: "别名",
  synonymsDes: "字段关键词的别名，可以提升用户在搜索时的命中率。可以填写多个，请使用逗号隔开。",
  contentKeyword: "维度值",
  contentKeywordDes: "当模型中某字段开启[维度值可搜索]时，该字段包含的数据（不包含字段名）会成为维度值。",
  contentKeywordSynonyms: "同义词",
  contentKeywordSynonymsDes: "维度值同义词需满足如下规则： ",
  contentKeywordSynonymsDesSame: "1. 同一维度中配置的相同同义词将被视为失效，如河北和河南的同义词不能同时为“河” ",
  contentKeywordSynonymsDesOther: "2. 同一维度中不能将维度值配置为别的维度值的同义词，如河北的同义词为河南 ",
  contentKeywordSynonymsDesCharacter: "3. 同义词不能包含特殊符号（仅支持 - /  _ 4种），且不能为纯数字",
  appliedModel: "应用模型",
  appliedModelDes: "指标、维度和维度表都是系统中的概念，可以应用到具体模型的具体字段。修改维度、指标、维度表时将会影响其所有应用的模型。",
  recommendRating: "推荐优先级",
  recommendRatingDes: "影响维度在系统中的推荐权重，优先级越高的维度将在关联推荐和智能分析中优先展示",
  tableType: "表类型",
  tableTypeDesCustomTable: "自定义表：通过SQL自定义从数据库中拉取的表",
  tableTypeDesDatabaseTable: "数据库表：从数据源中直接同步到系统中的表",
  applyModel: "应用模型",
  applyModelDes: "表的应用模型，修改自定义表将影响所有其应用的模型",
};
