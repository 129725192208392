export default {
  forgetPassword: "忘记密码",
  submit: "设置密码并登录",
  back: "返回",
  passwordInputPlaceholder: "请输入密码",
  inconsistentPassword: "两次密码输入不一致",
  reenterPassword: "请再输入一次密码",
  rememberAccountCheckboxText: "记住账号",
  verificationCodeRequiredMessage: "请输入收到的验证码",
};
