export default {
  search: "搜 索",
  inputPrompt: "请输入您想了解的问题",
  canTry: "可以试试",
  description: "查看说明",
  existingReports: "现有报告",
  seeMore: "查看更多",
  concerns: "已关注的问题",
  searchHistory: "搜索历史",
  searchAdviceTitle: "搜索建议",
  myWatchTitle: "我的观察",
  allViews: "全部浏览记录",
  recentSearch: "最近搜索",
  clearViewsTip: "要清除浏览请前往“我的”-“搜索历史”页面",
  startSearchText: "暂时没有可添加的内容，请开始探索数据吧~",
  updateCardSuccessToast: "更新卡片成功",
  deleteSuccessToast: "删除成功",
  save: "保存",
  cardSaved: "保存的卡片",
  addContentWatched: "添加观察的内容",
  noAuthPrompt: "您暂无此数据权限，请联系工作区管理员",
  deleteCardBtnText: "删除卡片",
  dragPrompt: "拖拽  可移动位置",
  pinboard: "报告",
  concern: "关注的问题",
  feedbackEntryTooltip: "对搜索结果不满意可在此反馈",
  feedback: "校准反馈",
  help: "帮助文档",
  helpTooltip: "点击前往帮助文档",
};
