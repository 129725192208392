import { useContext } from "react";
import { AccountTier, PinboardPermission, PinboardShareRequestStatus, ReminderMethod } from "@/components/Constants";
import MessageCardContent from "@/components/MessageCardContent";
import GlobalContext from "@/contexts/GlobalContext";
import { isTrueArray } from "@/utils/common";
import { getLocaleText } from "@/utils/locale";
import MessageAvatarType from "./MessageAvatarType";
import MessageCardStatus from "./MessageCardStatus";

export default class MessageCardDetail {
  static CODE_1001 = 1001;

  static CODE_1003 = 1003;

  static CODE_1004 = 1004;

  static CODE_1101 = 1101;

  static CODE_1102 = 1102;

  static CODE_1901 = 1901;

  static CODE_1902 = 1902;

  static CODE_1903 = 1903;

  static CODE_1904 = 1904;

  static CODE_1905 = 1905;

  static CODE_1906 = 1906;

  static CODE_1907 = 1907;

  static CODE_1908 = 1908;

  static CODE_2101 = 2101;

  static CODE_2102 = 2102;

  static CODE_2103 = 2103;

  static CODE_2901 = 2901; // 报告-同意/拒绝 可编辑人 添加新用户

  static CODE_2902 = 2902; // 报告-被分享到工作区 给负责人发的消息  # 暂定不给每个用户发

  static CODE_2903 = 2903; // 报告-转移负责人

  static CODE_2904 = 2904; // 报告-权限发生改变

  static CODE_2906 = 2906; // 搜索反馈-请求审批

  static CODE_4901 = 4901; // 报告-审批可编辑人发起的邀请

  static CODE_4902 = 4902; // 报告-审批链接分享

  // 报告部分需要审批的内容
  static pinboardApprovalCodes = [this.CODE_4901, this.CODE_4902];

  static getMessageCard = message => {
    const {
      approvalStatus,
      expectedText,
      expectedTokens,
      tokens,
      originText,
      forbiddenTokens,
      isKeyword,
      cutTokens,
      title,
      channels,
      channel,
      oldTier,
      tier,
    } = message?.content || {};

    const { isMobile } = useContext(GlobalContext);

    const getText = id => getLocaleText(`pages.Message.${id}`);

    const PERMISSION_TRANSFER = getText("permissionTransfer");
    const PERMISSION_APPLICATION = getText("permissionApplication");

    const baseInfo = {
      content: MessageCardContent({ message }),
    };

    const baseWorkSpaceInfo = {
      avatar: MessageAvatarType.WORKSPACE,
      type: MessageCardStatus.NOTICE,
      title: getText("workspaceReminder"),
      content: MessageCardContent({ message }),
    };

    const basePinboardInfo = {
      avatar: MessageAvatarType.PINBOARD,
      type: MessageCardStatus.NOTICE,
      title: getText("reportReminder"),
      content: MessageCardContent({ message }),
    };

    switch (message.messageType) {
      case MessageCardDetail.CODE_1001:
        return {
          avatar: MessageAvatarType.SYSTEM,
          type: MessageCardStatus.NOTICE,
          title: getText("systemMessages"),
          content: MessageCardContent({ message }),
          messageNote: AccountTier.isUpgrade(oldTier, tier)
            ? oldTier === AccountTier.TIER_3 && getText("canCreateOrEditPin")
            : tier === AccountTier.TIER_3 && getText("PinFrozen"),
        };
      case MessageCardDetail.CODE_1003:
      case MessageCardDetail.CODE_1004:
      case MessageCardDetail.CODE_1101:
        return {
          avatar: MessageAvatarType.SYSTEM,
          type: MessageCardStatus.NOTICE,
          title: getText("systemMessages"),
          content: MessageCardContent({ message }),
        };
      case MessageCardDetail.CODE_1102:
      case MessageCardDetail.CODE_1901:
      case MessageCardDetail.CODE_1902:
      case MessageCardDetail.CODE_1903:
        return basePinboardInfo;
      case MessageCardDetail.CODE_1904: {
        const tokenList = [];
        if (isTrueArray(tokens)) {
          if (isTrueArray(expectedTokens)) {
            tokenList.push({ text: getText("originalMatch"), tokens });
            tokenList.push({ text: getText("nowMatch"), tokens: expectedTokens });
          }
        } else tokenList.push({ text: getText("matchingField"), tokens: expectedTokens });
        return { ...baseWorkSpaceInfo, tokenList };
      }
      case MessageCardDetail.CODE_1905: {
        const tokenList = [];
        if (approvalStatus === PinboardShareRequestStatus.AGREE) {
          if (isTrueArray(expectedTokens)) tokenList.push({ text: getText("nowMatched"), tokens: expectedTokens });
        } else {
          tokenList.push({ text: getText("currentMatch"), tokens });
          tokenList.push({ text: getText("requestedMatch"), tokens: expectedTokens });
        }

        const operation =
          approvalStatus === PinboardShareRequestStatus.AGREE ? (expectedText ? getText("handle") : getText("adopt")) : getText("refuse");

        return {
          ...baseInfo,
          avatar: MessageAvatarType.APPROVAL,
          type: MessageCardStatus.NOTICE,
          avatarName: getText("administrators")[0],
          title: `${getText("administrators")}${getText("already")}${operation}${getText("FeedbackYourSubmitted")}`,
          text: approvalStatus === PinboardShareRequestStatus.AGREE && !isTrueArray(expectedTokens) ? getText("nowUnmatched") : null,
          tokenList,
          note: isTrueArray(forbiddenTokens)
            ? `${getText("notCurrently")}“${forbiddenTokens.map(token => token.word).join("、")}”${getText("contactTheAdministrator")}`
            : null,
        };
      }
      case MessageCardDetail.CODE_1906:
        return { ...baseWorkSpaceInfo, avatar: MessageAvatarType.PINBOARD, title };
      case MessageCardDetail.CODE_1907:
        return {
          ...baseWorkSpaceInfo,
          avatar: MessageAvatarType.PINBOARD,
          messageNote: getText(channel === ReminderMethod.EMAIL ? "bindMailboxForYou" : "useThirdPartyLogin"),
        };
      case MessageCardDetail.CODE_1908:
        return {
          ...baseWorkSpaceInfo,
          avatar: MessageAvatarType.PINBOARD,
          messageNote: getText(channels[0] === ReminderMethod.EMAIL ? "bindMailboxForYou" : "useThirdPartyLogin"),
        };
      case MessageCardDetail.CODE_2101:
      case MessageCardDetail.CODE_2102:
      case MessageCardDetail.CODE_2103:
        return baseWorkSpaceInfo;
      case MessageCardDetail.CODE_2901:
        return { ...basePinboardInfo, title: PERMISSION_TRANSFER };
      case MessageCardDetail.CODE_2902:
        return {
          ...baseInfo,
          avatar: MessageAvatarType.PINBOARD,
          type: MessageCardStatus.OPERATION,
          title: getText("permissionNotification"),
          note: `${getText("remarks")}：${getText("workspaceUsersWith")}【${PinboardPermission.getPermissionOptionText(
            PinboardPermission.READ_USER
          )}】${getText("viewWithPermissions")}`,
          permission: PinboardPermission.getPermissionText(PinboardPermission.READ_USER),
        };
      case MessageCardDetail.CODE_2903:
        return {
          ...baseInfo,
          avatar: MessageAvatarType.PINBOARD,
          type: MessageCardStatus.OPERATION,
          title: PERMISSION_TRANSFER,
          permission: PinboardPermission.getPermissionText(PinboardPermission.RESPONSIBLE),
        };
      case MessageCardDetail.CODE_2904:
        return { ...basePinboardInfo };
      // 搜索反馈审批
      case MessageCardDetail.CODE_2906: {
        // 自行输入
        if (expectedText) {
          return {
            ...baseInfo,
            avatar: MessageAvatarType.APPROVAL,
            type: MessageCardStatus.OPERATION,
            title: getText("feedbackAudit"),
            hasApprovalBtn: true,
            disableMobileBtn: true,
            approvalBtnConfig: {
              agreeText: getText("matchNow"),
              refuseText: getText("ignore"),
              agreedText: getText("processed"),
              rejectedText: getText("ignored"),
            },
            tokenList: [isKeyword ? { text: getText("existingField"), tokens: [cutTokens] } : { text: getText("currentMatch"), tokens }],
            hasUserDemand: true,
            note: !isMobile && !isKeyword && getText("unconfiguredThenAddToWorkspace"),
          };
        }
        // 非自行输入
        const getConfirmText = () => {
          // 无当前匹配
          if (!isTrueArray(tokens)) {
            if (expectedTokens.length === 1) return `${getText("afterAgreeWillBe")} ${originText} ${getText("visibleToHasPermissions")}`; // 请求匹配为一个
            if (expectedTokens.length === 0) return null; // 请求匹配和当前匹配都为无，不弹确认框
            return getText("createNewRules"); // 请求匹配为多个
          }
          // 有当前匹配
          if (!isTrueArray(expectedTokens)) return `${getText("afterAgreeToDeleteNowRule")} ${originText} ${getText("matchingOf")}`; // 请求匹配为无
          if (expectedTokens.length === 1) {
            return `${getText("afterAgreeToDeleteOldRule")}${expectedTokens[0].word} ${getText("willBe")} ${originText} ${getText(
              "visibleToHasPermissions"
            )}`; // 请求匹配为一个
          }
          return getText("afterAgreeToReplaceRule"); // 请求匹配为多个
        };
        return {
          ...baseInfo,
          avatar: MessageAvatarType.APPROVAL,
          type: MessageCardStatus.OPERATION,
          title: getText("feedbackAudit"),
          hasApprovalBtn: true,
          disableMobileBtn: true,
          approvalBtnConfig: { confirmText: getConfirmText() },
          tokenList: [
            { text: getText("currentMatch"), tokens },
            { text: getText("requestedMatch"), tokens: expectedTokens },
          ],
        };
      }
      case MessageCardDetail.CODE_4901:
        return {
          ...baseInfo,
          avatar: MessageAvatarType.PINBOARD,
          type: MessageCardStatus.OPERATION,
          title: PERMISSION_APPLICATION,
          hasPermissionSelect: true,
          hasApprovalBtn: true,
        };
      case MessageCardDetail.CODE_4902:
        return {
          ...baseInfo,
          avatar: MessageAvatarType.PINBOARD,
          type: MessageCardStatus.OPERATION,
          title: PERMISSION_APPLICATION,
          hasPermissionSelect: true,
          hasNote: true,
          hasApprovalBtn: true,
        };
      default:
        return {
          avatar: MessageAvatarType.SYSTEM,
          type: MessageCardStatus.NOTICE,
          title: "",
          content: "",
        };
    }
  };
}
