export default {
  panelTitleForecastDesc: "预测解析",
  panelTitleConfidenceInterval: "置信区间",
  panelTitleModelDesc: "模型描述",
  goToIntro: "前往说明书了解更多",
  trainRangeLabel: "训练时段",
  forecastRangeLabel: "预测时段",
  dependentUnitLabel: "选择因变量",
  independentUnitsLabel: "选择自变量",
  selectPlaceholder: "请选择",
  tableStatusTitle: "状态",
  tableApplying: "应用中",
  tableUnApply: "未应用",
  trainRange: "训练时段",
  forecastRange: "预测时段",
  dependentUnit: "因变量",
  independentUnits: "自变量",
  tableMapeDesc: "该参数最好小于20%",
  tableTopDesc: "可在修改模型处通过修改时间范围和加入指标来提升质量度量，优化模型的表现。度量的数值越小说明提升效果越好。",
  changePoints: "拐点和趋势",
  seasonality: "周期性波动",
  qualityMetric: "质量度量",
  changeModelParams: "调整模型参数",
  cancel: "取消",
  ok: "确定",
  checkBtn: "查看",
  confidenceInterval: "置信区间",
  showHistoryForecast: "显示过去预测/区间值",
  titleBar: "预测信息",
  valueDesc1: "根据预测 ",
  valueDesc2: " 至 ",
  valueDesc3: " ，总",
  valueDesc4: "将达到",
  valueDesc5: "，平均每日",
  valueDesc6: "为",
  valueDesc7: "。",
  changePointsDesc1: "下降趋势变为上升趋势",
  changePointsDesc2: "上升趋势变为下降趋势",
  changePointsDesc3: "预测区间内，",
  changePointsDesc4: "从",
  changePointsDesc5: "，拐点出现在",
  changePointsDesc6: "。",
  noData: "无",
  subTitle1: "根据",
  subTitle2: "至",
  subTitle3: "的数据预测",
  subTitle4: "至",
  pleaseChooseOverAYearTrainRange: "请选择 1 年以上的训练时段",
  pleaseChooseBelowHalfOfTrainTimeRange: "请选择少于 1/2 训练时长的预测时段",
  pleaseInput: "请输入",
  lessThanOneYearErrorTip: "训练数据不足一年，预测结果可能不准确",
  moreThanMaxTip1: "训练时段大于设置范围最大值",
  moreThanMaxTip2: "，请缩短训练时段，或联系管理员",
  lessThanMinTip1: "训练时段小于设置范围最小值",
  lessThanMinTip2: "，请增加训练时段，或联系管理员",
};
