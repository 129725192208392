import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.MemberGroupSettingTab.${id}`);

export default class MemberGroupSettingTab {
  static MEMBER = "member";

  static ROLE = "role";

  static INFO = "info";

  static choices = [this.MEMBER, this.ROLE, this.INFO];

  static getText = t => {
    if (t === this.MEMBER) return getTextById("userManagement");
    if (t === this.ROLE) return getTextById("permissionManagement");
    if (t === this.INFO) return getTextById("basicInfo");
    return "";
  };
}
