export default {
  modalTitle: "模型概览",
  dataOverview: "数据总览",
  timeDimensionStatistic: "时间维度统计",
  measureStatistic: "指标统计",
  dimensionStatistic: "维度统计",
  allTableDataTip: "结果由全表数据扫描得出",
  partTableDataTip: "结果由前10万行数据抽样统计得出",
  refreshButtonText: "刷新概览数据",
  loading: "查询中...",
  loadingTip: "可等待概览查询完成后再打开查看",
  loadingFailed: "查询失败",
  overviewColumnName: "统计项目",
  overviewColumnValue: "值",
  overviewColumnNameTimeSeries: "时间维度数",
  overviewColumnNameDimension: "维度数",
  overviewColumnNameMeasure: "指标数",
  timeDimensionColumnName: "时间维度名称",
  timeDimensionColumnMinUnit: "最小颗粒度",
  timeDimensionColumnMin: "起始时间",
  timeDimensionColumnMax: "结束时间",
  timeDimensionColumnTypeError: "数据类型错误",
  nonFieldTip: "自定义列不支持统计",
  countColumnTip: "计数/计数（去重）不支持统计",
  measureColumnName: "指标名称",
  measureColumnMax: "最大值",
  measureColumnMin: "最小值",
  measureColumnMean: "均值",
  measureColumnStd: "标准差",
  measureColumnMedian: "中位数",
  measureColumnNull: "Null值比例",
  dimensionColumnName: "维度名称",
  dimensionColumnTotal: "维度值数/总行数",
  dimensionColumnNull: "Null值比例",
  dimensionColumnDisplay: "维度值示例（出现频次最高的5个维度值）",
  refreshSuccessToast: "刷新概览数据成功",
  refreshFailedToast: "刷新概览数据失败，请稍后重试",
  displaySplit: "，",
};
