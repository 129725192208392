import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`pages.admin.personalization.${id}`);

export default class PersonalizationTab {
  static PersonalizedStyle = "personalizedStyle";

  static PersonalizedFunction = "personalizedFunction";

  static choices = [this.PersonalizedStyle, this.PersonalizedFunction];

  static getText = type => {
    if (type === this.PersonalizedStyle) return getText("personalizedStyle");
    if (type === this.PersonalizedFunction) return getText("personalizedFunction");
    return "";
  };
}
