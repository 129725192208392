export default class DingTalkAppType {
  static ROBOT = 1;

  static APP = 2;

  static getText = value => {
    if (value === DingTalkAppType.ROBOT) return "钉钉机器人";
    if (value === DingTalkAppType.APP) return "钉钉应用";
    return "";
  };
}
