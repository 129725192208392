import React from "react";

const Filter = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66667 2C2.29848 2 2 2.29848 2 2.66667V3.66667C2 3.85076 2.14924 4 2.33333 4H13.6667C13.8508 4 14 3.85076 14 3.66667V2.66667C14 2.29848 13.7015 2 13.3333 2H2.66667Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M2.59488 5.33331C2.28819 5.33331 2.14425 5.71253 2.3737 5.91603L6.48323 9.56077C6.59023 9.65567 6.65147 9.79183 6.65147 9.93485V13.5681C6.65147 13.8052 6.89204 13.9665 7.11139 13.8765L9.06478 13.0746C9.2524 12.9976 9.3749 12.8149 9.3749 12.6121V9.93405C9.3749 9.7915 9.43575 9.65573 9.54214 9.56086L13.6305 5.91544C13.8591 5.71162 13.7149 5.33331 13.4086 5.33331H2.59488Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default Filter;
