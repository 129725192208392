import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.TableFieldType.${id}`);

export default class TableFieldType {
  static TIME_SERIES = "timeSeries";

  static DIMENSION = "dimension";

  static MEASURE = "measure";

  static CUMULATIVE = "cumulative";

  static choices = [TableFieldType.TIME_SERIES, TableFieldType.DIMENSION, TableFieldType.MEASURE, TableFieldType.CUMULATIVE];

  static boolChoices = [TableFieldType.DIMENSION];

  static getText = type => {
    if (type === TableFieldType.TIME_SERIES) return getTextById("timeSeries");
    if (type === TableFieldType.DIMENSION) return getTextById("dimension");
    if (type === TableFieldType.MEASURE) return getTextById("measure");
    if (type === TableFieldType.CUMULATIVE) return getTextById("cumulative");
    return "";
  };

  static getSelectOptions = (choice = TableFieldType.choices) => choice.map(value => ({ label: this.getText(value), value }));

  static getFieldByText = text => {
    if (text === "时间维度") return TableFieldType.TIME_SERIES;
    if (text === "维度") return TableFieldType.DIMENSION;
    if (text === "指标") return TableFieldType.MEASURE;
    if (text === "累计指标") return TableFieldType.CUMULATIVE;
    return "";
  };
}
