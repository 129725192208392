import React from "react";

const DownOutlined = props => {
  return (
    <svg {...props} width="1em" height=".7em" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 1.5L5 5.5L1 1.5" stroke="currentColor" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DownOutlined;
