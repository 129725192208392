import React from "react";

const ShareFilled = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4.26667C14 5.51851 12.9852 6.53333 11.7333 6.53333C10.972 6.53333 10.2983 6.15796 9.88726 5.58218L6.47035 7.46715C6.51152 7.63804 6.53333 7.81647 6.53333 8C6.53333 8.12467 6.52327 8.24698 6.50391 8.36618L9.99219 10.282C10.408 9.7837 11.0336 9.46667 11.7333 9.46667C12.9852 9.46667 14 10.4815 14 11.7333C14 12.9852 12.9852 14 11.7333 14C10.4815 14 9.46667 12.9852 9.46667 11.7333C9.46667 11.5398 9.49092 11.3519 9.53655 11.1726L6.1246 9.29875C5.71474 9.88399 5.03539 10.2667 4.26667 10.2667C3.01482 10.2667 2 9.25185 2 8C2 6.74815 3.01482 5.73333 4.26667 5.73333C4.97649 5.73333 5.6101 6.05961 6.02571 6.57036L9.49962 4.65395C9.47796 4.5281 9.46667 4.3987 9.46667 4.26667C9.46667 3.01482 10.4815 2 11.7333 2C12.9852 2 14 3.01482 14 4.26667Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default ShareFilled;
