import React from "react";

const ConfirmWarning: React.FC = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08018 2.2641C5.61314 1.41146 4.70743 0.833328 3.66669 0.833328C2.1479 0.833328 0.916687 2.06455 0.916687 3.58333C0.916687 4.75377 1.6479 5.75343 2.67843 6.15041C2.13605 7.32091 1.83335 8.6251 1.83335 9.99999C1.83335 15.0626 5.93741 19.1667 11 19.1667C16.0626 19.1667 20.1667 15.0626 20.1667 9.99999C20.1667 8.6251 19.864 7.32091 19.3216 6.15041C20.3521 5.75343 21.0834 4.75377 21.0834 3.58333C21.0834 2.06455 19.8521 0.833328 18.3334 0.833328C17.2926 0.833328 16.3869 1.41146 15.9199 2.2641C14.4984 1.35821 12.8105 0.833328 11 0.833328C9.18953 0.833328 7.50163 1.35821 6.08018 2.2641ZM11.9076 6.33333C11.9076 5.82707 11.4992 5.41666 10.9955 5.41666C10.4917 5.41666 10.0834 5.82707 10.0834 6.33333V10C10.0834 10.5063 10.4917 10.9167 10.9955 10.9167C11.4992 10.9167 11.9076 10.5063 11.9076 10V6.33333ZM10.9955 12.75C10.4917 12.75 10.0834 13.1604 10.0834 13.6667C10.0834 14.1729 10.4917 14.5833 10.9955 14.5833H11.0046C11.5083 14.5833 11.9167 14.1729 11.9167 13.6667C11.9167 13.1604 11.5083 12.75 11.0046 12.75H10.9955Z"
        fill="var(--datarc-color-warning)"
      />
    </svg>
  );
};

export default ConfirmWarning;
