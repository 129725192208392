export default {
  guide: "初始化",
  settings: "基础设置",
  sso: "登录设置",
  workspaceManagement: "工作区管理",
  personalization: "个性化设置",
  user: "用户管理",
  audit: "审计日志",
  systemStatus: "系统状态",
  email: "邮箱设置",
  llm: "大语言模型设置",
  shortMessage: "短信设置",
  netStatus: "网络状态",
};
