export default {
  feedback: "语义校准规则",
  feedbackTip: "当用户输入的搜索词需要对应多个字段时，可以通过校准反馈申请，审核后生效",
  searchInputPlaceholder: "请输入想搜索的信息",
  addBtnText: "添加规则",
  addSuccessToast: "创建成功",
  deleteSuccessToast: "删除成功",
  saveSuccessToast: "修改成功",
  editBtnText: "编辑",
  deleteBtnText: "删除",
  deleteConfirmTitle: "确认删除该语义校准规则？",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  invalidRuleTip: "该规则已失效",
  userInput: "用户输入",
  tokens: "字段匹配",
  operator: "操作人",
  disabledAccountTip: "该用户已被禁用或注销",
  updatedAt: "更新时间",
  operations: "操作",
  feedbackModalTitle: "语义校准规则",
  userInputLabel: "用户输入",
  okBtnText: "确认",
};
