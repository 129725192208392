import _ from "lodash";
import React, { Ref, useContext, useEffect, useMemo, useState } from "react";
import { LoadingCircle, Magnifier } from "@/components/SVGIcons";
import GlobalContext from "@/contexts/GlobalContext";
import { getLocaleText } from "@/utils/locale";
import Input, { InputProps, InputRef } from "../Input";

import styles from "./index.module.scss";

const getText = id => getLocaleText(`components.datarc.AutoSearchInput.${id}`);

interface AutoSearchInputType extends InputProps {
  placeholder?: string;
  className?: string;
  inputRef?: Ref<InputRef>;
  loading?: boolean;
  onSearch: React.Dispatch<React.SetStateAction<string>>;
  delay?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
}

// 存在输入内容，自动触发搜索，并且隐藏搜索图标，loading 为 true 时候，在输入框开头显示 loading 图标
const AutoSearchInput: React.FC<AutoSearchInputType> = ({
  placeholder = getText("placeholder"),
  className = "",
  inputRef,
  loading,
  onSearch,
  style = {},
  delay = 300,
  onChange,
  value,
  ...rest
}) => {
  const [v, setV] = useState("");
  const [isCompositionStart, setIsCompositionStart] = useState(false); // IME 输入状态

  const debouncedOnSearch = useMemo(() => _.debounce(onSearch, delay), []);

  const { isMobile } = useContext(GlobalContext);

  useEffect(() => {
    // 中文输入法下，输入拼音情况下，选择了文字才搜索，正在输入拼音的情况下不搜索
    if (!isCompositionStart) debouncedOnSearch(_.isNil(value) ? v : value); // 空数据也需要重新 set 触发搜索刷新
  }, [isCompositionStart, value, v]);

  return (
    <div className={`${isMobile ? styles.mobileInputBox : styles.inputBox} ${className}`} style={style}>
      <Input.Search
        onCompositionStart={() => setIsCompositionStart(true)}
        onCompositionEnd={() => setIsCompositionStart(false)}
        ref={inputRef}
        placeholder={placeholder}
        prefix={loading ? <LoadingCircle style={{ fontSize: "20px" }} /> : null}
        suffix={
          isMobile || v ? null : <Magnifier className="search-icon" style={{ fontSize: "16px", color: "var(--datarc-color-text-3)" }} />
        }
        onChange={_.isNil(onChange) ? e => setV(e.target.value) : onChange}
        value={_.isNil(value) ? v : value}
        {...rest}
      />
    </div>
  );
};

export default AutoSearchInput;
