export default {
  feedbackAndCalibration: "校准反馈",
  submit: "提交",
  cancel: "取消",
  fieldMatch: "字段匹配",
  fieldMatchPrompt: "如当前匹配不准确，请在以下列表中选择想要匹配的字段",
  fieldSelectPlaceholder: "指定匹配的字段",
  modelSelectPlaceholder: "未指定模型",
  noSuitableMatchText: "没有合适的匹配",
  yourInput: "你的输入",
  inputPlaceholder: "输入想要匹配的词语，目前支持中英文字符及 _ / \\ - 的输入",
  searchNoResultText: "查找无结果，请更换检索条件。",
  maxTagPlaceholderFormer: "等",
  maxTagPlaceholderLatter: "个模型",
  all: "全部",
  dimension: "维度",
  measure: "指标",
  successToast: "校准反馈提交成功",
  errorToast: "提交失败，请稍后再试",
  duplicateTextError: "已有对应字段，请更换搜索词，或进入左下角“没有合适的匹配”反馈",
  existingFieldError: "已有对应字段",
  illegalTextError: "目前支持中英文字符及 _ / \\ - 的输入",
  fieldMatchCountCheckHelp: "语义校准规则需要至少选择2个匹配字段",
  emptyFieldMatchCheckHelp: "请选择想要匹配的字段",
  feedbackLabel: "希望得到的匹配",
  feedbackInfo: "工作区管理员将收到该反馈",
  feedbackPlaceholder: "请具体描述你想得到的数据匹配...",
  back: "返回",
  feedbackToast: "感谢你的反馈",
  feedbackIssuesTitle: "你想反馈的问题",
  othersPlaceholder: "也可以填写其他对产品的建议与反馈",
  feedbackDesc: "请填写你不满意的原因，这将帮助我们提供更好的服务",
  feedbackType: "在此处切换指标和维度，在下方勾选你需要匹配的指标或维度，可以多选",
  feedbackConfirm1: "点击此处提交校准反馈。",
  feedbackConfirm2: "提交后需要管理员审核，审核通过后才能在搜索框直接搜索你反馈的短语。",
};
