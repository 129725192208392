import React from "react";

const DoubtCircle = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0.965517C3.21953 0.965517 0.965517 3.21953 0.965517 6C0.965517 8.78047 3.21953 11.0345 6 11.0345C8.78047 11.0345 11.0345 8.78047 11.0345 6C11.0345 3.21953 8.78047 0.965517 6 0.965517ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00087 8.22217C6.30769 8.22217 6.55642 8.4709 6.55642 8.77772C6.55642 9.08455 6.30769 9.33328 6.00087 9.33328C5.69404 9.33328 5.44531 9.08455 5.44531 8.77772C5.44531 8.4709 5.69404 8.22217 6.00087 8.22217Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99956 2.6665C7.22686 2.6665 8.22179 3.66143 8.22179 4.88872C8.22179 5.88584 7.56507 6.72957 6.66056 7.01098L6.55512 7.04094V7.11095C6.55512 7.41777 6.30639 7.6665 5.99956 7.6665C5.71466 7.6665 5.47984 7.45203 5.44775 7.17574L5.44401 7.11095V6.55539C5.44401 6.27048 5.65848 6.03566 5.93478 6.00357L6.08249 5.99679C6.65741 5.95437 7.11068 5.47448 7.11068 4.88872C7.11068 4.27508 6.61321 3.77761 5.99956 3.77761C5.38592 3.77761 4.88845 4.27508 4.88845 4.88872C4.88845 5.19555 4.63972 5.44428 4.3329 5.44428C4.02607 5.44428 3.77734 5.19555 3.77734 4.88872C3.77734 3.66143 4.77227 2.6665 5.99956 2.6665Z"
      fill="currentColor"
    />
  </svg>
);

export default DoubtCircle;
