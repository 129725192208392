/* 一些常用的字符串常量 */
export default class Str {
  static NAME = "name";

  static DATA = "data";

  static TITLE = "title";

  static SUB_TITLE = "sub_title";

  static CARD_FILTER_UNITS = "card_filter";

  static DESCRIPTION = "description";

  static EXPLANATIONS = "explanations";

  static KEYWORDS = "analysisKeywords";

  static PROPORTION_TYPE = "proportionType";

  static CARD_TYPE = "card_type";

  static DATE_TIME_ANCHOR = "datetimeAnchor";

  static ORIGIN_CARD_TYPE = "origin_card_type";

  static CHART_CHOICES = "chart_choices";

  static COLUMN = "column";

  static COLUMNS = "columns";

  static ADAPTER = "adapter";

  static PROJECT = "project";

  static SQL = "sql";

  static QUERY_KEY = "qKey";

  static PARAMS = "params";

  static OFFSET = "offset";

  static LIMIT = "limit";

  static X_AXIS = "xAxis";

  static Y_AXIS = "yAxis";

  static Z_AXIS = "zAxis";

  static SERIES = "series";

  static X_AXIS_PARAMS = "x_axis_params";

  static Y_AXIS_SCALE = "yAxisScale";

  static UNITS = "units";

  static OPERATOR = "operator";

  static SORT_TIME_SERIES = "sortTimeSeries";

  static DRILL_ERROR_CODE = "DrillErrorCode";

  // 图表字符串
  static LINE = "line";

  static BAR = "bar";

  static MIXED_BAR_LINE = "mixedBarLine";

  static PIE = "pie";

  static STACK = "stack";

  static SCATTER = "scatter";

  static VALUE_SCATTER = "value_scatter";

  static HEATMAP = "heatmap";

  static SANKEY = "sankey";

  static FUNNEL = "funnel";

  static MAP = "map";

  static TABLE = "table";

  // Border Style
  static DOTTED = "dotted";

  static SOLID = "solid";

  static DASHED = "dashed";

  static CONFIGS = "configs";

  static RESULT_TYPE = "resultType";

  static TABLE_BATCH_SIZE = "tableBatchSize";

  // 中文字符
  static PERCENT = "占比";

  // 位置
  static TOP = "top";

  static BOTTOM = "bottom";

  static LEFT = "left";

  static RIGHT = "right";

  static HIDDEN = "hidden";

  static REMOVE = "remove";

  static POSITION = "position";

  static TOTAL = "total";

  static SORT = "sort";

  // column 类型
  static DIMENSION = "dimension"; // 维度

  static TIME_DIMENSION = "timeDimension"; // 时间维度

  static MEASURE = "measure"; // 指标

  static CUSTOM_COLUMN = "customColumn";

  static NO_X_AXIS = "noXAxis";

  // 是否超出浏览器缓存极限
  static IS_CACHE_EXCEEDED = "isCacheExceeded";

  static END_TIME = "End"; // 历史数据结束时间

  // 维度展示类型
  static DEFAULT_TYPE = "default"; // 默认展示类型(全展示);

  static NUMBER_TYPE = "number"; // 展示数值;

  static PERCENT_TYPE = "percent"; // 展示百分比;

  static THRESHOLD_TYPE = "threshold"; // 展示阈值;

  // 浏览器缓存内的 key
  static AI_CORRECTIONS = "aiCorrections"; // 大语言模型错误解释

  static COLOR_AVATAR = "colorAvatar"; // 用户头像颜色

  static DATARC_PERSONAL_THEME = "datarcPersonalTheme"; // 个人主题设置
}
