export default {
  editModalTitle: "编辑日期标签",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  datePickerLabel: "日期",
  settingsLabel: "标签设置",
  addBtnText: "新增",
  cancelAddBtnText: "删除新增",
  tagName: "标签名",
  selectTagPlaceholder: "选择标签",
  addTagBtnText: "添加",
  invalidNameErrorToast: "请检查标签名称格式是否正确",
  repeatedNameErrorToast: "标签名称重复",
  cycleType: "是否循环",
  noCycle: "不循环",
  monthCycle: "月循环",
  yearCycle: "年循环",
  timeRange: "时间范围",
  yearInputPlaceholder: "50以内整数",
  monthInputPlaceholder: "600以内整数",
  yearSuffix: "年",
  monthSuffix: "月",
  operations: "操作",
  deleteBtnText: "删除",
  deleteConfirmTitle: "只删除此日期下的此标签，不影响循环内其他日期的标签。确认删除此标签？",
  deleteCycleBtnText: "删除循环",
  deleteCycleConfirmTitle: "此标签下，此日期相关循环内所有已打标签都将被删除。确认删除循环？",
  /* 自定义标签弹窗补充部分 */
  addCustomTagModalTitle: "添加自定义标签",
  editCustomTagModalTitle: "编辑标签",
  tagNameInputPlaceholder: "请输入标签名",
  customTagNameErrorToast: "请检查标签名格式",
  timeRangeErrorToast: "请检查时间范围是否正确",
  startDate: "起始日期",
  customTagSettingsLabel: "标签规则",
};
