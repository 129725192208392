export default {
  panelTitleForecastDesc: "Forecast Analysis",
  panelTitleConfidenceInterval: "Confidence Interval",
  panelTitleModelDesc: "Model Description",
  goToIntro: "Go to the manual to learn more",
  trainRangeLabel: "Training Period",
  forecastRangeLabel: "Forecast Period",
  dependentUnitLabel: "Select Dependent Variable",
  independentUnitsLabel: "Select Independent Variables",
  selectPlaceholder: "Please select",
  tableStatusTitle: "Status",
  tableApplying: "Applying",
  tableUnApply: "Unapplied",
  trainRange: "Training Period",
  forecastRange: "Forecast Period",
  dependentUnit: "Dependent Variable",
  independentUnits: "Independent Variables",
  tableMapeDesc: "This parameter is best kept under 20%",
  tableTopDesc:
    "You can modify the time range and add independent variables in order to improve the performance of the model. Typically, a model that yields lower values for quality measures indicates better performance.",
  changePoints: "Change Points and Trends",
  seasonality: "Periodic Fluctuations",
  qualityMetric: "Quality Measures",
  changeModelParams: "Adjust Model Parameters",
  cancel: "Cancel",
  ok: "OK",
  checkBtn: "Check",
  confidenceInterval: "Confidence Interval",
  showHistoryForecast: "Show Past Forecast/Interval Values",
  titleBar: "Forecast Information",
  valueDesc1: "According to the forecast, from ",
  valueDesc2: " to ",
  valueDesc3: " ,the total ",
  valueDesc4: " is projected to reach ",
  valueDesc5: ". the average daily ",
  valueDesc6: " is projected to reach ",
  valueDesc7: ".",
  changePointsDesc1: "The downward trend becomes upward",
  changePointsDesc2: "The upward trend becomes downward",
  changePointsDesc3: "Within the forecast interval,",
  changePointsDesc4: "from",
  changePointsDesc5: ", the turning point appears at",
  changePointsDesc6: ".",
  noData: "Nothing",
  subTitle1: "Based on",
  subTitle2: "to",
  subTitle3: "predictive data",
  subTitle4: "to",
  pleaseChooseOverAYearTrainRange: "Please choose a training period of over 1 year.",
  pleaseChooseBelowHalfOfTrainTimeRange: "Please choose a forecast period that is less than half of the training period.",
  pleaseInput: "Please input.",
  lessThanOneYearErrorTip: "Training data is less than one year old, and predictions may be inaccurate",
  moreThanMaxTip1: "The training range is longer than the maximum range",
  moreThanMaxTip2: ". Please shorten the training range or contact the administrator.",
  lessThanMinTip1: "The training range is shorter than the minimum range",
  lessThanMinTip2: ". Please extend the training range or contact the administrator.",
};
