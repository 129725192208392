export default {
  invalidAnalyse: "Simultaneous querying with other analyses in this search is not supported at this time.",
  invalidAcrossModel: "If you need to perform cross-model queries, please contact the administrator.",
  noPermission: "No permission to view this data at this time.",
  invalidCustomRule: "The semantic rule has expired.",
  invalidMeasureCount: "The relationship between more than two measures is not supported",
  invalidMeasureTypeMix: "The combined calculation of cumulative measures and non-cumulative measures is not supported",
  invalidDataBlending: "Does not meet the triggering conditions for data blend",
  noKeywords: "Current selected model does not contain this keyword.",
  noMultipleTimeDimension: "Unsupported multiple time dimensions.",
};
