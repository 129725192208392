import React from "react";

const DownArrow = ({ arrowColor = "var(--datarc-color-info-active)", ...rest }) => (
  <svg {...rest} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 10.2223C17 10.3645 16.9456 10.5068 16.8372 10.6151L12.3928 15.0595C12.1756 15.2767 11.8245 15.2767 11.6073 15.0595L7.16291 10.6151C6.9457 10.3979 6.9457 10.0468 7.16291 9.82954C7.38013 9.61232 7.73124 9.61232 7.94846 9.82954L12.0001 13.8812L16.0517 9.82954C16.2689 9.61232 16.62 9.61232 16.8372 9.82954C16.9456 9.93787 17 10.0801 17 10.2223Z"
      fill={arrowColor}
    />
  </svg>
);

export default DownArrow;
