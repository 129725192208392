export default {
  invalidAnalyse: "暂不支持与本次搜索中其他分析同时查询",
  invalidAcrossModel: "如需跨模型查询，请联系管理员",
  noPermission: "暂无查看此数据的权限",
  invalidCustomRule: "语义校准规则已失效",
  invalidMeasureCount: "暂不支持两个以上指标的关系",
  invalidMeasureTypeMix: "暂不支持累计指标同非累计指标共同计算",
  invalidDataBlending: "不满足数据融合的触发条件",
  noKeywords: "当前选中的模型不包含此关键词",
  noMultipleTimeDimension: "不支持的多时间维度",
};
