import React from "react";

const SmileFace = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM32.5333 16C34.3006 16 35.7333 14.5673 35.7333 12.8C35.7333 11.0327 34.3006 9.6 32.5333 9.6C30.766 9.6 29.3333 11.0327 29.3333 12.8C29.3333 14.5673 30.766 16 32.5333 16ZM19.2 12.8C19.2 14.5673 17.7673 16 16 16C14.2327 16 12.8 14.5673 12.8 12.8C12.8 11.0327 14.2327 9.6 16 9.6C17.7673 9.6 19.2 11.0327 19.2 12.8ZM30.1412 20.2181C30.5563 20.0666 30.7699 19.6073 30.6184 19.1923C30.4668 18.7772 30.0076 18.5636 29.5925 18.7151C27.685 19.4115 25.5106 19.5667 23.5366 19.4611C21.5659 19.3557 19.8529 18.9936 18.9014 18.7018C18.479 18.5722 18.0316 18.8097 17.902 19.2321C17.7725 19.6545 18.0099 20.1019 18.4323 20.2315C19.5098 20.5619 21.3516 20.9465 23.4511 21.0588C25.5472 21.171 27.9583 21.015 30.1412 20.2181Z"
      fill="currentColor"
    />
  </svg>
);

export default SmileFace;
