export default {
  editBtnText: "Edit",
  saveBtnText: "Save",
  cancelBtnText: "Cancel",
  saveSuccessToast: "Modified successfully",
  /* 数据相关设置 */
  basicSettings: "Data related settings",
  yAxisScale: "Ratio from zero value",
  yAxisScaleTip: "Y-axis coordinate scale will not be forced to include zero scale after opening",
  tableThreshold: "Default display table threshold",
  tableThresholdTip:
    "When the number of data rows ≥ this value (recommended no more than 2000), the content will be displayed in tabular format first, and users can switch charts. Application scope: X-axis non-time charts.",
  tableThresholdErrMsg: "Please enter an integer less than 60000", // 3.31 新增
  tableBatchSize: "Table load data size",
  tableBatchSizeTip: "The amount of data loaded each time the table scrolls",
  breakpointsOptions: "Display style for polyline missing values",
  breakpointsOptionsTip: "The display style of line charts (column-fold mixed charts) when lines are discontinuous due to missing data",
  defaultDisplay: "Do not handle, polyline breakpoints",
  showZero: "Missing values are displayed as zero",
  skipBreakpoints: "Skip missing values, polylines are continuous",
  skipBreakpointsTip:
    "The abscissa corresponding to the missing value is no longer displayed, so that the polyline is continuous. May cause uneven abscissa.",
  ignoreBreakpoints: "Direct connection before and after missing values, polylines are continuous",
  ignoreBreakpointsTip:
    "Display the abscissa corresponding to the missing value, but directly connect the values before and after the missing value, so that the polyline is continuous. At this time, if the mouse hovers over the missing value, the data will not be displayed",
  on: "Open",
  off: "Off",
  /* 图表样式默认设置 & 表格、透视表默认设置 */
  defaultChartStyle: "Chart style default setting",
  defaultTableStyle: "Table, pivot table default settings",
  dataMarkers: "Data Point Tags",
  dataMarkersTip: "The Data Point in the back line chart will be highlighted when turned on",
  dataLabels: "Data point value label",
  dataLabelsTip: "The specific value of the Data Point will be displayed in the chart after opening",
  smoothLine: "Smooth curve",
  smoothLineTip: "After opening the polyline will be displayed as a smooth curve",
  xAxisGridLines: "X-axis gridlines",
  yAxisGridLines: "Y-axis gridlines",
  gridLinesTip: "Heat map, sangi chart, pie chart, word cloud, funnel chart, table, pivot table, map Not applicable",
  legendPosition: "Legend Position",
  legendPositionTip: "Tables, pivot tables, maps Not applicable",
  top: "Top",
  bottom: "Below",
  left: "Left",
  right: "Right",
  hidden: "Hidden legend",
  valueFormat: "Numeric measures",
  percentageFormat: "Percentage measure",
  numberFormat: "Numerical value display style",
  defaultFormat: "Default style",
  originalValue: "Original value",
  decimalPlaces: "Decimal places",
  decimalPlacesTip: "Please enter an integer from 0-10",
  decimalPlacesErrorMsg: "Please enter decimal places",
  thousandsSeparator: "Thousand separator",
  negativeFormat: "Negative number display style",
  rowHeightLabel: "Row height",
  rowHeightLabelTip:
    "Low can display up to 1 line of content, medium can display up to 2 lines, and high can display up to 4 lines (automatic wrapping)",
  heatMapModel: "Heat map model",
  heatMapModelTip: "The cells will be colored according to the value.",
  /* 编辑态的预览窗口 */
  panelTitle: "Preview",
  chart: "Chart",
  table: "Table",
  chartTitle: "Daily Turnover YoY",
  chartSubtitle: "2023-03-24 to 04-02, YoY",
  xAxisName: "Daily",
  measureName: "Turnover",
  yoySales: "YoY Turnover",
  yoySalesGrowth: "YoY growth rate of turnover",
};
