import React from "react";

const TimedReminderIcon = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99999 2.25C8.71004 2.25 8.47499 2.48505 8.47499 2.775V3.04172C6.15737 3.30238 4.35483 5.269 4.35483 7.65756V10.9265C4.35483 11.1129 4.28534 11.2925 4.15993 11.4304L3.16941 12.5194C2.64361 13.0975 3.05376 14.025 3.8352 14.025H6.9715C7.14784 14.985 7.98896 15.7125 8.99999 15.7125C10.011 15.7125 10.8521 14.985 11.0285 14.025H14.1526C14.9359 14.025 15.3453 13.0936 14.8157 12.5165L13.8432 11.4568C13.7158 11.3179 13.6452 11.1363 13.6452 10.9479V7.65756C13.6452 5.27021 11.8439 3.30264 9.52499 3.04175V2.775C9.52499 2.48505 9.28994 2.25 8.99999 2.25ZM12.5202 10.9479V7.65756C12.5202 5.71709 10.9498 4.14258 9.00937 4.13741C7.06158 4.13222 5.47983 5.70976 5.47983 7.65756V10.9265C5.47983 11.3928 5.30595 11.8424 4.99217 12.1874L4.34401 12.9H13.6407L13.0144 12.2174C12.6965 11.871 12.5202 11.418 12.5202 10.9479ZM8.99999 14.5875C8.61556 14.5875 8.28517 14.3561 8.1405 14.025H9.85948C9.71482 14.3561 9.38443 14.5875 8.99999 14.5875ZM15.1913 7.70771C14.8806 7.70771 14.632 7.45509 14.5989 7.14619C14.4993 6.21506 14.1546 5.3577 13.6303 4.63906C13.4472 4.3881 13.4614 4.03393 13.6913 3.825C13.9212 3.61607 14.2792 3.63169 14.467 3.87914C15.168 4.8026 15.6218 5.92481 15.7292 7.14585C15.7564 7.45531 15.5019 7.70771 15.1913 7.70771ZM3.40485 7.14619C3.3718 7.45509 3.12315 7.70771 2.81249 7.70771C2.50183 7.70771 2.24737 7.45531 2.27458 7.14585C2.38194 5.92481 2.8358 4.8026 3.53677 3.87914C3.72459 3.63169 4.08258 3.61607 4.31249 3.825C4.5424 4.03393 4.55659 4.3881 4.37349 4.63906C3.84915 5.3577 3.50449 6.21506 3.40485 7.14619Z"
      fill="currentColor"
    />
  </svg>
);

export default TimedReminderIcon;
