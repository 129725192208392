import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.Effect.${id}`);

export default class Effect {
  static ALLOW = "allow";

  static DENY = "deny";

  static getText = v => getTextById(v === Effect.ALLOW ? "whitelist" : "blacklist");

  static getDataMaskingText = v => getTextById(v === Effect.ALLOW ? "nonSensitiveList" : "sensitiveList");

  static getDataSource = () => [
    {
      label: Effect.getText(Effect.ALLOW),
      value: Effect.ALLOW,
    },
    {
      label: Effect.getText(Effect.DENY),
      value: Effect.DENY,
    },
  ];

  static getDataMasking = () => [
    {
      label: Effect.getDataMaskingText(Effect.DENY),
      value: Effect.DENY,
    },
    {
      label: Effect.getDataMaskingText(Effect.ALLOW),
      value: Effect.ALLOW,
    },
  ];
}
