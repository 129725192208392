export default {
  userGroups: "User groups",
  userGroupSettings: "User group details",
  userGroupFormerTip: "Current group:",
  deleteBtnText: "Delete this user group",
  deleteConfirmTitle: "Confirm to delete this user group?",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  deleteSuccessToast: "User group deleted successfully",
};
