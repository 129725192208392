import { Base64 } from "js-base64";
import _ from "lodash";

const paddingBase64 = (value: string) => {
  const num = 4 - (value.length % 4 | 0);
  return value + (num ? new Array(num).fill("=").join("") : "");
};

// 解码前将 - 换回 /，然后补全 =
export const decodeCustomBase64 = (str: string) => {
  if (!_.isString(str)) return str;
  return Base64.decode(paddingBase64(str.replace(/-/g, "/")));
};

// 编码后，将 / 替换成 -，并且去掉尾部的 =
export const encodeEndStripBase64 = (str, seq = "=") => {
  if (!_.isString(str)) return str;
  return _.trimEnd(Base64.encode(str).replace(/\//g, "-"), seq);
};

// 编码 sql
export const encryptSql = sql => {
  if (!sql) return;
  const data = Base64.encode(sql, true).split("").reverse().join("");
  return _.trim(Base64.encode(data), "=").split("").reverse().join("");
};

// 解码 sql
export const decryptSql = sql => {
  if (!sql) return "";
  const paddingStr = paddingBase64(sql.split("").reverse().join(""));
  return Base64.decode(Base64.decode(paddingStr).split("").reverse().toString());
};

export const getLicencePayload = data => JSON.parse(Base64.decode(data.split(".")[0]));
