import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.PinboardPermission.${id}`);

export default class PinboardPermission {
  static DELETE = -1;

  static READ_USER = 3;

  static EDIT_USER = 1;

  static RESPONSIBLE = 0;

  static getPermissionOptionText = id => {
    switch (id) {
      case this.READ_USER:
        return getText("selfCanRead");
      case this.EDIT_USER:
        return getText("selfCanEdit");
      case this.RESPONSIBLE:
        return getText("setAsOwner");
      default:
        return "";
    }
  };

  static getPermissionTip = id => {
    switch (id) {
      case this.READ_USER:
        return getText("readAsSelf");
      case this.EDIT_USER:
        return getText("editAsSelf");
      case this.RESPONSIBLE:
        return getText("ownerIntroduction");
      default:
        return "";
    }
  };

  static getPermissionText = id => {
    if (id === this.RESPONSIBLE) return getText("owner");
    return this.getPermissionOptionText(id);
  };

  static hasEditAuth = permission => [this.RESPONSIBLE, this.EDIT_USER].includes(permission);

  static canFork = pinboard =>
    this.hasEditAuth(pinboard.permission) || (pinboard.permission === this.READ_USER && pinboard.allowReadBySelfCreateFork);

  static canEditShareAll = pinboard =>
    pinboard.permission === PinboardPermission.RESPONSIBLE ||
    (pinboard.permission === PinboardPermission.EDIT_USER && pinboard.allowCanEditShareAll);
}
