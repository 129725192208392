import React from "react";

const Refresh = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="retry">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52859 3.5286L6.86192 2.19526C7.12227 1.93491 7.54438 1.93491 7.80473 2.19526C8.04505 2.43558 8.06354 2.81374 7.86019 3.07527L7.80473 3.13807L7.59276 3.34868C7.72773 3.33847 7.86354 3.33333 7.99999 3.33333C10.9455 3.33333 13.3333 5.72115 13.3333 8.66667C13.3333 11.6122 10.9455 14 7.99999 14C5.05447 14 2.66666 11.6122 2.66666 8.66667C2.66666 8.29848 2.96513 8 3.33332 8C3.70151 8 3.99999 8.29848 3.99999 8.66667C3.99999 10.8758 5.79085 12.6667 7.99999 12.6667C10.2091 12.6667 12 10.8758 12 8.66667C12 6.45753 10.2091 4.66667 7.99999 4.66667C7.87528 4.66667 7.7514 4.67235 7.62862 4.68363L7.80473 4.86193C8.06508 5.12228 8.06508 5.54439 7.80473 5.80474C7.5644 6.04506 7.18625 6.06355 6.92472 5.8602L6.86192 5.80474L5.52859 4.4714C5.28826 4.23108 5.26978 3.85293 5.47313 3.5914L5.52859 3.5286L6.86192 2.19526L5.52859 3.5286Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Refresh;
