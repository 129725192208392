export default class SmartTipMode {
  static INTELLIGENCE = 1;

  static FIELD = 2;

  static QUESTION = 3;

  static DEFAULT = this.INTELLIGENCE;

  static getText = t => {
    if (t === SmartTipMode.INTELLIGENCE) return "智能提示";
    if (t === SmartTipMode.FIELD) return "字段提示";
    if (t === SmartTipMode.QUESTION) return "问题提示";
    return "";
  };
}
