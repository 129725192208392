import React from "react";

const TrendForecast: React.FC = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="16" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4402 10.9773C23.9361 11.2204 24.1411 11.8195 23.898 12.3154L23.7177 12.6832C23.4746 13.1791 22.8755 13.384 22.3796 13.1409C21.8837 12.8978 21.6788 12.2988 21.9218 11.8029L22.1021 11.4351C22.3452 10.9392 22.9443 10.7342 23.4402 10.9773ZM22.058 13.797C22.5539 14.0401 22.7589 14.6392 22.5158 15.1351L22.1552 15.8707C21.9121 16.3666 21.313 16.5715 20.8171 16.3284C20.3212 16.0853 20.1163 15.4863 20.3593 14.9904L20.7199 14.2548C20.963 13.7589 21.5621 13.5539 22.058 13.797ZM15.8836 19.4679L17.4849 16.4431C17.781 16.5323 18.1142 16.4834 18.3792 16.282C18.8189 15.9478 18.9044 15.3204 18.5703 14.8807L18.2962 14.5201C18.2621 14.4753 18.225 14.4341 18.1853 14.3967C18.1023 14.1767 17.9418 13.9849 17.7177 13.8662C17.2296 13.6078 16.6244 13.794 16.366 14.2821L14.9345 16.986L12.8514 13.6009C12.6551 13.2818 12.2968 13.0993 11.9233 13.1279C11.5497 13.1566 11.2235 13.3917 11.0781 13.737L8.07814 20.862C7.86382 21.371 8.10271 21.9573 8.61171 22.1716C9.12072 22.386 9.70709 22.1471 9.92141 21.6381L12.167 16.3048L14.1481 19.5241C14.3362 19.8297 14.6735 20.0111 15.0321 19.9995C15.3908 19.9879 15.7157 19.785 15.8836 19.4679ZM19.625 20.25C20.6605 20.25 21.5 19.4105 21.5 18.375C21.5 17.3395 20.6605 16.5 19.625 16.5C18.5895 16.5 17.75 17.3395 17.75 18.375C17.75 19.4105 18.5895 20.25 19.625 20.25Z"
      fill="white"
    />
  </svg>
);

export default TrendForecast;
