import React from "react";

const EditAxisBtn = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25974 3.03784C9.26002 3.03756 9.2603 3.03728 9.26058 3.037C9.83337 2.46422 9.69715 1.39933 8.95633 0.658514C8.26097 -0.0368454 7.28009 -0.19953 6.68866 0.2568C6.64668 0.28256 6.60753 0.313329 6.57213 0.348718L0.439795 6.63154C0.348636 6.72494 0.297607 6.85028 0.297607 6.98079V8.96558C0.297607 9.24173 0.521465 9.46558 0.797607 9.46558H2.7753C2.91001 9.46558 3.03902 9.41123 3.13312 9.31483L9.25974 3.03784ZM0.931207 10.7328C0.581279 10.7328 0.297607 11.0165 0.297607 11.3664C0.297607 11.7163 0.58128 12 0.931207 12H11.0688C11.4187 12 11.7024 11.7163 11.7024 11.3664C11.7024 11.0165 11.4187 10.7328 11.0688 10.7328H0.931207Z"
      fill="currentColor"
    />
  </svg>
);
export default EditAxisBtn;
