export default {
  disableSearchAdviceLabel: "Search Advice",
  disableSearchAdviceTitle: "Search Advice",
  disableSearchAdviceHelp:
    "Help new users quickly understand how to search and assist existing users in quickly querying common issues, including sub-modules such as 'You can try', 'Popular searches', 'My frequent searches', 'Understanding rankings', 'Understanding trends', etc.",
  disableSearchAdviceExtra:
    "When disabled, the homepage will no longer display recommended modules such as 'You can try' and 'Popular searches'",
  disableIsWatchedLabel: "My Observation",
  disableIsWatchedTitle: "My Observation",
  disableIsWatchedHelp:
    "Users can add content they want to observe to the homepage to quickly understand the monitored measures or reports.",
  disableIsWatchedExtra: "When disabled, the homepage will no longer display observed cards and reports.",
  disableLatestSearchLabel: "Recent Searches",
  disableLatestSearchExtra: "When disabled, the homepage will no longer display the user's search history.",
  titleLabel: "Page Title",
  titleExtra: "The web page title displayed in browser tabs and shared links. If not filled, the default content will be displayed.",
  titlePlaceholder: "Smart Data Search",
  descriptionLabel: "Page Description",
  descriptionExtra: "The web page description attached to shared links. If not filled, the default content will be displayed.",
  descriptionPlaceholder: "Smart Search",
  enableWatermarkLabel: "Watermark",
  enableWatermarkExtra: "When enabled, a watermark will be generated on all global interfaces.",
  lightHomeLogoLabel: "Light Home Logo",
  darkHomeLogoLabel: "Dark Home Logo",
  lightDisableCornerLogoLabel: "Light Corner Logo",
  darkDisableCornerLogoLabel: "Dark Corner Logo",
  homeLogoLabel: "Home Logo",
  homeLogoUploadExtra: [
    "The complete logo above the homepage search box",
    "Recommended size: 200x100px, PNG format with a transparent background, file size not exceeding 500K",
  ],
  disableCornerLogoLabel: "Corner Logo",
  disableCornerLogoExtra: "When disabled, the navigation bar logo position will display as 'Home'",
  disableCornerLogoUploadExtra: [
    "The corner logo on the left side of the navigation bar and at the bottom of the page",
    "Recommended size: 100x100px, PNG format with a transparent background, file size not exceeding 500K",
  ],
  cornerLogoLabel: "Tab Logo",
  faviconLabel: "Favicon",
  cornerLogoExtra: [
    "The logo displayed on the browser tab",
    "Recommended size: 100x100px, PNG format with a transparent background, file size not exceeding 500K",
  ],
  backendLogoLabel: "Backend Logo",
  backendLogoExtra:
    "When not uploaded, the logo for workspace configuration, system settings, and audit logs will be the same as the homepage.",
  backendLogoUploadExtra: [
    "The logo for workspace configuration, system settings, and audit logs",
    "Recommended size: 200x100px, PNG format with a transparent background, file size not exceeding 500K",
  ],
  enableVoiceLabel: "Enable Voice Search",
  enableVoiceExtra: "When enabled, voice input is allowed for search queries.",
  disableCardDownloadLabel: "Download",
  disableCardDownloadExtra: "When disabled, card data cannot be downloaded, PNG images cannot be downloaded, and emails cannot be sent.",
  disableCardShareLabel: "Card Sharing",
  disableCardShareExtra: "When disabled, cards cannot be shared with others.",
  guideDividerTitle: "Beginner's Guide",
  disableFrontendGuideLabel: "Front Desk Novice Guide",
  frontendGuideExtra: "After closing, the novice guide page will no longer be displayed when the user logs in for the first time.",
  disableBackendGuideLabel: "Backstage Newcomer's Manual",
  backendGuideExtra: "After closing, the workspace settings will no longer display the newcomer manual and instructional videos.",
  help: "Help Documentation: ",
  helpExtra: "Customize the help documentation. If not filled in, users will see the default help documentation.",
  setUp: "Set Up",
  name: "Name",
  themeColor: "Theme Color",
  operation: "Settings",
  setAsDefaultBtnText: "Set as Default",
  edit: "Edit",
  delete: "Delete",
  openThemeColor: "Enable Custom Theme Color",
  closeThemeColor: "Disable Custom Theme Color",
  updateThemeColorSuccessToast: "Theme color updated successfully",
  switchThemeColorTip: "When enabled, you can customize the theme color of the charts",
  addThemeColor: "Add Theme Color",
  editThemeColor: "Edit Theme Color",
  requestErrorToast: "Operation failed. Please try again later.",
  opened: "Opened",
  closed: "Closed",
  personalizedFunction: "Personalized Functions",
  personalizedStyle: "Personalized Styles",
  cancel: "Cancel",
  closeConfirm: "Confirm Close",
  searchFunction: "Search Function",
  disableShareConfirmTitle: "All shared links will become invalid",
  formerDisableShareConfirmContent:
    "You are about to disable card sharing. After disabling, all existing shared links will become invalid. ",
  latterDisableShareConfirmContent: "Confirm disabling card sharing?",
  saveSuccessToast: "Saved successfully",
  confirm: "Confirm",
  deleteSuccessToast: "Deleted successfully",
  appearance: "Appearance",
  themeColorSetting: "Custom Theme Color Setting",
  homeDisplayModule: "Home Display Modules",
  browserTag: "Browser Tab",
  warningMessage:
    "Your account has exceeded the limit (i.e., the number of accounts exceeds the quota), and various system functions cannot be used properly. Please reduce the number of accounts of each type (including inactive, active, disabled, and dormant accounts) to match the quota.",
  themeModeLabel: "Theme Mode",
  lightModeBtnText: "Light Mode",
  darkModeBtnText: "Dark Mode",
  themeChangeSuccessToast: "Theme mode successfully modified",
  themeModeExtra: "Only affects the color theme during front-end search, workspace configuration, and system settings are not affected.",
};
