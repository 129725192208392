export default {
  guideBtn: "前往设置",
  initTitle: "请先完成以下初始化必要设置",
  urlGuideTitle: "设置网站 URL，并保证网站能成功连通",
  urlGuideDesc: "确保系统内的上传下载，以及外链的生成",
  userGuideTitle: "新建（或导入）业务用户",
  userGuideDesc: "所有需要加入工作区的用户都需要先在系统中创建为业务用户",
  adminGuideTitle: "创建工作区，并选择工作区负责人",
  adminGuideDesc: "需要先创建业务用户，你当前登录账号为系统管理员账号（无法进入工作区，也无法成为工作区负责人）",
  loginTitle: "如何设置单点登录",
  loginDesc: "当你需要将北极与你自己的系统账号打通时，需要进行以下设置",
  ssoTitle: "配置单点登录信息，并选择登录方式",
  ssoDesc: "可以同时配置多种登录方式",
  syncUserTitle: "在基础设置中设置【允许同步用户】开关",
  syncUserDesc1: "开启后，当新用户通过单点或授权登录时，系统会在同步信息时自动为其注册账户",
  syncUserDesc2: "当你希望控制用户账号时，可以将此开关关闭",
  aiTitle: "如何设置大语言模型",
  aiDesc: "当你需要使用系统中的大语言模型功能时，需要此配置",
  llmTitle: "开启大语言模型服务，选择需要开启的功能模块（支持单功能调整 prompt ）",
  llmDesc1: "北极支持多种大语言模型",
  llmDesc2: "每一个关联大语言模型的功能都支持单独开启，也支持手动调整 prompt",
  styleTitle: "如何应用品牌LOGO、颜色等个性化设置",
  logoTitle: "在个性化设置中上传品牌 LOGO、修改网页标题",
  logoDesc1: "首页的 LOGO，浏览器标签页的 LOGO 及工作区设置的 LOGO 都支持独立上传",
  logoDesc2: "网页的标题及描述也可以在【浏览器标签】这一小项中设置",
  themeTitle: "打开自定义主题色，并编辑默认主题色的色卡",
  themeDesc1: "可以分别设置浅色和深色主题下的主题色",
  themeDesc2: "开启自定义主题色后支持修改默认的主题色色卡",
  watermarkTitle: "打开网站水印",
  watermarkDesc: "支持访客水印、版权水印和图片水印三种水印方式",
};
