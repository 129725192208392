import React from "react";

const Dimension = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.11562 3.08129C7.11562 2.5947 7.51008 2.20024 7.99667 2.20024C8.48326 2.20024 8.87772 2.5947 8.87772 3.08129C8.87772 3.56788 8.48326 3.96234 7.99667 3.96234C7.51008 3.96234 7.11562 3.56788 7.11562 3.08129ZM7.99667 1.00024C6.84734 1.00024 5.91563 1.93196 5.91563 3.08129C5.91563 4.05883 6.58962 4.87894 7.49825 5.10227L7.49802 6.43472C5.74742 6.67895 4.4 8.18212 4.4 10C4.4 10.4571 4.4852 10.8943 4.64058 11.2966L3.32548 12.0393C2.9784 11.78 2.54766 11.6264 2.08105 11.6264C0.931717 11.6264 0 12.5581 0 13.7075C0 14.8568 0.931717 15.7885 2.08105 15.7885C3.23038 15.7885 4.16209 14.8568 4.16209 13.7075C4.16209 13.4637 4.12016 13.2296 4.04312 13.0122L5.25415 12.3283C5.91449 13.1063 6.89959 13.6 8 13.6C9.10014 13.6 10.085 13.1065 10.7454 12.3288L11.9551 13.0134C11.8783 13.2305 11.8366 13.4641 11.8366 13.7075C11.8366 14.8568 12.7683 15.7885 13.9176 15.7885C15.0669 15.7885 15.9987 14.8568 15.9987 13.7075C15.9987 12.5581 15.0669 11.6264 13.9176 11.6264C13.4505 11.6264 13.0193 11.7804 12.672 12.0403L11.3591 11.2973C11.5147 10.8948 11.6 10.4574 11.6 10C11.6 8.18075 10.2506 6.67669 8.49802 6.43417L8.49825 5.10149C9.40529 4.87704 10.0777 4.05769 10.0777 3.08129C10.0777 1.93196 9.146 1.00024 7.99667 1.00024ZM2.08105 12.8264C1.59446 12.8264 1.2 13.2209 1.2 13.7075C1.2 14.1941 1.59446 14.5885 2.08105 14.5885C2.56764 14.5885 2.96209 14.1941 2.96209 13.7075C2.96209 13.2209 2.56764 12.8264 2.08105 12.8264ZM13.9176 12.8264C13.431 12.8264 13.0366 13.2209 13.0366 13.7075C13.0366 14.1941 13.431 14.5885 13.9176 14.5885C14.4042 14.5885 14.7987 14.1941 14.7987 13.7075C14.7987 13.2209 14.4042 12.8264 13.9176 12.8264ZM8 7.6C6.67452 7.6 5.6 8.67452 5.6 10C5.6 11.3255 6.67452 12.4 8 12.4C9.32548 12.4 10.4 11.3255 10.4 10C10.4 8.67452 9.32548 7.6 8 7.6Z"
      fill="currentColor"
    />
  </svg>
);
export default Dimension;
