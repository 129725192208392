export default {
  confirm: "Confirm",
  manageTags: "Manage tags",
  clear: "Clear",
  noTagTip: "You currently have no tags, please click 'Manage tags' in the upper right corner to create tags.",
  preQuitTip: "If you don't need to set tags, click '",
  afterQuitTip: "' button to complete the setup.",
  save: "Save changes",
  addCategory: "Add category",
  needCategoryName: "Category name is not filled in",
  cancel: "Cancel",
  categoryName: "Category name",
  delete: "Delete",
  editCategory: "Edit category",
  addTag: "New tag",
  editTag: "Edit tag",
  noTagName: "Tag name is not filled in",
  tagCategory: "Tag category",
  tagName: "Tag name",
};
