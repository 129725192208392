export default class WorkSpaceConfigFormName {
  static FIELD = "field";

  static SQL = "sql";

  static NAMES = "names";

  static SYNONYM = "synonym";

  // 成员配置相关
  static NAME = "name";

  static DESC = "desc";

  static AUTO_ADD_MEMBER = "auto_add_member";

  static ACTIONS = "actions";

  static DISABLE_DOWNLOAD = "disableDownload";

  static actionKeys = [this.DISABLE_DOWNLOAD];
}
