import React from "react";
const ConfirmError: React.FC = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08018 2.26411C5.61314 1.41148 4.70743 0.833344 3.66669 0.833344C2.1479 0.833344 0.916687 2.06456 0.916687 3.58334C0.916687 4.75378 1.6479 5.75344 2.67843 6.15043C2.13605 7.32092 1.83335 8.62511 1.83335 10C1.83335 15.0626 5.93741 19.1667 11 19.1667C16.0626 19.1667 20.1667 15.0626 20.1667 10C20.1667 8.62511 19.864 7.32092 19.3216 6.15043C20.3521 5.75344 21.0834 4.75378 21.0834 3.58334C21.0834 2.06456 19.8521 0.833344 18.3334 0.833344C17.2926 0.833344 16.3869 1.41148 15.9199 2.26411C14.4984 1.35822 12.8105 0.833344 11 0.833344C9.18953 0.833344 7.50163 1.35822 6.08018 2.26411ZM13.9399 7.06016C14.2979 7.41814 14.2979 7.99854 13.9399 8.35652L12.2964 10L13.9399 11.6435C14.2979 12.0015 14.2979 12.5819 13.9399 12.9399C13.5819 13.2978 13.0015 13.2978 12.6435 12.9399L11 11.2964L9.35654 12.9399C8.99856 13.2978 8.41815 13.2978 8.06017 12.9399C7.70219 12.5819 7.70219 12.0015 8.06017 11.6435L9.70366 10L8.06017 8.35652C7.70219 7.99854 7.70219 7.41814 8.06017 7.06016C8.41815 6.70218 8.99856 6.70218 9.35654 7.06016L11 8.70365L12.6435 7.06016C13.0015 6.70218 13.5819 6.70218 13.9399 7.06016Z"
        fill="var(--datarc-color-danger)"
      />
    </svg>
  );
};

export default ConfirmError;
