import React from "react";

const ConfirmSuccess: React.FC = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08018 2.2641C5.61314 1.41147 4.70743 0.833332 3.66669 0.833332C2.1479 0.833332 0.916687 2.06455 0.916687 3.58333C0.916687 4.75377 1.6479 5.75343 2.67843 6.15042C2.13605 7.32091 1.83335 8.6251 1.83335 10C1.83335 15.0626 5.93741 19.1667 11 19.1667C16.0626 19.1667 20.1667 15.0626 20.1667 10C20.1667 8.6251 19.864 7.32091 19.3216 6.15042C20.3521 5.75343 21.0834 4.75377 21.0834 3.58333C21.0834 2.06455 19.8521 0.833332 18.3334 0.833332C17.2926 0.833332 16.3869 1.41147 15.9199 2.2641C14.4984 1.35821 12.8105 0.833332 11 0.833332C9.18953 0.833332 7.50163 1.35821 6.08018 2.2641ZM14.3982 8.81485C14.7562 8.45687 14.7562 7.87646 14.3982 7.51848C14.0402 7.1605 13.4598 7.1605 13.1018 7.51848L10.0834 10.537L8.8982 9.35182C8.54022 8.99384 7.95982 8.99384 7.60184 9.35182C7.24386 9.7098 7.24386 10.2902 7.60184 10.6482L9.43517 12.4815C9.60708 12.6534 9.84024 12.75 10.0834 12.75C10.3265 12.75 10.5596 12.6534 10.7315 12.4815L14.3982 8.81485Z"
        fill="var(--datarc-color-success)"
      />
    </svg>
  );
};

export default ConfirmSuccess;
