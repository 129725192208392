export default {
  lineChart: "折线图",
  barChart: "柱状图",
  pieChart: "饼图",
  sankeyChart: "桑基图",
  funnelChart: "漏斗图",
  stackChart: "堆叠图",
  percentStackChart: "百分比堆叠柱状图",
  scatterChart: "散点图",
  valueScatterChart: "数值散点图",
  heatMap: "热力图",
  table: "表格",
  map: "地图",
  stackBarChart: "堆叠柱状图",
  bubbleChart: "气泡图",
  wordCloud: "词云",
  stackAreaChart: "堆叠面积图",
  mixedBarLineChart: "柱折混合图",
  horizontalBarChart: "条形图",
  mixedStackLineChart: "堆叠折线混合图",
  paretoChart: "帕累托图",
  pivotTable: "透视表",
  animationBar: "动态图",
  needOneDimensionOneMeasure: "你的搜索需要 >= 1 个维度，>= 1 个指标",
  needOneDimensionTwoMeasures: "你的搜索需要 >= 1 个维度，>= 2 个指标",
  needTwoDimensionsOneMeasure: "你的搜索需要 >= 2 个维度，>= 1 个指标",
  needTwoDimensionsTwoMeasures: "你的搜索需要 >= 2 个维度，>= 2 个指标",
  needThreeItems: "你的搜索需要 >= 2 个维度，>= 1 个指标或 >= 1 个维度，>= 2 个指标",
  needOneAreaDimensionOneMeasure: "你的搜索需要 >= 1 个地理类型维度，>= 1 个指标",
  stackAreaChartHoverText: "你的搜索需要 > 1个维度，1 个指标",
  stackPercentChartHoverText: "你的搜索需要 >= 2 个维度，>= 1 个指标， 至少 1 个指标是全正/全负",
  pieLikedChartHoverText: "你的搜索需要 >= 1 个维度，>= 1 个指标，至少 1 个指标是全正/全负",
  paretoChartHoverText: "你的搜索需要 >= 1 个维度，1 个指标",
  animationBarChartHoverText: "你的搜索需要 1 个时间维度，>=1 个维度，>=1 个指标",
  YOY: "同比",
  RING: "环比",
  boxPlot: "箱型图",
  bar3D: "3D柱状图",
  histogram: "直方图",
};
