import * as React from "react";
import { SVGProps } from "react";
const Enter = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0858 0C9.5335 0 9.08579 0.447715 9.08579 1C9.08579 1.55228 9.5335 2 10.0858 2H15.5858C16.1381 2 16.5858 2.44772 16.5858 3V7C16.5858 7.55228 16.1381 8 15.5858 8H3L5.29289 5.70711C5.68342 5.31658 5.68342 4.68342 5.29289 4.29289C4.90237 3.90237 4.2692 3.90237 3.87868 4.29289L0.585787 7.58579C-0.195263 8.36684 -0.195261 9.63317 0.585787 10.4142L3.87868 13.7071C4.2692 14.0976 4.90237 14.0976 5.29289 13.7071C5.68342 13.3166 5.68342 12.6834 5.29289 12.2929L3 10H15.5858C17.2426 10 18.5858 8.65685 18.5858 7V3C18.5858 1.34315 17.2426 0 15.5858 0H10.0858Z"
      fill="currentColor"
    />
  </svg>
);
export default Enter;
