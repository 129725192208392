import { getLocaleText } from "@/utils/locale";

const getTextLocale = id => getLocaleText(`components.Constants.CurrencyCode.${id}`);

export default class CurrencyCode {
  static None = "null";

  static CNY = "CNY";

  static USD = "USD";

  static EUR = "EUR";

  static JPY = "JPY";

  static GBP = "GBP";

  static CHF = "CHF";

  static CNY_TEXT = "人民币";

  static USD_TEXT = "美元";

  static EUR_TEXT = "欧元";

  static JPY_TEXT = "日元";

  static GBP_TEXT = "英镑";

  static CHF_TEXT = "瑞士法郎";

  static CNY_ICON = "¥";

  static USD_ICON = "$";

  static EUR_ICON = "€";

  static JPY_ICON = "¥";

  static GBP_ICON = "£";

  static CHF_ICON = "fr.";

  static choices = [
    CurrencyCode.None,
    CurrencyCode.CNY,
    CurrencyCode.USD,
    CurrencyCode.EUR,
    CurrencyCode.JPY,
    CurrencyCode.GBP,
    CurrencyCode.CHF,
  ];

  static iconChoices = [CurrencyCode.CNY_ICON, CurrencyCode.USD_ICON, CurrencyCode.EUR_ICON, CurrencyCode.GBP_ICON, CurrencyCode.CHF_ICON];

  static getMoneyUnitOptions = () => {
    return [
      { label: getTextLocale("None"), value: CurrencyCode.None },
      { label: `${CurrencyCode.CNY_ICON} ${getTextLocale("CNY")}`, value: CurrencyCode.CNY },
      { label: `${CurrencyCode.USD_ICON} ${getTextLocale("USD")}`, value: CurrencyCode.USD },
      { label: `${CurrencyCode.EUR_ICON} ${getTextLocale("EUR")}`, value: CurrencyCode.EUR },
      { label: `${CurrencyCode.GBP_ICON} ${getTextLocale("GBP")}`, value: CurrencyCode.GBP },
      { label: `${CurrencyCode.JPY_ICON} ${getTextLocale("JPY")}`, value: CurrencyCode.JPY },
      { label: `${CurrencyCode.CHF_ICON} ${getTextLocale("CHF")}`, value: CurrencyCode.CHF },
    ];
  };

  static getText = currencyType => {
    switch (currencyType) {
      case CurrencyCode.CNY:
        return getTextLocale("CNY");
      case CurrencyCode.USD:
        return getTextLocale("USD");
      case CurrencyCode.EUR:
        return getTextLocale("EUR");
      case CurrencyCode.JPY:
        return getTextLocale("JPY");
      case CurrencyCode.GBP:
        return getTextLocale("GBP");
      case CurrencyCode.CHF:
        return getTextLocale("CHF");
      default:
        return getTextLocale("default");
    }
  };

  static getIcon = currencyType => {
    switch (currencyType) {
      case CurrencyCode.CNY:
        return CurrencyCode.CNY_ICON;
      case CurrencyCode.USD:
        return CurrencyCode.USD_ICON;
      case CurrencyCode.EUR:
        return CurrencyCode.EUR_ICON;
      case CurrencyCode.JPY:
        return CurrencyCode.JPY_ICON;
      case CurrencyCode.GBP:
        return CurrencyCode.GBP_ICON;
      case CurrencyCode.CHF:
        return CurrencyCode.CHF_ICON;
      default:
        return "";
    }
  };

  static getChoicesCurrencyCode = currencyCode => (CurrencyCode.choices.includes(currencyCode) ? currencyCode : CurrencyCode.None);

  static getOptions = () =>
    CurrencyCode.choices.map(item => ({ value: item, label: `${CurrencyCode.getIcon(item)} ${CurrencyCode.getText(item)}` }));
}
