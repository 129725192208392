export default class ModelService {
  static HTTP = "http";

  static HTTP_HEADER = "httpHeader";

  // 大模型服务关联功能模块
  static SEARCH = "search"; // 辅助搜索

  static EXPLAIN = "explain"; // 数据解释

  static CORRECTION = "correction"; // SQL 报错

  static FIELD = "field"; // AI 字段类型配置

  static KEYWORD = "keyword"; // AI 关键词配置

  static SYNONYM = "synonym"; // AI 别名配置

  static CHAT = "chat"; // 对话

  // HTTP 请求体和返回体
  static HTTP_REQUEST_FOR_SEARCH = "httpRequestForSearch"; // 数据搜索请求体

  static HTTP_RESPONSE_FOR_SEARCH = "httpResponseForSearch"; // 数据搜索响应体

  static HTTP_REQUEST_FOR_FIELD = "httpRequestForField"; // 字段类型请求体

  static HTTP_RESPONSE_FOR_FIELD = "httpResponseForField"; // 字段类型响应体

  static HTTP_REQUEST_FOR_KEYWORD = "httpRequestForKeyword"; // 关键词请求体

  static HTTP_RESPONSE_FOR_KEYWORD = "httpResponseForKeyword"; // 关键词响应体

  static HTTP_REQUEST_FOR_SYNONYM = "httpRequestForSynonym"; // 同义词请求体

  static HTTP_RESPONSE_FOR_SYNONYM = "httpResponseForSynonym"; // 同义词响应体

  static HTTP_REQUEST_FOR_EXPLAIN = "httpRequestForExplain"; // 数据解释请求体

  static HTTP_RESPONSE_FOR_EXPLAIN = "httpResponseForExplain"; // 数据解释响应体

  static HTTP_REQUEST_FOR_CORRECTION = "httpRequestForCorrection"; // SQL 纠错请求体

  static HTTP_RESPONSE_FOR_CORRECTION = "httpResponseForCorrection"; // SQL 纠错响应体

  static HTTP_REQUEST_FOR_CHAT = "httpRequestForChat"; // 对话请求体

  static HTTP_RESPONSE_FOR_CHAT = "httpResponseForChat"; // 对话响应体

  static PROMPT_REQ_MAPPING = {
    [this.SEARCH]: this.HTTP_REQUEST_FOR_SEARCH,
    [this.FIELD]: this.HTTP_REQUEST_FOR_FIELD,
    [this.KEYWORD]: this.HTTP_REQUEST_FOR_KEYWORD,
    [this.SYNONYM]: this.HTTP_REQUEST_FOR_SYNONYM,
    [this.EXPLAIN]: this.HTTP_REQUEST_FOR_EXPLAIN,
    [this.CORRECTION]: this.HTTP_REQUEST_FOR_CORRECTION,
    [this.CHAT]: this.HTTP_REQUEST_FOR_CHAT,
  };

  static PROMPT_RES_MAPPING = {
    [this.SEARCH]: this.HTTP_RESPONSE_FOR_SEARCH,
    [this.FIELD]: this.HTTP_RESPONSE_FOR_FIELD,
    [this.KEYWORD]: this.HTTP_RESPONSE_FOR_KEYWORD,
    [this.SYNONYM]: this.HTTP_RESPONSE_FOR_SYNONYM,
    [this.EXPLAIN]: this.HTTP_RESPONSE_FOR_EXPLAIN,
    [this.CORRECTION]: this.HTTP_RESPONSE_FOR_CORRECTION,
    [this.CHAT]: this.HTTP_RESPONSE_FOR_CHAT,
  };

  static codeEditPartKeys = [
    this.HTTP_HEADER,
    this.HTTP_REQUEST_FOR_SEARCH,
    this.HTTP_REQUEST_FOR_FIELD,
    this.HTTP_REQUEST_FOR_KEYWORD,
    this.HTTP_REQUEST_FOR_SYNONYM,
    this.HTTP_REQUEST_FOR_EXPLAIN,
    this.HTTP_REQUEST_FOR_CORRECTION,
    this.HTTP_REQUEST_FOR_CHAT,
    this.HTTP_RESPONSE_FOR_SEARCH,
    this.HTTP_RESPONSE_FOR_FIELD,
    this.HTTP_RESPONSE_FOR_KEYWORD,
    this.HTTP_RESPONSE_FOR_SYNONYM,
    this.HTTP_RESPONSE_FOR_EXPLAIN,
    this.HTTP_RESPONSE_FOR_CORRECTION,
    this.HTTP_RESPONSE_FOR_CHAT,
  ];

  static promptService = [this.SEARCH, this.EXPLAIN, this.CORRECTION, this.FIELD, this.KEYWORD, this.SYNONYM, this.CHAT];
}
