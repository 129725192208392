export default {
  deleteMyObservationConfirmText: "是否删除：【首页-我的观察】正在使用",
  pinboard: "报告",
  delete: "删除",
  deleteConfirmText: "是否删除",
  deleteContentInRecycleBinTip: "删除内容将进入回收站，30天后自动彻底删除。",
  deleteCanNotUseTip: "删除后其他人无法使用此报告。",
  remove: "移除",
  removeConfirmText: "是否移除",
  removeReapplyAuthTip: "移除后，需重新申请权限。",
  removeContentInvisibleTip: "移除后，该内容在本账户中将不可见。",
  confirm: "确认",
  cancel: "取消",
};
