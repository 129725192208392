export default {
  title: "User has assets that need to be transferred",
  content1: "You can [cancel] this operation and contact the workspace owner to transfer assets",
  content2: "You can also click [Confirm] to transfer assets to the corresponding workspace owner.",
  content3: "You can also click [Delete Assets] to delete all data assets of the user.",
  content4: "Asset type: report, data alert",
  deletePrefix: "Confirm deletion",
  deleteSuffix: "All assets",
  deleteContentPrefix: "The delete operation cannot be undone, after deletion it will automatically",
  deleteContentSuffix: "The user",
  deleteButtonText: "Delete asset",
  cancelButtonText: "Cancel",
  confirmButtonText: "Confirm",
};
