export default class RuleNodeType {
  static START = "start";

  static CONDITION = "condition";

  static ACTION = "action";

  static OUTPUT = "output";

  static RULES = "rules";
}
