export default {
  confirmToDeleteTheModel: "Are you sure to delete the model?",
  formerConfirmToDeleteTheModel: "Confirm to delete model [",
  latterConfirmToDeleteTheModel: "]？",
  deletedModelCannotBeRestored: "Deleted models are not recoverable.",
  confirm: "OK",
  cancel: "Cancel",
  selectFactTable: "Select Fact Table",
  exitAndDeleteModel: "Exit and delete the model",
  goBackToThePreviousStep: "Go back to the previous step",
  pleaseSelect1TableAsTheFactTable: "Please select 1 table as fact table",
  unselectedTables: "Unselected tables will default to dimension tables",
};
