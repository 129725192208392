import React from "react";

const Attribution = props => (
  <svg color="#4759FD" {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.98346 8.01651C5.98346 8.164 5.96223 8.30654 5.92266 8.44123L8.5598 9.96378C8.38761 10.2502 8.25018 10.5599 8.15333 10.887L5.33018 9.25703C5.0882 9.42331 4.79513 9.52063 4.47934 9.52063C3.64864 9.52063 2.97522 8.84721 2.97522 8.01651C2.97522 7.18581 3.64864 6.51239 4.47934 6.51239C5.31004 6.51239 5.98346 7.18581 5.98346 8.01651ZM11.4984 16.0075C11.6626 16.0279 11.83 16.0385 11.9998 16.0385C12.1696 16.0385 12.3369 16.028 12.5011 16.0075V18.6309C13.0854 18.8374 13.5039 19.3945 13.5039 20.0495C13.5039 20.8802 12.8305 21.5536 11.9998 21.5536C11.1691 21.5536 10.4957 20.8802 10.4957 20.0495C10.4957 19.3946 10.9142 18.8375 11.4984 18.631V16.0075ZM15.8464 10.887C15.7495 10.5599 15.6121 10.2502 15.4399 9.96384L18.0772 8.44123C18.0376 8.30654 18.0163 8.16401 18.0163 8.01651C18.0163 7.18581 18.6898 6.51239 19.5205 6.51239C20.3512 6.51239 21.0246 7.18581 21.0246 8.01651C21.0246 8.84721 20.3512 9.52063 19.5205 9.52063C19.2047 9.52063 18.9116 9.42331 18.6696 9.25703L15.8464 10.887Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5013 6.95784C13.5061 6.72991 14.2563 5.83132 14.2563 4.75752C14.2563 3.51147 13.2461 2.50134 12.0001 2.50134C10.754 2.50134 9.7439 3.51147 9.7439 4.75752C9.7439 5.8312 10.4939 6.72971 11.4985 6.95776V9.06076C10.0758 9.29947 8.9917 10.5368 8.9917 12.0274C8.9917 12.6771 9.19766 13.2787 9.54785 13.7704L7.43531 15.883C7.08751 15.6638 6.67566 15.537 6.23421 15.537C4.98815 15.537 3.97803 16.5472 3.97803 17.7932C3.97803 19.0393 4.98815 20.0494 6.23421 20.0494C7.48026 20.0494 8.49039 19.0393 8.49039 17.7932C8.49039 17.3517 8.36357 16.9398 8.14438 16.592L10.2569 14.4795C10.7487 14.8297 11.3502 15.0356 11.9999 15.0356C12.6496 15.0356 13.2512 14.8297 13.743 14.4795L15.8555 16.592C15.6363 16.9399 15.5095 17.3517 15.5095 17.7932C15.5095 19.0393 16.5196 20.0494 17.7657 20.0494C19.0118 20.0494 20.0219 19.0393 20.0219 17.7932C20.0219 16.5472 19.0118 15.537 17.7657 15.537C17.3242 15.537 16.9124 15.6638 16.5646 15.883L14.452 13.7705C14.8022 13.2787 15.0082 12.6771 15.0082 12.0274C15.0082 10.5368 13.9241 9.29943 12.5013 9.06075V6.95784Z"
      fill="currentColor"
    />
  </svg>
);

export default Attribution;
