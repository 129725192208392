export default {
  skipExceptionLineText: "跳过异常行",
  onlySkipLineText: "只跳过该行",
  stopImportText: "终止导入",
  stopImportExtraText: "本次导入失败，不会创建表或更新任何内容",
  exceptionDataText: "异常数据设置为空值",
  exceptionDataExtraText: "无法转换的内容设置为null",
  replaceAllTableLabel: "替换全表",
  addToFooterLabel: "新增至表尾",
  updateFileTitle: "更新文件",
  uploadFilesTitle: "上传文件",
  cancelBtn: "取消",
  nextStepBtn: "下一步",
  updateMethodLabel: "更新方式",
  exceptionHandlingLabel: "异常处理方式",
  selectFileText: "选择文件",
  selectFileExtraText1: "支持大小不超过 50M 的 .csv .xls .xlsx 文件",
  selectFileExtraText2: ".csv 文件仅支持 utf-8，gbk，ascii，utf-16 编码",
  confirmUploadBtn: "确认上传",
  updateDataOptionsTitle: "更新数据表选项",
  selectSheetLabel: "请选择需要上传的 sheet 页",
  delimiterSelectLabel: "分隔符选择",
  skipLineLabel: "上传选项",
  delimiterSelectHelp: "仅对 .csv 文件生效",
};
