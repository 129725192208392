export default {
  measure: "123",
  dimension: "Abc",
  timeDimension: "Time",
  addFilter: "Add filter",
  noResultFound: "No result found, please update keyword",
  noSelectPrompt: "Select cards you want to apply filter to",
  selectionNotSupported: "The card with the grayed-out checkbox does not support the application of this filter.",
  noCardApplied: "No card applied",
  cancel: "Cancel",
  apply: "Apply",
  setDefault: "Set default value",
  setFilter: "Set Filters",
  chooseCard: "Select cards to apply",
  viewCard: "Applied Cards",
  to: " to ",
  toToday: " till now",
  timeRange: "Time Range",
  batchAdd: "Batch input",
  back: "Return",
  showTags: "Show all filters",
  hideTags: "Hide filters",
  all: "All",
  allFilter: "All filters",
  tip: "* Display only data that meets any criteria, please wait",
  multiSelect: "Multiple selection",
  startTime: "Start Time",
  endTime: "End Time",
  noPermission: "No permission to access this data",
  is: " is ",
  isNot: " is not ",
  contain: " contains ",
  notContain: " exclude ",
  with: " start ",
  start: " with ",
  end: " end ",
  isNull: " is null",
  isNotNull: " is not null",
};
