export default {
  noAuthPrompt: "You do not have access to some keywords, please contact workspace admin for access",
  searchSuggestions: "Search suggestion",
  suggestLabel: "Try below",
  relevantResults: "Related searches",
  forecasting: "Forecasting data",
  analysis: "Analysis",
  relevantResultsPrompt: "Below result is related to your search",
  feedbackBtnText: "Feedback",
  noDataPromptFormer: "No result found",
  noDataPromptLatter: "Click the Feedback button and submit your request, or check the search suggestions below for inspiration",
  noDataPrompt: "No result found, try suggested keywords or search suggestions",
  feedbackTourBtn:
    "Didn't find the data you were looking for? Click this button to provide feedback on the dimensions and indicators you think this phrase should match.",
  checkBtn: "View Now",
  skipBtn: "Ok",
};
