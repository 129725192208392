import React from "react";

const Task = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 14H2.5475C2.2485 14 2 13.745 2 13.423V2.577C2 2.255 2.2485 2 2.5475 2H11.4525C11.7515 2 12 2.255 12 2.577V5.428C12 5.56061 12.0527 5.68779 12.1464 5.78155C12.2402 5.87532 12.3674 5.928 12.5 5.928C12.6326 5.928 12.7598 5.87532 12.8536 5.78155C12.9473 5.68779 13 5.56061 13 5.428V2.577C13 1.709 12.31 1 11.4525 1H2.5475C1.69 1 1 1.709 1 2.577V13.423C1 14.291 1.69 15 2.5475 15H7C7.13261 15 7.25979 14.9473 7.35355 14.8536C7.44732 14.7598 7.5 14.6326 7.5 14.5C7.5 14.3674 7.44732 14.2402 7.35355 14.1464C7.25979 14.0527 7.13261 14 7 14Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M11.5 7C10.4391 7 9.42172 7.42143 8.67157 8.17157C7.92143 8.92172 7.5 9.93913 7.5 11C7.5 12.0609 7.92143 13.0783 8.67157 13.8284C9.42172 14.5786 10.4391 15 11.5 15C12.5609 15 13.5783 14.5786 14.3284 13.8284C15.0786 13.0783 15.5 12.0609 15.5 11C15.5 9.93913 15.0786 8.92172 14.3284 8.17157C13.5783 7.42143 12.5609 7 11.5 7ZM11.5 14C11.106 14 10.7159 13.9224 10.3519 13.7716C9.98797 13.6209 9.65726 13.3999 9.37868 13.1213C9.1001 12.8427 8.87913 12.512 8.72836 12.1481C8.5776 11.7841 8.5 11.394 8.5 11C8.5 10.606 8.5776 10.2159 8.72836 9.85195C8.87913 9.48797 9.1001 9.15725 9.37868 8.87868C9.65726 8.6001 9.98797 8.37913 10.3519 8.22836C10.7159 8.0776 11.106 8 11.5 8C12.2956 8 13.0587 8.31607 13.6213 8.87868C14.1839 9.44129 14.5 10.2044 14.5 11C14.5 11.7956 14.1839 12.5587 13.6213 13.1213C13.0587 13.6839 12.2956 14 11.5 14Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M12.9915 10.75H11.75V9.75C11.75 9.61739 11.6973 9.49021 11.6035 9.39645C11.5098 9.30268 11.3826 9.25 11.25 9.25C11.1174 9.25 10.9902 9.30268 10.8964 9.39645C10.8027 9.49021 10.75 9.61739 10.75 9.75V11.25C10.75 11.3826 10.8027 11.5098 10.8964 11.6036C10.9902 11.6973 11.1174 11.75 11.25 11.75H12.9915C13.1241 11.75 13.2513 11.6973 13.345 11.6036C13.4388 11.5098 13.4915 11.3826 13.4915 11.25C13.4915 11.1174 13.4388 10.9902 13.345 10.8964C13.2513 10.8027 13.1241 10.75 12.9915 10.75ZM10 5C10 4.86739 9.94732 4.74021 9.85355 4.64645C9.75978 4.55268 9.6326 4.5 9.5 4.5H4.5C4.36739 4.5 4.24021 4.55268 4.14645 4.64645C4.05268 4.74021 4 4.86739 4 5C4 5.13261 4.05268 5.25979 4.14645 5.35355C4.24021 5.44732 4.36739 5.5 4.5 5.5H9.5C9.6326 5.5 9.75978 5.44732 9.85355 5.35355C9.94732 5.25979 10 5.13261 10 5ZM4.5 8.5C4.36739 8.5 4.24021 8.55268 4.14645 8.64645C4.05268 8.74021 4 8.86739 4 9C4 9.13261 4.05268 9.25979 4.14645 9.35355C4.24021 9.44732 4.36739 9.5 4.5 9.5H5.7545C5.88711 9.5 6.01428 9.44732 6.10805 9.35355C6.20182 9.25979 6.2545 9.13261 6.2545 9C6.2545 8.86739 6.20182 8.74021 6.10805 8.64645C6.01428 8.55268 5.88711 8.5 5.7545 8.5H4.5Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default Task;
