import React from "react";

const MobileFilterItemDelete = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3501 9.99906C17.3501 14.0584 14.0593 17.3491 10 17.3491C5.94072 17.3491 2.65 14.0584 2.65 9.99906C2.65 5.93975 5.94072 2.64902 10 2.64902C14.0593 2.64902 17.3501 5.93975 17.3501 9.99906Z"
      stroke="currentColor"
      strokeOpacity="0.45"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.80029 10H13.2003"
      stroke="currentColor"
      strokeOpacity="0.45"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MobileFilterItemDelete;
