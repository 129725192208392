export default {
  dataType: "Data type",
  dataTypeSelectPlaceholder: "Please select the data type",
  dataTypeErrMsg: "Please select the data type",
  format: "Display format",
  formatSelectPlaceholder: "Please select the display format",
  formatErrMsg: "Please select the display format",
  keyword: "Keywords",
  keywordInputPlaceholder: "Please enter keywords",
  keywordErrMsg1: "Please enter a keyword",
  keywordErrMsg2: "Keyword cannot be empty",
  alias: "Alias",
  aliasTip: "Alias settings can increase the hit probability of keywords, and multiple aliases are separated by commas",
  aliasInputPlaceholder: "Please enter an alias",
  aliasErrMsg: "Please enter an alias",
  measureType: "Measure type",
  description: "Description",
  descriptionPlaceholderMiddleText: " description, adding a description can give users a better understanding of the purpose of the ",
  descriptionPlaceholderLatterText: "",
  descriptionFormerErrMsg: "Please enter",
  descriptionLatterErrMsg: "Description",
  indicatorType: "Numeric type",
  positive: "Positive measures",
  negative: "Negative measures",
  indicatorTypeErrMsg: "Please select a value type",
  timeUnit: "Default time unit",
  timeUnitTip: "Default units for time dimension aggregation, such as day, week, month, etc.",
  timeUnitSelectPlaceholder: "Please select the default time unit",
  timeUnitErrMsg: "Please select the default time unit",
  minTimeUnit: "Minimum time unit",
  minTimeUnitTip: "The smallest unit of time dimension aggregation, such as day, week, month, etc.",
  minTimeUnitSelectPlaceholder: "Please select the minimum time unit",
  minTimeUnitErrMsg: "Please select the minimum time unit",
  timeRange: "Default query time range",
  timeRangeTip:
    "The default query time range when searching for this time dimension. If not set, it will be displayed according to the range in the search settings",
  timeRangeInputPlaceholder: "For example the first 30 days",
  timeRangeErrMsg: "Please enter the default query time range",
  autoGroupTip: "When multiple search terms within this dimension are entered, the search results will be displayed in grouped format.",
  showSubDimensions: "Automatically display sub-dimension cards",
  showSubDimensionsTip:
    "When the parent dimension is searched after opening, the related chart cards of the child dimension will be automatically displayed",
  searchContentTip: "After opening, the non-pure numerical content of the column will be pushed to the system as the dimension value.",
  customKeywordLimit: "Limit on the number of searchable dimension values",
  customKeywordLimitTip:
    "After opening, the number of dimension values that can be directly retrieved by the algorithm will be limited. The total number is less than 100,000",
};
