export default {
  copyLinkSuccessToast: "链接已复制",
  copyLinkFailToast: "链接复制失败，请稍后再试",
  linkShareOpenTip: "被分享用户登录后访问链接即可查看卡片内容",
  linkShareCloseTip: "开启后可复制卡片链接分享给其他用户",
  copyLink: "复制链接",
  shareLink: "分享链接",
  openLinkShare: "开启链接分享",
  already: "已",
  not: "未",
  creatingCSVFile: "正在生成CSV文件",
  createSuccessText: "生成完成",
  createFailText: "生成失败",
  cancelCreateCSVFileConfirmText: "确定取消生成：CSV文件？",
};
