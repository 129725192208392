export default {
  furtherExploration: "Explore More",
  negative: "Negative Correlation",
  anomalyAnalysis: "Anomaly Analysis",
  separator: ": ",
  contrast: "Comparison",
  exploreBtnText: "Explore",
  keyDimension: "Key Dimension",
  mobileKeyDimension: "Dimension",
  disappearingDimension: "Disappeared Dimension",
  mobileDisappearingDimension: "Disappeared",
  newDimension: "New Dimension",
  mobileNewDimension: "New",
  negativeMark: "Negative Correlation",
  mobileNegativeMark: "Negative",
  keyDimensionUpTitle: "Fastest Decline",
  keyDimensionDownTitle: "Fastest Growth",
  disappearingDimensionTitle: "Dropped To 0 In This Period",
  newDimensionTitle: "New Additions In This Period",
  negativeMarkTitle: "Negatively Correlated Indicators",
  anomalyData: "Anomaly Data",
  mobileAnomalyData: "Anomaly",
  dimensionName: "Dimension name",
  dimensionValue: "Dimension value",
  diff: " Variation",
  impactOnChange: "Impact on overall change",
  impactOnChangeTip:
    "The percentage of the current dimension's measure change to the total change in the current period. The calculation method is (Current dimension A {measure} - Previous dimension A {measure}) ÷ (Current total {measure} - Previous total {measure}) × 100%",
  previousPeriod: "Previous Period",
  thisPeriod: "Current Period",
};
