import { Tag as AntdTag } from "antd";
import { CheckableTagProps } from "antd/lib/tag";
import { CloseCircleFilled } from "@/components/SVGIcons";

import styles from "./index.module.scss";

const TokenTypes: string[] = ["default", "fuzzy", "mixed"];

interface TokenProps extends CheckableTagProps {
  type?: typeof TokenTypes[number];
  disabled?: boolean;
  color?: string;
  bottomColor?: string; // 底下的边框和阴影的颜色
  activeClassName?: string;
  onClose?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Token: React.FC<TokenProps> = ({
  className,
  activeClassName,
  color,
  bottomColor,
  style,
  checked,
  children,
  disabled = false,
  onClose = e => e,
  type = TokenTypes[0],
  ...rest
}) => {
  let tagClassName = `${styles.a} ${styles.b} ${styles.tag}`;
  const tagStyle = { backgroundColor: color, borderColor: color, ...style };
  let showBottomBorder = false;
  let bottomBorderColor;

  if (disabled) tagClassName += ` ${styles.disabled}`;
  if (className) tagClassName += ` ${className}`;

  // fuzzy 类型
  if (type === TokenTypes[1]) {
    // 选中状态
    if (checked && !disabled) {
      // 如果传了 activeClassName，则认为自定义选中样式，否则按组件默认样式设置，这时 需要传 activeBoxShadowColor 来设置 boxShadow 颜色
      if (activeClassName) tagClassName += ` ${activeClassName}`;
      else tagStyle.boxShadow = `0 2px 0 ${bottomColor}`;
    } else {
      showBottomBorder = true;
      bottomBorderColor = bottomColor;
    }
  }

  // mixed 类型 Token 背景和边框都透明
  if (type === TokenTypes[2]) {
    showBottomBorder = true;
    tagStyle.backgroundColor = "transparent";
    tagStyle.borderColor = "transparent";
  }

  return (
    <AntdTag.CheckableTag checked={checked} style={tagStyle} className={tagClassName} {...rest}>
      <span className={styles.text}>{children}</span>
      <div className={styles.tagClose} onClick={onClose}>
        <CloseCircleFilled />
      </div>
      {showBottomBorder && <div style={{ borderColor: bottomBorderColor }} className={styles.bottomBorder} />}
    </AntdTag.CheckableTag>
  );
};

export default Token;
