export default {
  30101: "维度关键词词典中未找到该关键词",
  30102: "该关键词已有同义词，请在表格中搜索后直接编辑",
  30103: "无有效同义词",
  30201: "同义词不存在",
  30301: "文件导入数据出错",
  30401: "未知的网络错误",
  30501: "同义词下载链接无效",
  30502: "该工作区无效",
  30503: "该链接过期",
};
