export default class KeyCode {
  static TAB = 9;

  static ENTER = 13;

  static ESC = 27;

  static ARROW_LEFT = 37;

  static ARROW_UP = 38;

  static ARROW_RIGHT = 39;

  static ARROW_DOWN = 40;
}
