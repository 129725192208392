import { getLocaleText } from "@/utils/locale";
import Str from "./Str";

const getLocalLanguageText = id => getLocaleText(`components.Constants.CardToggle.${id}`);

export default class CardToggle {
  static LINE = Str.LINE;

  static BAR = Str.BAR;

  static PIE = Str.PIE;

  static STACK = Str.STACK;

  static TABLE = Str.TABLE;

  static MAP = Str.MAP;

  static SCATTER = Str.SCATTER;

  static VALUE_SCATTER = Str.VALUE_SCATTER;

  static HEATMAP = Str.HEATMAP;

  static SANKEY = Str.SANKEY;

  static FUNNEL = Str.FUNNEL;

  static chartToggles = [
    CardToggle.LINE,
    CardToggle.BAR,
    CardToggle.PIE,
    CardToggle.SANKEY,
    CardToggle.FUNNEL,
    CardToggle.STACK,
    CardToggle.VALUE_SCATTER,
    CardToggle.SCATTER,
    CardToggle.HEATMAP,
    CardToggle.TABLE,
  ];

  static getText = t => {
    if (t === CardToggle.LINE) return getLocalLanguageText("lineChart");
    if (t === CardToggle.BAR) return getLocalLanguageText("barChart");
    if (t === CardToggle.PIE) return getLocalLanguageText("pieChart");
    if (t === CardToggle.SANKEY) return getLocalLanguageText("sankeyChart");
    if (t === CardToggle.FUNNEL) return getLocalLanguageText("funnelChart");
    if (t === CardToggle.STACK) return getLocalLanguageText("stackChart");
    if (t === CardToggle.SCATTER) return getLocalLanguageText("scatterChart");
    if (t === CardToggle.VALUE_SCATTER) return getLocalLanguageText("valueScatterChart");
    if (t === CardToggle.HEATMAP) return getLocalLanguageText("heatMap");
    if (t === CardToggle.TABLE) return getLocalLanguageText("table");
    if (t === CardToggle.MAP) return getLocalLanguageText("map");
  };

  static filterChartToggles = toggles => toggles.filter(t => CardToggle.chartToggles.includes(t));
}
