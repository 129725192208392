export default {
  pinboardName: "名称",
  pinboardNamePlaceholder: "请输入报告名称",
  pinboardDesc: "简介",
  pinboardDescPlaceholder: "请输入报告简介",
  pinboardNameNotFilledText: "报告名称未填写",
  plainSpacePinboardNameText: "报告名称不能是纯空格",
  plainSpacePinboardDescText: "报告描述不能是纯空格",
  createPinboard: "新建报告",
  pinboardTag: "标签",
  cancel: "取消",
  create: "新建",
};
