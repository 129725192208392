export default {
  sensitiveInfoPermission: "Sensitive information viewing restrictions",
  permissionOffTip: "Permission is not enabled, there are no sensitive restrictions when users view sensitive information",
  saveSuccessToast: "Sensitive information viewing limit updated successfully",
  enableConfirmTitle: "Confirm that sensitive information viewing restrictions are turned on?",
  enableConfirmTip:
    "After enabling, you need to configure sensitive information viewing restrictions, otherwise it may cause no data in search results",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  refreshBtnText: "Refresh",
  dataMaskingTip:
    "Please add the resources that need to be added to the list from the left to the right, and the resources added to the list on the right will be desensitized",
  unmaskingTip:
    "Please add the resources that need to be added to the list from the left to the right, and the resources added to the list on the right will not be desensitized",
  allDimensions: "All dimensions",
  selectedDimensions: "App List",
  saveBtnText: "Update",
};
