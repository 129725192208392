import React from "react";

const Hamburger = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5 18.5H21.5" stroke="currentColor" strokeOpacity="0.65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 12.5H21.5" stroke="currentColor" strokeOpacity="0.65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 6.5H21.5" stroke="currentColor" strokeOpacity="0.65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default Hamburger;
