import React from "react";

const PinboardBtnAdd = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.41732 5.41634V0.458008H6.58398V5.41634H11.5423V6.58301H6.58398V11.5413H5.41732V6.58301H0.458984V5.41634H5.41732Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PinboardBtnAdd;
