export default {
  rules: "Scene Giraffe",
  rulesTip:
    "Flexible configuration of scenarios allows users to query complex logic using simple vocabulary by configuring triggering and execution rules. Rules will be executed in order",
  preRuleSettings: "Pre participle rules",
  ruleSettings: "post participle rules",
  testRunBtn: "Test run",
  deleteSuccessToast: "Delete successfully",
  saveSuccessToast: "Rule saved",
};
