import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import Auth from "@/components/Auth";
import { ThemeModeType, WatermarkFontWeight } from "@/components/Constants";
import { getPersonalTheme, getPersonalThemeValue, savePersonalTheme } from "@/utils/personalTheme";
import GlobalContext from "./GlobalContext";

const InitialContext = React.createContext({
  clientID: undefined,
  cas: undefined,
  hasLark: false,
  hasDingTalk: false,
  hasOauth2: false,
  hasWeChatWork: false,
  hasSaml2: false,
  hasYufu: false,
  allowRegister: false,
  hasWatermark: false,
  watermarkType: 1,
  watermarkStyle: 0,
  watermarkContent: "",
  watermarkFontSize: 14,
  watermarkFontWeight: WatermarkFontWeight.DEFAULT,
  watermarkOpacity: 20,
  enableVoice: false,
  setEnableVoice: _.noop,
  loginMethods: [],
  loadData: _.noop,
  hiddenHeaderRight: false,
  disableCornerLogo: false,
  disableCardShare: false,
  setDisableCardShare: _.noop,
  disableCardDownload: false,
  setDisableCardDownload: _.noop,
  disableFrontendGuide: false,
  setDisableFrontendGuide: _.noop,
  disableBackendGuide: false,
  setDisableBackendGuide: _.noop,
  disableMessageCenter: false,
  setDisableMessageCenter: _.noop,
  disableSearchAdvice: false,
  setDisableSearchAdvice: _.noop,
  disableIsWatched: false,
  setDisableIsWatched: _.noop,
  disableLatestSearch: false,
  setDisableLatestSearch: _.noop,
  enable2fa: false,
  themeMode: ThemeModeType.LIGHT,
  themeConfigs: {},
  llmConfig: {},
  inactivityLogoutTime: 0,
});

export default InitialContext;

export const InitialContextProvider = ({ children }) => {
  const [clientID, setClientID] = useState();
  const [renew, setRenew] = useState(false); // 许可证是否过期，true为过期

  // 用户登录相关的状态
  const [cas, setCAS] = useState();
  const [allowRegister, setAllowRegister] = useState(false);
  const [enable2fa, setEnable2fa] = useState(false);
  const [hasLark, setHasLark] = useState(false);
  const [hasDingTalk, setHasDingTalk] = useState(false);
  const [hasOauth2, setHasOauth2] = useState(false);
  const [hasWeChatWork, setHasWeChatWork] = useState(false);
  const [hasSaml2, setHasSaml2] = useState(false);
  const [hasYufu, setHasYufu] = useState(false);
  const [loginMethods, setLoginMethods] = useState([]);

  // 水印相关的状态
  const [hasWatermark, setHasWatermark] = useState(false);
  const [watermarkType, setWatermarkType] = useState(1);
  const [watermarkFontSize, setWatermarkFontSize] = useState(14);
  const [watermarkFontWeight, setWatermarkFontWeight] = useState(WatermarkFontWeight.DEFAULT);
  const [watermarkOpacity, setWatermarkOpacity] = useState(20);
  const [watermarkStyle, setWatermarkStyle] = useState(0);
  const [inactivityLogoutTime, setInactivityLogoutTime] = useState(0);
  const [watermarkContent, setWatermarkContent] = useState("");

  // 语音输入相关的状态
  const [enableVoice, setEnableVoice] = useState(false);

  // 顶部右侧隐藏
  const [hiddenHeaderRight, setHiddenHeadRight] = useState(false);

  // 新手引导相关状态
  const [disableFrontendGuide, setDisableFrontendGuide] = useState(false); // 前台新手引导
  const [disableBackendGuide, setDisableBackendGuide] = useState(false); // 后台新手引导

  const [disableCornerLogo, setDisableCornerLogo] = useState(false); // 后台角标 logo 开关
  const [disableCardShare, setDisableCardShare] = useState(false);
  const [disableCardDownload, setDisableCardDownload] = useState(false);
  const [disableMessageCenter, setDisableMessageCenter] = useState(false);

  const [disableSearchAdvice, setDisableSearchAdvice] = useState(false);
  const [disableIsWatched, setDisableIsWatched] = useState(false);
  const [disableLatestSearch, setDisableLatestSearch] = useState(false);

  const [themeMode, setThemeMode] = useState(getPersonalThemeValue(getPersonalTheme()) || ThemeModeType.LIGHT); // 优先从个人设置取，themeMode = light | dark
  const [themeConfigs, setThemeConfigs] = useState();

  // 大语言模型关联功能
  const [llmConfig, setLlmConfig] = useState();

  const { isMobile } = useContext(GlobalContext);

  const location = useLocation();

  const loadData = force => {
    Auth.initClientConf(({ hiddenHeaderRight, renew }) => {
      setClientID(Auth.clientID);
      setRenew(renew);
      setAllowRegister(Auth.allowRegister);
      setCAS(Auth.cas);
      setHasLark(Auth.hasLark);
      setHasDingTalk(Auth.hasDingTalk);
      setHasOauth2(Auth.hasOauth2);
      setHasWeChatWork(Auth.hasWeChatWork);
      setHasSaml2(Auth.hasSaml2);
      setHasYufu(Auth.hasYufu);
      setHasWatermark(Auth.hasWatermark);
      setWatermarkType(Auth.watermarkType);
      setWatermarkFontSize(Auth.watermarkFontSize);
      setWatermarkFontWeight(Auth.watermarkFontWeight);
      setWatermarkOpacity(Auth.watermarkOpacity);
      setWatermarkStyle(Auth.watermarkStyle);
      setWatermarkContent(Auth.watermarkContent);
      setInactivityLogoutTime(Auth.inactivityLogoutTime);
      setEnableVoice(Auth.enableVoice);
      setLoginMethods(Auth.loginMethods);
      setHiddenHeadRight(hiddenHeaderRight);
      setDisableCardShare(Auth.disableCardShare);
      setDisableCardDownload(Auth.disableCardDownload);
      setDisableFrontendGuide(Auth.disableFrontendGuide);
      setDisableBackendGuide(Auth.disableBackendGuide);
      setDisableMessageCenter(Auth.disableMessageCenter);
      setDisableSearchAdvice(Auth.disableSearchAdvice);
      setDisableIsWatched(Auth.disableIsWatched);
      setDisableLatestSearch(Auth.disableLatestSearch);
      setEnable2fa(Auth.enable2fa);
      !getPersonalTheme() && setThemeMode(Auth.themeMode); // 个人不存在主题设置时可用
      setThemeConfigs(Auth.themeConfigs);
      setLlmConfig(Auth.llmConfig);
    }, !!force);
  };
  useEffect(loadData, []);

  useEffect(() => {
    const themeConfigKey = isMobile ? ThemeModeType.LIGHT : themeMode;
    themeConfigs?.[themeConfigKey] && setDisableCornerLogo(themeConfigs[themeConfigKey].disableCornerLogo); // 当前主题角标logo开关状态

    const noDarkThemePages = ["/manage", "/admin", "/auth", "/audit"];
    const hasNoDarkThemePage = noDarkThemePages.some(item => location.pathname.startsWith(item));

    if (isMobile || hasNoDarkThemePage) return document.documentElement.setAttribute("data-theme", ""); // 移动端 后台 登录, 暂时不支持深色模式

    document.documentElement.setAttribute("data-theme", themeMode === ThemeModeType.DARK ? "dark" : "");
  }, [themeMode, isMobile, themeConfigs, location.pathname]);

  const setPersonalThemeMode = useCallback(personalTheme => {
    setThemeMode(getPersonalThemeValue(personalTheme));
    savePersonalTheme(personalTheme);
  }, []);

  const getPersonalThemeMode = useCallback(() => getPersonalTheme() || themeMode, [themeMode]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    clientID,
    renew,
    allowRegister,
    cas,
    hasLark,
    hasDingTalk,
    hasOauth2,
    hasWeChatWork,
    hasSaml2,
    hasYufu,
    hasWatermark,
    watermarkType,
    watermarkStyle,
    watermarkContent,
    watermarkFontSize,
    watermarkFontWeight,
    watermarkOpacity,
    enableVoice,
    setEnableVoice,
    loginMethods,
    loadData,
    hiddenHeaderRight,
    disableCardShare,
    setDisableCardShare,
    disableCardDownload,
    setDisableCardDownload,
    disableFrontendGuide,
    setDisableFrontendGuide,
    disableBackendGuide,
    setDisableBackendGuide,
    disableMessageCenter,
    setDisableMessageCenter,
    disableSearchAdvice,
    setDisableSearchAdvice,
    disableIsWatched,
    setDisableIsWatched,
    disableLatestSearch,
    setDisableLatestSearch,
    disableCornerLogo,
    enable2fa,
    themeMode,
    getPersonalThemeMode,
    setPersonalThemeMode,
    themeConfigs,
    llmConfig,
    inactivityLogoutTime,
  };

  return <InitialContext.Provider value={value}>{children}</InitialContext.Provider>;
};
