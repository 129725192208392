import React from "react";

const SearchSuggestion = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M16.0756 2.5H19.4616C20.8639 2.5 22.0001 3.64585 22.0001 5.05996V8.47452C22.0001 9.88864 20.8639 11.0345 19.4616 11.0345H16.0756C14.6734 11.0345 13.5371 9.88864 13.5371 8.47452V5.05996C13.5371 3.64585 14.6734 2.5 16.0756 2.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.53852 2.5H7.92449C9.32676 2.5 10.463 3.64585 10.463 5.05996V8.47452C10.463 9.88864 9.32676 11.0345 7.92449 11.0345H4.53852C3.13626 11.0345 2 9.88864 2 8.47452V5.05996C2 3.64585 3.13626 2.5 4.53852 2.5ZM4.53852 13.9655H7.92449C9.32676 13.9655 10.463 15.1114 10.463 16.5255V19.94C10.463 21.3532 9.32676 22.5 7.92449 22.5H4.53852C3.13626 22.5 2 21.3532 2 19.94V16.5255C2 15.1114 3.13626 13.9655 4.53852 13.9655ZM19.4615 13.9655H16.0755C14.6732 13.9655 13.537 15.1114 13.537 16.5255V19.94C13.537 21.3532 14.6732 22.5 16.0755 22.5H19.4615C20.8637 22.5 22 21.3532 22 19.94V16.5255C22 15.1114 20.8637 13.9655 19.4615 13.9655Z"
      fill="currentColor"
    />
  </svg>
);

export default SearchSuggestion;
