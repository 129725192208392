export default {
  900: "Invalid SQL statement",
  902: "Invalid data type",
  903: "Invalid table name",
  905: "Keyword spelling error",
  906: "Missing left parenthesis",
  907: "Missing right parenthesis",
  909: "Incorrect number of parameters",
  918: "Ambiguous field usage",
  923: "Missing FROM keyword",
  925: "Missing INTO keyword",
  926: "Missing VALUES keyword",
  928: "Missing SELECT keyword",
  932: "Incompatible value and target field type",
  942: "Table or view does not exist",
  950: "Invalid DROP option",
  955: "Name already used by existing object",
  957: "Duplicate field name",
  960: "Ambiguous field name in search list",
  972: "Identifier too long",
  999: "Invalid view name",
  1036: "Illegal variable name or variable",
  1400: "Cannot insert NULL value into field",
  1403: "Data not found",
  1410: "Invalid row number",
  1435: "User does not exist",
  1438: "Value exceeds precision limit of field",
  1476: "Divide by zero",
  1730: "Number of fields is ambiguous when creating a view",
  1745: "Invalid host or binding variable name",
  1747: "Invalid table or field specification",
  1861: "Text does not match format string",
  4043: "Object not found",
  4063: "Package body contains errors",
  6576: "Not a valid function or process name",
  12899: "Value is too large for this field",
  20983: "Package does not exist",
  24333: "Spelling error in SQL statement",
  24381: "Array insertion or merge error",
};
