export default {
  basicInfo: "基本信息",
  name: "用户组名称",
  nameInputErrMsg: "请输入用户组名称",
  autoAddUsers: "自动添加用户",
  on: "开启",
  off: "关闭",
  autoAddUsersTip: "开启后新添加到本工作区的用户将自动添加到此用户组",
  createdAt: "创建时间",
  description: "用户组介绍",
  notSet: "未设置",
  disableDownloadLabel: "禁止下载数据",
  disableDownloadDesc: "打开后禁止用户下载卡片数据、下载PNG图片及发送邮件",
  downloadMessage: "系统开启了禁止下载数据，所有用户自动禁止下载数据",
};
