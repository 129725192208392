export default {
  guide: "initialization",
  settings: "Basic Settings",
  sso: "Login Settings",
  workspaceManagement: "Workspace Management",
  personalization: "Personalization Settings",
  user: "User Management",
  audit: "Audit Logs",
  systemStatus: "System Status",
  email: "Email Settings",
  llm: "Large Language Model Settings",
  shortMessage: "SMS Settings",
  netStatus: "Network Status",
};
