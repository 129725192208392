export default class UploadFileExceptionMode {
  // 异常处理模式
  static SKIP = 0; // 跳过异常行

  static TERMINATE = 1; // 异常终止

  static SET_NULL = 2; // 数据转换异常时，置为空

  static EXCEPTION_MODE = "exceptionMode"; // 接口字段
}
