import React from "react";

const AutoInsightInfo: React.FC = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <path
      d="M10.8022 7.94191L9.97059 8.25294C9.90441 8.27721 9.86029 8.34118 9.86029 8.41176C9.86029 8.48235 9.90441 8.54632 9.97059 8.57059L10.8022 8.88162L11.1132 9.71324C11.1375 9.77941 11.2015 9.82353 11.2721 9.82353C11.3426 9.82353 11.4066 9.77941 11.4309 9.71324L11.7419 8.88162L12.5735 8.57059C12.6397 8.54632 12.6838 8.48235 12.6838 8.41176C12.6838 8.34118 12.6397 8.27721 12.5735 8.25294L11.7419 7.94191L11.4309 7.11029C11.4066 7.04412 11.3426 7 11.2721 7C11.2015 7 11.1375 7.04412 11.1132 7.11029L10.8022 7.94191ZM6.64191 15.7221C6.22941 16.1346 6.22941 16.8051 6.64191 17.2199L7.40515 17.9831C7.81765 18.3956 8.48824 18.3956 8.90294 17.9831L17.314 9.56985C17.7265 9.15735 17.7265 8.48676 17.314 8.07206L16.5507 7.31103C16.1382 6.89853 15.4676 6.89853 15.0529 7.31103L6.64191 15.7221ZM16.3147 8.82206L13.9985 11.1382L13.4846 10.6243L15.8007 8.30809L16.3147 8.82206ZM5.79044 9.58529C5.69118 9.62279 5.625 9.71765 5.625 9.82353C5.625 9.92941 5.69118 10.0243 5.79044 10.0618L7.03676 10.5294L7.50441 11.7757C7.54191 11.875 7.63676 11.9412 7.74265 11.9412C7.84853 11.9412 7.94338 11.875 7.98088 11.7757L8.44853 10.5294L9.69485 10.0618C9.79412 10.0243 9.86029 9.92941 9.86029 9.82353C9.86029 9.71765 9.79412 9.62279 9.69485 9.58529L8.44853 9.11765L7.98088 7.87132C7.94338 7.77206 7.84853 7.70588 7.74265 7.70588C7.63676 7.70588 7.54191 7.77206 7.50441 7.87132L7.03676 9.11765L5.79044 9.58529ZM13.5551 15.2324C13.4559 15.2699 13.3897 15.3647 13.3897 15.4706C13.3897 15.5765 13.4559 15.6713 13.5551 15.7088L14.8015 16.1765L15.2691 17.4228C15.3066 17.5221 15.4015 17.5882 15.5074 17.5882C15.6132 17.5882 15.7081 17.5221 15.7456 17.4228L16.2132 16.1765L17.4596 15.7088C17.5588 15.6713 17.625 15.5765 17.625 15.4706C17.625 15.3647 17.5588 15.2699 17.4596 15.2324L16.2132 14.7647L15.7456 13.5184C15.7081 13.4191 15.6132 13.3529 15.5074 13.3529C15.4015 13.3529 15.3066 13.4191 15.2691 13.5184L14.8015 14.7647L13.5551 15.2324Z"
      fill="white"
    />
  </svg>
);

export default AutoInsightInfo;
