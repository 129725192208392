export default {
  settings: "Settings",
  personalSettings: "Account",
  username: "Username",
  password: "Password",
  largeFontMode: "Large font mode",
  hasSet: "Set",
  hasNotSet: "Not set",
  changePassword: "Change Password",
  usernameEmptyErrorToast: "Username cannot be empty",
  save: "Save",
  saveSuccessToast: "Saved successfully",
  timeDataIntelligentAnalysis: "Time data intelligent analysis",
  autoDisplayDimensionYoyAnalysis: "Display dimension YoY analysis automatically ",
  autoDisplayDimensionRingAnalysis: "Display dimension Previous Period analysis automatically ",
  followWorkspaceSettings: "Follow workspace settings",
  alwaysOpen: "Always open",
  alwaysClose: "Always close",
  smartTip: "Intelligent",
  differentTipBoxOfSearchTip: "Switch automatically based on your search",
  searchTipSettings: "Search Suggestion",
  defaultFieldTip: "Keyword",
  defaultQuestionTip: "Question",
  switchToQuestionTipManuallyTip:
    "Display keyword suggestions when focusing on the search bar, and switch to question suggestions manually",
  switchToFieldTipManuallyTip: "Display question suggestions when focusing on the search bar, and switch to keyword suggestions manually",
  projectSettings: "Workspace",
  confirm: "Confirm",
  oldPassword: "Old password",
  newPassword: "New password",
  confirmNewPassword: "Confirm new password",
  passwordTooShortToast: "Password cannot be less than 8 characters",
  passwordTooLongToast: "Password cannot be more than 32 characters",
  passwordInconsistentToast: "Passwords do not match",
  modifySuccessToast: "Modified successfully",
  enterOldPassword: "Enter old password",
  enterNewPassword: "Enter new password",
  enterNewPasswordAgain: "Enter new password again",
  wpsPluginManagement: "WPS Plugin",
  otherSettings: "Other",
  wpsTip: "Install or uninstall WPS plugin",
  userNameLabel: "Username",
  userNameInputPrompt: "Please enter your username",
  passwordLabel: "Password",
  timeDataIntelligentAnalysisLabel: "Time Series Analysis",
  autoDisplayDimensionYoyAnalysisLabel: "Dimension YoY Analysis",
  autoDisplayDimensionRingAnalysisLabel: "Dimension Previous Period Analysis",
  insightsTypeSwitch: "Auto Insights",
  saveSettings: "Save Settings",
  enterTourBtnText: "Enter the novice guide again",
  insightSettingsTabs: "Insights",
  basicInsightTitle: "Basic analysis",
  dimensionInsightTitle: "Dimension related analysis",
  trendInsightTitle: "Trend analysis",
  outlierLabel: "Abnormal values",
  outlierDesc: "Shows points in the data that are significantly higher or lower than other values",
  maximumLabel: "Max",
  minimumLabel: "Min",
  averageLabel: "Average",
  statisticsLabel: "Dispersion",
  paretoLabel: "80/20 Pareto",
  paretoDesc:
    "Determine whether the data conforms to the 28-20 distribution. That is, in this set of data, the most important dimension only accounts for a small part, about 20%, and the remaining 80%, although it is the majority, is secondary.",
  ringOutlierLabel: "Growth Outliers",
  ringOutlierDesc: "Display the points in the data where the growth rate or decline rate is significantly higher than other times.",
  seasonalityLabel: "Seasonality",
  seasonalityDesc: "Determine whether the data has the highest or lowest value on a fixed day/month in each week/year",
  trendLabel: "Overall Trend",
  trendDesc: "Determine whether the data shows an overall upward or downward trend",
  changePointLabel: "Trend change point",
  changePointDesc: "Determine whether there is an inflection point in the data trend",
  breakoutLabel: "Breakthrough",
  breakoutDesc: "Determine whether the data is increasing or decreasing at an accelerated rate",
  volatilityLabel: "Volatility change",
  volatilityDesc: "Determine whether there are large fluctuations in the data",
};
