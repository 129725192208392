import React from "react";

const UpArrow = ({ arrowColor = "var(--datarc-color-info-active)", ...rest }) => (
  <svg {...rest} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13.6667C7 13.5245 7.05444 13.3823 7.16278 13.274L11.6072 8.82957C11.8244 8.61235 12.1755 8.61235 12.3927 8.82957L16.8371 13.274C17.0543 13.4912 17.0543 13.8423 16.8371 14.0595C16.6199 14.2767 16.2688 14.2767 16.0515 14.0595L11.9999 10.0079L7.94832 14.0595C7.7311 14.2767 7.37999 14.2767 7.16278 14.0595C7.05444 13.9512 7 13.8089 7 13.6667Z"
      fill={arrowColor}
    />
  </svg>
);

export default UpArrow;
