export default class ChoiceBaseFieldType {
  static MANUALLY_METHOD = "manually"; // 手动配置方式

  static JSON_METHOD = "json"; // json 输入方式

  static TYPE_BEFORE_MAP_FIELD = "typeBeforeMap";

  static TYPE_AFTER_MAP_FIELD = "typeAfterMap";

  static CHOICE_FIELD = "choice";

  static BASE_FIELD_JSON_FIELD = "baseFieldJson";

  static BOOL_TRUE_LABEL = "true";

  static BOOL_FALSE_LABEL = "false";
}
