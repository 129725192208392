import React from "react";

const PinboardTableFilter = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.18855 0.747376C0.286865 0.535478 0.499225 0.399902 0.732821 0.399902H14.0662C14.2997 0.399902 14.5121 0.535478 14.6104 0.747376C14.7087 0.959275 14.6751 1.20897 14.5243 1.38734L9.33282 7.52626V12.9999C9.33282 13.2078 9.22515 13.401 9.04826 13.5103C8.87137 13.6196 8.65049 13.6296 8.46449 13.5366L5.79783 12.2032C5.59456 12.1016 5.46615 11.8938 5.46615 11.6666V7.52626L0.274679 1.38734C0.12384 1.20897 0.0902349 0.959275 0.18855 0.747376ZM2.026 1.5999L6.5243 6.91913C6.61589 7.02745 6.66615 7.16472 6.66615 7.30657V11.2957L8.13282 12.0291V7.30657C8.13282 7.16472 8.18308 7.02745 8.27468 6.91913L12.773 1.5999H2.026Z"
      fill="currentColor"
      fillOpacity="0.65"
    />
  </svg>
);

export default PinboardTableFilter;
