export default {
  aiFill: "AI Configuration",
  autoFill: "Intelligent Fill",
  descriptionFill: "Fill in database comments",
  fieldFill: "Fill in data column names",
  uploadFill: "Manual upload",
  noFill: "Do not fill",
  autoFillDesc: "Intelligently fill according to rules",
  descriptionFillDesc: "Fill in the field name with the data column comments in the database",
  fieldFillDesc: "Fill in the field name with the data column name",
  noFillDesc: "Selecting this option will clear the current fill content.",
  uploadFillDesc: "Populate based on the contents of the uploaded csv file",
};
