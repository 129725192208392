import React from "react";

const InfoOutlined = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7 2.625H3.3C2.51625 2.625 1.88212 3.26625 1.88212 4.05L1.87561 15.782C1.87538 16.1851 2.3628 16.3872 2.64787 16.1021L4.725 14.025H14.7C15.4837 14.025 16.125 13.3837 16.125 12.6V4.05C16.125 3.26625 15.4837 2.625 14.7 2.625ZM14.5982 12.4643H4.25L3.71325 13.0204C3.60512 13.1438 3.40179 13.0673 3.40179 12.9032V4.15179H14.5982V12.4643ZM8.22788 10.5134C8.22788 10.0918 8.56966 9.75 8.99127 9.75H9.01306C9.43467 9.75 9.77645 10.0918 9.77645 10.5134C9.77645 10.935 9.43467 11.2768 9.01306 11.2768H8.99127C8.56966 11.2768 8.22788 10.935 8.22788 10.5134ZM8.22788 6.24929C8.22788 5.82166 8.57454 5.475 9.00217 5.475C9.42979 5.475 9.77645 5.82166 9.77645 6.24929V7.95786C9.77645 8.38548 9.42979 8.73214 9.00217 8.73214C8.57454 8.73214 8.22788 8.38548 8.22788 7.95786V6.24929Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoOutlined;
