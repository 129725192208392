export default {
  dataCacheRefreshSucceeded: "Data cache refresh successfully",
  enterAModelNameToSearch: "Please enter a model name to search",
  enterAModelNameOrKeywordToSearch: "Please enter a model name or keyword to search",
  formal: "Formal",
  roughDraft: "Draft",
  refreshCachedData: "Refresh Cache Data",
  syncKeywordTaskStart:
    "The keyword synchronization task starts, please avoid using the search function during the synchronization process, which may lead to inaccurate data",
  syncKeywords: "Sync Keywords",
  createModel: "Create Model",
  ifYouNeedHelpPlease: "If you need help please",
  clickHere: "Click here",
  learnHowToCreateModel: "Learn how to create a model.",
  orderNumber: "Serial number",
  name: "Name",
  source: "Source",
  noSource: "No source",
  updatedAt: "Modified",
  operator: "Operator",
  operation: "Settings",
  overview: "Overview",
  edit: "Edit",
  delete: "Delete",
  saveSuccessToast: "Save Successfully",
  modelIsANecessary: "Model is necessary for searching data",
  haveNotCreatedAnyModels: "You haven't created any models ~",
  learnHowToCreateModelFromVideo: "You can learn how to create models from the video on the right",
  disabledOrLoggedOutUsers: "Disabled or logged out users",
  default: " (default) ",
  notConfigured: "Not configured",
  confirmToDeleteTheModel: "Are you sure to delete the model?",
  currentModel: "Current Model: ",
  formerConfirmToDeleteTheModel: "Confirm to delete model [",
  latterConfirmToDeleteTheModel: "]？",
  modelDeletedSuccessfully: "Model deleted successfully",
  deletingMayAffectTheSearchBehavior:
    "Note that deleting the data table may affect the search behavior of the workspace, and the configuration of this model is not recoverable.",
  delMeasureAndDimension: "Simultaneously delete dimensions and indicators that are only used in this model",
  deleteConfirmOkText: "Confirm Delete",
  cancel: "Cancel",
  copy: "Copy",
  copySucceeded: "Copy successfully",
  successfullyModifiedFactTableName: "Modified fact table name successfully",
  factTable: "Fact table",
  startUsing: "Enable",
  forbidden: "Disable",
  dateOffsetDefault: "Follow workspace settings",
  dataUpdateTime: "Data update time",
  successfullyUpdateTime: "Modify data update time successfully",
  successfullyUpdateTimeSeries: "Modify default time series successfully",
};
