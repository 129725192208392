import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.WorkspaceTab.${id}`);

export default class WorkspaceTab {
  // 基础设置界面 tabs
  static BASIC = "basic";

  static SETTING = "setting";

  static DISPLAY = "display";

  // model 页面 tabs
  static DATA_CONNECT = "dataConnect";

  static MODEL_CONFIG = "modelConfig";

  static DATA_TABLE = "dataTable";

  static getText = t => {
    switch (t) {
      case this.BASIC:
        return getText("basicInfo");
      case this.SETTING:
        return getText("searchSettings");
      case this.DISPLAY:
        return getText("displaySettings");
      case this.DATA_CONNECT:
        return getText("dataConnect");
      case this.MODEL_CONFIG:
        return getText("modelConfig");
      case this.DATA_TABLE:
        return getText("dataTable");
      default:
        return "";
    }
  };
}
