export default {
  feedback: "Semantic rules",
  feedbackTip:
    "When the search term entered by the user needs to correspond to multiple fields, you can apply for feedback, and it will take effect after review",
  searchInputPlaceholder: "Please enter the information you want to search",
  addBtnText: "Add rule",
  addSuccessToast: "Create successfully",
  deleteSuccessToast: "Delete successfully",
  saveSuccessToast: "Modified successfully",
  editBtnText: "Edit",
  deleteBtnText: "Delete",
  deleteConfirmTitle: "Confirm the deletion of this semantic rule?",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  invalidRuleTip: "This rule is invalid",
  userInput: "User input",
  tokens: "Fields Match",
  operator: "Operator",
  disabledAccountTip: "The user has been disabled or logged out",
  updatedAt: "Modified",
  operations: "Settings",
  feedbackModalTitle: "Semantic rules",
  userInputLabel: "User input",
  okBtnText: "Confirm",
};
