export default {
  monitoringIndicators: "监控指标",
  dataAlarmModalTitle1: "告警设置",
  dataAlarmModalTitle2: "选择接收人",
  dataAlarmModalTitle3: "监控指标试跑",
  measureSelectPlaceholder: "请选择需要告警的指标",
  greaterThanStr: "大于(＞)",
  greaterEqualThanStr: "大于等于(≥)",
  lessThanStr: "小于(＜)",
  lessEqualThanStr: "小于等于(≤)",
  equalStr: "等于(=)",
  notEqualStr: "不等于(≠)",
  yoyChange: "同比变化",
  ringChange: "环比变化",
  valueItself: "值本身",
  intelligentAlarm: "智能告警",
  typePlaceholder: "请填写比较值",
  triggerConditions: "触发条件",
  useAtLeastOne: "至少使用一种",
  monitoringCycle: "监控周期",
  sendingTime: "告警发送时间",
  reminderMethod: "提醒方式",
  operatorPlaceholder: "请填写比较值",
  mailPush: "邮件推送",
  requireBindMail: "需要用户绑定邮箱",
  larkReminder: "飞书提醒",
  requireBindLark: "需要用户绑定飞书账号",
  dingtalkReminder: "钉钉提醒",
  requireBindDingtalk: "需要用户绑定钉钉账号",
  wecomReminder: "企业微信提醒",
  requireBindWecom: "需要用户绑定企业微信账号",
  nextStep1: "下一步，选择接收人",
  nextStep2: "下一步，试跑",
  create: "确定并新建",
  save: "保存",
  cancel: "取消",
  back1: "上一步，设置条件",
  back2: "上一步，选择接收人",
  daily: "天",
  weekly: "周",
  monthly: "月",
  everyDay: "每天",
  every: "每",
  day: "日",
  formerTime: "第",
  latterTime: "天",
  progressText: "试跑监控指标及筛选条件",
  runningErrorText: "试跑失败，无法创建，请调整筛选条件后重试",
  additionalParameters: "附加参数",
  copySuccessToast: "复制成功",
  copyFailToast: "复制失败，请稍后重试",
  resultSQL: "结果SQL",
  resultSQLInfo: "支持复制SQL到数据库中校验",
  copy: "复制",
  trialRunRes: "试跑结果",
  trialRunResInfo: "请确认下图为你需要监控的指标，试跑内容为 筛选条件+指标+T-1的周期",
  alertUpdateSuccess: "修改成功",
  alertSetSuccess: "数据告警设置成功",
  pleaseChooseMembers: "请选择接收人",
  addFilterBtn: "+ 添加",
  filterLabel: "维度筛选条件",
  addDimensionFilter: "添加维度筛选条件",
  addDimensionFilterPlaceholder: "请选择需要筛选的维度",
  hasNotDimension: "请先选择需要筛选的维度，再选择具体维度值",
  advancedSetting: "高级设置",
  noResultTip: "搜索无结果",
  smartAlertTip: "该指标的时序模型出现异常点或者连续两个周期均有突破性上升或下降时，自动触发告警",
};
