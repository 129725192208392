import React from "react";

const AiExplanation = prop => {
  return (
    <svg {...prop} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3.39999C17.2583 3.39999 17.4876 3.56525 17.5692 3.81026L18.2244 5.77565L20.1897 6.43078C20.4348 6.51245 20.6 6.74174 20.6 6.99999C20.6 7.25825 20.4348 7.48754 20.1897 7.5692L18.2244 8.22434L17.5692 10.1897C17.4876 10.4347 17.2583 10.6 17 10.6C16.7418 10.6 16.5125 10.4347 16.4308 10.1897L15.7757 8.22434L13.8103 7.5692C13.5653 7.48754 13.4 7.25825 13.4 6.99999C13.4 6.74174 13.5653 6.51245 13.8103 6.43078L15.7757 5.77565L16.4308 3.81026C16.5125 3.56525 16.7418 3.39999 17 3.39999Z"
        fill="url(#paint0_linear_31_10155)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 12C17.5523 12 18 12.4477 18 13V18C18 18.5523 17.5523 19 17 19C16.4477 19 16 18.5523 16 18V13C16 12.4477 16.4477 12 17 12Z"
        fill="url(#paint1_linear_31_10155)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.14886 6.72784C7.27064 6.29726 7.66365 5.99999 8.11112 5.99999H9.88889C10.3364 5.99999 10.7294 6.29727 10.8511 6.72785L13.9622 17.7278C14.1125 18.2593 13.8035 18.8119 13.272 18.9622C12.7406 19.1126 12.1879 18.8036 12.0376 18.2721L9.13249 7.99999H8.86752L5.96227 18.2721C5.81196 18.8036 5.2593 19.1126 4.72786 18.9622C4.19642 18.8119 3.88745 18.2593 4.03776 17.7278L7.14886 6.72784Z"
        fill="url(#paint2_linear_31_10155)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00001 15C5.00001 14.4477 5.44773 14 6.00001 14H12C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16H6.00001C5.44773 16 5.00001 15.5523 5.00001 15Z"
        fill="url(#paint3_linear_31_10155)"
      />
      <defs>
        <linearGradient id="paint0_linear_31_10155" x1="12.2999" y1="3.39999" x2="12.2999" y2="19.0002" gradientUnits="userSpaceOnUse">
          <stop stopColor="#256EFF" />
          <stop offset="1" stopColor="#7B51DB" />
        </linearGradient>
        <linearGradient id="paint1_linear_31_10155" x1="12.2999" y1="3.39999" x2="12.2999" y2="19.0002" gradientUnits="userSpaceOnUse">
          <stop stopColor="#256EFF" />
          <stop offset="1" stopColor="#7B51DB" />
        </linearGradient>
        <linearGradient id="paint2_linear_31_10155" x1="12.2999" y1="3.39999" x2="12.2999" y2="19.0002" gradientUnits="userSpaceOnUse">
          <stop stopColor="#256EFF" />
          <stop offset="1" stopColor="#7B51DB" />
        </linearGradient>
        <linearGradient id="paint3_linear_31_10155" x1="12.2999" y1="3.39999" x2="12.2999" y2="19.0002" gradientUnits="userSpaceOnUse">
          <stop stopColor="#256EFF" />
          <stop offset="1" stopColor="#7B51DB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AiExplanation;
