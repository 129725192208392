import React from "react";

const CarryOutOutlined = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99967 2.66669C2.0792 2.66669 1.33301 3.41288 1.33301 4.33336V11.6667C1.33301 12.5872 2.0792 13.3334 2.99967 13.3334H12.9997C13.9201 13.3334 14.6663 12.5872 14.6663 11.6667V4.33335C14.6663 3.41288 13.9201 2.66669 12.9997 2.66669H2.99967ZM3.18307 4.28537C2.94948 4.1381 2.64073 4.20809 2.49346 4.44168C2.34619 4.67528 2.41618 4.98403 2.64977 5.1313L3.03033 5.37121L7.00176 7.94842C7.60878 8.34234 8.39073 8.34234 8.99775 7.94842L12.9692 5.3712L13.3497 5.1313C13.5833 4.98403 13.6533 4.67528 13.5061 4.44168C13.3588 4.20809 13.05 4.1381 12.8164 4.28537L12.4331 4.52704L12.4276 4.53057L8.45339 7.10957C8.17747 7.28862 7.82204 7.28862 7.54612 7.10957L3.57194 4.53057L3.56641 4.52704L3.18307 4.28537Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default CarryOutOutlined;
