export default {
  saveSuccessToast: "Modified successfully",
  addSuccessToast: "Add successfully",
  editModalTitle: "Editing Synonyms",
  addModalTitle: "Add synonyms",
  okBtnText: "Confirm",
  dimensionValue: "Dimension value",
  dimensionValueErrMsg: "Please enter a dimension value",
  dimensionValueSearchPlaceholder: "Please enter the dimension value you want to search for",
  notFoundContent1: "No dimension value",
  notFoundContent2: "Not found, please try another word.",
  synonymTip: "Separate multiple synonyms with commas (,) ",
  synonymErrMsg: "Please enter a synonym",
};
