export default {
  factTable: "Fact table",
  factTableDes:
    "A fact table is the factual data obtained from observing things. Facts involve measures from business process events, which are basically expressed in quantitative values; the fact table here will also include two types of descriptive attributes, dimension and time dimension. Fact tables Usually contains a large number of data rows, and the amount of data will grow significantly over time.",
  timeDimension: "Time dimension",
  timeDimensionDes: "Fields used to describe the time and date in the workspace. For example, the date of the sale of goods.",
  dimension: "Dimension",
  dimensionDes:
    "In addition to the time dimension, fields used to describe business and attributes in the workspace. For example, city, store name.",
  indicator: "Measure",
  indicatorDes: "Quantization Parameter for measuring business functionality or content in the workspace.",
  model: "Model",
  modelDes:
    "A data retrieval paradigm under the Arctic Data rules is established for the use of natural language search and fast data lookup.",
  dimensionTable: "Dimension table",
  dimensionTableDes:
    "Dimension tables are perspectives on things, providing descriptive attributes involved in a business process practice for filtering and categorizing facts, used to describe events related to who, what, where, why, how  Configured dimension tables can be reused in other models; multiple dimension tables can also be configured using the original tables of the same database.",
  workspaceFieldType: "Field type",
  workspaceFieldTypeDes: "Define the attributes of the data column in the system, divided into time dimension, dimension and measure.",
  workspaceFieldTypeDesOfTimeDimension:
    "Time dimension: A measure that uses time as a description and expression of variables, usually a date. For example, the date an order was placed;",
  workspaceFieldTypeDesOfDimension:
    "Dimension: The number of abstract concepts in which things are'connected', which can also be understood as describing angles, usually text information. Such as the store address of a chain business;",
  workspaceFieldTypeDesOfIndicator:
    "Measure: A parameter, usually a number, to measure a goal. For example, the sales volume or sales of a certain category of goods.",
  dataColumn: "Data column",
  dataColumnDes: "To ensure performance, only selected fields will enter the calculation and display of search results",
  fieldNameForSearch: "Keyword",
  fieldNameForSearchDes:
    "Please use 1 natural language word to describe the data or content that summarizes this column, so that you can use this word to find the content of this column when searching.",
  fieldNameForSearchInputTip:
    "Please name the time dimension/dimension/measure, which can be used for question search. Multiple aliases can be added to improve the search hit rate, such as' this day' and' Today '.",
  dataType: "Data type",
  dataTypeDes: "Defines the data format of this column in the system, corresponding to the format required for data search in the model.",
  displayFormat: "Display format",
  displayFormatDes:
    "Defines the style in which the data or content of the data column is displayed in search results. If the column data type is floating point, it may need to be displayed as a percentage.",
  currencyCode: "Monetary unit",
  currencyCodeDes:
    "When the measure is related to the amount, its currency unit can be selected, and multiple currency units are supported",
  defaultTimeUnit: "Default time unit",
  fieldContentForSearch: "Dimension value searchable",
  fieldContentForSearchDes:
    "After opening, the non-pure numerical content of the column can be directly retrieved by the algorithm. For example, 'province' contains ' Beijing', 'Shanghai', 'Guangdong', etc. If this function is turned on, words such as 'Beijing', 'Shanghai', 'Guangdong' can be retrieved directly by the algorithm, while '711' cannot be retrieved because it is a pure numerical value.",
  polymerizationWay: "Calculation method",
  polymerizationWayDes:
    "Calculation and integration method of measures. For example, the turnover of different stores at different times is added up by store.",
  defaultOrder: "Value type",
  defaultOrderDes:
    "Positive: It means that the larger the value during sorting, the better the efficiency of the measure. At this time, the maximum value is equivalent to the highest, best, and largest meanings in the problem, such as the maximum profit in turnover 100, 80, and 50 is 100; Negative measures: The smaller the value during sorting, the better the efficiency of the measures. At this time, the maximum value is equivalent to the lowest, worst, and smallest meanings of the problem, such as the minimum loss of -100, -80, -50 is -50",
  addGlobalFilter: "Add global filter",
  addGlobalFilterDes:
    "The SQL formed by one or some fields of the current fact table, or the conditions formed by the corresponding search statements in the database, are used as filters for global data retrieval. That is, the corresponding conditions after the general SQL query statement where (Please write the SQL statement here according to the database retrieval syntax rules used by the customer).",
  searchWordsLimits: "Search term limits",
  searchWordsLimitsDes:
    "The data column may contain a large number of content that can be searched. This allows adding rules to limit the number of content loads.",
  searchDimensionPlus: "Default search dimension completion",
  searchDimensionPlusDes:
    "When the natural language input by a member is missing a dimension word, this item will be used to complete the content. If'Nike'is filled in this item, when a member enters ”Month's Turnover “, the search result will be ”Month's Nike Turnover “. Note that this configuration is only for this data column.",
  autoGroupBySearchResult: "Automatic grouping of search results",
  autoGroupBySearchResultDes:
    "When members enter multiple search terms in this dimension after opening, the search results will be displayed in groups. If you enter‘Adidas Nike Puma's turnover’, the brand name will be used as 1 coordinate on the x-axis, showing the comparison of the turnover of the 3 brands. Turn off the switch data will be aggregated and displayed (the accumulation of the turnover of the 3 brands).",
  dataTypeMapping: "Mapping",
  dataTypeMappingDes:
    "You can map some values in the fields in the original data table here. For example, map 1 to 'male', 0 to 'female', etc.",
  timelineScaleUnit: "Timeline scale unit",
  timelineScaleUnitDes:
    "Set a time period, the unit of the timeline scale in the search results chart, and the data in this time period will be aggregated according to this unit; if you set the data within 60 days to be aggregated in 'weeks', one tick on the timeline is one week.",
  theTableRelationship: "Table association relationship",
  theTableRelationshipDes: "Query data from two tables based on the relationship between their columns",
  relatedColumns: "Associated Columns",
  relatedColumnsDes: "A key for one or more columns that helps two tables establish a relationship",
  databaseUpdateLoading: "Database is being refreshed, please wait",
  databaseUpdateLoadingDes: "The database is being refreshed, please wait.",
  updateDatabase: "Refresh database",
  updateDatabaseDes: "Refresh the database.",
  databaseConnectSuccess: "Database connected",
  databaseConnectSuccessDes: "The database is connected.",
  databaseConnectLoading: "Database is trying to connect. Please wait",
  databaseConnectLoadingDes: "The database is trying to connect. Please wait.",
  databaseStartConnect: "Click to connect to the database",
  databaseConnectFail: "Database connection failed",
  databaseConnectUnSet: "Data connection not set",
  synonyms: "Alias",
  synonymsDes:
    "The alias of the field keyword, which can improve the hit rate of users when searching. Multiple aliases can be filled in, please use commas to separate them.",
  contentKeyword: "Dimension value",
  contentKeywordDes:
    "When a field in the model enables [dimension value searchable], the data contained in the field (excluding the field name) will become the dimension value.",
  contentKeywordSynonyms: "Synonyms",
  contentKeywordSynonymsDes: "Dimension value synonyms must meet the following rules:",
  contentKeywordSynonymsDesSame:
    "1. The same synonyms configured in the same dimension will be considered invalid. For example, the synonyms of Hebei and Henan cannot be 'river' at the same time",
  contentKeywordSynonymsDesOther:
    "2. In the same dimension, the dimension value cannot be configured as a synonym for other dimension values. For example, the synonym for Hebei is Henan",
  contentKeywordSynonymsDesCharacter: "3. Synonyms cannot contain special symbols (only -/_ 4 are supported), and cannot be pure numbers",
  appliedModel: "Applied model",
  appliedModelDes:
    "Measures, dimensions, and dimension tables are all concepts in the system that can be applied to specific fields of specific models. Modifying dimensions, measures, and dimension tables will affect all applied models.",
  recommendRating: "Recommended priority",
  recommendRatingDes:
    "Influences the recommendation weight of the dimension in the system. The dimension with higher priority will be displayed preferentially in the associated recommendation and intelligent analysis",
  tableType: "Table type",
  tableTypeDesCustomTable: "Custom table: Customize the table pulled from the database through SQL",
  tableTypeDesDatabaseTable: "Data databases & tables: Synchronize directly from data sources to tables in the system",
  applyModel: "Applied model",
  applyModelDes: "The application model of the table, modifying the custom table will affect all the models it applies",
};
