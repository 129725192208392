import React from "react";

const MessageAvatarPinboard = props => {
  const { iconBackgroundColor, style } = props;
  return (
    <svg style={style} width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill={iconBackgroundColor} />
      <path
        opacity="0.3"
        d="M17.2602 9.91997L14.1335 9.42664C11.5268 9.01997 10.3202 9.89997 9.90682 12.5066L9.41349 15.6333C9.14682 17.34 9.42682 18.4466 10.3935 19.12C10.9002 19.48 11.5935 19.72 12.4935 19.86L15.6202 20.3533C18.2268 20.76 19.4335 19.88 19.8468 17.2733L20.3335 14.1466C20.4135 13.6333 20.4468 13.1733 20.4202 12.7666C20.3335 11.1 19.3535 10.2466 17.2602 9.91997ZM13.4935 14.2333C12.7135 14.2333 12.0802 13.6 12.0802 12.8266C12.0802 12.0466 12.7135 11.4133 13.4935 11.4133C14.2668 11.4133 14.9002 12.0466 14.9002 12.8266C14.9002 13.6 14.2668 14.2333 13.4935 14.2333Z"
        fill="currentColor"
      />
      <path
        d="M21.6669 16.98L20.6669 19.9867C19.8336 22.4933 18.5002 23.16 15.9936 22.3267L12.9869 21.3267C11.4736 20.8267 10.6336 20.1333 10.3936 19.12C10.9002 19.48 11.5936 19.72 12.4936 19.86L15.6202 20.3533C18.2269 20.76 19.4336 19.88 19.8469 17.2733L20.3336 14.1467C20.4136 13.6333 20.4469 13.1733 20.4202 12.7667C22.0136 13.6133 22.3602 14.8933 21.6669 16.98Z"
        fill="currentColor"
      />
      <path
        d="M14.9001 12.8267C14.9001 13.6 14.2667 14.2333 13.4934 14.2333C12.7134 14.2333 12.0801 13.6 12.0801 12.8267C12.0801 12.0467 12.7134 11.4133 13.4934 11.4133C14.2667 11.4133 14.9001 12.0467 14.9001 12.8267Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessageAvatarPinboard;
