import { message as AntdMessage } from "antd";
import type { ArgsProps } from "antd/es/message";
import React from "react";
import { ConfirmError, ConfirmInfo, ConfirmSuccess, ConfirmWarning } from "@/components/SVGIcons";

import loadingSrc from "@/assets/loading.gif";

import styles from "./index.module.scss";

AntdMessage.config({
  top: 30,
});

const open = (props: ArgsProps) =>
  AntdMessage.open({
    icon: <ConfirmInfo />,
    className: `${styles.a} ${styles.b} ${styles.message}`,
    ...props,
  });

const getTypeMethod = function (icon) {
  return function (
    content: React.ReactNode | ArgsProps,
    duration?: number | VoidFunction, // Also can use onClose directly
    onClose?: VoidFunction
  ) {
    // 如果参数是对象类型
    if (content && typeof content === "object" && "content" in content) {
      return open({
        icon,
        ...(content as ArgsProps),
      });
    }
    const config: ArgsProps = {
      icon,
      content: content,
    };
    if (typeof duration === "function") {
      config.onClose = duration;
    } else {
      config.duration = duration;
      config.onClose = onClose;
    }
    return open(config);
  };
};

const message = {
  open: open,
  destroy: AntdMessage.destroy,
  config: AntdMessage.config,
  useMessage: AntdMessage.useMessage,
  info: getTypeMethod(<ConfirmInfo />),
  success: getTypeMethod(<ConfirmSuccess />),
  error: getTypeMethod(<ConfirmError />),
  warning: getTypeMethod(<ConfirmWarning />),
  loading: getTypeMethod(<img className={styles.loadingImg} src={loadingSrc} />),
};

export default message;
