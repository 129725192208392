export default {
  autoCreateType: "自动生成字段类型",
  autoCreateName: "自动生成字段名称",
  autoCreateAlias: "自动生成别名",
  autoConfigData: "自动配置数据",
  cancelConfirmTitle: "已完成的 AI 配置部分将被保留",
  cancelConfirmContent1: "已经生成的字段名称和别名将不会被清除",
  cancelConfirmContent2: "确认终止 AI 配置？",
  okText: "确认",
  modalTitle: "AI 配置",
  modalTitleTip: "DataGPT 将根据数据列的名称自动生成对应的字段名称并自动匹配适合搜索的别名",
  errorRequestConfigLoopText: "AI 配置请求出错，请稍后重试",
  emptyColumnToast: "当前没有需要 AI 配置的数据列",
  waiting: "等待",
  errorEmptyAliasList: "自动生成别名失败",
  fail: "失败",
};
