import React from "react";

const ConfirmDeleteToastIcon = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08049 2.26426C5.61344 1.41163 4.70773 0.833496 3.66699 0.833496C2.14821 0.833496 0.916992 2.06471 0.916992 3.5835C0.916992 4.75394 1.6482 5.7536 2.67873 6.15058C2.13635 7.32107 1.83366 8.62527 1.83366 10.0002C1.83366 15.0628 5.93772 19.1668 11.0003 19.1668C16.0629 19.1668 20.167 15.0628 20.167 10.0002C20.167 8.62527 19.8643 7.32107 19.3219 6.15058C20.3525 5.7536 21.0837 4.75394 21.0837 3.5835C21.0837 2.06471 19.8524 0.833496 18.3337 0.833496C17.2929 0.833496 16.3872 1.41163 15.9202 2.26426C14.4987 1.35837 12.8108 0.833496 11.0003 0.833496C9.18983 0.833496 7.50193 1.35837 6.08049 2.26426ZM13.9402 7.06031C14.2982 7.4183 14.2982 7.9987 13.9402 8.35668L12.2967 10.0002L13.9402 11.6436C14.2982 12.0016 14.2982 12.582 13.9402 12.94C13.5822 13.298 13.0018 13.298 12.6438 12.94L11.0003 11.2965L9.35684 12.94C8.99886 13.298 8.41846 13.298 8.06048 12.94C7.7025 12.582 7.7025 12.0016 8.06048 11.6436L9.70396 10.0002L8.06048 8.35668C7.7025 7.9987 7.7025 7.4183 8.06048 7.06031C8.41846 6.70233 8.99886 6.70233 9.35684 7.06031L11.0003 8.7038L12.6438 7.06031C13.0018 6.70233 13.5822 6.70233 13.9402 7.06031Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ConfirmDeleteToastIcon;
