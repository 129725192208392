import React from "react";

const ChartSelectBtn = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.2 5.6H3.37143C4.03143 5.6 4.57143 6.14 4.57143 6.8V12.8C4.57143 13.46 4.03143 14 3.37143 14H3.2C2.54 14 2 13.46 2 12.8V6.8C2 6.14 2.54 5.6 3.2 5.6ZM8 2C8.66 2 9.2 2.54 9.2 3.2V12.8C9.2 13.46 8.66 14 8 14C7.34 14 6.8 13.46 6.8 12.8V3.2C6.8 2.54 7.34 2 8 2ZM12.8 8.85714C13.46 8.85714 14 9.39714 14 10.0571V12.8C14 13.46 13.46 14 12.8 14C12.14 14 11.6 13.46 11.6 12.8V10.0571C11.6 9.39714 12.14 8.85714 12.8 8.85714Z"
      fill="currentColor"
    />
  </svg>
);
export default ChartSelectBtn;
