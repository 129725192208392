import { WdBreakType, WdCollapseDirection } from "./constants";

export default class WPSBasic {
  //  Application 对象会在 WPS 加载项创建时自动注入到浏览器的执行上下文环境，调用 WPS 的接口都需要依赖这个对象
  static app = window.Application;

  // 该对象代表当前激活的文档
  static activeDocument = this.app?.ActiveDocument;

  // 代表文档中选定的内容或插入点
  static selection = this.app?.Selection;

  // 获取添加内容的区域
  static getDefaultRange = () => {
    // 默认在光标位置添加
    const range = this.selection.Range;
    // 折叠该区域（若未折叠，新增内容将替换该区域）
    range.Collapse(WdCollapseDirection.wdCollapseEnd);
    return range;
  };

  // 将文档的插入点设置到指定位置
  static setSelection = range => this.selection.SetRange(range, range);

  static insertLineBreak = (range = this.getDefaultRange()) => range.InsertBreak(WdBreakType.wdLineBreak);

  // 将 WPS 集合转换为数组
  static collectionToArray = collection => {
    const result = [];
    const count = collection.Count;
    for (let i = 1; i <= count; i++) {
      result.push(collection.Item(i));
    }
    return result;
  };
}
