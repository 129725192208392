export default {
  copySuccessToast: "Copied",
  copyFailToast: "Copy unsuccessfully, please try again later",
  copy: "Copy",
  edit: "Edit",
  loseDataColTip: "The data column is missing, you can deselect the column.",
  firstGuideStep: "Connect to data source",
  noDataBaseAuthTip: "You do not have database connection permissions, please contact the person in charge to complete this step",
  secondGuideStep: "Create model",
  secondGuideStepDesc: "Create a star model centered on the fact table",
  guide: "Tutorial",
  thirdGuideStep: "Push keywords & build dictionary (auto trigger) ",
  thirdGuideStepDesc: "The ARCTIC DATA needs to synchronize the dimension values in the database and build a dictionary",
  guideFinish: "You can search for data when the task is completed!",
  guideWelcomeText: "Welcome to Arctic Data!",
  guideBeginText: "Complete the following steps and you can enjoy searching for data ~",
  advancedFunctionGuide: "Other advanced functions can be consulted in the",
  instruction: "Instruction manual",
  skipNewGuide: "Skip the user manual",
  newGuide: "User manual",
  noAuth: "Sorry, you have no permission~",
  noAuthTip: "Sorry, you have no permission at the moment, please see other pages",
  titleInputPlaceholder: "Title name cannot be empty",
  descInputPlaceholder: "Please enter a profile",
  cannotEditTooltip: "Modification is not supported for the time being",
};
