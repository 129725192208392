import { Radio as AntdRadio } from "antd";
import { RadioGroupProps, RadioProps } from "antd/es/radio";
import React from "react";

import styles from "./index.module.scss";

const Radio: React.FC<RadioProps> & {
  Group: typeof AntdRadio.Group;
  Button: typeof AntdRadio.Button;
} = ({ className, disabled, ...rest }) => {
  let radioClassName = `${styles.a} ${styles.b} ${styles.radio}`;
  if (disabled) radioClassName += ` ${styles.radioDisabled}`;
  if (className) radioClassName += ` ${className}`;
  return <AntdRadio disabled={disabled} className={radioClassName} {...rest} />;
};

const Group: React.FC<RadioGroupProps> = ({ className, disabled, ...rest }) => {
  let radioGroupClassName = `${styles.a} ${styles.b} ${styles.radioGroup}`;
  if (disabled) radioGroupClassName += ` ${styles.radioGroupDisabled}`;
  if (className) radioGroupClassName += ` ${className}`;
  return <AntdRadio.Group disabled={disabled} className={radioGroupClassName} {...rest} />;
};

Radio.Group = Group as typeof AntdRadio.Group;
Radio.Button = AntdRadio.Button;

export default Radio;
