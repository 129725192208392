import React from "react";
import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.ModelConfigInfo.${id}`);

export default class ModelConfigInfo {
  // 工作区 暂无引用
  // WORKSPACE: "工作区",
  // WORKSPACE_DES: "用户用来搜索数据、创建报告的项目单元。",

  /** ********************************************************* 模型列表页面 ********************** */
  // 事实表
  static FACT_TABLE = "factTable";

  static FACT_TABLE_DES = "factTableDes";

  // 时间维度
  static TIME_DIMENSION = "timeDimension";

  static TIME_DIMENSION_DES = "timeDimensionDes";

  // 维度
  static DIMENSION = "dimension";

  static DIMENSION_DES = "dimensionDes";

  // 指标
  static INDICATOR = "indicator";

  static INDICATOR_DES = "indicatorDes";

  // 模型
  static MODEL = "model";

  static MODEL_DES = "modelDes";

  // 星型模型  暂无引用
  // STAR_MODEL: "星型模型",
  // STAR_MODEL_DES: "为了使用自然语言搜索和快速数据查找，所建立规则下的数据检索范式。",

  // 维度表
  static DIMENSION_TABLE = "dimensionTable";

  static DIMENSION_TABLE_DES = "dimensionTableDes";

  /** ********************************************************* 表配置 (事实表 + 维度表) ********************** */
  // 工作区字段类型
  static WORKSPACE_FIELD_TYPE = "workspaceFieldType";

  static WORKSPACE_FIELD_TYPE_DES = "workspaceFieldTypeDes";

  static getWorkspaceFieldTypeDes = () => (
    <div>
      {getText("workspaceFieldTypeDes")}
      <br />
      {getText("workspaceFieldTypeDesOfTimeDimension")}
      <br />
      {getText("workspaceFieldTypeDesOfDimension")}
      <br />
      {getText("workspaceFieldTypeDesOfIndicator")}
    </div>
  );

  // 数据列
  static DATA_COLUMN = "dataColumn";

  static DATA_COLUMN_DES = "dataColumnDes";

  // 字段搜索名称(旧：工作区字段名)
  static FIELD_NAME_FOR_SEARCH = "fieldNameForSearch";

  static FIELD_NAME_FOR_SEARCH_DES = "fieldNameForSearchDes";

  static FIELD_NAME_FOR_SEARCH_INPUT_TIP = "fieldNameForSearchInputTip";

  // 数据类型
  static DATA_TYPE = "dataType";

  static DATA_TYPE_DES = "dataTypeDes";

  // 展示格式
  static DISPLAY_FORMAT = "displayFormat";

  static DISPLAY_FORMAT_DES = "displayFormatDes";

  // 数据类型
  static CURRENCY_CODE = "currencyCode";

  static CURRENCY_CODE_DES = "currencyCodeDes";

  // 默认时间单位(旧：时间单位)
  static DEFAULT_TIME_UNIT = "defaultTimeUnit";

  // 维度值可搜索
  static FIELD_CONTENT_FOR_SEARCH = "fieldContentForSearch";

  static FIELD_CONTENT_FOR_SEARCH_DES = "fieldContentForSearchDes";

  // 计算方式 Polymerization way
  static POLYMERIZATION_WAY = "polymerizationWay";

  static POLYMERIZATION_WAY_DES = "polymerizationWayDes";

  // 数值类型
  static DEFAULT_ORDER = "defaultOrder";

  static DEFAULT_ORDER_DES = "defaultOrderDes";

  /** ********************************************************* 表配置 弹窗  ********************** */
  // 添加全局过滤条件
  static ADD_GLOBAL_FILTER = "addGlobalFilter";

  static ADD_GLOBAL_FILTER_DES = "addGlobalFilterDes";

  // 搜索词限制（“维度”其他-配置 项）
  static SEARCH_WORDS_LIMITS = "searchWordsLimits";

  static SEARCH_WORDS_LIMITS_DES = "searchWordsLimitsDes";

  // 搜索维度补全（“维度”其他-配置 项）
  static SEARCH_DIMENSION_PLUS = "searchDimensionPlus";

  static SEARCH_DIMENSION_PLUS_DES = "searchDimensionPlusDes";

  // 搜索结果自动分组（“维度”其他-配置 项）
  static AUTO_GROUP_BY_SEARCH_RESULT = "autoGroupBySearchResult";

  static AUTO_GROUP_BY_SEARCH_RESULT_DES = "autoGroupBySearchResultDes";

  // 数据类型-映射(旧：数据类型-选项)
  static DATA_TYPE_MAPPING = "dataTypeMapping";

  static DATA_TYPE_MAPPING_DES = "dataTypeMappingDes";

  // 时间轴刻度单位（“时间维度”其他-配置 项）
  static TIMELINE_SCALE_UNIT = "timelineScaleUnit";

  static TIMELINE_SCALE_UNIT_DES = "timelineScaleUnitDes";

  /** ********************************************************* 配置表关系弹窗 ********************** */
  // 表的关联关系
  static THE_TABLE_RELATIONSHIP = "theTableRelationship";

  static THE_TABLE_RELATIONSHIP_DES = "theTableRelationshipDes";

  // 关联列
  static RELATED_COLUMNS = "relatedColumns";

  static RELATED_COLUMNS_DES = "relatedColumnsDes";

  /** ********************************************************* 模型列表首页 数据库连接状态 ********************** */
  // 数据库刷新中
  static DATABASE_UPDATE_LOADING = "databaseUpdateLoading";

  static DATABASE_UPDATE_LOADING_DES = "databaseUpdateLoadingDes";

  // 刷新数据库
  static UPDATE_DATABASE = "updateDatabase";

  static UPDATE_DATABASE_DES = "updateDatabaseDes";

  // 数据库已连通
  static DATABASE_CONNECT_SUCCESS = "databaseConnectSuccess";

  static DATABASE_CONNECT_SUCCESS_DES = "databaseConnectSuccessDes";

  // 数据库正在尝试连接。请稍候。
  static DATABASE_CONNECT_LOADING = "databaseConnectLoading";

  static DATABASE_CONNECT_LOADING_DES = "databaseConnectLoadingDes";

  static DATABASE_START_CONNECT = "databaseStartConnect";

  // 数据库连接失败
  static DATABASE_CONNECT_FAIL = "databaseConnectFail";

  // 数据连接未设置
  static DATABASE_CONNECT_UN_SET = "databaseConnectUnSet";

  // 别名
  static SYNONYMS = "synonyms";

  static SYNONYMS_DES = "synonymsDes";

  // 维度值
  static CONTENT_KEYWORD = "contentKeyword";

  static CONTENT_KEYWORD_DES = "contentKeywordDes";

  static CONTENT_KEYWORD_SYNONYMS = "contentKeywordSynonyms";

  static CONTENT_KEYWORD_SYNONYMS_DES = "contentKeywordSynonymsDes";

  static getContentKeywordSynonymsDes = () => (
    <div>
      {getText("contentKeywordSynonymsDes")}
      <br />
      {getText("contentKeywordSynonymsDesSame")}
      <br />
      {getText("contentKeywordSynonymsDesOther")}
      <br />
      {getText("contentKeywordSynonymsDesCharacter")}
    </div>
  );

  static APPLIED_MODEL = "appliedModel";

  static APPLIED_MODEL_DES = "appliedModelDes";

  static RECOMMEND_RATING = "recommendRating";

  static RECOMMEND_RATING_DES = "recommendRatingDes";

  /** ********************************************************* 模型列表首页 数据表 ********************** */
  static TABLE_TYPE = "tableType";

  static TABLE_TYPE_DES = "tableTypeDes";

  static getTableTypeDes = () => (
    <div>
      <div>{getText("tableTypeDesCustomTable")}</div>
      <div>{getText("tableTypeDesDatabaseTable")}</div>
    </div>
  );

  static APPLY_MODEL = "applyModel";

  static APPLY_MODEL_DES = "applyModelDes";

  static getText = t => {
    if (t === this.FACT_TABLE) return getText("factTable");
    if (t === this.FACT_TABLE_DES) return getText("factTableDes");
    if (t === this.TIME_DIMENSION) return getText("timeDimension");
    if (t === this.TIME_DIMENSION_DES) return getText("timeDimensionDes");
    if (t === this.DIMENSION) return getText("dimension");
    if (t === this.DIMENSION_DES) return getText("dimensionDes");
    if (t === this.INDICATOR) return getText("indicator");
    if (t === this.INDICATOR_DES) return getText("indicatorDes");
    if (t === this.MODEL) return getText("model");
    if (t === this.MODEL_DES) return getText("modelDes");
    if (t === this.DIMENSION_TABLE) return getText("dimensionTable");
    if (t === this.DIMENSION_TABLE_DES) return getText("dimensionTableDes");
    if (t === this.WORKSPACE_FIELD_TYPE) return getText("workspaceFieldType");
    if (t === this.WORKSPACE_FIELD_TYPE_DES) return this.getWorkspaceFieldTypeDes();
    if (t === this.FIELD_NAME_FOR_SEARCH) return getText("fieldNameForSearch");
    if (t === this.DATA_COLUMN) return getText("dataColumn");
    if (t === this.DATA_COLUMN_DES) return getText("dataColumnDes");
    if (t === this.FIELD_NAME_FOR_SEARCH) return getText("fieldNameForSearch");
    if (t === this.FIELD_NAME_FOR_SEARCH_DES) return getText("fieldNameForSearchDes");
    if (t === this.FIELD_NAME_FOR_SEARCH_INPUT_TIP) return getText("fieldNameForSearchInputTip");
    if (t === this.DATA_TYPE) return getText("dataType");
    if (t === this.DATA_TYPE_DES) return getText("dataTypeDes");
    if (t === this.DISPLAY_FORMAT) return getText("displayFormat");
    if (t === this.DISPLAY_FORMAT_DES) return getText("displayFormatDes");
    if (t === this.CURRENCY_CODE) return getText("currencyCode");
    if (t === this.CURRENCY_CODE_DES) return getText("currencyCodeDes");
    if (t === this.DEFAULT_TIME_UNIT) return getText("defaultTimeUnit");
    if (t === this.FIELD_CONTENT_FOR_SEARCH) return getText("fieldContentForSearch");
    if (t === this.FIELD_CONTENT_FOR_SEARCH_DES) return getText("fieldContentForSearchDes");
    if (t === this.POLYMERIZATION_WAY) return getText("polymerizationWay");
    if (t === this.POLYMERIZATION_WAY_DES) return getText("polymerizationWayDes");
    if (t === this.DEFAULT_ORDER) return getText("defaultOrder");
    if (t === this.DEFAULT_ORDER_DES) return getText("defaultOrderDes");
    if (t === this.ADD_GLOBAL_FILTER) return getText("addGlobalFilter");
    if (t === this.ADD_GLOBAL_FILTER_DES) return getText("addGlobalFilterDes");
    if (t === this.SEARCH_WORDS_LIMITS) return getText("searchWordsLimits");
    if (t === this.SEARCH_WORDS_LIMITS_DES) return getText("searchWordsLimitsDes");
    if (t === this.SEARCH_DIMENSION_PLUS) return getText("searchDimensionPlus");
    if (t === this.SEARCH_DIMENSION_PLUS_DES) return getText("searchDimensionPlusDes");
    if (t === this.AUTO_GROUP_BY_SEARCH_RESULT) return getText("autoGroupBySearchResult");
    if (t === this.AUTO_GROUP_BY_SEARCH_RESULT_DES) return getText("autoGroupBySearchResultDes");
    if (t === this.DATA_TYPE_MAPPING) return getText("dataTypeMapping");
    if (t === this.DATA_TYPE_MAPPING_DES) return getText("dataTypeMappingDes");
    if (t === this.TIMELINE_SCALE_UNIT) return getText("timelineScaleUnit");
    if (t === this.TIMELINE_SCALE_UNIT_DES) return getText("timelineScaleUnitDes");
    if (t === this.THE_TABLE_RELATIONSHIP) return getText("theTableRelationship");
    if (t === this.THE_TABLE_RELATIONSHIP_DES) return getText("theTableRelationshipDes");
    if (t === this.RELATED_COLUMNS) return getText("relatedColumns");
    if (t === this.RELATED_COLUMNS_DES) return getText("relatedColumnsDes");
    if (t === this.DATABASE_UPDATE_LOADING) return getText("databaseUpdateLoading");
    if (t === this.DATABASE_UPDATE_LOADING_DES) return getText("databaseUpdateLoadingDes");
    if (t === this.UPDATE_DATABASE) return getText("updateDatabase");
    if (t === this.UPDATE_DATABASE_DES) return getText("updateDatabaseDes");
    if (t === this.DATABASE_CONNECT_SUCCESS) return getText("databaseConnectSuccess");
    if (t === this.DATABASE_CONNECT_SUCCESS_DES) return getText("databaseConnectSuccessDes");
    if (t === this.DATABASE_CONNECT_LOADING) return getText("databaseConnectLoading");
    if (t === this.DATABASE_CONNECT_LOADING_DES) return getText("databaseConnectLoadingDes");
    if (t === this.DATABASE_START_CONNECT) return getText("databaseStartConnect");
    if (t === this.DATABASE_CONNECT_FAIL) return getText("databaseConnectFail");
    if (t === this.DATABASE_CONNECT_UN_SET) return getText("databaseConnectUnSet");
    if (t === this.SYNONYMS) return getText("synonyms");
    if (t === this.SYNONYMS_DES) return getText("synonymsDes");
    if (t === this.CONTENT_KEYWORD) return getText("contentKeyword");
    if (t === this.CONTENT_KEYWORD_DES) return getText("contentKeywordDes");
    if (t === this.CONTENT_KEYWORD_SYNONYMS) return getText("contentKeywordSynonyms");
    if (t === this.CONTENT_KEYWORD_SYNONYMS_DES) return this.getContentKeywordSynonymsDes();
    if (t === this.APPLIED_MODEL) return getText("appliedModel");
    if (t === this.APPLIED_MODEL_DES) return getText("appliedModelDes");
    if (t === this.RECOMMEND_RATING) return getText("recommendRating");
    if (t === this.RECOMMEND_RATING_DES) return getText("recommendRatingDes");
    if (t === this.TABLE_TYPE) return getText("tableType");
    if (t === this.TABLE_TYPE_DES) return this.getTableTypeDes();
    if (t === this.APPLY_MODEL) return getText("applyModel");
    if (t === this.APPLY_MODEL_DES) return getText("applyModelDes");
  };
}
