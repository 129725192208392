import React from "react";
import Button from "@/components/datarc/Button";
import { getLocaleText } from "@/utils/locale";

import upgradeVersionLogo from "@/assets/png/upgradeVersionLogo.png";

import styles from "./index.module.scss";

const ReleaseUpdatedTip: React.FC = () => {
  return (
    <div className={styles.main}>
      <div className={styles.text}>
        <img src={upgradeVersionLogo} alt="" />
        <span>{getLocaleText("utils.versionUpdatedTip")}</span>
      </div>
      <Button
        type="primary"
        size="mini"
        className={styles.button}
        onClick={() => {
          window.location.reload();
        }}
      >
        {getLocaleText("utils.versionUpdatedTipRefresh")}
      </Button>
    </div>
  );
};

export default ReleaseUpdatedTip;
