export default {
  currentPreviewTable: "当前预览表:",
  currentTableNotSelected: "当前表: 未选择",
  sampleData: "数据示例",
  metadata: "元数据",
  fieldName: "字段名",
  originalDataType: "原始数据类型",
  fieldContent: "字段内容",
  databaseHasChanged: "数据库已发生变化，请重新编辑该自定义表",
  loadingFailed: "加载失败,",
  reload: "重新加载",
  loadingPleaseWait: "加载中，请稍候……",
  canSwitchToMetadata: "您也可以切换预览模式为【元数据】",
  toPreview: "请点击左侧表名进行预览",
  loadError: "自定义表查询出错",
  retryBtnText: "重试",
  editCustomTableBtnText: "编辑自定义表",
  checkSQLTip: "请重试或检查修改SQL",
};
