import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.ModelCanvasNodeType.${id}`);
export default class ModelCanvasNodeType {
  static FACT = "fact";

  static TABLE = "table";

  static DIMENSION = "dimension";

  static getText = t => {
    if (t === this.FACT) return getText("factTable");
    if (t === this.TABLE) return getText("dimensionTable");
    if (t === this.DIMENSION) return getText("dimension");
    return "";
  };
}
