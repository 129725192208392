export default {
  rowPermission: "Line permission",
  permissionOffTip: "Permission is not enabled, there are no restrictions when users search for row data",
  saveSuccessToast: "Row permission configuration updated successfully",
  enableConfirmTitle: "Confirm to enable row permissions?",
  enableConfirmTip:
    "After opening, you need to configure the permission content and update it, otherwise it may cause no data in the search results",
  satisfyAll: "Final permission must meet all conditions",
  satisfyAny: "The final permission must meet any condition",
  method: "Control mode",
  whitelistTip: "Only Whitelist content can be used",
  blacklistTip: "Blacklisted content will be disabled",
  selectPlaceholder: "Please select",
  field: "Controlled field",
  selectFieldErrMsg: "Please select controlled field",
  condition: "Control type",
  selectConditionErrMsg: "Please select the control type",
  equalTip: "Accurate match, controlled when it is exactly the same as the content below",
  containTip: "Fuzzy Match, fields containing the following content are controlled",
  content: "Controlled Content",
  contentTip: "Please enter the content to be added to the list, separated by Enter key",
  operations: "Settings",
  editBtnText: "Edit",
  deleteBtnText: "Delete",
  deleteConfirmTitle: "Confirm to delete this item?",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  addBtnText: "New",
  saveBtnText: "Update",
  editModalTitle: "Edit row permission control",
  addModalTitle: "Add row permission control",
};
