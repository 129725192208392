import React from "react";

const Search = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.08301 0.333496C13.6394 0.333496 17.333 4.02715 17.333 8.5835C17.333 10.5312 16.6581 12.3213 15.5293 13.7326L18.8979 17.102C19.2558 17.46 19.2558 18.0404 18.8979 18.3983C18.5674 18.7288 18.0474 18.7542 17.6878 18.4746L17.6015 18.3983L14.2321 15.0298C12.8208 16.1585 11.0307 16.8335 9.08301 16.8335C4.52666 16.8335 0.833008 13.1398 0.833008 8.5835C0.833008 4.02715 4.52666 0.333496 9.08301 0.333496ZM9.08301 2.16683C5.53918 2.16683 2.66634 5.03967 2.66634 8.5835C2.66634 12.1273 5.53918 15.0002 9.08301 15.0002C12.6268 15.0002 15.4997 12.1273 15.4997 8.5835C15.4997 5.03967 12.6268 2.16683 9.08301 2.16683Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);
export default Search;
