export default {
  keywordUpdatingAlertMessage: "Keywords updating, search result may not be accurate",
  needSyncKeywordMsg:
    "There are unsynchronized updates to the keywords, and there may be errors in the search results. Please contact the administrator to synchronize the keywords",
  emptyTipPart1: "Start searching! ",
  emptyTipPart2: "Click here ",
  emptyTipPart3: "for search guide.",
  day: "Day",
  week: "Week",
  month: "Month",
  quarter: "Quarter",
  year: "Year",
  second: "Second",
  minute: "Minute",
  hour: "Hour",
  customTimeRangeTitle: "Custom Time Range",
  former: "Days ago",
  to: "To",
  apply: "Apply",
  between: "Between",
  now: "Now",
  inputInteger: "Please enter an integer",
  defaultSearchPromptConfirmLabel: "Do you want to set this as default search hint?",
  formerChangeSearchPromptConfirmTip: "You changed search hint multiple times, you can also go to",
  changeSearchPromptLinkText: "Search Tip settings",
  latterChangeSearchPromptConfirmTip: "To update default search hint",
  noPromptNext: "Do not show this again",
  setAsDefault: "Set as default",
  notNeed: "Not now",
  saveSuccessToast: "Saved successfully",
  searchHistory: "Search history",
  topSearch: "Top search",
  noFieldTip: "Keyword not found",
  checkAllFields: "Check all keywords",
  keyboardOperation: "Keyboard Shortcuts",
  switchToQuestionPrompt: "Switch to question suggestions",
  switchToFieldPrompt: "Switch to keyword suggestions",
  microphoneErrorToast: "Can not access microphone, error message:",
  loadingText: "Please wait...",
  listening: "Listening",
  addIntro: "Add description",
  pinboard: "Dashboard",
  externalResource: "External Resource",
  changeProjectBtnText: "switch workspace",
  unConfigReadyErrorTip: "The workspace is not fully configured yet, and searching is not supported at the moment. You can try ",
  noDataPermissionTip: "No data permissions in the current workspace. Please contact the administrator or try again ",
  noModelErrorTip: "There are currently no valid models in this workspace. Please contact the administrator. You can try ",
  segmentServiceUnReadyErrorTip:
    "The word segmentation service is not configured, and data search is temporarily unavailable. Please try contacting the operations or deployment personnel for assistance, or ",
  segmentServiceConnectFailErrorTip:
    "Failed to connect to the word segmentation service, and data search is temporarily unavailable. Please try contacting the operations or deployment personnel for assistance, or ",
  segmentServiceBuildingErrorTip:
    "The word segmentation dictionary is being built, and data search is temporarily unavailable. Please contact the workspace owner or administrator to synchronize search terms, or ",
};
