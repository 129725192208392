import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.RuleNode.${id}`);

export default class RuleStepType {
  static TEMPLATE = "template";

  static REPLACE = "replace";

  static DELETE = "delete";

  static choices = [RuleStepType.TEMPLATE, RuleStepType.REPLACE, RuleStepType.DELETE];

  static getText = t => {
    if (t === RuleStepType.TEMPLATE) return getText("addLabel");
    if (t === RuleStepType.REPLACE) return getText("replaceLabel");
    if (t === RuleStepType.DELETE) return getText("deleteLabel");
  };
}
