export default {
  basicSettings: "基础设置",
  memberConfig: "成员配置",
  user: "用户",
  userGroup: "用户组",
  searchModel: "搜索模型",
  model: "模型",
  dimension: "维度",
  quota: "指标",
  customDataset: "自定义数据集",
  keyWord: "关键词同步任务",
  synonym: "别名与同义词",
  otherConfig: "其他配置",
  customTimePeriod: "自定义时间周期",
  externalResource: "外部资源",
  dataTags: "日期标签",
  advancedConfig: "进阶配置",
  rules: "场景鹿",
  feedback: "语义校准规则",
  assetManagement: "资源管理",
};
