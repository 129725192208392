export default {
  customDataset: "Custom dataset",
  customDatasetTip:
    "You can predefine datasets here that meet certain conditions and search directly using keywords (e.g., user groups marked with different labels in marketing)",
  searchInputPlaceholder: "Please enter the information you want to search",
  addBtnText: "New",
  addSuccessToast: "Create successfully",
  deleteSuccessToast: "Delete successfully",
  saveSuccessToast: "Modified successfully",
  editBtnText: "Edit",
  deleteBtnText: "Delete",
  deleteConfirmTitle: "Confirm the deletion of this custom dataset?",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  keywords: "Keywords",
  keywordsInputPlaceholder: "Please fill in the keywords that can be used to search for this dataset during search",
  question: "Question for marking conditions",
  source: "Source model",
  dimension: "Selected dimension",
  operator: "Operator",
  updatedAt: "Modified",
  operations: "Settings",
  userInputLabel: "User input",
  okBtnText: "Save",
  createCustomDatasetSuccessTip: "Successfully created custom dataset",
  updateCustomDatasetSuccessTip: "Successfully updated custom dataset",
  datasetConditionLabel: "Dataset conditions",
  sourceModel: "Source model",
  questionLabel: "Questions for marking conditions",
  questionInputPlaceholder: "Please fill in the questions for marking conditions",
  tryQuestionButtonText: "Test run question",
  dimensionLabel: "Selected dimensions of the dataset",
  dimensionSelectPlaceholder: "Please select after test run",
  datasetPreview: "Dataset preview",
};
