export default {
  okText: "Got it",
  demoteModalTitle: "User is a Workspace Administrator",
  demoteModalContent:
    "After being demoted to a General Data Explorer, the user will automatically become a Workspace User instead of a Workspace Administrator.",
  notChangeOptionLabel: "Do not change",
  resetOptionLabel: "Reset",
  passwordExtraMessage: "Administrators can force a password reset for users",
  noUserPasswordExtraMessage: "Please enter the initial password for the account",
  userPasswordLabel: "User Password",
  passwordInputPlaceholder: "Please enter a password",
  primaryPasswordLabel: "Initial Password",
  confirm: "Confirm",
  cancel: "Cancel",
  addUser: "Add User",
  editUser: "Edit User",
  nickNameLabel: "User Name",
  nickNameHelp: "Please enter the user's name",
  userNameLabel: "Login Name",
  userNameHelp: "Used for login. If left blank, the system will generate a string automatically",
  userNameInputPlaceholder: "Please enter a login name",
  phoneLabel: "Phone Number",
  phoneHelp: "Optional. After entering, the user can log in using a phone verification code",
  phoneInputPlaceholder: "Please enter a phone number",
  emailLabel: "Email",
  emailHelp: "Optional. After entering, the user can log in using an email verification code",
  emailInputPlaceholder: "Please enter an email",
  userDescLabel: "User Description",
  userDescHelp: "Optional. A brief introduction of the user",
  userDescInputPlaceholder: "Please enter a user description, such as position or department",
  userRoleLabelTitle: "User Role",
  userRoleLabelContent: "Divided into Business User, System Administrator, and Auditor. Only Business Users occupy quota resources",
  userRoleRequiredMessage: "Please select a user role",
  accountTypeLabelTitle: "Account Type",
  firstAccountTypeIntro: "Different accounts can use different functions of the workspace",
  secondAccountTypeIntro: "Data Expert: All functions of the workspace (including workspace management)",
  thirdAccountTypeIntro: "General Data Scientist: Search, view, data download, and report functions",
  fourthAccountTypeIntro: "General Data Explorer: Only has search and view functions",
  linkText: "Click to view details",
  accountTypeRequiredMessage: "Please select an account type",
  accountPropsLabelTitle: "Account Properties",
  accountPropsLabelContent:
    "Divided into Formal and Temporary. Temporary accounts are valid for 3 months from the activation date. After expiration, they will automatically enter a dormant state and can be reactivated.",
  accountPropsRequiredMessage: "Please select an account property",
  createBusinessUserTitle: "Create Business User",
  createAdminUserTitle: "Create System Administrator",
  createAuditorUserTitle: "Create Auditor",
  alertFieldText: "Demote",
};
