import { ButtonType } from "antd/lib/button";
import AntdModal, { ModalFuncProps, ModalProps } from "antd/lib/modal";
import React, { ReactNode } from "react";
import { CloseOutlined, ConfirmError, ConfirmInfo, ConfirmSuccess, ConfirmWarning } from "@/components/SVGIcons";
import Button from "../Button";
import { getLocaleText } from "../utils/locale";

import styles from "./index.module.scss";

const OriginModal: React.FC<ModalProps> = ({
  className,
  cancelText = getLocaleText("cancelText"),
  okText = getLocaleText("okText"),
  okType = "primary",
  cancelButtonProps = {},
  okButtonProps = {},
  onCancel = e => e,
  onOk = e => e,
  ...rest
}) => {
  const modalClassName = `${styles.a} ${styles.b} ${styles.modal} ${className}`;

  const footer = (
    <>
      <Button onClick={onCancel} {...cancelButtonProps}>
        {cancelText}
      </Button>
      <Button onClick={onOk} type={okType as ButtonType} {...okButtonProps}>
        {okText}
      </Button>
    </>
  );

  return <AntdModal footer={footer} onCancel={onCancel} className={modalClassName} closeIcon={<CloseOutlined />} zIndex={1000} {...rest} />;
};

const Modal = OriginModal as typeof AntdModal;

Modal.confirm = (config: ModalFuncProps) => {
  let mergedIcon = config.icon;
  if (!config.icon && config.icon !== null) mergedIcon = <ConfirmWarning />; // "", undefined,false,.... 或者不传
  if (config.title) {
    config.title = getModalTitle(mergedIcon, config.title);
    config.icon = null;
  } else config.icon = mergedIcon;
  config.cancelText = config.cancelText || getLocaleText("cancelText");
  config.okText = config.okText || getLocaleText("confirmOkText");
  config.transitionName = "";
  config.maskTransitionName = "";
  config.className = `${styles.a} ${styles.b} ${styles.modalConfirm} ${config.title ? "" : styles.noTitleConfirm} ${config.className}`;
  return AntdModal.confirm(config);
};

const getModalTitle = (icon: ReactNode, title: ReactNode): ReactNode => (
  <div className={styles.confirmTitle}>
    {icon}
    {title}
  </div>
);

const getTypeConfirmRender = (icon: ReactNode) => (config: ModalFuncProps) => {
  config.title = getModalTitle(config.icon || icon, config.title);
  config.okText = config.okText || getLocaleText("confirmOkText");
  config.className = `${styles.a} ${styles.b} ${styles.modalConfirm} ${config.className}`;
  config.transitionName = "";
  config.maskTransitionName = "";
  config.icon = null;
  return AntdModal.info(config);
};

Modal.info = getTypeConfirmRender(<ConfirmInfo />);

Modal.success = getTypeConfirmRender(<ConfirmSuccess />);

Modal.warning = getTypeConfirmRender(<ConfirmWarning />);

Modal.error = getTypeConfirmRender(<ConfirmError />);

Modal.destroyAll = AntdModal.destroyAll;

export type { ModalFuncProps, ModalProps };

export default Modal;
