export default {
  aiFill: "AI 配置",
  autoFill: "智能填充",
  descriptionFill: "填充数据库注释",
  fieldFill: "填充数据列名称",
  uploadFill: "手动上传",
  noFill: "不填充",
  autoFillDesc: "根据规则智能填充",
  descriptionFillDesc: "数据库中的数据列注释填入字段名",
  fieldFillDesc: "数据列名称填入字段名",
  noFillDesc: "选择后会将当前的填充内容置空",
  uploadFillDesc: "根据上传的 csv 文件内容进行填充",
};
