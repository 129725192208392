export enum TourStepId {
  // 新手引导当前步骤
  IS_BEGINNER_TOUR = "isBeginnerTour", // 新手引导

  WELCOME = "welcome", // 欢迎页

  CHANGE_PROJECT = "changeProject", // 切换工作区

  SEARCH_SUGGESTIONS = "SearchSuggestions", // 搜索建议

  // 校准反馈引导
  FEEDBACK = "feedback", // 校准反馈引导

  FEEDBACK_ENTRY = "feedbackEntry", // 校准反馈入口

  FEEDBACK_TYPE = "feedbackType", // 校准反馈选择

  FEEDBACK_CONFIRM = "feedbackConfirm", // 校准反馈提交

  // 筛选器引导模块
  FILTER = "filter", // 筛选器引导

  FILTER_GUIDE = "filterGuide", // 筛选器弹框引导

  FILTER_WORD_GUIDE = "filterWordGuide", // 筛选器内容引导

  FILTER_ICON_GUIDE = "filterIconGuide", // 筛选器筛选图标引导

  // 模糊匹配模块
  FUZZY = "fuzzy", // 模糊匹配

  FUZZY_ENTRY = "fuzzyEntry", // 模糊匹配入口

  FUZZY_TYPE = "fuzzyType", // 模糊结果类型

  FUZZY_CONFIRM = "fuzzyConfirm", //模糊结果确认

  FUZZY_CANCEL = "fuzzyCancel", // 取消模糊

  // 搜索框占比编辑模块
  PROPORTION = "proportion", // 搜索框占比编辑

  PROPORTION_ENTRY = "proportionEntry", // 占比编辑入口

  PROPORTION_DRAG = "proportionDrag", // 占比拖拽引导

  PROPORTION_RESULT = "proportionResult", // 占比结果显示

  PROPORTION_CHART = "proportionChart", // 占比示意图

  // 搜索页卡片按钮引导
  CHART_CARD = "chartCard", // 图表卡片

  INSIGHT_BTN = "insightBtn", // 数据解释按钮

  TREND_FORECAST_BTN = "trendForecastBtn", // 预测按钮

  SWITCH_CHART_BTN = "switchChartBtn", // 切换图表按钮

  DATA_INSIGHT = "dataInsight", // 数据洞察卡片

  DATA_INSIGHT_DETAIL = "dataInsightDetail", // 数据洞察查看详情 popover
}

// 新手引导状态
export enum TourStatus {
  WAIT = "wait",

  START = "start",

  SKIP = "skip",

  FINISH = "finish",

  ERROR = "error",
}

// 新手引导操作类型
export enum TourType {
  NEXT = "next",

  SKIP = "skip",

  NEW = "new",

  STOP = "stop",
}

export default class Tour {
  static STEP = TourStepId;

  static STATUS = TourStatus;

  static TYPE = TourType;
}
