import { getLocaleText } from "@/utils/locale";

import animationBar from "@/assets/svg/animationBar.svg";
import barIcon from "@/assets/svg/bar.svg";
import bar3DIcon from "@/assets/svg/bar3DIcon.svg";
import boxPlotIcon from "@/assets/svg/boxPlot.svg";
import bubbleIcon from "@/assets/svg/bubble.svg";
import funnelIcon from "@/assets/svg/funnel.svg";
import heatmapIcon from "@/assets/svg/heatmap.svg";
import histogramIcon from "@/assets/svg/histogram.svg";
import horizontalBarIcon from "@/assets/svg/horizontalBar.svg";
import lineIcon from "@/assets/svg/line.svg";
import mapIcon from "@/assets/svg/map.svg";
import mixedBarLineIcon from "@/assets/svg/mixedBarLine.svg";
import mixedStackLineIcon from "@/assets/svg/mixedStackLine.svg";
import paretoIcon from "@/assets/svg/pareto.svg";
import percentStackIcon from "@/assets/svg/percentStack.svg";
import pieIcon from "@/assets/svg/pie.svg";
import pivotTableIcon from "@/assets/svg/pivotTable.svg";
import sankeyIcon from "@/assets/svg/sankey.svg";
import scatterIcon from "@/assets/svg/scatter.svg";
import stackIcon from "@/assets/svg/stack.svg";
import stackAreaIcon from "@/assets/svg/stackArea.svg";
import tableIcon from "@/assets/svg/table.svg";
import wordCloudIcon from "@/assets/svg/wordCloud.svg";

const getText = id => getLocaleText(`components.Constants.CardToggle.${id}`);

export default class ChartType {
  // 图表字符串  按照产品prd顺序 16个
  static LINE = "line";

  static LINE_NAME = "lineChart";

  static BAR = "bar";

  static BAR_NAME = "barChart";

  static STACK = "stack";

  static STACK_NAME = "stackBarChart";

  static PIE = "pie";

  static PIE_NAME = "pieChart";

  static TABLE = "table";

  static TABLE_NAME = "table";

  static BUBBLE = "bubble";

  static BUBBLE_NAME = "bubbleChart";

  static SCATTER = "scatter"; // scatter

  static SCATTER_NAME = "scatterChart";

  static HEATMAP = "heatmap";

  static HEATMAP_NAME = "heatMap";

  static SANKEY = "sankey";

  static SANKEY_NAME = "sankeyChart";

  static FUNNEL = "funnel";

  static FUNNEL_NAME = "funnelChart";

  static WORD_CLOUD = "wordCloud";

  static WORD_CLOUD_NAME = "wordCloud";

  static STACK_AREA = "stackArea";

  static STACK_AREA_NAME = "stackAreaChart";

  static MIXED_BAR_LINE = "mixedBarLine";

  static MIXED_BAR_LINE_NAME = "mixedBarLineChart";

  static HORIZONTAL_BAR = "horizontalBar";

  static ANIMATION_BAR = "animationBar";

  static HORIZONTAL_BAR_NAME = "horizontalBarChart";

  static MIXED_STACK_LINE = "mixedStackLine";

  static MIXED_STACK_LINE_NAME = "mixedStackLineChart";

  static PERCENT_STACK = "percentStack";

  static PERCENT_STACK_NAME = "percentStackChart";

  static PIVOT = "pivot";

  static PIVOT_TABLE = "pivottable";

  static PIVOT_TABLE_NAME = "pivotTable";

  static MAP = "map";

  static MAP_NAME = "map";

  static MEASURE_CARD = "measureCard";

  static GAUGE = "gauge";

  static BOX_PLOT = "boxPlot";

  static PARETO = "pareto"; // 帕累托图

  static PARETO_NAME = "paretoChart";

  static BAR_3D = "bar3D";

  static HISTOGRAM = "histogram";

  // 基本可以展现的图表类型，对应图表交互选择图表面板顺序
  static getChartTypeNameMapping = () => ({
    [ChartType.LINE]: getText(ChartType.LINE_NAME), // 折线图
    [ChartType.BAR]: getText(ChartType.BAR_NAME), // 柱状图
    [ChartType.MIXED_BAR_LINE]: getText(ChartType.MIXED_BAR_LINE_NAME), // 柱折混合图
    [ChartType.HORIZONTAL_BAR]: getText(ChartType.HORIZONTAL_BAR_NAME), // 条形图
    [ChartType.STACK]: getText(ChartType.STACK_NAME), // 堆叠柱状图
    [ChartType.PERCENT_STACK]: getText(ChartType.PERCENT_STACK_NAME), // 百分比堆叠图
    [ChartType.MIXED_STACK_LINE]: getText(ChartType.MIXED_STACK_LINE_NAME), // 堆叠折线 混合图
    [ChartType.STACK_AREA]: getText(ChartType.STACK_AREA_NAME), // 堆叠面积图
    [ChartType.SANKEY]: getText(ChartType.SANKEY_NAME), // 桑基图
    [ChartType.PIE]: getText(ChartType.PIE_NAME), // 饼图
    [ChartType.WORD_CLOUD]: getText(ChartType.WORD_CLOUD_NAME), // 词云
    [ChartType.BUBBLE]: getText(ChartType.BUBBLE_NAME), // 气泡图 (旧：散点图）
    [ChartType.SCATTER]: getText(ChartType.SCATTER_NAME), // 散点图（旧：数值散点图）
    [ChartType.HEATMAP]: getText(ChartType.HEATMAP_NAME), // 热力图
    [ChartType.FUNNEL]: getText(ChartType.FUNNEL_NAME), // 漏斗图
    [ChartType.TABLE]: getText(ChartType.TABLE_NAME), // 表格
    [ChartType.PIVOT_TABLE]: getText(ChartType.PIVOT_TABLE_NAME), // 透视表
    [ChartType.MAP]: getText(ChartType.MAP_NAME), // 地图
    [ChartType.PARETO]: getText(ChartType.PARETO_NAME), // 帕累托图
    [ChartType.BOX_PLOT]: getText(ChartType.BOX_PLOT), // 盒须图
    [ChartType.BAR_3D]: getText(ChartType.BAR_3D), // 3D 柱状图
    [ChartType.HISTOGRAM]: getText(ChartType.HISTOGRAM), // 直方图
    [ChartType.ANIMATION_BAR]: getText(ChartType.ANIMATION_BAR), // 动态条形图
  });

  static ChartTypeImgMapping = {
    [ChartType.LINE]: lineIcon, // 折线图
    [ChartType.BAR]: barIcon, // 柱状图
    [ChartType.MIXED_BAR_LINE]: mixedBarLineIcon, // 柱折混合图
    [ChartType.HORIZONTAL_BAR]: horizontalBarIcon, // 条形图
    [ChartType.STACK]: stackIcon, // 堆叠柱状图
    [ChartType.MIXED_STACK_LINE]: mixedStackLineIcon, // 堆叠折线 混合图
    [ChartType.STACK_AREA]: stackAreaIcon, // 堆叠面积图
    [ChartType.SANKEY]: sankeyIcon, // 桑基图
    [ChartType.PIE]: pieIcon, // 饼图
    [ChartType.PERCENT_STACK]: percentStackIcon, // 百分比堆叠图
    [ChartType.WORD_CLOUD]: wordCloudIcon, // 词云
    [ChartType.BUBBLE]: bubbleIcon, // 气泡图 (旧：散点图）
    [ChartType.SCATTER]: scatterIcon, // 散点图（旧：数值散点图）
    [ChartType.HEATMAP]: heatmapIcon, // 热力图
    [ChartType.FUNNEL]: funnelIcon, // 漏斗图
    [ChartType.TABLE]: tableIcon, // 表格
    [ChartType.PIVOT_TABLE]: pivotTableIcon, // 透视表
    [ChartType.MAP]: mapIcon, // 地图
    [ChartType.BOX_PLOT]: boxPlotIcon,
    [ChartType.PARETO]: paretoIcon, // 帕累托图
    [ChartType.BAR_3D]: bar3DIcon, // 3D 柱状图
    [ChartType.HISTOGRAM]: histogramIcon, // 直方图
    [ChartType.ANIMATION_BAR]: animationBar, // 动态条形图
  };

  static ONE_DIMENSION_ONE_MEASURE = "needOneDimensionOneMeasure";

  static ONE_DIMENSION_TWO_MEASURE = "needOneDimensionTwoMeasures";

  static TWO_DIMENSION_ONE_MEASURE = "needTwoDimensionsOneMeasure";

  static TWO_DIMENSION_TWO_MEASURE = "needTwoDimensionsTwoMeasures";

  static THREE_ITEM = "needThreeItems";

  static ONE_AREA_DIMENSION_ONE_MEASURE = "needOneAreaDimensionOneMeasure";

  static PIE_LIKED_CHART_HOVER_TEXT = "pieLikedChartHoverText";

  // hover 显示的提示文字
  static getChartTypeHintTips = key => {
    const mapping = {
      [ChartType.LINE]: getText(this.ONE_DIMENSION_ONE_MEASURE), // 折线图
      [ChartType.BAR]: getText(this.ONE_DIMENSION_ONE_MEASURE), // 柱状图
      [ChartType.HORIZONTAL_BAR]: getText(this.ONE_DIMENSION_ONE_MEASURE), // 条形图
      [ChartType.MIXED_BAR_LINE]: getText(this.ONE_DIMENSION_TWO_MEASURE), // 柱折混合图
      [ChartType.BOX_PLOT]: getText(this.ONE_DIMENSION_ONE_MEASURE), // 箱型图
      [ChartType.PARETO]: getText("paretoChartHoverText"), // 帕累托图

      [ChartType.BAR_3D]: getText(this.TWO_DIMENSION_ONE_MEASURE), // 3D 柱状图

      [ChartType.ANIMATION_BAR]: getText("animationBarChartHoverText"), // 动态柱状图

      [ChartType.MIXED_STACK_LINE]: getText(this.TWO_DIMENSION_TWO_MEASURE), // 堆叠折线 混合图
      [ChartType.STACK_AREA]: getText("stackAreaChartHoverText"), // 堆叠面积图
      [ChartType.STACK]: getText(this.TWO_DIMENSION_ONE_MEASURE), // 堆叠柱状图
      [ChartType.PERCENT_STACK]: getText("stackPercentChartHoverText"), // 百分比堆叠柱状图

      [ChartType.FUNNEL]: getText(this.PIE_LIKED_CHART_HOVER_TEXT), // 漏斗图
      [ChartType.PIE]: getText(this.PIE_LIKED_CHART_HOVER_TEXT), // 饼图
      [ChartType.WORD_CLOUD]: getText(this.ONE_DIMENSION_ONE_MEASURE), // 词云
      [ChartType.SANKEY]: getText(this.TWO_DIMENSION_ONE_MEASURE), // 桑基图

      [ChartType.SCATTER]: getText(this.ONE_DIMENSION_ONE_MEASURE), // 散点图（旧：数值散点图）
      [ChartType.BUBBLE]: getText(this.THREE_ITEM), // 气泡图 (旧：散点图）
      [ChartType.HEATMAP]: getText(this.TWO_DIMENSION_ONE_MEASURE), // 热力图

      [ChartType.PIVOT_TABLE]: getText(this.TWO_DIMENSION_ONE_MEASURE), // 透视表
      [ChartType.TABLE]: "", // 表格

      [ChartType.MAP]: getText(this.ONE_AREA_DIMENSION_ONE_MEASURE), // 地图
    };
    return mapping[key];
  };

  static BASIC_CHART = [
    this.LINE,
    this.BAR,
    this.MIXED_BAR_LINE,
    this.HORIZONTAL_BAR,
    this.STACK,
    this.MIXED_STACK_LINE,
    this.STACK_AREA,
    this.PERCENT_STACK,
    this.PARETO,
    this.HISTOGRAM,
  ];

  static AUTO_INSIGHT_TYPES = [this.LINE, this.BAR];

  static PIE_LIKED_BASIC_CHART = [this.PIE, this.SANKEY, this.WORD_CLOUD, this.FUNNEL];

  static VALUE_SCATTER_CHART = [this.SCATTER, this.BUBBLE, this.HEATMAP, this.BOX_PLOT, this.BAR_3D];

  static TABLE_CHART = [this.TABLE, this.PIVOT_TABLE];

  // 三维图
  static CHART_3D = [this.BAR_3D];

  // 没有 legend 的图
  static NO_LEGEND_CHART = [this.TABLE, this.PIVOT_TABLE, this.MAP];

  // 不能显示只有同时含有正负数指标的图
  static NO_ONLY_POS_AND_NEG_CHART = [this.PIE, this.FUNNEL, this.PERCENT_STACK];
}
