export default {
  modalTitle: "批量注销",
  selectFile: "选择文件",
  cancelText: "取消",
  nextBtnText: "下一步",
  firstStepWarningText: "注销操作不可逆，请谨慎操作",
  secondStepWarningText: "请再次确认需要注销的人员后点击下一步",
  uploadTitle: "请按要求上传文件",
  uploadTip1: "文件每行为需要注销用户的登录名",
  uploadTip2: "单个文件最多 2000 行",
  uploadTip3: "注：工作区负责人无法通过此方式注销。",
  uploadSuffixTip: "支持格式：.txt",
  uploadFormatTip: "支持编码格式：utf-8，gbk，ascii，utf-16",
  readFileError: "文件读取失败",
  noAccountError: "文件不包含有效用户名，请重新上传",
  confirmBtnText: "确认注销",
  loadingText: "注销中，请稍候……",
  loggingOutOverTipTitle: "批量注销已完成",
  confirm: "好的",
  loggingOutFailed: "注销失败",
  loggingOutFailedTip: "失败可能由于该用户是某工作区负责人或名下有未转移的资源，可尝试手动注销。",
};
