import { MarkerType } from "react-flow-renderer";

export default class ModelMarkerEndType {
  static DEFAULT = "default";

  static ACTIVE = "active";

  static getConfig = type => {
    if (type === this.DEFAULT) return { type: MarkerType.ArrowClosed, color: "#bfbfbf" };
    return { type: MarkerType.ArrowClosed, color: "#4759FD" };
  };
}
