export default {
  hasNewDataColumnsAdded: "有新增的数据列",
  isANewlyAddedColumn: "为新增列",
  missingWillAutomaticallyFillIn: "新增列造成的缺失值将会自动填充为null",
  dataHasNotBeenUploaded: "数据还未上传至系统，当前仅预览前25行",
  cancel: "取消",
  confirmUpload: "确定上传",
  dataTableUpdatedSuccessfully: "数据表更新成功",
  successfullyUploadedTheDataTable: "数据表上传成功",
};
