export default {
  custom: "自定义值",
  fixed: "固定值",
  max: "最大值",
  min: "最小值",
  average: "平均数",
  median: "中位数",
  percentile: "百分位",
  rank: "排名",
  calc: "计算值",
};
