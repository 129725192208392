import React from "react";

const EyeVisible = props => (
  <svg {...props} width="1em" height=".75em" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0.5C12.2291 0.5 15.0798 2.68917 17.5601 6.92916L17.7455 7.25101L18 7.7L17.5601 8.47084C15.0798 12.7108 12.2291 14.9 9 14.9C5.77094 14.9 2.92017 12.7108 0.439869 8.47084L0.254541 8.149L0 7.7L0.254541 7.25101C2.77787 2.8 5.69021 0.5 9 0.5ZM8.99998 2.30001C6.56721 2.30001 4.25544 4.024 2.07477 7.70001C4.25544 11.376 6.56721 13.1 8.99998 13.1C11.3667 13.1 13.616 11.4711 15.7383 8.01026L15.9253 7.70013L15.738 7.38981C13.6124 3.92693 11.3633 2.30001 8.99998 2.30001ZM8.99999 5.00001C10.4912 5.00001 11.7 6.20885 11.7 7.70001C11.7 9.19118 10.4912 10.4 8.99999 10.4C7.50882 10.4 6.29999 9.19118 6.29999 7.70001C6.29999 6.20885 7.50882 5.00001 8.99999 5.00001Z"
      fill="black"
      fillOpacity="0.65"
    />
  </svg>
);

export default EyeVisible;
