import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.AccountType.${id}`);

export default class AccountType {
  static TEMPORARY = 0;

  static NORMAL = 1;

  static choices = [this.NORMAL, this.TEMPORARY];

  // 数据专家不能是临时账户
  static tier1Choices = [this.NORMAL];

  static getText = type => {
    if (type === this.TEMPORARY) return getTextById("temporaryLabel");
    if (type === this.NORMAL) return getTextById("normalLabel");
    return "";
  };
}
