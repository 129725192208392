import React from "react";

const TriangleDown = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0202 13.9138L8.07497 10.5026C7.91482 10.3171 8.02824 10 8.25472 10H14.1453C14.3718 10 14.4852 10.3171 14.325 10.5026L11.3798 13.9138C11.2805 14.0287 11.1195 14.0287 11.0202 13.9138Z"
      fill="currentColor"
    />
  </svg>
);

export default TriangleDown;
