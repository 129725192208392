export default {
  dimension: "维度",
  measure: "指标",
  dateTag: "日期标签",
  analysis: "分析（内置）",
  filter: "筛选",
  dateRange: "时间范围",
  keyword: "关键词",
  fromDimension: "来自维度",
  category: "类型",
  synonyms: "同义词",
  description: "说明",
  model: "模型",
  models: "个模型",
  aliases: "同义词",
  polysemyTip: "一词多义字段，点击在菜单中选择",
  shortcut: "匹配字段",
  formula: "计算公式",
  semanticCalibrationRules: "语义校准规则",
  dimensionValue: "维度值",
  fuzzyEditModalTitle: "模糊：",
  fuzzyEditModalLeftTitle: "模糊结果类型",
  fuzzyEditModalRightTitle: "模糊结果确认",
  fuzzyEditModalRightTip: "仅支持模糊单一维度下的各维度值",
  fuzzy: "匹配了",
  fuzzyContain: "代表",
  polysemy: "你想问",
  pinboard: "报告",
  pinboardTip: "点击直接跳转报告，并带入问题中的筛选项",
  cancelFuzzyPrefix: "该词不需要模糊？点击",
  cancelFuzzy: "取消模糊",
  cancelFuzzyConfirmTitle: "取消模糊后，当前问题将不再对这个词分词（即将该词视为无意义的词）",
  deleteFuzzyLog: "删除模糊记录",
  deleteFuzzyLogConfirmTitle: "删除模糊记录后，所有对模糊结果确认的操作将失效，将回到初始的模糊匹配结果",
  polysemyEditModalTitle: "有多种含义：",
  polysemySubTittlePre: "你想问的是",
  polysemySubTittlePost: "作为：",
  dimensionMatchOptionSocket: "中的维度值",
  cancel: "取消",
  ok: "确定",
  confirmOk: "确认",
  fuzzyTipTitle: "请点击修改模糊匹配结果",
  fuzzyTipOrigin: "原词：",
  fuzzyMatch: "模糊匹配了：",
  proportion: "占比",
  proportionTitleTip: "搜索多个指标时，会按以下占比逻辑分别展示各指标占比",
  proportionModalFooterTip: "*注：占比示意图仅供理解，最终图表以实际结果为准",
  numerator: "分子",
  proportionDataGroup: "数据分组(Group by)",
  proportionDataGroupTip: "图表按分组拆成多个柱或饼",
  proportionFilter: "数据范围筛选",
  proportionFilterTip: "同时筛选分子分母",
  proportionExampleTitle: "最终搜索结果及占比示意",
  denominator: "分母",
  proportionNumeratorErrorTip: "分子需要至少有一个维度或维度值",
  proportionDataGroupErrorTip: "筛选只支持维度值",
  proportionFilterErrorTip: "数据分组只支持维度",
  example: "示例图",
  dataGroupDefaultTip: "请拖入维度",
  filterDefaultTip: "请拖入维度值",
  notOpenLLMTip: "尚未配置并启用大语言模型对话功能，请联系系统管理员配置",
  fuzzySkip: "好的",
  fuzzyNext: "立即查看",
  fuzzyEntry: "语义不明确的词语（有虚线标识）我们自动匹配了模糊的结果，你可以点击此处进行调整。",
  okBtn: "知道了",
  fuzzyType: "这里可以切换模糊结果的类型",
  fuzzyConfirm: "这里可以勾选你最后想要的结果",
  fuzzyCancel: "如果觉得当前的词不需要模糊可以点击【取消模糊】，如果觉得以上都不是你想要的结果你可以点击【校准反馈】选择更多的指标维度",
  proportionEntry: "占比的具体分子分母可以在这看到，你也可以点击此处调整占比的结构",
  proportionDrag: "最前方有标记的块可以拖拽到别的模块中，你可以按自己的需要拖拽组成最后的占比结构",
  proportionResult: "这里是最后的占比组成，你可以清晰的看到分子和分母分别是什么。如果有多个指标则其他指标也会采用同样的占比方式",
  proportionChart: "这里是占比结果的简略示意，帮助理解当前的占比结构，仅做参考",
  showMore: "展开",
  packUp: "收起",
  projectBlockTip: "推测你想问的数据不在此工作区，建议",
  aiFill: "AI 自动补充了",
  mathMeasure: "匹配指标：",
  delimiter: "，",
  fuzzyTip: "发现存在模糊词汇，自动匹配结果，可点击右侧块修改",
  questionPolysemyTip: "发现有词语存在多重含义，猜你想问",
  proportionTip: "问题中存在占比问法，自动选择分子分母，可点击右侧块修改",
  pinboardSearchTip: "匹配到系统已有报告，可点击查看",
  cancelContainPrefixText: "该词不使用包含逻辑？点击 ",
  cancelContainText: "取消包含",
  fuzzyCancelContainTip: "取消包含逻辑后，再次搜索该词时会自动使用模糊逻辑",
};
