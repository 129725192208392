import React from "react";

const RightArrow = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0081 3.65608L11.0774 3.57757C11.3778 3.27717 11.8505 3.25406 12.1774 3.50825L12.2559 3.57757L18.0893 9.41091L18.1238 9.44754L18.1697 9.50407L18.2295 9.59678L18.274 9.69072L18.3035 9.7785L18.3276 9.90175L18.3334 10.0002L18.331 10.0629L18.3165 10.1674L18.2919 10.2603L18.2553 10.3528L18.2117 10.4341L18.1503 10.5214L18.0893 10.5894L12.2559 16.4228C11.9305 16.7482 11.4029 16.7482 11.0774 16.4228C10.777 16.1223 10.7539 15.6497 11.0081 15.3227L11.0774 15.2442L15.4867 10.8335H2.50002C2.03978 10.8335 1.66669 10.4604 1.66669 10.0002C1.66669 9.53993 2.03978 9.16683 2.50002 9.16683H15.4884L11.0774 4.75609C10.777 4.45568 10.7539 3.98299 11.0081 3.65608L11.0774 3.57757L11.0081 3.65608Z"
      fill="currentColor"
    />
  </svg>
);

export default RightArrow;
