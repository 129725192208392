export default {
  sumPrefix: "Total",
  sumSuffix: "Term",
  unValidTip: "The keyword is invalid, there is no such keyword in the system thesaurus",
  columnOperation: "Settings",
  editBtnText: "Edit",
  deletePopoverTitle: "Confirm to delete the alias of this keyword?",
  deletePopoverDes: "Keywords will not be deleted",
  deleteBtnText: "Delete",
  deleteSuccessToast: "Delete successfully",
  uploadSuccessToast: "There is an alias or synonym modification, the system dictionary update task has been automatically started",
  uploadFailNote: "Data upload error",
  clearSuccessToast: "Clear alias library successfully",
  clearConfirmTitle: "Confirm emptying the alias library? This operation cannot be undone",
  clearConfirmSuggest: "It is recommended to download the full amount first for backup",
  downloadAllBtnText: "Full download",
  cancelBtnText: "Cancel",
  okBtnText: "Confirm",
  searchPlaceholder: "Please enter the information you want to search",
  batchUploadBtnText: "Batch upload",
  clearBtnText: "Clear",
  lastUpdateTime: "Last updated to the dictionary:",
  downloadFileName: "Alias template",
  downloadCSVFileName: "Alias List",
  editSuccessToast: "Modified successfully",
  batchUploadSuccessToast: "Batch upload successfully",
};
