export default {
  save: "保存",
  openSuccessToast: "已开启",
  closeSuccessToast: "已关闭",
  changeAuthSuccessToast: "修改用户权限成功",
  discoverTitle: "添加图表",
  createCopySuccessToast: "创建副本成功",
  createCopyFailToast: "创建副本失败，请稍后试一试",
  createCopy: "创建副本",
  confirm: "确定",
  cancel: "取消",
  editChartTitle: "编辑图表",
  saveChanges: "保存修改",
  giveUpChanges: "放弃修改",
  confirmExit: "当前有未保存图表样式修改，是否要保存修改后退出？",
  copyFailToast: "复制失败，请稍后重试",
  noShortUrl: "链接加载失败",
  copySuccessToast: "复制成功",
  copyLink: "复制链接",
  noUserPrompt: "您还没有邀请用户或用户组",
  modifyPinboardSuccessToast: "修改报告成功",
  saveErrorToast: "报告名称不能为空",
  pinboardInfo: "报告信息",
  pinboardTitle: "报告标题",
  rename: "重命名",
  owner: "负责人",
  createTime: "创建时间",
  updatedAt: "更新时间",
  description: "描述",
  tag: "标签",
  change: "修改",
  noDescription: "暂无描述",
  noTag: "暂无标签",
  renamePinboard: "重命名报告名称",
  changeDescription: "修改描述",
  currentMessage: "当前报告消息",
  clearUnread: "清除未读",
  message: "消息",
  changeUserAuthSuccessToast: "修改用户权限成功",
  addUserAuthSuccessToast: "新增用户权限成功",
  applyForOwner: "已向负责人发送申请",
  addSpecifyUser: "+ 指定用户",
  invite: "邀请",
  invitePrompt: "被邀请用户登录后访问链接即可查看内容。",
  shareToWorkspace: "分享至工作区",
  sharePrompt: "此工作区用户以【只读】的权限查看内容。",
  unSharePrompt: "未开启分享，仅负责人和协作者可查看内容。",
  authSetting: "权限设置",
  inviteSetting: "邀请设置",
  openToShare: "已开启分享",
  notOpenToShare: "未开启分享",
  sendMessage: "发送消息",
  manageVisitor: "管理访问者",
  allowSelfCanReadCreateFork: "允许 只读权限 的用户 创建副本",
  allowSelfCanEditShareAll: "允许 编辑权限 的用户 分享至工作区",
  noUserMatched: "没有找到用户或用户组，请输入其他关键词查询",
  noUserInWorkspace: "工作区没有用户或用户组，请在配置中创建",
  searchText: "搜索用户、用户组",
  complete: "完成",
  selectAll: "全选",
  volumeSetting: "批量设置",
  changeAuth: "修改权限",
  noUserAvailable: "暂时没有可设置的成员或成员组",
  disableEditTip: "选择了搜索账户，无法设置为编辑权限",
  share: "分享",
  next: "下一步",
  sendInvitation: "发送邀请",
  allow: "允许",
  selfCanRead: "只读权限",
  user: "的用户",
  selfCanEdit: "编辑权限",
  remove: "移除",
  allUsers: "所有用户、用户组",
  selectedUserPermissionSetting: "已选用户权限设置：",
  specifyUser: "指定用户",
  specifyUserModalTip: "用户实际浏览的与编辑的报告数据，以用户本人的数据权限为准",
  removeAuth: "移除权限",
  pinboardIntroInputPlaceholder: "请输入报告简介",
  replaceTitle: "替换卡片",
  cacheSettingSuccessToast: "设置成功",
  cacheSettingTitle: "缓存设置",
  cacheTask: "定时缓存任务",
  cacheScope: "对象设置",
  cacheScopeAll: "为有【报告权限】的所有人缓存",
  cacheScopeOpen: "仅为【打开过报告】的人缓存",
  cacheStartTime: "任务开始时间",
  cacheTTL: "缓存有效时间",
  cacheTips: "建议为在某些时段有大量查询需求的报告设置定时缓存，以缓解数据库压力",
  cacheHour: "小时",
  cacheDay: "天",
  yourAuthWillDowngrade: "你的权限将降为 只读",
  createTemplateTipTitle: "创建自定义模板方式，如下",
  createTemplate: "方式一：新建报告并转换为模板",
  transformPin: "方式二：打开已有文档，从更多中选择 “转换为模板",
  noTemplate: "暂无模板",
  templateLib: "模板库",
  searchEmpty: "查找无结果，请更换关键字",
  delTemplateTitle: "是否删除模板？",
  delTemplateAfter: "删除后，此报告从模板库移除，还原为普通报告",
  delTemplateButton: "删除模板",
  delTemplateSuccess: "删除模板成功",
  transformToTemplate: "转化为模版",
  searchTemplatePlaceholder: "输入关键词检索",
  template: "模板",
  allCollaborators: "报告所有协作者",
  chooseUser: "选择用户",
  drillDown: "向下钻取",
  browse: "浏览",
  errorMessage: "有不满足设置条件的数据权限的用户，请移除后重试",
  haveSelected: "已选",
};
