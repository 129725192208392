import React from "react";

const OpenIcon = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1C4.13437 1 1 4.13437 1 8C1 11.8656 4.13437 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13437 11.8656 1 8 1ZM8 13.8125C4.79062 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79062 4.79062 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79062 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
      <path
        d="M11.2406 7.79807L6.61407 4.43869C6.57696 4.41149 6.53303 4.39513 6.48717 4.39143C6.44131 4.38773 6.39532 4.39684 6.35434 4.41773C6.31335 4.43863 6.27897 4.4705 6.25503 4.50979C6.23109 4.54908 6.21853 4.59425 6.21875 4.64026V11.359C6.21875 11.5637 6.45 11.6793 6.61407 11.5606L11.2406 8.20119C11.2726 8.17818 11.2986 8.1479 11.3165 8.11285C11.3345 8.07781 11.3438 8.039 11.3438 7.99963C11.3438 7.96026 11.3345 7.92145 11.3165 7.88641C11.2986 7.85136 11.2726 7.82108 11.2406 7.79807V7.79807ZM7.21563 9.89182V6.10744L9.82032 7.99963L7.21563 9.89182V9.89182Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default OpenIcon;
