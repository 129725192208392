import React from "react";

const DownloadPNG = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      d="M14.6666 5.20665V9.26665L13.58 8.33331C13.06 7.88665 12.22 7.88665 11.7 8.33331L8.92665 10.7133C8.40665 11.16 7.56665 11.16 7.04665 10.7133L6.81998 10.5266C6.34665 10.1133 5.59331 10.0733 5.05998 10.4333L1.77998 12.6333L1.70665 12.6866C1.45998 12.1533 1.33331 11.52 1.33331 10.7933V5.20665C1.33331 2.77998 2.77998 1.33331 5.20665 1.33331H10.7933C13.22 1.33331 14.6666 2.77998 14.6666 5.20665Z"
      fill="currentColor"
    />
    <path
      d="M6 6.91998C6.87629 6.91998 7.58666 6.2096 7.58666 5.33331C7.58666 4.45702 6.87629 3.74664 6 3.74664C5.1237 3.74664 4.41333 4.45702 4.41333 5.33331C4.41333 6.2096 5.1237 6.91998 6 6.91998Z"
      fill="currentColor"
    />
    <path
      d="M14.6667 9.26669V10.7934C14.6667 13.22 13.22 14.6667 10.7933 14.6667H5.20666C3.50666 14.6667 2.28 13.9534 1.70667 12.6867L1.78 12.6334L5.06 10.4334C5.59333 10.0734 6.34666 10.1134 6.82 10.5267L7.04666 10.7134C7.56666 11.16 8.40666 11.16 8.92666 10.7134L11.7 8.33335C12.22 7.88669 13.06 7.88669 13.58 8.33335L14.6667 9.26669Z"
      fill="currentColor"
    />
  </svg>
);

export default DownloadPNG;
