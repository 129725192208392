import { getLocaleText } from "@/utils/locale";

const getTextLocale = id => getLocaleText(`components.Constants.ReferenceLineValue.${id}`);

export default class ReferenceLineValue {
  static CUSTOM_VALUE = "customValue"; // 自定义值

  static FIXED_VALUE = "fixedValue"; // 固定值

  static PERCENTILE = "percentile"; // 百分位

  static RANKING = "ranking"; // 排名

  static CALCULATE_VALUE = "calculateValue"; // 计算值

  static AVERAGE = "average"; // 平均数

  static MAX = "max"; // 最大值

  static MIN = "min"; // 最小值

  static MEDIAN = "median"; // 中位数

  static getText = value => {
    switch (value) {
      case this.CUSTOM_VALUE:
        return getTextLocale("custom");
      case this.FIXED_VALUE:
        return getTextLocale("fixed");
      case this.PERCENTILE:
        return getTextLocale("percentile");
      case this.RANKING:
        return getTextLocale("rank");
      case this.CALCULATE_VALUE:
        return getTextLocale("calc");
      case this.AVERAGE:
        return getTextLocale("average");
      case this.MAX:
        return getTextLocale("max");
      case this.MIN:
        return getTextLocale("min");
      case this.MEDIAN:
        return getTextLocale("median");
      default:
        return "";
    }
  };

  static customChoices = [this.FIXED_VALUE, this.PERCENTILE, this.RANKING];

  static calculateChoices = [this.AVERAGE, this.MAX, this.MIN, this.MEDIAN];

  // 参考线类型
  static getMenuConfigs = () => [
    {
      name: this.getText(this.CUSTOM_VALUE),
      value: this.CUSTOM_VALUE,
      children: this.customChoices.map(item => ({ name: this.getText(item), value: item, disableSelected: true })),
    },
    {
      name: this.getText(this.CALCULATE_VALUE),
      value: this.CALCULATE_VALUE,
      children: this.calculateChoices.map(item => ({ name: this.getText(item), value: item, disableSelected: true })),
    },
  ];
}
