import React from "react";

const Unfinished = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
    </svg>
  );
};

export default Unfinished;
