export default {
  name: "用户组名称",
  nameInputPlaceholder: "请输入用户组名称",
  nameInputErrMsg: "请输入用户组名称",
  description: "用户组介绍",
  descriptionInputPlaceholder: "请输入用户组介绍",
  numberUsers: "用户数",
  autoAddUsers: "自动添加用户",
  disableDownload: "禁止下载数据",
  on: "开启",
  off: "关闭",
  operations: "操作",
  settingsBtn: "设置",
  editBtnText: "成员与权限",
  deleteBtnText: "删除",
  deleteConfirmTitle: "确认删除此用户组？",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  addBtnText: "添加用户组",
  searchGroupInputPlaceholder: "请输入想搜索的用户组信息",
  addUserGroupModalTitle: "新增用户组",
  saveSuccessToast: "修改成功",
  groupOptionsModalTitle: "用户组设置",
};
