import "polyfill-object.fromentries";
import "dayjs/locale/zh-cn";
import dayjs from "dayjs";
import arraySupport from "dayjs/plugin/arraySupport";
import isLeapYear from "dayjs/plugin/isLeapYear";
import isoWeek from "dayjs/plugin/isoWeek";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import ReactDOM from "react-dom";
import { getLocale, ZH_CN } from "@/utils/locale";
import router from "./router";

import "normalize.css";
import "@/styles/globalBaseStyle.scss";
import "@/styles/reset.scss";
import "@/styles/globalScrollbar.scss";
import "@/styles/globalSelect.scss";
import "@/styles/antdLayout.scss";
import "@/styles/globalFontFamily.scss";
import "@/theme/theme.css";

dayjs.extend(localizedFormat); // 支持 ll 类似格式化写法
dayjs.extend(isSameOrAfter);
dayjs.extend(arraySupport);
dayjs.extend(quarterOfYear);
dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(weekOfYear);
dayjs.extend(localeData);

// 设置语言
window.locale = getLocale();

// 设置 dayjs 语言
switch (window.locale) {
  case ZH_CN: {
    dayjs.locale("zh-cn");
    break;
  }
  default:
    dayjs.locale("en");
}

const ICE_CONTAINER = document.getElementById("ice-container");

if (!ICE_CONTAINER) {
  throw new Error('当前页面不存在 <div id="ice-container"></div> 节点.');
}
// react-quill 暂不兼容 React18 的渲染方式，故继续使用原渲染语句
ReactDOM.render(router(), ICE_CONTAINER);
