import { notification } from "antd";
import axios from "axios";
import Auth, { axiosError } from "@/components/Auth";
import { getV2ChangeProjectUrl } from "@/components/Urls";
import { uuidV4 } from "@/utils/common";

const signal = axios.CancelToken.source();

export const changeProject = ({ projectId, callback, handleErrorFunc = axiosError }) => {
  if (!projectId) return;
  axios
    .post(getV2ChangeProjectUrl(), { id: projectId }, { cancelToken: signal.token })
    .then(r => {
      notification.destroy();
      Auth.setAuthData(r.data.data); // 更新用户的登录信息
      callback?.(r.data.data);
    })
    .catch(handleErrorFunc);
};

export const redirectToIndex = ({ match, setRefreshKey, history }) => {
  // 如果是当前是首页，强制刷新。否则跳转到 搜索页面
  match.url === "/" && match.isExact ? setRefreshKey(uuidV4()) : history.push("/");
};
