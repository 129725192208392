export default class CardType {
  // https://datarc.feishu.cn/wiki/wikcnfpnJTYQ92HM8GqGU6MYTSf

  static MEASURES = 1; // 测量值

  static LABELED_CHART = 3; // 带标签的图

  static CHART_TABLE_TOGGLE = 7; // 图和表格的混合结构

  static TREND_FORECAST = 8; // 趋势预测图

  static RANK = 12; // 第几名卡片，标题会根据查询结果生成，内容还是数值卡片。“营业额第三名的门店”

  static ATTRIBUTION_ANALYSIS = 13; // 贡献度分析

  static EXCEED = 15; // 越界类型卡片，趋势类卡片超出数据上限

  /*
   * 以下四个类型前端内部用，后端不会返回此字段，前后端交互中无下列字段
   */
  static CHART = 2; // 图

  static TABLE = 6; // 表格

  static ASSOCIATION = -1; // 问题关联卡片（你可能还想搜，点赞倒赞）

  /*
   * 以下类型以下类型为需要移除的过期类型
   */

  // static CHINA_MAP = 4; // 中国地图
  // static TAB = 5; // 标签
  // static HORIZONTAL_BAR = 10;
  // static MAP_CHART_TABLE_TOGGLE = 11; // 地图，图和表格的混合结构
  // static ANOMALY_CARD = 14; // 反常数据
  // static DIMENSION_ANALYSIS = 9; // 维度分析 下掉了

  // 显示日期偏移信息的卡片
  static showDateOffsetCards = [this.MEASURES, this.LABELED_CHART, this.CHART_TABLE_TOGGLE, this.RANK];
}
