import React from "react";

const ErrorStatusIcon = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00403 0C3.1384 0 0.00402832 3.13437 0.00402832 7C0.00402832 10.8656 3.1384 14 7.00403 14C10.8697 14 14.004 10.8656 14.004 7C14.004 3.13437 10.8697 0 7.00403 0ZM9.5884 9.65937L8.55715 9.65469L7.00403 7.80312L5.45247 9.65312L4.41965 9.65781C4.3509 9.65781 4.29465 9.60312 4.29465 9.53281C4.29465 9.50312 4.30559 9.475 4.32434 9.45156L6.35715 7.02969L4.32434 4.60937C4.30546 4.58647 4.29498 4.5578 4.29465 4.52812C4.29465 4.45937 4.3509 4.40312 4.41965 4.40312L5.45247 4.40781L7.00403 6.25937L8.55559 4.40937L9.58684 4.40469C9.65559 4.40469 9.71184 4.45937 9.71184 4.52969C9.71184 4.55937 9.7009 4.5875 9.68215 4.61094L7.65247 7.03125L9.68371 9.45312C9.70247 9.47656 9.7134 9.50469 9.7134 9.53437C9.7134 9.60312 9.65715 9.65937 9.5884 9.65937Z"
      fill="currentColor"
    />
  </svg>
);

export default ErrorStatusIcon;
