import React from "react";

const PinboardFilled = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 22C19.1046 22 20 21.1046 20 20V6.86347C20 6.29781 19.7605 5.7586 19.3407 5.3794L16.1711 2.51593C15.8035 2.18385 15.3258 2 14.8304 2H12H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18ZM7 9.5C7 8.94772 7.44772 8.5 8 8.5H16C16.5523 8.5 17 8.94772 17 9.5C17 10.0523 16.5523 10.5 16 10.5H8C7.44772 10.5 7 10.0523 7 9.5ZM8 13.5C7.44772 13.5 7 13.9477 7 14.5C7 15.0523 7.44772 15.5 8 15.5H12.2C12.7523 15.5 13.2 15.0523 13.2 14.5C13.2 13.9477 12.7523 13.5 12.2 13.5H8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PinboardFilled;
