// 此文件不需要进行国际化，已按照中英文进行区分
export default class DefaultData {
  static NULL = 1; // 无单位时的计算基数

  static K = 10 ** 3;

  static W = 10 ** 4;

  static M = 10 ** 6;

  static Y = 10 ** 8;

  static B = 10 ** 9;

  static T = 10 ** 12;

  // 中文单位
  static TEN_THOUSAND = "万";

  static TEN_THOUSAND_BASE = "w";

  static HUNDRED_MILLION = "亿";

  static HUNDRED_MILLION_BASE = "y";

  static TRILLION_ZH = "兆";

  // 英文单位
  static THOUSAND = "K"; // 千

  static THOUSAND_BASE = "k";

  static MILLION = "M"; // 百万

  static MILLION_BASE = "m";

  static BILLION = "B"; // 十亿

  static BILLION_BASE = "b";

  static TRILLION_EN = "T"; // 兆

  static TRILLION_BASE = "t";

  static UNITLESS = "null";

  static PERCENT = "%";

  static unitChoices = [
    DefaultData.THOUSAND,
    DefaultData.TEN_THOUSAND,
    DefaultData.MILLION,
    DefaultData.HUNDRED_MILLION,
    DefaultData.BILLION,
    DefaultData.TRILLION_ZH,
    DefaultData.TRILLION_EN,
    DefaultData.PERCENT,
  ];

  static unitToShorthandMap = {
    [this.THOUSAND]: this.THOUSAND_BASE,
    [this.TEN_THOUSAND]: this.TEN_THOUSAND_BASE,
    [this.HUNDRED_MILLION]: this.HUNDRED_MILLION_BASE,
    [this.MILLION]: this.MILLION_BASE,
    [this.BILLION]: this.BILLION_BASE,
    [this.TRILLION_ZH]: this.TRILLION_BASE,
    [this.TRILLION_EN]: this.TRILLION_BASE,
  };

  static powerToShorthandMap = {
    [this.K]: this.THOUSAND_BASE,
    [this.W]: this.TEN_THOUSAND_BASE,
    [this.Y]: this.HUNDRED_MILLION_BASE,
    [this.M]: this.MILLION_BASE,
    [this.B]: this.BILLION_BASE,
    [this.T]: this.TRILLION_BASE,
  };

  static getNumberPower = (type, defaultUnit = 1) => {
    switch (type) {
      case this.TEN_THOUSAND:
        return this.W;
      case this.HUNDRED_MILLION:
        return this.Y;
      case this.TRILLION_ZH:
        return this.T;
      case this.THOUSAND:
        return this.K;
      case this.MILLION:
        return this.M;
      case this.BILLION:
        return this.B;
      case this.TRILLION_EN:
        return this.T;
      default:
        return defaultUnit;
    }
  };

  static zhFormatterData = {
    [this.TEN_THOUSAND_BASE]: {
      // 万
      data: this.W,
      endStr: this.TEN_THOUSAND,
    },
    [this.HUNDRED_MILLION_BASE]: {
      // 亿
      data: this.Y,
      endStr: this.HUNDRED_MILLION,
    },
    [this.TRILLION_BASE]: {
      // 兆(T)
      data: this.T,
      endStr: this.TRILLION_ZH,
    },
  };

  static enFormatterData = {
    [this.THOUSAND_BASE]: {
      // 千(K)
      data: this.K,
      endStr: this.THOUSAND,
    },
    [this.MILLION_BASE]: {
      // 百万(M)
      data: this.M,
      endStr: this.MILLION,
    },
    [this.BILLION_BASE]: {
      // 十亿(B)
      data: this.B,
      endStr: this.BILLION,
    },
    [this.TRILLION_BASE]: {
      // 兆(T)
      data: this.T,
      endStr: this.TRILLION_EN,
    },
  };
}
