export default {
  unsupprotWebAudioApi: "浏览器不支持 WebAudioApi 相关接口",
  unsupportWebSocket: "浏览器不支持 WebSocket",
  connectingToTheNetwork: "网络连接中...",
  networkError: "网络似乎出现了点问题，请",
  retry: "重试",
  checkMicrophone: "请检查麦克风是否打开",
  goAhead: "请说",
  try: "试试：",
  identityFailure: "抱歉，未识别出语音内容，请",
  errorEnvironment: "当前环境无法获取浏览器录音功能，请在 HTTPS 环境中尝试。",
  lackOfRecordFunction: "暂无法获取浏览器录音功能，请升级浏览器或使用 Chrome。",
  mobileIdentityFailure: "抱歉，未识别出语音内容",
  mobileNetworkError: "网络似乎出现了点问题",
};
