import { Alert as AntdAlert } from "antd";
import { AlertProps as AntdAlertProps } from "antd/es/alert";
import React from "react";
import { CloseOutlined, ConfirmError, ConfirmInfo, ConfirmSuccess, ConfirmWarning } from "@/components/SVGIcons";

import "./index.module.scss";

const alertIcons = {
  info: <ConfirmInfo />,
  success: <ConfirmSuccess />,
  warning: <ConfirmWarning />,
  error: <ConfirmError />,
};

interface AlertProps extends AntdAlertProps {
  noBackground?: boolean;
}

const Alert: React.FC<AlertProps> & {
  ErrorBoundary: typeof AntdAlert.ErrorBoundary;
} = ({ className, noBackground, type, ...rest }) => {
  let alertClassName = `alertPrefixA alertPrefixB alert`;
  if (className) alertClassName += ` ${className}`;
  if (noBackground) alertClassName += ` noBackground`;

  return <AntdAlert icon={alertIcons[type]} closeIcon={<CloseOutlined />} type={type} className={alertClassName} {...rest} />;
};

Alert.ErrorBoundary = AntdAlert.ErrorBoundary;

export default Alert;
