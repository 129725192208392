export default {
  confirmToDeleteTheModel: "确定删除模型？",
  formerConfirmToDeleteTheModel: "确定删除模型【",
  latterConfirmToDeleteTheModel: "】？",
  deletedModelCannotBeRestored: "被删除的模型不可恢复。",
  confirm: "确定",
  cancel: "取消",
  selectFactTable: "选择事实表",
  exitAndDeleteModel: "退出并删除模型",
  goBackToThePreviousStep: "返回上一步",
  pleaseSelect1TableAsTheFactTable: "请选择1张表作为事实表",
  unselectedTables: "未选中的表将默认作为维度表",
};
