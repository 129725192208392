import React from "react";

const CloseOutlined = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7263 0.440367C12.4008 0.11493 11.8732 0.11493 11.5477 0.440367L6.83526 5.15284L2.12279 0.440368C1.79735 0.114931 1.26971 0.114931 0.944273 0.440368C0.618836 0.765805 0.618837 1.29344 0.944273 1.61888L5.65675 6.33136L0.944273 11.0438C0.618836 11.3693 0.618836 11.8969 0.944273 12.2223C1.26971 12.5478 1.79735 12.5478 2.12278 12.2223L6.83526 7.50987L11.5477 12.2223C11.8732 12.5478 12.4008 12.5478 12.7263 12.2223C13.0517 11.8969 13.0517 11.3693 12.7263 11.0438L8.01377 6.33136L12.7263 1.61888C13.0517 1.29344 13.0517 0.765804 12.7263 0.440367Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default CloseOutlined;
