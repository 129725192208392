import * as React from "react";
import { SVGProps } from "react";

const TemplateGrid = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={2.813} y={10.313} width={12.375} height={4.875} rx={0.563} stroke="currentColor" strokeWidth={1.125} />
    <rect x={2.813} y={2.813} width={7.125} height={5.625} rx={0.563} stroke="currentColor" strokeWidth={1.125} />
    <rect x={11.813} y={2.813} width={3.375} height={5.625} rx={0.563} stroke="currentColor" strokeWidth={1.125} />
  </svg>
);

export default TemplateGrid;
