import React from "react";

const EditBtn = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9584 5.03784L10.9592 5.037C11.5318 4.46422 11.3956 3.39933 10.6551 2.65851C9.96002 1.96315 8.97956 1.80047 8.38837 2.2568C8.34641 2.28256 8.30727 2.31333 8.27188 2.34872L2.14213 8.63154C2.05101 8.72494 2 8.85028 2 8.98079V10.9656C2 11.2417 2.22376 11.4656 2.49979 11.4656H4.47666C4.61131 11.4656 4.74026 11.4112 4.83432 11.3148L10.9584 5.03784Z"
      fill="currentColor"
    />
    <path
      d="M2.63333 12.7328C2.28355 12.7328 2 13.0165 2 13.3664C2 13.7163 2.28355 14 2.63333 14H13.3667C13.7164 14 14 13.7163 14 13.3664C14 13.0165 13.7164 12.7328 13.3667 12.7328H2.63333Z"
      fill="currentColor"
    />
  </svg>
);

export default EditBtn;
