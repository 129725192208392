import { Input as AntdInput } from "antd";
import { GroupProps, InputProps, InputRef, PasswordProps, SearchProps, TextAreaProps } from "antd/lib/input";
import React from "react";
import Password from "./Password";
import Search from "./Search";
import TextArea from "./TextArea";

import "./index.module.scss";

const InternalInput: React.FC<InputProps> = React.forwardRef(({ className, defaultValue, value, ...rest }, ref: React.Ref<InputRef>) => {
  let inputClassName = `inputPrefixA inputPrefixB input`;
  if (className) inputClassName += ` ${className}`;
  if (defaultValue || value) inputClassName += ` fillInput`;

  return <AntdInput ref={ref} className={inputClassName} defaultValue={defaultValue} value={value} {...rest} />;
});

const Input = InternalInput as typeof AntdInput;

Input.TextArea = TextArea;
Input.Group = AntdInput.Group;
Input.Search = Search;
Input.Password = Password;

export type { GroupProps, InputProps, InputRef, PasswordProps, SearchProps, TextAreaProps };

export default Input;
