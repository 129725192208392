export default class ForecastCardConstants {
  // 参数 key
  static TRAIN_RANGE = "trainRange";

  static FORECAST_RANGE = "forecastRange";

  static DEPENDENT_UNIT = "dependentUnit";

  static INDEPENDENT_UNITS = "independentUnits";

  static RMSE = "rmse";

  static MAE = "mae";

  static MSE = "mse";

  static MAPE = "mape";

  // 面板 key
  static ANALYSIS_PANEL_KEY = "analysisPanel"; // 预测解析

  static CONFIDENCE_INTERVAL_KEY = "confidenceInterval"; // 置信区间

  static MODEL_DESC_KEY = "modelDesc"; // 模型描述

  // 用户调参的四个数值
  static qualityParamKeys = [this.TRAIN_RANGE, this.FORECAST_RANGE, this.DEPENDENT_UNIT, this.INDEPENDENT_UNITS];

  // 质量度量的展示 table 列表
  static qualityKeys = [
    this.TRAIN_RANGE,
    this.FORECAST_RANGE,
    this.DEPENDENT_UNIT,
    this.INDEPENDENT_UNITS,
    this.RMSE,
    this.MAE,
    this.MSE,
    this.MAPE,
  ];
}
