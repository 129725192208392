export default class WatermarkVisitorDataIndex {
  static USERNAME = "username";

  static NICKNAME = "nickname";

  static EMAIL = "email";

  static PHONE = "phone";

  static DESC = "desc";

  static CUSTOM = "custom";

  static getText = v => {
    if (v === this.USERNAME) return "登录名";
    if (v === this.NICKNAME) return "用户名";
    if (v === this.EMAIL) return "邮箱";
    if (v === this.PHONE) return "手机号";
    if (v === this.DESC) return "用户简介";
    if (v === this.CUSTOM) return "自定义文案";
    return "";
  };

  static getOptions = () =>
    [this.USERNAME, this.NICKNAME, this.EMAIL, this.PHONE, this.DESC, this.CUSTOM].map(v => ({
      label: this.getText(v),
      value: v,
    }));
}
