export default {
  workspaceBasicInfo: "Workspace Basic Information",
  edit: "Edit",
  deleteWorkspace: "Delete Workspace",
  workspaceNumber: "ID",
  workspaceName: "Name",
  workspaceNameInputPlaceholder: "Please enter workspace name",
  workspaceOwner: "Owner",
  workspaceOwnerSelectPlaceholder: "Please select workspace owner",
  ownerEmail: "Owner Email",
  workspaceCreationTime: "Workspace Creation Time",
  configureUpdateTime: "Configuration Update Time",
  workspaceAdapter: "Datasource",
  workspaceIntroduction: "Description",
  hasNotSet: "Not set",
  dailyUpdateToTodayOffset: "Daily update to today offset",
  dataUpdateTime: "Data Update Time",
  dataUpdateTimeTip:
    "The user records the update time of the data here. When querying 'relative time', we will offset the latest time described by the update time to obtain the relative time. For example, the data is updated at 6 a.m. every day. Searching 'yesterday' before 6 a.m. on February 5 will return data on February 3, and searching 'yesterday' after 6 a.m. will return data on February 4.",
  inputNumberHelp: "Please enter an integer between 1-60",
  preciseWordSegmentationTimeLimit: "Precise Word Segmentation Time Limit",
  preciseWordSegmentationTip:
    "1. Precise word segmentation: Only when the words and keywords in the search question are exactly the same, will the system perform parsing and tagging.",
  preciseWordSegmentationTimeLimitTip:
    "2. Precise word segmentation time limit: The time reserved for accurate word segmentation when the system analyzes search questions. If it exceeds this time, the system will report an error.",
  preciseWordSegmentationInputPlaceholder: "Please enter the precise word segmentation time limit",
  fuzzyWordSegmentationTimeLimit: "Fuzzy Word Segmentation Time Limit",
  fuzzyWordSegmentationTip:
    "1. Fuzzy word segmentation: Words and keywords that are split out from the search question can be matched fuzzily. For example, when 'Adi' is mentioned in the question, it can be matched to 'Adidas' even if 'Adi' is not exactly the same as the keyword.",
  fuzzyWordSegmentationTimeLimitTip:
    "2. Fuzzy word segmentation time limit: The time reserved for fuzzy word segmentation when the system analyzes search questions. If it exceeds this time, the system will report an error.",
  fuzzyWordSegmentationInputPlaceholder: "Please enter the fuzzy word segmentation time limit",
  allowSearchingForFutureData: "Allow searching for future data",
  allowSearchingForFutureDataTip:
    "Future data refers to planned or predicted data entered into the database by some industries/companies. When turned off, only actual data from today and before can be searched.",
  allowSearchingForFutureDataExtra: "When turned off, only actual data from today and before can be searched.",
  deriveFill: "Derived indicators calculate empty values as 0.",
  deriveFillExtra1: "When turned on, the null value of the indicator used in the calculation of the derived indicator is treated as 0;",
  deriveFillExtra2:
    "When turned off, the final result of the calculation of a derivative indicator with a null value is also considered null.",
  enableDataBlend: "Data blending",
  enableDataBlendExtra: "Turn on this switch, users will be able to simultaneously search for data indicators in different models",
  dateBeforeContainsToday: "The last N periods will include current period",
  dateBeforeContainsTodayExtra1: "If on, search for the last 2 weeks, the result will include this week;",
  dateBeforeContainsTodayExtra2: "If off, the result is for the 2 weeks prior to this week (excluding this week).",
  automaticallyAddUsers: "Automatically add users",
  automaticallyAddUsersExtra: "When turned on, users newly added to the system will be automatically added to this workspace.",
  fuzzyDimensionValueThreshold: "Threshold for the number of dimension values to trigger fuzzy search",
  fuzzyDimensionValueThresholdExtra:
    "Based on the user's recent 30-day search history, dimension values will be added to the fuzzy dictionary one by one until the number reaches the threshold. Only words in the fuzzy dictionary can trigger fuzzy search.",
  collaborationBannedAll: "Banned for all users",
  collaborationAllowOwner: "Allowed for owners only",
  collaborationAllowManager: "Allowed for owners and managers only",
  collaborationAllowAll: "Allowed for all users",
  collaboration: "Collaboration restrictions",
  collaborationExtra:
    "Collaboration includes sharing reports with designated individuals or workspaces, creating report templates, and creating alerts for others.",
  collaborationTooltip:
    "Restricted users will not be able to initiate new report sharing, create report templates, or set alert recipients other than themselves. However, shared reports or created report templates will not be unshared or set to template status. Messages for already set alerts will still be pushed.",
  open: "Open",
  close: "Close",
  confirmDeletingWorkspace: "Confirm deletion of this workspace?",
  unableToRecoverAfterDeletion:
    "All information for this workspace will be permanently deleted and cannot be recovered. Please proceed with caution.",
  confirm: "Confirm",
  cancel: "Cancel",
  deleteSuccessToast: "Deleted successfully",
  modifySuccessToast: "Modified successfully",
  requiredItemsAreNotFilledIn: "Unable to save due to missing required information.",
  save: "Save",
};
