import React from "react";

const Filter = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.44809 4.14434C1.90016 3.49403 2.36245 2.5 3.21283 2.5H16.8245C17.6924 2.5 18.1483 3.52978 17.5648 4.17228L12.239 10.0369C12.0719 10.221 11.9793 10.4606 11.9793 10.7092V16.6541C11.9793 17.4071 11.1785 17.8899 10.5126 17.5385L8.56466 16.5106C8.23665 16.3375 8.03137 15.9971 8.03137 15.6262V11.136C8.03137 10.9002 7.94805 10.6719 7.79611 10.4916L2.44809 4.14434Z"
      fill="currentColor"
    />
  </svg>
);

export default Filter;
