import React from "react";

const ShareCard: React.FC = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      d="M5.1142 4.20065L12.2818 1.80878C15.5001 0.736015 17.2483 2.49217 16.1835 5.71046L13.7917 12.8781C12.1865 17.7016 9.54828 17.7016 7.94311 12.8781L7.23588 10.7485L5.10625 10.0413C0.290734 8.44403 0.290734 5.81377 5.1142 4.20065Z"
      fill="currentColor"
    />
    <path d="M9.09521 8.70643L12.1228 5.6709L9.09521 8.70643Z" fill="currentColor" />
    <path
      d="M9.09546 9.30216C8.94448 9.30216 8.79349 9.24654 8.6743 9.12734C8.44385 8.89689 8.44385 8.51547 8.6743 8.28502L11.6939 5.24949C11.9244 5.01905 12.3058 5.01905 12.5362 5.24949C12.7667 5.47994 12.7667 5.86137 12.5362 6.09181L9.51662 9.12734C9.39742 9.23859 9.24644 9.30216 9.09546 9.30216Z"
      fill="currentColor"
    />
  </svg>
);

export default ShareCard;
