export default {
  modalTitle: "选择模型",
  cancelBtnText: "取消",
  okBtnText: "完成",
  allCheckedHint: "*如后台有新增模型将自动勾选。",
  partCheckedHint: "*如后台有新增模型将不会自动勾选。",
  emptyModelWarnText: "因部分模型失效，当前未选中任何模型，如进行搜索则无法命中。",
  allCheckedHintInPinboard: "* 如报告内有新增的数据来源模型将自动勾选。",
  partCheckedHintInPinboard: "* 如报告内有新增的数据来源模型将不会自动勾选。",
  searchPlaceholder: "输入关键词查找",
  searchEmptyTip: "查找无结果，请更换关键字。",
  modelSuffix: "模型",
  saveSuccessToast: "保存成功",
};
