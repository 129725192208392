import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.ModelErrCode.${id}`);

export default class ModelErrCode {
  static MODEL_NOT_EXISTS = 1;

  static FACTOR_TABLE_NOT_EXISTS = 2;

  static FACTOR_TABLE_COLUMN_NOT_EXISTS = 3;

  static DIMENSION_TABLE_NOT_EXISTS = 4;

  static DIMENSION_TABLE_COLUMN_NOT_EXISTS = 5;

  static getText = status => {
    if (status === this.MODEL_NOT_EXISTS) return getTextById("missingModel");
    if (status === this.FACTOR_TABLE_NOT_EXISTS) return getTextById("missingFactTable");
    if (status === this.FACTOR_TABLE_COLUMN_NOT_EXISTS) return getTextById("missingFactTableColumn");
    if (status === this.DIMENSION_TABLE_NOT_EXISTS) return getTextById("missingDimensionTable");
    if (status === this.DIMENSION_TABLE_COLUMN_NOT_EXISTS) return getTextById("missingDimensionTableColumn");
    return "";
  };
}
