export default {
  forgetPassword: "Forget Password",
  submit: "Reset password and login",
  back: "Return",
  passwordInputPlaceholder: "Enter your password",
  inconsistentPassword: "Password doesn't match",
  reenterPassword: "Enter your password again",
  rememberAccountCheckboxText: "Remember Account",
  verificationCodeRequiredMessage: "Enter your verification code",
};
