export default {
  dateTag: "Date tag",
  dateTagTip: "When searching, you can use keywords to search for all dates with this tag",
  calendar: "Calendar view",
  settings: "Label Management",
  /* 日历视图 */
  tagName: "Tag name",
  timeRange: "Updated range",
  numberCycles: "Number of cycles included",
  status: "Status",
  on: "Enable",
  off: "Prohibited",
  operations: "Settings",
  viewBtnText: "Details",
  editBtnText: "Edit",
  disableBtnText: "Disabled",
  disableConfirmTitle: "Confirm disabling this tag?",
  enableBtnText: "Enable",
  enableConfirmTitle: "Confirm to enable this tag?",
  deleteBtnText: "Remove",
  deleteConfirmTitle: "Confirm to remove this tag and all typed date tags attributed to it?",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  systemTags: "System tags",
  customTags: "Custom Tags",
  searchTagInputPlaceholder: "Please enter the tag you want to search for",
  addBtnText: "Add custom tags",
  /* 标签管理 */
  resetBtnText: "Reset Calendar",
  resetConfirmTitle: "All tabs in the calendar view will be reset, are you sure to reset the calendar?",
  selectTagPlaceholder: "Select Tag",
};
