import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Modals.UploadSynonymFileModal.${id}`);

export default class UploadSynonymWords {
  static DOWNLOAD_FAIL = 1;

  static ANALYSIS_FAIL = 2;

  static INVALID_FAIL = 3;

  static ENCODE_FAIL = 4;

  static getText = v => {
    if (v === this.DOWNLOAD_FAIL) return getTextById("uploadErrMsg1");
    if (v === this.ANALYSIS_FAIL) return getTextById("uploadErrMsg2");
    if (v === this.INVALID_FAIL) return getTextById("uploadErrMsg3");
    if (v === this.ENCODE_FAIL) return getTextById("uploadErrMsg4");
    return "";
  };

  static getTitleText = v => {
    if (v === this.INVALID_FAIL) return getTextById("uploadErrMsgTitle2");
    return getTextById("uploadErrMsgTitle");
  };
}
