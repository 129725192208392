export default {
  tag: "标签",
  create: "新建",
  tagSelectPlaceholder: "请选择或新建标签",
  createFailed: "新建标签失败",
  tagManageTitle: "标签管理",
  tagSearchPlaceholder: "输入关键词检索",
  cancel: "取消",
  save: "确定",
  tableName: "标签名",
  pinboardNumber: "关联报告数",
  action: "操作",
  updateTagSuccess: "修改标签成功",
  updateTagFailed: "修改标签失败",
  deleteTagSuccess: "修改标签成功",
  deleteTagFailed: "修改标签失败",
  deleteConfirmTitle: "确认删除此标签？",
  deleteConfirmContent: "删除标签不影响报告本身",
  searchNoDataTip: "查找无结果，请更换关键字。",
};
