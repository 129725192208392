export default {
  22000: "数据异常",
  22001: "字串长度超出限制",
  22002: "NULL值不能做指示器参数",
  22003: "数字值超出范围",
  22004: "不允许NULL值",
  22005: "赋值中出错",
  22007: "非法日期时间格式",
  22008: "日期时间字段溢出",
  22009: "非法时区显示值",
  22012: "被零除",
  22013: "前后大小无效",
  22015: "内部字段溢出",
  22018: "类型转换时非法的字符值",
  22020: "非法限制值",
  22021: "字符不在准备好的范围内",
  22022: "指示器溢出",
  22023: "非法参数值",
  22026: "字串数据长度不匹配",
  23000: "执行的函数没有返回语句",
  23001: "违反限制",
  23502: "违反非空",
  23503: "违反外键约束",
  23505: "违反唯一约束",
  23514: "违反检查",
  24000: "非法游标状态",
  25000: "非法事务状态",
  25006: "只读的SQL事务",
  26000: "非法SQL语句名",
  39000: "外部过程调用异常",
  39004: "不允许空值",
  40000: "事务回滚",
  40002: "违反事务完整性约束",
  42000: "语法错误或者违反访问规则",
  42501: "权限不够",
  42601: "语法错误",
  42602: "非法名字",
  42611: "非法字段定义",
  42622: "名字太长",
  42701: "重复的字段",
  42702: "模糊的字段",
  42703: "未定义的字段",
  42704: "未定义对象",
  42710: "重复的对象",
  42712: "重复的别名",
  42803: "分组错误",
  42804: "数据类型不匹配",
  42830: "非法的外键",
  42883: "未定义的函数",
  44000: "违反 WITH CHECK 选项",
  53000: "资源不够",
  53100: "磁盘满",
  53200: "内存耗尽",
  53300: "太多连接",
  54000: "超过程序限制",
  54001: "语句太复杂",
  54011: "太多字段",
  54023: "参数太多",
  "08000": "连接异常",
  "08001": "SQL客户端不能建立SQL连接",
  "08003": "连接不存在",
  "08004": "SQL服务器拒绝建立SQL连接",
  "08006": "连接失败",
  "08007": "未知的事务解析",
  "08P01": "违反协议",
  "09000": "触发的动作异常",
  "2200B": "逃逸字符冲突",
  "2200F": "零长度的字符串",
  "2200G": "最相关类型不匹配",
  "2201B": "非法正则表达式",
  "2201E": "对数运算的非法参数",
  "2201F": "指数函数的非法参数",
  "2202E": "数组下标错误",
  "22P01": "浮点异常",
  "22P02": "非法文本表现形式",
  "22P03": "非法二进制表现形式",
  "2F000": "SQL过程异常",
  "2F005": "执行的函数没有返回语句",
  "39P01": "违反触发器协议",
  "3D000": "非法数据库名",
  "3F000": "非法模式名",
  "42P01": "未定义的表",
  "42P02": "未定义的参数",
  "42P04": "重复的数据库",
  "42P05": "重复的准备好语句",
  "42P07": "重复的表",
  "42P08": "模糊的参数",
  "42P10": "非法字段引用",
  "42P11": "非法游标定义",
  "42P12": "非法的数据库定义",
  "42P16": "非法表定义",
  "42P17": "非法对象定义",
  "42P18": "不确定的数据类型",
  "58P02": "重复的文件",
  P0001: "抛出异常",
  XX000: "内部错误",
  XX001: "数据损坏",
  XX002: "索引损坏",
};
