export default {
  ruleName: "规则名",
  setting: "操作",
  editBtn: "编辑",
  tokenTitle: "经过场景鹿处理后的结果",
  tokenDesc: "输入问题并点击试跑后展示结果",
  preRulesTitle: "生效的分词前规则",
  rulesTitle: "生效的分词后规则",
  rulesDesc: "点击试跑后展示实际生效的规则",
  testRun: "场景鹿试跑",
  inputTitle: "试跑问题",
  inputPlaceholder: "请输入问题",
  testBtn: "试跑",
};
