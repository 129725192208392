import React from "react";

const Contrast = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.21234 3.5H11.5V6.35056V18.0712V20.5H6.20159C4.15873 20.5 2.5 18.8656 2.5 16.8673V14.1604V9.87974V7.14328C2.5 5.14317 4.15949 3.5 6.21234 3.5Z"
      fill="currentColor"
      stroke="#256EFF"
    />
    <path
      d="M21.25 14.1503V16.8665C21.25 18.6596 19.5982 20.25 17.3782 20.25H11.75V3.75H17.3782C19.5983 3.75 21.25 5.3405 21.25 7.13246V9.79584V14.1503Z"
      fill="currentColor"
      fillOpacity="0.4"
      stroke="#256EFF"
      strokeWidth="1.5"
    />
    <rect x="11" y="2" width="2" height="20" rx="0.5" fill="currentColor" />
  </svg>
);
export default Contrast;
