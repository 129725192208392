// 用于做新手引导的上下文
import _ from "lodash";
import React, { createContext, useReducer } from "react";
import { Tour } from "@/components/Constants";
/**
 * 新手引导
 * ******************************************
 * 共7大任务模块
 * 1. 首页欢迎页引导
 * 2. 筛选器引导
 * 3. 切换工作区引导
 * 4. 搜索建议引导
 * 5. 搜索页搜索框模糊匹配引导
 * 5. 搜索页搜索框下占比配置引导
 * 6. 校准反馈引导
 * 7. 搜索结果页面卡片引导
 *
 * *****************任务状态*******************
 * 弹窗内维护卡片教学状态，初始为 wait，当卡片出现在视口中时候，状态更新为 start，点击确定后状态变为 finish；
 * 当大任务模块内的子任务点击跳过后状态设为 skip,不再进行后续子任务教学，当前大任务状态更新为 finish；
 *
 * *****************筛选器引导******************
 * 筛选器
 * 组件内筛选器添加字段
 * 组件内筛选器字段图标
 *
 * **********搜索页搜索框模糊匹配引导*************
 * 搜索框下模糊匹配引导
 * 组件内模糊结果类型引导
 * 组件内模糊结果确认引导
 * 组件内取消模糊引导
 *
 * **********搜索页搜索框下占比配置引导***********
 * 搜索框下占比引导
 * 组件内拖拽框引导
 * 组件内占比组成显示引导
 * 组件内占比结果示意图显示引导
 *
 * ****************校准反馈引导****************
 * 校准反馈按钮引导
 * 组件内指标和维度切换引导
 * 组件内提交反馈引导
 *
 * **************搜索结果页面卡片引导***********
 * 数据解释按钮
 * 预测按钮
 * 切换图表按钮
 * 编辑图标按钮
 * 加入报告按钮
 */

type StepId =
  | "isBeginnerTour" //是否是新手引导
  | "welcome" // 欢迎页
  | "changeProject" // 切换工作区
  | "SearchSuggestions" // 搜索建议
  | "filter" // 筛选器引导
  | "filterGuide" // 筛选器弹框引导
  | "filterWordGuide" // 筛选器内容引导
  | "filterIconGuide" // 筛选器筛选图标引导
  | "feedback" // 校准反馈
  | "feedbackEntry" // 校准反馈入口引导
  | "feedbackType" // 校准反馈选择
  | "feedbackConfirm" // 校准反馈提交
  | "fuzzy" // 模糊匹配
  | "fuzzyEntry" // 模糊匹配入口
  | "fuzzyType" // 模糊结果类型
  | "fuzzyConfirm" //模糊结果确认
  | "fuzzyCancel" // 取消模糊
  | "proportion" // 搜索框占比编辑
  | "proportionEntry" // 占比编辑入口
  | "proportionDrag" // 占比拖拽引导
  | "proportionResult" // 占比结果显示
  | "proportionChart" // 占比示意图
  | "chartCard" // 图表卡片
  | "insightBtn" // 数据解释按钮
  | "trendForecastBtn" // 预测按钮
  | "switchChartBtn"; // 切换图表按钮

type StepStatus = "wait" | "start" | "finish" | "skip";
type Step = { stepId: StepId; status: StepStatus };
type TaskStep = { status: StepStatus; step?: Step[] };
type ActionType = {
  id?: StepId; // 当前新手引导模块
  stepId?: StepId; // 新手引导子模块
  status?: StepStatus; // 引导任务状态
  stop?: boolean;
};

interface InitialStepsProps {
  isBeginnerTour: TaskStep;
  welcome: TaskStep;
  changeProject: TaskStep;
  SearchSuggestions: TaskStep;
  filter: TaskStep;
  feedbackEntry: TaskStep;
  feedback: TaskStep;
  fuzzyEntry: TaskStep;
  proportionEntry: TaskStep;
  fuzzy: TaskStep;
  proportion: TaskStep;
  chartCard: TaskStep;
}

const { STEP, STATUS } = Tour;
const { WAIT, START, FINISH } = STATUS;

const initialSteps: InitialStepsProps = {
  isBeginnerTour: { status: WAIT },
  welcome: { status: WAIT },
  changeProject: { status: WAIT },
  SearchSuggestions: { status: WAIT },
  feedbackEntry: { status: WAIT },
  fuzzyEntry: { status: WAIT },
  proportionEntry: { status: WAIT },
  filter: {
    status: WAIT,
    step: [
      { stepId: "filterGuide", status: WAIT },
      { stepId: "filterWordGuide", status: WAIT },
      { stepId: "filterIconGuide", status: WAIT },
    ],
  },
  feedback: {
    status: WAIT,
    step: [
      { stepId: "feedbackType", status: WAIT },
      { stepId: "feedbackConfirm", status: WAIT },
    ],
  },
  fuzzy: {
    status: WAIT,
    step: [
      { stepId: "fuzzyType", status: WAIT },
      { stepId: "fuzzyConfirm", status: WAIT },
      { stepId: "fuzzyCancel", status: WAIT },
    ],
  },
  proportion: {
    status: WAIT,
    step: [
      { stepId: "proportionDrag", status: WAIT },
      { stepId: "proportionResult", status: WAIT },
      { stepId: "proportionChart", status: WAIT },
    ],
  },
  chartCard: {
    status: WAIT,
    step: [
      { stepId: "insightBtn", status: WAIT },
      { stepId: "trendForecastBtn", status: WAIT },
      { stepId: "switchChartBtn", status: WAIT },
    ],
  },
};

const reducer: (state: InitialStepsProps, action: ActionType) => InitialStepsProps = (state, action) => {
  const { id, stepId, status, stop } = action;

  if (stop) return { ...initialSteps };
  if (stepId) {
    state[id].step.forEach(item => {
      if (item.stepId === stepId) item.status = status;
    });
    // 如果子引导完成，当前引导模块整体状态至为 finish
    if (state[id].step.every(item => item.status === FINISH)) {
      state[id].status = FINISH;
    }
  } else {
    state[id].status = status;
  }
  return { ...state };
};

export const TourContext = createContext<{
  isBeginnerTour: boolean;
  steps: InitialStepsProps;
  dispatch: React.Dispatch<ActionType>;
  startTour: () => void;
  endTour: () => void;
  checkTourStatus: (id: StepId, status?: StepStatus) => boolean;
  checkStepStatus: (id: StepId, stepId: StepId, status?: StepStatus) => boolean;
}>({
  isBeginnerTour: false,
  steps: _.cloneDeep(initialSteps),
  dispatch: _.noop,
  startTour: _.noop,
  endTour: _.noop,
  checkTourStatus: () => false,
  checkStepStatus: () => false,
});

export const TourContextProvider: React.FC = ({ children }) => {
  const [steps, dispatch] = useReducer(reducer, initialSteps);

  // 是否进入新手引导
  const isBeginnerTour = steps.isBeginnerTour.status === START;

  const changeGlobalStatus = status => {
    for (const key in steps) {
      dispatch({ id: key as StepId, status });
      if (steps[key].step) {
        steps[key].step.forEach(item => {
          dispatch({ id: key as StepId, stepId: item.stepId, status });
        });
      }
    }
  };

  // 结束新手引导
  const endTour = () => {
    changeGlobalStatus(FINISH);
  };

  // 开始新手引导
  const startTour = () => {
    changeGlobalStatus(WAIT);
    dispatch({ id: STEP.IS_BEGINNER_TOUR, status: START });
    dispatch({ id: STEP.WELCOME, status: START });
  };

  // 检查引导任务状态
  const checkTourStatus: (id: StepId, status?: StepStatus) => boolean = (id, status = START) => steps[id].status === status;

  // 检查子引导任务状态
  const checkStepStatus: (id: StepId, stepId: StepId, status?: StepStatus) => boolean = (id, stepId, status = START) =>
    steps[id].status === START && !!steps[id].step.filter(item => item.stepId === stepId && item.status === status)[0];

  return (
    <TourContext.Provider
      value={{
        isBeginnerTour,
        steps,
        dispatch,
        startTour,
        endTour,
        checkTourStatus,
        checkStepStatus,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export default TourContext;
