export default {
  relevance: "Star models, fact tables and dimension tables",
  dragTable: "1. Drag the table into the canvas",
  selectCorrespondingDimension: "2. Select (or create) the time dimension, dimension and measure corresponding to the data column",
  createDimensionTable: "3. Create (or reuse) dimension tables",
  joinTables: "4. Join fact table and dimension table",
  viewBriefDescription: "You can click here to view the simple instructions for model creation again",
  learnToCreateModels: "Learn to create models in a few simple steps",
  viewDetailedInstructions: "View detailed instructions",
  doNotRemindMeAgain: "No more reminders next time",
  skip: "Skip",
};
