import React from "react";

const LampOn = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2097 6.36001C18.1697 4.26001 16.1597 2.71001 13.8297 2.20001C11.3897 1.66001 8.88973 2.24001 6.97973 3.78001C5.05973 5.31001 3.96973 7.60001 3.96973 10.05C3.96973 12.64 5.51973 15.35 7.85973 16.92V17.75C7.84973 18.03 7.83973 18.46 8.17973 18.81C8.52973 19.17 9.04973 19.21 9.45973 19.21H14.5897C15.1297 19.21 15.5397 19.06 15.8197 18.78C16.1997 18.39 16.1897 17.89 16.1797 17.62V16.92C19.2797 14.83 21.2297 10.42 19.2097 6.36001Z"
      fill="currentColor"
    />
    <path
      d="M15.2602 21.9999C15.2002 21.9999 15.1302 21.9899 15.0702 21.9699C13.0602 21.3999 10.9502 21.3999 8.94015 21.9699C8.57015 22.0699 8.18015 21.8599 8.08015 21.4899C7.97015 21.1199 8.19015 20.7299 8.56015 20.6299C10.8202 19.9899 13.2002 19.9899 15.4602 20.6299C15.8302 20.7399 16.0502 21.1199 15.9402 21.4899C15.8402 21.7999 15.5602 21.9999 15.2602 21.9999Z"
      fill="currentColor"
      fillOpacity="0.4"
    />
  </svg>
);
export default LampOn;
