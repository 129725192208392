export default {
  sendSuccessToast: "测试邮件已发送，请及时查收",
  emailSettings: "邮箱设置",
  emailServerSettings: "邮件服务设置",
  host: "服务器",
  hostPlaceholder: "请输入邮件服务的服务器地址",
  username: "用户名",
  usernamePlaceholder: "请输入用户名",
  password: "密码",
  passwordPlaceholder: "请输入密码",
  protocol: "安全协议",
  protocolPlaceholder: "请选择安全协议",
  port: "端口号",
  portPlaceholder: "请输入端口号",
  unsetPlaceholder: "未设置",
  buttonTextSet: "设置",
  sendEmailTitle: "邮件发送",
  formLabelSendEmailAddress: "发件邮箱地址",
  formLabelSelectCharset: "邮件编码格式",
  selectCharsetPlaceholder: "请选择邮件编码格式",
  testEmailTitle: "测试收件",
  testEmailPlaceholder: "请输入测试邮件的收件地址",
  emailSettingsModalTitle: "邮件服务设置",
  defaultPlaceholder: "此项必填",
  protocolNull: "无",
  cancel: "取消",
  save: "保存",
  saveSuccessToast: "保存成功",
  sendEmailAddressPlaceholder: "请输入发件邮箱地址",
  emailFormatError: "需要输入一个合法的邮箱地址",
};
