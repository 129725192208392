import WPSBasic from "./WPSBasic";

export default class WPSStorage extends WPSBasic {
  // 存储数据的文件名
  static fileName = "WPSStorage";

  // 获取系统临时目录的路径
  static getTempPath = () => this.app.Env.GetTempPath();

  static getStorageFilePath = () => `${this.getTempPath()}/${this.fileName}`;

  static existsStorageFile = () => this.app.FileSystem.Exists(this.getStorageFilePath());

  static readFile = filePath => this.app.FileSystem.ReadFile(filePath);

  static writeFile = (filePath, data) => this.app.FileSystem.WriteFile(filePath, data);

  static removeFile = filePath => this.app.FileSystem.Remove(filePath);

  static getData = () => {
    try {
      return JSON.parse(this.readFile(this.getStorageFilePath()));
    } catch (e) {
      return undefined;
    }
  };

  static setItem = (key, value) => {
    if (!this.existsStorageFile()) return this.writeFile(this.getStorageFilePath(), JSON.stringify({ [key]: value }));
    const oldData = this.getData();
    const newData = { ...oldData, [key]: value };
    this.writeFile(this.getStorageFilePath(), JSON.stringify(newData));
  };

  static getItem = key => {
    if (!this.existsStorageFile()) return;
    const data = this.getData();
    return data?.[key];
  };

  static removeItem = key => {
    if (!this.existsStorageFile()) return;
    const data = this.getData();
    delete data?.[key];
    this.writeFile(this.getStorageFilePath(), JSON.stringify(data));
  };

  static clear = () => {
    if (!this.existsStorageFile()) return;
    this.removeFile(this.getStorageFilePath());
  };
}
