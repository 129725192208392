export default {
  measure: "指标",
  searchInputPlaceholder: "请输入想搜索的指标名称",
  addBtnText: "添加指标",
  addDerivativeBtnText: "衍生指标",
  deleteSuccessToast: "删除成功",
  editBtnText: "编辑",
  deleteBtnText: "删除",
  deleteConfirmTitle1: "确认删除该",
  deleteConfirmTitle2: "？该操作无法撤销",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  id: "ID",
  keyword: "关键词",
  synonyms: "别名",
  measureType: "指标类型",
  currencyUnit: "单位",
  formatType: "展示格式",
  formula: "计算公式",
  derivativeMeasure: "衍生指标",
  normalMeasure: "事实表指标",
  formulaExtra: "支持已有模型的指标间加减乘除的基本运算",
  formulaPlaceholder: "请点击右侧编辑按钮创建或修改公式",
  noUnit: "无",
  models: "应用模型",
  operations: "操作",
  current: "当前指标：",
  tableDeleteConfirmTitle: "确认删除该指标",
  tableDeleteConfirmContent1: "该指标已有应用模型。",
  tableDeleteConfirmContent2: "确认后会在所有包含该指标的模型中移除。",
  tableDeleteConfirmContent3: "该操作无法撤销",
  pruneButtonText: "清空无效指标",
  pruneButtonTip: "清空没有被模型使用的指标",
  pruneConfirmTitle: "确认删除无效的指标",
  pruneDeriveConfirmTitle: "确认删除无效的衍生指标",
  pruneConfirmContent: "确认后，没有应用模型的所有指标都将被清空。",
};
