import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Modals.UploadAliasErrorCode.${id}`);

export default class UploadAliasErrorCode {
  static ANALYSIS_FAIL = 1;

  static ENCODE_FAIL = 2;

  static INVALID_FAIL = 3;

  static getText = v => {
    if (v === this.ANALYSIS_FAIL) return getTextById("uploadErrMsg1");
    if (v === this.ENCODE_FAIL) return getTextById("uploadErrMsg2");
    if (v === this.INVALID_FAIL) return getTextById("uploadErrMsg3");
    return "";
  };
}
