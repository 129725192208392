import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.DrillType.${id}`);
export default class DrillType {
  // 下钻菜单类型
  static DRILL_DOWN = "drillDown"; // 下钻

  static ROLL_UP = "rollup"; // 上钻

  static COPY = "copy"; // 表格单元格复制

  static FILTER = "filter"; // 报告联动筛选

  // 下钻错误码
  static IS_BLEND = 30001; // data blend 无法钻取

  static NOT_INT_OR_STR = 30002; // 非整数或文本无法钻取

  static NEED_MASKING = 30003; // 脱敏数据无法钻取

  static TIME_COMPARED = 30004; // 包含时间聚合的对比类问题暂不支持下钻

  static MERGE_TYPE_DATA = "mergeTypeData"; // 合并类型的数据不支持下钻

  static getText = status => {
    if (status === this.IS_BLEND) return getText("isBlend");
    if (status === this.NOT_INT_OR_STR) return getText("notIntOrStr");
    if (status === this.NEED_MASKING) return getText("needMasking");
    if (status === this.TIME_COMPARED) return getText("timeCompared");
    if (status === this.MERGE_TYPE_DATA) return getText("mergeTypeData");
  };
}
