import React from "react";

const ArrowLeft = (
  <svg width="1em" height="1em" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.360269 0.947189L6.39777 5.66281C6.44908 5.70284 6.4906 5.75404 6.51915 5.81253C6.5477 5.87101 6.56254 5.93523 6.56254 6.00031C6.56254 6.06539 6.5477 6.12962 6.51915 6.1881C6.4906 6.24658 6.44908 6.29779 6.39777 6.33781L0.360269 11.0534C0.290626 11.1083 0.1875 11.0588 0.1875 10.9691V9.9338V2.06683V1.03156C0.187406 1.01149 0.192976 0.991787 0.203571 0.974732C0.214166 0.957676 0.229357 0.943955 0.247399 0.935143C0.265441 0.926332 0.285603 0.922788 0.305568 0.924919C0.325533 0.92705 0.344493 0.934768 0.360269 0.947189Z"
      fill="currentColor"
    />
  </svg>
);
export default ArrowLeft;
