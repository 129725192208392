export default {
  checklistModalTitle: "Select charts to update",
  checkAll: "Check all",
  okBtnText: "Start updating",
  inProgressModalTitle: "Update list",
  waitForUpdateTip: "Waiting...",
  inProgressTip: "Updating...",
  inProgress: "In progress",
  finished: "Finished",
  noContentTip: "No content",
  noAuthErrorMessage: "Update failed, no workspace permission",
  noResultErrorMessage: "No search result",
  unknownErrorMessage: "Update failed, please try again later",
  recallTip: "Recall",
  recallSucceed: "Recalled successfully",
  recallFail: "Recall failed",
};
