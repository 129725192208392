export default {
  userGroups: "用户组",
  userGroupSettings: "用户组详情",
  userGroupFormerTip: "当前组：",
  deleteBtnText: "删除此用户组",
  deleteConfirmTitle: "确认删除此用户组？",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  deleteSuccessToast: "用户组删除成功",
};
