export default {
  isHelpfulText: "How was the results?",
  helpfulTip: "Helpful",
  notHelpfulTip: "Not helpful",
  feedbackToast: "thanks for your feedback",
  okBtn: "Confirm",
  noHelpfulTitle: "Why Are You Dissatisfied?",
  noHelpfulLabel: "Please choose the reasons(MultiSelect).It will help us provide a better service.",
  othersLabel: "Other reasons (optional)",
  othersPlaceholder: "Other suggestions and feedbacks are welcomed",
  noUnderstandBtn: "Can’t understand",
  errorBtn: "Wrong results",
  noResultBtn: "No results found",
  slowBtn: "Slow",
  unreasonableBtn: "Unreasonable chart type",
  notRelevantBtn: 'Irrelevant "Related searches"',
  othersBtn: "Others",
};
