import { notification } from "antd";
import axios from "axios";
import _ from "lodash";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import NotPermissionBlock from "@/components/Blocks/NotPermissionBlock";
import {
  AccountRole,
  AccountTier,
  AccountType,
  BreakPointDisplayType,
  ChartLegendPosition,
  CollaborationType,
  LoginMethod,
  NumberFormatterNegative,
  NumberFormatterStyle,
  ThemeModeType,
} from "@/components/Constants";
import {
  getConfUrl,
  getDingTalkOAuthUrl,
  getLarkOAuthUrl,
  getOAuth2LoginUrl,
  getV2DisplayConfigsUrl,
  getV3LogoutUrl,
  getWeComOAuthUrl,
  getYufuOAuthUrl,
} from "@/components/Urls";
import { formatUrl, getSearchString, parseSearchString, uuidV4 } from "@/utils/common";
import { getLocale } from "@/utils/locale";
import DatarcStorage from "@/utils/storage";
import Toast from "./Toast";

export const WORKSPACE_ERROR_HAVE_OTHER = "0010001"; // 工作区不存在，用户有其他的工作区
export const WORKSPACE_ERROR_NO_OTHER = "0010002"; // 工作区不存在，用户没有其他的工作区
const QUERY_KEY_EXPIRED = "10402"; // 问题已过期，请重新查询

export const noAuthStatus = [401, 403];

// axios 的错误处理，会处理不同类型的阶段
// https://axios-http.com/zh/docs/handling_errors
// 一个通用的 axios 错误处理函数生成函数，处理公共的错误信息，返回一个用于处理请求错误的**函数**
export function axiosErrorFunc({ preHandleFunc, handleFunc } = {}) {
  return error => {
    // 如果有前置处理函数，执行前置处理函数
    if (_.isFunction(preHandleFunc)) preHandleFunc();
    // axios 主动 cancel 请求，直接返回
    if (axios.isCancel(error)) return;

    // 网络异常，请求没有发起
    if (error.code === "ERR_NETWORK") return Toast.warning("网络连接错误，无法连接到服务器，请检查您的网络状态");

    // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
    if (error.response) {
      const { status } = error.response;
      // 访问了没有权限的接口，退出登录
      if (noAuthStatus.includes(status)) return Auth.logout();
      // 达到并发数上限，提示文案 + 退出登录
      if (status === 429) {
        Toast.error(error.response.data.detail);
        return Auth.logout();
      }
      // 服务器发生错误
      if (Number.isInteger(status) && status >= 500 && status < 600) return Toast.error("服务器发生错误，请稍后重试");
      // 用户不在工作区，修改 authData 中的 project 中的 role，让用户重新选择工作区
      if ([WORKSPACE_ERROR_HAVE_OTHER, WORKSPACE_ERROR_NO_OTHER].includes(error.response?.data?.status)) {
        Auth.setAuthProject();
        return (window.location.href = "#/launch?error=removed");
      }
      // 查询的问题已经过期，提示重新查询
      if (QUERY_KEY_EXPIRED === error.response?.data?.status) return Toast.error("问题已过期，请重新查询");
      // 有自定义处理函数，调用自定义的处理函数
      if (_.isFunction(handleFunc)) return handleFunc(error);
      // 如果返回的错误信息有 message 字段，则 Toast 提示 message (我们绝大部分的错误信息都是这个结构)；以上都没发生，则提示 网络异常
      return Toast.error(error.response?.data?.message || "网络异常");
    }
    // 请求已经成功发起，但没有收到响应。其中 `e.request`:
    // - 在浏览器中是 XMLHttpRequest 的实例，
    // - 在 node.js 中是 http.ClientRequest 的实例
    if (error.request) {
      Toast.warning("服务器繁忙，请稍后重试");
      console.error(error.request);
    }
  };
}

export const axiosError = axiosErrorFunc();

function configureToken(token) {
  axios.defaults.headers = {
    Authorization: token,
    "Accept-Language": getLocale(),
  };
}

// 当前的登录数据的相关函数
const LOCATION_PATH = "nextPathname";
const LOCATION_SEARCH = "nextSearch";
const setLocation = location => {
  if (location === undefined) {
    localStorage.removeItem(LOCATION_PATH);
    localStorage.removeItem(LOCATION_SEARCH);
  } else {
    const { pathname, search } = location;
    if (pathname.indexOf("/auth/login") !== -1) return;
    localStorage.setItem(LOCATION_PATH, pathname);
    localStorage.setItem(LOCATION_SEARCH, search);
  }
};
export const getLocation = () => {
  const pathname = localStorage.getItem(LOCATION_PATH);
  if (!pathname) return undefined;
  const search = localStorage.getItem(LOCATION_SEARCH);
  return { pathname, search };
};

export default class Auth {
  static authPrefix = "/auth/";

  static defaultLaunchPath = "/launch";

  static strSavedUsername = "savedUsername";

  static strSavedPhone = "savedPhone";

  static strAuthData = "authData";

  static clientID = undefined;

  static allowRegister = false;

  static hasWatermark = false;

  static cas = undefined;

  static authData = undefined;

  static hasEmail = undefined;

  static enableVoice = false;

  static hasAttribution = false;

  static hasWPS = false;

  static disableCardShare = false;

  static disableCardDownload = false;

  static disableFrontendGuide = false;

  static disableBackendGuide = false;

  static disableMessageCenter = false;

  static disableSearchAdvice = false;

  static disableIsWatched = false;

  static disableLatestSearch = false;

  static disableSms = false;

  static enable2fa = false;

  static renew = false;

  static enableDataMask = true;

  static themeMode = ThemeModeType.LIGHT;

  static hasDingTalk = false;

  static hasLark = false;

  static hasOauth2 = false;

  static hasSaml2 = false;

  static hasWeChatWork = false;

  static hasYufu = false;

  static projectConfigs = {
    // eslint-disable-next-line camelcase
    y_axis_scale: false, // 脱离零值比例
    // eslint-disable-next-line camelcase
    table_threshold: 2000, // 默认展示表格阈值
    breakPointDisplayType: BreakPointDisplayType.DEFAULT, // 折线缺失值展示样式
    cSymbol: false, // 数据点标记
    cSmooth: false, // 平滑曲线
    cLabel: true, // 数据点数值标签
    cSplitXLine: false, // x 轴分割线显示
    cSplitYLine: true, // y 轴分割线显示
    cLegendPosition: ChartLegendPosition.RIGHT, // 图例位置
    cNumberStyle: NumberFormatterStyle.DEFAULT, // 图表数字指标数值展示样式
    cNumberThousandSeparator: true, // 图表数字指标千分位分隔符
    cNumberNegative: NumberFormatterNegative.NORMAL, // 图表数字指标负数格式
    cNumberDecimalPlaces: 2, // 图表数字指标小数点位数
    cPercentDecimalPlaces: 2, // 图表百分比指标小数点位数
    cPercentThousandSeparator: true, // 图表百分比指标千分位分隔符
    tNumberStyle: NumberFormatterStyle.DEFAULT, // 表格数字指标数值展示样式
    tNumberThousandSeparator: true, // 表格数字指标千分位分隔符
    tNumberNegative: NumberFormatterNegative.NORMAL, // 表格数字指标负数格式
    tNumberDecimalPlaces: 2, // 表格数字指标小数点位数
    tPercentDecimalPlaces: 2, // 表格百分比指标小数点位数
    tPercentThousandSeparator: true, // 表格百分比指标千分位分隔符
    collaboration: CollaborationType.ALLOW_ALL, // 报告协作，允许所有
    disableDateRangeLimit: false, // 允许搜索未来的数据
    // eslint-disable-next-line camelcase
    date_offset: -1, // 基础日期偏移值
    // eslint-disable-next-line camelcase
    forecast_max_days: undefined, // 预测模型的训练数据范围 - 最大值
    // eslint-disable-next-line camelcase
    forecast_min_days: undefined, // 预测模型的训练数据范围 - 最小值
  };

  static initDisplayConfigs() {
    const projectId = Auth.getProjectId();
    if (!projectId) return;
    axios
      // eslint-disable-next-line camelcase
      .get(getV2DisplayConfigsUrl())
      .then(r => {
        Auth.projectConfigs = { ...Auth.projectConfigs, ...r.data.data };
      })
      .catch(axiosError);
  }

  // 获取用户的默认登录页面
  // 假如系统禁用了短信，则只能通过密码登录
  // 否则优先跳转为用户上次的登录方式
  static getDefaultLoginPath() {
    if (Auth.disableSms) {
      return "/auth/login";
    }
    return DatarcStorage.getItem("isUserPreferPassword") ? "/auth/login" : "/auth/login/pincode";
  }

  static getClientID() {
    return Auth.clientID;
  }

  static getHasEmail() {
    return Auth.hasEmail;
  }

  static getHasAttribution() {
    return Auth.hasAttribution;
  }

  static getHasWPS() {
    return Auth.hasWPS;
  }

  /*
   * 使用 method 方法进行 SSO
   */
  static ssoLogin(method, params) {
    if (method === LoginMethod.OAUTH2) window.location.href = getOAuth2LoginUrl();
    if (method === LoginMethod.CAS) window.location.href = `${formatUrl(params?.cas)}login/`;
    if (method === LoginMethod.DINGTALK) window.location.href = getDingTalkOAuthUrl();
    if (method === LoginMethod.LARK) window.location.href = getLarkOAuthUrl();
    if (method === LoginMethod.WECOM) window.location.href = getWeComOAuthUrl();
    if (method === LoginMethod.YUFU) window.location.href = getYufuOAuthUrl();
  }

  static setAuthData(data) {
    const strAuthData = Auth.strAuthData;
    Auth.authData = data;
    if (data) {
      const { token } = data;
      configureToken(token);
      DatarcStorage.setItem(strAuthData, JSON.stringify(data));
    } else {
      DatarcStorage.removeItem(strAuthData);
    }
  }

  static getAuthData() {
    if (!Auth.authData) {
      const data = DatarcStorage.getItem(Auth.strAuthData);
      if (data) Auth.authData = JSON.parse(data);
    }
    // 检查一下 authData 的数据类型，去掉一些失效过期的。
    if (_.isObject(Auth.authData)) {
      // 新版本必须要有 tier
      AccountTier.choices.includes(Auth.authData.tier) || Auth.setAuthData(undefined);
    }
    return Auth.authData;
  }

  static logout(saveLocation = true) {
    // 多个请求可能会同时 401 跳转, 所以只处理第一个调用
    if (window.location.hash.indexOf("/auth/login") !== -1) return;
    // 保存当前路由信息
    if (saveLocation) {
      const { hash } = window.location;
      let splitIndex = hash.indexOf("?");
      if (splitIndex < 0) splitIndex = hash.length;
      const pathname = hash.slice(1, splitIndex);
      const search = hash.slice(splitIndex);
      if (!pathname.startsWith("/manage")) setLocation({ pathname, search });
    }
    // 清理所有提示框
    notification.destroy();
    // 调用登出接口
    axios.post(getV3LogoutUrl()).then();
    // 清理用户登录信息
    Auth.setAuthData(undefined);
    // 如果登录方式只有cas，就要调用这个的登出, 钉钉之类的只有扫码登录
    if (_.toString(Auth.loginMethods) === LoginMethod.CAS) {
      window.location.href = `${formatUrl(Auth.cas)}logout/`;
      return false;
    }
    window.location.hash = this.getDefaultLoginPath();
    return true;
  }

  static initClientConf(callback, force) {
    if (Auth.clientID && !force) return;

    axios.get(getConfUrl()).then(r => {
      const {
        id = null,
        renew,
        hasEmail,
        allowRegister,
        hasWatermark,
        cas,
        hasDingTalk,
        hasLark,
        hasOauth2,
        hasSaml2,
        hasWeChatWork,
        hasYufu,
        watermarkType,
        watermarkStyle,
        watermarkContent,
        watermarkFontSize,
        watermarkFontWeight,
        watermarkOpacity,
        enableVoice,
        loginMethods,
        hiddenHeaderRight,
        hasAttribution,
        hasWPS,
        disableMessageCenter,
        disableCardShare,
        disableCardDownload,
        disableFrontendGuide,
        disableBackendGuide,
        disableSearchAdvice,
        disableIsWatched,
        disableLatestSearch,
        enable2fa,
        enableDataMask,
        themeMode,
        themeConfigs,
        disableSms,
        llmConfig,
        inactivityLogoutTime,
      } = r.data;

      Auth.disableSms = disableSms;

      Auth.clientID = id;

      Auth.hasEmail = hasEmail;
      Auth.allowRegister = !!allowRegister;
      Auth.hasWatermark = !!hasWatermark;

      Auth.cas = cas;
      Auth.hasDingTalk = hasDingTalk;
      Auth.hasLark = hasLark;
      Auth.hasOauth2 = hasOauth2;
      Auth.hasWeChatWork = hasWeChatWork;
      Auth.hasSaml2 = hasSaml2;
      Auth.hasYufu = hasYufu;

      // 水印的设置
      Auth.watermarkType = watermarkType;
      Auth.watermarkStyle = watermarkStyle;
      Auth.watermarkContent = watermarkContent;
      Auth.watermarkFontSize = watermarkFontSize;
      Auth.watermarkFontWeight = watermarkFontWeight;
      Auth.watermarkOpacity = watermarkOpacity;

      // 无操作自动登出时间
      Auth.inactivityLogoutTime = inactivityLogoutTime;

      // 语音输入
      Auth.enableVoice = enableVoice;
      // 贡献度分析功能
      Auth.hasAttribution = hasAttribution;
      // 文档集成开关
      Auth.hasWPS = hasWPS;
      // 登录方式
      Auth.loginMethods = loginMethods;
      // 双因子登录
      Auth.enable2fa = enable2fa;
      Auth.disableCardShare = disableCardShare;
      Auth.disableCardDownload = disableCardDownload;
      Auth.disableMessageCenter = disableMessageCenter;

      Auth.disableSearchAdvice = disableSearchAdvice;
      Auth.disableIsWatched = disableIsWatched;
      Auth.disableLatestSearch = disableLatestSearch;
      // 数据脱敏
      Auth.enableDataMask = enableDataMask;
      // 主题模式
      Auth.themeMode = `${themeMode}`;
      Auth.themeConfigs = themeConfigs;
      // 新手引导开关
      Auth.disableFrontendGuide = disableFrontendGuide;
      Auth.disableBackendGuide = disableBackendGuide;
      // 大语言模型关联开关
      Auth.llmConfig = llmConfig;

      if (_.isFunction(callback)) callback({ hiddenHeaderRight, renew });
    });
  }

  static getProject() {
    const authData = Auth.getAuthData();
    if (!authData) return undefined;
    const { project } = authData;
    return project;
  }

  static getFeatures() {
    return this.getProject()?.features;
  }

  static getProjectMuId() {
    return this.getProject()?.muid;
  }

  static getProjectId() {
    return this.getProject()?.id;
  }

  static setAuthProject(project) {
    const authData = Auth.getAuthData();
    Auth.setAuthData({ ...authData, project });
  }

  static hasPinboardEditPermission = () => !Auth.isSearchAccount();

  static getUid() {
    const authData = Auth.getAuthData();
    if (!authData) return undefined;
    const { uid } = authData;
    return uid;
  }

  static getTier() {
    const authData = Auth.getAuthData();
    if (!authData) return undefined;
    return authData.tier;
  }

  static getAccountType() {
    const authData = Auth.getAuthData();
    // 兼容一下老的逻辑，如果不带 t 的属性，当成临时类型的用户
    return AccountType.choices.includes(authData?.t) ? authData.t : AccountType.TEMPORARY;
  }

  static getToken() {
    const authData = Auth.getAuthData();
    if (!authData) return undefined;
    const { token } = authData;
    return token;
  }

  static getNickname() {
    const authData = Auth.getAuthData();
    if (!authData) return undefined;
    const { nickname } = authData;
    return nickname;
  }

  static setSavedUsername(username) {
    if (username) {
      DatarcStorage.setItem(Auth.strSavedUsername, username);
    } else {
      DatarcStorage.removeItem(Auth.strSavedUsername);
    }
  }

  static getSavedUsername() {
    return DatarcStorage.getItem(Auth.strSavedUsername);
  }

  static setSavedPhone(phone) {
    if (phone) {
      DatarcStorage.setItem(Auth.strSavedPhone, phone);
    } else {
      DatarcStorage.removeItem(Auth.strSavedPhone);
    }
  }

  static getSavedPhone() {
    return DatarcStorage.getItem(Auth.strSavedPhone);
  }

  static isAdmin() {
    const authData = Auth.getAuthData();
    if (!_.isObject(authData)) return false;
    return authData.role === AccountRole.ADMIN;
  }

  static isAuditor() {
    const authData = Auth.getAuthData();
    if (!_.isObject(authData)) return false;
    return authData.role === AccountRole.AUDITOR;
  }

  static isSearchAccount() {
    return Auth.getTier() === AccountTier.TIER_3;
  }

  static needLogin(props) {
    // 如果网址里面包含授权信息
    if (props?.location?.search) {
      const parsed = parseSearchString(props.location.search);
      if (_.isString(parsed.authData)) {
        const data = JSON.parse(parsed.authData);
        Auth.setAuthData(data);
        delete parsed.authData;
        // 如果有 OAuth 数据，需要发送消息通知
        if (_.isString(parsed.oauthData)) {
          try {
            const oauth = JSON.parse(parsed.oauthData);
            const { platform, data } = oauth;
            axios.post(`/${encodeURI(platform)}/notice/bind-success/`, data);
          } catch (e) {
            console.error(e);
          }
          delete parsed.oauthData;
        }
        // 设置完权限信息之后，需要跳转到目标网页
        const to = {
          pathname: props.location.pathname,
          search: getSearchString(parsed),
        };
        return <Redirect to={to} key={uuidV4()} />;
      }
    }
    if (Auth.getAuthData()) return null;
    // 存储当前地址，前往登录页面
    if (_.isString(props.location.pathname) && !props.location.pathname.startsWith(Auth.authPrefix)) setLocation(props.location);
    return <Redirect to={Auth.getDefaultLoginPath()} key={uuidV4()} />;
  }

  static needAdmin() {
    if (Auth.isAdmin()) return null;
    return <Route component={NotPermissionBlock} />;
  }

  static needAudit() {
    if (Auth.isAuditor) return null;
    return <Route component={NotPermissionBlock} />;
  }

  static needLaunch(props) {
    if (Auth.getProject()) return null;
    setLocation(props.location);
    return <Redirect push to={Auth.defaultLaunchPath} key={uuidV4()} />;
  }

  static redirectAfterAuth() {
    const authData = Auth.getAuthData();
    // 跳转到用户角色默认的入口
    const redirectToDefault = () => {
      if (authData?.role === AccountRole.USER) return <Redirect push to="/" />;
      if (authData?.role === AccountRole.ADMIN) return <Redirect push to="/admin/guide" />; // 系统管理员账号登录后默认跳转至引导页
      if (authData?.role === AccountRole.AUDITOR) return <Redirect push to="/audit" />;
      return <Route component={NotPermissionBlock} />;
    };
    const to = getLocation();
    if (!to) return redirectToDefault();
    setLocation(); // remove location data
    const { pathname } = to;

    // auth 不需要跳回去，跳转到默认的地址。
    const reg = /^\/auth\/*/gi;
    if (reg.test(pathname)) return redirectToDefault();
    // 系统管理员  /admin 开头的地址，跳过去，否则跳默认地址
    if (authData?.role === AccountRole.ADMIN) {
      return /^\/(admin)\/*/gi.test(pathname) ? <Redirect push to={to} /> : redirectToDefault();
    }
    // 审计类用户  /audit 开头的地址，跳过去，否则跳默认地址
    if (authData?.role === AccountRole.AUDITOR) {
      return /^\/(audit)\/*/gi.test(pathname) ? <Redirect push to={to} /> : redirectToDefault();
    }
    // 业务用户  不是 /audit 、/admin 开头，跳过去，否则跳默认地址
    if (authData?.role === AccountRole.USER) {
      return /^\/(audit|admin)\/*/gi.test(pathname) ? redirectToDefault() : <Redirect push to={to} />;
    }
    return <Route component={NotPermissionBlock} />;
  }
}

configureToken(Auth.getToken());
