export default {
  addToPinboard: "Add to dashboard",
  searchPinboardInputPlaceholder: "Type keywords to search dashboard",
  chartNameLabel: "Chart name",
  chartNameInputPlaceholder: "Enter chart name",
  selectPinboardLabel: "Select dashboard",
  cancel: "Cancel",
  confirm: "Confirm",
  pinboardNameInputPlaceholder: "Enter dashboard name",
  allPinboardsLabel: "All dashboards",
  createPinboardBtnText: "Create new dashboard",
  noPinboardPrompt: "No existing dashboard, please",
  addSuccessToast: "Added to dashboard",
  viewThisPinboardLinkText: "Open dashboard",
  noResultTip: "No result found",
  samePinboardNameErrorTip: "Dashboard name already exists, please choose a different name",
  allSpacePinboardNameErrorTip: "Dashboard name can not be space only",
  pinboardNameTooLongErrorTip: "Maximum 50 characters allowed",
  create: "Create",
  next: "Next",
  cardApplicationTitle: "Card application",
  saveSuccessToast: "Saved successfully, go to",
  view: "View",
  mySavedCardLinkText: "My Account-Saved Cards",
  updateCardSuccessToast: "Card updated",
  saveCard: "Save card",
  saveAsNewCard: "Save as new card",
  update: "Update",
  saveRepeatCardConfirm: "This card has already been saved, do you want to update saved card or save as new card?",
  save: "Save",
  noPinboard: "No dashboard",
  changeKeywordTip: "Please update keywords, or",
  addDescPlaceholder: "Add description",
  chartNameErrorTip: "Enter valid chart name",
};
