export default class SuggestionCardType {
  // 我常搜索
  static OFTEN_SEARCH = 1;

  // 新增搜索
  static NEW_SEARCH = 2;

  // 热门搜索
  static TOP_SEARCH = 3;

  // 你可以试试
  static YOU_CAN_TRY = 4;

  // 了解趋势
  static LEARN_TREND = 5;

  // 了解排名
  static LEARN_RATING = 6;

  // 进行对比
  static COMPARISON = 7;

  // 了解内容
  static LEARN_CONTENT = 8;
}
