import React from "react";

const PinboardMobileCardList = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1097 7.46687L11.9996 11.2626L2.88961 7.46687C2.27397 7.21035 2.27396 6.30061 2.88961 6.0441L11.9996 2.24835L21.1097 6.0441C21.7253 6.30061 21.7253 7.21035 21.1097 7.46687ZM5.14091 6.75548L11.9996 9.61321L18.8584 6.75548L11.9996 3.89775L5.14091 6.75548Z"
      fill="currentColor"
    />
    <path d="M2.24854 10.54V12.3807L12 16.6679L21.7485 12.382V10.5413L12 14.8272L2.24854 10.54Z" fill="currentColor" />
    <path d="M2.24854 15.6291V17.4699L12.0002 21.7572L21.7485 17.4713V15.6306L12.0002 19.9164L2.24854 15.6291Z" fill="currentColor" />
  </svg>
);

export default PinboardMobileCardList;
