import React from "react";

const Report = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.0013 3.99999C5.63311 3.99999 5.33464 4.29847 5.33464 4.66666C5.33464 5.03485 5.63311 5.33333 6.0013 5.33333H10.0013C10.3695 5.33333 10.668 5.03485 10.668 4.66666C10.668 4.29847 10.3695 3.99999 10.0013 3.99999H6.0013Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M5.33464 7.33333C5.33464 6.96514 5.63311 6.66666 6.0013 6.66666H10.0013C10.3695 6.66666 10.668 6.96514 10.668 7.33333C10.668 7.70152 10.3695 7.99999 10.0013 7.99999H6.0013C5.63311 7.99999 5.33464 7.70152 5.33464 7.33333Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66797 2.66666C2.66797 1.93028 3.26492 1.33333 4.0013 1.33333H12.0013C12.7377 1.33333 13.3346 1.93028 13.3346 2.66666V13.3333C13.3346 14.0697 12.7377 14.6667 12.0013 14.6667H4.0013C3.26492 14.6667 2.66797 14.0697 2.66797 13.3333V2.66666ZM4.0013 2.66666H12.0013V13.3333H11.3417L9.86623 10.4278C9.52527 9.75633 8.83604 9.33333 8.08299 9.33333H4.0013V2.66666ZM4.0013 10.6667V13.3333H9.84631L8.6774 11.0315C8.56375 10.8077 8.33401 10.6667 8.08299 10.6667H4.0013Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default Report;
