export default {
  ascendText: "Sort by descending order",
  descendText: "Clear sort",
  defaultText: "Sort by ascending order",
  createSuccessToast: "New dashboard created",
  allPinboards: "All",
  starred: "Starred",
  unStarred: "Not Starred",
  sharedWithMe: "Shared",
  myPinboards: "Created",
  recycleBin: "Trash",
  delPinboardToast: "Dashboard deleted",
  restoreSuccessToast: "Recovered successfully",
  delSuccessToast: "Deleted successfully",
  restore: "Restore",
  delPermanently: "Permanently Delete",
  sharePinboard: "Share",
  delete: "Delete",
  remove: "Remove",
  cancelStar: "Delete bookmark",
  pinboardName: "Name",
  tag: "Tag",
  owner: "Owner",
  lastModifier: "Last Modifier",
  operation: "Settings",
  loading: "Loading...",
  backToTop: "Back to top",
  create: "Create",
  searchText: "Search",
  updatedAt: "Modified",
  noResultFound: "No result found",
  noRecordFound: "No record found",
  noPinboardInList: "No dashboard in current list",
  createPinboard: "Create dashboard",
  noContentInRecycleBin: "Trash is empty",
  noContentPrompt1: "No dashboard, start ",
  noContentPrompt2: "creating dashboard",
  noContentPrompt3: ".",
  cancel: "Cancel",
  tags: "Add Tags",
  editSuccessToast: "Edit successfully",
  markPinboardDialogTitle: "Tag dashboard",
  properTagHint: "Add tag for easy search",
  templateLibrary: "Template Library",
  transformTemplateSuccessToast: "Transformed to template successfully",
  modifyPinboardSuccessToast: "Report modified successfully",
  template: "Template",
  timedReminder: "Schedule",
  all: "All",
  renderNull: "null",
  tagManage: "Tag Manage",
};
