import React from "react";

const DrillDownDimensions = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.46465C0 1.10346 1.10346 0 2.46465 0C3.82583 0 4.92929 1.10346 4.92929 2.46465C4.92929 3.56433 4.20909 4.49581 3.21462 4.81311V9.60923C3.21462 10.2996 3.77426 10.8592 4.46462 10.8592H8.59385C8.7513 9.64986 9.78545 8.7159 11.0377 8.7159C12.3989 8.7159 13.5024 9.81936 13.5024 11.1806C13.5024 12.5417 12.3989 13.6452 11.0377 13.6452C10.1035 13.6452 9.29067 13.1254 8.87267 12.3592H4.46462C2.94583 12.3592 1.71462 11.128 1.71462 9.60923V4.8131C0.720181 4.49577 0 3.56431 0 2.46465ZM2.46465 1.5C1.93189 1.5 1.5 1.93189 1.5 2.46465C1.5 2.99741 1.93189 3.42929 2.46465 3.42929C2.99741 3.42929 3.42929 2.99741 3.42929 2.46465C3.42929 1.93189 2.99741 1.5 2.46465 1.5ZM11.0377 10.2159C10.505 10.2159 10.0731 10.6478 10.0731 11.1806C10.0731 11.7133 10.505 12.1452 11.0377 12.1452C11.5705 12.1452 12.0024 11.7133 12.0024 11.1806C12.0024 10.6478 11.5705 10.2159 11.0377 10.2159Z"
      fill="currentColor"
    />
  </svg>
);

export default DrillDownDimensions;
