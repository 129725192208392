import React from "react";

const AutoInsightPareto: React.FC = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <path
      d="M14.9575 6.96255C15.0605 6.65341 15.3965 6.48333 15.6923 6.61996C16.5249 7.00453 17.2471 7.59987 17.7846 8.35131C18.3221 9.10274 18.6522 9.97848 18.7471 10.8907C18.7809 11.2148 18.5114 11.4778 18.1856 11.4754L14.2778 11.4465C13.877 11.4435 13.5958 11.0503 13.7225 10.67L14.9575 6.96255Z"
      fill="white"
    />
    <path
      d="M16.7797 12.3947C17.1055 12.3947 17.3731 12.6597 17.3369 12.9835C17.2352 13.8951 16.8986 14.7683 16.3556 15.5158C15.6965 16.4229 14.7671 17.0982 13.7007 17.4447C12.6342 17.7912 11.4855 17.7912 10.419 17.4447C9.35256 17.0982 8.42319 16.4229 7.76409 15.5158C7.10499 14.6086 6.75 13.5161 6.75 12.3947C6.75 11.2734 7.10499 10.1809 7.76409 9.27373C8.42319 8.36656 9.35257 7.69134 10.419 7.34483C11.2977 7.05934 12.2322 7.00907 13.1306 7.19403C13.4497 7.25973 13.6191 7.59605 13.5184 7.90594L12.3108 11.6225C12.1869 12.0037 12.4711 12.3947 12.8719 12.3947H16.7797Z"
      fill="white"
    />
  </svg>
);

export default AutoInsightPareto;
