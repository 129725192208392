export default {
  saveSuccessToast: "修改成功",
  addSuccessToast: "添加成功",
  editModalTitle: "编辑同义词",
  addModalTitle: "添加同义词",
  okBtnText: "确认",
  dimensionValue: "维度值",
  dimensionValueErrMsg: "请输入维度值",
  dimensionValueSearchPlaceholder: "请输入想搜索的维度值",
  notFoundContent1: "没有维度值",
  notFoundContent2: "未找到，请尝试其他词。",
  synonymTip: "多个同义词请用英文逗号(,)隔开",
  synonymErrMsg: "请输入同义词",
};
