import React from "react";

const CryFace = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM57.7778 41.1111C61.4597 41.1111 64.4444 38.1263 64.4444 34.4444C64.4444 30.7625 61.4597 27.7778 57.7778 27.7778C54.0959 27.7778 51.1111 30.7625 51.1111 34.4444C51.1111 38.1263 54.0959 41.1111 57.7778 41.1111ZM34.4444 30.5556C34.4444 33.9306 31.7084 36.6667 28.3333 36.6667C24.9583 36.6667 22.2222 33.9306 22.2222 30.5556C22.2222 27.1805 24.9583 24.4444 28.3333 24.4444C31.7084 24.4444 34.4444 27.1805 34.4444 30.5556ZM60.047 18.8091C59.5365 18.0431 58.5017 17.8361 57.7358 18.3467C56.9699 18.8572 56.7629 19.892 57.2734 20.6579C59.1981 23.5452 62.8519 28.0951 68.9669 29.9296C69.8486 30.1941 70.7777 29.6938 71.0422 28.8121C71.3067 27.9305 70.8064 27.0013 69.9247 26.7368C64.9286 25.238 61.8258 21.4774 60.047 18.8091ZM37.0228 9.47165C36.647 10.3119 35.6612 10.6884 34.8209 10.3126C31.9044 9.00815 27.1802 9.57623 23.7196 12.6952C23.0358 13.3114 21.9819 13.2567 21.3657 12.573C20.7495 11.8892 20.8042 10.8354 21.4879 10.2191C25.8137 6.32038 31.9263 5.36637 36.1819 7.26975C37.0221 7.64557 37.3986 8.63139 37.0228 9.47165ZM53.6497 62.6426C54.1886 63.3888 55.2304 63.5568 55.9766 63.0179C56.7228 62.4789 56.8909 61.4371 56.352 60.6909C48.5713 49.9177 36.461 50.067 31.071 50.9065C30.1615 51.0481 29.5391 51.9003 29.6807 52.8098C29.8224 53.7193 30.6745 54.3418 31.584 54.2001C36.5514 53.4265 46.9859 53.4158 53.6497 62.6426Z"
      fill="currentColor"
    />
  </svg>
);

export default CryFace;
