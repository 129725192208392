export default {
  noContentTip: "No Content",
  messageCenter: "Message Center",
  clearUnread: "Clear unread",
  deleteBtnText: "Delete",
  multiSelect: "Multiple selection",
  deleteSelectedMessageConfirmTitle: "Are you sure you want to delete the message?",
  agree: "Agree",
  refuse: "Reject",
  noMessageTip: "No message",
  viewNewMessage: "View new messages",
  cancel: "Cancel",
  selectAll: "Select All",
  noMessage: "No message",
  message: "Messages",
  agreed: "Agreed",
  rejected: "Rejected",
  systemMessages: "System Messages",
  reportReminder: "Dashboard Notification",
  workspaceReminder: "Workspace Notification",
  permissionTransfer: "Permission Transfer",
  permissionNotification: "Permission Notification",
  permissionApplication: "Permission Application",
  feedbackAudit: "Feedback Audit",
  workspaceMessages: "Workspace Messages",
  auditedMessages: "Audit Messages",
  currentPermissions: "Current Permissions",
  userDemand: "User Demand",
  goToTheBackgroundToModify: "Go to workspace settings",
  remarks: "Remarks",
  workspaceUsersWith: "Workspace users can view this dashboard with ",
  viewWithPermissions: " permission",
  matchNow: "Match Now",
  ignore: "Ignore",
  processed: "Processed",
  ignored: "Ignored",
  existingField: "Existing fields",
  currentMatch: "Current Match",
  requestedMatch: "Requested Match",
  unconfiguredThenAddToWorkspace: "If the demand contains unconfigured measures or dimensions, please add them to the workspace first",
  afterAgreeWillBe: "After agreeing, this field will become ",
  visibleToHasPermissions: "'s synonym, visible to users with permissions in the workspace.",
  createNewRules: "After agreeing, a new custom rule will be created, visible to users with permissions in the workspace.",
  afterAgreeToDeleteNowRule: "After agreeing, the current custom rule will be deleted, canceling the match of ",
  matchingOf: "",
  afterAgreeToDeleteOldRule: "After agreeing, the custom rule will be deleted,",
  willBe: "Will become",
  afterAgreeToReplaceRule: "After agreeing, the original custom rule will be replaced, visible to users with permissions in the workspace.",
  haveBeenDeleted: "Deleted",
  okText: "Ok",
  rejectTheFeedback: "Reject the feedback",
  agreeAfterAdding: "If necessary, go to the workspace settings to add and then agree, or reject this feedback.",
  agreeToMatchOther: "Agree to match other valid fields.",
  agreeToCurrentMatch: "Agree to current match",
  agreeLater: "Agree later",
  nowMatch: "Current Match",
  originalMatch: "Original Match",
  nowMatched: "Current Match",
  matchingField: "Matched fields",
  administrators: "Admin",
  adopt: "Approved",
  handle: "Processed",
  already: " has been ",
  FeedbackYourSubmitted: "The feedback you submitted ",
  nowUnmatched: "Custom rule removed",
  logOff: "Deleted",
  goToThePCForProcessing: "Please go to the desktop to process",
  processedOnPC: "Processed on the desktop",
  notCurrently: "No ",
  contactTheAdministrator: "Data permissions, contact admin to enable search.",
  null: "None",
  useThirdPartyLogin: "To successfully receive push messages, please use the corresponding third-party login on your next login.",
  bindMailboxForYou: "To successfully receive push messages, please contact the system administrator to bind your mailbox for you.",
  canCreateOrEditPin: "You can create and edit dashboards, and download data in cards",
  PinFrozen:
    "You can no longer create or edit dashboards, you have read-only (user) access to current dashboard, you can no longer download data in chart",
  noMessageText1: "There was a problem loading, please try again in a few minutes",
  formerNoMessageText2: "or to",
  latterNoMessageText2: "page",
  clickToViewDetails: "Click to view details",
  readAll: "Mark all as read",
  viewAll: "View all",
  all: "All",
  onlyShowPending: "Show only pending",
  deleteToast: "Deleted successfully.",
};
