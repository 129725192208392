import { encodeEndStripBase64 } from "@/utils/base64";

const getEncodeBase64Arr = strArr => strArr.map(encodeEndStripBase64).join(",");
export default class TokenProp {
  // 是 ...
  static getInProp = strArr => `$in_[${getEncodeBase64Arr(strArr)}]`;

  // 不是 ...
  static getNotInProp = strArr => `$nin_[${getEncodeBase64Arr(strArr)}]`;

  // 以（江）开头 $sWith_[base64_encode(江)]
  static getStartWithProp = strArr => `$sWith_[${getEncodeBase64Arr(strArr)}]`;

  // "以（江）结尾 $eWith_[base64_encode(江)]
  static getEndWithProp = strArr => `$eWith_[${getEncodeBase64Arr(strArr)}]`;

  // 包含（江，河） $likeIn_[base64_encode(江), base64_encode(河)]
  static getLikeInProp = strArr => `$likeIn_[${getEncodeBase64Arr(strArr)}]`;

  // 排除（江，河） $nlikeIn_[base64_encode(江), base64_encode(河)]
  static getNotLikeInProp = strArr => `$nlikeIn_[${getEncodeBase64Arr(strArr)}]`;

  // 介于 ... 之间
  static getInRangeProp = arr => `inRange_${encodeEndStripBase64(arr[0])}:${encodeEndStripBase64(arr[1])}`;

  // 在 ... 之间 dateRange_2021010120220101
  static getDateRangeProp = arr => `dateRange_${arr[0]}${arr[1]}`;

  // 在 ... 之间 datetimeRange_2021010108101520220101081015
  static getDateTimeRangeProp = arr => `datetimeRange_${arr[0]}${arr[1]}`;

  // 前 xx （季度） "dateBefore_3Q"
  static getDateBeforeProp = time => `dateBefore_${time}`;

  // 至今
  static getDatePoint = time => `datePoint_${time}`;

  static UNKNOWN = "unknown";

  // 等于
  static EQUAL = "$eq";

  // 不等于
  static NOT_EQUAL = "$ne";

  // 为空
  static NULL = "$null";

  // 不为空
  static NOT_NULL = "$nnull";

  // 小于等于(≤)
  static LESS_EQUAL_THAN = "$lte";

  // 小于(<)
  static LESS_THAN = "$lt";

  // 大于等于(>=)
  static GREATER_EQUAL_THAN = "$gte";

  // 大于(>)
  static GREATER_THAN = "$gt";

  static IN_RANGE = "$inRange";

  static IN = "$in";

  static NOT_IN = "$nin";

  static LIKE_IN = "$likeIn";

  static LIKE = "$like";

  static NOT_LIKE_IN = "$nlikeIn";

  static NOT_LIKE = "$nlike";

  static START_WITH = "$sWith";

  static END_WITH = "$eWith";

  static IS_NULL = "$null";

  static IS_NOT_NULL = "$nnull";

  // 语义校准规则，prop 格式
  static getFeedbackProp = id => `shortcut_${id}`;
  // 自定义数据集规则，prop 格式
  static getCustomDatasetProp = id => `ds${id}`;
}
