export default {
  changePasswordTitle: "Change Password",
  oldPassword: "Please enter old password",
  newPassword: "Please enter a new password",
  inputErrorTip: "The passwords entered twice are inconsistent",
  inputOldPasswordAgain: "Enter the new password again",
  cancelBtn: "Cancel",
  okBtn: "Ok",
  changeSuccessTip: "Successfully modified",
};
