import React from "react";

const LeftArrow = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99193 3.65608L8.92261 3.57757C8.62221 3.27717 8.14951 3.25406 7.8226 3.50825L7.7441 3.57757L1.91076 9.41091L1.87627 9.44754L1.8303 9.50407L1.77058 9.59678L1.72604 9.69072L1.69656 9.7785L1.67244 9.90175L1.66669 10.0002L1.66901 10.0629L1.68355 10.1674L1.70814 10.2603L1.74475 10.3528L1.78835 10.4341L1.84976 10.5214L1.91076 10.5894L7.7441 16.4228C8.06954 16.7482 8.59717 16.7482 8.92261 16.4228C9.22301 16.1223 9.24612 15.6497 8.99193 15.3227L8.92261 15.2442L4.51335 10.8335H17.5C17.9603 10.8335 18.3334 10.4604 18.3334 10.0002C18.3334 9.53993 17.9603 9.16683 17.5 9.16683H4.51169L8.92261 4.75609C9.22301 4.45568 9.24612 3.98299 8.99193 3.65608L8.92261 3.57757L8.99193 3.65608Z"
      fill="currentColor"
    />
  </svg>
);

export default LeftArrow;
