import React from "react";

const ForecastInfo = prop => (
  <svg {...prop} width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948211 13.6761 0 11.3869 0 9C0 6.61305 0.948211 4.32387 2.63604 2.63604C4.32387 0.948211 6.61305 0 9 0C11.3869 0 13.6761 0.948211 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9ZM8.1 9.9V12.6C8.1 12.8387 8.19482 13.0676 8.3636 13.2364C8.53239 13.4052 8.7613 13.5 9 13.5C9.23869 13.5 9.46761 13.4052 9.6364 13.2364C9.80518 13.0676 9.9 12.8387 9.9 12.6V9.9C9.9 9.6613 9.80518 9.43239 9.6364 9.2636C9.46761 9.09482 9.23869 9 9 9C8.7613 9 8.53239 9.09482 8.3636 9.2636C8.19482 9.43239 8.1 9.6613 8.1 9.9ZM9 4.95C8.64196 4.95 8.29858 5.09223 8.04541 5.34541C7.79223 5.59858 7.65 5.94196 7.65 6.3C7.65 6.65804 7.79223 7.00142 8.04541 7.25459C8.29858 7.50777 8.64196 7.65 9 7.65C9.35804 7.65 9.70142 7.50777 9.95459 7.25459C10.2078 7.00142 10.35 6.65804 10.35 6.3C10.35 5.94196 10.2078 5.59858 9.95459 5.34541C9.70142 5.09223 9.35804 4.95 9 4.95Z"
      fill="currentColor"
      fillOpacity="0.25"
    />
  </svg>
);

export default ForecastInfo;
