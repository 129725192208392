export default {
  settings: "设置",
  personalSettings: "个人设置",
  username: "用户名称",
  password: "密码",
  largeFontMode: "大字模式",
  hasSet: "已设置",
  hasNotSet: "未设置",
  changePassword: "修改密码",
  usernameEmptyErrorToast: "用户名称不能为空",
  save: "保存",
  saveSuccessToast: "保存成功",
  timeDataIntelligentAnalysis: "时间数据智能分析",
  autoDisplayDimensionYoyAnalysis: "自动展示维度同比分析",
  autoDisplayDimensionRingAnalysis: "自动展示维度环比分析",
  followWorkspaceSettings: "跟随工作区设置",
  alwaysOpen: "始终打开",
  alwaysClose: "始终关闭",
  smartTip: "智能提示",
  differentTipBoxOfSearchTip: "根据您的搜索行为切换不同的提示框",
  searchTipSettings: "搜索提示设置",
  defaultFieldTip: "默认字段提示",
  defaultQuestionTip: "默认问题提示",
  switchToQuestionTipManuallyTip: "任何情况对焦搜索框都给出字段提示，可手动切换到问题提示",
  switchToFieldTipManuallyTip: "任何情况对焦搜索框都给出问题提示，可手动切换到字段提示",
  projectSettings: "工作区设置",
  confirm: "确定",
  oldPassword: "旧密码",
  newPassword: "新密码",
  confirmNewPassword: "确认新密码",
  passwordTooShortToast: "密码不能小于8位",
  passwordTooLongToast: "密码不能大于32位",
  passwordInconsistentToast: "两次输入密码不一致",
  modifySuccessToast: "修改成功",
  enterOldPassword: "请输入旧密码",
  enterNewPassword: "请输入新密码",
  enterNewPasswordAgain: "请再次输入新密码",
  wpsPluginManagement: "WPS 插件管理",
  otherSettings: "其他设置",
  wpsTip: "点击进入新页面安装、卸载 WPS 插件",
  userNameLabel: "用户名称",
  userNameInputPrompt: "请输入用户名称",
  passwordLabel: "密码",
  timeDataIntelligentAnalysisLabel: "时间数据智能分析",
  autoDisplayDimensionYoyAnalysisLabel: "自动展示维度同比分析",
  autoDisplayDimensionRingAnalysisLabel: "自动展示维度环比分析",
  insightsTypeSwitch: "智能解析类型开关",
  saveSettings: "保存设置",
  enterTourBtnText: "再次进入新手引导",
  insightSettingsTabs: "智能解析设置",
  basicInsightTitle: "基础解析",
  dimensionInsightTitle: "维度相关解析",
  trendInsightTitle: "趋势类解析",
  outlierLabel: "异常值",
  outlierDesc: "展示数据中显著高于或低于其他值的点",
  maximumLabel: "最大值",
  minimumLabel: "最小值",
  averageLabel: "平均值",
  statisticsLabel: "离散统计",
  paretoLabel: "80/20构成",
  paretoDesc: "判断数据是否符合二八分布，即这组数据中，最重要的维度只占其中一小部分，约20%，其余80%尽管是多数，却是次要的",
  ringOutlierLabel: "环比异常值",
  ringOutlierDesc: "展示数据中增速或降幅显著高于其他时间的点",
  seasonalityLabel: "周期性波动",
  seasonalityDesc: "判断数据在每周/年中是否存在固定某天/月最高或最低",
  trendLabel: "总体变化趋势",
  trendDesc: "判断数据是否整体呈现上升或下降趋势",
  changePointLabel: "趋势变化点",
  changePointDesc: "判断数据趋势是否存在拐点",
  breakoutLabel: "突破性增长",
  breakoutDesc: "判断数据是存在增幅或降幅加速的情况",
  volatilityLabel: "波动异常性",
  volatilityDesc: "判断数据是否存在大幅度的波动",
};
