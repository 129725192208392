export default {
  deleteSuccessToast: "删除成功",
  editBtnText: "编辑",
  timeSeries: "时间维度",
  deleteBtnText: "删除",
  deleteConfirmTitle: "确认删除该时间维度？该操作无法撤销",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  id: "ID",
  keyword: "关键词",
  alias: "别名",
  minTimeUnit: "最小时间单位",
  timeUnit: "默认时间单位",
  dataType: "数据类型",
  operations: "操作",
  searchInputPlaceholder: "请输入想搜索的时间维度名称",
  addBtnText: "添加时间维度",
  current: "当前时间维度：",
  tableDeleteConfirmTitle: "确认删除该时间维度",
  tableDeleteConfirmContent1: "该时间维度已有应用模型。",
  tableDeleteConfirmContent2: "确认后会在所有包含该时间维度的模型中移除。",
  tableDeleteConfirmContent3: "该操作无法撤销",
  pruneButtonText: "清空无效维度",
  pruneButtonTip: "清空没有被模型使用的时间维度",
  pruneConfirmTitle: "确认删除无效的时间维度",
  pruneConfirmContent: "确认后，没有应用模型的时间维度将被清空。",
};
