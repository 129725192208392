export default {
  modeAddUser: "新增用户",
  modeUpdateUser: "更新用户",
  uploadCSVModalContent: "当前上传文件仅支持 .csv 格式",
  uploadErrorInFileFormat: "数据文件格式出错",
  okText: "确认",
  cancelText: "取消",
  uploadSuccessToast: "用户信息上传成功",
  uploadErrorToast: "用户信息上传错误",
  fileModelName: "系统成员模板.csv",
  modalTitle: "批量上传",
  nextBtnText: "下一步",
  uploadLabel: "更新方式",
  delimiterLabel: "分隔符选择",
  downloadModelText: "请先下载模板，按模板格式填写后上传",
  downloadDataText: "请先下载数据，更新文件后上传",
  supportTip: "支持格式：.csv ，仅支持 utf-8，gbk，ascii，utf-16 编码",
  selectBtn: "选择文件",
  downloadModelBtn: "下载模板",
  downloadDataBtn: "下载用户数据",
  uploadErrorTitle: "上传失败",
  uploadErrorDesc: "请下载错误信息，根据信息修改后再次上传",
  uploadErrorBtnText: "下载错误信息文件",
  errorFilename: "报错信息",
  errorRow: "报错行数",
  errorMessage: "报错信息",
  errorField: "报错列",
  username: "用户名称",
  loginName: "登录名",
  password: "初始密码",
  phone: "手机号",
  email: "邮箱",
  tier: "账号类型",
  type: "账号属性",
};
