import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.SynonymErrorCode.${id}`);

export default class SynonymErrorCode {
  static CODE = {
    30101: getText("30101"),
    30102: getText("30102"),
    30103: getText("30103"),
    30201: getText("30201"),
    30301: getText("30301"),
    30401: getText("30401"),
    30501: getText("30501"),
    30502: getText("30502"),
    30503: getText("30403"),
  };

  static getErrorCodeText = v => this.CODE[v] || "";
}
