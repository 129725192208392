import React from "react";

const DataAlarmOutlined = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.16675 1.5C7.16675 1.03976 7.53984 0.666664 8.00008 0.666664C8.46032 0.666664 8.83342 1.03976 8.83342 1.5V2.33333C8.83342 2.79357 8.46032 3.16666 8.00008 3.16666C7.53984 3.16666 7.16675 2.79357 7.16675 2.33333V1.5ZM12.2499 15.6667V9.91667C12.2499 7.56946 10.3471 5.66667 7.99992 5.66667C5.65271 5.66667 3.74992 7.56945 3.74992 9.91666V15.6667H12.2499ZM2.08325 9.91666V15.6667L1.33333 15.6667C0.873096 15.6667 0.5 16.0398 0.5 16.5C0.5 16.9602 0.873096 17.3333 1.33333 17.3333L2.08325 17.3333H3.74992H12.2499H13.9166L14.6667 17.3333C15.1269 17.3333 15.5 16.9602 15.5 16.5C15.5 16.0398 15.1269 15.6667 14.6667 15.6667L13.9166 15.6667V9.91667C13.9166 6.64898 11.2676 4 7.99992 4C4.73223 4 2.08325 6.64898 2.08325 9.91666ZM8.00008 7.41666C7.53984 7.41666 7.16675 7.78976 7.16675 8.25V11.0833C7.16675 11.5436 7.53984 11.9167 8.00008 11.9167C8.46032 11.9167 8.83342 11.5436 8.83342 11.0833V8.25C8.83342 7.78976 8.46032 7.41666 8.00008 7.41666ZM8.00008 12.5833C7.53984 12.5833 7.16675 12.9564 7.16675 13.4167C7.16675 13.8769 7.53984 14.25 8.00008 14.25C8.46032 14.25 8.83342 13.8769 8.83342 13.4167C8.83342 12.9564 8.46032 12.5833 8.00008 12.5833ZM13.7776 3.15332C14.1762 2.9232 14.6858 3.05976 14.9159 3.45834C15.1461 3.85691 15.0095 4.36657 14.6109 4.59669L13.8892 5.01336C13.4906 5.24348 12.981 5.10691 12.7509 4.70834C12.5208 4.30976 12.6573 3.8001 13.0559 3.56998L13.7776 3.15332ZM1.14071 3.45834C1.37083 3.05976 1.88048 2.9232 2.27906 3.15332L3.00075 3.56998C3.39933 3.8001 3.53589 4.30976 3.30577 4.70834C3.07565 5.10691 2.56599 5.24348 2.16742 5.01336L1.44573 4.59669C1.04715 4.36657 0.910588 3.85691 1.14071 3.45834Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DataAlarmOutlined;
