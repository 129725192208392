import React from "react";

const Ashbin = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.27308 6.22727C8.62454 6.22727 8.90945 6.51218 8.90945 6.86364V10.6818C8.90945 11.0333 8.62454 11.3182 8.27308 11.3182C7.92163 11.3182 7.63672 11.0333 7.63672 10.6818V6.86364C7.63672 6.51218 7.92163 6.22727 8.27308 6.22727Z"
      fill="black"
      fillOpacity="0.25"
    />
    <path
      d="M6.36399 6.86364C6.36399 6.51218 6.07908 6.22727 5.72763 6.22727C5.37617 6.22727 5.09126 6.51218 5.09126 6.86364V10.6818C5.09126 11.0333 5.37617 11.3182 5.72763 11.3182C6.07908 11.3182 6.36399 11.0333 6.36399 10.6818V6.86364Z"
      fill="black"
      fillOpacity="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.81853 3.04545V2.40909C3.81853 1.90277 4.01967 1.41718 4.3777 1.05916C4.73572 0.701136 5.2213 0.5 5.72763 0.5H8.27308C8.7794 0.5 9.26499 0.701136 9.62301 1.05916C9.98104 1.41718 10.1822 1.90277 10.1822 2.40909V3.04545L12.7276 3.04545C13.0791 3.04545 13.364 3.33036 13.364 3.68182C13.364 4.03327 13.0791 4.31818 12.7276 4.31818H12.0913V12.5909C12.0913 13.0972 11.8901 13.5828 11.5321 13.9408C11.1741 14.2989 10.6885 14.5 10.1822 14.5H3.81853C3.31221 14.5 2.82663 14.2989 2.4686 13.9408C2.11058 13.5828 1.90944 13.0972 1.90944 12.5909V4.31818H1.27308C0.921628 4.31818 0.636719 4.03327 0.636719 3.68182C0.636719 3.33036 0.921628 3.04545 1.27308 3.04545L3.81853 3.04545ZM5.27765 1.95911C5.39699 1.83977 5.55885 1.77273 5.72763 1.77273H8.27308C8.44185 1.77273 8.60372 1.83977 8.72306 1.95911C8.8424 2.07846 8.90944 2.24032 8.90944 2.40909V3.04545H5.09126V2.40909C5.09126 2.24032 5.15831 2.07846 5.27765 1.95911ZM3.18217 12.5909V4.31818H10.8185V12.5909C10.8185 12.7597 10.7515 12.9215 10.6321 13.0409C10.5128 13.1602 10.3509 13.2273 10.1822 13.2273H3.81853C3.64976 13.2273 3.4879 13.1602 3.36856 13.0409C3.24922 12.9215 3.18217 12.7597 3.18217 12.5909Z"
      fill="black"
      fillOpacity="0.25"
    />
  </svg>
);

export default Ashbin;
