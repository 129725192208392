export default {
  uploadModalTitle: "Bulk upload",
  okBtnText: "Next step",
  uploadTip: "Please download the template first, update the file according to the template format and upload it",
  formatTip: "Supported format:. csv, only utf-8,gbk,ascii,sutf-16 encoding is supported",
  uploadBtnText: "Select file",
  downloadBtnText: "Download template",
  invalidFormatConfirmTitle: "Data file format error",
  invalidFormatConfirmContent: "Currently uploaded files only support .csv format",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  started: "Start execution",
  success: "Upload successfully",
  failure: "Upload failed",
  inProgress: "Load data",
  uploadErrMsg1: "Tencent cloud download file failed",
  uploadErrMsg2: "Parsing file failed",
  uploadErrMsg3:
    "The file does not contain valid information. Please ensure that the file separator is correct and check for invalid keywords or synonyms (only three special characters: slash(/), space( ), and dash(_) are supported, and cannot be pure numbers)",
  uploadErrMsg4: "File decoding error, please upload a UTF-8 format file",
  uploadErrMsgTitle: "File upload error",
  uploadErrMsgTitle2: "Invalid file",
  delimiterLabel: "Select delimiter",
};
