import React from "react";

const Toggle = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.33366 1.52393L2.66699 4.19059M2.66699 4.19059L5.33366 6.85726M2.66699 4.19059L12.0003 4.19059C12.7367 4.19059 13.3337 4.78755 13.3337 5.52392V5.52392"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6663 9.14282L13.333 11.8095M13.333 11.8095L10.6663 14.4762M13.333 11.8095L3.33301 11.8095C2.59663 11.8095 1.99967 11.2125 1.99967 10.4762V10.4762"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Toggle;
