export default {
  unsupprotWebAudioApi: "Browser does not support WebAudioApi related interfaces",
  unsupportWebSocket: "Browser does not support WebSocket",
  connectingToTheNetwork: "Connecting to the network...",
  networkError: "There seems to be a problem with the network, please",
  retry: "Retry",
  checkMicrophone: "Please check if the microphone is turned on",
  goAhead: "Please speak",
  try: "Try:",
  identityFailure: "Sorry, speech content was not recognized, please",
  errorEnvironment: "The current environment cannot obtain browser recording function, please try in HTTPS environment.",
  lackOfRecordFunction: "Unable to obtain browser recording function, please upgrade the browser or use Chrome.",
  mobileIdentityFailure: "Sorry, the voice content was not recognized",
  mobileNetworkError: "There seems to be something wrong with the network",
};
