import { CloseOutlined } from "@ant-design/icons";
import _ from "lodash";
import React from "react";
import { message } from "@/components/datarc";
import { ErrorBear, InfoBear, SuccessBear, WarningBear } from "@/components/SVGIcons";
import { uuidV4 } from "@/utils/common";

import styles from "./index.module.scss";

export const MSG_KEY = "select-chart-msg-key";

export default class Toast {
  static getIcon = type => {
    if (type === "success") return SuccessBear;
    if (type === "error") return ErrorBear;
    if (type === "warning") return WarningBear;
    return InfoBear;
  };

  // 保存 toast 提示的 key，防止出现重复提示
  static toastKeys = {};

  static deleteDefaultToastKey = () => delete Toast.toastKeys[MSG_KEY];

  static deleteToastKey = key => delete Toast.toastKeys[key];

  static destroy = key => {
    message.destroy();
    key ? Toast.deleteToastKey(key) : Toast.deleteDefaultToastKey();
  };

  static getToast({ content, type = "info", duration, hasClose }) {
    const key = _.isString(content) ? content : MSG_KEY; // 用 message 作为 key，不重复报错信息

    // 如果有重复 key 正在显示，啥也不干
    if (Toast.toastKeys[key]) return;

    Toast.toastKeys[key] = true;

    const messageKey = uuidV4();

    message[type]({
      key: messageKey, // 需要保证每次 key 都不同，防止低版本浏览器 key 相同时，提示消息一致不隐藏
      content: (
        <div>
          {content}
          {hasClose && (
            <CloseOutlined
              className={styles.msgClose}
              onClick={() => {
                message.destroy(messageKey);
                delete Toast.toastKeys[key];
              }}
            />
          )}
        </div>
      ),
      duration, // 0 表示不自动消失，调试样式时用到
      onClose: () => {
        // 提示关闭，删除 key
        delete Toast.toastKeys[key];
      },
    });
  }

  static preFormat =
    type =>
    (content, rest = {}) =>
      Toast.getToast({
        type,
        content,
        ...rest,
      });

  static notice = this.preFormat("info");

  static error = this.preFormat("error");

  static success = this.preFormat("success");

  static warning = this.preFormat("warning");
}
