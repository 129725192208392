export default {
  licence: "License: ",
  url: "Site URL: ",
  urlExtra:
    "The URL of the website that users access, also used for generating external links. Incorrect configuration may result in the inability to access external links correctly.",
  urlRequired: "Site URL cannot be empty",
  allowSynchro: "Allow User Synchronization: ",
  allowSynchroExtra:
    "When new users log in through single sign-on or authorization, the system will automatically register an account for them during information synchronization.",
  watermarkTip: "When users select to add a watermark, it will take effect on the global interface and generate a watermark.",
  basicSettingsSuccessfullyUpdated: "Basic settings updated successfully",
  updateConfig: "Update Settings",
  sessionTimeout: "Session Timeout",
  inactivityLogoutTime: "Automatic logout time for inactivity",
  inactivityLogoutTimeTip: "The minimum is 60 (0 can be filled in, 0 indicates disabling the automatic logout function without operation)",
  inactivityLogoutTimeErrorTip: "Please enter 0, or a minimum number of 60",
  freqWorkspaceNum: "Number of frequently used workspaces",
  username: "Customer Identifier",
  expireTime: "Expiration Time: ",
  viewBtnText: "View",
  updateBtnText: "Update",
  formerDaysRemain: "Only",
  latterDaysRemain: "days remain",
  second: "second",
  warningMessage:
    "The current account exceeds the limit (i.e., the number of accounts > the quota), and various system functions cannot be used properly. Please reduce the number of accounts of each type (including inactive, normal, disabled, and dormant accounts) to the quota.",
  urlSuccessfullyUpdated: "Site URL updated successfully",
  urlUnsuccessfullyUpdated: "Failed to update Site URL",
  urlTooltipFormer: "Site URL and the URL set in the server",
  urlConsistentTooltipLatter: "Consistent",
  urlInconsistentTooltipLatter: "Inconsistent, may result in the inability to access external links correctly",
  tier1Label: "Data Expert: Full access to all features in the workspace (including workspace management)",
  tier2Label: "Citizen Data Scientist: Ability to search, view data, download, and generate reports",
  tier3Label: "Citizen Data Explorer: Limited to search and view functionality only",
  defaultAccountTierLabel: "Default Synchronized Account Type:",
  defaultAccountTierHelp:
    "Controls the default account type for users automatically registered during single sign-on or authorization login",
};
