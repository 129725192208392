import React from "react";

const DropArrow = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.23707 6.12065L12.7595 1.00392C13.0597 0.725707 12.8471 0.25 12.4224 0.25L1.37763 0.25C0.952981 0.25 0.740314 0.725706 1.04059 1.00392L6.56298 6.12065C6.74913 6.29312 7.05092 6.29312 7.23707 6.12065Z"
      fill="currentColor"
    />
  </svg>
);

export default DropArrow;
