import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.CardAnswer.${id}`);

export default class TableCellHeight {
  // 类型
  static LOW_NUM = 1;

  static MIDDLE_NUM = 2;

  static HIGH_NUM = 3;

  // 字号
  static LOW = 31;

  static MIDDLE = 53;

  static HIGH = 97;

  // 换行数
  static getRowNum = type => {
    switch (type) {
      case TableCellHeight.MIDDLE:
        return 2;
      case TableCellHeight.HIGH:
        return 4;
      default:
        return 1;
    }
  };

  static linHeightMapping = {
    [TableCellHeight.LOW_NUM]: TableCellHeight.LOW,
    [TableCellHeight.MIDDLE_NUM]: TableCellHeight.MIDDLE,
    [TableCellHeight.HIGH_NUM]: TableCellHeight.HIGH,
  };

  static cellHeightOptions = [
    { label: getText("lowLabel"), value: TableCellHeight.LOW_NUM },
    { label: getText("middleLabel"), value: TableCellHeight.MIDDLE_NUM },
    { label: getText("highLabel"), value: TableCellHeight.HIGH_NUM },
  ];

  static getHeightText = type => {
    switch (type) {
      case TableCellHeight.LOW_NUM:
        return getText("lowLabel");
      case TableCellHeight.MIDDLE_NUM:
        return getText("middleLabel");
      case TableCellHeight.HIGH_NUM:
        return getText("highLabel");
      default:
        return "";
    }
  };
}
