import React from "react";

const MeasureSvg = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8332 6H10.4998L8.74984 11.25L5.24984 0.75L3.49984 6H1.1665"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MeasureSvg;
