import React from "react";

const PinboardTextBox = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.16659 2.6665C1.70635 2.6665 1.33325 3.0396 1.33325 3.49984C1.33325 3.96007 1.70635 4.33317 2.16659 4.33317H5.16665L5.16665 12.4999C5.16665 12.9601 5.53974 13.3332 5.99998 13.3332C6.46022 13.3332 6.83331 12.9601 6.83331 12.4999L6.83331 4.33317H9.83325C10.2935 4.33317 10.6666 3.96007 10.6666 3.49984C10.6666 3.0396 10.2935 2.6665 9.83325 2.6665H2.16659Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M9.50002 6.66652C9.03978 6.66652 8.66669 7.03962 8.66669 7.49986C8.66669 7.96009 9.03978 8.33319 9.50002 8.33319H10.8333V12.4999C10.8333 12.9601 11.2064 13.3332 11.6667 13.3332C12.1269 13.3332 12.5 12.9601 12.5 12.4999V8.33319H13.8334C14.2936 8.33319 14.6667 7.96009 14.6667 7.49986C14.6667 7.03962 14.2936 6.66652 13.8334 6.66652H9.50002Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default PinboardTextBox;
