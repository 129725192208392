export default {
  modelPermission: "模型权限",
  permissionOffTip: "权限未启用，用户使用此项功能时无限制条件",
  saveSuccessToast: "模型权限配置更新成功",
  enableConfirmTitle: "确认开启模型权限？",
  enableConfirmTip: "开启后需要配置权限内容并更新，否则可能导致搜索结果无数据",
  methods: "控制方式",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  refreshBtnText: "刷新",
  allowAccessTip: "请将需要加入名单的资源从左侧加入右侧，只有加入右侧名单的资源才允许被使用",
  denyAccessTip: "请将需要加入名单的资源从左侧加入右侧，加入右侧名单的资源将会被禁止使用",
  allModels: "所有模型",
  selectedModels: "应用名单",
  leftParenthesis: "（",
  rightParenthesis: "）",
  saveBtnText: "更新",
};
