export default {
  name: "User group name",
  nameInputPlaceholder: "Please enter a user group name",
  nameInputErrMsg: "Please enter a user group name",
  description: "User group introduction",
  descriptionInputPlaceholder: "Please enter the user group introduction",
  numberUsers: "Number of users",
  autoAddUsers: "Add users automatically",
  disableDownload: "Disable download",
  on: "Open",
  off: "Off",
  operations: "Settings",
  settingsBtn: "Settings",
  editBtnText: "Members Settings",
  deleteBtnText: "Delete",
  deleteConfirmTitle: "Confirm to delete this user group?",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  addBtnText: "Add user group",
  searchGroupInputPlaceholder: "Please enter the information of the user group you want to search for",
  addUserGroupModalTitle: "Add user group",
  saveSuccessToast: "Modified successfully",
  groupOptionsModalTitle: "User group settings",
};
