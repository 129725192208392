import React from "react";

const EmptySearchFace = prop => (
  <svg {...prop} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60 110C87.6142 110 110 87.6142 110 60C110 32.3858 87.6142 10 60 10C32.3858 10 10 32.3858 10 60C10 87.6142 32.3858 110 60 110ZM56.6667 80C60.3486 80 63.3333 77.0152 63.3333 73.3333C63.3333 69.6514 60.3486 66.6667 56.6667 66.6667C52.9848 66.6667 50 69.6514 50 73.3333C50 77.0152 52.9848 80 56.6667 80ZM32.2222 68.3333C32.2222 71.7084 29.4862 74.4444 26.1111 74.4444C22.736 74.4444 20 71.7084 20 68.3333C20 64.9583 22.736 62.2222 26.1111 62.2222C29.4862 62.2222 32.2222 64.9583 32.2222 68.3333ZM65.453 64.9968C66.3717 65.0542 67.163 64.3561 67.2204 63.4374C67.2778 62.5187 66.5796 61.7274 65.661 61.67C57.4852 61.159 52.8941 56.0566 51.2032 53.52C50.6927 52.7541 49.6579 52.5471 48.892 53.0576C48.1261 53.5682 47.9191 54.603 48.4296 55.3689C50.4421 58.3879 55.851 64.3967 65.453 64.9968ZM16.6833 58.0975C16.8135 57.1863 17.6577 56.5532 18.5689 56.6833C21.7317 57.1352 26.1174 55.2894 28.5865 51.3388C29.0744 50.5583 30.1026 50.321 30.8832 50.8088C31.6638 51.2967 31.9011 52.3249 31.4132 53.1055C28.3268 58.0438 22.7125 60.6425 18.0975 59.9832C17.1863 59.853 16.5531 59.0088 16.6833 58.0975ZM33.1834 96.0916C33.7862 96.7872 34.8388 96.8624 35.5344 96.2595C36.23 95.6567 36.3052 94.6041 35.7023 93.9085C34.0093 91.955 33.1608 90.3036 32.836 89.0519C32.5107 87.7984 32.7295 87.062 32.9748 86.7015C33.2014 86.3685 33.6175 86.1039 34.3303 86.1195C35.0697 86.1357 36.1163 86.4713 37.293 87.4126C38.0117 87.9877 39.0606 87.8711 39.6356 87.1523C40.2106 86.4336 40.094 85.3848 39.3753 84.8097C37.7741 83.5289 36.0427 82.8228 34.4032 82.787C32.737 82.7505 31.1734 83.4234 30.2188 84.8265C29.283 86.2021 29.1193 88.0004 29.6096 89.8893C30.1002 91.78 31.2654 93.8785 33.1834 96.0916Z"
      fill="currentColor"
    />
  </svg>
);

export default EmptySearchFace;
