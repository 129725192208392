export default class ThemeModeType {
  static LIGHT = "0";

  static DARK = "1";

  static FOLLOW_SYSTEM = "-1";

  static choice = [ThemeModeType.LIGHT, ThemeModeType.DARK];

  static getText = themeMode => {
    if (themeMode === ThemeModeType.DARK) return "dark";
    return "light";
  };
}
