import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.DataTableType.${id}`);

export default class DataTableType {
  static ALL = 0;

  static CUSTOM_TABLE = 1;

  static DATABASE_TABLE = 2;

  static choices = [DataTableType.ALL, DataTableType.CUSTOM_TABLE, DataTableType.DATABASE_TABLE];

  static getText = v => {
    if (v === DataTableType.ALL) return getText("allTableTypes");
    if (v === DataTableType.CUSTOM_TABLE) return getText("customTable");
    if (v === DataTableType.DATABASE_TABLE) return getText("databaseTable");
    return "";
  };

  static getOptions = () => DataTableType.choices.map(item => ({ label: DataTableType.getText(item), value: item }));

  // 不支持自定义表的选项
  static getOnlyDBOptions = () => [DataTableType.DATABASE_TABLE].map(item => ({ label: DataTableType.getText(item), value: item }));
}
