export default {
  save: "Save",
  openSuccessToast: "Opened successfully",
  closeSuccessToast: "Closed successfully",
  changeAuthSuccessToast: "User permissions updated successfully",
  discoverTitle: "Add chart",
  createCopySuccessToast: "Copy created",
  createCopyFailToast: "Error creating copy, please try again later",
  createCopy: "Duplicate",
  confirm: "Confirm",
  cancel: "Cancel",
  editChartTitle: "Edit chart",
  saveChanges: "Save changes",
  giveUpChanges: "Discard changes",
  confirmExit: "Changes to chart are not saved, do you want to save before exit?",
  copyFailToast: "Copy failed, please try again later",
  noShortUrl: "Failed to load link, please try again later",
  copySuccessToast: "Copied successfully",
  copyLink: "Copy Link",
  noUserPrompt: "You have not invited any user or user group",
  modifyPinboardSuccessToast: "Changes to dashboard saved",
  saveErrorToast: "Dashboard name can not be empty",
  pinboardInfo: "Dashboard description",
  pinboardTitle: "Dashboard title",
  rename: "Rename",
  owner: "Owner",
  createTime: "Date created",
  updatedAt: "Modified",
  description: "Description",
  tag: "Tags",
  change: "Modify",
  noDescription: "No description",
  noTag: "No tags",
  renamePinboard: "Rename dashboard",
  changeDescription: "Edit description",
  currentMessage: "Current dashboard messages",
  clearUnread: "Clear unread messages",
  message: "Messages",
  changeUserAuthSuccessToast: "User permissions modified",
  addUserAuthSuccessToast: "User permissions added",
  applyForOwner: "Request sent to owner",
  addSpecifyUser: "+ Users",
  invite: "Invite",
  invitePrompt: "Invited user can access after signing in",
  shareToWorkspace: "Share to workspace",
  unSharePrompt: "Share not enabled, only owner and co-editor can view dashboard.",
  sharePrompt: "Users in current workspace can view dashboard with read-only access. ",
  openToShare: "Share enabled",
  notOpenToShare: "Share disabled",
  authSetting: "Permission settings",
  inviteSetting: "Share settings",
  sendMessage: "Send message",
  manageVisitor: "Manage visitors",
  allowSelfCanReadCreateFork: "Allow read-only user to create copy",
  allowSelfCanEditShareAll: "Allow edit user to share to workspace",
  noUserMatched: "No user/user group found, please update keyword",
  noUserInWorkspace: "No user/user group in workspace, please add in workspace settings",
  searchText: "Search user/user group",
  complete: "Complete",
  selectAll: "Select all",
  volumeSetting: "Batch settings",
  changeAuth: "Change permissions",
  noUserAvailable: "No user/user group",
  disableEditTip: "You have selected a search account, unable to grant edit permission.",
  share: "Share",
  next: "Next",
  sendInvitation: "Send invitation",
  allow: "Allow",
  selfCanRead: "Read-only",
  user: " user",
  selfCanEdit: "Edit",
  remove: "Remove",
  allUsers: "All users/user groups",
  selectedUserPermissionSetting: "Selected user permission settings:",
  specifyUser: "Selected users",
  specifyUserModalTip: "The report data that the user actually browses and edits should be based on the user's own data permissions",
  removeAuth: "Revoke access",
  pinboardIntroInputPlaceholder: "Add dashboard description",
  replaceTitle: "Replace card",
  cacheSettingSuccessToast: "Setting successful",
  cacheSettingTitle: "Cache settings",
  cacheTask: "Scheduled cache task",
  cacheScope: "Object settings",
  cacheScopeAll: "Cache for all users with [report permissions]",
  cacheScopeOpen: "Cache only for people who have [opened the report]",
  cacheStartTime: "Task start time",
  cacheTTL: "Cache valid time",
  cacheTips:
    "It is recommended to set up scheduled caching for reports with a large number of query requests during certain periods of time to relieve database pressure",
  cacheHour: "Hour",
  cacheDay: "Day",
  yourAuthWillDowngrade: "Your permissions will be downgraded to Readable",
  createTemplateTipTitle: "Two ways to create custom templates:",
  createTemplate: "Method 1: Create a new report and convert it to a template",
  transformPin: "Method 2: Open an existing document and select 'Convert to template' from the 'More' menu",
  noTemplate: "No templates at this time",
  templateLib: "Template Library",
  searchEmpty: "No results found, please try a different keyword",
  delTemplateTitle: "Are you sure you want to delete this template?",
  delTemplateAfter: "After deletion, this report will be removed from the template library and restored to a regular report.",
  delTemplateButton: "Delete template",
  delTemplateSuccess: "Template deleted successfully",
  transformToTemplate: "Convert to Template",
  searchTemplatePlaceholder: "Search",
  template: "Template",
  allCollaborators: "All collaborators on the report",
  chooseUser: "Choose a user",
  drillDown: "Drill down",
  browse: "Browse",
  errorMessage: "There are users with data permissions that do not meet the settings. Please remove them and try again.",
  haveSelected: "Selected",
};
