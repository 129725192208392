export default {
  sum: "求和",
  sumPrefix: "总",
  avg: "平均值",
  avgPrefix: "平均",
  max: "最大值",
  maxPrefix: "最大",
  min: "最小值",
  minPrefix: "最小",
  latest: "最新",
  latestPrefix: "最新",
  count: "计数",
  countDistinct: "计数（去重）",
  countLabel: "全部计数",
  countDistinctLabel: "去重计数",
  timeInterval: "时间区间",
  hiddenLabel: "不显示",
  dataFormatLabel: "数值格式",
  sortLabel: "排序",
  ascLabel: "升序",
  descLabel: "降序",
  totalLabel: "总计",
};
