import _ from "lodash";
import React, { useState } from "react";
import { CloseIconSvg } from "../SVGIcons";

import styles from "./index.module.scss";

const BaseCloseButton = ({ onClick = _.noop, style = {}, className = "", disabled = false }) => {
  const [isHover, setIsHover] = useState(false);

  return disabled ? (
    <CloseIconSvg className={className} style={style} backgroundOpacity="0" contentOpacity="0.25" cursor="not-allowed" />
  ) : (
    <CloseIconSvg
      className={`${styles.closeIcon} ${className}`}
      style={style}
      backgroundOpacity={isHover ? "0.05" : "0"}
      contentOpacity={isHover ? "0.85" : "0.65"}
      cursor={isHover ? "pointer" : "auto"}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
  );
};

export default BaseCloseButton;
