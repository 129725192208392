export default {
  name: "Name",
  link: "Link",
  paramKey: "URL search key",
  paramKeyExplain:
    "URL search key will replace search keywords in URL. For example, link is `https://google.com/sesarch?q=KEY`, search key can be `KEY`.",
  lastModified: "Creator",
  updatedAt: "Modified",
  operations: "Settings",
  addBtnText: "Add resource",
  addSuccessToast: "Add successful",
  saveSuccessToast: "Update successful",
  deleteConfirmTitle: "Are you sure to delete this resource?",
  confirmBtnText: "Delete",
  deleteSuccessToast: "Delete successful",
  cancelBtnText: "Cancel",
  editBtnText: "Edit",
  deleteBtnText: "Delete",
  externalAssetsTitle: "External resource",
  searchInputPlaceholder: "Please input name",
  editModalTitle: "Edit Resource",
  addModalTitle: "Add Resource",
  editConfirmBtnText: "Save",
  addConfirmBtnText: "Add",
  inputPlaceHolder: "Please input",
  formHelp: "Please input",
};
