export default {
  basicSettings: "Basic Settings",
  memberConfig: "Member Settings",
  user: "User",
  userGroup: "User Group",
  searchModel: "Model Settings",
  model: "Models",
  dimension: "Dimensions",
  quota: "Measures",
  customDataset: "Custom Dataset",
  keyWord: "Keyword Sync Tasks",
  synonym: "Synonyms",
  otherConfig: "Other Settings",
  customTimePeriod: "Custom Date Tags",
  externalResource: "External Resources",
  dataTags: "Date Tags",
  advancedConfig: "Advanced Settings",
  rules: "Scene Giraffe",
  feedback: "Semantic Rules",
  assetManagement: "Resource Management",
};
