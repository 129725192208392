import { getLocaleText } from "@/utils/locale";
import MessageCardDetail from "./MessageCardDetail";

const getText = id => getLocaleText(`pages.Message.${id}`);
export default class MessageChannelTab {
  static Notice = "0";

  static WorkSpace = "1";

  static Audit = "2";

  static getText = source => {
    if (source === this.Notice) return getText("systemMessages");
    if (source === this.WorkSpace) return getText("workspaceMessages");
    if (source === this.Audit) return getText("auditedMessages");
  };

  static NoticeArr = [
    MessageCardDetail.CODE_1001,
    MessageCardDetail.CODE_1003,
    MessageCardDetail.CODE_1004,
    MessageCardDetail.CODE_1101,
    MessageCardDetail.CODE_1102,
    MessageCardDetail.CODE_2101,
    MessageCardDetail.CODE_2102,
    MessageCardDetail.CODE_2103,
  ];

  static WorkSpaceArr = [
    MessageCardDetail.CODE_1901,
    MessageCardDetail.CODE_1902,
    MessageCardDetail.CODE_1903,
    MessageCardDetail.CODE_1904,
    MessageCardDetail.CODE_1905,
    MessageCardDetail.CODE_1906,
    MessageCardDetail.CODE_1907,
    MessageCardDetail.CODE_1908,
    MessageCardDetail.CODE_2901,
    MessageCardDetail.CODE_2902,
    MessageCardDetail.CODE_2903,
    MessageCardDetail.CODE_2904,
  ];

  static AuditArr = [MessageCardDetail.CODE_4901, MessageCardDetail.CODE_4902, MessageCardDetail.CODE_2906];

  static getMessageTypes = channel => {
    if (channel === this.Notice) return this.NoticeArr.join(",");
    if (channel === this.WorkSpace) return this.WorkSpaceArr.join(",");
    if (channel === this.Audit) return this.AuditArr.join(",");
  };

  static getChannelTab = messageType => {
    if (this.NoticeArr.includes(messageType)) return this.Notice;
    if (this.WorkSpaceArr.includes(messageType)) return this.WorkSpace;
    if (this.AuditArr.includes(messageType)) return this.Audit;
  };
}
