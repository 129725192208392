import React from "react";

const Folder = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M16.8843 5.11485H13.9413C13.2081 5.11969 12.512 4.79355 12.0474 4.22751L11.0782 2.88762C10.6214 2.31661 9.9253 1.98894 9.19321 2.00028H7.11261C3.37819 2.00028 2.00001 4.19201 2.00001 7.91884V11.9474C1.99536 12.3904 21.9956 12.3898 21.9969 11.9474V10.7761C22.0147 7.04924 20.6721 5.11485 16.8843 5.11485Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8321 6.54329C21.1521 6.91737 21.3993 7.34768 21.5612 7.81219C21.8798 8.76687 22.0273 9.77012 21.9969 10.7759V16.0289C21.9956 16.4714 21.963 16.9132 21.8991 17.3511C21.7775 18.1238 21.5057 18.8653 21.0989 19.5339C20.9119 19.8569 20.6849 20.155 20.4231 20.4213C19.2383 21.5087 17.665 22.0747 16.0574 21.9919H7.93061C6.32049 22.0741 4.74461 21.5083 3.55601 20.4213C3.2974 20.1545 3.07337 19.8564 2.88915 19.5339C2.48475 18.8658 2.21869 18.1236 2.1067 17.3511C2.03549 16.914 1.99981 16.4718 2 16.0289V10.7759C1.99983 10.3372 2.02357 9.89878 2.07113 9.46264C2.08113 9.38612 2.09614 9.31084 2.11098 9.23635C2.13573 9.11216 2.16005 8.99014 2.16005 8.86812C2.25031 8.34179 2.41496 7.83092 2.64908 7.35077C3.34261 5.86891 4.76525 5.11467 7.09481 5.11467H16.8754C18.1802 5.01376 19.4753 5.40656 20.5032 6.21498C20.6215 6.31535 20.7316 6.42515 20.8321 6.54329ZM6.97033 15.5409H17.0355H17.0533C17.2741 15.5505 17.4896 15.4715 17.6517 15.3214C17.8137 15.1714 17.9088 14.9628 17.9157 14.7423C17.9282 14.5485 17.8644 14.3575 17.7379 14.2099C17.5924 14.0116 17.3618 13.8932 17.1155 13.8905H6.97033C6.51365 13.8905 6.14343 14.2599 6.14343 14.7157C6.14343 15.1714 6.51365 15.5409 6.97033 15.5409Z"
      fill="currentColor"
    />
  </svg>
);
export default Folder;
