export default {
  transferPinboardsModalTitle: "用户资产处理",
  removeAccountConfirmText: "确认并移除原用户",
  cancelBtnText: "取消",
  selectOwnerPlaceholder: "请选择文件变更后的负责人",
  pinboardName: "报告名",
  editAccess: "是否开放编辑",
  true: "是",
  false: "否",
  numberUsers: "编辑人数",
  action: "处理方式",
  transfer: "转移",
  delete: "删除",
  transferTarget: "转移对象",
  transferMethod: "具体处理方式",
  pinboard: "报告",
  alert: "数据告警",
};
