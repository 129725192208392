export default {
  uploadModalTitle: "批量上传",
  okBtnText: "下一步",
  uploadTip: "请先下载模板，按模板格式填写后上传",
  formatTip: "支持格式：.csv ，仅支持 utf-8，gbk，ascii，utf-16 编码",
  uploadBtnText: "选择文件",
  downloadBtnText: "下载模板",
  invalidFormatConfirmTitle: "数据文件格式出错",
  invalidFormatConfirmContent: "当前上传文件仅支持 .csv 格式",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  started: "开始执行",
  success: "上传成功",
  failure: "上传失败",
  inProgress: "加载数据",
  uploadErrMsg1: "腾讯云下载文件失败",
  uploadErrMsg2: "解析文件失败",
  uploadErrMsg3:
    '文件无有效信息，请确保文件分隔符正确，并检查是否含有无效关键词或同义词（仅支持斜杠"/"空格" "、划线线"_"3种特殊字符，且不能为纯数字）',
  uploadErrMsg4: "文件解码错误，请上传UTF-8格式文件",
  uploadErrMsgTitle: "文件上传错误",
  uploadErrMsgTitle2: "无效文件",
  delimiterLabel: "分隔符选择",
};
