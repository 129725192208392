export default {
  modalTitle: "Select model",
  cancelBtnText: "Cancel",
  okBtnText: "OK",
  allCheckedHint: "* New models will be automatically added",
  partCheckedHint: "* New models will not be automatically added",
  emptyModelWarnText: "No model selected, there will be no search results",
  allCheckedHintInPinboard: "* New models in dashboard will be automatically added",
  partCheckedHintInPinboard: "* New models in dashboard will not be automatically added",
  searchPlaceholder: "Search",
  searchEmptyTip: "No result found, please change keyword",
  modelSuffix: "models",
  saveSuccessToast: "Saved successfully",
};
