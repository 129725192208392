export default {
  editBtnText: "编辑",
  saveBtnText: "保存",
  cancelBtnText: "取消",
  saveSuccessToast: "修改成功",
  /* 数据相关设置 */
  basicSettings: "数据相关设置",
  yAxisScale: "脱离零值比例",
  yAxisScaleTip: "开启后 Y 轴坐标刻度不会强制包含零刻度",
  tableThreshold: "默认展示表格阈值",
  tableThresholdTip: "当数据行数 ≥ 该值时（建议不超过 2000），内容优先以表格方式显示，用户可切换图表。应用范围：X 轴非时间的图表。",
  tableThresholdErrMsg: "请输入小于 60000 的整数",
  tableBatchSize: "表格加载数据量",
  tableBatchSizeTip: "表格每次滚动加载的数据量",
  breakpointsOptions: "折线缺失值展示样式",
  breakpointsOptionsTip: "数据缺失导致折线不连续时，折线图（柱折混合图）的展示样式",
  defaultDisplay: "不处理，折线断点",
  showZero: "缺失值显示为零",
  skipBreakpoints: "跳过缺失值，折线连续",
  skipBreakpointsTip: "不再展示缺失值对应的横坐标，使折线连续。可能会造成横坐标不均匀。",
  ignoreBreakpoints: "直接连接缺失前后值，折线连续",
  ignoreBreakpointsTip: "展示缺失值对应的横坐标，但直接连接缺失值前后的值，使折线连续。此时若鼠标悬停在缺失值上，不展示数据",
  on: "开启",
  off: "关闭",
  /* 图表样式默认设置 & 表格、透视表默认设置 */
  defaultChartStyle: "图表样式默认设置",
  defaultTableStyle: "表格、透视表默认设置",
  dataMarkers: "数据点标记",
  dataMarkersTip: "开启后折线图中的数据点将着重展示",
  dataLabels: "数据点数值标签",
  dataLabelsTip: "开启后图表中将展示数据点的具体数值",
  smoothLine: "平滑曲线",
  smoothLineTip: "开启后折线将展示成平滑曲线",
  xAxisGridLines: "X 轴网格线",
  yAxisGridLines: "Y 轴网格线",
  gridLinesTip: "热力图、桑基图、饼图、词云、漏斗图、表格、透视表、地图不适用",
  legendPosition: "图例位置",
  legendPositionTip: "表格、透视表、地图不适用",
  top: "顶部",
  bottom: "底部",
  left: "左侧",
  right: "右侧",
  hidden: "隐藏图例",
  valueFormat: "数字指标",
  percentageFormat: "百分比指标",
  numberFormat: "数值展示样式",
  defaultFormat: "默认样式",
  originalValue: "原始数值",
  decimalPlaces: "小数位数",
  decimalPlacesTip: "请输入 0-10 的整数",
  decimalPlacesErrorMsg: "请输入小数位数",
  thousandsSeparator: "千分位分隔符",
  negativeFormat: "负数展示样式",
  rowHeightLabel: "行高",
  rowHeightLabelTip: "低最多可以展示1行内容，中最多展示2行，高最多展示4行（自动折行）",
  heatMapModel: "热图模式",
  heatMapModelTip: "打开后单元格会根据数值高低进行染色",
  /* 编辑态的预览窗口 */
  panelTitle: "预览",
  chart: "图表",
  table: "表格",
  chartTitle: "每日营业额同比",
  chartSubtitle: "2023-03-24 to 04-02, 同比",
  xAxisName: "每日",
  measureName: "营业额",
  yoySales: "同比营业额",
  yoySalesGrowth: "营业额同比变化率",
};
