export default {
  errorToastText: "Failed to import DEMO workspace. Please contact the operation and maintenance personnel to re-import.",
  title: "License successfully activated",
  titleWithDemo: "License successfully activated, automatically importing DEMO workspace",
  stepTitle1: "Create DEMO owner",
  stepDesc1: "Data expert account:",
  stepTitle2: "Import DEMO data",
  stepDesc2: "Import demo data from deployment package",
  stepTitle3: "Initialize DEMO workspace",
  stepDesc3: "Automatically configure data sources, models, etc.",
  stepTitle4: "Initialize DEMO workspace report",
  stepDesc4: "Import report samples from deployment package",
  skipButtonText: "Go to initialization page",
};
