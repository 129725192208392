export default class CardResultType {
  static DIRECT = 0; // 直接结果

  static INDIRECT = 1; // 间接结果

  static ANALYSIS = 2; // 分析结果

  static FORECAST = 3; // 预测结果
}

export const ANALYSIS_AND_FORECAST = [CardResultType.ANALYSIS, CardResultType.FORECAST];
