export default {
  model: "模型",
  modelListTip: "工作区内可搜索的数据取决于该页创建的模型",
  deletedTableTip: "已删除的自定义表",
  customTable: "自定义表",
  tableNameAndSqlCannotBeEmpty: "表名和 sql 不能为空",
  successfullyCreatedCustomTable: "自定义表新建成功",
  successfullyChangedCustomTable: "自定义表更改成功",
  confirm: "确认",
  exitingEditing: "正在退出编辑，本次修改内容将不会被保存",
  pleaseEnterCustomTableName: "请输入自定义表的名称",
  cancel: "取消",
  save: "保存",
  confirmNew: "确定新建",
  sqlEditor: "SQL 编辑器",
  pleaseTryRunningSuccessfullyFirst: "请先试跑成功后再确定新建",
  underExecution: "执行中",
  trialSQL: "试跑 SQL",
  theDraftHasBeenDeleted: "该草稿已经被删除",
  successfullyDeleted: "删除成功",
  tableName: "表名",
  operation: "操作",
  edit: "编辑",
  preview: "预览",
  areYouSureToDeleteThisCustomTable: "确认删除此自定义表？",
  delete: "删除",
  download: "下载",
  updateButtonText: "更新",
  pleaseEnterTableName: "请输入想搜索的表名称",
  areYouSureToDeleteThisDataTable: "确认删除数据表？",
  appliedToTheModel1: "当前数据表已应用于模型",
  appliedToTheModel2: "删除可能导致模型出错",
  uploadFiles: "上传文件",
  uploadSuccessToast: "文件上传成功",
  internalTableEmptyDataListText: "暂无数据表，请上传文件",
  emptyDataListText: "暂无数据表，或者您可以选择同步数据表更新数据",
  analyzeFail: "文件解析错误",
  fileParsing: "文件解析中...",
  copySuccessToast: "复制成功",
  copyFailToast: "复制失败，请稍后重试",
  runResultsTabs: "试跑结果",
  previewTabs: "预览",
  copyTooltip: "复制",
  searchTablePlaceholder: "查找数据表",
  ViewAIAnalysisBtn: "查看 AI 对错误的解读",
  shrinkBtn: "收起",
  expansionBtn: "展开",
  aiAnalysisTitle: "AI 错误解读",
  renameTitle: "数据列需要重新命名",
  runErrorTitle: "试跑发生错误",
};
