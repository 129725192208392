import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Form.${id}`);

export default class MoneyUnitType {
  static CURRENCY = 0; // 货币

  static CUSTOM = 1; // 自定义

  static choices = [MoneyUnitType.CURRENCY, MoneyUnitType.CUSTOM];

  static getText = t => {
    if (t === MoneyUnitType.CURRENCY) return getText("currencyLabel");
    if (t === MoneyUnitType.CUSTOM) return getText("customLabel");
    return "";
  };
}
