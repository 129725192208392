export default {
  feedbackAndCalibration: "Feedback",
  submit: "Submit",
  cancel: "Cancel",
  fieldMatch: "Desired Match",
  fieldMatchPrompt: "If the current match is inaccurate, please select the desired field from the following list",
  fieldSelectPlaceholder: "Specify the matching field",
  modelSelectPlaceholder: "Model not specified",
  noSuitableMatchText: "No matches found",
  yourInput: "Your Input",
  inputPlaceholder: "Enter the words you want to match",
  searchNoResultText: "No results found, please change your search criteria.",
  maxTagPlaceholderFormer: " , etc. All ",
  maxTagPlaceholderLatter: " models",
  all: "All",
  dimension: "Dimensions",
  measure: "Measures",
  successToast: "Feedback submitted successfully",
  errorToast: "Submission failed, please try again later",
  duplicateTextError:
    "Current field already exists, please change your input or use the 'No Suitable Match' feedback in the lower left corner",
  existingFieldError: "Corresponding field already exists",
  illegalTextError: "Currently only supports input of Chinese and English characters, _, /, , and -",
  fieldMatchCountCheckHelp: "At least 2 matching fields are required for custom rules",
  emptyFieldMatchCheckHelp: "Please select the fields you want to match",
  feedbackLabel: "Desired Match",
  feedbackInfo: "Workspace admin will receive this feedback",
  feedbackPlaceholder: "Please describe specifically the data you want to match",
  back: "Return",
  feedbackToast: "thanks for your feedback",
  feedbackIssuesTitle: "What are the issues you want to feedback",
  othersPlaceholder: "Other suggestions and feedbacks are welcomed",
  feedbackDesc: "Please fill in the reason for your dissatisfaction, which will help us provide better service",
  feedbackType:
    "Switch between indicators and dimensions here, and check the indicators or dimensions you need to match below. You can select multiple",
  feedbackConfirm1: "Click here to submit calibration feedback.",
  feedbackConfirm2:
    "After submission, it needs to be reviewed by the administrator. Only after the review is passed can you directly search for the phrase you reported in the search box.",
};
