import { Tooltip as AntdTooltip } from "antd";
import { TooltipPropsWithOverlay, TooltipRef } from "antd/es/tooltip";
import React, { Ref } from "react";

import styles from "./index.module.scss";

// 目前只有两种模式，small 表现为不带三角箭头的 tip，padding 比较小， large 带有三角箭头，并且 padding 比较大
const TooltipSizes: string[] = ["small", "large"];

export type TooltipSize = typeof TooltipSizes[number];

export interface TooltipProps extends TooltipPropsWithOverlay {
  size?: TooltipSize;
}

const Tooltip: React.FC<TooltipProps> = React.forwardRef(
  ({ children, size = TooltipSizes[1], overlayClassName, ...rest }, ref: Ref<TooltipRef>) => {
    const basicOverlayClassName = size === TooltipSizes[0] ? styles.smallTooltip : styles.largeTooltip;

    return (
      <AntdTooltip ref={ref} overlayClassName={`${styles.tooltip} ${basicOverlayClassName} ${overlayClassName}`} {...rest}>
        {children}
      </AntdTooltip>
    );
  }
);

export default Tooltip;
