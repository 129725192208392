import React from "react";

const AddPinboardBtn = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="currentColor" />
    <path
      d="M18.4888 10.6853L21.7957 13.9921C22.3183 14.5148 22.3183 15.3622 21.7957 15.8848L20.1264 17.5541C19.9795 17.701 19.8688 17.8801 19.8031 18.0772L19.1683 19.9817C18.8537 20.9255 17.6558 21.2083 16.9523 20.5048L14.8277 18.3802L11.1675 22.0405C10.9722 22.2357 10.6556 22.2357 10.4604 22.0405C10.2651 21.8452 10.2651 21.5286 10.4604 21.3334L14.1206 17.6731L11.9761 15.5286C11.2726 14.8252 11.5554 13.6273 12.4993 13.3127L14.4037 12.6778C14.6008 12.6121 14.7799 12.5014 14.9268 12.3545L16.5961 10.6853C17.1188 10.1626 17.9662 10.1626 18.4888 10.6853Z"
      fill="white"
    />
  </svg>
);

export default AddPinboardBtn;
