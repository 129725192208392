import Auth from "../Auth";

export default class LocalStorageKey {
  // 弹窗不再显示的标志位字段
  static DISABLE_MODEL_NODE_CONNECT_ERROR_MODAL = "disableModelNodeConnectErrorModal";

  // 不再显示如何创建模型引导条
  static DISABLE_HOW_TO_CREATE_A_MODEL_GUIDE_LINE = "disableHowToCreateAModelGuideLine";

  // 模型配置 - 不再显示新手引导 tooltip —— 没有事实表
  static DISABLE_MODEL_FACT_TABLE_TOOLTIP = "disableModelFactTableTooltip";

  // 模型配置 - 不再显示新手引导 tooltip —— 第一次拖拽
  static DISABLE_MODEL_FIRST_DRAG_TIP = "disableModelFirstDragTableTooltip";

  // 模型配置 - 新手引导提示顺序设置
  static DISABLE_BEGINNER_TOOLTIP_STEP = "disableBeginnerTooltipStep";

  // 工作区新人手册，顺序记录
  static BEGINNER_PROCESS_STEP = "beginnerProcessStep";

  // 模型新手引导弹窗，是否跳过自动显示的标识
  static MODEL_BEGINNER_GUIDE_SKIP = "modelBeginnerGuideSkip";

  // 模型配置 - 右下角模型帮助提示弹窗是否点击过的标识
  static CHECKED_CREATE_MODEL_INTRO_TIP = "checkedCreateModelIntroTip";

  static getBeginnerProcessKey = () => `${this.BEGINNER_PROCESS_STEP}${Auth.getUid()}`;
}
