import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import FilterLoading from "./FilterLoading";

const Loading = ({ style = {} }) => (
  <div style={{ width: "100%", height: "100%", textAlign: "center", paddingTop: 150, background: "var(--datarc-color-bg-1)", ...style }}>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
  </div>
);

export default Loading;

export { FilterLoading };
