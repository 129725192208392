export default class SearchTokenSourceType {
  // 搜索框输入
  static INPUT = 1;

  // 筛选器点击
  static FILTER_CLICK = 2;

  // 来自上一个问题
  static LAST_QUESTION = 3;

  // 下钻
  static DRILL = 4;

  // 搜索字段提示
  static SEARCH_PROMPT = 5;

  // 搜索框历史问题
  static INPUT_HISTORY = 6;

  // 历史记录
  static HISTORY = 7;

  // 你可能还想搜
  static MAY_WANT_SEARCH = 8;

  // 我常搜索
  static OFTEN_SEARCH = 9;

  // 热门搜索
  static TOP_SEARCH = 10;

  // 你可以试试
  static YOU_CAN_TRY = 11;

  // 新增搜索
  static NEW_SEARCH = 12;

  // 关注的问题
  static STARRED_QUESTION = 13;

  // 首页 hint
  static HOME_HINT = 14;

  // PC端搜索
  static PC = "PC";

  // 移动端搜索
  static MOBILE = "mobile";
}
