export default class WatermarkStyle {
  static INTENSIVE = 0;

  static LOOSE = 1;

  static getText = t => {
    if (t === WatermarkStyle.INTENSIVE) return "密集型";
    if (t === WatermarkStyle.LOOSE) return "宽松型";
    return "";
  };
}
