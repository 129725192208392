export default {
  betweenStr: "Between",
  greaterThanStr: "Greater than(>)",
  greaterEqualThanStr: "Greater than or equal to(≥)",
  lessThanStr: "Less than(<)",
  lessEqualThanStr: "Less than or equal to(≤)",
  isNullStr: "Is null",
  isNotNullStr: "Is not null",
  equalStr: "Equals to(=)",
  notEqualStr: "Does not equal to(≠)",
  inStr: "Is",
  notInStr: "Is not",
  likeInStr: "Contains",
  notLikeInStr: "Does not contain",
  startWithStr: "Starts with",
  endWithStr: "Ends with",
  betweenTimeStr: "Between",
  afterTimeStr: "After",
  formerStr: "Before",
  dateThis: "This",
  in: " is",
  notIn: " is not",
  contain: " contains ",
  notContain: " exclude ",
  with: " start",
  start: "With",
  end: "End",
  to: "To",
  betweenness: " between ",
  betweenTo: " and ",
  between: "",
  isNull: " is null",
  isNotNull: " is not null",
};
