export default {
  sum: "Sum",
  sumPrefix: "Sum ",
  avg: "Avg",
  avgPrefix: "Avg ",
  max: "Max",
  maxPrefix: "Max ",
  min: "Min",
  minPrefix: "Min ",
  latest: "Latest",
  latestPrefix: "Latest ",
  count: "Count",
  countDistinct: "Count distinct",
  countLabel: "Count",
  countDistinctLabel: "Count distinct",
  timeInterval: "Time interval",
  hiddenLabel: "Hide",
  dataFormatLabel: "Data Format",
  sortLabel: "Sort",
  ascLabel: "A-Z",
  descLabel: "Z-A",
  totalLabel: "Total",
};
