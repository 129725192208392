export default {
  dataAlarmModalTitle1: "Alert Settings",
  monitoringIndicators: "Monitoring Measures",
  dataAlarmModalTitle2: "Select Recipient",
  dataAlarmModalTitle3: "Test run of monitoring measures",
  measureSelectPlaceholder: "Please select the measure that needs to be monitored",
  greaterThanStr: "Greater than (>) ",
  greaterEqualThanStr: "Greater than or equal to (≥) ",
  lessThanStr: "Less than (<) ",
  lessEqualThanStr: "Less than or equal to (≤) ",
  equalStr: "Equals (=) ",
  notEqualStr: "Not equal to (≠) ",
  yoyChange: "YoY Growth",
  ringChange: "Previous Period Growth",
  valueItself: "Value",
  intelligentAlarm: "Auto Alert",
  typePlaceholder: "",
  triggerConditions: "Conditions",
  useAtLeastOne: "Use at least one",
  monitoringCycle: "Monitoring Cycle",
  sendingTime: "Sending Time",
  reminderMethod: "Alert Method",
  operatorPlaceholder: "",
  mailPush: "Mail Reminder",
  requireBindMail: "Requires the user to bind email",
  larkReminder: "Lark Reminder",
  requireBindLark: "Requires the user to bind the Lark account",
  dingtalkReminder: "DingTalk Reminder",
  requireBindDingtalk: "Requires the user to bind the DingTalk account",
  wecomReminder: "WeCom Reminder",
  requireBindWecom: "Requires the user to bind the WeCom account",
  nextStep1: "Next, Select Recipients",
  nextStep2: "Next, Test",
  create: "Confirm and Create",
  save: "Save",
  cancel: "Cancel",
  back1: "Previous, Set Conditions",
  back2: "Previous, Select Recipient",
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  every: "Every ",
  everyDay: "Every day",
  day: "Day",
  formerTime: "No.",
  latterTime: "Day",
  progressText: "Test run monitoring measures and filter conditions",
  runningErrorText: "The trial run failed and cannot be created. Please adjust the filter conditions and try again",
  additionalParameters: "Additional parameters",
  copySuccessToast: "Copy successfully",
  copyFailToast: "Copy failed, please try again later",
  resultSQL: "Result SQL",
  resultSQLInfo: "Supports copying SQL to database for validation",
  copy: "Copy",
  trialRunRes: "Test results",
  trialRunResInfo:
    "Please confirm that the following result is the measure you need to monitor. Test run result: filter + measure + T-1 period.",
  alertUpdateSuccess: "Update successfully",
  alertSetSuccess: "Data alarm set successfully",
  pleaseChooseMembers: "Please select the recipient",
  addFilterBtn: "+ Add",
  filterLabel: "Filters",
  addDimensionFilter: "Add Filter",
  addDimensionFilterPlaceholder: "Please select the dimension to be filtered",
  hasNotDimension: "Please select the dimension to be filtered first, and then select the specific dimension value",
  advancedSetting: "Advanced Settings",
  noResultTip: "Search no results",
  smartAlertTip:
    "When there is an abnormal point in the time series model of the measure or there is a breakthrough rise or fall in two consecutive periods, an alarm will be triggered automatically",
};
