export default {
  cycleByYearFormerText: "Yearly",
  monthSuffix: "Month",
  daySuffix: "Day",
  hourSuffix: "Hour",
  cycleLatterText: "Sub-sync keywords",
  monthSeparator: "Month,",
  daySeparator: "Day,",
  weekSeparator: ", ",
  monthDaySeparator: "Of the month",
  dayHourSeparator: "Of the day",
  weekHourSeparator: "The",
  cycleByMonthFormerText: "Every interval",
  cycleByMonthMiddleText: "Months",
  cycleByDayFormerText: "Daily",
  syncKeywords: "Sync keywords",
  instructions: "Setup instructions",
  operations: "Settings",
  deleteBtnText: "Delete",
  deleteConfirmTitle: "Confirm the deletion of this synchronization task?",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  deleteSuccessToast: "Delete successfully",
  lastTimeFormerText: "The time of the last successful synchronization:",
  addBtnText: "Add sync task",
};
