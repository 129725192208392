export default {
  selectDataPlaceholder: "Find and select columns of data",
  manualInputText: "Manual input",
  enterPlaceholder: "Please enter",
  tableRelationshipTitle: "Configuration table relationship",
  confirmText: "Confirm",
  cancelText: "Cancel",
  addColumnsText: "Add a related column",
  addColumnsTip: 'The data columns in the left and right selectors correspond to the selectors on both sides of the "table relationship"',
};
