import React from "react";

const SadFace = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM22.4 33.6C24.1673 33.6 25.6 32.1673 25.6 30.4C25.6 28.6327 24.1673 27.2 22.4 27.2C20.6327 27.2 19.2 28.6327 19.2 30.4C19.2 32.1673 20.6327 33.6 22.4 33.6ZM10.6667 28C10.6667 29.62 9.35337 30.9333 7.73333 30.9333C6.1133 30.9333 4.8 29.62 4.8 28C4.8 26.38 6.1133 25.0667 7.73333 25.0667C9.35337 25.0667 10.6667 26.38 10.6667 28ZM26.6174 26.3985C27.0584 26.426 27.4382 26.0909 27.4658 25.6499C27.4934 25.209 27.1582 24.8292 26.7173 24.8016C22.7929 24.5563 20.5892 22.1072 19.7775 20.8896C19.5325 20.522 19.0358 20.4226 18.6682 20.6677C18.3005 20.9127 18.2012 21.4094 18.4462 21.7771C19.4122 23.2262 22.0085 26.1104 26.6174 26.3985ZM3.20798 23.0868C3.27046 22.6494 3.67569 22.3455 4.11308 22.408C5.63122 22.6249 7.73636 21.7389 8.92154 19.8426C9.15571 19.468 9.64927 19.3541 10.0239 19.5882C10.3986 19.8224 10.5125 20.316 10.2783 20.6906C8.79685 23.061 6.102 24.3084 3.8868 23.9919C3.44942 23.9294 3.1455 23.5242 3.20798 23.0868ZM11.128 41.324C11.4174 41.6579 11.9226 41.6939 12.2565 41.4046C12.5904 41.1152 12.6265 40.61 12.3371 40.2761C11.5244 39.3384 11.1172 38.5457 10.9613 37.9449C10.8051 37.3432 10.9102 36.9898 11.0279 36.8167C11.1367 36.6569 11.3364 36.5299 11.6785 36.5374C12.0335 36.5451 12.5358 36.7062 13.1006 37.1581C13.4456 37.4341 13.9491 37.3781 14.2251 37.0331C14.5011 36.6881 14.4451 36.1847 14.1001 35.9087C13.3316 35.2938 12.5005 34.955 11.7135 34.9377C10.9138 34.9202 10.1632 35.2432 9.70504 35.9167C9.25584 36.577 9.17729 37.4402 9.41258 38.3468C9.64811 39.2544 10.2074 40.2617 11.128 41.324Z"
      fill="currentColor"
    />
  </svg>
);

export default SadFace;
