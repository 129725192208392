export default {
  deleteSuccessToast: "删除成功",
  saveSuccessToast: "修改成功",
  editBtnText: "编辑",
  sortBtnText: "排序",
  sortSuccessToast: "设置成功。",
  deleteBtnText: "删除",
  dimensionPrefix: "维度表",
  dimensionSuffix: "属性",
  deleteConfirmFormerText: "确认删除该维度表",
  deleteConfirmLatterText: "？该操作无法撤销",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  name: "维度表名称/关键词",
  alias: "别名",
  field: "字段名",
  dataType: "数据类型",
  isKeyword: "维度值可搜索",
  dimension: "维度",
  operations: "操作",
  searchInputPlaceholder: "请输入想搜索的内容",
  dimensionListTip: "维度表需要在模型中新增并关联一张实际表，是对事实表的维度扩展表",
  current: "当前维度表：",
  tableDeleteConfirmTitle: "确认删除该维度表",
  tableDeleteConfirmContent1: "该维度表已有应用模型。",
  tableDeleteConfirmContent2: "确认后会在所有包含该维度表的模型中移除。",
  tableDeleteConfirmContent3: "该操作无法撤销",
  dimensionList: "维度表",
  pruneButtonText: "清空无效维度",
  pruneButtonTip: "清空没有被模型使用的维度表",
  pruneConfirmTitle: "确认删除无效的维度表",
  pruneConfirmContent: "确认后，没有应用模型的维度表将被清空。",
  migrateTable: "迁为新表",
  migrateTableTitle: "此旧维度表将自动迁移为新维度表  ",
  migrateTableContent1: "维度会自动配置成新的格式，不会丢失数据。",
  migrateTableContent2: "迁移完成后系统将自动发起字典重构任务。",
  migrateTableSuccessToast: "迁移成功",
};
