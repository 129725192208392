export default {
  pinboardName: "Name",
  pinboardNamePlaceholder: "Enter name",
  pinboardDesc: "Description",
  pinboardDescPlaceholder: "Enter description",
  pinboardNameNotFilledText: "Dashboard name is empty",
  plainSpacePinboardNameText: "Dashboard name can not be only space",
  plainSpacePinboardDescText: "Dashboard description can not be only space",
  createPinboard: "Create Dashboard",
  pinboardTag: "Tag",
  cancel: "Cancel",
  create: "Create",
};
