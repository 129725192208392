import React from "react";

const ThreeDot = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 2 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.125 1.61131C0.125 1.84338 0.217187 2.06594 0.381281 2.23003C0.545376 2.39413 0.767936 2.48631 1 2.48631C1.23206 2.48631 1.45462 2.39413 1.61872 2.23003C1.78281 2.06594 1.875 1.84338 1.875 1.61131C1.875 1.37925 1.78281 1.15669 1.61872 0.992595C1.45462 0.8285 1.23206 0.736313 1 0.736313C0.767936 0.736313 0.545376 0.8285 0.381281 0.992595C0.217187 1.15669 0.125 1.37925 0.125 1.61131ZM0.125 5.98631C0.125 6.21838 0.217187 6.44094 0.381281 6.60503C0.545376 6.76913 0.767936 6.86131 1 6.86131C1.23206 6.86131 1.45462 6.76913 1.61872 6.60503C1.78281 6.44094 1.875 6.21838 1.875 5.98631C1.875 5.75425 1.78281 5.53169 1.61872 5.36759C1.45462 5.2035 1.23206 5.11131 1 5.11131C0.767936 5.11131 0.545376 5.2035 0.381281 5.36759C0.217187 5.53169 0.125 5.75425 0.125 5.98631V5.98631ZM0.125 10.3613C0.125 10.5934 0.217187 10.8159 0.381281 10.98C0.545376 11.1441 0.767936 11.2363 1 11.2363C1.23206 11.2363 1.45462 11.1441 1.61872 10.98C1.78281 10.8159 1.875 10.5934 1.875 10.3613C1.875 10.1292 1.78281 9.90669 1.61872 9.7426C1.45462 9.5785 1.23206 9.48631 1 9.48631C0.767936 9.48631 0.545376 9.5785 0.381281 9.7426C0.217187 9.90669 0.125 10.1292 0.125 10.3613V10.3613Z"
      fill="#BFBFBF"
    />
  </svg>
);

export default ThreeDot;
