export default {
  text: "文本",
  longText: "长文本",
  list: "列表",
  float: "浮点数",
  second: "秒",
  minute: "分钟",
  hour: "小时",
  date: "日期",
  datetime: "时间",
  day: "天",
  week: "周",
  month: "月",
  quarter: "季度",
  year: "年",
  percentage: "百分比",
  phone: "手机号",
  id: "ID",
  integer: "整数",
  decimal: "数字",
};
