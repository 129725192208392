export default class CompHeight {
  /* 组件的高度设置，来源见各自对应的样式文件 */
  static DEFAULT = 400; // 默认高度

  // style.scss
  static TABLE_ROW = 44; // 表格行高：2.75rem

  static TAB_LABEL = 32; // Tab标签：2rem

  static MEASURE_CARD = 105;

  static MEASURE_CARD_HIDE = 47;

  static CARD_PADDING = 32; // 卡片 padding 高度

  // Toggle 高度
  static TOGGLE_MARGIN = 16;

  static TOGGLE = 21;

  // Pin
  static PIN_ROW = 30; // 栅格灰色格子高度

  static PIN_SPACING = 16; // 栅格格子间距高度

  static PIN_PADDING = 16;

  static PIN_PADDING_MOBILE = 16;

  static PIN_MAX_DESKTOP_X = 12; // 报告x轴格数

  // pinboard
  static pinboard = {
    MIN_H: 4,
    MIN_W: 2,
    TEXT_CARD_MIN_H: 2,
    HEADING_TEXT_CARD_MIN_H: 2,
    MAX_MOBILE_X: 2,
    HALF_MAX_DESKTOP_X: 6,
    ATTRIBUTION_CARD_MIN_H: 9,
    ATTRIBUTION_CARD_MIN_W: CompHeight.PIN_MAX_DESKTOP_X,
    MEASURE_CARD_MIN_H: 6,
    CARD_MIN_H: 15,
    HEADING_W: CompHeight.PIN_MAX_DESKTOP_X,
  };
}
