export default {
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  wrongColorToast: "颜色填写不正确",
  createThemeColorErrorToast: "主题色需要8个主色才能成功创建",
  RGBColorValueColumnName: "RGB色值（Hex）",
  RGBColorValueInputPlaceholder: "请输入6位RGB色值（Hex）",
  colorValueColumnName: "色值",
  colorValueInputPlaceholder: "请输入色值",
  operation: "操作",
  resetThemeColorConfirmText: "确认将此组主题色重置回预设颜色？",
  resetBtnText: "重置",
  saveBtnText: "保存",
  themeName: "主题名称",
  mainColor: "主色",
  mainColorTip: "主色为8种，排序影响图表自动选色顺序和展示顺序",
  secondaryColor: "辅助色",
  secondaryColorTip: "辅助色不限数量，用于标签过多时的颜色选择",
  add: "新增",
  addSuccessToast: "添加成功",
  searchNoResultAlert: "搜索无结果，请更换关键词",
  addWatchingContentDrawerTitle: "添加观察的内容",
  noAddableContentToast: "暂时没有可添加的内容",
  keywordInputPlaceholder: "输入关键词查找",
  backBtnText: "返回上一级",
  noAddableContentTip: "暂时没有可添加的内容，请开始探索数据吧~",
  date: "日期",
  editBtnText: "编辑",
  deleteBtnText: "删除",
  deleteDateTagConfirmText: "只删除此日期下的此标签，不影响循环内其他日期的标签。确认删除此标签？",
  tagDetail: "标签明细",
  currentTag: "当前标签：",
  closeBtnText: "收起",
  beforeQuestion: "至问题前",
  afterQuestion: "至问题后",
  actionSettingDrawerTitle: "操作设置",
  addOperationBtnText: "添加操作",
  deleteOperationConfirmText: "确认删除此项操作？",
  operationNameInputPlaceholder: "请输入操作名，非必填",
  executeInOrder: "按顺序执行",
  operationContentLabel: "操作内容",
  selectPlaceholder: "请选择",
  templateInputPlaceholder: "请输入模版",
  oldContentInputPlaceholder: "请输入旧内容",
  replaceWith: "成",
  newContentInputPlaceholder: "请输入新内容",
  deleteContentInputPlaceholder: "请输入需要删除的内容",
  addOperationContentBtnText: "添加操作内容",
};
