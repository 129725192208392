import React from "react";

const Copy = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8125 2.40002H3.375C2.75368 2.40002 2.25 2.9037 2.25 3.52502V11.9625C2.25 12.2732 2.50184 12.525 2.8125 12.525C3.12316 12.525 3.375 12.2732 3.375 11.9625V3.52502H11.8125C12.1232 3.52502 12.375 3.27318 12.375 2.96252C12.375 2.65186 12.1232 2.40002 11.8125 2.40002Z"
      fill="currentColor"
    />
    <path
      d="M7.79998 10.5375C7.79998 10.2269 8.05182 9.97502 8.36248 9.97502H9.825V8.51252C9.825 8.20186 10.0768 7.95002 10.3875 7.95002C10.6982 7.95002 10.95 8.20186 10.95 8.51252V9.97502H12.3375C12.6481 9.97502 12.9 10.2269 12.9 10.5375C12.9 10.8482 12.6481 11.1 12.3375 11.1H10.95V12.4875C10.95 12.7982 10.6982 13.05 10.3875 13.05C10.0768 13.05 9.825 12.7982 9.825 12.4875V11.1H8.36248C8.05182 11.1 7.79998 10.8482 7.79998 10.5375Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.95018 6.22502C4.95018 5.6037 5.45386 5.10002 6.07518 5.10002H14.6252C15.2465 5.10002 15.7502 5.6037 15.7502 6.22502V14.775C15.7502 15.3963 15.2465 15.9 14.6252 15.9H6.07518C5.45386 15.9 4.95018 15.3963 4.95018 14.775V6.22502ZM6.07518 6.22502H14.6252V14.775H6.07518L6.07518 6.22502Z"
      fill="currentColor"
    />
  </svg>
);

export default Copy;
