import { Popover as AntdPopover, PopoverProps } from "antd";
import { TooltipRef } from "antd/es/tooltip";
import React, { Ref } from "react";

import styles from "./index.module.scss";

const Popover: React.FC<PopoverProps> = React.forwardRef(({ overlayClassName, ...rest }, ref: Ref<TooltipRef>) => {
  let popoverClassName = styles.popover;
  if (overlayClassName) popoverClassName += ` ${overlayClassName}`;
  return <AntdPopover ref={ref} overlayClassName={popoverClassName} {...rest} />;
});

export default Popover;
