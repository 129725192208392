export default {
  addIntroInputPlaceholder: "添加说明（选填）",
  formerApplyForPermissionText: "向",
  latterApplyForPermissionText: "申请权限，审核通过后即可查看",
  waitForAuditBtnText: "等待审核",
  note: "备注",
  applicationFailTip: "您的申请没有通过，暂时无法查看",
  applyAgainBtnText: "继续申请",
  applyForViewBtnText: "申请查看",
  noMorePrompt: "不再提示",
  haveLearnedBtnText: "我已了解",
  noAuthInWorkspaceText: "您不在卡片所在工作区，请联系工作区管理员",
  shareClosedText: "当前分享已关闭",
  showMore: "查看更多",
  noContent: "报告中暂无卡片",
};
