import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.DownloadExcelError.${id}`);

export default class DownloadExcelError {
  static PROJECT_NOT_INTERNAL = 1;

  static CONNECT_FAILED = 2;

  static TABLE_NOT_EXISTS = 3;

  static DOWNLOAD_TABLE_FAILED = 4;

  static UPLOAD_FILE_FAILED = 5;

  static getText = status => {
    if (status === this.PROJECT_NOT_INTERNAL) return getText("projectNotInternal");
    if (status === this.CONNECT_FAILED) return getText("connectFailed");
    if (status === this.TABLE_NOT_EXISTS) return getText("tableNotExists");
    if (status === this.DOWNLOAD_TABLE_FAILED) return getText("downLoadTableFailed");
    if (status === this.UPLOAD_FILE_FAILED) return getText("uploadFileFailed");
    return "";
  };
}
