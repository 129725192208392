export default {
  outlier: "异常值",
  pareto: "构成",
  ringOutlier: "环比异常点",
  seasonality: "周期性波动",
  trend: "总体变化趋势",
  changePoint: "趋势变化点",
  breakout: "突破性上升下降",
  volatility: "波动性变化",
  maximum: "最大值",
  minimum: "最小值",
  average: "均值",
  statistics: "离散",
  count: "其它",
  suffix: "解析",
  correlation: "相关分析",
  recentChange: "最新变化",
  mixedTrend: "趋势差异",
  correlationDesc: "值越接近1，相关性越大，相互的影响越强烈",
};
