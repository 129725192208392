export default {
  colPermissionUpdateSuccessToast: "列权限配置更新成功",
  openColPermissionConfirmText: "确认开启列权限？",
  openColPermissionTip: "开启后需要配置权限内容并更新，否则可能导致搜索结果无数据",
  confirm: "确认",
  cancel: "取消",
  refreshBtnText: "刷新",
  dimensionPermission: "维度权限",
  allModels: "所有模型",
  appList: "应用名单",
  updateBtnText: "更新",
  colPermission: "列权限",
  noPermissionTip: "权限未启用，用户搜索列数据时无限制条件",
  resourceEffectAllowTip: "请将需要加入名单的资源从左侧加入右侧，只有加入右侧名单的资源才允许被使用",
  resourceEffectNotAllowTip: "请将需要加入名单的资源从左侧加入右侧，加入右侧名单的资源将会被禁止使用",
  measurePermission: "指标权限",
  updateDatabaseToast: "同步数据表任务将在1分钟后开始，请避免创建及修改模型操作",
  updateDatabaseBtnText: "同步数据表",
  retryBtnText: "重试",
  tableNameSearchPlaceholder: "输入表名或关键字查找",
  modelNameInputPlaceholder: "请输入模型名称",
  setModelName: "点击设置模型名称",
  addTag: "添加标签",
  tag: "标签",
  exist: "已存在",
};
