export default class AccountRole {
  static USER = 1;

  static AUDITOR = 2;

  static ADMIN = 3;

  static choices = [this.USER, this.AUDITOR, this.ADMIN];

  static getText = type => {
    if (type === this.USER) return "业务用户";
    if (type === this.AUDITOR) return "审计员";
    if (type === this.ADMIN) return "系统管理员";
    return "";
  };
}
