import React from "react";

const SearchHistoryOutlined = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7157 14.1298C11.4539 15.0938 9.87708 15.6665 8.1665 15.6665C4.02437 15.6665 0.666504 12.3086 0.666504 8.1665C0.666504 4.02437 4.02437 0.666504 8.1665 0.666504C12.3086 0.666504 15.6665 4.02437 15.6665 8.1665C15.6665 9.877 15.0939 11.4538 14.1299 12.7155L16.6067 15.1924C16.9973 15.5829 16.9973 16.2161 16.6067 16.6066C16.2162 16.9971 15.5831 16.9971 15.1925 16.6066L12.7157 14.1298ZM14.1665 8.1665C14.1665 11.4802 11.4802 14.1665 8.1665 14.1665C4.8528 14.1665 2.1665 11.4802 2.1665 8.1665C2.1665 4.8528 4.8528 2.1665 8.1665 2.1665C11.4802 2.1665 14.1665 4.8528 14.1665 8.1665Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SearchHistoryOutlined;
