export default class PVTokenSource {
  static FONT_PAGE = 1;

  static DISCOVER_PAGE = 2;

  static PINBOARD_PAGE = 3;

  static PINBOARD_ID_PAGE = 4;

  static MY_ALERTS_PAGE = 5;

  static MY_STARRED_PAGE = 6;

  static MY_SAVE_PAGE = 7;

  static MY_HISTORY_PAGE = 8;

  static ATTRIBUTION_PAGE = 9;

  static MY_ROUTER_MAPPING = {
    alerts: this.MY_ALERTS_PAGE,
    "starred-questions": this.MY_STARRED_PAGE,
    "saved-cards": this.MY_SAVE_PAGE,
    "search-history": this.MY_HISTORY_PAGE,
  };
}
