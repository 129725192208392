import React from "react";

const CopyIcon = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2993 6.85216C10.5189 6.63249 10.8751 6.63249 11.0948 6.85216C11.3144 7.07183 11.3144 7.42798 11.0948 7.64765L7.64761 11.0948C7.42794 11.3145 7.07179 11.3145 6.85212 11.0948C6.63245 10.8751 6.63245 10.519 6.85212 10.2993L10.2993 6.85216Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.77065 3.29722C11.1326 1.93527 13.3408 1.93527 14.7027 3.29722C16.0647 4.65917 16.0647 6.86734 14.7027 8.22929L12.4206 10.5114C12.2009 10.7311 11.8447 10.7311 11.6251 10.5114C11.4054 10.2918 11.4054 9.93561 11.6251 9.71594L13.9072 7.4338C14.8298 6.51118 14.8298 5.01533 13.9072 4.09272C12.9846 3.1701 11.4888 3.1701 10.5661 4.09272L8.284 6.37486C8.06433 6.59453 7.70817 6.59453 7.4885 6.37486C7.26883 6.15519 7.26883 5.79904 7.4885 5.57937L9.77065 3.29722Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.31932 9.74858C1.95736 11.1105 1.95736 13.3187 3.31932 14.6806C4.68127 16.0426 6.88943 16.0426 8.25139 14.6806L10.5114 12.4206C10.7311 12.201 10.7311 11.8448 10.5114 11.6251C10.2917 11.4055 9.93559 11.4055 9.71592 11.6251L7.45589 13.8852C6.53328 14.8078 5.03743 14.8078 4.11481 13.8852C3.1922 12.9625 3.1922 11.4667 4.11481 10.5441L6.37484 8.28405C6.59451 8.06438 6.59451 7.70822 6.37484 7.48855C6.15517 7.26888 5.79901 7.26888 5.57934 7.48855L3.31932 9.74858Z"
      fill="currentColor"
    />
  </svg>
);

export default CopyIcon;
