import React from "react";

const SearchHistory = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="8.59922" cy="8.65324" rx="8.59922" ry="8.65324" fill="#FFBE27" />
    <path
      opacity="0.4"
      d="M17.6746 18.9553C17.3406 18.9445 17.0229 18.807 16.7854 18.5705L14.7489 16.1902C14.3123 15.7909 14.2766 15.1123 14.6689 14.6689C14.8525 14.4831 15.1021 14.3787 15.3625 14.3787C15.6229 14.3787 15.8725 14.4831 16.0562 14.6689L18.6172 16.7181C18.9862 17.0957 19.1 17.6563 18.9079 18.1492C18.7158 18.6422 18.2536 18.9754 17.728 19L17.6746 18.9553Z"
      fill="currentColor"
    />
  </svg>
);

export default SearchHistory;
