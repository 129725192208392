export default {
  formerDeleteWorkspaceConfirmText: "确定删除",
  latterDeleteWorkspaceConfirmText: "工作区吗？",
  deleteWorkspaceTip: "该操作不可撤销，请谨慎操作！",
  delete: "删除",
  cancel: "取消",
  deleteSuccessToast: "删除成功",
  id: "编号",
  workspaceName: "工作区名称",
  workspaceIntro: "工作区介绍",
  owner: "负责人",
  databaseType: "数据库类型",
  autoAddMember: "自动加入",
  createdAt: "创建时间",
  updatedAt: "更新时间",
  operation: "操作",
  edit: "编辑",
  accountExcessAlert:
    "当前账号超额（即账号数量＞配额数量），系统各类功能无法正常使用，请将各类型的账号数量（包含未激活、正常、禁用和休眠状态的账号）减至配额数量",
  createWorkspaceBtnText: "创建工作区",
  workspaceInfoInputPlaceholder: "请输入您想搜索的工作区信息",
  add: "添加",
  workspace: "工作区",
};
