export default {
  excess: "超额",
  normal: "正常",
  admin: "系统管理员",
  auditor: "审计员",
  user: "业务用户",
  unbind: "解绑",
  config: "配置",
  success: "成功",
  CASService: "CAS服务",
  loginMethod: "登录方式",
  configured: "已配置",
  notConfigured: "未配置",
  configuredOthers: "，已开启属性映射：",
  confirm: "确认",
  cancel: "取消",
  unbindConfirmText: "确认解绑？",
  unbindConfirmTip: "该登录方式的信息将被清空。",
  unlimited: "不限",
  dingDing: "钉钉",
  application: "应用",
  lark: "飞书",
  viewMoreBtnText: "查看更多",
  openDrawerBtnText: "打开侧边栏",
  qianFan: "千帆玉符",
  askRank: "了解排名",
  askTrend: "了解趋势",
  askContent: "了解内容",
  askContrast: "进行对比",
  frequentQuestions: "我常搜索",
  newQuestions: "新增搜索",
  hotQuestions: "热门搜索",
  questionGuide: "你可以试试",
  questionGuideText: "可以点击这里的问题试试",
  weChat: "企业微信",
  verificationFile: "验证文件",
  weComAddMenu: "增加菜单按钮",
  weComRemoveMenu: "移除菜单按钮",
  OAuth2: "OAuth 2.0",
  SAML2: "SAML 2.0",
  subscriptionModel: "订阅模式",
  core: "核",
  byNumberOfCores: "按核心数",
  byNumberOfCoresNote: "不限业务用户账号数",
  byAccountNumber: "按账号数",
  byAccountNumberNote: "按各类业务用户账号数订阅",
  addBtnText: "新增",
  phone: "手机号",
  email: "邮箱",
};
