import { Dropdown as AntdDropdown, DropDownProps } from "antd";
import React from "react";
import { SubMenuType } from "@/components/datarc/Menu";

import styles from "./index.module.scss";

const handlerMenuItemsChildren = item => {
  if (item.children?.length) {
    item.className = `${styles.menuItem} ${item.className}`;
    item.popupClassName = `${styles.a} ${styles.b} ${styles.c} ${styles.subMenuContainer} ${item.popupClassName}`;

    // 处理子菜单的选中状态
    item.children.forEach(child => {
      const childItem = child;
      if (!childItem.disabled && childItem.selected && !childItem.children?.length) {
        childItem.className = `${styles.subMenuItemActive} ${childItem.className}`;
      }
      handlerMenuItemsChildren(childItem);
    });
  }
};

const Dropdown: React.FC<DropDownProps> & {
  Button: typeof AntdDropdown.Button;
} = ({ menu, ...rest }) => {
  let baseClassName = `${styles.a} ${styles.b} ${styles.c} ${styles.dropdownMenuClassName}`;
  if (menu.className) baseClassName += ` ${menu.className}`;
  menu.className = baseClassName;

  // 处理 menu 中的 items 选项
  if (menu?.items?.length) {
    // 对 items 进行处理，如果有子菜单，注入 className，popupClassName，popupOffset
    menu.items.forEach(item => {
      const menuItem = item as SubMenuType;
      handlerMenuItemsChildren(menuItem);
    });
  }

  return <AntdDropdown menu={menu} {...rest} />;
};

Dropdown.Button = AntdDropdown.Button;

export default Dropdown;
