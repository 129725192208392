import React from "react";

const GoTop = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.333C0 11.9648 0.298477 11.6663 0.666667 11.6663H11.3333C11.7015 11.6663 12 11.9648 12 12.333C12 12.7012 11.7015 12.9997 11.3333 12.9997H0.666667C0.298477 12.9997 0 12.7012 0 12.333ZM6.66667 2.88501V9.66634C6.66667 10.0345 6.36819 10.333 6 10.333C5.63181 10.333 5.33333 10.0345 5.33333 9.66634V2.88501L1.75733 6.4616C1.49703 6.72195 1.07496 6.72196 0.814633 6.46164C0.554337 6.20134 0.554325 5.77933 0.814605 5.51901L5.72407 0.608974C5.87646 0.456564 6.12355 0.456558 6.27595 0.608958L11.1853 5.5183C11.4456 5.77863 11.4456 6.20072 11.1853 6.46105C10.925 6.72135 10.503 6.72138 10.2426 6.46112L6.66667 2.88634V2.88501Z"
      fill="currentColor"
      fillOpacity="0.65"
    />
  </svg>
);

export default GoTop;
