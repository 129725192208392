import _ from "lodash";
import { EN_US, getLocaleText } from "@/utils/locale";
import TimeUnit from "./TimeUnit";
import TokenProp from "./TokenProp";

const getText = id => getLocaleText(`components.Constants.SearchFilterConditions.${id}`);

const getJoinArr = strArr => _.trim(strArr.join("，"), "， ");

export default class SearchFilterConditions {
  // 以下是指标的条件
  static BETWEEN = "between";

  static GREATER_THAN = "greaterThan";

  static GREATER_EQUAL_THAN = "greaterEqualThan";

  static LESS_THAN = "lessThan";

  static LESS_EQUAL_THAN = "lessEqualThan";

  static IS_NULL = "isNull";

  static IS_NOT_NULL = "isNotNull";

  static EQUAL = "equal";

  static NOT_EQUAL = "notEqual";

  // 以下是维度的条件
  static IN = "in";

  static NOT_IN = "notIn";

  static LIKE_IN = "likeIn";

  static NOT_LIKE_IN = "notLikeIn";

  static START_WITH = "startWith";

  static END_WITH = "endWith";

  // 以下是时间维度的条件
  static BETWEEN_TIME = "betweenTime";

  static AFTER_TIME = "afterTime";

  static FORMER = "former";

  static DATE_TIME_WORD_FORMAT = "YYYY-MM-DD HH:mm:ss";

  static DATE_WORD_FORMAT = "YYYY-MM-DD";

  static DATE_TIME_PROP_FORMAT = "YYYYMMDDHHmmss";

  static DATE_PROP_FORMAT = "YYYYMMDD";

  static DATE_THIS = "dateThis"; // 本日，本月。。。。

  // 不用额外输入的筛选条件
  static emptyInputChoices = [this.IS_NOT_NULL, this.IS_NULL];

  static getText = v => {
    switch (v) {
      // 指标的条件
      case this.BETWEEN:
        return getText("betweenStr");
      case this.GREATER_THAN:
        return getText("greaterThanStr");
      case this.GREATER_EQUAL_THAN:
        return getText("greaterEqualThanStr");
      case this.LESS_THAN:
        return getText("lessThanStr");
      case this.LESS_EQUAL_THAN:
        return getText("lessEqualThanStr");
      case this.EQUAL:
        return getText("equalStr");
      case this.NOT_EQUAL:
        return getText("notEqualStr");
      // 维度条件
      case this.IN:
        return getText("inStr");
      case this.NOT_IN:
        return getText("notInStr");
      case this.LIKE_IN:
        return getText("likeInStr");
      case this.NOT_LIKE_IN:
        return getText("notLikeInStr");
      case this.START_WITH:
        return getText("startWithStr");
      case this.END_WITH:
        return getText("endWithStr");
      case this.IS_NULL:
        return getText("isNullStr");
      case this.IS_NOT_NULL:
        return getText("isNotNullStr");
      // 时间维度
      case this.BETWEEN_TIME:
        return getText("betweenTimeStr");
      case this.AFTER_TIME:
        return getText("afterTimeStr");
      case this.FORMER:
        return getText("formerStr");
      case this.DATE_THIS:
        return getText("dateThis");
      default:
        return "";
    }
  };

  static getGroupData = group => group.map(g => ({ label: this.getText(g), value: g }));

  // 指标的筛选 select
  static getNumberFilterGroupOptions = () => {
    const list = [
      [this.BETWEEN],
      [this.GREATER_THAN, this.GREATER_EQUAL_THAN, this.LESS_THAN, this.LESS_EQUAL_THAN],
      [this.EQUAL, this.NOT_EQUAL],
    ];
    return list.map(this.getGroupData);
  };

  // 维度的筛选 select
  static getTextFilterGroupOptions = () => {
    const list = [
      [this.IN, this.NOT_IN],
      [this.LIKE_IN, this.NOT_LIKE_IN, this.START_WITH, this.END_WITH],
      [this.IS_NULL, this.IS_NOT_NULL],
    ];
    return list.map(this.getGroupData);
  };

  // 维度映射类型的可选项
  static getTextChoiceFilterGroupOptions = () => {
    const list = [
      [this.IN, this.NOT_IN],
      [this.IS_NULL, this.IS_NOT_NULL],
    ];
    return list.map(this.getGroupData);
  };

  // 时间维度的筛选 select
  static getTimeFilterGroupOptions = () => {
    // TODO 暂时不显示 “在...之后” 类型
    // const list = [[this.BETWEEN_TIME, this.AFTER_TIME], [this.FORMER]];
    const list = [[this.BETWEEN_TIME], [this.FORMER]];
    return list.map(this.getGroupData);
  };

  static textFilterConditionTokensGetters = {
    [this.IN]: strArr => [
      {
        word: `${getText("in")}（${getJoinArr(strArr)}）`,
        prop: TokenProp.getInProp(strArr),
      },
    ],
    [this.NOT_IN]: strArr => [
      {
        word: `${getText("notIn")}（${getJoinArr(strArr)}）`,
        prop: TokenProp.getNotInProp(strArr),
      },
    ],
    // 省包含（江）
    [this.LIKE_IN]: strArr => [
      {
        word: `${getText("contain")}（${getJoinArr(strArr)}）`,
        prop: TokenProp.getLikeInProp(strArr),
      },
    ],
    // 省排除（江）
    [this.NOT_LIKE_IN]: strArr => [
      {
        word: `${getText("notContain")}（${getJoinArr(strArr)}）`,
        prop: TokenProp.getNotLikeInProp(strArr),
      },
    ],
    // 省以（江）开头
    [this.START_WITH]: strArr => [
      {
        word: `${getText("with")}（${getJoinArr(strArr)}）${getText("start")}`,
        prop: TokenProp.getStartWithProp(strArr),
      },
    ],
    // 省以（江）结尾
    [this.END_WITH]: strArr => [
      {
        word: `${getText("with")}（${getJoinArr(strArr)}）${getText("end")}`,
        prop: TokenProp.getEndWithProp(strArr),
      },
    ],
    [this.IS_NULL]: () => [
      {
        word: getText("isNull"),
        prop: TokenProp.NULL,
      },
    ],
    [this.IS_NOT_NULL]: () => [
      {
        word: getText("isNotNull"),
        prop: TokenProp.NOT_NULL,
      },
    ],
  };

  static getDateText = (year, month, date) => `${year}年${month}月${date}日`;

  static getQuarterText = (year, quarter) => `${year}年第${quarter}季度`;

  static getMonthText = (year, month) => `${year}年${month}月`;

  static getWeekText = (year, week) => `${year}年第${week}周`;

  static getYearText = year => `${year}年`;

  static getWordFormatTime = (time, isDate) => time.format(isDate ? this.DATE_WORD_FORMAT : this.DATE_TIME_WORD_FORMAT);

  static getPropFormatTime = (time, isDate) => time.format(isDate ? this.DATE_PROP_FORMAT : this.DATE_TIME_PROP_FORMAT);

  static getBetWeenFormatWord = (arr, type, isDate) => {
    if (window.locale === EN_US) {
      return `${arr.map(m => this.getWordFormatTime(m, isDate)).join(getText("to"))}`;
    }

    const timeFormatArray = arr.map(item => ({
      year: item.year(),
      quarter: item.quarter(),
      month: item.month() + 1,
      week: item.isoWeek(),
      date: item.date(),
    }));

    const startDate = timeFormatArray[0];
    const endDate = timeFormatArray[1];

    // 周特殊处理，暂不支持省略问法
    if (type === TimeUnit.WEEKS_ABBR) {
      if (startDate.year !== endDate.year && startDate.week === endDate.week) {
        return this.getWeekText(startDate.year, startDate.week);
      }
      return timeFormatArray.map(item => this.getWeekText(item.year, item.week)).join("至");
    }

    // 日处理
    if (type === TimeUnit.DAYS_ABBR) {
      if (startDate.year !== endDate.year) {
        return timeFormatArray.map(item => this.getDateText(item.year, item.month, item.date)).join("至");
      }
      if (startDate.month !== endDate.month) {
        return `${this.getDateText(startDate.year, startDate.month, startDate.date)}至${endDate.month}月${endDate.date}日`;
      }
      if (startDate.date !== endDate.date) {
        return `${this.getDateText(startDate.year, startDate.month, startDate.date)}至${endDate.date}日`;
      }

      return this.getDateText(startDate.year, startDate.month, startDate.date);
    }

    // 月处理
    if (type === TimeUnit.MONTHS_ABBR) {
      if (startDate.year !== endDate.year) {
        return timeFormatArray.map(item => this.getMonthText(item.year, item.month)).join("至");
      }
      if (startDate.month !== endDate.month) {
        return `${this.getMonthText(startDate.year, startDate.month)}至${endDate.month}月`;
      }

      return this.getMonthText(startDate.year, startDate.month);
    }

    // 季度处理
    if (type === TimeUnit.QUARTERS_ABBR) {
      if (startDate.year !== endDate.year) {
        return timeFormatArray.map(item => this.getQuarterText(item.year, item.quarter)).join("至");
      }
      if (startDate.quarter !== endDate.quarter) {
        return `${this.getQuarterText(startDate.year, startDate.quarter)}至第${endDate.quarter}季度`;
      }

      return this.getQuarterText(startDate.year, startDate.quarter);
    }

    // 年处理
    if (type === TimeUnit.YEARS_ABBR) {
      if (startDate.year !== endDate.year) {
        return timeFormatArray.map(item => this.getYearText(item.year)).join("至");
      }

      return this.getYearText(startDate.year);
    }

    return timeFormatArray.map(item => this.getDateText(item.year, item.month, item.date)).join("至");
  };

  static getTimeFormatWord = (time, type) => {
    if (window.locale === EN_US) return this.getWordFormatTime(time, true);

    const year = time.year();
    const quarter = time.quarter();
    const month = `${time.month() + 1}`;
    const week = time.isoWeek();
    const date = time.date();
    if (type === TimeUnit.DAYS_ABBR) return this.getDateText(year, month, date);
    if (type === TimeUnit.WEEKS_ABBR) return this.getWeekText(year, week);
    if (type === TimeUnit.MONTHS_ABBR) return this.getMonthText(year, month);
    if (type === TimeUnit.QUARTERS_ABBR) return this.getQuarterText(year, quarter);
    if (type === TimeUnit.YEARS_ABBR) return this.getYearText(year);
  };

  static betweenTokenWord = window.locale === EN_US;

  static timeFilterConditionTokensGetters = {
    // 在 … 之间
    [this.BETWEEN_TIME]: (momentArr, formerUnit, isDate) => {
      const getter = isDate ? TokenProp.getDateRangeProp : TokenProp.getDateTimeRangeProp;
      return [
        {
          word: `${getText("inStr")}`,
          prop: TokenProp.EQUAL,
        },
        {
          word: this.getBetWeenFormatWord(momentArr, formerUnit, isDate),
          prop: getter(momentArr.map(m => this.getPropFormatTime(m, isDate))),
        },
      ];
    },
    // 在 … 之后
    [this.AFTER_TIME]: (momentArr, formerUnit, isDate) => {
      if (isDate) {
        return [
          {
            word: ">",
            prop: TokenProp.GREATER_EQUAL_THAN,
          },
          {
            word: this.getTimeFormatWord(momentArr[0], formerUnit),
            prop: TokenProp.getDatePoint(this.getPropFormatTime(momentArr[0], true)),
          },
        ];
      }
      return [
        {
          word: ">",
          prop: TokenProp.GREATER_EQUAL_THAN,
        },
        {
          word: `${this.getPropFormatTime(momentArr[0], false)}}`,
          prop: TokenProp.getDateTimeRangeProp(momentArr.map(m => this.getPropFormatTime(m, false)).join("")),
        },
      ];
    },
    [this.FORMER]: (time, unitAbbr) => {
      return [
        {
          word: `${getText("inStr")}`,
          prop: TokenProp.EQUAL,
        },
        {
          word: `${getText("formerStr")}${time}${TimeUnit.getTextByAbbr(unitAbbr)}`,
          prop: TokenProp.getDateBeforeProp(`${time}${unitAbbr}`),
        },
      ];
    },
  };

  static numberFilterConditionTokensGetters = {
    // 介于 ... 之间
    [this.BETWEEN]: arr => [
      {
        word: `${getText("betweenness")}${arr[0]}${getText("betweenTo")}${arr[1]}${getText("between")}`,
        prop: TokenProp.getInRangeProp(arr),
      },
    ],
    // 大于(>)
    [this.GREATER_THAN]: num => [
      {
        word: ">",
        prop: TokenProp.GREATER_THAN,
      },
      {
        word: num,
        prop: TokenProp.UNKNOWN,
      },
    ],
    // 大于等于(>=)
    [this.GREATER_EQUAL_THAN]: num => [
      {
        word: ">=",
        prop: TokenProp.GREATER_EQUAL_THAN,
      },
      {
        word: num,
        prop: TokenProp.UNKNOWN,
      },
    ],
    // 小于(<)
    [this.LESS_THAN]: num => [
      {
        word: "<",
        prop: TokenProp.LESS_THAN,
      },
      {
        word: num,
        prop: TokenProp.UNKNOWN,
      },
    ],
    // 小于等于(<=)
    [this.LESS_EQUAL_THAN]: num => [
      {
        word: "<=",
        prop: TokenProp.LESS_EQUAL_THAN,
      },
      {
        word: num,
        prop: TokenProp.UNKNOWN,
      },
    ],
    // 等于(=)
    [this.EQUAL]: num => [
      {
        word: "=",
        prop: TokenProp.EQUAL,
      },
      {
        word: num,
        prop: TokenProp.UNKNOWN,
      },
    ],
    // 不等于(≠)
    [this.NOT_EQUAL]: num => [
      {
        word: "!=",
        prop: TokenProp.NOT_EQUAL,
      },
      {
        word: num,
        prop: TokenProp.UNKNOWN,
      },
    ],
    // 为空
    [this.IS_NULL]: () => [
      {
        word: getText("isNull"),
        prop: TokenProp.NULL,
      },
    ],
    // 不为空
    [this.IS_NOT_NULL]: () => [
      {
        word: getText("isNotNull"),
        prop: TokenProp.NOT_NULL,
      },
    ],
  };

  // 语义校准规则 token 格式
  static getFeedbackTokens = (word, id) => [
    {
      word,
      prop: TokenProp.getFeedbackProp(id),
    },
  ];

  // 自定义数据集 token 格式
  static getCustomDatasetTokens = (word, id) => [
    {
      word,
      prop: TokenProp.getCustomDatasetProp(id),
    },
  ];
}
