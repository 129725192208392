export default {
  addIntroInputPlaceholder: "Add description (optional)",
  formerApplyForPermissionText: "Apply for permission to",
  latterApplyForPermissionText: "And you can view it after approval",
  waitForAuditBtnText: "Waiting for approval",
  note: "Note",
  applicationFailTip: "Your application has not been approved and you cannot view it temporarily.",
  applyAgainBtnText: "Continue to apply",
  applyForViewBtnText: "Apply to view",
  noMorePrompt: "Do not prompt again",
  haveLearnedBtnText: "I have learned",
  noAuthInWorkspaceText: "You are not in the workspace where the card is located, please contact the workspace administrator",
  shareClosedText: "The current share has been closed",
  showMore: "Show more",
  noContent: "No card in the report at this time",
};
