import React from "react";

const DragDot = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.33325 6.6665C2.88554 6.6665 3.33325 6.21879 3.33325 5.6665C3.33325 5.11422 2.88554 4.6665 2.33325 4.6665C1.78097 4.6665 1.33325 5.11422 1.33325 5.6665C1.33325 6.21879 1.78097 6.6665 2.33325 6.6665Z"
      fill="currentColor"
      fillOpacity="0.65"
    />
    <path
      d="M2.33325 11.3331C2.88554 11.3331 3.33325 10.8854 3.33325 10.3331C3.33325 9.78085 2.88554 9.33313 2.33325 9.33313C1.78097 9.33313 1.33325 9.78085 1.33325 10.3331C1.33325 10.8854 1.78097 11.3331 2.33325 11.3331Z"
      fill="currentColor"
      fillOpacity="0.65"
    />
    <path
      d="M8.99994 5.6665C8.99994 6.21879 8.55222 6.6665 7.99994 6.6665C7.44765 6.6665 6.99994 6.21879 6.99994 5.6665C6.99994 5.11422 7.44765 4.6665 7.99994 4.6665C8.55222 4.6665 8.99994 5.11422 8.99994 5.6665Z"
      fill="currentColor"
      fillOpacity="0.65"
    />
    <path
      d="M7.99994 11.3331C8.55222 11.3331 8.99994 10.8854 8.99994 10.3331C8.99994 9.78085 8.55222 9.33313 7.99994 9.33313C7.44765 9.33313 6.99994 9.78085 6.99994 10.3331C6.99994 10.8854 7.44765 11.3331 7.99994 11.3331Z"
      fill="currentColor"
      fillOpacity="0.65"
    />
    <path
      d="M14.6666 5.6665C14.6666 6.21879 14.2189 6.6665 13.6666 6.6665C13.1143 6.6665 12.6666 6.21879 12.6666 5.6665C12.6666 5.11422 13.1143 4.6665 13.6666 4.6665C14.2189 4.6665 14.6666 5.11422 14.6666 5.6665Z"
      fill="currentColor"
      fillOpacity="0.65"
    />
    <path
      d="M13.6666 11.3331C14.2189 11.3331 14.6666 10.8854 14.6666 10.3331C14.6666 9.78085 14.2189 9.33313 13.6666 9.33313C13.1143 9.33313 12.6666 9.78085 12.6666 10.3331C12.6666 10.8854 13.1143 11.3331 13.6666 11.3331Z"
      fill="currentColor"
      fillOpacity="0.65"
    />
  </svg>
);

export default DragDot;
