import React from "react";
import { getLocaleText } from "@/utils/locale";

import loadingGif from "@/assets/loading.gif";

import styles from "./FilterLoading.module.scss";

const getText = id => getLocaleText(`components.FilterConditionContent.${id}`);

const FilterLoading = () => {
  return (
    <div className={styles.spinLoading}>
      <img height="32px" alt="" src={loadingGif} style={{ marginBottom: "12px" }} />
      <div>{getText("loading")}</div>
    </div>
  );
};

export default FilterLoading;
