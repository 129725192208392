// 工作区增加协作控制开关;

import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Workspace.WorkspaceView.${id}`);

export default class CollaborationType {
  static BANNED_ALL = 1; // 禁止全部用户

  static ALLOW_OWNER = 2; // 仅允许负责人

  static ALLOW_OWNER_AND_MANAGER = 3; // 允许负责人和管理员

  static ALLOW_ALL = 4; // 允许全部用户

  static getText = v => {
    switch (v) {
      case this.BANNED_ALL:
        return getText("collaborationBannedAll");
      case this.ALLOW_OWNER:
        return getText("collaborationAllowOwner");
      case this.ALLOW_OWNER_AND_MANAGER:
        return getText("collaborationAllowManager");
      case this.ALLOW_ALL:
        return getText("collaborationAllowAll");
      default:
        return "";
    }
  };

  static choices = [this.ALLOW_ALL, this.ALLOW_OWNER, this.ALLOW_OWNER_AND_MANAGER, this.BANNED_ALL];

  static getOptions = () => this.choices.map(k => ({ label: this.getText(k), value: k }));
}
