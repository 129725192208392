// $ 为参数占位符
export default {
  // 通用错误码，前缀为 00
  "0010000": "未知错误",
  "0010001": "工作区不存在，用户有其他的工作区",
  "0010002": "工作区不存在，用户没有其他的工作区",
  "0010004": "该用户在工作区内权限不足",
  "0010005": "手机号不正确",
  "0010006": "邮箱不正确",
  "0010007": "登录名格式不正确：登录名应为数字和字母的组合，不能为纯数字，不能包含@特殊符号",
  "0010008": "问题参数类型不正确",
  "0010009": "问题参数不能为空",
  "0010010": "offset 和 limit 不正确",
  "0020000": "$出现未知错误",
  "0020001": "$参数未传递$",
  "0020002": "$参数不能为空",
  "0020003": "$参数值最小为$",
  "0020004": "$参数值最大为$",
  "0020005": "$参数不允许包含 URI 保留字符",
  "0020006": "$参数长度不能低于$",
  "0020007": "$参数长度不能超过$",
  "0020008": "$参数长度必须为$",
  "0020009": "$参数必须为列表类型",
  "0020010": "$参数不正确",

  // 异步任务错误码，前缀为 01
  "0110000": "未知错误",
  "0110001": "请求过于频繁, 请稍后再试",
  "0110002": "不被允许的任务类型",
  "0110003": "不支持的异步任务",
  "0120000": "$出现未知错误",
  "0120001": "异步任务[$]不存在",
  "0120002": "更新异步任务[$]失败",

  // 登录认证错误码，前缀为 02
  "0210000": "未知错误",
  "0210001": "用户数超额，系统无法使用，请联系管理员",
  "0210002": "手机号不存在",
  "0210003": "邮箱不存在",
  "0210004": "登录名不存在",
  "0210005": "登录名密码不匹配",
  "0210006": "验证码无效",
  "0210007": "验证码不正确",
  "0210008": "登录失败",
  "0210009": "请输入正确的手机号码或者邮箱",
  "0210010": "未设置邮箱和手机号，无法进行登录验证",
  "0210011": "用户不存在",
  "0220000": "$出现未知错误",

  // 卡片相关错误码，前缀为 03
  "0310000": "未知错误",
  "0310001": "链接错误",
  "0310002": "卡片分享不存在",
  "0310003": "卡片分享已失效",
  "0310004": "卡片已存在",
  "0310005": "您没有访问卡片的权限",
  "0310006": "卡片不存在",
  "0310007": "您无卡片权限",
  "0310008": "您不是该卡片的创建者， 非创建者不可修改卡片",
  "0320000": "$出现未知错误",

  // 消息中心相关错误码，前缀为 04
  "0410000": "未知错误",
  "0410001": "scroll 和 anchor 必须同时存在",
  "0410002": "锚点处消息不存在",
  "0410003": "消息不存在",
  "0410004": "未审核的消息无法删除",
  "0420000": "$出现未知错误",

  // 报告相关错误码，前缀为 05
  "0510000": "未知错误",
  "0510001": "报告数量已达上限，请联系系统管理员进行增加",
  "0510003": "布局数据错误，请重新编辑",
  "0510004": "报告不存在",
  "0510005": "用户不存在",
  "0510006": "消息不存在",
  "0510007": "您没有权限阅读报告",
  "0510008": "您没有权限编辑报告",
  "0510009": "你不在此工作区，无法申请此报告权限",
  "0510010": "该报告未开启链接分享",
  "0510011": "该报告分类下没有对应的二级分类",
  "0510012": "您的账户没有创建副本权限，请联系管理员",
  "0510013": "搜索账户，不允许创建报告副本",
  "0510014": "工作区不存在",
  "0510015": "问题不在报告中",
  "0510016": "question 和 uid 参数至少传递一个",
  "0510017": "创建报告副本时错误",
  "0510018": "您的权限不足",
  "0510019": "您尚未配置服务网址",
  "0510020": "您不具有分享报告权限",
  "0510021": "您不具有移交负责人权限",
  "0510022": "工作区不存在",
  "0510023": "您没有审批权限",
  "0510024": "用户组不存在",
  "0510025": "查看报告申请已存在",
  "0510026": "报告审批状态错误",
  "0520000": "$出现未知错误",
  "0520001": "该审批消息[$]请求未能匹配到对应请求",

  // 贡献度相关错误码，前缀为06
  "0610000": "未知错误",
  "0610001": "问题未包含时间维度，请尝试换个问法",
  "0620000": "$出现未知错误",

  // SQL 查询数据相关错误码，前缀为07
  "0710000": "未知错误",
  "0710001": "工作区尚未配置数据源连接",
  "0710002": "尚未配置数据库地址 Host",
  "0720000": "$出现未知错误",

  // 模型 summary 查询错误
  1210001: "工作区不存在",
  1220000: "模型不存在",
  1221000: "模型概览获取失败",
};
