import { getLocaleText } from "@/utils/locale";

const getLocalLanguageText = id => getLocaleText(`components.Constants.CardToggle.${id}`);

export default class ComparisonType {
  static YOY = "yoy";

  static RING = "ring";

  static choices = [this.YOY, this.RING];

  static getText = type => {
    if (type === this.YOY) return getLocalLanguageText("YOY");
    if (type === this.RING) return getLocalLanguageText("RING");
    return "";
  };
}
