export default {
  notSet: "未设置",
  carefullyReplaceDataSources: "请谨慎更换数据源",
  allModelsWillFail: "更换后工作区所有的模型均会失效，可能会造成短时间难以使用及数据不准。确认仍要更换数据源？",
  excelCarefullyReplaceDataSources: "此数据库切换后将被删除",
  excelAllModelsWillFail1: "当前所有的数据表将被删除！",
  excelAllModelsWillFail2: "再次切换回自有数据源也无法找回！",
  excelAllModelsWillFail3: "请谨慎操作！",
  excelSecondCarefullyReplaceDataSources: "确认切换为其他数据源",
  excelSecondAllModelsWillFail1: "当前工作区所有上传的数据表将被删除",
  excelSecondAllModelsWillFail2: "再次切换回自有数据源也无法找回",
  excelSecondAllModelsWillFail3: "请谨慎操作！",
  dataCacheRefreshSuccessful: "数据缓存刷新成功",
  dataSource: "连接信息",
  edit: "编辑",
  refreshCacheData: "刷新缓存数据",
  confirmPopExcel: "进入切换",
  confirmSecondPopExcel: "确认切换",
  confirm: "确认",
  cancel: "取消",
  replaceDataSource: "切换数据源",
  databaseType: "数据库类型",
  collocationMethod: "配置方式",
  dataSourceUrl: "数据源URL",
  dataSourceMessage: "请输入数据源URL",
  detailedConfiguration: "详细配置",
  host: "服务器(Host)",
  hostMessage: "请输入服务器地址",
  hostPlaceholder: "数据库服务器地址, 例如 123.4.56.789 或 database-address.com",
  port: "端口(Port)",
  portMessage: "请输入端口",
  user: "用户名(User)",
  userMessage: "请输入用户名",
  password: "密码(Password)",
  database: "数据库(Database)",
  databaseMessage: "请输入数据库",
  SIDMessage: "请输入SID",
  server: "实例名称(Server)",
  serverMessage: "请输入实例名称",
  protocol: "协议(Protocol)",
  dbLocale: "服务字符集(DB_LOCALE)",
  clientLocale: "客户端字符集(CLIENT_LOCALE)",
  connectionType: "连接类型",
  connectionStatus: "连接状态",
  connected: "已连通",
  connectionFailed: "连接失败",
  authMechanism: "身份验证(Auth_mechanism)",
  referenceDocumentsIs: "参考文档：",
  dataSourceConfigurationDescription: " 说明文档",
  fileStyleTemplate: "帮助文档",
  suggestAdjustingTheModel: "数据源切换后建议调整模型",
  mayCauseModelFailure: "数据源更换可能会导致模型中的表失效，从而使整个模型失效，建议相应地调整模型。",
  goToConfigurationModel: "前往配置模型",
  dataFileFormatError: "数据文件格式出错",
  uploadedFileOnlySupports: "当前上传文件仅支持 .csv .xls .xlsx 格式",
  exceededUploadLimit: "超过上传限制",
  fileCapacityExceedsTheMaximumLimit: "文件容量超过上限 50M。",
  dataTableUploadError: "数据表上传错误",
  uploadFailed: "上传失败",
  dataTableName: "数据表名",
  recentOperator: "最近操作人",
  updatedAt: "更新时间",
  operation: "操作",
  update: "更新",
  areYouSureToDeleteThisDataTable: "确认删除此数据表？",
  appliedToTheModel: "此数据表已应用于模型，删除后对应模型中的表也会被一并删除，可能导致模型失效。",
  remove: "移除",
  pleaseEnterTheTableName: "请输入想查找的表名",
  uploadFiles: "上传文件",
  dataSourceSettings: "数据源设置",
  pleaseSelectDataSource: "请选择您需要连接的数据源，可以上传文件或直接点击想连接的数据库类型进行连接设置",
  none: "无",
  databaseConnectionSuccessful: "数据库连接成功",
  noModification: "不改动",
  modify: "修改",
  fillOutTheConfigurationDocument: "请根据选择的数据库类型，填写正确的配置文档",
  referenceDocuments: "参考文档",
  currentDatabaseType: "当前数据库类型：",
  pleaseInput: "请输入",
  optional: "选填",
  required: "必填",
  optionalRemarks: "选填，不设置将使用默认值",
  save: "保存",
  successfullySet: "设置成功",
  dataQuerySettings: "数据查询设置",
  setUp: "设置",
  maximumQueryTime: "最大查询时间",
  maximumQueryTimeExtra: "对数据库的最大查询时间，实际查询超过此时间会被取消，最多12小时",
  maximumQueryTimePlaceholder: "请输入 1 ~ 43200 之间的整数",
  second: "秒",
  authentication: "身份验证方式",
  username: "用户名",
  clusterIdentifier: "集群标识符",
  accessKey: "IAM 访问密钥",
  secretAccessKey: "IAM 秘密访问密钥",
  sessionToken: "使用临时凭证时的访问密钥",
  region: "集群所在的 AWS 区域",
  clusterIdentifierMessage: "请输入集群标识符",
  regionMessage: "集群所在的 AWS 区域",
  advancedSetting: "高级设置",
  advancedSettingTooltip: "请填写 json 格式的设置信息",
  selfDBTitle: "自有数据存储引擎",
  selfDBDetail: "北极自带的数据服务，快速搭建自己的数据库，方便快捷、轻松管理",
  selfDBDes1: "支持直接在本系统中上传 CSV/EXCEL 文件、管理数据库表",
  selfDBDes2: "支持在数据库端直接管理数据",
  recommendDataSource: "推荐",
  recommendDataSourceDetail: "推荐使用以下数据源，如有疑问请查阅",
  limitedSupportDataSource: "有限支持",
  limitedSupportDataSourceDetail:
    "以下数据库提供有限支持，数据库部分版本或者查询场景未完全适配或测试，仅用于体验试用。如需完善支持，请联系我们",
  dbLabel: "存储容量上限",
  dbRowLabel: "数据行数上限",
  dbLabelUsed: "已使用容量",
  dbRowLabelUsed: "已存储数据行数",
  tenThousandUnit: "万行",
  rowUnit: "行",
  assetManage: "资源管理",
  copyLinkSuccessToast: "复制成功",
  copyLinkFailToast: "复制失败",
  watchPassword: "查看密码",
  copyText: "复制",
  resetPasswordModalTitle: "重置数据库密码",
  resetPasswordModalContent: "当前数据库的密码将被重置",
  resetPasswordModalOkBtnText: "确认重置",
  resetPasswordSuccessToast: "重置密码成功",
  checkPasswordBtnText: "查看",
  resetPasswordBtnText: "重置",
  setExcelConfigTitle: "配置数据存储引擎",
  autoConfigExcelProgressTitle: "自动配置数据连接信息",
  connectInternalDBError1: "当前数据库连接失败，无法查询资源使用情况",
  connectInternalDBError2: "请联系运维处理",
  formatMessage: "请输入符合 JSON 结构的字符串",
};
