export default {
  dimension: "Dimension",
  measure: "Measure",
  dateTag: "Time tag",
  analysis: "Analytics",
  filter: "Filter",
  dateRange: "Time range",
  keyword: "Keyword",
  fromDimension: "Dimension",
  category: "Category",
  synonyms: "Synonyms",
  description: "About",
  model: "Model",
  models: " models",
  aliases: "Synonyms",
  polysemyTip: "It is polysemic field, you can select in menu",
  shortcut: "Matching tokens",
  formula: "Formula",
  semanticCalibrationRules: "Semantic rules",
  dimensionValue: "Value of Dimension",
  fuzzyEditModalTitle: "Fuzzy: ",
  fuzzyEditModalLeftTitle: "Fuzzy result type",
  fuzzyEditModalRightTitle: "Fuzzy result confirmation",
  fuzzyEditModalRightTip: "Only supports various dimension values under a single fuzzy dimension",
  fuzzy: "Fuzzy",
  fuzzyContain: "Represent",
  polysemy: "You want to ask",
  pinboard: "Pinboard",
  pinboardTip: "Click to directly navigate to the report and apply the filtering options from the question",
  cancelFuzzyPrefix: "This word does not require fuzziness? Click",
  cancelFuzzy: "Cancel Fuzzy",
  cancelFuzzyConfirmTitle:
    "After canceling fuzziness, the current question will no longer tokenize this word (i.e., treat the word as a meaningless term).",
  deleteFuzzyLog: "Delete fuzzy records",
  deleteFuzzyLogConfirmTitle:
    "After deleting fuzzy records, all operations confirming fuzzy results will be invalidated, reverting to the initial fuzzy matching results.",
  polysemyEditModalTitle: "has multiple meanings",
  polysemySubTittlePre: "You want to ask",
  polysemySubTittlePost: "as:",
  dimensionMatchOptionSocket: "dimension values",
  cancel: "Cancel",
  ok: "Ok",
  confirmOk: "Confirm",
  fuzzyTipTitle: "Please click to edit the fuzzy matching results",
  fuzzyTipOrigin: "Original Word: ",
  fuzzyMatch: "Fuzzy Match: ",
  proportion: "Proportion",
  proportionTitleTip:
    "When searching for multiple indicators, the proportion logic will be used to display the proportion of each indicator as follows.",
  proportionModalFooterTip: "*Note: The proportion diagram is for illustration purposes only. The final chart is based on actual results.",
  numerator: "Numerator",
  proportionDataGroup: "Data grouping(Group by)",
  proportionDataGroupTip: "The chart is divided into multiple bars or pies according to the grouping.",
  proportionFilter: "Data range filtering",
  proportionFilterTip: "Simultaneously filter numerator and denominator.",
  proportionExampleTitle: "Final search results and percentage illustration",
  denominator: "Denominator",
  proportionNumeratorErrorTip: "The numerator needs to have at least one dimension or dimension value.",
  proportionDataGroupErrorTip: "Filtering only supports dimension values.",
  proportionFilterErrorTip: "Data grouping only supports dimensions.",
  example: "Example Image",
  dataGroupDefaultTip: "Please drag and drop the dimension",
  filterDefaultTip: "Please drag and drop the dimension value",
  notOpenLLMTip:
    "The large language model conversation feature has not been configured and enabled yet. Please contact the system administrator to configure it",
  fuzzySkip: "Ok",
  fuzzyNext: "View now",
  fuzzyEntry:
    "For words with unclear meaning (marked with a dotted line), we automatically match the ambiguous results. You can click here to adjust them.",

  okBtn: "Confirm",
  fuzzyType: "Here you can switch the type of blur result",
  fuzzyConfirm: "Here you can check the result you want",
  fuzzyCancel:
    "If you think the current word does not need to be blurred, you can click [Unblur]. If you think the above are not the results you want, you can click [Calibrate Feedback] to select more indicator dimensions.",
  proportionEntry:
    "The specific numerator and denominator of the percentage can be seen here. You can also click here to adjust the structure of the percentage",
  proportionDrag:
    "The marked block at the front can be dragged to other modules. You can drag and drop to form the final proportion structure according to your needs.",
  proportionResult:
    "Here is the final ratio composition, you can clearly see what the numerator and denominator are. If there are multiple indicators, the other indicators will also use the same ratio.",
  proportionChart:
    "Here is a brief summary of the percentage results to help you understand the current percentage structure. It is for reference only.",
  showMore: "Show More",
  packUp: "Pack Up",
  projectBlockTip: "I guess the data you want to ask about is not in this workspace",
  aiFill: "AI automatic",
  mathMeasure: "Matching measure:",
  delimiter: ",",
  fuzzyTip: "If fuzzy words are found, the results will be matched automatically. You can click the right block to modify them",
  questionPolysemyTip: "I found that some words have multiple meanings. I guess you want to ask",
  proportionTip:
    "The question contains a percentage question, and the numerator and denominator are automatically selected. You can click the block on the right to modify it.",
  pinboardSearchTip: "The system has a report that matches the system. Click to view it.",
  cancelContainPrefixText: "Does this term not use inclusive logic? Click",
  cancelContainText: "Cancel inclusion",
  fuzzyCancelContainTip: "After canceling inclusion logic, fuzzy logic will be automatically used when searching for this term again",
};
