import React from "react";

const NormalStatusIcon = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00403 0C3.1384 0 0.00402832 3.13437 0.00402832 7C0.00402832 10.8656 3.1384 14 7.00403 14C10.8697 14 14.004 10.8656 14.004 7C14.004 3.13437 10.8697 0 7.00403 0ZM10.0275 4.71406L6.73684 9.27656C6.69085 9.34076 6.63022 9.39306 6.55998 9.42914C6.48973 9.46523 6.4119 9.48405 6.33293 9.48405C6.25397 9.48405 6.17613 9.46523 6.10589 9.42914C6.03565 9.39306 5.97502 9.34076 5.92903 9.27656L3.98059 6.57656C3.92122 6.49375 3.98059 6.37812 4.08215 6.37812H4.81497C4.97434 6.37812 5.1259 6.45469 5.21965 6.58594L6.33215 8.12969L8.7884 4.72344C8.88215 4.59375 9.03215 4.51562 9.19309 4.51562H9.9259C10.0275 4.51562 10.0868 4.63125 10.0275 4.71406Z"
      fill="currentColor"
    />
  </svg>
);

export default NormalStatusIcon;
