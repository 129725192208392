import { Input as AntdInput } from "antd";
import { TextAreaProps, TextAreaRef } from "antd/lib/input/TextArea";
import React, { useState } from "react";

import "./TextArea.module.scss";

const InternalTextArea: React.FC<TextAreaProps> = React.forwardRef(
  ({ className, size = "small", defaultValue, onChange, value, ...rest }, ref: React.Ref<TextAreaRef>) => {
    const [hasValue, setHasValue] = useState<boolean>(!!(defaultValue || value));

    let textAreaClassName = "textareaPrefixA textareaPrefixB";
    if (hasValue) textAreaClassName += ` fillTextarea`;
    if (size === "large") textAreaClassName += ` largeTextarea`;
    if (className) textAreaClassName += ` ${className}`;
    const onValueChange = e => {
      setHasValue(!!e.target.value);
      onChange?.(e);
    };

    return (
      <AntdInput.TextArea
        ref={ref}
        size={size}
        onChange={onValueChange}
        className={textAreaClassName}
        defaultValue={defaultValue}
        value={value}
        {...rest}
      />
    );
  }
);

const TextArea = InternalTextArea as typeof AntdInput.TextArea;

export default TextArea;
