export default {
  canOnlyConnectFactTables: "Dimension tables can only be connected to fact tables",
  saveSuccessToast: "Save Successfully",
  onlyOneFactTableIsAllowed:
    "The star model only allows one fact table as the center, and one fact table already exists in the action area.",
  needFactTableAsTheCenter:
    "The star model needs a fact table as the center. You can add 1 fact table first, or set 1 of them as a fact table.",
  dimensionTable: "Dimension table",
  findDimensionTable: "Find Dimension Table",
  canBeReusedInModel: "You can reuse these configured tables in the model in that workspace.",
  learnMore: "Learn more",
  loading: "Loading...",
  loadMore: "Load More",
  dataTableWasNotFound: "The corresponding data table was not found, please try another keyword.",
  dataTable: "Data table",
  findDataTable: "Find data table",
  customTable: "Custom Table",
  autoJoining: "Auto connecting",
};
