export default {
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  /* 起点 */
  preRuleStartNodeLabel: "User searching problems",
  ruleStartNodeLabel: "Problem after parsing",
  /* 条件 */
  deleteConditionConfirmTitle: "Confirm to delete this condition?",
  emptyConditionNodeLabel: "Condition not set",
  conditionNodeFormerLabel: "Satisfied",
  matchAllLatterLabel: "All conditions",
  matchAnyLatterLabel: "Any condition",
  conditionDrawerTitle: "Condition settings",
  matchTypeLabel: "Match type",
  selectPlaceholder: "Please select",
  andLabel: "All conditions meet",
  orLabel: "Meet any condition",
  expressionsLabel: "Matching condition",
  questionLabel: "Input question",
  dateRangeLabel: "Time range days",
  dimensionsLabel: "Number of dimensions",
  dimensionsMatchLabel: "Number of dimension values",
  measuresLabel: "Number of measures",
  timeFuncLabel: "Time aggregation quantity",
  timeRangeLabel: "Number of time ranges",
  keywordsLabel: "Number of keywords",
  orderLabel: "Number of sort",
  limitLabel: "Number of limit words",
  sumLabel: "Number of indicator filters",
  gtLabel: "Greater than",
  gteLabel: "Not less than",
  ltLabel: "Less than",
  lteLabel: "No more than",
  eqLabel: "Equal to",
  neqLabel: "Not equal to",
  containLabel: "Contains",
  notContainLabel: "Not included",
  numberInputErrMsg: "Please enter a number",
  textInputErrMsg: "Please enter a keyword",
  deleteExpressionConfirmTitle: "Confirm to delete this condition?",
  addExpressionBtnText: "Add condition",
  saveConditionBtnText: "Save",
  /* 操作 */
  deleteActionConfirmTitle: "Confirm to delete this operation?",
  emptyActionNodeLabel: "Operation not set",
  actionNodeLabel: "Do the following",
  actionFormerLabel: "Operation",
  addLabel: "Increase",
  replaceLabel: "Replace",
  deleteLabel: "Delete",
  addFormerText: "Increase",
  addMiddleText: "“to the question ",
  addBeforeLatterText: "Former",
  addAfterLatterText: "After",
  replaceFormerText: "Replace",
  replaceMiddleText: "”into“",
  replaceLatterText: "”",
  deleteFormerText: "Delete",
};
