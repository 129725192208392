export default class DownloadAccountResult {
  static DEAL_ACCOUNT_ERROR = 1;

  static GENERATE_ACCOUNT_FILE_FAIL = 2;

  static DOWNLOAD_FILE_NAME_ERROR = 3;

  static getText = status => {
    if (status === this.DEAL_ACCOUNT_ERROR) return "处理用户数据失败";
    if (status === this.GENERATE_ACCOUNT_FILE_FAIL) return "生成用户文件失败";
    if (status === this.DOWNLOAD_FILE_NAME_ERROR) return "传递的用户文件名错误";
    return "";
  };
}
