export default {
  confirm: "确认",
  manageTags: "管理标签",
  clear: "清除",
  noTagTip: "您目前没有标签，请点击右上角“管理标签”创建标签。",
  preQuitTip: "如果您不需要设置标签，请点击 “",
  afterQuitTip: "” 按钮完成设置。",
  save: "保存修改",
  addCategory: " 添加分类",
  needCategoryName: "分类名称未填写",
  cancel: "取消",
  categoryName: "分类名称",
  delete: "删除",
  editCategory: "修改分类",
  addTag: "新建标签",
  editTag: "编辑标签",
  noTagName: "标签名称未填写",
  tagCategory: "标签分类",
  tagName: "标签名称",
};
