import React from "react";

const PinboardTabMine = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0002 11.6666C10.4585 11.6666 10.851 11.5033 11.1777 11.1766C11.5038 10.8505 11.6668 10.4583 11.6668 9.99996C11.6668 9.54163 11.5038 9.14913 11.1777 8.82246C10.851 8.49635 10.4585 8.33329 10.0002 8.33329C9.54183 8.33329 9.14961 8.49635 8.8235 8.82246C8.49683 9.14913 8.3335 9.54163 8.3335 9.99996C8.3335 10.4583 8.49683 10.8505 8.8235 11.1766C9.14961 11.5033 9.54183 11.6666 10.0002 11.6666ZM6.66683 15H13.3335V14.5208C13.3335 14.1875 13.2432 13.8819 13.0627 13.6041C12.8821 13.3263 12.6321 13.118 12.3127 12.9791C11.9516 12.8263 11.5802 12.7083 11.1985 12.625C10.8163 12.5416 10.4168 12.5 10.0002 12.5C9.5835 12.5 9.18405 12.5416 8.80183 12.625C8.42016 12.7083 8.04877 12.8263 7.68766 12.9791C7.36822 13.118 7.11822 13.3263 6.93766 13.6041C6.75711 13.8819 6.66683 14.1875 6.66683 14.5208V15ZM15.0002 18.3333H5.00016C4.54183 18.3333 4.14961 18.1702 3.8235 17.8441C3.49683 17.5175 3.3335 17.125 3.3335 16.6666V3.33329C3.3335 2.87496 3.49683 2.48246 3.8235 2.15579C4.14961 1.82968 4.54183 1.66663 5.00016 1.66663H11.6668L16.6668 6.66663V16.6666C16.6668 17.125 16.5038 17.5175 16.1777 17.8441C15.851 18.1702 15.4585 18.3333 15.0002 18.3333ZM15.0002 16.6666V7.37496L10.9585 3.33329H5.00016V16.6666H15.0002Z"
      fill="currentColor"
    />
  </svg>
);

export default PinboardTabMine;
