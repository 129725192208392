import React from "react";

const Chat = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9802 13.2896C11.2702 13.2796 10.7002 12.7096 10.7002 11.9996C10.7002 11.2996 11.2802 10.7196 11.9802 10.7296C12.6902 10.7296 13.2602 11.2996 13.2602 12.0096C13.2602 12.7096 12.6902 13.2896 11.9802 13.2896ZM7.36984 13.2896C6.66984 13.2896 6.08984 12.7096 6.08984 12.0096C6.08984 11.2996 6.65984 10.7296 7.36984 10.7296C8.07984 10.7296 8.64984 11.2996 8.64984 12.0096C8.64984 12.7096 8.07984 13.2796 7.36984 13.2896ZM15.31 12.0096C15.31 12.7096 15.88 13.2896 16.59 13.2896C17.3 13.2896 17.87 12.7096 17.87 12.0096C17.87 11.2996 17.3 10.7296 16.59 10.7296C15.88 10.7296 15.31 11.2996 15.31 12.0096Z"
      fill="currentColor"
    />
  </svg>
);
export default Chat;
