export default {
  copyLinkSuccessToast: "Link copied",
  copyLinkFailToast: "Copy link failed, please try again later",
  linkShareOpenTip: "User with this link can access this card after sign in",
  linkShareCloseTip: "Once sharing enabled, card can be shared via link",
  copyLink: "Copy Link",
  shareLink: "Share Link",
  openLinkShare: " link sharing",
  already: "Enabled",
  not: "Disabled",
  creatingCSVFile: "Creating CSV File",
  createSuccessText: "File created",
  createFailText: "Create file failed",
  cancelCreateCSVFileConfirmText: "Are you sure you want to cancel creating csv file?",
};
