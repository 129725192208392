export default class TipType {
  static DIMENSION_PERMISSION = 1;

  static MEASURE_PERMISSION = 2;

  static MEASURE_MISSING = 3;

  static DIMENSION_MISSING = 4;

  static TIME_SERIES_MISSING = 5;

  static DOUBLE_TIME_FUNC_INVALID = 6;

  static TIME_FUNC_INVALID = 7;

  static DATE_RANGE_INVALID = 8;
}
