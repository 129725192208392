export default {
  inputOneValue: "Enter 1 value",
  mobileNumberInputPlaceholder: "Enter an integer",
  minValueText: "Minimum Value",
  maxValueText: "Maximum Value",
  minValue: "Minimum Value (≥)",
  maxValue: "Maximum Value (≤)",
  NoIntersection: "Filter conditions have no intersection",
  AddCondition: "Add Condition",
  meetAllConditions: "Display data that meets all conditions",
  inputInt: "Please enter a positive integer",
  loading: "Loading, please wait...",
  batchAddValue: "Batch add included values",
  findNoResults: "No results found, please try a different keyword.",
  notInDatabase: "This dimension value may not be in the database. ",
  selectStartTimeTitle: "Select Start Time",
  selectEndTimeTitle: "Select End Time",
  startTimeLabel: "Start Time",
  endTimeLabel: "End Time",
  customRangeTimeTitle: "Custom Time Range",
  selectAfterTimeTitle: "Select after...",
  selectFormerTimeTitle: "Select time range",
  applyButtonText: "Apply",
  okButtonText: "OK",
  dateTimeFormatter: "YYYY-MM-DD HH:mm:ss",
  dateFormatter: "YYYY-MM-DD",
  childListLimitToast: "Displays the first 500 dimension values, you can search for unshown values",
  textFilterTip: "The data that meets one of the conditions will be displayed.",
  multiSelect: "Multiple selection",
  batchAdd: "Batch input",
  cancel: "Cancel",
  days: "Day",
  weeks: "Week",
  months: "Month",
  quarters: "Quarter",
  years: "Year",
  seconds: "Second",
  minutes: "Minute",
  hours: "Hour",
  timeUnit: "Time Unit",
  timeQuickFilter: "Quick filtering",
  optionsNull: "Null",
  thisDay: "this day",
  this: "this ",
  timeRange: "Time Range",
};
