import { Str, ThemeModeType } from "@/components/Constants";
import { getLocaleText } from "@/utils/locale";
import DatarcStorage from "./storage";

export const savePersonalTheme = theme => DatarcStorage.setItem(Str.DATARC_PERSONAL_THEME, theme);

export const getPersonalTheme = () => DatarcStorage.getItem(Str.DATARC_PERSONAL_THEME);

export const getPersonalThemeValue = themeMode => {
  if (!themeMode) return null;
  return themeMode === ThemeModeType.FOLLOW_SYSTEM
    ? window.matchMedia("(prefers-color-scheme: dark)").matches
      ? ThemeModeType.DARK
      : ThemeModeType.LIGHT
    : themeMode;
};

export const themeMenus = () => [
  {
    value: ThemeModeType.LIGHT,
    name: getLocaleText("theme.light"),
  },
  {
    value: ThemeModeType.DARK,
    name: getLocaleText("theme.dark"),
  },
  {
    value: ThemeModeType.FOLLOW_SYSTEM,
    name: getLocaleText("theme.followSystem"),
  },
];
