export default class WatermarkFontWeight {
  static DEFAULT = 1;

  static BOLD = 2;

  static getText = v => {
    if (v === this.BOLD) return "加粗";
    return "常规";
  };

  static getOptions = () =>
    [this.DEFAULT, this.BOLD].map(v => ({
      label: this.getText(v),
      value: v,
    }));
}
