export default {
  cancel: "取消",
  save: "保存",
  confirm: "确认",
  saveFormulaOkText: "试算并保存",
  add: "添加",
  edit: "编辑",
  addSuccessToast: "添加成功",
  dimension: "维度",
  editSuccessToast: "编辑成功",
  validateFailToast: "有数据填写有误，请检查修改。",
  contentKeywordDivider: "维度值相关",
  drillDimensionDivider: "下钻维度关系",
  parentDimensionLabel: "父维度",
  parentDimensionHelp: "展示下钻维度关系，可以在下钻维度关系中配置完整的关系链路",
  none: "无",
  subDimensionsLabel: "子维度",
  subDimensionsNeedValidValuesErrorText: "子维度需要设置有效值",
  keywordFiltersNeedValidValuesErrorText: "可搜索维度值数量限制打开后，需要至少添加一个条件",
  measure: "指标",
  derivativeMeasure: "衍生指标",
  formatType: "展示格式",
  formula: "计算公式",
  formulaExtra: "支持已有模型的指标间加减乘除的基本运算",
  formulaPlaceholder: "请点击右侧编辑按钮创建或修改公式",
  editFormulaContentTitle: "编辑公式",
  formulaTextareaPlaceholder: "请点击左侧指标后，添加运算符和数字，组成公式",
  formulaSearchInputPlaceholder: "请输入关键词",
  formulaTip1: "支持公式中同时含有指标和累计指标",
  formulaTip2: "支持公式中含有不同模型中的指标，但不能超过3个模型",
  formulaTip3: "支持包含 + - * / () 的简单四则运算",
  formulaTip4: "支持运算中带有数字（含浮点数）",
  formulaTip5: "eg1. 店铺成本 + 进货成本",
  formulaTip6: "eg2. 营业额 / (0.9*目标营业额)",
  loadMore: "点击加载更多",
  timeDimension: "时间维度",
  otherSettingsDivider: "其他设置",
  timeAggregationMethodLabel: "时间聚合方式",
  checkedItemNoContentErrorText: "存在勾选的项没有填入内容，请填入",
  editTimeDimension: "编辑时间维度",
  addTimeDimension: "添加时间维度",
  userNameLabel: "用户名称",
  userNameRequiredMessage: "请输入用户名称",
  userNameInputPlaceholder: "请输入用户名称",
  saveSuccessToast: "保存成功",
  passwordLabel: "密码",
  modify: "修改",
  timeDataIntelligentAnalysisLabel: "时间数据智能分析",
  autoDisplayDimensionYoyAnalysisLabel: "自动展示维度同比分析",
  autoDisplayDimensionRingAnalysisLabel: "自动展示维度环比分析",
  insightsTypeSwitch: "智能解析类型开关",
  followWorkspaceSettings: "跟随工作区设置",
  alwaysOpen: "始终打开",
  alwaysClose: "始终关闭",
  smartTip: "智能提示",
  differentTipBoxOfSearchTip: "根据您的搜索行为切换不同的提示框",
  defaultFieldTip: "默认字段提示",
  defaultQuestionTip: "默认问题提示",
  switchToQuestionTipManuallyTip: "任何情况对焦搜索框都给出字段提示，可手动切换到问题提示",
  switchToFieldTipManuallyTip: "任何情况对焦搜索框都给出问题提示，可手动切换到字段提示",
  saveConfigBtnText: "保存配置",
  unitLabel: "单位",
  unitNameInputPlaceholder: "请输入单位名称",
  currencyUnitSelectPlaceholder: "请选择货币单位",
  currencyLabel: "货币",
  customLabel: "自定义",
  lastSearch: "最终搜索",
  replaceWith: "替换为",
  formerNoViewDimensionAuthText: "暂无查看维度",
  latterNoViewAuthText: "的权限。",
  formerNoViewMeasureAuthText: "暂无查看指标",
  missMeasure: "缺少指标",
  missDimensionOrTimeDimension: "缺少时间范围",
  timeRange: "时间范围",
  timeDimensionCombination: "时间维度组合",
  invalid: "无效。",
  period: "。",
  resultPunctuation1: "，",
  resultPunctuation2: "，",
  supply: "补充",
  tryTip: "你可以试试",
  viewDetails: "查看详情",
  searchTips: "条搜索提示",
  manualInputLabel: "手动输入",
  selectPlaceholder: "查找或创建关键词",
  invalidSelectPlaceholder: "已失效，请重选或新建",
  noMoreSubDimensions: "暂无更细分维度",
  exploreBtnText: "探索",
  thisPeriod: "本期",
  create: "新增",
  formerNegativeRelatedText: "与",
  latterNegativeRelatedText: "负相关",
  applyBtnText: "应用",
  dimensionTextAreaPlaceholder: "请换行隔开不同关键字",
  configureOptionsModalTitle: "配置选填项",
  passwordRequiredMessage: "请输入密码",
  passwordCharLimitMessage: "密码中必须包含大小写字母和特殊字符",
  passwordNoSpaceRuleMessage: "密码中不能包含空格",
  checkMinLen: "密码不能小于8位",
  checkMaxLen: "密码不能大于32位",
  dataLoadingText: "正在取数",
  customOtherLine: "其他设置",
  defaultTimeFunc: "时间聚合方式",
  defaultTimeFuncErr: "存在勾选的项没有填入内容，请填入",
};
