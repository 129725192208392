export default {
  correct: "Correct",
  normal: "Normal",
  error: "Error",
  item: "Item",
  status: "Status",
  systemTime: "System Time",
  lastDebounceTaskExecutorBeat: "Last Debounce Task Start Time",
  databaseMaxConnections: "Database Max Connections",
  objectStorageService: "Object Storage Service",
  wordSegmentationService: "Word Segmentation Service",
  dataWarehouseService: "Data Warehouse Service",
  dwzService: "Short URL Service",
  AutomaticSpeechRecognitionService: "Speech Service",
  debounceTaskWarningText:
    "Recent start time of debounce task is more than 1 minute behind the current time. Heartbeat service or task service may be experiencing issues.",
  dispatchTaskErrorText: "Dispatch service is not started",
  databaseConnectionsWarningText:
    "The number of connections is set too low, which may cause service errors. It is recommended to set it to 1000.",
  serviceUnavailable: "Service Unavailable",
  noConfig: "Not Configured",
  systemTimeErrorTextFormer: "Deviation between the browser and the server's system time",
  systemTimeErrorTextLatter: "may cause abnormalities in the functionality and records of scheduled tasks",
  hour: "hour(s)",
  minute: "minute(s)",
  second: "second(s)",
  inputIP: "Current Access IP",
  outputIP: "Service Egress IP",
  supportedHTTPMethods: "Supported HTTP Methods",
  reset: "Reset",
  resetting: "Resetting",
  checking: "Checking",
  configErrorText: "Configuration error. The object storage service cannot be connected in the current network environment.",
  smsService: "SMS Service",
  workerQueueLength: "Message Queue Length",
  queryQueueLength: "Query Queue Length",
  queueBusy: "Queue Busy",
  queueBlock: "Queue Block",
  otherService: "Other Services",
};
