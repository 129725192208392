import React from "react";

const LeftOutlined = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 15.75C11.8082 15.75 11.6162 15.6765 11.4699 15.5303L5.46994 9.53025C5.17669 9.237 5.17669 8.763 5.46994 8.46975L11.4699 2.46975C11.7632 2.1765 12.2372 2.1765 12.5304 2.46975C12.8237 2.763 12.8237 3.237 12.5304 3.53025L7.06069 9L12.5304 14.4698C12.8237 14.763 12.8237 15.237 12.5304 15.5303C12.3842 15.6765 12.1922 15.75 12.0002 15.75"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default LeftOutlined;
