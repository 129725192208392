import React from "react";

const ShareBtn = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.65779 4.39425V2.59465C8.65751 2.35688 8.79447 2.14186 9.00589 2.04812C9.21731 1.95438 9.46245 2 9.62897 2.16405L13.8211 6.27998C13.9353 6.39211 14 6.54777 14 6.71058C14 6.87338 13.9353 7.02905 13.8211 7.14118L9.62897 11.2571C9.46245 11.4212 9.21731 11.4668 9.00589 11.373C8.79447 11.2793 8.65751 11.0643 8.65779 10.8265V9.0269C5.41669 9.0269 3.65247 11.3165 2.54624 13.8318C2.48702 13.959 2.34936 14.0255 2.21696 13.9909C2.08455 13.9563 1.99398 13.8302 2.00031 13.6892C2.33936 9.01502 4.17829 5.06242 8.65779 4.39425Z"
      fill="currentColor"
    />
  </svg>
);
export default ShareBtn;
