import React from "react";

const FieldFilter = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.45837 2.33331C1.45837 1.85006 1.85012 1.45831 2.33337 1.45831L11.6667 1.45831C12.15 1.45831 12.5417 1.85006 12.5417 2.33331V4.21487C12.5417 4.51403 12.3889 4.79246 12.1365 4.95307L9.33337 6.73687V12.25L8.16671 12.25V6.57676C8.16671 6.27759 8.31955 5.99917 8.57194 5.83855L11.375 4.05476V2.62498L2.62504 2.62498V4.05476L5.42814 5.83855C5.68053 5.99917 5.83337 6.27759 5.83337 6.57676V10.7916H4.66671L4.66671 6.73687L1.86361 4.95307C1.61122 4.79246 1.45837 4.51403 1.45837 4.21487L1.45837 2.33331Z"
      fill="currentColor"
    />
  </svg>
);

export default FieldFilter;
