export default {
  renameConfirmFormerText: "修改后",
  renameConfirmLatterText: "名错误",
  renameConfirmContent1: "修改后在数据库中仍然匹配不到对应的表，存在数据库链接失败导致此情况的可能。",
  renameConfirmContent2: "确认仍要修改",
  renameConfirmContent3: "名？",
  confirmBtnText: "确认修改",
  cancelBtnText: "取消",
  editModalTitlePrefix: "修改",
  editModalTitleSuffix: "名",
  oldName: "原表名",
  newName: "修改后表名",
  customizeLabel: "自定义",
  customizeOptionLabel: "手动输入",
  selectOptionLabel: "选取",
  customizeInputPlaceholder: "请输入",
  selectPlaceholder: "请选择",
  searchPlaceholder: "请输入想搜索的表名",
  notFoundContent: "未找到对应数据表，请尝试其他关键词。",
};
