import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.BatchDownLoadAccountError.${id}`);

export default class BatchDownLoadAccountError {
  static FETCH_ACCOUNTS_FAILED = 1;

  static GENERATE_FILE_FAILED = 2;

  static UPLOAD_FILE_FAILED = 3;

  static getText = status => {
    if (status === this.FETCH_ACCOUNTS_FAILED) return getText("error1");
    if (status === this.GENERATE_FILE_FAILED) return getText("error2");
    if (status === this.UPLOAD_FILE_FAILED) return getText("error3");
    return "";
  };
}
