import React from "react";

const MobileFilter = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.23508 3.64532C1.68715 2.995 2.14944 2.00098 2.99982 2.00098H13.0073C13.8752 2.00098 14.3311 3.03076 13.7476 3.67326L9.84265 7.97333C9.67553 8.15736 9.58294 8.39703 9.58294 8.64561V12.9926C9.58294 13.7456 8.78219 14.2284 8.11624 13.877L6.9579 13.2657C6.62988 13.0926 6.42461 12.7522 6.42461 12.3813V8.98276C6.42461 8.74696 6.34128 8.51875 6.18935 8.33842L2.23508 3.64532Z"
      fill="currentColor"
    />
  </svg>
);

export default MobileFilter;
