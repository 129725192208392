import React from "react";

const MobileChecked = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60639 11.664L2.47108 7.52871C2.21073 7.26836 1.78862 7.26836 1.52827 7.52871C1.26792 7.78905 1.26792 8.21116 1.52827 8.47151L6.19494 13.1382C6.47755 13.4208 6.94362 13.3929 7.19055 13.0787L14.5239 3.74532C14.7514 3.45581 14.7011 3.03671 14.4116 2.80923C14.122 2.58176 13.7029 2.63205 13.4755 2.92156L6.60639 11.664Z"
        fill="#4759FD"
      />
    </svg>
  );
};

export default MobileChecked;
