export default {
  loginSloganText: "企业数据搜索平台",
  loginFormTitle: "企业用户登录",
  userNameInputPlaceholder: "登录名/邮箱/手机号",
  usernameOrEmailOrPhoneRequiredMessage: "请输入登录名、邮箱或手机号",
  passwordInputPlaceholder: "请输入密码",
  rememberAccountCheckboxText: "记住账号",
  loginBtnText: "登 录",
  verificationCodeLogin: "验证码登录",
  phoneInputPlaceholder: "手机号",
  EmailInputPlaceholder: "邮箱",
  usernameRequiredMessage: "必填",
  phoneRequiredMessage: "请输入手机号或者邮箱",
  getVerificationCodeBtnText: "获取验证码",
  resendBtnText: "秒后重新发送",
  verificationCodeInputPlaceholder: "数字验证码",
  verificationCodeRequiredMessage: "请输入收到的验证码",
  passwordLogin: "密码登录",
  exceedConfirmTitle: "业务用户数超额",
  exceedConfirmContent: "系统无法使用，请联系系统管理员前往后台管理用户数量。",
  confirm: "确定",
  forgetPassword: "忘记密码？",
  moreLoginOptions: "更多登录方式",
  sendCoderSuccessToast: "验证码已发送",
  loginTitle: "登录",
};
