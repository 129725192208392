export default {
  1005: "创建表失败",
  1006: "创建数据库失败",
  1007: "数据库已存在:创建数据库失败",
  1008: "数据库不存在，删除数据库失败",
  1009: "不能删除数据库文件导致删除数据库失败",
  1010: "不能删除数据目录导致删除数据库失败",
  1011: "删除数据库文件失败",
  1012: "不能读取系统表中的记录",
  1020: "记录已被其他用户修改",
  1021: "硬盘剩余空间不足，请加大硬盘可用空间",
  1022: "关键字重复，更改记录失败",
  1023: "关闭时发生错误",
  1024: "读文件错误",
  1025: "更改名字时发生错误",
  1026: "写文件错误",
  1032: "记录不存在",
  1036: "数据表是只读的，不能对它进行修改",
  1037: "系统内存不足，请重启数据库或重启服务器",
  1038: "用于排序的内存不足，请增大排序缓冲区",
  1040: "已到达数据库的最大连接数，请加大数据库可用连接数",
  1041: "系统内存不足",
  1042: "无效的主机名",
  1043: "无效连接",
  1044: "当前用户没有访问数据库的权限",
  1045: "不能连接数据库，用户名或密码错误",
  1048: "字段不能为空",
  1049: "数据库不存在",
  1050: "数据表已存在",
  1051: "数据表不存在",
  1054: "字段不存在",
  1065: "无效的SQL语句，SQL语句为空",
  1081: "不能建立Socket连接",
  1114: "数据表已满，不能容纳任何记录",
  1116: "打开的数据表太多",
  1129: "数据库出现异常，请重启数据库",
  1130: "连接数据库失败，没有连接数据库的权限",
  1133: "数据库用户不存在",
  1141: "当前用户无权访问数据库",
  1142: "当前用户无权访问数据表",
  1143: "当前用户无权访问数据表中的字段",
  1146: "数据表不存在",
  1147: "未定义用户对数据表的访问权限",
  1149: "SQL语句语法错误",
  1158: "网络错误，出现读错误，请检查网络连接状况",
  1159: "网络错误，读超时，请检查网络连接状况",
  1160: "网络错误，出现写错误，请检查网络连接状况",
  1161: "网络错误，写超时，请检查网络连接状况",
  1162: "字段值重复，入库失败",
  1169: "字段值重复，更新记录失败",
  1177: "打开数据表失败",
  1180: "提交事务失败",
  1181: "回滚事务失败",
  1203: "当前用户和数据库建立的连接已到达数据库的最大连接数，请增大可用的数据库连接数或重启数据库",
  1205: "加锁超时",
  1211: "当前用户没有创建用户的权限",
  1216: "外键约束检查失败，更新子表记录失败",
  1217: "外键约束检查失败，删除或修改主表记录失败",
  1226: "当前用户使用的资源已超过所允许的资源，请重启数据库或重启服务器",
  1227: "权限不足，您无权进行此操作",
  2013: "无法连接服务器，可能存在网络超时或端口占用等问题",
};
