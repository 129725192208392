import { Card as AntdCard } from "antd";
import { CardProps as AntdCardProps } from "antd/es/card";
import React from "react";

import "./index.module.scss";

interface CardProps extends AntdCardProps {
  noDivider?: boolean;
}

const Card: React.FC<CardProps> & {
  Grid: typeof AntdCard.Grid;
  Meta: typeof AntdCard.Meta;
} = ({ className, noDivider, ...rest }) => {
  let cardClassName = `cardPrefixA cardPrefixB card`;
  if (className) cardClassName += ` ${className}`;
  if (noDivider) cardClassName += ` noDivider`;

  return <AntdCard className={cardClassName} {...rest} />;
};

Card.Grid = AntdCard.Grid;
Card.Meta = AntdCard.Meta;

export default Card;
