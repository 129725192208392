import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { AddInType } from "@/components/Constants";
import { getAddInType, getScreenType } from "@/utils/common";

const GlobalContext = React.createContext({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  addInType: AddInType.DEFAULT,
  isLargeText: false,
  setIsLargeText: _.noop,
  setRefreshKey: _.noop,
  openNavMenu: false,
  setOpenNavMenu: _.noop,
});

export default GlobalContext;

export const GlobalContextProvider = ({ children }) => {
  const { isMobile: initIsMobile, isTable: initIsTablet, isDesktop: initIsDesktop } = getScreenType();
  const initAddInType = getAddInType();

  const [isMobile, setIsMobile] = useState(initIsMobile);
  const [isTablet, setIsTablet] = useState(initIsTablet);
  const [isDesktop, setIsDesktop] = useState(initIsDesktop);
  const [isLargeText, setIsLargeText] = useState(null);
  const [addInType, setAddInType] = useState(initAddInType);
  const [openNavMenu, setOpenNavMenu] = useState(false); //  打开选择工作区的 菜单

  // 搜索强制刷新，在搜索切换工作区时强制刷新
  const [refreshKey, setRefreshKey] = useState();

  const resize = useCallback(
    _.debounce(() => {
      const { isMobile, isTable, isDesktop } = getScreenType();
      setIsMobile(isMobile);
      setIsTablet(isTable);
      setIsDesktop(isDesktop);
    }, 150),
    []
  );

  // 在 Office.js 加载完毕后，更新加载项类型（在 Office 以外的环境执行，host 为 null，不作更新）
  const updateAddInType = () => {
    const office = window.Office;
    if (_.isEmpty(office)) return;
    office.onReady().then(info => {
      if (info.host === office.HostType.Word) {
        setAddInType(AddInType.OFFICE_WORD);
      }
    });
  };
  useEffect(updateAddInType, []);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window && window.removeEventListener("resize", resize);
      resize.cancel();
    };
  }, []);

  const values = useCallback(
    {
      isMobile,
      isTablet,
      isDesktop,
      addInType,
      refreshKey,
      isLargeText,
      openNavMenu,
      setRefreshKey,
      setIsLargeText,
      setOpenNavMenu,
    },
    [isMobile, isTablet, isDesktop, addInType, refreshKey, isLargeText, openNavMenu]
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
  );
};
