export default {
  errorToastText: "导入 DEMO 工作区失败，如需重新导入请联系运维",
  title: "许可证已成功激活",
  titleWithDemo: "许可证已成功激活，自动导入 DEMO 工作区",
  stepTitle1: "创建 DEMO 负责人",
  stepDesc1: "数据专家账号：",
  stepTitle2: "导入 DEMO 数据",
  stepDesc2: "导入部署包中的 demo 数据",
  stepTitle3: "初始化 DEMO 工作区",
  stepDesc3: "自动配置数据源、模型等",
  stepTitle4: "初始化 DEMO 工作区报告",
  stepDesc4: "导入部署包中的报告样本",
  skipButtonText: "前往初始化页",
};
