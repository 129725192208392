import React from "react";

const Broom = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0482 12.5895L12.2201 7.81445H12.4998C12.7248 7.81445 12.906 7.6332 12.906 7.4082V4.4082C12.906 4.1832 12.7248 4.00195 12.4998 4.00195H8.65601V1.1582C8.65601 0.933203 8.47476 0.751953 8.24976 0.751953H5.74976C5.52476 0.751953 5.34351 0.933203 5.34351 1.1582V4.00195H1.49976C1.27476 4.00195 1.09351 4.1832 1.09351 4.4082V7.4082C1.09351 7.6332 1.27476 7.81445 1.49976 7.81445H1.77944L0.951319 12.5895C0.941171 12.6478 0.943907 12.7076 0.959337 12.7647C0.974767 12.8218 1.00251 12.8749 1.04063 12.9202C1.07875 12.9654 1.12632 13.0018 1.17999 13.0267C1.23366 13.0517 1.29214 13.0645 1.35132 13.0645H12.6482C12.6716 13.0645 12.6951 13.0629 12.7169 13.0582C12.7696 13.0494 12.8199 13.0302 12.8651 13.0018C12.9103 12.9734 12.9494 12.9364 12.9802 12.8928C13.0109 12.8492 13.0328 12.8 13.0445 12.7479C13.0562 12.6959 13.0574 12.642 13.0482 12.5895V12.5895ZM2.18726 5.0957H6.43726V1.8457H7.56226V5.0957H11.8123V6.7207H2.18726V5.0957ZM9.49976 11.9707V9.5332C9.49976 9.46445 9.44351 9.4082 9.37476 9.4082H8.62476C8.55601 9.4082 8.49976 9.46445 8.49976 9.5332V11.9707H5.49976V9.5332C5.49976 9.46445 5.44351 9.4082 5.37476 9.4082H4.62476C4.55601 9.4082 4.49976 9.46445 4.49976 9.5332V11.9707H2.16851L2.87319 7.9082H11.1248L11.8294 11.9707H9.49976Z"
      fill="black"
      fillOpacity="0.45"
      stroke="currentColor"
    />
  </svg>
);

export default Broom;
