import React from "react";

const HackingToTheGate = React.lazy(() => import("@/pages/HackingToTheGate"));
const Discover = React.lazy(() => import("@/pages/Discover"));
const Attribution = React.lazy(() => import("@/pages/Attribution"));
const AutoInsights = React.lazy(() => import("@/pages/AutoInsights"));
const Index = React.lazy(() => import("@/pages/Index"));
const Chat = React.lazy(() => import("@/pages/Chat"));
const ForgetPassword = React.lazy(() => import("@/pages/ForgetPassword"));
const Launch = React.lazy(() => import("@/pages/Launch"));
const Login = React.lazy(() => import("@/pages/Login"));
const Logout = React.lazy(() => import("@/pages/Logout"));
const My = React.lazy(() => import("@/pages/My"));
const Message = React.lazy(() => import("@/pages/Message"));
const Pinboard = React.lazy(() => import("@/pages/Pinboard"));
const UILib = React.lazy(() => import("@/pages/UILib"));
const Share = React.lazy(() => import("@/pages/Share"));
const Workspaces = React.lazy(() => import("@/pages/Workspaces"));
const Settings = React.lazy(() => import("@/pages/Settings"));
const Language = React.lazy(() => import("@/pages/Language"));
const Appearance = React.lazy(() => import("@/pages/Appearance"));
const WPSUpdate = React.lazy(() => import("@/pages/WPSUpdate"));
const SingleQuery = React.lazy(() => import("@/pages/SingleQuery"));
const EmergencyLogin = React.lazy(() => import("@/pages/EmergencyLogin"));

const routerConfig = [
  // 匿名权限
  {
    path: "/auth/forget-password",
    layout: "blank",
    component: ForgetPassword,
    exact: true,
    auth: 0,
  },
  {
    path: "/auth/logout",
    layout: "blank",
    component: Logout,
    exact: true,
    auth: 0,
  },
  {
    path: "/auth/login/:method?",
    layout: "blank",
    component: Login,
    exact: true,
    auth: 0,
  },
  {
    path: "/auth/emergency-login",
    layout: "blank",
    component: EmergencyLogin,
    exact: true,
    auth: 0,
  },
  {
    path: "/UI/:type?",
    layout: "blank",
    component: UILib,
    exact: true,
    auth: 0,
  },
  {
    path: "/wps-update",
    layout: "blank",
    component: WPSUpdate,
    exact: true,
    auth: 0,
  },
  // 已登录用户
  {
    path: "/launch",
    layout: "base",
    component: Launch,
    exact: true,
    auth: 1,
  },
  {
    path: "/hacking",
    layout: "base",
    component: HackingToTheGate,
    exact: true,
    auth: 1,
  },
  {
    path: "/workspaces",
    layout: "base",
    component: Workspaces,
    exact: true,
    auth: 1,
  },
  {
    path: "/language",
    layout: "base",
    component: Language,
    exact: true,
    auth: 1,
  },
  {
    path: "/Appearance",
    layout: "base",
    component: Appearance,
    exact: true,
    auth: 1,
  },
  {
    path: "/settings/:settingType?",
    layout: "base",
    component: Settings,
    exact: true,
    auth: 1,
  },
  {
    path: "/message",
    layout: "base",
    component: Message,
    exact: true,
    auth: 1,
  },
  {
    path: "/chat",
    layout: "base",
    component: Chat,
    exact: true,
    auth: 1,
  },
  {
    path: "/share/projects/:projectId/:shareType/:shareUID",
    layout: "base",
    component: Share,
    exact: true,
    auth: 1,
  },
  {
    path: "/singleQuery",
    layout: "base",
    component: SingleQuery,
    exact: true,
    auth: 2,
  },
  // 已登录且选择了工作区
  {
    path: "/my/:manageType?",
    layout: "base",
    component: My,
    exact: true,
    auth: 2,
  },
  {
    path: "/discover",
    layout: "base",
    component: Discover,
    exact: true,
    auth: 2,
  },
  {
    path: "/attribution",
    layout: "base",
    component: Attribution,
    exact: true,
    auth: 2,
  },
  {
    path: "/auto-insights",
    layout: "base",
    component: AutoInsights,
    exact: true,
    auth: 2,
  },
  {
    path: "/pinboard/:pinboardUID?",
    layout: "base",
    component: Pinboard,
    exact: true,
    auth: 2,
  },
  {
    path: "/",
    layout: "base",
    component: Index,
    exact: true,
    auth: 2,
  },
];

export default routerConfig;
