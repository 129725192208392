import copyToClipboard from "copy-text-to-clipboard";
import crypto from "crypto";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jschardet from "jschardet";
import _ from "lodash";
import queryString from "query-string";
import Auth from "@/components/Auth";
import { AddInType, CompHeight, Prefix, ProjectAccountRole, WatermarkStatus, WatermarkStyle } from "@/components/Constants";
import WatermarkFontWeight from "@/components/Constants/WatermarkFontWeight";
import WatermarkVisitorDataIndex from "@/components/Constants/WatermarkVisitorDataIndex";
import { getLocaleText, ZH_CN } from "./locale";

const getText = id => getLocaleText(`utils.${id}`);

export const arrayToObject = (arr, func = (_, index) => index) => {
  const obj = {};
  arr.forEach((item, index) => (obj[func(item, index)] = item));
  return obj;
};

export const isDimensionTable = obj => _.isObject(obj) && !!(obj.table || obj.tableUid);

export const getDimensionIdentity = obj => `${Prefix.DIMENSION}${_.isString(obj) ? obj : obj.id}`;

export const getDimensionAttrIdentity = (obj, attr) => `${getDimensionIdentity(obj)}.${_.isString(attr) ? attr : attr.aid}`;

export const getMeasureIdentity = obj => `${Prefix.MEASURE}${obj.id}`;

export const formatTransferDimensions = dimensions => {
  const nodes = [];
  dimensions?.forEach(dimension => {
    if (isDimensionTable(dimension)) {
      const attrs = dimension?.attrs || [];
      for (let i = 0; i < attrs.length; i++) {
        const attr = attrs[i];
        nodes.push({ label: getFirstName(attr), value: getDimensionAttrIdentity(dimension, attr) });
      }
    } else {
      nodes.push({ label: getFirstName(dimension), value: getDimensionIdentity(dimension) });
    }
  });
  return nodes;
};

export const getById = (array, id) => array.find(e => e.id === id);

export const isUUIDv4 = uid => _.isString(uid) && uid.length === 32;

export const isTrueArray = v => _.isArray(v) && !_.isEmpty(v);

export const isTrueObject = v => _.isObject(v) && !_.isEmpty(v);

// 钉钉使用了旧版本的 chrome (85 or lower) 内核，所以 string.replaceAll 我们就需要手动实现
export const stringReplaceAll = (str, oldStr, newStr) => _.isString(str) && str.split(oldStr).join(newStr);

// 中英文逗号都分割
export const splitComma = string => string.split("，").join(",").split(",");

export const stringToArray = v =>
  v
    .split("，")
    .join(",")
    .split(",")
    .map(v => v.trim())
    .filter(v => v);

export const getDuplicates = array =>
  array.reduce((acc, v, i, arr) => (arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc), []);

export const getProjectOwner = project => project?.owner?.uid;

export const isProjectOwner = (project, uid) => uid && getProjectOwner(project) === uid;

export const isProjectManager = project => project?.role === ProjectAccountRole.ADMIN;

export const uuidV4 = () =>
  "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16).toLowerCase();
  });

export const getTableItemSortResult = (tables, selected) => {
  const result = _.clone(tables);
  // 获得在 selected 内的 key 值
  const getKey = o => (_.isObject(o) ? o.id : o);
  result.sort((a, b) => selected.indexOf(getKey(b)) - selected.indexOf(getKey(a)));
  return result;
};

// 获得一个基于 orders 顺序进行排序的比较函数
export const getMultiPartOrderCompareFunc = orders => {
  return (a, b) => {
    if (orders[a] === undefined && b === undefined) return 0;
    if (orders[a] === undefined) return 1;
    if (orders[b] === undefined) return -1;
    // 防御性编程，下面是正常的情况，逐个比较值
    if (_.isArray(orders[a]) && _.isArray(orders[b]) && orders[a].length === orders[b].length) {
      for (let i = 0; i < orders[a].length; i++) {
        if (orders[a][i] !== orders[b][i]) return orders[a][i] - orders[b][i];
      }
    }
    // 兜底的，不做处理，返回 0
    return 0;
  };
};

/* 字符串比较函数，用于字符串排序。
 1. 同一个对称位置，是数字，取出连续数字部分，比较数值大小
 2. 同一个对称位置，不都是数字，按照字典序
 */
export const stringCmp = (a, b) => {
  const isDigit = c => c >= "0" && c <= "9";
  const getIntStr = (s, i) => {
    let j = i + 1;
    while (j < s.length && isDigit(s[j])) {
      j++;
    }
    return s.slice(i, j);
  };

  let aIndex = 0;
  let bIndex = 0;
  for (; aIndex < a.length && bIndex < b.length; ) {
    if (isDigit(a[aIndex]) && isDigit(b[bIndex])) {
      const as = getIntStr(a, aIndex);
      const bs = getIntStr(b, bIndex);
      aIndex += as.length;
      bIndex += bs.length;
      const an = parseInt(as);
      const bn = parseInt(bs);
      if (an !== bn) return an > bn ? 1 : -1;
    }
    if (a[aIndex] === b[bIndex]) {
      aIndex++;
      bIndex++;
    } else {
      return localeCompare(String(a[aIndex]), String(b[bIndex])); // 数字类型的维度数据 a[aIndex] 会得出 number 数据，localeCompare 需要 string 类型
    }
  }
  if (aIndex === a.length && bIndex === b.length) return 0;
  return aIndex === a.length ? -1 : 1;
};

export const UTF8Sig = "\uFEFF";

export const downloadFile = (fileName, content) => {
  const aTag = document.createElement("a");
  aTag.download = fileName;
  // 如果是 csv 文件，确保以 \uFEFF 开头，正确设置文件编码
  if (_.isString(fileName) && fileName.toLowerCase().endsWith(".csv") && _.isString(content) && !content.startsWith(UTF8Sig)) {
    content = `${UTF8Sig}${content}`;
  }
  const blob = new Blob([content]);
  aTag.href = URL.createObjectURL(blob);
  aTag.click();
  URL.revokeObjectURL(blob);
};

export const downloadFileFromUrl = href => {
  const elm = document.createElement("a");
  elm.href = href;
  document.body.appendChild(elm);
  elm.click();
  elm.remove();
};

export const getIncrFunc = () => {
  let value = 0;
  return () => {
    value++;
    return value;
  };
};

export const getCacheValueFunc = initValue => {
  let value = initValue;
  return v => {
    // v 有值则更新 value，始终返回 value
    if (v !== undefined) value = v;
    return value;
  };
};

export const getFilledArray = (length, defaultValue) => {
  const initDefaultValue = null;
  defaultValue = defaultValue === undefined ? initDefaultValue : defaultValue;
  const array = new Array(length);
  return array.fill(defaultValue);
};

export const tokensToStr = (tokens, sep = " ") =>
  isTrueArray(tokens)
    ? tokens
        .map(x => x.word || "")
        .join(sep)
        .trim()
    : "";

export const formatUrl = v => `${_.trim(v).replace(/\/*$/, "")}/`;

export const getFirstName = v => (_.isObject(v) && isTrueArray(v.names) ? v.names[0] : undefined);

export const unitsToStr = units =>
  isTrueArray(units)
    ? units
        .map(u => u.tokens)
        .map(token => tokensToStr(token))
        .reduce((a, b) => `${a} ${b}`)
        .trim()
    : "";

export const concatTokens = units => (isTrueArray(units) ? units.reduce((pre, cur) => pre.concat(cur.tokens), []) : []);

export const getSearchString = v => `?${queryString.stringify(v)}`;

export const parseSearchString = v => queryString.parse(v);

export const getIntHeight = height => {
  if (height === undefined) {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    height = Math.round(screenWidth * 0.618);
    height = height > CompHeight.DEFAULT ? CompHeight.DEFAULT : height;
  }
  return height;
};

export const getFormItemValidateErrorAttrForAntdForm = (validateError, name) => {
  // 获得 Form.Item 对应的校验错误的属性设置
  if (_.isEmpty(validateError[name])) return {};
  const attrs = { validateStatus: "error" }; // validateStatus  antd form 字段
  const { errors } = validateError[name];
  if (isTrueArray(errors)) attrs.help = errors[0];
  return attrs;
};

export const calcMD5 = v => crypto.createHash("md5").update(v).digest("hex").toLowerCase();

export const copyToClipboardData = (text, callback) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(text).then(
      () => _.isFunction(callback) && callback(true),
      () => _.isFunction(callback) && callback(copyToClipboard(text))
    );
    return;
  }
  callback(copyToClipboard(text));
};

export const getHeightStyle = height => (_.isNumber(height) && height > 0 ? { height: `${height}px` } : {});

export const removeWrap = str => (_.isString(str) ? str.split(/[(\r\n)\r\n]+/).join("") : "");

export const getTimeText = (time, format) => {
  if (_.isString(time) || _.isInteger(time)) return dayjs(new Date(time)).format(format || "YYYY-MM-DD HH:mm:ss");
  return "";
};

export const timestampToTimeText = (time, format) => dayjs(time).format(format || "YYYY-MM-DD HH:mm:ss");

export const getQuotaText = num => (_.isNumber(num) && num >= 0 ? num : getText("noLimit"));

// 获取屏幕类型。组件情况下，尽量使用 GlobalContext，不要使用该函数。
export const getScreenType = () => {
  const offsetWidth = document.body.offsetWidth;
  const isMobile = offsetWidth <= 720;
  const isTable = offsetWidth >= 721 && offsetWidth <= 1199;
  const isDesktop = offsetWidth >= 1200;
  return {
    offsetWidth,
    isMobile,
    isTable,
    isDesktop,
  };
};

// 获取加载项类型
export const getAddInType = () => {
  if (window.wps !== undefined) return AddInType.WPS_WORD;
  if (window.Word !== undefined) return AddInType.OFFICE_WORD;
  return AddInType.DEFAULT;
};

export const localeCompare = (a, b) => String.prototype.localeCompare.apply(a, [b, "zh-CN"]);

export const getDateAlias = str => {
  let today = dayjs();
  today = dayjs([today.year(), today.month(), today.date(), 0, 0, 0, 0]);
  const date = dayjs(str);
  let localePre = "";
  if (date.isSameOrAfter(today)) localePre = getText("today");
  else if (date.isSameOrAfter(today.subtract(1, "d"))) localePre = getText("yesterday");
  else if (date.isSameOrAfter(today.subtract(2, "d"))) localePre = getText("dayBeforeYesterday");
  return localePre;
};

export const getDate = val =>
  getDateAlias(val) ? `${getDateAlias(val)} ${dayjs(val).format("HH:mm:ss")}` : dayjs(val).format("ll HH:mm:ss");

export const getPopupContainer = triggerNode => triggerNode?.parentNode;

export const redirectToNewPath = (history, path, params) => history.push({ pathname: path, state: params });

export const redirectToSearch = (history, question) => history.push({ pathname: "/discover", state: { key: question } });

// 字符串是否在英文字母表范围内
export const isAlpha = char => /^[A-Za-z]/.test(char);

// 字符串是否是数字
export const isDigit = char => /^[0-9]/.test(char);

const getVisitorWatermarkText = content => {
  const obj = parseWatermarkContent(content);
  let text = "";
  if (isTrueArray(obj.dataIndexes)) {
    const authData = Auth.getAuthData();
    obj.dataIndexes.forEach(key => {
      if (authData?.[key] && key !== WatermarkVisitorDataIndex.CUSTOM) text += ` ${authData[key]}`;
    });
    if (obj.customInput) text += ` ${obj.customInput}`;
  }
  return text;
};

export const getWatermarkConfig = ({
  watermarkType,
  watermarkStyle,
  watermarkContent,
  watermarkFontSize,
  watermarkFontWeight,
  watermarkOpacity,
}) => {
  // 初始化的是 宽松型 的结构
  const config = {
    fontColor: getCssVariable("--datarc-color-black"),
    fontSize: watermarkFontSize || 14,
    opacity: watermarkOpacity ? watermarkOpacity / 100 : 0.2,
    fontWeight: watermarkFontWeight === WatermarkFontWeight.BOLD ? "bold" : "normal",
    gapX: 200,
    gapY: 200,
    width: 120,
    height: 60,
    rotate: -22,
    text: Auth.getNickname() || "",
    monitor: false, // 这个一定要 false !!!因为打开后存在bug。
    isBody: false,
    mode: "repeat",
  };

  if (watermarkType === WatermarkStatus.PICTURE) {
    config.text = undefined;
    config.image = watermarkContent || "";
  } else {
    if (watermarkType === WatermarkStatus.VISITOR) config.text = getVisitorWatermarkText(watermarkContent);
    if (watermarkType === WatermarkStatus.COPYRIGHT) config.text = watermarkContent || "";
    // 水印文字限制长度 1024
    config.text = config.text.slice(0, 1024);
    // 英大长安特殊逻辑，取第一个 ']'  之前的字符。因为他们介绍里面放了很多东西（身份证，部门等）但是只需要显示到部门
    if (Auth.getClientID() === "yingda") {
      const idx = config.text.indexOf("]");
      if (idx !== -1) config.text = config.text.slice(0, idx + 1);
    }
  }

  // 如果是密集型 调整水印之间的 水平间距和垂直间距
  if (watermarkStyle === WatermarkStyle.INTENSIVE) config.mode = "interval";

  return config;
};

// 使用 html2canvas 时，会为隐藏的元素添加 data-hidden="printHide" 的属性，用于 PNG 下载和 Word 添加图表
export const PRINT_HIDE = "printHide";

export const getChartCanvas = (canvasRef, options = {}) =>
  html2canvas(canvasRef.current, { ...options, ignoreElements: element => element.getAttribute("data-hidden") === PRINT_HIDE });

// 高亮处理，忽略大小写
export const highlightWord = (word = "", q = "") => {
  word = _.escape(word);
  if (!q) return word;
  q = _.escape(q);
  q = _.escapeRegExp(q);
  const reg = new RegExp(`(${q})`, "gi");
  return word.replace(reg, '<span style="color: #4759FD">$1</span>');
};

export const getPopupContainerDefaultFunc = triggerNode => triggerNode.parentNode;

export const getCssVariable = name => getComputedStyle(document.body).getPropertyValue(name);

export const setRefDomStyleProperty = (ref, key, value) => ref?.current?.style?.setProperty(key, value);

// 下拉
export const isPullDown = (startY, endY) => endY - startY > 30;

// 上划
export const isPullUp = (startY, endY) => startY - endY > 30;

// hex string to rgb array #2c2c2c => [44, 44, 44]
export const getHexToRgb = hex => {
  if (!hex) return [0, 0, 0];
  // remove space
  hex = hex.replace(/\s/g, "");
  // remove # if exist
  hex = hex[0] === "#" ? hex.slice(1) : hex;
  // if hex is 3 length, convert to 6 length
  if (hex.length === 3) hex = hex.replace(/(.)/g, "$1$1");

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return [r, g, b];
};

//  hex to rgba #2c2c2c => rgba(44, 44, 44, opacity)
export const getHexToRgbaByOpacity = (hex, opacity) => {
  const rgb = getHexToRgb(hex);
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
};

// 首页根据是否有 Modal, Drawer 显示来调整 Header 的右侧 padding，避免滚动条消失之后的闪动效果
export const setHomeHeaderRightPadding = (visible, history) => {
  if (history.location.pathname !== "/") return;
  const hasScroll = document.getElementsByTagName("main")[0].clientHeight > document.body.clientHeight;
  document.getElementsByTagName("header")[0].style.paddingRight = visible && hasScroll ? "49px" : "32px";
};

// 获取表盘当前值的最大值范围，最大值以 (10**n) 或 (10**n)*5 基础上取最接近当前值的数，如当前值是 16，取最大值 50，当前是 511，取最大值 1000
export const getMaxRange = value => {
  const absValue = Math.abs(value);
  // 获取整数位数
  const valueLength = Math.ceil(Math.log10(absValue));
  const power = 10 ** valueLength;
  const limitValue = power / 2 > absValue ? power / 2 : power;
  return value < 0 ? -limitValue : limitValue;
};

export const getSpliceText = (data, boundSymbol) =>
  isTrueArray(data) ? data.reduce((prev, v, i) => `${prev}${v}${i === data.length - 1 ? "" : boundSymbol}`, "") : "";

export const getSeparatorByLocale = () => (window.locale === ZH_CN ? "" : " ");

export const generateElement = (tag = "div", className = "") => {
  const dom = document.createElement(tag);
  dom.className = className;
  return dom;
};

export const getTextByTokens = (tokens, separator = getSeparatorByLocale()) => {
  if (typeof tokens === "string") return tokens;
  if (!isTrueArray(tokens)) return "";
  return tokens.reduce((prev, token, i) => {
    // 第一项的前面、token 中存在空格时，不再添加分割空格
    if (i === 0 || (separator === " " && (token.word === " " || prev[prev.length - 1] === " "))) {
      return `${prev}${typeof token === "string" ? token : token.word}`;
    }
    return `${prev}${separator}${typeof token === "string" ? token : token.word}`;
  }, "");
};

// 根据表单校验规则，判断是否禁用确定按钮
// 在 Form 的 onValuesChange 里用form.validateFields().then判断校验结果时结果不对，所以还判断了errorInfo
export const checkFormOkDisable = ({ form, setOkDisabled }) => {
  form
    .validateFields()
    .then(() => setOkDisabled(false))
    .catch(errorInfo => setOkDisabled(isTrueArray(errorInfo?.errorFields)));
};

export const getParentNodeByClassName = (node, className) => {
  if (!node) return null;
  const { parentNode } = node;
  if (!parentNode) return node;
  if (parentNode.className?.includes(className)) return parentNode;
  return getParentNodeByClassName(parentNode, className);
};

// 获取编辑后的字符串（若无编辑则置空），用于图表标题的保存，保存的字符串为空时使用后端返回的卡片标题
export const getEditedString = (currentStr, originalStr) => (currentStr === originalStr ? "" : currentStr);

const getReleaseFunc = () => {
  let release = null;
  return function () {
    if (release !== null) return release;
    // 如果不隐藏版本号，才设置 release 状态
    if (!window.hiddenRelease && _.isString(window.release)) {
      let r = window.release;
      // 如果隐藏 shumiao 定制化版本的后缀，处理一下
      if (window.hiddenShumiaoSuffix) r = r.split(".").slice(0, 6).join(".");
      release = r;
    } else release = "";
    return release;
  };
};

export const getRelease = getReleaseFunc();

export const detectFileEncodingPromise = file => {
  return new Promise((resolve, reject) => {
    // 猜编码
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const array = new Uint8Array(reader.result);
      let string = "";
      for (let i = 0; i < array.length; ++i) {
        string += String.fromCharCode(array[i]);
      }

      // 可以正确检测出来编码
      // windows （ansi，utf16，utf16be，utf16bom）
      const encoding = jschardet.detect(string)?.encoding;
      resolve(encoding);
    });
    reader.addEventListener("error", reject);
    reader.readAsArrayBuffer(file);
  });
};

export const updateDomStyle = (dom, property, value) => {
  if (!dom || !property) return;
  dom.style?.setProperty(property, value);
};

export const getRandomFileName = filename => {
  const uid = uuidV4();
  if (!filename.includes(".")) return uid;
  return `${uid}.${filename.slice(filename.lastIndexOf(".") + 1)}`;
};

export const isWindowTop = () => {
  // 检测当前页面是不是在顶部
  try {
    return window.self === window.top;
  } catch {
    return false;
  }
};

export const isHeadless = location => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get("headless") === "1";
};

// 比对不同 sql 是否存在相同 token
export const checkMultipleArrEquality = arrays => {
  if (arrays.length < 2) return true; // 不足两个数组，直接返回true

  const referenceArray = arrays[0]; // 以第一个数组作为参照
  for (let i = 1; i < arrays.length; i++) {
    const currentArray = arrays[i];
    if (referenceArray.length !== currentArray.length) {
      return false; // 数组长度不同，直接返回false
    }
    for (let j = 0; j < referenceArray.length; j++) {
      if (referenceArray[j].length !== currentArray[j].length) {
        return false; // 子数组长度不同，直接返回false
      }
      for (let k = 0; k < referenceArray[j].length; k++) {
        if (referenceArray[j][k].prop !== currentArray[j][k].prop) {
          return false; // 对应项的属性不同，返回false
        }
      }
    }
  }
  return true; // 所有数组都相同，返回true
};

// 移除字符串两端的单引号或双引号 (eg:""value"" , "`value`", "'value'")
export const removeQuotes = str => {
  // 判断字符串两端是否都有单引号或双引号
  if (/^['"`].*['"`]$/.test(str)) {
    // 去除两端的单引号或双引号
    return str.slice(1, -1);
  }
  return str;
};

export const SPLIT = "U+200AU+200BU+200C";

export const parseWatermarkContent = content => {
  try {
    return JSON.parse(content);
  } catch (e) {
    return {};
  }
};
