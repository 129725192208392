import React from "react";

const EyeClose = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.18162 0.939176L5.16434 3.92138C5.38041 3.71261 5.67513 3.58409 6 3.58409C6.66274 3.58409 7.2 4.11897 7.2 4.77878C7.2 5.10427 7.06925 5.39937 6.85719 5.61484L9.81838 8.57593C10.0527 8.81024 10.0527 9.19014 9.81838 9.42446C9.58406 9.65877 9.20416 9.65877 8.96985 9.42446L8.42986 8.88506C7.65892 9.33151 6.84908 9.55755 6 9.55755C3.84864 9.55755 1.94916 8.10637 0.296347 5.29567L0.169694 5.07679L0 4.77878L0.169694 4.48077C0.674538 3.59419 1.20274 2.8362 1.75445 2.20945L1.3331 1.7877C1.09878 1.55339 1.09878 1.17349 1.3331 0.939176C1.56741 0.704861 1.94731 0.704861 2.18162 0.939176ZM1.52742 4.54142L1.38318 4.77879L1.508 4.98475C2.92509 7.28308 4.42448 8.36287 5.99999 8.36287C6.52368 8.36287 7.03876 8.24376 7.54498 8.00061L5.27781 5.733C5.18714 5.66487 5.10649 5.58424 5.03844 5.49366L2.60248 3.05642C2.23908 3.47724 1.8807 3.97153 1.52742 4.54142ZM5.99999 0C8.15136 0 10.0508 1.45118 11.7036 4.26188L11.8303 4.48077L12 4.77877L11.7067 5.29039C11.2455 6.07545 10.765 6.75458 10.2651 7.32578L9.41791 6.47851C9.82406 6.00483 10.2238 5.43925 10.6168 4.77885C9.16306 2.33896 7.62186 1.19469 5.99999 1.19469C5.48622 1.19469 4.98055 1.30952 4.48318 1.54398L3.59662 0.657252C4.35961 0.220898 5.16063 0 5.99999 0Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeClose;
