export default {
  /* 顶部设置规则名称和按钮的部分 */
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  ruleNameInputPlaceholder: "请输入规则名称",
  goBackConfirmTitle: "是否离开规则配置页面？",
  goBackConfirmTip: "当前配置将不会被保存。",
  saveRuleBtnText: "保存规则",
  notConnectedErrMsg: "连接未完成",
  notConnectedTip: "有未完成的连接，请连接后再保存",
  incompleteErrMsg: "配置未完成",
  incompleteTip: "有未设置的条件或操作",
  deleteRuleConfirmTitle: "确认删除此规则？",
  deleteRuleBtnText: "删除规则",
  /* 提示菜单栏 */
  menuTitle: "规则运行的逻辑",
  preRuleStartingPoint: "输入问题",
  ruleStartingPoint: "解析后的问题",
  conditionTip: "判断条件（非必须，可串联）",
  addConditionBtnText: "添加条件",
  actionTip: "执行操作并输出到问题",
  addActionTip: "添加操作",
  tips: "注意事项",
  tipOne: "1. 操作间并行执行，操作内顺序执行",
  tipTwo: "2. 可以不设条件直接连接问题和操作",
  tipThree: "3. 条件可与条件串联，多层判断",
  tipFour: "4. 执行操作后可能输出多个问题，这些问题都将影响最后的搜索结果",
};
