import React, { ReactNode } from "react";
import { getLocaleText } from "@/components/datarc/utils/locale";
import { CryFace, SadFace, SmileFace, WinkFace } from "@/components/SVGIcons";
import Button, { ButtonProps } from "../Button";

import styles from "./index.module.scss";

const IconTypes = ["smile", "sad", "wink", "cry"];
export type IconType = typeof IconTypes[number];

const icons = {
  [IconTypes[0]]: SmileFace,
  [IconTypes[1]]: SadFace,
  [IconTypes[2]]: WinkFace,
  [IconTypes[3]]: CryFace,
};

export interface ResultProps {
  className?: string;
  title?: ReactNode;
  titleClassName?: string;
  titleStyle?: React.CSSProperties;
  desc?: ReactNode;
  descClassName?: string;
  descStyle?: React.CSSProperties;
  icon?: ReactNode;
  iconClassName?: string;
  iconSize?: number;
  iconStyle?: React.CSSProperties;
  iconType?: IconType;
  buttons?: ReactNode | null;
  okText?: string;
  onOk?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  hasCancel?: boolean;
  cancelText?: string;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  style?: React.CSSProperties;
}

const Result: React.FC<ResultProps> = ({
  title,
  titleClassName,
  titleStyle = {},
  desc,
  descClassName,
  descStyle = {},
  icon,
  iconClassName,
  iconSize,
  iconStyle = {},
  iconType = IconTypes[0],
  buttons,
  hasCancel = true,
  okText = getLocaleText("resultOkText"),
  cancelText = getLocaleText("resultCancelText"),
  onOk,
  onCancel,
  className,
  okButtonProps,
  cancelButtonProps,
  style = {},
}) => {
  // icon 处理，如果没有传 icon，则根据 iconType 使用内置的 icon
  let resultIcon = null;
  if (icon) resultIcon = icon;
  else {
    const Icon = icons[iconType];
    resultIcon = <Icon fontSize={iconSize} />;
  }

  // button 处理，默认只展示右侧 ok btn, 可以自定义 btn 的文本，如果传了 buttons，则不使用默认的 button
  let resultButtons = null;
  if (buttons) resultButtons = buttons;
  else {
    const okProps: ButtonProps = okButtonProps || {};
    if (onOk) okProps.onClick = onOk;
    const cancelProps: ButtonProps = cancelButtonProps || {};
    if (onCancel) cancelProps.onClick = onCancel;

    resultButtons = (
      <>
        {hasCancel && <Button {...cancelProps}>{cancelText}</Button>}
        <Button type="primary" {...okProps}>
          {okText}
        </Button>
      </>
    );
  }

  return (
    <div style={style} className={`${styles.main} ${className || ""}`}>
      <div style={iconStyle} className={`${styles.icon} ${iconClassName || ""}`}>
        {resultIcon}
      </div>
      {title && (
        <div style={titleStyle} className={`${styles.title} ${titleClassName || ""}`}>
          {title}
        </div>
      )}
      {desc && (
        <div style={descStyle} className={`${styles.desc} ${descClassName || ""}`}>
          {desc}
        </div>
      )}
      {buttons !== null && <div className={styles.buttons}>{resultButtons}</div>}
    </div>
  );
};

export default Result;
