export default {
  basicInfo: "Basic information",
  name: "User group name",
  nameInputErrMsg: "Please enter a user group name",
  autoAddUsers: "Add users automatically",
  on: "Open",
  off: "Off",
  autoAddUsersTip: "New users added to this workspace after opening will be automatically added to this user group",
  createdAt: "Creation time",
  description: "User group introduction",
  notSet: "Not set",
  disableDownloadLabel: "Download prohibited",
  disableDownloadDesc: "After opening, users are prohibited from downloading card data, downloading PNG images, and sending emails.",
  downloadMessage:
    "The system turns on the prohibition of downloading data, and all users are automatically prohibited from downloading data.",
};
