import React from "react";

const WinkFace = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM16 16C17.7673 16 19.2 14.5673 19.2 12.8C19.2 11.0327 17.7673 9.6 16 9.6C14.2327 9.6 12.8 11.0327 12.8 12.8C12.8 14.5673 14.2327 16 16 16ZM34.8612 10.349C34.9778 10.7569 34.7416 11.1819 34.3338 11.2985C33.8296 11.4425 32.7325 11.8712 31.5878 12.383C31.0444 12.626 30.513 12.8777 30.0472 13.1153L30.0906 13.1278C31.1956 13.4461 32.803 13.9154 34.441 14.661C34.827 14.8367 34.9975 15.2921 34.8218 15.6782C34.646 16.0642 34.1906 16.2347 33.8046 16.059C32.2792 15.3646 30.7685 14.9215 29.6654 14.6037L29.5507 14.5708C29.0653 14.4311 28.6276 14.3051 28.3376 14.1918C28.2549 14.1595 28.168 14.1221 28.0864 14.0788C28.0137 14.0402 27.8992 13.9733 27.796 13.8683C27.6961 13.7666 27.5053 13.5203 27.553 13.1623C27.5977 12.8266 27.8224 12.6412 27.9205 12.5721C28.5903 12.1003 29.8131 11.494 30.9608 10.9808C32.1151 10.4647 33.296 9.99749 33.9118 9.82155C34.3196 9.70503 34.7447 9.94118 34.8612 10.349ZM30.0346 20.2717C30.4496 20.1202 30.6632 19.6609 30.5117 19.2459C30.3602 18.8308 29.9009 18.6172 29.4859 18.7687C27.5783 19.4651 25.4039 19.6203 23.4299 19.5147C21.4592 19.4093 19.7463 19.0472 18.7948 18.7554C18.3724 18.6258 17.9249 18.8633 17.7954 19.2857C17.6658 19.7081 17.9032 20.1555 18.3257 20.2851C19.4031 20.6155 21.245 21.0001 23.3444 21.1124C25.4406 21.2246 27.8517 21.0686 30.0346 20.2717Z"
      fill="currentColor"
    />
  </svg>
);

export default WinkFace;
