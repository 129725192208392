import React from "react";

const SighCircle = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00008 10.5207C8.49675 10.5207 10.5207 8.49672 10.5207 6.00005C10.5207 3.50339 8.49675 1.47944 6.00009 1.47944C3.50342 1.47944 1.47948 3.50339 1.47948 6.00005C1.47947 8.49672 3.50342 10.5207 6.00008 10.5207ZM11.3337 6.00005C11.3337 8.94571 8.94574 11.3336 6.00008 11.3336C3.05443 11.3336 0.666504 8.94571 0.666504 6.00005C0.666504 3.0544 3.05443 0.666473 6.00009 0.666473C8.94574 0.666474 11.3337 3.0544 11.3337 6.00005Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.40633 4.0291C6.40633 4.25359 6.22434 4.43558 5.99984 4.43558H5.9943C5.7698 4.43558 5.58782 4.25359 5.58782 4.0291C5.58782 3.8046 5.7698 3.62261 5.9943 3.62261H5.99984C6.22434 3.62261 6.40633 3.8046 6.40633 4.0291Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99984 8.37738C5.77535 8.37738 5.59336 8.19539 5.59336 7.97089V6.00005C5.59336 5.77556 5.77535 5.59357 5.99984 5.59357C6.22434 5.59357 6.40633 5.77556 6.40633 6.00005V7.97089C6.40633 8.19539 6.22434 8.37738 5.99984 8.37738Z"
      fill="currentColor"
    />
  </svg>
);

export default SighCircle;
