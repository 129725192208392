export default {
  checklistModalTitle: "选择需更新图表",
  checkAll: "全选",
  okBtnText: "开始更新",
  inProgressModalTitle: "更新列表",
  waitForUpdateTip: "等待中...",
  inProgressTip: "更新中...",
  inProgress: "进行中",
  finished: "已完成",
  noContentTip: "暂无任何内容",
  noAuthErrorMessage: "更新失败，无工作区权限",
  noResultErrorMessage: "搜索无结果",
  unknownErrorMessage: "更新失败，请稍后再试",
  recallTip: "撤回",
  recallSucceed: "撤回成功",
  recallFail: "撤回失败",
};
