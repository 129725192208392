import React from "react";

const CloseIcon = ({ backgroundOpacity, contentOpacity, ...restProps }) => (
  <svg {...restProps} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="currentColor" fillOpacity={backgroundOpacity} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0101 12.0001L16.7907 8.21944C17.07 7.94016 17.07 7.48874 16.7907 7.20946C16.5114 6.93018 16.06 6.93018 15.7807 7.20946L12.0001 10.9901L8.21944 7.20946C7.94016 6.93018 7.48874 6.93018 7.20946 7.20946C6.93018 7.48874 6.93018 7.94016 7.20946 8.21944L10.9901 12.0001L7.20946 15.7807C6.93018 16.06 6.93018 16.5114 7.20946 16.7907C7.34874 16.93 7.5316 17 7.71445 17C7.89731 17 8.08016 16.93 8.21944 16.7907L12.0001 13.0101L15.7807 16.7907C15.92 16.93 16.1029 17 16.2857 17C16.4686 17 16.6514 16.93 16.7907 16.7907C17.07 16.5114 17.07 16.06 16.7907 15.7807L13.0101 12.0001Z"
      fill="currentColor"
      fillOpacity={contentOpacity}
    />
  </svg>
);

export default CloseIcon;
