export default {
  relevance: "星型模型、事实表与维度表",
  dragTable: "1. 拖拽表格放入画布",
  selectCorrespondingDimension: "2. 选择(或创建)数据列对应的时间维度、维度和指标",
  createDimensionTable: "3. 创建(或复用)维度表",
  joinTables: "4. 连接事实表与维度表",
  viewBriefDescription: "您可以点击此处再次查看模型创建的简单说明",
  learnToCreateModels: "简单几步学会创建模型",
  viewDetailedInstructions: "查看详细说明书",
  doNotRemindMeAgain: "下次不再提醒",
  skip: "跳过",
};
