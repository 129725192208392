export default {
  matchedForYou: "We matched ",
  changeInitialPasswordTitle: "Change password",
  inputNewPasswordMessage: "Enter new password",
  inputNewPasswordAgainMessage: "Enter new password again",
  passwordNotMatchErrorText: "Passwords don't match",
  cancelBtnText: "Cancel",
  oKAndLoginBtnText: "Confirm and login",
  noLimit: "Not set",
  today: "Today",
  yesterday: "Yesterday",
  dayBeforeYesterday: "The day before yesterday",
  feedbackAndCalibration: "Feedback",
  semanticCalibrationRule: "Semantic rule",
  makeParticipleMatchingMoreAccurate: "Make participle matching more accurate",
  timeout: "Request timeout",
  selectDataFailed: "Select data failed",
  errorStatusTipPrefix: "Request has",
  errorStatusTipSuffix: "Error",
  defaultErrorTip: "An unknown error occurred",
  determineDeleteDraft: "Are you sure to delete the draft?",
  confirm: "Confirm",
  draftDeletedSuccessfully: "Draft deleted successfully",
  saveConfirmTitle: "Saving changes will affect all applied models",
  saveConfirmContent1: "the",
  saveConfirmContent2: "existing application model.",
  saveConfirmContent3: "After confirmation, the changes will be synchronized to all applied models.",
  saveConfirmContent4: "The operation cannot be undone.",
  confirmBtnText: "Confirm",
  cancelConfirmTitle: "There are unsaved changes in the current dimension, confirm the exit editing status?",
  cancelConfirmContent:
    "This dimension table is a configured dimension table and needs to save the modifications separately. After canceling the modifications, the unsaved modifications will be emptied.",
  formItemErrMsg: "This field is required",
  nameErrMsg1: "Name error, name length cannot exceed 120 words",
  nameErrMsg2: "Naming error, the name must contain a Chinese character or English letter",
  nameErrMsg3: "Naming error, the special symbol in the name only supports _/\\ -",
  leftBracket: "【",
  colon: "：",
  rightBracket: "】",
  none: "None",
  on: "Enable",
  off: "Prohibited",
  limitPrefix: "Take",
  limitSuffix: "A",
  versionUpdatedTip: "Version has updated, click to upgrade experience",
  versionUpdatedTipRefresh: "Refresh",
  synchronizeKeywordsTip: "There are keyword related changes in the workspace, please manually synchronize the keywords",
  synchronizeKeywordsTipBtn: "Sync",
  nextStepBtn: "Next step",
  uploadLicenseTitle: "Upload license",
  uploadFilesBtn: "Upload files",
  activationCodeTitle: "Activation code",
  activationCodePlaceholder: "Please enter the activation code",
  confirmLicenseTitle: "Confirm license",
  cancelBtn: "Cancel",
  confirmBtn: "Confirm",
  deleteConfirmBtn: "Confirm Delete",
  deleteSuccessToast: "Delete Success",
  userDeleted: "Written off",
  selectPlaceHolder: "Please Select",
  lockPinboardWarningButtonText: "Understood",
  pinboardLockedWarningTitle: "The current report is being edited",
  pinboardLockAuthor: "Current editing user: ",
  pinboardLockTip: "Please try again later to access the editing.",
  lockOvertimeWarningTitle: "The current editing session has timed out due to inactivity for an extended period",
  lockOvertimeWarningContent: "Please refresh the page and re-enter the editing mode.",
};
