import { getLocaleText } from "@/utils/locale";

const getTextLocale = id => getLocaleText(`components.Constants.CalcType.${id}`);

export default class CalcType {
  static SUM = "sum";

  static AVG = "avg";

  static MIN = "min";

  static MAX = "max";

  static LATEST = "latest";

  static COUNT = "count";

  static COUNT_DISTINCT = "count_distinct";

  static TIME_INTERVAL = "timeInterval";

  static HIDDEN = "hidden";

  static ASC = "asc"; // 升序

  static DESC = "desc"; // 降序

  static DATA_FORMAT = "dataFormat"; // 数值格式

  static choices = [CalcType.SUM, CalcType.AVG, CalcType.MIN, CalcType.MAX, CalcType.COUNT, CalcType.COUNT_DISTINCT];

  static getPrefixText = value => {
    if (value === CalcType.SUM) return getTextLocale("sumPrefix");
    if (value === CalcType.AVG) return getTextLocale("avgPrefix");
    if (value === CalcType.MIN) return getTextLocale("minPrefix");
    if (value === CalcType.MAX) return getTextLocale("maxPrefix");
    if (value === CalcType.LATEST) return getTextLocale("latestPrefix");
    return `${value}`;
  };

  static getText = value => {
    if (value === CalcType.SUM) return getTextLocale("sum");
    if (value === CalcType.AVG) return getTextLocale("avg");
    if (value === CalcType.MIN) return getTextLocale("min");
    if (value === CalcType.MAX) return getTextLocale("max");
    if (value === CalcType.LATEST) return getTextLocale("latest");
    if (value === CalcType.COUNT) return getTextLocale("count");
    if (value === CalcType.COUNT_DISTINCT) return getTextLocale("countDistinct");
    return `${value}`;
  };

  static getCalcMapping = () => ({
    [this.MAX]: getTextLocale("max"),
    [this.SUM]: getTextLocale("sum"),
    [this.MIN]: getTextLocale("min"),
    [this.AVG]: getTextLocale("avg"),
    [this.COUNT]: getTextLocale("countLabel"),
    [this.COUNT_DISTINCT]: getTextLocale("countDistinctLabel"),
    [this.TIME_INTERVAL]: getTextLocale("timeInterval"),
  });

  static getSortItemMap = () => [
    { label: getTextLocale("ascLabel"), key: this.ASC },
    { label: getTextLocale("descLabel"), key: this.DESC },
  ];

  static getMeasuresCalcMap = () => [
    { label: getTextLocale("hiddenLabel"), key: this.HIDDEN },
    { label: getTextLocale("sum"), key: this.SUM },
    { label: getTextLocale("avg"), key: this.AVG },
    { label: getTextLocale("max"), key: this.MAX },
    { label: getTextLocale("min"), key: this.MIN },
  ];

  static getTimeDimensionMap = () => [
    { label: getTextLocale("hiddenLabel"), key: this.HIDDEN },
    { label: getTextLocale("timeInterval"), key: this.TIME_INTERVAL },
  ];

  static getDimensionMap = () => [
    { label: getTextLocale("hiddenLabel"), key: this.HIDDEN },
    { label: getTextLocale("countLabel"), key: this.COUNT },
    { label: getTextLocale("countDistinctLabel"), key: this.COUNT_DISTINCT },
  ];

  static getDataFormatMap = () => ({ label: getTextLocale("dataFormatLabel"), key: this.DATA_FORMAT });
}
