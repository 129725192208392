export default {
  title: "大语言模型设置",
  status: "服务状态",
  statusDesc: "开启后用户可以使用大语言模型的能力自动进行模型配置和图表内容数据解释",
  serverConfig: "服务配置",
  modalTitle: "大语言模型服务设置",
  providerQianfan: "百度千帆大模型平台",
  modalOkButtonText: "测试并保存",
  modalSaveButtonText: "保存",
  providerLabel: "模型服务",
  apiKeyLabel: "连接密钥",
  apiKeyPlaceholder: "API的连接密钥",
  modelLabel: "模型",
  proxyLabel: "代理地址",
  qianfanApiKeyLabel: "API Key",
  qianfanApiKeyPlaceholder: "千帆平台创建的应用的 API Key",
  qianfanSecretLabel: "Secret Key",
  qianfanSecretPlaceholder: "千帆平台创建的应用的 Secret Key",
  proxyPlaceHolder: "连接 OpenAI 使用的代理地址，网络顺利的情况下可以不设置",
  setButtonText: "设置",
  checkConnectionButtonText: "测试连接状态",
  checkConnectionButtonTip: "测试服务的连接状态，需要消耗一定token数量",
  checkBtnDisabledTip: "请至少打开一个关联功能开关",
  successfulToast: "大语言模型服务连接正常",
  failToast: "大语言模型服务连接失败，失败原因",
  cancelText: "取消",
  none: "无",
  saveSuccessToast: "保存成功",
  serviceAddressLabel: "服务地址",
  serviceAddressPlaceholder: "请填写成你的服务地址，留空代表使用 OpenAI 服务",
  httpUrlLabel: "同步 HTTP 调用模型地址",
  httpUrlPlaceholder: "输入大语言模型同步 HTTP 调用接口的地址",
  timeoutLabel: "请求超时时间（秒）",
  timeoutTitle: "调用大语言模型服务的请求超时时间",
  timeoutDesc: "请求超时时间",
  timeoutUnit: "秒",
  httpHeader: "HTTP 请求头",
  OpenAILabel: "OpenAI 接口",
  httpLabel: "同步 HTTP 调用模型（测试版）",
  httpRequestBody: "HTTP 请求体",
  httpResponseBody: "HTTP 返回体",
  relatedSwitch: "关联功能开关",
  searchLabel: "IM 辅助搜索",
  searchDesc: "IM 端将利用大模型辅助处理用户问题，更准确识别用户的查询意图",
  promptBtnLabel: "调整 Prompt",
  aiFieldLabel: "AI 字段类型配置",
  aiKeywordLabel: "AI 关键词配置",
  aiSynonymLabel: "AI 别名配置",
  aiFieldDesc: "模型配置时，将利用大模型判断字段类型信息",
  aiKeywordDesc: "模型配置时，将利用大模型判断关键词名称信息",
  aiSynonymDesc: "模型配置时，将利用大模型判断别名信息",
  insightServerLabel: "数据解释",
  insightServerDesc: "图表卡片上，将利用大模型根据数据结果提供相应的数据解释、分析和建议",
  sqlLabel: "SQL 报错",
  sqlDesc: "图表卡片或后台自定义SQL出错时，将利用大模型对数据库的 SQL 报错进行错误解释和提出修改建议",
  chatLabel: "对话",
  chatDesc: "未能作为数据问题匹配的问题，将和大语言模型进行对话，并获取回答",
};
