export default {
  isHelpfulText: "当前结果解决了你的问题？",
  helpfulTip: "满意，解决了我的问题",
  notHelpfulTip: "不满意，没有解决",
  feedbackToast: "感谢你的反馈",
  okBtn: "确定",
  noHelpfulTitle: "你不满意的原因",
  noHelpfulLabel: "请选择你不满意的原因（多选），这将帮助我们提供更好的服务",
  othersLabel: "其他原因（选填）",
  othersPlaceholder: "也可以填写其他对产品的建议与反馈",
  noUnderstandBtn: "搜索结果看不懂",
  errorBtn: "搜索结果错误",
  noResultBtn: "搜索无结果",
  slowBtn: "搜索速度慢",
  unreasonableBtn: "图表样式不合理",
  notRelevantBtn: "关联卡片与问题无关",
  othersBtn: "其他",
};
