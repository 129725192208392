export default {
  editModalTitle: "Edit time period",
  addModalTitle: "Add time period",
  editConfirmBtnText: "Confirm",
  addConfirmBtnText: "Add",
  keyword: "Keywords",
  keywordTip: "Trigger keywords of this time range when searching",
  keywordInputErrMsg: "Please select a keyword",
  keywordInputPlaceholder: "Please enter searching keywords",
  emptyInputErrMsg: "Keyword cannot be empty",
  invalidInputErrMsg: "Keywords cannot be pure numbers",
  timeUnit: "Time unit",
  timeUnitErrMsg: "Please select a time unit",
  timeUnitSelectPlaceholder: "Select the time unit for action",
  offset: "Start time",
  offsetErrMsg: "Please select start time",
  offsetSelectPlaceholder: "Select the start time of the action",
};
