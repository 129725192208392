export default {
  outlier: "Abnormal values",
  pareto: "Composition",
  ringOutlier: "Growth Outliers",
  seasonality: "Seasonality",
  trend: "Overall Trend",
  changePoint: "Trend change point",
  breakout: "Breakthrough",
  volatility: "Volatility change",
  maximum: "Max",
  minimum: "Min",
  average: "Average",
  statistics: "Dispersion",
  count: "Other",
  suffix: "Analysis",
  correlation: "Correlation",
  recentChange: "Recent changes",
  mixedTrend: "Mixed trend",
  correlationDesc: "The closer the value is to 1, the greater the correlation, indicating a stronger mutual influence.",
};
