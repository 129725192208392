import React from "react";

const EmptyEdit = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.15623 10.4256L10.5289 3.89654C10.6207 3.78477 10.6155 3.62103 10.5147 3.51524L11.5075 2.56915C12.1077 3.19902 12.1196 4.18553 11.5346 4.8296L4.90222 11.6247C4.79469 11.7349 4.64725 11.797 4.49329 11.797H2.39174C2.07615 11.797 1.82031 11.5412 1.82031 11.2256V9.11595C1.82031 8.96679 1.87863 8.82355 1.98281 8.71681L8.62086 1.91586C9.23008 1.31127 10.21 1.30177 10.8308 1.89443L11.2049 2.25161L11.5075 2.56915L10.5147 3.51524L10.2345 3.22118L9.88381 2.88642C9.80183 2.80816 9.67324 2.80805 9.59114 2.8852L3.19174 9.44165V10.4256H4.15623Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M2.50703 13.1705H13.4945C13.8738 13.1705 14.1812 13.4779 14.1812 13.8572C14.1812 14.2365 13.8738 14.5439 13.4945 14.5439H2.50703C2.12777 14.5439 1.82031 14.2365 1.82031 13.8572C1.82031 13.4779 2.12777 13.1705 2.50703 13.1705Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default EmptyEdit;
