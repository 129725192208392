export default {
  okText: "知道了",
  demoteModalTitle: "用户为工作区管理员",
  demoteModalContent: "降级为全民数据探索家后，用户将从工作区管理员自动变为工作区用户。",
  notChangeOptionLabel: "不改动",
  resetOptionLabel: "重置",
  passwordExtraMessage: "管理员可以强制重置用户密码",
  noUserPasswordExtraMessage: "请输入账号的初始密码",
  userPasswordLabel: "用户密码",
  passwordInputPlaceholder: "请输入密码",
  primaryPasswordLabel: "初始密码",
  confirm: "确认",
  cancel: "取消",
  addUser: "添加用户",
  editUser: "编辑用户",
  nickNameLabel: "用户名称",
  nickNameHelp: "请输入用户名称",
  userNameLabel: "登录名",
  userNameHelp: "用作登录，若不填写则系统自动生成一串字符串",
  userNameInputPlaceholder: "请输入登录名",
  phoneLabel: "手机号",
  phoneHelp: "非必填，输入后用户可使用手机验证码登录",
  phoneInputPlaceholder: "请输入手机号",
  emailLabel: "邮箱",
  emailHelp: "非必填，输入后用户可使用邮箱验证码登录",
  emailInputPlaceholder: "请输入邮箱",
  userDescLabel: "用户简介",
  userDescHelp: "非必填，简单的用户介绍",
  userDescInputPlaceholder: "请输入用户简介，可填写职位部门等信息",
  userRoleLabelTitle: "用户角色",
  userRoleLabelContent: "分为 业务用户、系统管理员、和审计员，只有业务用户占配额资源",
  userRoleRequiredMessage: "请选择用户角色",
  accountTypeLabelTitle: "账号类型",
  firstAccountTypeIntro: "不同账号能使用工作区的不同功能",
  secondAccountTypeIntro: "数据专家：工作区全部功能（包括工作区管理）",
  thirdAccountTypeIntro: "全民数据科学家：搜索查看、数据下载及报告功能",
  fourthAccountTypeIntro: "全民数据探索家：仅享有搜索和查看功能",
  linkText: "点击查看详情",
  accountTypeRequiredMessage: "请选择账号类型",
  accountPropsLabelTitle: "账号属性",
  accountPropsLabelContent: "分为正式和临时，临时账号自激活日起有3个月有效期。超期后将自动进入休眠，可重新激活。",
  accountPropsRequiredMessage: "请选择账号属性",
  createBusinessUserTitle: "创建业务用户",
  createAdminUserTitle: "创建系统管理员",
  createAuditorUserTitle: "创建审计员",
  alertFieldText: "降级",
};
