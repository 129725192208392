import DataType from "@/components/Constants/DataType";

// 筛选条件类型
export default class FilterConditionType {
  static NUMBER = "number"; // 数字类型

  static TEXT = "text"; // 文本类型

  static TIME = "time"; // 时间类型

  // 下面的类型展示为 [文本] 类型的筛选条件
  static textChoices = [DataType.STR, DataType.PROVINCE_STR, DataType.CITY_STR, DataType.COUNTY_STR, DataType.CHOICE];

  // 下面的类型展示为 [数字] 类型的筛选条件
  static numberChoices = [DataType.FLOAT, DataType.INT];
}
