import React from "react";

const AutoInsightOutlier: React.FC = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <path
      d="M17.6092 14.0004L13.931 7.37937C13.4368 6.48853 12.7529 6 12 6C11.2471 6 10.5632 6.48853 10.069 7.37937L6.39076 14.0004C5.92524 14.8452 5.87352 15.6556 6.24708 16.2936C6.62065 16.9315 7.35629 17.2821 8.32181 17.2821H15.6782C16.6437 17.2821 17.3794 16.9315 17.7529 16.2936C18.1265 15.6556 18.0748 14.8395 17.6092 14.0004ZM11.569 10.0232C11.569 9.78753 11.7644 9.59212 12 9.59212C12.2356 9.59212 12.431 9.78753 12.431 10.0232V12.8969C12.431 13.1325 12.2356 13.3279 12 13.3279C11.7644 13.3279 11.569 13.1325 11.569 12.8969V10.0232ZM12.408 15.0292C12.3793 15.0521 12.3506 15.0751 12.3218 15.0981C12.2874 15.1211 12.2529 15.1384 12.2184 15.1499C12.1839 15.1671 12.1494 15.1786 12.1092 15.1843C12.0747 15.1901 12.0345 15.1958 12 15.1958C11.9655 15.1958 11.9253 15.1901 11.8851 15.1843C11.8506 15.1786 11.8161 15.1671 11.7816 15.1499C11.7471 15.1384 11.7126 15.1211 11.6782 15.0981C11.6494 15.0751 11.6207 15.0521 11.592 15.0292C11.4885 14.92 11.4253 14.7705 11.4253 14.6211C11.4253 14.4717 11.4885 14.3222 11.592 14.213C11.6207 14.19 11.6494 14.167 11.6782 14.1441C11.7126 14.1211 11.7471 14.1038 11.7816 14.0923C11.8161 14.0751 11.8506 14.0636 11.8851 14.0578C11.9598 14.0406 12.0402 14.0406 12.1092 14.0578C12.1494 14.0636 12.1839 14.0751 12.2184 14.0923C12.2529 14.1038 12.2874 14.1211 12.3218 14.1441C12.3506 14.167 12.3793 14.19 12.408 14.213C12.5115 14.3222 12.5747 14.4717 12.5747 14.6211C12.5747 14.7705 12.5115 14.92 12.408 15.0292Z"
      fill="white"
    />
  </svg>
);

export default AutoInsightOutlier;
