export default {
  sensitiveInfoPermission: "敏感信息查看限制",
  permissionOffTip: "权限未启用，用户查看敏感信息时无敏感限制",
  saveSuccessToast: "敏感信息查看限制更新成功",
  enableConfirmTitle: "确认开启敏感信息查看限制？",
  enableConfirmTip: "开启后需要配置敏感信息查看限制，否则可能导致搜索结果无数据",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  refreshBtnText: "刷新",
  dataMaskingTip: "请将需要加入名单的资源从左侧加入右侧，加入右侧名单的资源将会脱敏展示",
  unmaskingTip: "请将需要加入名单的资源从左侧加入右侧，加入右侧名单的资源将不会脱敏展示",
  allDimensions: "所有维度",
  selectedDimensions: "应用名单",
  saveBtnText: "更新",
};
