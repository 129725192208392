import React from "react";

const MagnifierFill = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="10.5992" cy="10.6532" rx="8.59922" ry="8.65324" fill="currentColor" />
    <path
      opacity="0.4"
      d="M20.6745 21.9555C20.3405 21.9447 20.0228 21.8073 19.7853 21.5707L17.7488 19.1904C17.3122 18.7912 17.2765 18.1126 17.6688 17.6692V17.6692C17.8524 17.4834 18.102 17.3789 18.3624 17.3789C18.6228 17.3789 18.8725 17.4834 19.0561 17.6692L21.6172 19.7184C21.9861 20.096 22.0999 20.6565 21.9078 21.1495C21.7157 21.6424 21.2535 21.9757 20.7279 22.0003L20.6745 21.9555Z"
      fill="currentColor"
    />
  </svg>
);
export default MagnifierFill;
