export default {
  selectDataPlaceholder: "查找并选择数据列",
  manualInputText: "手动输入",
  enterPlaceholder: "请输入",
  tableRelationshipTitle: "配置表关系",
  confirmText: "确定",
  cancelText: "取消",
  addColumnsText: "加一个关联列",
  addColumnsTip: "左右选择器中数据列分别对应“表的关联关系”两边的选择器",
};
