export default {
  leaveModelConfigurationPage: "Do you want to leave the model configuration page?",
  configurationWillNotBeSaved: "The current configuration will not be saved.",
  viewDraft: "View Draft",
  saveDraft: "Save draft",
  saveAsDraft: "Save as draft",
  cancel: "Cancel",
  confirm: "Confirm",
  confirmDeletionOfModel: "Confirm deletion of model",
  deletedModelCannotBeRestored: "Deleted models are not recoverable.",
  contentWillReplaceOriginalModel: "The current content will replace the original model after publication",
  operationCannotBeUndone: "The content of the operation cannot be withdrawn, please operate with caution.",
  draftDoesNotReferenceModel: "The current draft does not reference the model, publishing the draft will create a new model",
  confirmPublishing: "Confirm publishing of the current content?",
  deleteDraft: "Delete draft",
  publish: "Publish",
  save: "Save",
  deleteModel: "Delete model",
};
