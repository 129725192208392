import React from "react";

const SaveCard = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      d="M10.6666 1.33331C10.6666 1.33331 7.99998 1.33331 5.33331 1.33331C2.66665 1.33331 1.33331 2.66665 1.33331 5.33331V10.6666C1.33331 13.3333 2.66665 14.6666 5.33331 14.6666C7.99998 14.6666 10.6666 14.6666 10.6666 14.6666C13.3333 14.6666 14.6666 13.3333 14.6666 10.6666V5.33331C14.6666 2.66665 13.3333 1.33331 10.6666 1.33331Z"
      fill="currentColor"
    />
    <path
      d="M10.3334 7.50002H8.50002V5.66669C8.50002 5.39335 8.27335 5.16669 8.00002 5.16669C7.72669 5.16669 7.50002 5.39335 7.50002 5.66669V7.50002H5.66669C5.39335 7.50002 5.16669 7.72669 5.16669 8.00002C5.16669 8.27335 5.39335 8.50002 5.66669 8.50002H7.50002V10.3334C7.50002 10.6067 7.72669 10.8334 8.00002 10.8334C8.27335 10.8334 8.50002 10.6067 8.50002 10.3334V8.50002H10.3334C10.6067 8.50002 10.8334 8.27335 10.8334 8.00002C10.8334 7.72669 10.6067 7.50002 10.3334 7.50002Z"
      fill="currentColor"
    />
  </svg>
);

export default SaveCard;
