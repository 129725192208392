export default {
  dataType: "数据类型",
  dataTypeSelectPlaceholder: "请选择数据类型",
  dataTypeErrMsg: "请选择数据类型",
  format: "展示格式",
  formatSelectPlaceholder: "请选择展示格式",
  formatErrMsg: "请选择展示格式",
  keyword: "关键词",
  keywordInputPlaceholder: "请输入关键词",
  keywordErrMsg1: "请输入关键词",
  keywordErrMsg2: "关键词不能为空",
  alias: "别名",
  aliasTip: "别名设置可以增加关键词的命中概率，多个别名以逗号区隔",
  aliasInputPlaceholder: "请输入别名",
  aliasErrMsg: "请输入别名",
  measureType: "指标类型",
  description: "说明",
  descriptionPlaceholderMiddleText: "的说明，添加说明可以让用户更好的了解",
  descriptionPlaceholderLatterText: "的用途",
  descriptionFormerErrMsg: "请输入",
  descriptionLatterErrMsg: "的说明",
  indicatorType: "数值类型",
  positive: "正面指标",
  negative: "负面指标",
  indicatorTypeErrMsg: "请选择数值类型",
  timeUnit: "默认时间单位",
  timeUnitTip: "时间维度聚合的默认单位，例如日、周、月等",
  timeUnitSelectPlaceholder: "请选择默认时间单位",
  timeUnitErrMsg: "请选择默认时间单位",
  minTimeUnit: "最小时间单位",
  minTimeUnitTip: "时间维度聚合的最小单位，例如日、周、月等",
  minTimeUnitSelectPlaceholder: "请选择最小时间单位",
  minTimeUnitErrMsg: "请选择最小时间单位",
  timeRange: "默认查询时间范围",
  timeRangeTip: "搜索该时间维度时默认查询的时间范围，若未设置将根据搜索设置中的范围展示",
  timeRangeInputPlaceholder: "例如前30天",
  timeRangeErrMsg: "请输入默认查询时间范围",
  autoGroupTip: "开启后输入本维度内多个搜索词时，搜索结果将以分组方式展示。",
  showSubDimensions: "自动展示子维度卡片",
  showSubDimensionsTip: "打开后搜索父维度时会自动展示子维度的相关图表卡片",
  searchContentTip: "打开后该列非纯数值内容会推送到系统中成为维度值。",
  customKeywordLimit: "可搜索维度值数量限制",
  customKeywordLimitTip: "打开后将限制可直接被算法检索的维度值数量，总数小于10万条",
};
