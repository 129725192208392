export default {
  modeAddUser: "Add User",
  modeUpdateUser: "Update User",
  uploadCSVModalContent: "Currently, only .csv files are supported for upload.",
  uploadErrorInFileFormat: "Error in data file format",
  okText: "OK",
  cancelText: "Cancel",
  uploadSuccessToast: "User information uploaded successfully",
  uploadErrorToast: "Error uploading user information",
  fileModelName: "system_members_template.csv",
  modalTitle: "Bulk Upload",
  nextBtnText: "Next",
  uploadLabel: "Update Method",
  delimiterLabel: "Delimiter Selection",
  downloadModelText: "Please download the template and fill in the information according to the template format before uploading.",
  downloadDataText: "Please download the data, update the file, and then upload it.",
  supportTip: "Supported format: .csv, only utf-8,gbk,ascii,utf-16 encoding is supported.",
  selectBtn: "Select File",
  downloadModelBtn: "Download Template",
  downloadDataBtn: "Download User Data",
  uploadErrorTitle: "Upload Failed",
  uploadErrorDesc: "Please download the error information, make the necessary corrections, and upload again.",
  uploadErrorBtnText: "Download Error Information File",
  errorFilename: "Error Filename",
  errorRow: "Error Row",
  errorField: "Error Field",
  errorMessage: "Error Message",
  username: "Username",
  loginName: "Login Name",
  password: "Initial Password",
  phone: "Phone Number",
  email: "Email",
  tier: "Account Type",
  type: "Account Property",
};
