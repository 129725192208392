export default class LoginMethod {
  static CAS = "cas";

  static DINGTALK = "dingTalk";

  static WECOM = "weCom";

  static LARK = "lark";

  static YUFU = "yuFu";

  static OAUTH2 = "oauth2";

  static SAML2 = "saml2";

  static DEFAULT = "default";

  static getText = t => {
    if (t === this.CAS) return "CAS服务";
    if (t === this.DINGTALK) return "钉钉";
    if (t === this.WECOM) return "企业微信";
    if (t === this.LARK) return "飞书";
    if (t === this.YUFU) return "千帆玉符";
    if (t === this.DEFAULT) return "用户名密码";
    if (t === this.OAUTH2) return "OAuth 2.0";
    if (t === this.SAML2) return "SAML 2.0";
    return "";
  };
}
