import React from "react";

const BookOutline = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.70479 1.56551C1.85933 1.45617 2.05733 1.42863 2.23585 1.49163L7.00004 3.17311L11.7642 1.49163C11.9428 1.42863 12.1407 1.45617 12.2953 1.56551C12.4498 1.67486 12.5417 1.8524 12.5417 2.04171V10.2084C12.5417 10.4557 12.3857 10.6761 12.1525 10.7585L7.19419 12.5085C7.06855 12.5528 6.93153 12.5528 6.8059 12.5085L1.84756 10.7585C1.61434 10.6761 1.45837 10.4557 1.45837 10.2084V2.04171C1.45837 1.8524 1.55025 1.67486 1.70479 1.56551ZM6.41671 4.20443L2.62504 2.86619V9.79566L6.41671 11.1339V4.20443ZM7.58337 11.1339L11.375 9.79566V2.86619L7.58337 4.20443V11.1339ZM3.69624 3.97153L5.7379 4.7007L5.34551 5.79939L3.30384 5.07023L3.69624 3.97153ZM10.6962 5.07023L8.65457 5.79939L8.26218 4.7007L10.3038 3.97153L10.6962 5.07023ZM3.69624 6.0132L5.7379 6.74236L5.34551 7.84106L3.30384 7.11189L3.69624 6.0132ZM10.6962 7.11189L8.65457 7.84106L8.26218 6.74236L10.3038 6.0132L10.6962 7.11189ZM3.69624 8.05486L5.7379 8.78403L5.34551 9.88273L3.30384 9.15356L3.69624 8.05486ZM10.6962 9.15356L8.65457 9.88273L8.26218 8.78403L10.3038 8.05486L10.6962 9.15356Z"
      fill="currentColor"
    />
  </svg>
);

export default BookOutline;
