export default {
  hasNewDataColumnsAdded: "There are new data columns",
  isANewlyAddedColumn: "For new columns",
  missingWillAutomaticallyFillIn: "Missing values caused by new columns will be automatically filled with null",
  dataHasNotBeenUploaded: "The data has not been uploaded to the system, currently only the first 25 rows are previewed",
  cancel: "Cancel",
  confirmUpload: "Confirm upload",
  dataTableUpdatedSuccessfully: "Data table updated successfully",
  successfullyUploadedTheDataTable: "Data table uploaded successfully",
};
