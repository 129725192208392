import { Menu as AntdMenu } from "antd";
import { MenuDividerProps, MenuItemGroupProps, MenuItemProps, MenuProps, SubMenuProps } from "antd/es/menu";
import { SubMenuType as AntdSubMenuType } from "antd/es/menu/interface";
import React from "react";

import styles from "./index.module.scss";

export interface SubMenuType extends AntdSubMenuType {
  selected?: boolean;
}

const handlerVerticalItemsChildren = item => {
  if (item.children?.length) {
    item.className = `${styles.menuItem} ${item.className}`;
    item.popupClassName = `${styles.a} ${styles.b} ${styles.c} ${styles.subMenuContainer} ${item.popupClassName}`;

    // 处理子菜单的选中状态
    item.children.forEach(child => {
      const childItem = child;
      if (!childItem.disabled && childItem.selected && !childItem.children?.length) {
        childItem.className = `${styles.subMenuItemActive} ${childItem.className}`;
      }
      handlerVerticalItemsChildren(childItem);
    });
  }
};

const Menu: React.FC<MenuProps> & {
  Item: typeof AntdMenu.Item;
  SubMenu: typeof AntdMenu.SubMenu;
  Divider: typeof AntdMenu.Divider;
  ItemGroup: typeof AntdMenu.ItemGroup;
} = ({ className, mode, items, ...rest }) => {
  let baseClassName = `${styles.a} ${styles.b} ${styles.c}`;
  if (mode === "horizontal") baseClassName += ` ${styles.antdMenuHorizontal}`;
  else {
    // vertical 模式
    baseClassName += ` ${styles.antdMenuVertical}`;
    // 对 items 进行处理，如果有子菜单，注入 className，popupClassName，popupOffset
    items.forEach(item => {
      const menuItem = item as SubMenuType;
      handlerVerticalItemsChildren(menuItem);
    });
  }

  return <AntdMenu mode={mode} items={items} className={`${baseClassName} ${className}`} {...rest} />;
};

Menu.Item = AntdMenu.Item;
Menu.SubMenu = AntdMenu.SubMenu;
Menu.Divider = AntdMenu.Divider;
Menu.ItemGroup = AntdMenu.ItemGroup;

export type { MenuDividerProps, MenuItemGroupProps, MenuItemProps, MenuProps, SubMenuProps };

export default Menu;
