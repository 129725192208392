import React from "react";

const TitleIcon = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_17877_77459)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00012 14.781C11.7451 14.781 14.781 11.7451 14.781 8.00012C14.781 4.25512 11.7451 1.2192 8.00012 1.2192C4.25512 1.2192 1.2192 4.25512 1.2192 8.00012C1.2192 11.7451 4.25512 14.781 8.00012 14.781ZM16.0005 8.00012C16.0005 12.4186 12.4186 16.0005 8.00012 16.0005C3.58164 16.0005 -0.000253364 12.4186 -0.000252978 8.00012C-0.000252592 3.58163 3.58164 -0.000254763 8.00012 -0.000254377C12.4186 -0.000253991 16.0005 3.58164 16.0005 8.00012Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.60938 5.04365C8.60938 5.3804 8.33639 5.65338 7.99965 5.65338L7.99133 5.65338C7.65459 5.65338 7.3816 5.3804 7.3816 5.04365C7.3816 4.70691 7.65459 4.43393 7.99133 4.43393L7.99965 4.43393C8.33639 4.43393 8.60938 4.70691 8.60938 5.04365Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99965 11.5661C7.6629 11.5661 7.38992 11.2931 7.38992 10.9564L7.38992 8.00012C7.38992 7.66337 7.6629 7.39039 7.99965 7.39039C8.33639 7.39039 8.60938 7.66337 8.60938 8.00012L8.60938 10.9564C8.60938 11.2931 8.33639 11.5661 7.99965 11.5661Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_17877_77459">
        <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);

export default TitleIcon;
