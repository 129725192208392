import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Workspace.WorkspaceDisplay.${id}`);

export default class NumberFormatterStyle {
  static DEFAULT = 0;

  static ORIGIN = 1;

  static getText = t => {
    if (t === NumberFormatterStyle.DEFAULT) return getTextById("defaultFormat");
    if (t === NumberFormatterStyle.ORIGIN) return getTextById("originalValue");
  };

  static choices = [this.DEFAULT, this.ORIGIN];

  static getOptions = () => this.choices.map(value => ({ label: this.getText(value), value }));
}
