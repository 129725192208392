import React from "react";

const ProfessionalTier = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_70_13859)">
      <path
        d="M3.93832 10.4469C2.10701 10.4469 0.597412 8.93735 0.597412 7.10604C0.597412 5.27473 2.10701 3.76514 3.93832 3.76514C4.58176 3.76514 5.20044 3.93837 5.74489 4.28483C5.96761 4.43332 6.04186 4.73029 5.89337 4.97776C5.74489 5.20049 5.44792 5.27473 5.20044 5.12625C4.82923 4.87877 4.38378 4.75503 3.93832 4.75503C2.65145 4.75503 1.58731 5.81917 1.58731 7.10604C1.58731 8.39291 2.65145 9.45705 3.93832 9.45705C5.22519 9.45705 6.28933 8.39291 6.28933 7.10604C6.28933 6.83382 6.51206 6.61109 6.78428 6.61109C7.0565 6.61109 7.27923 6.83382 7.27923 7.10604C7.27923 8.93735 5.76963 10.4469 3.93832 10.4469V10.4469Z"
        fill="currentColor"
      />
      <path
        d="M9.63024 10.4469C8.93731 10.4469 8.26913 10.249 7.72469 9.85301C7.50196 9.70452 7.45247 9.38281 7.60095 9.16008C7.74944 8.93735 8.07115 8.88786 8.29388 9.03634C8.68984 9.30856 9.16004 9.45705 9.63024 9.45705C10.9171 9.45705 11.9812 8.39291 11.9812 7.10604C11.9812 5.81917 10.9171 4.75503 9.63024 4.75503C8.34337 4.75503 7.27923 5.81917 7.27923 7.10604C7.27923 7.37826 7.05651 7.60099 6.78429 7.60099C6.51206 7.60099 6.28934 7.37826 6.28934 7.10604C6.28934 5.27473 7.79893 3.76514 9.63024 3.76514C11.4616 3.76514 12.9711 5.24998 12.9711 7.10604C12.9711 8.93735 11.4616 10.4469 9.63024 10.4469Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_70_13859">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ProfessionalTier;
