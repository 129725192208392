import { Select as AntdSelect } from "antd";
import type { RefSelectProps, SelectProps as AntdSelectProps } from "antd/lib/select";
import _ from "lodash";
import React from "react";
import { DownOutlined } from "@/components/SVGIcons";

import "./index.module.scss";

interface RenderLabelFunc {
  (value?: any): string;
}

export interface SelectProps extends AntdSelectProps {
  renderLabel?: RenderLabelFunc;
}

const OriginSelect = React.forwardRef(
  ({ value, options, mode, renderLabel, className, ...props }: SelectProps, ref: React.Ref<RefSelectProps>) => {
    if (
      value !== undefined &&
      _.isFunction(renderLabel) &&
      _.isArray(options) &&
      !options.find(o => (_.isObject(o) ? o.value === value : o === value))
    ) {
      value = renderLabel(value);
    }

    return (
      <AntdSelect
        ref={ref}
        value={value}
        className={`selectPrefixA selectPrefixB ${className || ""}`}
        suffixIcon={<DownOutlined />}
        options={options}
        mode={mode}
        popupClassName={`selectPrefixA selectPrefixB ${mode === "multiple" ? "multipleSelectPopup" : "selectPopup"}`}
        {...props}
      />
    );
  }
);

const Select = OriginSelect as unknown as ((props: React.PropsWithChildren<SelectProps>) => React.ReactElement) & {
  Option: typeof AntdSelect.Option;
  OptGroup: typeof AntdSelect.OptGroup;
};

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;

export default Select;
