export default {
  deleteMyObservationConfirmText: "Do you want to delete: [Home - My Observation] that is being used?",
  pinboard: "Pinboard",
  delete: "Delete",
  deleteConfirmText: "Are you sure you want to delete?",
  deleteContentInRecycleBinTip: "Deleted content will be sent to the recycle bin and will be automatically deleted after 30 days.",
  deleteCanNotUseTip: "After deleting, others will not be able to use this report.",
  remove: "Remove",
  removeConfirmText: "Are you sure you want to remove?",
  removeReapplyAuthTip: "After removal, you need to reapply for permission.",
  removeContentInvisibleTip: "After removal, this content will not be visible in this account.",
  confirm: "Confirm",
  cancel: "Cancel",
};
