import admin from "./admin";
import Appearance from "./Appearance";
import Attribution from "./Attribution";
import Discover from "./Discover";
import ForgetPassword from "./ForgetPassword";
import home from "./home";
import InitDemo from "./InitDemo";
import Language from "./Language";
import Launch from "./Launch";
import Login from "./Login";
import Manage from "./Manage";
import Message from "./Message";
import my from "./My";
import Pinboard from "./Pinboard";
import Settings from "./Settings";
import Workspaces from "./Workspaces";
import WPSUpdate from "./WPSUpdate";

export default {
  home,
  admin,
  my,
  Pinboard,
  Discover,
  Login,
  ForgetPassword,
  Message,
  Language,
  Workspaces,
  Appearance,
  Settings,
  WPSUpdate,
  InitDemo,
  Manage,
  Attribution,
  Launch,
};
