export default {
  findOrCreateColumnNames: "查找或创建列名称",
  pleaseFillInTheFieldName: "请填写字段名称",
  uploadThisColumn: "上传本列",
  pleaseSelectADataType: "请选择数据类型",
  theNameOfTheDataTableIs: "上传至系统的数据表名为",
  pleaseFillInTheNameOfTheDataTable: "请填写数据表名称",
  theFirstRowIsTheTitleRow: "表格首行是标题行",
  dataTableHint:
    "每一列为一个字段，您可以选择需要上传的字段、修改各个字段的字段名以及原始数据类型。原始数据类型将会影响数据处理和展示方式。",
};
