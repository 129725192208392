export default {
  22000: "Data anomaly",
  22001: "String length exceeds limit",
  22002: "NULL value cannot be used as a measure",
  22003: "Numeric value out of range",
  22004: "NULL value not allowed",
  22005: "Error occurred during assignment",
  22007: "Illegal date-time format",
  22008: "Date-time field overflow",
  22009: "Illegal time zone display value",
  22012: "Divide by zero",
  22013: "Invalid range",
  22015: "Internal field overflow",
  22018: "Illegal character value during type conversion",
  22020: "Illegal limit value",
  22021: "Character not within prepared range",
  22022: "Measure overflow",
  22023: "Illegal parameter value",
  22026: "String data length does not match",
  23000: "Function executed without return statement",
  23001: "Violation of limit",
  23502: "Violation of non-null constraint",
  23503: "Violation of foreign key constraint",
  23505: "Violation of unique constraint",
  23514: "Violation of check constraint",
  24000: "Illegal cursor state",
  25000: "Illegal transaction state",
  25006: "Read-only SQL transaction",
  26000: "Illegal SQL statement name",
  39000: "Exception during external procedure call",
  39004: "Null value not allowed",
  40000: "Transaction rollback",
  40002: "Violation of transaction integrity constraint",
  42000: "Syntax error or violation of access rules",
  42501: "Insufficient privileges",
  42601: "Syntax error",
  42602: "Illegal name",
  42611: "Illegal field definition",
  42622: "Name too long",
  42701: "Duplicate field",
  42702: "Ambiguous field",
  42703: "Undefined field",
  42704: "Undefined object",
  42710: "Duplicate object",
  42712: "Duplicate alias",
  42803: "Grouping error",
  42804: "Data type mismatch",
  42830: "Illegal foreign key",
  42883: "Undefined function",
  44000: "Violation of WITH CHECK option",
  53000: "Insufficient resources",
  53100: "Disk full",
  53200: "Out of memory",
  53300: "Too many connections",
  54000: "Exceeded program limit",
  54001: "Statement too complex",
  54011: "Too many fields",
  54023: "Too many parameters",
  "08000": "Connection exception",
  "08001": "SQL client unable to establish SQL connection",
  "08003": "Connection does not exist",
  "08004": "SQL server refused to establish SQL connection",
  "08006": "Connection failed",
  "08007": "Unknown transaction resolution",
  "08P01": "Violation of protocol",
  "09000": "Triggered action exception",
  "2200B": "Escape character conflict",
  "2200F": "Zero-length string",
  "2200G": "Most relevant type does not match",
  "2201B": "Illegal regular expression",
  "2201E": "Illegal parameter for logarithmic function",
  "2201F": "Illegal parameter for exponential function",
  "2202E": "Array subscript error",
  "22P01": "Floating-point exception",
  "22P02": "Illegal text representation",
  "22P03": "Illegal binary representation",
  "2F000": "SQL procedure exception",
  "2F005": "Function executed without return statement",
  "39P01": "Violation of trigger protocol",
  "3D000": "Illegal database name",
  "3F000": "Illegal schema name",
  "42P01": "Undefined table",
  "42P02": "Undefined parameter",
  "42P04": "Duplicate database",
  "42P05": "Duplicate prepared statement",
  "42P07": "Duplicate table",
  "42P08": "Ambiguous parameter",
  "42P10": "Illegal field reference",
  "42P11": "Illegal cursor definition",
  "42P12": "Illegal database definition",
  "42P16": "Illegal table definition",
  "42P17": "Illegal object definition",
  "42P18": "Indeterminate data type",
  "58P02": "Duplicate file",
  P0001: "Throw exception",
  XX000: "Internal error",
  XX001: "Data corruption",
  XX002: "Index corruption",
};
