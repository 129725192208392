import React from "react";

const EditFeedback = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M15.75 16.5H2.25C1.9425 16.5 1.6875 16.245 1.6875 15.9375C1.6875 15.63 1.9425 15.375 2.25 15.375H15.75C16.0575 15.375 16.3125 15.63 16.3125 15.9375C16.3125 16.245 16.0575 16.5 15.75 16.5Z"
      fill="currentColor"
      fillOpacity="0.45"
    />
    <path
      opacity="0.4"
      d="M14.2647 2.61003C12.8097 1.15503 11.3847 1.11753 9.89219 2.61003L8.98469 3.51753C8.90969 3.59253 8.87969 3.71253 8.90969 3.81753C9.47969 5.80503 11.0697 7.39503 13.0572 7.96503C13.0872 7.97253 13.1172 7.98003 13.1472 7.98003C13.2297 7.98003 13.3047 7.95003 13.3647 7.89003L14.2647 6.98253C15.0072 6.24753 15.3672 5.53503 15.3672 4.81503C15.3747 4.07253 15.0147 3.35253 14.2647 2.61003Z"
      fill="currentColor"
      fillOpacity="0.45"
    />
    <path
      d="M11.7077 8.64754C11.4902 8.54254 11.2802 8.43754 11.0777 8.31754C10.9127 8.22004 10.7552 8.11504 10.5977 8.00254C10.4702 7.92004 10.3202 7.80004 10.1777 7.68004C10.1627 7.67254 10.1102 7.62754 10.0502 7.56754C9.80274 7.35754 9.52524 7.08754 9.27774 6.78754C9.25524 6.77254 9.21774 6.72004 9.16524 6.65254C9.09024 6.56254 8.96274 6.41254 8.85024 6.24004C8.76024 6.12754 8.65524 5.96254 8.55774 5.79754C8.43774 5.59504 8.33274 5.39254 8.22774 5.18254C8.12274 4.95754 8.04024 4.74004 7.96524 4.53754L3.25524 9.24754C3.15774 9.34504 3.06774 9.53254 3.04524 9.66004L2.64024 12.5325C2.56524 13.0425 2.70774 13.5225 3.02274 13.845C3.29274 14.1075 3.66774 14.25 4.07274 14.25C4.16274 14.25 4.25274 14.2425 4.34274 14.2275L7.22274 13.8225C7.35774 13.8 7.54524 13.71 7.63524 13.6125L12.3452 8.90254C12.1352 8.82754 11.9327 8.74504 11.7077 8.64754Z"
      fill="currentColor"
      fillOpacity="0.45"
    />
  </svg>
);

export default EditFeedback;
