export default {
  progressDrawerTitle: "任务进程",
  inProgressTasks: "当前队列",
  historyTaskText: "历史任务",
  historyFormerText: "仅保留最近",
  historyLatterText: "条",
  comma: "，",
  syncConfiguration: "配置任务",
  uploadDataSource: "上传数据源文件任务",
  generateFuzzyDict: "模糊词典构建任务",
  syncKeywords: "关键词同步任务",
  constructDict: "分词词典构建任务",
  updateDatabase: "数据库表同步任务",
  inProgressFormerText: "进行中（",
  inProgressLatterText: "）",
  failureFormerText: "失败原因：",
  constructSuccessLatterText: "个关键词（包含同义词）",
  syncEmptyText: "未检测到任何需要同步的维度值",
  syncSuccessFormerText: "已成功同步",
  syncSuccessLatterText: "个维度值",
  syncFailureFormerText: "失败",
  syncFailureLatterText: "个维度",
  newSyncSuccessText1: "已成功同步",
  newSyncSuccessText2: "个维度的",
  newSyncSuccessText3: "个维度值（不含重复）",
  newSyncFailText1: "失败",
  newSyncFailText2: "个维度",
  newSyncNoNeedText: "无需要同步的维度值",
  updateSuccessFormerText: "成功同步",
  updateSuccessLatterText: "个数据表",
  abortTaskConfirmTitle: "确认终止当前任务？",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  abortSuccessLatterText: "已终止",
  startedAt: "开始时间",
  updatedAt: "结束时间",
  noTasksTip: "无历史任务",
  noTasksInProgressTip: "当前无队列",
  checkDetailButtonText: "查看详情",
  KeywordModalTitle: "同步失败维度",
  KeywordModalTitleTotalBefore: "共",
  KeywordModalTitleTotalAfter: "个",
  KeywordModalColumnName: "维度名",
  KeywordModalColumnTable: "数据表名",
  KeywordModalColumnError: "失败原因",
  syncKeywordsDetailsTitle: "关键词同步详情",
  syncKeywordsDetailsText1: "共成功",
  syncKeywordsDetailsText2: "个，失败",
  syncKeywordsDetailsText3: "个",
  syncKeywordsSuccessTab: "成功维度",
  syncKeywordsFailTab: "失败维度",
  KeywordModalColumnDimensionName: "维度名",
  KeywordModalColumnModelName: "所在模型",
  KeywordModalColumnErrorDetailName: "详细信息",
  KeywordModalColumnSuccessNumName: "数量",
  dbErrorText: "失败原因：",
  updateDBFailFormerText: "失败",
  updateDBFailLatterText: "个数据库",
  dbImpalaModalTitle: "表查询失败",
  dbImpalaModalTotalBefore: "共",
  dbImpalaModalTotalAfter: "个",
  dbImpalaModalColumnDbError: "失败原因",
  dbImpalaModalColumnDatabase: "数据库名",
  dbErrorInfoImpalaTitle: "库查询失败",
  dbErrorInfoElseTitle: "表查询失败",
  syncConfigurationTaskPending: "排队中",
  syncConfigurationTaskStarted: "进行中",
  syncConfigurationTaskSuccess: "配置成功",
  syncConfigurationTaskDownloadFile: "下载文件中",
  syncConfigurationTaskLoadFile: "正在导入配置",
  syncConfigurationFailedByLackOfFileKey: "缺少文件 key",
  syncConfigurationFailedByNoSections: "未选择配置项",
  syncConfigurationFailedByHasRunningTask: "存在进行中的任务",
  syncConfigurationFailedByDownloadFail: "下载配置文件失败",
  syncConfigurationFailedByLoadFail: "导入配置失败",
};
