export default {
  30101: "The keyword was not found in the dimension keyword dictionary",
  30102: "This keyword already has synonyms, please search in the table and edit directly",
  30103: "No valid synonym",
  30201: "Synonyms do not exist",
  30301: "Error importing data from file",
  30401: "Unknown network error",
  30501: "Invalid synonym download link",
  30502: "This workspace is invalid",
  30503: "This link is out of date",
};
