export default {
  dimension: "维度",
  timeDimension: "时间维度",
  measure: "指标",
  analysis: "分析",
  noResultFound: "查找无结果，请更换关键字。",
  noDimensionResult: "暂未添加维度，如有需要请联系工作区管理员。",
  noTimeSeriesResult: "暂未添加时间维度，如有需要请联系工作区管理员。",
  noMeasureResult: "暂未添加指标，如有需要请联系工作区管理员。",
  loadDataEnd: "没有更多数据了",
  searchingTip: "加载中，请稍候...",
  showMore: "查看更多",
  searchInputPlaceholder: "输入关键词查找",
  noDimensionMatched: "未找到对应维度，请尝试其他关键词。",
  noMeasureMatched: "未找到对应指标，请尝试其他关键词。",
  deleteToastPrefix: "已将",
  deleteToastSuffix: "从筛选条件中删除",
  batchInputBackBtnText: "返回",
  batchInputBtnText: "批量输入",
  data: "数据",
  applySuccessToast: "筛选已应用",
  addSuccessToast: "已添加",
  feedbackPartTitle: "语义校准规则",
  customDatasetPartTitle: "自定义数据集",
  internalPartTitle: "内置",
  filterTitle: "想了解更多可搜索的内容？",
  filterContent1: "筛选面板能够帮助你！",
  filterContent2: "除此之外，还能进行对数据更复杂的筛选~",
  nextBtnText: "如何使用",
  filterWord: "点击该行可添加字段至搜索框",
  filterIcon: "光标悬浮至此，点击按钮还能进行对数据更复杂的筛选~",
  goDocBtnText: "查看说明书",
  onBtnText: "好的",
  loadMoreButtonText: "加载更多",
  loadingBtnText: "加载中...",
};
