import { getLocaleText } from "@/utils/locale";

const getText = (id: string) => getLocaleText(`components.Constants.SystemStatusType.${id}`);

export default class SystemStatusType {
  static TIMEZONE_NOW = "timezoneNow"; // 系统时间

  static LAST_DEBOUNCE_TASK_EXECUTOR_BEAT = "lastDebounceTaskExecutorBeat"; // 最后一次防抖任务执行时间

  static DB_MAX_CONNECTIONS = "dbMaxConnections"; // 数据库最大连接数

  static OBJECT_STORAGE_SERVICE = "oss"; // 对象存储服务

  static WS_SERVICE = "ws"; // 分词服务

  static CLICKHOUSE = "clickhouse"; // 数据仓库服务

  static DWZ = "dwz"; // 短网址服务

  static ASR = "asr"; // 语音服务

  static INPUT = "input"; // 访问 IP

  static OUTPUT = "output"; // 出口 IP

  static SMS = "sms"; // 短信服务

  static WORKER_QUEUE_LEN = "cql"; // 任务队列长度

  static QUERY_QUEUE_LEN = "qql"; // 查询队列长度

  static SUPPORTED_HTTP_METHODS = "supportedHTTPMethods"; // 支持的 HTTP 请求

  static HTTP_METHODS = ["GET", "POST", "PATCH", "PUT", "DELETE"];

  static SYSTEM_STATUS_ITEMS = [
    { key: this.TIMEZONE_NOW, label: getText("systemTime"), anonymous: true },
    {
      key: this.LAST_DEBOUNCE_TASK_EXECUTOR_BEAT,
      label: getText("lastDebounceTaskExecutorBeat"),
      anonymous: true,
    },
    { key: this.INPUT, label: getText("inputIP"), anonymous: true },
    { key: this.OUTPUT, label: getText("outputIP"), anonymous: false },
    { key: this.SUPPORTED_HTTP_METHODS, label: getText("supportedHTTPMethods"), anonymous: true },
    { key: this.DB_MAX_CONNECTIONS, label: getText("databaseMaxConnections"), anonymous: false },
    { key: this.OBJECT_STORAGE_SERVICE, label: getText("objectStorageService"), anonymous: true },
    { key: this.WS_SERVICE, label: getText("wordSegmentationService"), anonymous: true },
    { key: this.CLICKHOUSE, label: getText("dataWarehouseService"), anonymous: true },
    { key: this.DWZ, label: getText("dwzService"), anonymous: true },
    { key: this.ASR, label: getText("AutomaticSpeechRecognitionService"), anonymous: true },
    { key: this.WORKER_QUEUE_LEN, label: getText("workerQueueLength"), anonymous: true },
    { key: this.QUERY_QUEUE_LEN, label: getText("queryQueueLength"), anonymous: true },
  ];

  static getLabel = (key: string) => {
    if (key === this.TIMEZONE_NOW) return getText("systemTime");
    if (key === this.LAST_DEBOUNCE_TASK_EXECUTOR_BEAT) return getText("lastDebounceTaskExecutorBeat");
    if (key === this.INPUT) return getText("inputIP");
    if (key === this.OUTPUT) return getText("outputIP");
    if (key === this.SUPPORTED_HTTP_METHODS) return getText("supportedHTTPMethods");
    if (key === this.DB_MAX_CONNECTIONS) return getText("databaseMaxConnections");
    if (key === this.OBJECT_STORAGE_SERVICE) return getText("objectStorageService");
    if (key === this.WS_SERVICE) return getText("wordSegmentationService");
    if (key === this.CLICKHOUSE) return getText("dataWarehouseService");
    if (key === this.DWZ) return getText("dwzService");
    if (key === this.ASR) return getText("AutomaticSpeechRecognitionService");
    if (key === this.SMS) return getText("smsService");
    if (key === this.WORKER_QUEUE_LEN) return getText("workerQueueLength");
    if (key === this.QUERY_QUEUE_LEN) return getText("queryQueueLength");
    return getText("otherService");
  };
}
