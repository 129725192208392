export default {
  dateRangeLabel: "In time range",
  dateRangeHelp: "The time range where dimension values that can be directly retrieved by the algorithm are located",
  dateRangeInputPlaceholder: "Please enter time range, e.g. 2021-2022/last 3 months",
  idLabel: "Limiting measures",
  idHelp: "Used to limit the number of dimension values that can be directly retrieved by the algorithm",
  idSelectPlaceholder: "Please select the measure to add",
  ascending: "By ascending order",
  descending: "By descending order",
  limitLabel: "Select number of keywords",
  limitHelp: "The maximum number of dimension values that can be directly retrieved by the algorithm under the above conditions",
  limitInputPlaceholder: "Please enter an integer less than 100000",
  setConstraintsModalTitle: "Add limitations",
  confirm: "Confirm",
  cancel: "Cancel",
  keywordInputPlaceholder: "Search",
  showMoreBtnText: "More",
  changeInputPlaceholder: "Enter your modification",
  reply: "Reply",
  comment: "Comment",
  commentInputPlaceholder: "How do you like this result?",
  maxStrLengthToast: "The length of each line of string cannot exceed",
  maxNumToast: "The total number of values participating in sorting cannot exceed",
  setCustomOrder: "Set custom ranking",
  currentSetting: "Current Setting",
  clearConfirmInfo: "Confirm clearing the current sorting",
  clearOver: "Clear",
  noCustomSortTip: "There is currently no custom display order",
  save: "Save",
  loadMore: "Load More",
  uploading: "Uploading...",
  newSetAlert: "The search results will be displayed in this order by default",
  multipleUpload: "Multiple Upload",
  downloadConfigBtnText: "Download existing configuration",
  selectFileToUpload: "Please click to select a file or drag the file here",
  selectFileToUploadAgain: "Select file to upload again",
  example: "Example:",
  yourSearch: "Your search",
  fromDataSource: "Model:",
  customField: "Custom fields",
  cumulative: "Cumulative",
  timeGroup: "Time Group",
  customOrder: "Custom Order",
  searchSQL: "SQL",
  explanationTipTitle: "Why are you seeing this card",
  setSuccessToast: "Set successfully",
  allUsers: "All users",
  loadMoreUsers: "Load more users",
  allGroups: "All groups",
  loadMoreGroups: "Load more groups",
  userSearchInputPlaceholder: "Search users and user groups",
  selectAllUsers: "Select all users and user groups in workspace",
  notSelect: "Unselect",
  select: "Select",
  firstSearchHint: "There are ",
  secondSearchHint: " users",
  thirdSearchHint: " groups contain ",
  fourthSearchHint: " groups",
  allowAllUsersCoop: "Allow all user and user groups to collaborate",
  clear: "Clear",
  next: "Next",
  user: "User",
  group: "Group",
  allowEdit: "Allow edit",
  backToSelectBtnText: "Back to select",
  collaborateBtnText: "Collaborate",
  selectCollaborators: "Select collaborator",
  remove: "Remove",
  coopUser: "Collaborate users",
  editable: "Editable",
  coopGroup: "Collaborate groups",
  selectAll: "Select all",
  clearSelect: "Clear selected",
  manageCoop: "Manage collaboration",
  manage: "Manage",
  batchRemove: "Batch remove",
  complete: "Complete",
  measurePrefix: "Measures [",
  measureSuffix: "]",
  joinText: "The",
  maxNumSuffix: "One.",
  period: ". ",
  downloadFileNameSuffix: "Dimension custom sort.txt",
  example1: "Beijing",
  example2: "Shanghai",
  example3: "Guangzhou",
  dataRange: "Data range",
  filter: "Filter",
  calcTitle: "Calculation logic",
  calcExtra: "The data range will be involved in the actual calculation.",
  calc: "Calc",
  aggregation: "Aggregation",
  other: "Other",
  sortTitle: "Default sort",
  sort: "Sort",
  desc: "DESC",
};
