export default {
  uploadToDBErrorMsgCode2: "Failed to acquire lock",
  uploadToDBErrorMsgCode3: "File format error",
  uploadToDBErrorMsgCode4: "File not found",
  uploadToDBErrorMsgCode5: "Failed to download data file",
  uploadToDBErrorMsgCode6: "File encoding format error",
  uploadToDBErrorMsgCode7: "Failed to read file",
  uploadToDBErrorMsgCode8: "Data not passed",
  uploadToDBErrorMsgCode9: "No afterTable specified for data table name",
  uploadToDBErrorMsgCode10: "Table name contains special characters or exceeds length limit, maximum 63 characters",
  uploadToDBErrorMsgCode11: "Duplicate data table name",
  uploadToDBErrorMsgCode12: "Column name contains special characters or exceeds length limit, maximum 63 characters",
  uploadToDBErrorMsgCode13: "Data column type is empty",
  uploadToDBErrorMsgCode14: "Data column type error",
  uploadToDBErrorMsgCode15: "Selected sheet is empty",
  uploadToDBErrorMsgCode16: "Data file is empty",
  uploadToDBErrorMsgCode17: "Database connection failed",
  uploadToDBErrorMsgCode19: "Data overflow",
  uploadToDBErrorMsgCode20: "Failed to insert into database",
  uploadToDBErrorMsgCodeDefault: "Abnormal termination",
  uploadPreviewDataErrorMsgCode1: "Failed to download file",
  uploadPreviewDataErrorMsgCode2: "File parsing failed",
  uploadPreviewDataErrorMsgCode3: "Uploaded file contains incorrect content",
  uploadPreviewDataErrorMsgCode4: "Failed to process file",
  uploadPreviewDataErrorMsgCode5: "Uploaded file is empty",
};
