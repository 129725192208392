export default {
  addTaskModalTitle: "Add synchronization task",
  addTaskTip: "The system will automatically perform keyword synchronization tasks at the set time",
  confirmBtnText: "Confirm",
  simpleCycle: "Simple cycle",
  complexCycle: "Complex Cycle",
  dayCycle: "Cycle by date",
  weekCycle: "Cycle by week",
  selectMonthPrefix: "Every",
  monthSuffix: "Month",
  daySuffix: "Day",
  hourSuffix: "Hour",
  minuteSuffix: "Minute",
};
