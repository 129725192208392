export default {
  beforeMapping: "Before mapping",
  afterMapping: "After mapping",
  dataType: "Data type",
  dataTypeTip: "Must be consistent with the data column type, please change with caution.",
  selectMapping: "Specify mapping relationship",
  numRepeat: "Value duplicate",
  columnInputPlaceholder: "Please fill in the content contained in this column",
  mappingInputPlaceholder: "Please fill in the mapped content",
  addOneOptionBtnText: "Add 1 option",
  optionDataInputPlaceholder: "Please write a JSON with the option data type and option value",
  inputMappingDataText: "Please select the data type before and after mapping and fill in the content before and after mapping",
  manual: "Manual input",
  json: "Write to JSON",
};
