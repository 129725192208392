export default {
  model: "Model",
  modelListTip: "Searchable data in the workspace depends on the model created by the page",
  deletedTableTip: "Deleted custom table",
  customTable: "Custom Table",
  tableNameAndSqlCannotBeEmpty: "Table name and sql cannot be empty",
  successfullyCreatedCustomTable: "Custom table created successfully",
  successfullyChangedCustomTable: "Custom table changed successfully",
  confirm: "Confirm",
  exitingEditing: "Exiting editing, this modification will not be saved",
  pleaseEnterCustomTableName: "Please enter the name of the custom table",
  cancel: "Cancel",
  save: "Save",
  confirmNew: "Confirm new",
  sqlEditor: "SQL Editor",
  pleaseTryRunningSuccessfullyFirst: "Please try running successfully before confirming the new creation",
  underExecution: "In progress",
  trialSQL: "Test run SQL",
  theDraftHasBeenDeleted: "This draft has been deleted",
  successfullyDeleted: "Delete successfully",
  tableName: "Table Name",
  operation: "Settings",
  edit: "Edit",
  preview: "Preview",
  areYouSureToDeleteThisCustomTable: "Confirm to delete this custom table?",
  delete: "Delete",
  download: "Download",
  pleaseEnterTableName: "Please enter the name of the table you want to search",
  areYouSureToDeleteThisDataTable: "Are you sure to delete this data table?",
  appliedToTheModel1: "This data table has been applied to the model ",
  updateButtonText: "Update",
  appliedToTheModel2:
    "After deletion, the tables in the corresponding model will also be deleted, which may cause the model to become invalid.",
  uploadFiles: "Upload Files",
  uploadSuccessToast: "File uploaded successfully",
  internalTableEmptyDataListText: "No data table available, please upload a file",
  emptyDataListText: "No data table available, or you can choose to synchronize data table to update data.",
  analyzeFail: "File parsing error",
  fileParsing: "File parsing...",
  copySuccessToast: "Copied successfully",
  copyFailToast: "Copy failed, please try again later",
  runResultsTabs: "Results",
  previewTabs: "Preview",
  copyTooltip: "Copy",
  searchTablePlaceholder: "Search data table",
  ViewAIAnalysisBtn: "AI interprets errors",
  shrinkBtn: "Shrink",
  expansionBtn: "Expand",
  aiAnalysisTitle: "AI misinterpretation",
  renameTitle: "Data columns need to be renamed",
  runErrorTitle: "An error occurred during the test run",
};
