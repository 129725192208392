export default {
  correct: "正确",
  normal: "正常",
  error: "错误",
  item: "项目",
  status: "状态",
  systemTime: "系统时间",
  lastDebounceTaskExecutorBeat: "防抖任务最近启动时间",
  databaseMaxConnections: "数据库最大连接数",
  objectStorageService: "对象存储服务",
  wordSegmentationService: "分词服务",
  dataWarehouseService: "数据仓库服务",
  dwzService: "短网址服务",
  AutomaticSpeechRecognitionService: "语音服务",
  debounceTaskWarningText: "最近的防抖任务启动时间，与当前时间差距>1分钟，心跳服务或者任务服务存在异常",
  dispatchTaskErrorText: "调度服务未启动",
  databaseConnectionsWarningText: "连接数设置太少，可能导致服务发生错误。建议设为1000",
  serviceUnavailable: "服务不可用",
  noConfig: "未配置",
  systemTimeErrorTextFormer: "浏览器与搭载系统的服务器时间偏差",
  systemTimeErrorTextLatter: "可能导致定时任务的功能及记录异常",
  hour: "小时",
  minute: "分",
  second: "秒",
  inputIP: "当前访问 IP",
  outputIP: "服务出口 IP",
  supportedHTTPMethods: "支持的 HTTP 请求",
  reset: "重置",
  resetting: "重置中",
  checking: "检测中",
  configErrorText: "配置错误，当前网络环境无法连接对象存储服务",
  smsService: "短信服务",
  workerQueueLength: "任务队列长度",
  queryQueueLength: "查询队列长度",
  queueBusy: "队列繁忙",
  queueBlock: "队列阻塞",
  otherService: "其他服务",
};
