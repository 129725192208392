import React from "react";

const PinboardMoreIcon = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 8.33301C9.67036 8.33301 9.34813 8.43076 9.07405 8.61389C8.79997 8.79703 8.58635 9.05733 8.4602 9.36187C8.33406 9.66641 8.30105 10.0015 8.36536 10.3248C8.42967 10.6481 8.5884 10.9451 8.82149 11.1782C9.05458 11.4113 9.35155 11.57 9.67485 11.6343C9.99815 11.6986 10.3333 11.6656 10.6378 11.5395C10.9423 11.4133 11.2026 11.1997 11.3858 10.9256C11.5689 10.6515 11.6667 10.3293 11.6667 9.99968C11.6667 9.55765 11.4911 9.13372 11.1785 8.82116C10.866 8.5086 10.442 8.33301 10 8.33301ZM4.16667 8.33301C3.83703 8.33301 3.5148 8.43076 3.24072 8.61389C2.96663 8.79703 2.75301 9.05733 2.62687 9.36187C2.50072 9.66641 2.46772 10.0015 2.53203 10.3248C2.59633 10.6481 2.75507 10.9451 2.98816 11.1782C3.22124 11.4113 3.51822 11.57 3.84152 11.6343C4.16482 11.6986 4.49993 11.6656 4.80447 11.5395C5.10902 11.4133 5.36931 11.1997 5.55245 10.9256C5.73559 10.6515 5.83333 10.3293 5.83333 9.99968C5.83333 9.55765 5.65774 9.13372 5.34518 8.82116C5.03262 8.5086 4.6087 8.33301 4.16667 8.33301ZM15.8333 8.33301C15.5037 8.33301 15.1815 8.43076 14.9074 8.61389C14.6333 8.79703 14.4197 9.05733 14.2935 9.36187C14.1674 9.66641 14.1344 10.0015 14.1987 10.3248C14.263 10.6481 14.4217 10.9451 14.6548 11.1782C14.8879 11.4113 15.1849 11.57 15.5082 11.6343C15.8315 11.6986 16.1666 11.6656 16.4711 11.5395C16.7757 11.4133 17.036 11.1997 17.2191 10.9256C17.4023 10.6515 17.5 10.3293 17.5 9.99968C17.5 9.55765 17.3244 9.13372 17.0118 8.82116C16.6993 8.5086 16.2754 8.33301 15.8333 8.33301Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default PinboardMoreIcon;
