export default class BulkCreateAccountResult {
  static FILE_TYPE_ERROR = 1;

  static DOWNLOAD_FILE_FAIL = 2;

  static TIER_1_QUOTA_EXCEEDED = 3;

  static TIER_2_QUOTA_EXCEEDED = 4;

  static TIER_3_QUOTA_EXCEEDED = 5;

  static ACCOUNTS_FILE_ERROR = 6;

  static DEAL_ACCOUNTS_FILE_FAIL = 7;

  static ACCOUNTS_FILE_EMPTY = 8;

  static IMPORT_ACCOUNT_FAIL = 9;

  static QUOTA_EXCEEDED = 10;

  static DECODE_FAIL = 11;

  static getText = status => {
    if (status === this.FILE_TYPE_ERROR) return "文件格式错误";
    if (status === this.DOWNLOAD_FILE_FAIL) return "下载文件失败";
    if (status === this.TIER_1_QUOTA_EXCEEDED) return "数据专家配额不足";
    if (status === this.TIER_2_QUOTA_EXCEEDED) return "全民数据科学家配额不足";
    if (status === this.TIER_3_QUOTA_EXCEEDED) return "全民数据探索家配额不足";
    if (status === this.ACCOUNTS_FILE_ERROR) return "请检查文件中列数是否正确，当前文件可能出现列缺失、列过多或存在空列的情况。";
    if (status === this.DEAL_ACCOUNTS_FILE_FAIL) return "处理文件失败";
    if (status === this.ACCOUNTS_FILE_EMPTY) return "上传文件为空";
    if (status === this.IMPORT_ACCOUNT_FAIL) return "创建/更新用户失败";
    if (status === this.QUOTA_EXCEEDED) return "账号配额不足";
    if (status === this.DECODE_FAIL) return "解码失败";
    return "";
  };
}
