export default class UserAgent {
  // https://datarc.feishu.cn/wiki/wikcneQW2nYCmn4RzDPpaC1F1bc

  static MOBILE = 1; // 手机浏览器端，手机浏览器内使用 密码/验证码 登录。

  static DESKTOP = 2; // 桌面浏览器端，桌面浏览器内使用 密码/验证码 登录。

  static OTHERS = 3;

  static getUserAgent = isMobile => (isMobile ? this.MOBILE : this.DESKTOP);
}
