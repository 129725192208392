export default {
  progressDrawerTitle: "Task process",
  inProgressTasks: "Current queue",
  historyTaskText: "History tasks",
  historyFormerText: "Historical tasks (only most recent",
  historyLatterText: "Item",
  comma: ",",
  syncConfiguration: "Configuration task",
  uploadDataSource: "Upload data source task",
  generateFuzzyDict: "Fuzzy dictionary build task",
  syncKeywords: "Keyword synchronization task",
  constructDict: "Dictionary build task",
  updateDatabase: "Database & table synchronization tasks",
  inProgressFormerText: "In progress (",
  inProgressLatterText: ")",
  failureFormerText: "Reason for failure:",
  constructSuccessLatterText: "Keywords (containing synonyms) ",
  syncEmptyText: "No dimension values were detected that need to be synchronized",
  syncSuccessFormerText: "Sync success",
  syncSuccessLatterText: "Dimension values",
  syncFailureFormerText: "Sync",
  syncFailureLatterText: "Dimension failed",
  newSyncSuccessText1: "Sync successful for",
  newSyncSuccessText2: "dimension(s) and",
  newSyncSuccessText3: "unique dimension value(s)",
  newSyncFailText1: "Sync failed for",
  newSyncFailText2: "dimension(s)",
  newSyncNoNeedText: "No dimension values that need to be synchronized.",
  updateSuccessFormerText: "Successful synchronization",
  updateSuccessLatterText: "Data table",
  abortTaskConfirmTitle: "Confirm to terminate the current task?",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  abortSuccessLatterText: "Terminated",
  startedAt: "Start time",
  updatedAt: "End time",
  noTasksTip: "No history tasks",
  noTasksInProgressTip: "Currently no queue",
  checkDetailButtonText: "View Details",
  KeywordModalTitle: "Sync failed dimension",
  KeywordModalTitleTotalBefore: "Total",
  KeywordModalTitleTotalAfter: "A",
  KeywordModalColumnName: "Dimension name",
  KeywordModalColumnTable: "Data table name",
  KeywordModalColumnError: "Reason for failure",
  syncKeywordsDetailsTitle: "Keyword Synchronization Details",
  syncKeywordsDetailsText1: "Total successful:",
  syncKeywordsDetailsText2: "items, failed:",
  syncKeywordsDetailsText3: "items",
  syncKeywordsSuccessTab: "Successful Dimensions",
  syncKeywordsFailTab: "Failed Dimensions",
  KeywordModalColumnDimensionName: "Dimension Name",
  KeywordModalColumnModelName: "Model Name",
  KeywordModalColumnErrorDetailName: "Error Details",
  KeywordModalColumnSuccessNumName: "Quantity",
  dbErrorText: "Reason for failure:",
  updateDBFailFormerText: "Failed",
  updateDBFailLatterText: "Database",
  dbImpalaModalTitle: "Table query failed",
  dbImpalaModalTotalBefore: "Total",
  dbImpalaModalTotalAfter: "A",
  dbImpalaModalColumnDbError: "Reason for failure",
  dbImpalaModalColumnDatabase: "Database name",
  dbErrorInfoImpalaTitle: "Library query failed",
  dbErrorInfoElseTitle: "Table query failed",
  syncConfigurationTaskPending: "Pending",
  syncConfigurationTaskStarted: "Running",
  syncConfigurationTaskSuccess: "Configuration successful",
  syncConfigurationTaskDownloadFile: "Downloading file",
  syncConfigurationTaskLoadFile: "Importing configuration",
  syncConfigurationFailedByLackOfFileKey: "File key missing",
  syncConfigurationFailedByNoSections: "No configuration section selected",
  syncConfigurationFailedByHasRunningTask: "Existing ongoing tasks",
  syncConfigurationFailedByDownloadFail: "Failed to download configuration file",
  syncConfigurationFailedByLoadFail: "Failed to import configuration",
};
