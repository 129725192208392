import React from "react";

const MessageAvatarSystem = props => {
  const { iconBackgroundColor, style } = props;
  return (
    <svg style={style} width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill={iconBackgroundColor} />
      <path
        d="M20.8869 19.1667C20.7802 19.1667 20.6802 19.1334 20.5869 19.0667C20.3669 18.9 20.3202 18.5867 20.4869 18.3667C21.5336 16.9734 21.5336 15.0267 20.4869 13.6334C20.3202 13.4134 20.3669 13.1 20.5869 12.9334C20.8069 12.7667 21.1202 12.8134 21.2869 13.0334C22.6002 14.78 22.6002 17.22 21.2869 18.9667C21.1936 19.1 21.0402 19.1667 20.8869 19.1667Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        d="M19.3866 12.94V19.06C19.3866 20.2066 18.9732 21.0666 18.2332 21.48C17.9332 21.6466 17.5999 21.7266 17.2532 21.7266C16.7199 21.7266 16.1466 21.5466 15.5599 21.18L13.6132 19.96C13.4799 19.88 13.3266 19.8333 13.1732 19.8333H12.5532V12.1666H13.1732C13.3266 12.1666 13.4799 12.12 13.6132 12.04L15.5599 10.82C16.5332 10.2133 17.4866 10.1066 18.2332 10.52C18.9732 10.9333 19.3866 11.7933 19.3866 12.94Z"
        fill="currentColor"
      />
      <path
        d="M12.5535 12.1667V19.8334H12.2202C10.6069 19.8334 9.72021 18.9467 9.72021 17.3334V14.6667C9.72021 13.0534 10.6069 12.1667 12.2202 12.1667H12.5535Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessageAvatarSystem;
