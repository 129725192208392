export default {
  workspaceBasicInfo: "工作区基本信息",
  edit: "编辑",
  deleteWorkspace: "删除工作区",
  workspaceNumber: "工作区编号",
  workspaceName: "工作区名称",
  workspaceNameInputPlaceholder: "请输入工作区名称",
  workspaceOwner: "工作区负责人",
  workspaceOwnerSelectPlaceholder: "请选择工作区负责人",
  ownerEmail: "负责人邮箱",
  workspaceCreationTime: "工作区创建时间",
  configureUpdateTime: "配置更新时间",
  workspaceAdapter: "当前数据源",
  workspaceIntroduction: "工作区介绍",
  hasNotSet: "未设置",
  dailyUpdateToTodayOffset: "每日更新至今天偏移",
  dataUpdateTime: "数据更新时间",
  dataUpdateTimeTip:
    "用户在此记录数据的更新时间，查询“相对时间”时，我们会根据更新时间描述的最新时间进行偏移得到相对时间。例如数据在每日早上6点更新，在2月5日早上6点前搜索的“昨天”会返回2月3日的数据，在早上6点后的“昨天”会返回2月4日数据。",
  inputNumberHelp: "请输入1-60的整数",
  preciseWordSegmentationTimeLimit: "精确分词时间限制",
  preciseWordSegmentationTip: "1.精确分词：搜索问题中的词语和关键词一模一样，才进行解析标记。",
  preciseWordSegmentationTimeLimitTip: "2.精确分词时间限制：系统分析搜索问题时预留给拆分标记精确分词的时间，超过此时间系统会进行报错。",
  preciseWordSegmentationInputPlaceholder: "请输入精确分词时间限制时间",
  fuzzyWordSegmentationTimeLimit: "模糊分词时间限制",
  fuzzyWordSegmentationTip: "1.模糊分词：搜索问题中拆分出的词语和关键词可以进行模糊匹配，如：问题中提到阿迪时，可以模糊匹配到阿迪达斯。",
  fuzzyWordSegmentationTimeLimitTip: "2.模糊分词时间限制：系统分析搜索问题时预留给拆分标记模糊分词的时间，超过此时间系统会进行报错。",
  fuzzyWordSegmentationInputPlaceholder: "请输入模糊分词时间限制时间",
  allowSearchingForFutureData: "允许搜索未来的数据",
  allowSearchingForFutureDataTip: "未来的数据指某些行业/公司录入数据库的计划性数据或预测性数据。关闭后仅能搜索今天及以前的实际数据。",
  allowSearchingForFutureDataExtra: "关闭后仅能搜索今天及以前的实际数据",
  deriveFill: "衍生指标计算空值视为0",
  deriveFillExtra1: "打开时，衍生指标在计算时所用指标 null 值视为 0；",
  deriveFillExtra2: "关闭时，衍生指标在计算时存在 null 值的计算最终结果也视为 null。",
  enableDataBlend: "数据融合",
  enableDataBlendExtra: "打开本开关，用户将可以同时搜索到不同模型中的数据指标",
  dateBeforeContainsToday: "近 N 期包含当期",
  dateBeforeContainsTodayExtra1: "打开时，搜索近2周，结果包含本周；",
  dateBeforeContainsTodayExtra2: "关闭时，结果为本周以前的2周（不含本周）。",
  automaticallyAddUsers: "自动添加用户",
  automaticallyAddUsersExtra: "开启后新添加到系统的用户将自动添加到此工作区",
  fuzzyDimensionValueThreshold: "可触发模糊搜索维度值数量阈值",
  fuzzyDimensionValueThresholdExtra:
    "会按照用户最近三十天搜索情况依次将维度值加入模糊词典直至其数量达到阈值，只有在模糊词典的词才可触发模糊搜索。",
  collaborationBannedAll: "禁止全部用户",
  collaborationAllowOwner: "仅允许负责人",
  collaborationAllowManager: "仅允许负责人和管理员",
  collaborationAllowAll: "允许全部用户",
  collaboration: "协作限制",
  collaborationExtra: "协作包含：将报告分享给指定人或工作区、创建报告模板及为他人创建告警",
  collaborationTooltip:
    "被禁止的用户将不能发起新的报告的分享、创建报告模板和设置自己以外的告警接收者，但对于已被分享的报告或创建的报告模板不会被取消分享或设置为模板状态，对于已经设置的告警仍能进行消息推送",
  open: "开启",
  close: "关闭",
  confirmDeletingWorkspace: "确认删除此工作区？",
  unableToRecoverAfterDeletion: "删除后此工作区的所有资料无法恢复，请谨慎操作",
  confirm: "确认",
  cancel: "取消",
  deleteSuccessToast: "删除成功",
  modifySuccessToast: "修改成功",
  requiredItemsAreNotFilledIn: "有必填项未填写，无法保存",
  save: "保存",
};
