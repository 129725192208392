import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.TrendLine.${id}`);

class TrendLine {
  static LINEAR = "linear";

  static EXPONENTIAL = "exponential";

  static LOGARITHMIC = "logarithmic";

  static POLYNOMIAL = "polynomial";

  static NONE = "none";

  static getOptions = () =>
    [TrendLine.LINEAR, TrendLine.EXPONENTIAL, TrendLine.LOGARITHMIC, TrendLine.POLYNOMIAL, TrendLine.NONE].map(item => ({
      value: item,
      label: getText(item),
    }));

  static getFormula = (type, x, y, parameter) => {
    const invalid = `${getText(type)}${getText("invalid")}`;

    const formatter = (number, needSign = false) => {
      if (number === 0) return "0";

      const result = Math.abs(number) < 0.01 ? number.toExponential(2) : number.toFixed(2);
      if (needSign) return number > 0 ? ` + ${result}` : ` - ${Math.abs(result)}`;
      return result;
    };

    switch (type) {
      case TrendLine.LINEAR: {
        const { gradient, intercept } = parameter;
        if (isNaN(gradient) || isNaN(intercept)) {
          return invalid;
        }
        const gradientStr = formatter(gradient);
        const interceptStr = formatter(intercept, true);
        return `${y} = ${gradientStr} * ${x}${interceptStr}`; // y = a * x + b
      }
      case TrendLine.EXPONENTIAL: {
        const { coefficient, index } = parameter;
        if (isNaN(coefficient) || isNaN(index)) {
          return invalid;
        }
        const coefficientStr = formatter(coefficient);
        const indexStr = formatter(index);

        return `${y} = ${coefficientStr} * e^(${indexStr} * ${x})`; // y = a * e^(b * x)
      }
      case TrendLine.LOGARITHMIC: {
        const { gradient, intercept } = parameter;
        if (isNaN(gradient) || isNaN(intercept)) {
          return invalid;
        }
        const gradientStr = formatter(gradient);
        const interceptStr = formatter(intercept, true);

        return `${y} = ${gradientStr} * ln(${x})${interceptStr}`; // y = a * ln(x) + b
      }
      case TrendLine.POLYNOMIAL: {
        const [c, b, a] = parameter;
        if (isNaN(a) || isNaN(b) || isNaN(c)) {
          return invalid;
        }
        const cStr = formatter(c, true);
        const bStr = formatter(b, true);
        const aStr = formatter(a);

        return `${y} = ${aStr} * ${x}^2${bStr} * ${x}${cStr}`; // y = a * x^2 + b * x + c
      }
      default: {
        return "";
      }
    }
  };
}

export default TrendLine;
