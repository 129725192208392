export default {
  changePasswordTitle: "修改密码",
  oldPassword: "请输入旧密码",
  newPassword: "请输入新密码",
  inputErrorTip: "两次输入密码不一致",
  inputOldPasswordAgain: "再次输入新密码",
  cancelBtn: "取消",
  okBtn: "确定",
  changeSuccessTip: "修改成功",
};
