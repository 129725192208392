import * as React from "react";
import { SVGProps } from "react";

const CloseIconInCardFilterBlock = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.200195" y="0.199997" width="17.6" height="17.6" rx="8.8" fill="currentColor" fillOpacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2945 6.79447C12.5952 6.49376 12.5952 6.00623 12.2945 5.70552C11.9937 5.40482 11.5062 5.40482 11.2055 5.70552L8.99998 7.91105L6.79445 5.70552C6.49375 5.40482 6.00621 5.40482 5.70551 5.70552C5.4048 6.00623 5.4048 6.49376 5.70551 6.79447L7.91104 9L5.70551 11.2055C5.4048 11.5062 5.4048 11.9938 5.70551 12.2945C6.00621 12.5952 6.49375 12.5952 6.79445 12.2945L8.99998 10.0889L11.2055 12.2945C11.5062 12.5952 11.9937 12.5952 12.2945 12.2945C12.5952 11.9938 12.5952 11.5062 12.2945 11.2055L10.0889 9L12.2945 6.79447ZM10.0889 9L8.99998 7.91105L7.91104 9L8.99998 10.0889L10.0889 9Z"
      fill="currentColor"
      fillOpacity="0.45"
    />
    <path d="M10.0889 9L8.99998 7.91105L7.91104 9L8.99998 10.0889L10.0889 9Z" fill="currentColor" fillOpacity="0.45" />
  </svg>
);
export default CloseIconInCardFilterBlock;
