import React from "react";

const ToTop = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="none" />
      <path
        opacity="0.6"
        d="M11.5 29H28.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2867 11.0559C20.1984 11.0199 20.1015 11 20 11C19.7932 11 19.606 11.0824 19.4705 11.2156L12.2193 18.3444C11.9269 18.6319 11.9269 19.0981 12.2193 19.3856C12.5118 19.6731 12.986 19.6731 13.2784 19.3856L19.2511 13.5137V26.2638C19.2511 26.6704 19.5864 27 20 27C20.4136 27 20.7489 26.6704 20.7489 26.2638L20.7489 13.5137L26.7216 19.3856C27.014 19.6731 27.4882 19.6731 27.7807 19.3856C28.0731 19.0981 28.0731 18.6319 27.7807 18.3444L20.5295 11.2156C20.4577 11.1451 20.375 11.0918 20.2867 11.0559Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ToTop;
