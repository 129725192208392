import { getLocaleText } from "@/utils/locale";

const getTextLocale = id => getLocaleText(`components.Constants.Constant.${id}`);

export default class Constant {
  static regexPincode = /^\d{6}$/;

  static unitSeparator = ":";

  static listSeparator = " - ";

  static textDefault = "-";

  // Dialog 全屏的边界条件
  static mobileMaxWidth = 925;

  // 去开头和结尾的逗号和空格，字符串
  static trimCommaStr = ", ";

  static passwordHidePlaceholder = "******";

  static getCommaStr = () => getTextLocale("comma");
}
