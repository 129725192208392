export default {
  keywordUpdatingAlertMessage: "系统正在更新搜索关键词，搜索结果可能会出现误差",
  needSyncKeywordMsg: "关键词有未同步的更新，搜索结果可能存在误差，请联系管理员同步关键词",
  emptyTipPart1: "开始探索数据吧！",
  emptyTipPart2: "在这里",
  emptyTipPart3: "可以精通数据搜索~",
  day: "天",
  week: "周",
  month: "个月",
  quarter: "季度",
  year: "年",
  second: "秒",
  minute: "分",
  hour: "时",
  customTimeRangeTitle: "自定义时间范围",
  former: "前",
  to: "至",
  apply: "应用",
  between: "在...之间",
  now: "现在",
  inputInteger: "请输入整数",
  defaultSearchPromptConfirmLabel: "是否将该提示设置为默认搜索提示？",
  formerChangeSearchPromptConfirmTip: "我们发现您多次手动切换了搜索提示框。您也可前往",
  changeSearchPromptLinkText: "搜索提示设置",
  latterChangeSearchPromptConfirmTip: "，依您的个人习惯设置搜索提示。",
  noPromptNext: "下次不再提示",
  setAsDefault: "设置为默认",
  notNeed: "暂时不用",
  saveSuccessToast: "保存成功",
  searchHistory: "搜索历史",
  topSearch: "热门搜索",
  noFieldTip: "找不到对应字段",
  checkAllFields: "查看所有字段",
  keyboardOperation: "键盘操作",
  switchToQuestionPrompt: "切换到问题提示",
  switchToFieldPrompt: "切换到字段提示",
  microphoneErrorToast: "无法打开麦克风。异常信息:",
  loadingText: "请稍候...",
  listening: "正在收听",
  addIntro: "添加简介",
  pinboard: "报告",
  externalResource: "外部资源",
  changeProjectBtnText: "切换工作区",
  unConfigReadyErrorTip: "工作区尚未完成配置，暂不支持搜索，您可尝试",
  noDataPermissionTip: "当前工作区无数据权限，您可联系管理员或尝试",
  noModelErrorTip: "该工作区内暂无有效模型，请联系管理员。您可尝试",
  segmentServiceUnReadyErrorTip: "分词服务未配置，暂时无法搜索数据。请尝试联系运维或部署人员帮助解决，或",
  segmentServiceConnectFailErrorTip: "分词服务连接失败，暂时无法搜索数据。请尝试联系运维或部署人员帮助解决，或",
  segmentServiceBuildingErrorTip: "分词字典构建中，暂时无法搜索数据。请联系工作区负责人或者管理员同步搜索词，或",
};
