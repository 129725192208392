export default {
  dimension: "Abc",
  timeDimension: "Time",
  measure: "123",
  analysis: "Analytics",
  noResultFound: "No result found, please update keywords",
  noDimensionResult: "No Dimension added in workspace, please contact workspace admin",
  loadDataEnd: "No more data",
  searchingTip: "Loading, please wait",
  showMore: "Show more",
  searchInputPlaceholder: "Search",
  noDimensionMatched: "No matched dimension, please update keyword",
  noMeasureMatched: "No matched measure, please update keyword",
  noTimeSeriesResult: "No time dimensions, please contact workspace admin to add.",
  noMeasureResult: "No measures, please contact workspace admin to add",
  deleteToastPrefix: "Removed ",
  deleteToastSuffix: " from filters",
  batchInputBackBtnText: "Return",
  batchInputBtnText: "Batch input",
  data: "Data",
  applySuccessToast: "Filter applied",
  addSuccessToast: "Added",
  feedbackPartTitle: "Semantic Rules",
  customDatasetPartTitle: "Custom Dataset",
  internalPartTitle: "Built-in Keywords",
  filterTitle: "Want to learn more about searchable content?",
  filterContent1: "The filter panel can help you!",
  filterContent2: "In addition, more complex filtering of data can be performed~~",
  nextBtnText: "how to use",
  filterWord: "Click on the row to add the field to the search box",
  filterIcon: "The cursor hovers here, click the button to perform more complex filtering of the data~",
  goDocBtnText: "View manual",
  onBtnText: "OK",
  loadMoreButtonText: "Load More",
  loadingBtnText: "loading...",
};
