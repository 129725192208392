import React from "react";

const Profile = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.997 15.1748C7.684 15.1748 4 15.8548 4 18.5748C4 21.2958 7.661 21.9998 11.997 21.9998C16.31 21.9998 19.994 21.3208 19.994 18.5998C19.994 15.8788 16.334 15.1748 11.997 15.1748"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M11.9971 12.584C14.9351 12.584 17.2891 10.229 17.2891 7.292C17.2891 4.355 14.9351 2 11.9971 2C9.06008 2 6.70508 4.355 6.70508 7.292C6.70508 10.229 9.06008 12.584 11.9971 12.584"
      fill="currentColor"
    />
  </svg>
);
export default Profile;
