import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Modals.EditModelNameModal.${id}`);

export default class ModelNameType {
  static CAN_SEARCH = 1;

  static CAN_NOT_SEARCH = 0;

  static choices = [ModelNameType.CAN_SEARCH, ModelNameType.CAN_NOT_SEARCH];

  static getText = t => {
    if (t === ModelNameType.CAN_SEARCH) return getTextById("canSearch");
    if (t === ModelNameType.CAN_NOT_SEARCH) return getTextById("canNotSearch");
    return "";
  };

  static getOptions = () =>
    ModelNameType.choices.map(item => ({
      value: item,
      label: ModelNameType.getText(item),
    }));
}
