export default {
  canSearch: "可搜索",
  canNotSearch: "不可搜索",
  modelNameConfigHelp: "模型名称选为可搜索时，用户可以在搜索框中输入模型名称强制命中此模型； 选为不可搜索时无法进行此操作",
  modelNameEmptyErrorMessage: "模型名不能为空",
  modelName: "模型名称",
  changeModelNameModelTitle: "修改模型名称",
  modelNameInputPlaceholder: "建议以模型场景或事实表名命名",
  createModelModalTitle: "创建模型",
  cancelBtnText: "取消",
  loadingBtnText: "加载中...",
  loadMoreBtnText: "加载更多",
  noDataTableFoundTip: "未找到对应数据表，请尝试其他关键词。",
  howToCreateBtnText: "如何创建？",
  startToCreateBtnText: "开始创建",
  selectTableTip: "请选择1或多张表开始",
  syncDataTable: "数据表同步",
  syncDatabase: "数据库同步",
  synchronizingDatabaseTable: "数据库表同步中...",
  dbConnectFail: "数据库无法连接",
  dbConnectFailButtonText: "查看详情",
  searchDataTablePlaceholder: "查找数据表",
};
