import * as React from "react";
import { SVGProps } from "react";
const CheckOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.955 8.748 1.854 5.646a.5.5 0 0 0-.708.708l3.5 3.5a.5.5 0 0 0 .747-.045l5.5-7a.5.5 0 0 0-.786-.618L4.955 8.748Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CheckOutlined;
