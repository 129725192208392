import React from "react";

const AttentionStarOutlined = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0002 2.66632L7.58458 5.53467C7.17261 6.36941 6.37628 6.94798 5.4551 7.08183L2.28968 7.54179L4.5802 9.77449C5.24677 10.4242 5.55094 11.3604 5.39359 12.2779L4.85287 15.4305L7.6841 13.942C8.50803 13.5088 9.49236 13.5088 10.3163 13.942L13.1475 15.4305L12.6068 12.2779C12.4494 11.3604 12.7536 10.4242 13.4202 9.77449L15.7107 7.54179L12.5453 7.08183C11.6241 6.94798 10.8278 6.36941 10.4158 5.53467L9.0002 2.66632ZM10.153 1.51642C9.68144 0.560933 8.31895 0.560933 7.84739 1.51642L6.20121 4.85194C6.01395 5.23136 5.65198 5.49435 5.23326 5.55519L1.5523 6.09007C0.497856 6.24329 0.0768223 7.5391 0.839824 8.28284L3.50339 10.8792C3.80638 11.1745 3.94464 11.6 3.87312 12.0171L3.24433 15.6832C3.06421 16.7333 4.16649 17.5342 5.10962 17.0384L8.40197 15.3075C8.77648 15.1106 9.2239 15.1106 9.59842 15.3075L12.8908 17.0384C13.8339 17.5342 14.9362 16.7333 14.7561 15.6832L14.1273 12.0171C14.0557 11.6 14.194 11.1745 14.497 10.8792L17.1606 8.28284C17.9236 7.5391 17.5025 6.24329 16.4481 6.09007L12.7671 5.55519C12.3484 5.49435 11.9864 5.23136 11.7992 4.85194L10.153 1.51642Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default AttentionStarOutlined;
