import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Workspace.WorkspaceSettingView.${id}`);

export default class AttributionMode {
  static SMART = 1;

  static choices = [this.SMART];

  static getText = mode => {
    if (mode === this.SMART) return getTextById("smartMode");
    return "";
  };
}
