export default {
  guideBtn: "Go to settings",
  initTitle: "Please complete the following initialization necessary settings first",
  urlGuideTitle: "Set the website URL and ensure that the website can be successfully connected",
  urlGuideDesc: "Ensure uploading and downloading within the system and the generation of external links",
  userGuideTitle: "Create a new (or import) business user",
  userGuideDesc: "All users who need to join the workspace need to be created as business users in the system first",
  adminGuideTitle: "Create a workspace and select the person responsible for the workspace",
  adminGuideDesc:
    "You need to create a business user first. Your current login account is the system administrator account (you cannot enter the workspace or become the person in charge of the workspace)",
  loginTitle: "How to set up single sign-on",
  loginDesc: "When you need to connect Arctic to your own system account, you need to make the following settings",
  ssoTitle: "Configure single sign-on information and select the login method",
  ssoDesc: "Multiple login methods can be configured at the same time",
  syncUserTitle: "Set the [Allow synchronized users] switch in the basic settings",
  syncUserDesc1:
    "After it is turned on, when a new user logs in through a single point or authorization, the system will automatically register an account for him or her when synchronizing information.",
  syncUserDesc2: "When you wish to control user accounts, you can turn this switch off",
  aiTitle: "How to set up a large language model",
  aiDesc: "This configuration is required when you need to use the large language model function in the system",
  llmTitle:
    "Enable the large language model service and select the function module that needs to be enabled (single function adjustment prompt is supported)",
  llmDesc1: "Arctic supports multiple large language models",
  llmDesc2:
    "Each function associated with a large language model can be turned on individually, and the prompt can also be manually adjusted.",
  styleTitle: "How to apply brand logo, color and other personalized settings",
  logoTitle: "Upload brand LOGO and modify web page title in personalization settings",
  logoDesc1:
    "The LOGO of the homepage, the LOGO of the browser tab page and the LOGO set in the workspace all support independent uploading.",
  logoDesc2: "The title and description of the web page can also be set in the [Browser Tag] item.",
  themeTitle: "Open the custom theme color and edit the color card of the default theme color",
  themeDesc1: "You can set the theme colors for light and dark themes separately",
  themeDesc2: "After turning on custom theme colors, you can modify the default theme color card.",
  watermarkTitle: "Turn on website watermark",
  watermarkDesc: "Supports three watermark methods: visitor watermark, copyright watermark and picture watermark",
};
