import React from "react";

const Tool = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4952_14331)">
      <rect y="0.000244141" width="24" height="24" rx="12" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6163 6.38289C13.8709 6.19084 13.087 6.2086 12.351 6.43419C11.6151 6.65978 10.9558 7.08443 10.4461 7.66122C9.93642 8.23801 9.59609 8.94448 9.46273 9.70258C9.32937 10.4607 9.40819 11.2409 9.69046 11.957L6.94784 14.2786C6.73984 14.4544 6.57058 14.6714 6.45078 14.916C6.33098 15.1605 6.26325 15.4273 6.25186 15.6994C6.24047 15.9715 6.28568 16.243 6.38463 16.4967C6.48357 16.7504 6.6341 16.9808 6.82669 17.1734C7.01928 17.3659 7.24973 17.5164 7.50347 17.6153C7.75721 17.7142 8.02872 17.7593 8.30081 17.7479C8.5729 17.7364 8.83966 17.6687 9.0842 17.5488C9.32873 17.4289 9.54574 17.2596 9.72147 17.0516L12.0419 14.3102C12.7581 14.5929 13.5385 14.6721 14.2969 14.5389C15.0553 14.4058 15.7621 14.0655 16.3391 13.5557C16.9162 13.046 17.341 12.3865 17.5667 11.6503C17.7923 10.9142 17.81 10.1299 17.6178 9.38432C17.5907 9.27956 17.5358 9.18404 17.459 9.10779C17.3822 9.03154 17.2863 8.9774 17.1814 8.95103C17.0764 8.92467 16.9663 8.92708 16.8626 8.95799C16.7589 8.9889 16.6654 9.04718 16.592 9.1267L14.7761 11.0988L13.4063 10.5949L12.9012 9.22331L14.8745 7.4104C14.9545 7.33703 15.0132 7.24341 15.0444 7.13943C15.0756 7.03545 15.078 6.92499 15.0516 6.81971C15.0251 6.71444 14.9707 6.61827 14.8941 6.54138C14.8175 6.46448 14.7215 6.40973 14.6163 6.38289Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4952_14331">
        <rect width="24" height="24" fill="white" transform="translate(0 0.000244141)" />
      </clipPath>
    </defs>
  </svg>
);
export default Tool;
