import {
  AmazonRedshiftSrc,
  ApacheDorisSrc,
  ApacheKylinSrc,
  ApacheSparkSrc,
  ClickHouseSrc,
  DB2Src,
  DenodoSrc,
  DM8Src,
  FileSrc,
  GaussDBDWSSrc,
  GBaseSrc,
  GreenplumSrc,
  ImpalaSrc,
  KyligenceSrc,
  MaxComputeSrc,
  MySQLSrc,
  OracleSrc,
  PostgreSQLSrc,
  PrestoSrc,
  SAPHANASrc,
  selectDBSrc,
  SQLServerSrc,
  StarRocksSrc,
  TiDBSrc,
  TrinoSrc,
} from "@/assets/common";
import { getLocaleText } from "@/utils/locale";

export default class Adapter {
  static POSTGRESQL = 1;

  static CLICKHOUSE = 2;

  static MYSQL = 3;

  static SPARK_SQL = 4;

  static MYSQL_5_7 = 7;

  static APACHE_KYLIN = 8;

  static ORACLE = 9;

  static INTERNAL = 12; // 自有数据源

  static KYLIGENCE = 13;

  static APACHE_DORIS = 14;

  static GREENPLUM = 15;

  static SQL_SERVER_2012 = 16;

  static TIDB = 18;

  static STAR_ROCKS = 19;

  static DM_8 = 21;

  static SAP_HANA = 22;

  static PRESTO = 23;

  static IMPALA = 24;

  static MAX_COMPUTE = 25;

  static DB_2 = 26;

  static GBASE = 27;

  static GAUSS_DB_DWS = 28;

  static AMAZON_REDSHIFT = 29;

  static SELECT_DB = 30;

  static TRINO = 31;

  static DENODO = 32;

  // 下列数据库，port 必填
  static requiredPortChoices = [this.SAP_HANA, this.DM_8, this.GBASE];

  // 下列数据库 name 必填
  static requiredDatabaseNameChoice = [
    this.SAP_HANA,
    this.GREENPLUM,
    this.GAUSS_DB_DWS,
    this.SQL_SERVER_2012,
    this.DM_8,
    this.GBASE,
    this.DENODO,
  ];

  // 下列数据库的 user 必填
  static requiredUserChoice = [
    this.SAP_HANA,
    this.IMPALA,
    this.POSTGRESQL,
    this.GREENPLUM,
    this.GAUSS_DB_DWS,
    this.SQL_SERVER_2012,
    this.DM_8,
    this.GBASE,
    this.DENODO,
  ];

  // 推荐使用的数据源
  static recommendAdapters = [
    this.CLICKHOUSE,
    // MySQL Series
    this.MYSQL,
    this.MYSQL_5_7,
    this.SELECT_DB,
    this.APACHE_DORIS,
    this.STAR_ROCKS,
    // PostgreSQL Series
    this.POSTGRESQL,
    this.GREENPLUM,
    this.GAUSS_DB_DWS,
    // Apache Kylin Series
    this.APACHE_KYLIN,
    this.KYLIGENCE,
  ];

  // 有限支持的数据源
  static limitedSupportAdapters = [
    this.SPARK_SQL,
    this.TIDB,
    this.GBASE,
    this.IMPALA,
    this.MAX_COMPUTE,
    this.ORACLE,
    this.TRINO,
    this.PRESTO,
    this.DB_2,
    this.DM_8,
    this.SAP_HANA,
    this.SQL_SERVER_2012,
    this.AMAZON_REDSHIFT,
    this.DENODO,
  ];

  // 显示高级配置的数据源
  static advancedSettingAdapters = [this.PRESTO, this.TRINO, this.DENODO];

  static getText = adapter => {
    if (adapter === Adapter.POSTGRESQL) return "PostgreSQL";
    if (adapter === Adapter.CLICKHOUSE) return "ClickHouse";
    if (adapter === Adapter.MYSQL) return "MySQL 8.0";
    if (adapter === Adapter.MYSQL_5_7) return "MySQL 5.7";
    if (adapter === Adapter.SPARK_SQL) return "Spark SQL";
    if (adapter === Adapter.APACHE_KYLIN) return "Apache Kylin";
    if (adapter === Adapter.INTERNAL) return "Internal";
    if (adapter === Adapter.KYLIGENCE) return "Kyligence";
    if (adapter === Adapter.APACHE_DORIS) return "Apache Doris";
    if (adapter === Adapter.GREENPLUM) return "Greenplum";
    if (adapter === Adapter.ORACLE) return "Oracle";
    if (adapter === Adapter.SQL_SERVER_2012) return "SQL Server 2012";
    if (adapter === Adapter.TIDB) return "TiDB";
    if (adapter === Adapter.STAR_ROCKS) return "StarRocks";
    if (adapter === Adapter.DM_8) return "达梦8";
    if (adapter === Adapter.SAP_HANA) return "SAP HANA";
    if (adapter === Adapter.PRESTO) return "Presto";
    if (adapter === Adapter.IMPALA) return "Impala";
    if (adapter === Adapter.MAX_COMPUTE) return "MaxCompute";
    if (adapter === Adapter.DB_2) return "IBM DB2";
    if (adapter === Adapter.GBASE) return "GBase 8s";
    if (adapter === Adapter.GAUSS_DB_DWS) return "GaussDB(DWS)";
    if (adapter === Adapter.AMAZON_REDSHIFT) return "Amazon Redshift";
    if (adapter === Adapter.SELECT_DB) return "SelectDB";
    if (adapter === Adapter.TRINO) return "Trino";
    if (adapter === Adapter.DENODO) return "Denodo";
    return "-";
  };

  static getStatusText = (adapter, errorData) => {
    const { dbError = {}, message = "" } = errorData || {};
    const { code, exception = "" } = dbError || {}; // dbError 可能是 null
    if (!code && !exception) return message;
    let tip = "";
    if (adapter === this.MYSQL || adapter === this.MYSQL_5_7) tip = getLocaleText(`components.datasourceStatus.MySQLStatus.${code}`);
    if (adapter === this.POSTGRESQL) tip = getLocaleText(`components.datasourceStatus.PostgreSQLStatus.${code}`);
    if (adapter === this.ORACLE) tip = getLocaleText(`components.datasourceStatus.OracleStatus.${code}`);
    if (adapter === this.CLICKHOUSE) tip = getLocaleText(`components.datasourceStatus.ClickHouseStatus.${code}`);
    const errorCode = `${code ? `${getLocaleText("components.datasourceStatus.common.errorCode")}${code} ，` : ""}`;
    // 根据 code 获取不到 tip 且 exception 不存在时，使用 message 兜底
    const detail = `${
      tip && !tip.startsWith(getLocaleText("errors.notFindKey")) && !tip.startsWith(getLocaleText("errors.errorId"))
        ? `${tip}${exception ? `，${exception}` : ""}`
        : `${exception || message}`
    }`;
    return `${errorCode}${detail}`;
  };

  // 选填情况下端口号默认值，后端会自动补，前端不用传
  static getPortPlaceholder = adapter => {
    switch (adapter) {
      case this.MYSQL_5_7:
      case this.MYSQL:
        return "3306";
      case this.ORACLE:
        return "1521";
      case this.IMPALA:
        return "21050";
      case this.POSTGRESQL:
      case this.GREENPLUM:
      case this.GAUSS_DB_DWS:
        return "5432";
      case this.CLICKHOUSE:
        return "9000";
      case this.SQL_SERVER_2012:
        return "1433";
      case this.AMAZON_REDSHIFT:
        return "5439";
      case this.DENODO:
        return "9999";
      default:
        return "";
    }
  };

  // 选填情况下数据库名默认值
  static getNamePlaceholder = adapter => {
    switch (adapter) {
      case this.MYSQL_5_7:
      case this.MYSQL:
        return "mysql";
      case this.ORACLE:
        return "XE";
      case this.IMPALA:
        return "default";
      case this.POSTGRESQL:
        return "postgres";
      case this.CLICKHOUSE:
        return "default";
      default:
        return "";
    }
  };

  // 选填情况下用户名默认值
  static getUserPlaceholder = adapter => {
    switch (adapter) {
      case this.MYSQL_5_7:
      case this.MYSQL:
        return "root";
      case this.ORACLE:
        return "SYSADMIN";
      default:
        return "";
    }
  };

  static getDatabaseIcon = name => {
    if (name === this.INTERNAL) return FileSrc;
    if (name === this.POSTGRESQL) return PostgreSQLSrc;
    if (name === this.CLICKHOUSE) return ClickHouseSrc;
    if (name === this.MYSQL || name === this.MYSQL_5_7) return MySQLSrc;
    if (name === this.KYLIGENCE) return KyligenceSrc;
    if (name === this.APACHE_KYLIN) return ApacheKylinSrc;
    if (name === this.SPARK_SQL) return ApacheSparkSrc;
    if (name === this.TIDB) return TiDBSrc;
    if (name === this.APACHE_DORIS) return ApacheDorisSrc;
    if (name === this.STAR_ROCKS) return StarRocksSrc;
    if (name === this.GBASE) return GBaseSrc;
    if (name === this.IMPALA) return ImpalaSrc;
    if (name === this.MAX_COMPUTE) return MaxComputeSrc;
    if (name === this.ORACLE) return OracleSrc;
    if (name === this.GREENPLUM) return GreenplumSrc;
    if (name === this.PRESTO) return PrestoSrc;
    if (name === this.DB_2) return DB2Src;
    if (name === this.GAUSS_DB_DWS) return GaussDBDWSSrc;
    if (name === this.DM_8) return DM8Src;
    if (name === this.SAP_HANA) return SAPHANASrc;
    if (name === this.SQL_SERVER_2012) return SQLServerSrc;
    if (name === this.AMAZON_REDSHIFT) return AmazonRedshiftSrc;
    if (name === this.SELECT_DB) return selectDBSrc;
    if (name === this.TRINO) return TrinoSrc;
    if (name === this.DENODO) return DenodoSrc;
  };
}
