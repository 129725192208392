import React from "react";

const AutoInsightTrend: React.FC = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.0002" r="12" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.48481 5.99731H14.5153C16.7004 5.99731 18.0031 7.29931 17.997 9.48331V14.5113C17.997 16.6953 16.6944 17.9973 14.5093 17.9973H9.48481C7.29972 17.9973 5.99707 16.6953 5.99707 14.5053V9.48331C5.99707 7.29931 7.29972 5.99731 9.48481 5.99731ZM14.6725 10.2157C14.7745 10.2577 14.8586 10.3417 14.9006 10.4437C14.9189 10.4803 14.9267 10.5203 14.9347 10.5612C14.9372 10.574 14.9397 10.5868 14.9426 10.5997V11.7217C14.9426 11.9557 14.7565 12.1417 14.5224 12.1417C14.2883 12.1417 14.1022 11.9557 14.1022 11.7217V11.6137L12.4694 13.2457C12.3793 13.3357 12.2653 13.3777 12.1332 13.3657C12.0071 13.3537 11.8991 13.2817 11.827 13.1797L11.2147 12.2677L9.78603 13.6957C9.70799 13.7737 9.59993 13.8157 9.49188 13.8157C9.38383 13.8157 9.28177 13.7797 9.19773 13.6957C9.03565 13.5277 9.03565 13.2637 9.19773 13.1017L10.9866 11.3137C11.0767 11.2237 11.1907 11.1817 11.3228 11.1937C11.4489 11.2057 11.5569 11.2777 11.6289 11.3797L12.2412 12.2917L13.5079 11.0257H13.3998C13.1657 11.0257 12.9796 10.8397 12.9796 10.6057C12.9796 10.3717 13.1657 10.1857 13.3998 10.1857H14.5164C14.5704 10.1857 14.6244 10.1977 14.6725 10.2157Z"
      fill="white"
    />
  </svg>
);

export default AutoInsightTrend;
