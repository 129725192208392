import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.RowPermission.${id}`);

export default class RowPermissionMode {
  static AND = 1;

  static OR = 2;

  static choices = [this.AND, this.OR];

  static getText = t => {
    if (t === this.AND) return getTextById("satisfyAll");
    if (t === this.OR) return getTextById("satisfyAny");
  };
}
