export default {
  sendSuccessToast: "Test email has been sent, please check your inbox",
  emailSettings: "Email Settings",
  emailServerSettings: "Email Server Settings",
  host: "Server",
  hostPlaceholder: "Enter the server address of the email service",
  username: "Username",
  usernamePlaceholder: "Enter the username",
  password: "Password",
  passwordPlaceholder: "Enter the password",
  protocol: "Security Protocol",
  protocolPlaceholder: "Select the security protocol",
  port: "Port",
  portPlaceholder: "Enter the port number",
  unsetPlaceholder: "Not Set",
  buttonTextSet: "Set",
  sendEmailTitle: "Send Email",
  formLabelSendEmailAddress: "Sender Email Address",
  sendEmailAddressPlaceholder: "Enter the sender's email address",
  formLabelSelectCharset: "Email Encoding Format",
  selectCharsetPlaceholder: "Select the email encoding format",
  testEmailTitle: "Test Recipient",
  testEmailPlaceholder: "Enter the recipient's email address for testing",
  emailSettingsModalTitle: "Email Server Settings",
  defaultPlaceholder: "This field is required",
  protocolNull: "None",
  cancel: "Cancel",
  save: "Save",
  saveSuccessToast: "Saved successfully",
  emailFormatError: "You need to enter a valid email address",
};
