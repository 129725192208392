export default {
  title: "用户有需要转移的资产",
  content1: "您可以【取消】本次操作并联系工作区负责人转移资产",
  content2: "也可以点击【确认】移交资产给对应工作区负责人。",
  content3: "也可以点击【删除资产】来删除该用户所有的数据资产。",
  content4: "资产类型：报告、数据告警",
  deletePrefix: "确认删除",
  deleteSuffix: "的所有资产",
  deleteContentPrefix: "删除操作无法撤销，删除后将自动",
  deleteContentSuffix: "该用户",
  deleteButtonText: "删除资产",
  cancelButtonText: "取消",
  confirmButtonText: "确认",
};
