export default {
  search: "Search",
  inputPrompt: "Type your question here",
  canTry: "You might be interested in",
  description: "Check Description",
  existingReports: "Dashboards",
  seeMore: "More",
  concerns: "Favorite Questions",
  searchHistory: "Search History",
  searchAdviceTitle: "Search Suggestions",
  myWatchTitle: "My Observations",
  allViews: "All search history",
  recentSearch: "Recent Searches",
  clearViewsTip: "Go to Search History to clear searches",
  startSearchText: "Nothing to add, please search!",
  updateCardSuccessToast: "Update card successfully",
  deleteSuccessToast: "Delete successfully",
  save: "Save",
  cardSaved: "Saved cards",
  addContentWatched: "Add to my observations",
  noAuthPrompt: "You don't have permission to view this, please contact workspace admin",
  deleteCardBtnText: "Delete card",
  dragPrompt: "Drag to move",
  pinboard: "Dashboard",
  concern: "Bookmarks",
  feedbackEntryTooltip: "If you are dissatisfied with the search results, you can provide feedback here",
  feedback: "Calibration Feedback",
  help: "Help Documentation",
  helpTooltip: "Click here to go to the help documentation",
};
