import AccountEditModal from "./AccountEditModal";
import AccountsBatchDeleteModal from "./AccountsBatchDeleteModal";
import AccountsUploadModal from "./AccountsUploadModal";
import AddCardToPinboardModal from "./AddCardToPinboardModal";
import AIFillProgressModal from "./AIFillProgressModal";
import AlertLogModal from "./AlertLogModal";
import AliasModal from "./AliasModal";
import BaseFieldConfigModal from "./BaseFieldConfigModal";
import BaseMobileFullScreenModal from "./BaseMobileFullScreenModal";
import CardAlarmModal from "./CardAlarmModal";
import CardAlertLogModal from "./CardAlertLogModal";
import CardShareModal from "./CardShareModal";
import ChangePasswordModal from "./ChangePasswordModal";
import ConfigTableRelationModal from "./ConfigTableRelationModal";
import ConfigUploadModal from "./ConfigUploadModal";
import CreateMyWatchCardModal from "./CreateMyWatchCardModal";
import CreatePinboardModal from "./CreatePinboardModal";
import CustomTimePeriodModal from "./CustomTimePeriodModal";
import DataAlarmModal from "./DataAlarmModal";
import DataSourceUploadModal from "./DataSourceUploadModal";
import DateOffsetModal from "./DateOffsetModal";
import DateTagModal from "./DateTagModal";
import DefineTableModal from "./DefineTableModal";
import EditModelNameModal from "./EditModelNameModal";
import EditSQLPreviewModal from "./EditSQLPreviewModal";
import EditTableNameModal from "./EditTableNameModal";
import EnterBeginnerTourModal from "./EnterBeginnerTourModal";
import FactDimensionConfigModal from "./FactDimensionConfigModal";
import FeedbackModal from "./FeedbackModal";
import JoinTypeErrorModal from "./JoinTypeErrorModal";
import KeywordSynonymsModal from "./KeywordSynonymsModal";
import MobileSearchInputModal from "./MobileSearchInputModal";
import ModelSummaryModal from "./ModelSummaryModal";
import PinboardTagModal from "./PinboardTagModal";
import ProgressModal from "./ProgressModal";
import RulesTestRunModal from "./RulesTestRunModal";
import SelectDefaultTimeSeriesModal from "./SelectDefaultTimeSeriesModal";
import SelectFactTableModal from "./SelectFactTableModal";
import SyncKeywordsModal from "./SyncKeywordsModal";
import TimedReminderModal from "./TimedReminderModal";
import TransferAssetsModal from "./TransferAssetsModal";
import UploadAliasErrorCode from "./UploadAliasErrorCode";
import UploadProgressModal from "./UploadProgressModal";
import UploadSynonymFileModal from "./UploadSynonymFileModal";
import UploadToDatabaseProgressModal from "./UploadToDatabaseProgressModal";

export default {
  UploadAliasErrorCode,
  AccountsBatchDeleteModal,
  AccountsUploadModal,
  AccountEditModal,
  AddCardToPinboardModal,
  BaseMobileFullScreenModal,
  CardShareModal,
  ConfigUploadModal,
  CreateMyWatchCardModal,
  CreatePinboardModal,
  EditModelNameModal,
  FeedbackModal,
  MobileSearchInputModal,
  PinboardTagModal,
  ProgressModal,
  TimedReminderModal,
  TransferAssetsModal,
  CustomTimePeriodModal,
  DateTagModal,
  DefineTableModal,
  AIFillProgressModal,
  FactDimensionConfigModal,
  SelectDefaultTimeSeriesModal,
  SelectFactTableModal,
  JoinTypeErrorModal,
  UploadProgressModal,
  UploadToDatabaseProgressModal,
  SyncKeywordsModal,
  DataAlarmModal,
  AliasModal,
  UploadSynonymFileModal,
  KeywordSynonymsModal,
  EditTableNameModal,
  AlertLogModal,
  BaseFieldConfigModal,
  EditSQLPreviewModal,
  ConfigTableRelationModal,
  DataSourceUploadModal,
  EnterBeginnerTourModal,
  ChangePasswordModal,
  ModelSummaryModal,
  DateOffsetModal,
  CardAlarmModal,
  CardAlertLogModal,
  RulesTestRunModal,
};
