import React from "react";
import { getLocaleText } from "@/utils/locale";

import contentImg from "@/assets/png/periscopeAndSeal.png";

import styles from "./index.module.scss";

const getText = id => getLocaleText(`components.BaseFieldCommonRenders.${id}`);

const NotPermissionBlock = () => {
  return (
    <div className={styles.exceptionContent}>
      <img src={contentImg} className={styles.imgException} alt="permission" />
      <div>
        <h3 className={styles.title}>{getText("noAuth")}</h3>
        <p className={styles.description}>{getText("noAuthTip")}</p>
      </div>
    </div>
  );
};

export default NotPermissionBlock;
