import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`pages.Manage.DateTags.${id}`);

export default class DateTagsTab {
  static CALENDAR = "calendar";

  static MANAGE = "manage";

  static choices = [this.CALENDAR, this.MANAGE];

  static getText = t => {
    if (t === this.CALENDAR) return getTextById("calendar");
    if (t === this.MANAGE) return getTextById("settings");
    return "";
  };
}
