import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Constants.AutoInsightType.${id}`);

export default class AutoInsightType {
  static OUTLIER = "outlier"; // 异常值

  static PARETO = "pareto"; // 构成

  static TEXT = "text"; // 文字解析

  static GPT_TEXT = "llm"; // GPT 文字解析

  static RING_OUTLIER = "ringOutlier"; // 环比异常点

  static SEASONALITY = "seasonality"; // 周期性波动

  static TREND = "trend"; // 总体变化趋势

  static CHANGE_POINT = "changepoint"; // 趋势变化点

  static BREAKOUT = "breakout"; // 突破性上升下降

  static VOLATILITY = "volatility"; // 波动性变化

  static MAXIMUM = "maximum"; // 最大值

  static MINIMUM = "minimum"; // 最小值

  static AVERAGE = "average"; // 均值

  static STATISTICS = "statistics"; // 离散

  static COUNT = "count"; // 其它

  static CORRELATION = "correlation"; // 相关性

  static RECENT_CHANGE = "recentChange"; // 最新变化

  static MIXED_TREND = "mixedTrend"; // 趋势差异

  static MEASURE_GROUP = "measureGroup"; // 仅前端指标分组用

  static TREND_TYPES = [
    this.RING_OUTLIER,
    this.SEASONALITY,
    this.TREND,
    this.CHANGE_POINT,
    this.BREAKOUT,
    this.VOLATILITY,
    this.RECENT_CHANGE,
    this.MIXED_TREND,
  ];

  // 点击 “查看解析” 按钮，展示关键维度表格的类型
  static KEY_DIMENSION_TYPES = [this.RING_OUTLIER, this.BREAKOUT];

  // 点击 “查看解析” 按钮，展示反常数据图表的类型
  static ANOMALY_TYPES = [this.OUTLIER, this.MAXIMUM, this.MINIMUM];

  // 点击 “查看解析” 按钮，展示简单图表的类型
  static SIMPLE_CHART_TYPES = [this.PARETO, this.SEASONALITY];

  // hover 单个解析时需要高亮数据点的 insight 类型
  static HOVER_HIGHLIGHT_TYPES = [
    this.OUTLIER,
    this.RING_OUTLIER,
    this.CHANGE_POINT,
    this.BREAKOUT,
    this.MAXIMUM,
    this.MINIMUM,
    this.AVERAGE,
  ];

  // 存在图表交互的 insight 类型
  static INTERACTION_TYPES = [
    this.OUTLIER,
    this.PARETO,
    this.RING_OUTLIER,
    this.CHANGE_POINT,
    this.BREAKOUT,
    this.VOLATILITY,
    this.MAXIMUM,
    this.MINIMUM,
    this.AVERAGE,
  ];

  static getText = t => {
    if (t === this.OUTLIER) return getTextById("outlier");
    if (t === this.PARETO) return getTextById("pareto");
    if (t === this.RING_OUTLIER) return getTextById("ringOutlier");
    if (t === this.SEASONALITY) return getTextById("seasonality");
    if (t === this.TREND) return getTextById("trend");
    if (t === this.CHANGE_POINT) return getTextById("changePoint");
    if (t === this.BREAKOUT) return getTextById("breakout");
    if (t === this.VOLATILITY) return getTextById("volatility");
    if (t === this.MAXIMUM) return getTextById("maximum");
    if (t === this.MINIMUM) return getTextById("minimum");
    if (t === this.AVERAGE) return getTextById("average");
    if (t === this.STATISTICS) return getTextById("statistics");
    if (t === this.COUNT) return getTextById("count");
    if (t === this.CORRELATION) return getTextById("correlation");
    if (t === this.RECENT_CHANGE) return getTextById("recentChange");
    if (t === this.MIXED_TREND) return getTextById("mixedTrend");
    return "";
  };

  static getDesc = t => {
    if (t === this.CORRELATION) return getTextById("correlationDesc");
    return "";
  };

  static getTitle = t => `${this.getText(t)}${getTextById("suffix")}`;
}
