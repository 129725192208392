export default {
  rowPermission: "行权限",
  permissionOffTip: "权限未启用，用户搜索行数据时无限制条件",
  saveSuccessToast: "行权限配置更新成功",
  enableConfirmTitle: "确认开启行权限？",
  enableConfirmTip: "开启后需要配置权限内容并更新，否则可能导致搜索结果无数据",
  satisfyAll: "最终权限需满足所有条件",
  satisfyAny: "最终权限需满足任一条件",
  method: "控制方式",
  whitelistTip: "只有白名单中内容可以被使用",
  blacklistTip: "黑名单中的内容将被禁用",
  selectPlaceholder: "请选择",
  field: "受控字段",
  selectFieldErrMsg: "请选择受控字段",
  condition: "控制类型",
  selectConditionErrMsg: "请选择控制类型",
  equalTip: "精准匹配，与下方内容需完全一致时受控",
  containTip: "模糊匹配，含有下方内容的字段均受控制",
  content: "受控内容",
  contentTip: "请输入需要加入名单的内容，以回车分隔",
  operations: "操作",
  editBtnText: "编辑",
  deleteBtnText: "删除",
  deleteConfirmTitle: "确认删除此项？",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  addBtnText: "新增",
  saveBtnText: "更新",
  editModalTitle: "编辑行权限控制",
  addModalTitle: "新增行权限控制",
};
