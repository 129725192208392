import React from "react";

const CloseCircleFilled: React.FC = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 17 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46152 15C12.3275 15 15.4615 11.866 15.4615 8C15.4615 4.13401 12.3275 1 8.46152 1C4.59552 1 1.46152 4.13401 1.46152 8C1.46152 11.866 4.59552 15 8.46152 15ZM11.4565 5.00501C11.7298 5.27838 11.7298 5.7216 11.4565 5.99496L9.45145 7.99999L11.4565 10.005C11.7298 10.2784 11.7298 10.7216 11.4565 10.995C11.1831 11.2683 10.7399 11.2683 10.4665 10.995L8.4615 8.98994L6.45648 10.995C6.18311 11.2683 5.7399 11.2683 5.46653 10.995C5.19316 10.7216 5.19316 10.2784 5.46653 10.005L7.47156 7.99999L5.46653 5.99496C5.19316 5.7216 5.19316 5.27838 5.46653 5.00501C5.7399 4.73165 6.18311 4.73165 6.45648 5.00501L8.4615 7.01004L10.4665 5.00501C10.7399 4.73165 11.1831 4.73165 11.4565 5.00501Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseCircleFilled;
