export default {
  modalTitle: "Batch Delete",
  selectFile: "Select File",
  cancelText: "Cancel",
  nextBtnText: "Next Step",
  firstStepWarningText: "Logout operation is irreversible. Please proceed with caution.",
  secondStepWarningText: 'Please confirm the personnel to be logged out again before clicking "Next."',
  uploadTitle: "Please upload the file as requested",
  uploadTip1: "Each line of the file contains the login names of the users to be logged out.",
  uploadTip2: "A single file can have a maximum of 2000 lines.",
  uploadTip3: "Note: Workspace administrators cannot log out using this method.",
  uploadSuffixTip: "Supported format: .txt",
  uploadFormatTip: "Supported encoding formats: utf-8, gbk, ascii, utf-16.",
  readFileError: "File reading failed",
  noAccountError: "The file does not contain a valid username. Please upload again.",
  confirmBtnText: "Confirm Logout",
  loadingText: "Logging out, please wait...",
  loggingOutOverTipTitle: "Batch Logout Completed",
  confirm: "Ok",
  loggingOutFailed: "Logging out failed",
  loggingOutFailedTip:
    "The failure may be due to the user being a workspace administrator or having untransferred resources under their name. You can try manually logging them out.",
};
