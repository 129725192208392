export default {
  editBtnText: "Edit",
  saveBtnText: "Save",
  cancelBtnText: "Cancel",
  /* 搜索结果预设 */
  searchResultsSettings: "Search Results Default Settings",
  defaultMeasure: "Default Measure",
  defaultMeasureTip: "The default measure used when searching for keywords related to measures mentioned in search queries",
  selectPlaceholder: "Please select",
  notSet: "Not set",
  hideMeasureCard: "Hide Measure Card",
  hideMeasureCardTip: "When enabled, the measure cards in the search results will be hidden",
  measureCard: "Measure Card",
  measureCardTip: "The summary of measures within the time range of search results, for example",
  /* 默认展示的时间范围 */
  searchDateRangeSettings: "Default Time Range",
  dateRangeKeywordsSettings: "Time Range Keywords and Default Time Range",
  searchDateRangeTip:
    "When searching for data using natural language like 'every day' or 'every month', a reasonable time range needs to be specified to get the search results. The time range can be specified using natural language, such as 'last n days/weeks/months/quarters/years' or 'from June 2019 to March 2020' or 'first half of 2020', etc.",
  dateRangeKeywordsTip:
    "When the search query contains the above time range keywords, the chart will display data within the corresponding default time range",
  labelForDefaultDateRange: "Default Display",
  labelForSecond: "Search 'per second'",
  labelForMinute: "Search 'per minute'",
  labelForHour: "Search 'per hour'",
  labelForDay: "Search 'per day'",
  labelForWeek: "Search 'per week'",
  labelForMonth: "Search 'per month'",
  labelForQuarter: "Search 'per quarter'",
  labelForYear: "Search 'per year'",
  inputPrefixForDefaultDateRange: "Default Display",
  inputPrefixForSecond: "Display 'per second'",
  inputPrefixForMinute: "Display 'per minute'",
  inputPrefixForHour: "Display 'per hour'",
  inputPrefixForDay: "Display 'per day'",
  inputPrefixForWeek: "Display 'per week'",
  inputPrefixForMonth: "Display 'per month'",
  inputPrefixForQuarter: "Display 'per quarter'",
  inputPrefixForYear: "Display 'per year'",
  placeholderForSecond: "Last 30 minutes",
  placeholderForMinute: "Last 12 hours",
  placeholderForHour: "Last 7 days",
  placeholderForDay: "Last 30 days",
  placeholderForWeek: "Last 25 weeks",
  placeholderForMonth: "Last 12 months",
  placeholderForQuarter: "Last 12 quarters",
  placeholderForYear: "Last 3 years",
  dateRangeSuffix: "Data",
  /* 预测分析 */
  forecastSettings: "Forecast Settings",
  forecasting: "Forecasting",
  forecastingTip: "The system makes predictions based on historical data (please refer to the help documentation for details)",
  forecastingExample: "Prediction:",
  on: "On",
  off: "Off",
  onByDefault: "(On by default)",
  offByDefault: "(Off by default)",
  checkboxText: "Enabled by default in user settings",
  forecastDateRange: "Training Data Range for Forecast Model",
  defaultForecastDateRange: "Unset",
  atLeast: "At least",
  atMost: "At most",
  dateUnit: "Days",
  separator: ",",
  maxLessThanMin: "The maximum value cannot be less than the minimum value.",
  minMoreThanMax: "The minimum value cannot be greater than the maximum value.",
  /* 维度分析 */
  dimensionAnalysisSettings: "Dimension Analysis Settings",
  dimensionAnalysisMode: "Dimension Analysis Mode",
  smartMode: "Smart Data Insights",
  attributionModeTip:
    "The system performs attribution analysis based on algorithmically selected key dimensions, disappearing/new dimensions, and negatively correlated dimensions",
  comparisonTypes: "Analysis method",
  comparisonTypesTip: "Please select at least one analysis type; if multiple types are selected, users can switch between them",
  comparisonTypeSuffix: "Analysis",
  defaultComparisonType: "Default Analysis Type",
  /* 引导问题 */
  customSearchSuggestions: "Custom Search Suggestions",
  searchSuggestions: "Workspace Search Suggestions",
  searchSuggestionsTip: "The search suggestions that new users see when they enter the workspace page",
  searchQuestionPlaceholder: "Please enter a question, no more than 255 characters",
  addBtnText: "Add Question",
};
