export default {
  deleteSuccessToast: "删除成功",
  editBtnText: "编辑",
  sortBtnText: "排序",
  sortSuccessToast: "设置成功。",
  deleteBtnText: "删除",
  deleteConfirmTitle: "确认删除该维度？该操作无法撤销",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  id: "ID",
  keyword: "关键词",
  alias: "别名",
  dataType: "数据类型",
  isKeyword: "维度值可搜索",
  dimension: "维度",
  operations: "操作",
  searchInputPlaceholder: "请输入想搜索的维度名称",
  addBtnText: "添加维度",
  current: "当前维度：",
  tableDeleteConfirmTitle: "确认删除该维度",
  tableDeleteConfirmContent1: "该维度已有应用模型。",
  tableDeleteConfirmContent2: "确认后会在所有包含该维度的模型中移除。",
  tableDeleteConfirmContent3: "该操作无法撤销",
  pruneButtonText: "清空无效维度",
  pruneButtonTip: "清空没有被模型使用的事实表维度",
  pruneConfirmTitle: "确认删除无效的事实表维度",
  pruneConfirmContent: "确认后，没有应用模型的事实表维度将被清空。",
};
