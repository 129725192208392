export default {
  1: "不支持的方法",
  2: "不支持的参数",
  3: "文件格式错误:未检测到正确的结束标志",
  6: "无法解析的文本",
  7: "字段个数错误",
  8: "字段不存在",
  9: "字段大小不匹配",
  10: "在块中未找到该字段",
  12: "参数溢出",
  15: "重复的字段",
  16: "表中未定义该字段",
  18: "无法向常量字段插入元素",
  20: "字段数不匹配",
  26: "无法解析引用字符串",
  31: "无法读取压缩包",
  32: "企图读取结束标志后的内容",
  33: "无法读取全部数据",
  34: "参数太多",
  35: "参数太少",
  36: "参数不存在",
  38: "无法解析时间",
  43: "非法的参数类型",
  44: "非法的字段",
  45: "非法的结果字段数",
  46: "未知的函数",
  49: "逻辑错误",
  50: "未知的类型",
  51: "字段查询列表为空",
  52: "字段重复查询",
  57: "已存在的表",
  59: "非法的筛选字段类型",
  60: "未知的表",
  62: "语法错误",
  66: "不是一个字段",
  71: "缓存区满，无法写入",
  72: "无法解析的数字",
  73: "未知的格式",
  76: "无法打开文件",
  77: "无法关闭文件",
  79: "文件名错误",
  81: "未知的数据库",
  82: "数据库已经存在",
  83: "目录不存在",
  85: "输入个格式不正确",
  86: "远程读写服务错误",
  104: "缓存空间太小",
  105: "无法读取历史信息",
  107: "文件不存在",
  117: "错误的数据",
  120: "不能将不同大小的值插入到元组",
  122: "不兼容的字段",
  126: "无法获取返回的类型",
  131: "字符串太长",
  140: "缺失所有请求的字段",
  145: "未知模式溢出",
  151: "字段下标超过范围",
  158: "行数太多",
  161: "字段太多",
  164: "权限只读，不可写入",
  165: "太多临时字段",
  172: "无法创建目录",
  173: "无法分配内存",
  191: "设定大小超过限制",
  192: "用户未知",
  193: "密码错误",
  203: "链接未释放",
  209: "网络连接超时",
  210: "网络错误",
  217: "客户端链接的端口错误",
  218: "表已销毁",
  219: "数据库非空",
  230: "未找到数据单元",
  233: "数据单元名无效",
  238: "格式版本太旧",
  241: "超出内存限制",
  242: "表为只读，不可写入",
  253: "副本已存在",
  263: "无法编译的代码",
  268: "处理器编号错误",
  269: "陷入死循环",
  279: "所有连接尝试都失败了",
  290: "超出限制",
  291: "拒绝访问数据库",
  293: "MONGODB无法验证",
  301: "无法创建子进程",
  303: "无法查找",
  318: "无效的配置参数",
  321: "数值超过该数据类型的范围",
  341: "未完成",
  347: "无法加载配置",
  349: "不可向普通字段插入NULL值",
  351: "模糊的表名",
  352: "模糊的字段名",
  363: "无法创建IO缓冲区",
  377: "语法对该数据类型非法",
  389: "重复的插入",
  391: "外部依赖错误",
  1002: "未知异常",
};
