import React from "react";

const EyeInvisible = props => (
  <svg {...props} width="1em" height=".75em" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.27244 1.90876L7.74651 6.38207C8.07061 6.06892 8.51269 5.87613 9 5.87613C9.99411 5.87613 10.8 6.67845 10.8 7.66817C10.8 8.15641 10.6039 8.59905 10.2858 8.92226L14.7276 13.3639C15.079 13.7154 15.079 14.2852 14.7276 14.6367C14.3761 14.9882 13.8062 14.9882 13.4548 14.6367L12.6448 13.8276C11.4884 14.4973 10.2736 14.8363 9 14.8363C5.77295 14.8363 2.92374 12.6596 0.44452 8.44351L0.254541 8.11518L0 7.66817L0.254541 7.22116C1.01181 5.89129 1.80411 4.7543 2.63168 3.81418L1.99964 3.18156C1.64817 2.83008 1.64817 2.26024 1.99964 1.90876C2.35111 1.55729 2.92096 1.55729 3.27244 1.90876ZM2.29113 7.31213L2.07477 7.66818L2.262 7.97712C4.38764 11.4246 6.63671 13.0443 8.99998 13.0443C9.78553 13.0443 10.5581 12.8656 11.3175 12.5009L7.91672 9.0995C7.78071 8.99731 7.65973 8.87635 7.55766 8.74049L3.90372 5.08464C3.35862 5.71586 2.82104 6.45729 2.29113 7.31213ZM8.99999 0.5C12.227 0.5 15.0763 2.67677 17.5555 6.89282L17.7455 7.22115L18 7.66816L17.5601 8.43559C16.8682 9.61317 16.1474 10.6319 15.3977 11.4887L14.1269 10.2178C14.7361 9.50725 15.3356 8.65888 15.9253 7.66828C13.7446 4.00844 11.4328 2.29204 8.99999 2.29204C8.22934 2.29204 7.47082 2.46428 6.72477 2.81597L5.39493 1.48588C6.53942 0.831348 7.74095 0.5 8.99999 0.5Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);

export default EyeInvisible;
