export default {
  customTimePeriod: "自定义时间周期",
  customTimePeriodTip: "自定义时间周期区别于自然周，可通过定义的关键词触发",
  addBtnText: "添加时间周期",
  addSuccessToast: "添加成功",
  saveSuccessToast: "修改成功",
  deleteConfirmFormerText: "确定删除",
  deleteConfirmLatterText: "吗？",
  deleteConfirmTip: "该操作不可撤销，请谨慎操作！",
  confirmBtnText: "删除",
  deleteSuccessToast: "删除成功",
  cancelBtnText: "取消",
  keyword: "关键词",
  timeUnit: "时间单位",
  offset: "起始时间",
  offsetFormerText: "往前偏移",
  operations: "操作",
  editBtnText: "编辑",
  deleteBtnText: "移除",
};
