export default {
  selectPlaceholder: "Please select",
  pleaseFillInTheAlias: "Please fill in aliases, separated by commas",
  pleaseNameTheField: "Please name field",
  copy: "Copy",
  delete: "Delete",
  edit: "Edit",
  optionalItems: "Optional",
  notApplicable: "Not applicable",
};
