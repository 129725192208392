import React from "react";

const InfoCycleFill = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-3.41715e-07 7C-1.63336e-06 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 1.63337e-06 7 3.41715e-07C3.13401 -9.49935e-07 9.49935e-07 3.134 -3.41715e-07 7ZM7 10.9997C6.72386 10.9997 6.5 10.7758 6.5 10.4997L6.5 6.00001C6.5 5.72386 6.72386 5.50001 7 5.50001C7.27614 5.50001 7.5 5.72386 7.5 6.00001L7.5 10.4997C7.5 10.7758 7.27614 10.9997 7 10.9997ZM7.60572 3.40001C7.60572 3.73138 7.33709 4.00001 7.00572 4.00001C6.67434 4.00001 6.40572 3.73138 6.40572 3.40001C6.40572 3.06864 6.67434 2.80001 7.00572 2.80001C7.33709 2.80001 7.60572 3.06864 7.60572 3.40001Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoCycleFill;
