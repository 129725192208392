import React from "react";

const SwitchGaugeBtn = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7496 11.6667C13.5336 10.6527 14 9.38082 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 9.38082 2.46645 10.6527 3.25037 11.6667H12.7496ZM10.5106 5.49639C10.3153 5.30113 9.99872 5.30113 9.80346 5.49639L8.52559 6.77427C8.36431 6.70502 8.18663 6.66667 7.99999 6.66667C7.26361 6.66667 6.66666 7.26363 6.66666 8.00001C6.66666 8.73639 7.26361 9.33334 7.99999 9.33334C8.73637 9.33334 9.33332 8.73639 9.33332 8.00001C9.33332 7.81713 9.29651 7.64285 9.22988 7.48418L10.5106 6.2035C10.7058 6.00824 10.7058 5.69165 10.5106 5.49639Z"
      fill="currentColor"
    />
    <path
      d="M5.33332 12.6667C4.96513 12.6667 4.66666 12.9651 4.66666 13.3333C4.66666 13.7015 4.96513 14 5.33332 14H10.6667C11.0348 14 11.3333 13.7015 11.3333 13.3333C11.3333 12.9651 11.0348 12.6667 10.6667 12.6667H5.33332Z"
      fill="currentColor"
    />
  </svg>
);

export default SwitchGaugeBtn;
