import dayjs from "dayjs";
import _ from "lodash";
import { uuidV4 } from "@/utils/common";
import { getLocaleText } from "@/utils/locale";
import CardType from "./CardType";
import FilterConditionType from "./FilterConditionType";
import PinboardFilterType from "./PinboardFilterType";
import SearchFilterConditions from "./SearchFilterConditions";
import TimeUnit from "./TimeUnit";
import TokenProp from "./TokenProp";

const getConditionWord = SearchFilterConditions.getText;

const getText = id => getLocaleText(`components.FilterInPinboard.${id}`);
const { NUMBER, TEXT, TIME } = FilterConditionType;

export default class PinboardFilterConditions {
  static IN = TokenProp.IN;

  static UNLIMIT = "$unlimit"; // 不填时值为不限。不限相当于这个筛选不生效。但是这样给查看者一个空的筛选器以便查看者筛选。

  static OPERATOR_DATE_BEFORE = "$datebefore";

  static OPERATOR_DATE_THIS = "$datethis";

  static OPERATOR_IN_RANGE = "$inRange";

  static OPERATOR_GT = "$gt";

  static TIME_DEFAULT_PROP = "t0";

  static DATE_TIME_WORD_FORMAT = "YYYY-MM-DD HH:mm:ss";

  static DATE_WORD_FORMAT = "YYYY-MM-DD";

  static DATE_TIME_FORMAT = "YYYYMMDDHHmmss";

  static DATE_FORMAT = "YYYYMMDD";

  static getPropFormatTime = (time, isShowTime) => {
    if (!time) return;
    return time.format(!isShowTime ? this.DATE_FORMAT : this.DATE_TIME_FORMAT);
  };

  static getWordFormatTime = (time, isDate) => dayjs(time).format(isDate ? this.DATE_WORD_FORMAT : this.DATE_TIME_WORD_FORMAT);

  static getWordFormatBetweenTime = (condition, isDate) => {
    const dateArr = condition.values.map(item => dayjs(item));
    return SearchFilterConditions.getBetWeenFormatWord(dateArr, condition.unit, isDate);
  };

  static getValuesFormatTime = (time, isShowTime) => {
    if (time.between.some(item => item)) {
      return [this.getPropFormatTime(time.between[0], isShowTime), this.getPropFormatTime(time.between[1], isShowTime)];
    }
    return [];
  };

  static getInitialTimeRange = () => ({
    type: PinboardFilterType.TIME_DIMENSION,
    prop: this.TIME_DEFAULT_PROP,
    word: getText("timeRange"),
    tuids: "all",
    conditions: [{ operator: this.UNLIMIT, values: [] }],
  });

  static getTimeRange = filters => {
    const oldTimeRange = filters.find(filter => filter.prop === this.TIME_DEFAULT_PROP);
    return oldTimeRange ? { ...oldTimeRange, word: this.getInitialTimeRange().word } : this.getInitialTimeRange();
  };

  static relationship = {
    [TIME]: [
      {
        word: SearchFilterConditions.BETWEEN_TIME,
        operator: this.OPERATOR_IN_RANGE,
        getTagContent: this.getWordFormatBetweenTime,
        getValues: this.getValuesFormatTime,
        getTime: condition => {
          return {
            type: SearchFilterConditions.BETWEEN_TIME,
            between: [dayjs(condition.values[0]), dayjs(condition.values[1])],
            formerUnit: condition.unit,
          };
        },
      },
      {
        word: SearchFilterConditions.AFTER_TIME,
        operator: this.OPERATOR_GT,
        getTagContent: (condition, isDate) => ` ${this.getWordFormatTime(condition.values[0], isDate)} ${getText("toToday")}`,
        getValues: (time, isShowTime) => [this.getPropFormatTime(time.after, isShowTime)],
        getTime: condition => {
          return { type: SearchFilterConditions.AFTER_TIME, after: dayjs(condition.values[0]), between: [] };
        },
      },
      {
        word: SearchFilterConditions.FORMER,
        operator: this.OPERATOR_DATE_BEFORE,
        getTagContent: condition =>
          ` ${getConditionWord(SearchFilterConditions.FORMER)}${condition.values[0]}${TimeUnit.getTextByAbbr(condition.unit)}`,
        getValues: time => [time.former],
        getTime: condition => ({
          type: SearchFilterConditions.FORMER,
          former: parseInt(condition.values[0]),
          formerUnit: condition.unit,
          between: [],
        }),
      },
      {
        word: SearchFilterConditions.DATE_THIS,
        operator: this.OPERATOR_DATE_THIS,
        getTagContent: condition => `${condition.values[0]}`,
        getValues: time => [time.thisValue],
        getTime: condition => ({
          type: SearchFilterConditions.DATE_THIS,
          thisValue: condition.values[0] || "",
          thisUnit: condition.unit,
          between: [],
        }),
      },
    ],
    [TEXT]: [
      { word: SearchFilterConditions.IN, operator: "$in", getTagContent: () => ({ start: getText("is") }) },
      { word: SearchFilterConditions.NOT_IN, operator: "$nin", getTagContent: () => ({ start: getText("isNot") }) },
      { word: SearchFilterConditions.LIKE_IN, operator: "$likeIn", getTagContent: () => ({ start: getText("contain") }) },
      { word: SearchFilterConditions.NOT_LIKE_IN, operator: "$nlikeIn", getTagContent: () => ({ start: getText("notContain") }) },
      {
        word: SearchFilterConditions.START_WITH,
        operator: "$sWith",
        getTagContent: () => ({ start: getText("with"), end: getText("start") }),
      },
      { word: SearchFilterConditions.END_WITH, operator: "$eWith", getTagContent: () => ({ start: getText("with"), end: getText("end") }) },
      { word: SearchFilterConditions.IS_NULL, operator: "$null", getTagContent: () => ({ start: getText("isNull") }) },
      { word: SearchFilterConditions.IS_NOT_NULL, operator: "$nnull", getTagContent: () => ({ start: getText("isNotNull") }) },
    ],
    [NUMBER]: [
      {
        word: SearchFilterConditions.BETWEEN,
        operator: "$inRange",
        getTagContent: arr => [`≤ ${arr[1]}`, `≥ ${arr[0]}`],
      },
      { word: SearchFilterConditions.GREATER_THAN, operator: "$gt", getTagContent: arr => [`> ${arr[0]}`] },
      { word: SearchFilterConditions.GREATER_EQUAL_THAN, operator: "$gte", getTagContent: arr => [`≥ ${arr[0]}`] },
      { word: SearchFilterConditions.LESS_THAN, operator: "$lt", getTagContent: arr => [`< ${arr[0]}`] },
      { word: SearchFilterConditions.LESS_EQUAL_THAN, operator: "$lte", getTagContent: arr => [`≤ ${arr[0]}`] },
      { word: SearchFilterConditions.EQUAL, operator: "$eq", getTagContent: arr => [`= ${arr[0]}`] },
      { word: SearchFilterConditions.NOT_EQUAL, operator: "$neq", getTagContent: arr => [`!= ${arr[0]}`] },
    ],
  };

  static getOperator = (type, searchFilterItem) => this.relationship[type].find(v => v.word === searchFilterItem.type)?.operator;

  static getWord = (type, pinboardFilterItem) => this.relationship[type].find(v => v.operator === pinboardFilterItem.operator)?.word;

  static getTimeValues = (time, isShowTime) =>
    this.relationship[TIME].find(item => item.word === time.type)?.getValues(time, isShowTime) || [];

  static getTime = (condition, initialTime) => {
    if (condition.operator === this.UNLIMIT) return initialTime;
    const time = this.relationship[TIME].find(item => item.operator === condition.operator)?.getTime(condition) || {};
    time.minFormerUnit = initialTime.minFormerUnit || "";
    return time;
  };

  static searchFilterToConditions = (searchFilter, pinboardFilter, isUnlimit) => {
    if (isUnlimit) return [{ operator: PinboardFilterConditions.UNLIMIT, values: [] }];
    const getValues = item => {
      if (item.type === SearchFilterConditions.BETWEEN) return [String(item.min), String(item.max)];
      return [String(item.value)];
    };
    const conditions = searchFilter.map(item => {
      return {
        operator: this.getOperator(NUMBER, item),
        values: getValues(item),
      };
    });
    return conditions;
  };

  static conditionsToSearchFilter = pinboardFilter => {
    if (pinboardFilter.conditions[0].operator === this.UNLIMIT) return [];
    return pinboardFilter.conditions.map(item => {
      const obj = { key: uuidV4(), type: this.getWord(NUMBER, item) };
      if (obj.type === SearchFilterConditions.BETWEEN) {
        obj.min = Number(item.values[0]);
        obj.max = Number(item.values[1]);
      } else obj.value = Number(item.values[0]);
      return obj;
    });
  };

  static getNumberTagArr = filter => {
    let resArr = [];
    filter.conditions.forEach(condition => {
      const getMeasureTagFunc = this.relationship[NUMBER].find(item => item.operator === condition.operator)?.getTagContent || _.noop;
      const content = getMeasureTagFunc(condition.values);
      resArr = resArr.concat(content);
    });
    return resArr;
  };

  static getTimeTagString = filter => {
    const { timeUnit: minTimeUnit } = filter;
    const isShowTime = TimeUnit.timeChoices.includes(minTimeUnit);
    const condition = filter.conditions[0];
    const getTimeTagFunc = this.relationship[TIME].find(item => item.operator === condition.operator)?.getTagContent || _.noop;
    return getTimeTagFunc(condition, !isShowTime);
  };

  static getTextTagObj = filter => {
    const condition = filter.conditions[0];
    const obj = this.relationship[TEXT].find(item => item.operator === condition.operator)?.getTagContent?.() || {};
    obj.values = condition.values;
    return obj;
  };

  static getDimensionType = filter => {
    if (FilterConditionType.numberChoices.includes(filter.dataType)) return NUMBER;
    return TEXT;
  };

  // 获取弹窗类型
  static getType = filter => {
    if (filter.type === PinboardFilterType.MEASURE) return NUMBER;
    if (filter.type === PinboardFilterType.TIME_DIMENSION) return TIME;
    if (filter.type === PinboardFilterType.DIMENSION) return this.getDimensionType(filter);
  };

  static canChoose = (pin, filter, isPinboard) => {
    // 报告初始时设置了筛选器都是禁用状态，所以获取card时这里先不根据字段少传筛选器。后端来筛
    if (!isPinboard) {
      // 筛选同维度/同时间维度对比时禁用卡片
      if (pin?.ignoreTimeSeriesFilter && filter.type === PinboardFilterType.TIME_DIMENSION) return false;
    }
    // 贡献度卡片不可选
    if (pin?.params?.cardType === CardType.ATTRIBUTION_ANALYSIS) return false;
    return true;
  };
}
