import React from "react";

const PinboardChart = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14C12.4477 14 12 13.5523 12 13L12 3C12 2.44771 12.4477 2 13 2ZM9 7C9 6.44771 8.55228 6 8 6C7.44772 6 7 6.44771 7 7L7 13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13L9 7ZM4 10.3333C4 9.78103 3.55229 9.33331 3 9.33331C2.44772 9.33331 2 9.78103 2 10.3333V13C2 13.5523 2.44772 14 3 14C3.55229 14 4 13.5523 4 13V10.3333ZM4 2.66669C4.32217 2.66669 4.58333 2.92785 4.58333 3.25002V4.08331H5.41667C5.73884 4.08331 6.00001 4.34448 6.00001 4.66665C6.00001 4.98881 5.73884 5.24998 5.41667 5.24998H4.58333V6.08335C4.58333 6.40552 4.32217 6.66669 4 6.66669C3.67783 6.66669 3.41667 6.40552 3.41667 6.08335V5.24998H2.58334C2.26117 5.24998 2.00001 4.98881 2.00001 4.66665C2.00001 4.34448 2.26117 4.08331 2.58334 4.08331H3.41667V3.25002C3.41667 2.92785 3.67783 2.66669 4 2.66669Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default PinboardChart;
