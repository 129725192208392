export default {
  tableNameRepeatOthers: "数据表名称与其他sheet对应的名称重复，请重新命名",
  tableNameRepeatOld: "数据表名称与已有表重名，请重新命名",
  tableNameHasSpace: "数据表名称含有空格，请重新命名",
  tableNameIsNull: "数据表名不能为空",
  colNameHasErrorChar: "列名称中含有特殊字符，请重新命名后再确认上传",
  colNameRepeat: "字段名称与表内其他字段重复，请重新命名",
  colNameHasSpace: "列名称含有空格，请重新命名后再确认上传",
  colNameIsNull: "列名称不能为空",
  colAllEmptySelected: "上传的数据表，必须至少选中一列数据",
  emptySelected: "至少需要选中一个数据表",
};
