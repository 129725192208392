export default {
  furtherExploration: "进一步探索",
  negative: "负相关 ",
  anomalyAnalysis: "异常分析",
  separator: "：",
  contrast: "对比",
  exploreBtnText: "探索",
  keyDimension: "关键维度",
  mobileKeyDimension: "维度",
  disappearingDimension: "消失维度",
  mobileDisappearingDimension: "消失",
  newDimension: "新增维度",
  mobileNewDimension: "新增",
  negativeMark: "负相关指标",
  mobileNegativeMark: "负相关",
  keyDimensionUpTitle: "上升最快",
  keyDimensionDownTitle: "下降最快",
  disappearingDimensionTitle: "本期降为 0",
  newDimensionTitle: "本期新增",
  negativeMarkTitle: "负相关指标",
  anomalyData: "反常数据",
  mobileAnomalyData: "反常",
  dimensionName: "维度名",
  dimensionValue: "维度值",
  diff: "变化量",
  impactOnChange: "对整体变化的影响占比",
  impactOnChangeTip:
    "当前维度下的指标变化占当期总变化的百分比。计算方法为（本期维度 A {指标} - 上期维度 A {指标}）÷（本期总 {指标 - 上期总 {指标}) × 100%",
  previousPeriod: "上期",
  thisPeriod: "本期",
};
