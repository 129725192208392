import React from "react";

const SystemConfig = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8074 7.62358L20.185 6.54349C19.6584 5.62957 18.4914 5.31429 17.5763 5.83869V5.83869C17.1406 6.09531 16.6208 6.16812 16.1314 6.04106C15.6421 5.91399 15.2233 5.59749 14.9676 5.16134C14.803 4.88412 14.7146 4.56836 14.7113 4.24601V4.24601C14.7261 3.72919 14.5311 3.22837 14.1708 2.85764C13.8104 2.48691 13.3153 2.27783 12.7982 2.27805H11.5442C11.0377 2.27804 10.552 2.47988 10.1947 2.83891C9.83742 3.19795 9.63791 3.68456 9.64034 4.19109V4.19109C9.62533 5.23689 8.77321 6.07678 7.7273 6.07667C7.40494 6.07332 7.08919 5.98491 6.81197 5.82038V5.82038C5.89679 5.29598 4.72985 5.61126 4.20327 6.52519L3.53508 7.62358C3.00914 8.53636 3.32013 9.70258 4.23073 10.2323V10.2323C4.82263 10.574 5.18726 11.2056 5.18726 11.889C5.18726 12.5725 4.82263 13.204 4.23073 13.5458V13.5458C3.32129 14.0719 3.00996 15.2353 3.53508 16.1453V16.1453L4.16666 17.2346C4.41338 17.6798 4.82734 18.0083 5.31693 18.1474C5.80652 18.2866 6.33137 18.2249 6.77535 17.976V17.976C7.21181 17.7213 7.73192 17.6515 8.22007 17.7822C8.70822 17.9128 9.12397 18.233 9.3749 18.6716C9.53943 18.9489 9.62784 19.2646 9.63119 19.587V19.587C9.63119 20.6435 10.4877 21.5 11.5442 21.5H12.7982C13.8512 21.5 14.7062 20.6491 14.7113 19.5961V19.5961C14.7088 19.088 14.9096 18.6 15.2689 18.2407C15.6282 17.8814 16.1162 17.6806 16.6243 17.6831C16.9459 17.6917 17.2604 17.7797 17.5397 17.9394V17.9394C18.4524 18.4653 19.6186 18.1543 20.1484 17.2437V17.2437L20.8074 16.1453C21.0625 15.7075 21.1325 15.186 21.0019 14.6963C20.8714 14.2067 20.551 13.7893 20.1117 13.5366V13.5366C19.6725 13.2839 19.3521 12.8665 19.2215 12.3769C19.091 11.8873 19.161 11.3658 19.4161 10.9279C19.582 10.6383 19.8221 10.3982 20.1117 10.2323V10.2323C21.0169 9.70286 21.3271 8.54346 20.8074 7.63274V7.63274V7.62358Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="12.1752" cy="11.889" r="2.63616" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default SystemConfig;
