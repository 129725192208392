import React from "react";

const Chart = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      d="M16.6756 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0711 3.92889 22 7.33333 22H16.6756C20.08 22 22 20.0711 22 16.6667V7.33333C22 3.92889 20.08 2 16.6756 2Z"
      fill="currentColor"
    />
    <path
      d="M7.36866 9.36914C6.91533 9.36914 6.54199 9.74247 6.54199 10.2047V17.0758C6.54199 17.5291 6.91533 17.9025 7.36866 17.9025C7.83088 17.9025 8.20421 17.5291 8.20421 17.0758V10.2047C8.20421 9.74247 7.83088 9.36914 7.36866 9.36914Z"
      fill="currentColor"
    />
    <path
      d="M12.0357 6.08887C11.5823 6.08887 11.209 6.4622 11.209 6.92442V17.0755C11.209 17.5289 11.5823 17.9022 12.0357 17.9022C12.4979 17.9022 12.8712 17.5289 12.8712 17.0755V6.92442C12.8712 6.4622 12.4979 6.08887 12.0357 6.08887Z"
      fill="currentColor"
    />
    <path
      d="M16.6402 12.9961C16.178 12.9961 15.8047 13.3694 15.8047 13.8316V17.0761C15.8047 17.5294 16.178 17.9028 16.6314 17.9028C17.0936 17.9028 17.4669 17.5294 17.4669 17.0761V13.8316C17.4669 13.3694 17.0936 12.9961 16.6402 12.9961Z"
      fill="currentColor"
    />
  </svg>
);
export default Chart;
