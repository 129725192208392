export default {
  measure: "Measures",
  searchInputPlaceholder: "Please enter the name of the measure you want to search for",
  addBtnText: "Add measure",
  addDerivativeBtnText: "Derived Measure",
  deleteSuccessToast: "Delete successfully",
  editBtnText: "Edit",
  deleteBtnText: "Delete",
  deleteConfirmTitle1: "Confirm to delete this ",
  deleteConfirmTitle2: "? This operation cannot be undone",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  id: "ID",
  keyword: "Keywords",
  synonyms: "Alias",
  measureType: "Measure type",
  currencyUnit: "Unit",
  formatType: "Display Format",
  formula: "Calculation Formula",
  derivativeMeasure: "Derived Measure",
  normalMeasure: "Fact Table Measure",
  formulaExtra: "Supports basic operations (addition, subtraction, multiplication, division) between indicators of existing models",
  formulaPlaceholder: "Please click the edit button on the right to create or modify the formula",
  noUnit: "None",
  models: "Application model",
  operations: "Settings",
  current: "Current measure: ",
  tableDeleteConfirmTitle: "Confirm to delete the measure",
  tableDeleteConfirmContent1: "The measure already has an applied model.",
  tableDeleteConfirmContent2: "After confirmation, it will be removed from all models that include this measure.",
  tableDeleteConfirmContent3: "This operation cannot be undone",
  pruneButtonText: "Clear Invalid Measures",
  pruneButtonTip: "Clear measures not used by the model",
  pruneConfirmTitle: "Confirming the deletion of invalid metrics",
  pruneDeriveConfirmTitle: "Confirming the deletion of invalid derive metrics",
  pruneConfirmContent: "After confirmation, all metrics that are not applied by the model will be cleared.",
};
