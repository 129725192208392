import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.ProjectAccountRole.${id}`);
export default class ProjectAccountRole {
  static USER = 0;

  static ADMIN = 1;

  static OWNER = 2;

  static choices = [ProjectAccountRole.USER, ProjectAccountRole.ADMIN, ProjectAccountRole.OWNER];

  static getText = role => {
    if (role === this.USER) return getText("user");
    if (role === this.ADMIN) return getText("admin");
    if (role === this.OWNER) return getText("owner");
    return "";
  };
}
