import React from "react";

const Cache = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.19997 3.37502C7.11712 3.37502 7.04996 3.44218 7.04996 3.52502V6.60002C7.04996 6.68287 7.11712 6.75002 7.19997 6.75002H8.17496C8.25781 6.75002 8.32497 6.68287 8.32497 6.60002V3.52502C8.32497 3.44218 8.25781 3.37502 8.17496 3.37502H7.19997Z"
        fill="currentColor"
      />
      <path
        d="M9.15 3.52502C9.15 3.44218 9.21716 3.37502 9.3 3.37502H10.275C10.3578 3.37502 10.425 3.44218 10.425 3.52502V6.60002C10.425 6.68287 10.3578 6.75002 10.275 6.75002H9.3C9.21716 6.75002 9.15 6.68287 9.15 6.60002V3.52502Z"
        fill="currentColor"
      />
      <path
        d="M11.4 3.37502C11.3171 3.37502 11.25 3.44218 11.25 3.52502V6.60002C11.25 6.68287 11.3171 6.75002 11.4 6.75002H12.375C12.4578 6.75002 12.525 6.68287 12.525 6.60002V3.52502C12.525 3.44218 12.4578 3.37502 12.375 3.37502H11.4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.65 2.625C4.65 2.00368 5.15368 1.5 5.775 1.5H13.875C14.4963 1.5 15 2.00368 15 2.625V15.375C15 15.9963 14.4963 16.5 13.875 16.5H4.125C3.50368 16.5 3 15.9963 3 15.375V8.86599C3 8.56762 3.11853 8.28147 3.3295 8.07049L4.60607 6.79393C4.6342 6.7658 4.65 6.72765 4.65 6.68787V2.625ZM13.875 2.625V15.375L4.125 15.375V8.86599L5.40156 7.58943C5.64067 7.35032 5.775 7.02602 5.775 6.68787V2.625H13.875Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Cache;
