import { getLocaleText } from "@/utils/locale";

const getTextById = id => getLocaleText(`components.Modals.UploadSynonymFileModal.${id}`);

export default class SynonymWordUploadStatus {
  static STARTED = 1;

  static SUCCESS = 2;

  static FAILURE = 3;

  static IMPORT_DATA = 4;

  static getText = t => {
    if (t === SynonymWordUploadStatus.STARTED) return getTextById("started");
    if (t === SynonymWordUploadStatus.SUCCESS) return getTextById("success");
    if (t === SynonymWordUploadStatus.FAILURE) return getTextById("failure");
    if (t === SynonymWordUploadStatus.IMPORT_DATA) return getTextById("inProgress");
    return "";
  };
}
