export default class AccountStatus {
  static DELETED = 0;

  static ACTIVE = 1;

  static APPLY = 2;

  static INACTIVE = 3;

  static DISABLE = 4;

  static choices = [this.APPLY, this.ACTIVE, this.DISABLE, this.INACTIVE];

  static inactiveChoices = [this.APPLY, this.INACTIVE];

  static getText = status => {
    if (status === this.DELETED) return "注销";
    if (status === this.ACTIVE) return "正常";
    if (status === this.APPLY) return "未激活";
    if (status === this.INACTIVE) return "休眠";
    if (status === this.DISABLE) return "禁⽤";
    return "";
  };

  static getColor = status => {
    if (status === this.DELETED) return "#FF4D4F";
    if (status === this.ACTIVE) return "#52C41A";
    if (status === this.APPLY) return "#D9D9D9";
    if (status === this.INACTIVE) return "#FADB14";
    if (status === this.DISABLE) return "#FF4D4F";
    return "#FFF";
  };
}
