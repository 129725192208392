export default {
  title: "Large Language Model Settings",
  status: "Service Status",
  statusDesc:
    "When enabled, users can utilize the capabilities of the large language model for automatic model configuration and interpretation of chart content data.",
  serverConfig: "Service Configuration",
  modalTitle: "Large Language Model Service Settings",
  providerQianfan: "Baidu AI Cloud",
  modalOkButtonText: "Test and Save",
  modalSaveButtonText: "Save",
  providerLabel: "Model Service",
  apiKeyLabel: "API Key",
  apiKeyPlaceholder: "API connection key",
  modelLabel: "Model",
  proxyLabel: "Proxy Address",
  qianfanApiKeyLabel: "Qianfan API Key",
  qianfanApiKeyPlaceholder: "Qianfan API Key of the application created on Qianfan platform",
  qianfanSecretLabel: "Qianfan Secret Key",
  qianfanSecretPlaceholder: "Qianfan Secret Key of the application created on Qianfan platform",
  proxyPlaceHolder: "Proxy address used for connecting to OpenAI. Can be left empty if the network connection is smooth.",
  setButtonText: "Set",
  checkConnectionButtonText: "Test Connection Status",
  checkConnectionButtonTip: "Test the connection status of the service. This will consume a certain number of tokens.",
  checkBtnDisabledTip: "Please turn on at least one related function switch",
  successfulToast: "Connection to the large language model service is normal.",
  failToast: "Failed to connect to the large language model service. Reason for failure:",
  cancelText: "Cancel",
  none: "none",
  saveSuccessToast: "Successfully Saved!",
  serviceAddressLabel: "Service Address",
  serviceAddressPlaceholder: "Please fill in your service address. Leaving it blank means using OpenAI service.",
  httpUrlLabel: "Synchronous HTTP call model address",
  httpUrlPlaceholder: "Enter the address of the large language model synchronization HTTP call interface",
  timeoutLabel: "Request timeout (seconds)",
  timeoutTitle: "Timeout for calling the large language model service request",
  timeoutDesc: "Request timeout",
  timeoutUnit: "Second",
  httpHeader: "HTTP Request Headers",
  OpenAILabel: "OpenAI interface",
  httpLabel: "Synchronous HTTP call model (beta)",
  httpRequestBody: "HTTP request body",
  httpResponseBody: "HTTP response body",
  relatedSwitch: "Related function switch",
  searchLabel: "IM Assisted Search",
  searchDesc: "The IM end will use the big model to assist users in querying the semantic structure and word segmentation of questions",
  promptBtnLabel: "Adjust Prompt",
  aiFieldLabel: "AI Field Type Configuration",
  aiKeywordLabel: "AI keyword configuration",
  aiSynonymLabel: "AI Alias Configuration",
  aiFieldDesc: "When configuring the model, the large model will be used to determine the field type information",
  aiKeywordDesc: "When configuring the model, the big model will be used to determine the keyword name information",
  aiSynonymDesc: "When configuring the model, the large model will be used to determine the alias information",
  insightServerLabel: "Data interpretation",
  insightServerDesc:
    "On the chart cards, the big model will be used to provide corresponding data interpretation, analysis and suggestions based on the data results.",
  sqlLabel: "SQL Error",
  sqlDesc:
    "When a chart card or background custom SQL error occurs, the big model will be used to interpret the SQL error in the database and make modification suggestions",
  chatLabel: "Chat",
  chatDesc: "Questions that are not matched as data questions will be communicated with the large language model and answered",
};
