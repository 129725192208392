export default {
  allRoleUsers: "All Users",
  all: "All",
  deleteUserSuccessToast: "User successfully deleted",
  modifySuccessToast: "Modification successful",
  addSuccessToast: "User added successfully",
  username: "Username",
  loginName: "Login Name",
  userEmail: "User Email/Phone Number",
  userBind: "Binding status",
  userDesc: "User Description",
  userRole: "User Role",
  accountType: "Account Type",
  firstAccountTypeIntro: "Different accounts have access to different workspace functionalities",
  secondAccountTypeIntro: "Data Expert: Full access to workspace functionalities (including workspace management)",
  thirdAccountTypeIntro: "Citizen Data Scientist: Search, view data, and generate reports",
  fourthAccountTypeIntro: "Citizen Data Explorer: Only has access to search and view functionalities",
  linkText: "Click to view details",
  accountProps: "Account Properties",
  accountPropsTip:
    "Accounts are categorized as regular and temporary. Temporary accounts have a validity period of 3 months starting from the activation date. After expiration, they will enter a dormant state and can be reactivated.",
  status: "Status",
  createdAt: "Registration Time",
  lastLoginAt: "Last Login At",
  operation: "Settings",
  workspace: "Workspace",
  edit: "Edit",
  activate: "Activate",
  enable: "Enable",
  disable: "Disable",
  delete: "Delete",
  confirm: "Confirm",
  cancel: "Cancel",
  activateUserConfirmText: "Are you sure you want to activate this user?",
  enableUserConfirmText: "Are you sure you want to enable this user?",
  disableUserConfirmText: "Are you sure you want to disable this user?",
  deleteUserConfirmText: "Are you sure you want to delete this user?",
  deleteUserTip:
    "After deletion, all user information will be permanently deleted, except for the operation records (visible to auditors in the audit logs).",
  allAccountStatus: "All Status",
  batchUpload: "Batch Upload",
  batchDelete: "Batch Delete",
  userSearchInputPlaceholder: "Enter user information to search",
  createUser: "Create User",
  businessUser: "Business User",
  unbindPrefix: "Confirm unbinding",
  unbindSuffix: "account binding",
  currentAccount: "Current account",
  bindTip: "Third-party login method(s) actually bound to the account",
  bindTitle: "Binding settings",
  unbindSuccess: "UnBind successfully",
  null: "None",
  email: "email",
  phone: "phone",
  separator: ":",
};
