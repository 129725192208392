export default {
  findOrCreateColumnNames: "Find or create column names",
  pleaseFillInTheFieldName: "Please fill in the field name",
  uploadThisColumn: "Upload this column",
  pleaseSelectADataType: "Please select the data type",
  theNameOfTheDataTableIs: "The name of the data table uploaded to the system",
  pleaseFillInTheNameOfTheDataTable: "Please fill in the data table name",
  theFirstRowIsTheTitleRow: "The first row of the table is the header row",
  dataTableHint:
    "For each column, you can select which fields to upload, modify the field name of each field and the original data source type. The original data source type will affect how data is processed and presented.",
};
