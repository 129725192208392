import React from "react";

const StarOutline = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.02087 7.80287C2.02019 7.80356 2.01951 7.80424 2.01883 7.80493M2.02087 7.80287L2.02205 7.8017L2.01883 7.80493M2.02087 7.80287C2.02019 7.80356 2.01951 7.80424 2.01883 7.80493M2.02087 7.80287L2.01883 7.80493M13.6096 1.99569L13.6015 1.97984C13.4247 1.6394 13.144 1.36216 12.7991 1.1903C12.3741 0.977344 11.8807 0.941375 11.4288 1.0912C10.971 1.24225 10.598 1.57171 10.3879 1.9972L8.11996 6.55959L3.04118 7.2931C3.03054 7.29464 3.01993 7.29634 3.00935 7.29822C2.65354 7.36138 2.32591 7.52361 2.06091 7.76357C2.04868 7.77465 2.03672 7.78603 2.02505 7.7977C2.01144 7.8113 1.99951 7.82403 1.98925 7.83539C1.32298 8.53798 1.33869 9.64607 2.02733 10.3302L2.03899 10.3416L5.7316 13.8925L4.85118 18.909L4.85112 18.909L4.84927 18.9202C4.78633 19.3046 4.84932 19.6999 5.03209 20.0473L5.0433 20.0681C5.51513 20.916 6.57624 21.2466 7.44775 20.803L7.44777 20.8031L7.45772 20.7979L12.017 18.4124L16.5418 20.7845L16.5634 20.7955C16.9065 20.9649 17.2939 21.0255 17.6738 20.9673L17.6738 20.9673L17.6824 20.9659C18.6484 20.8091 19.3063 19.9051 19.1633 18.9402C19.162 18.9314 19.1606 18.9227 19.1591 18.914L18.2784 13.8963L21.9483 10.3475C22.6074 9.72461 22.6901 8.70386 22.1363 7.98281C21.8488 7.60839 21.4255 7.36011 20.9538 7.29548L15.8798 6.56251L13.6096 1.99569Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StarOutline;
