export default {
  home: "Home",
  search: "Exploration",
  chat: "Chat(Beta)",
  pinboard: "Dashboard",
  my: "My",
  feedback: "Feedback",
  help: "Help",
  projectManage: "Workspace Settings",
  settings: "Settings",
  logout: "Sign Out",
  configuration: "Workspace Settings",
  workspace: "Workspace",
  theme: "Appearance",
  language: "Language",
  noWorkspaces: "No workspace",
  moreWorkspaces: "More workspaces",
  allWorkspaces: "All workspaces",
  switchWorkspace: "Switch Workspace",
  goToMessageCenter: "Notifications",
  openSettings: "Settings",
  goToConfiguration: "Workspace Settings",
  all: "All",
  helpCenter: "Help",
  messageCenter: "Notifications",
  confirm: "Confirm",
  cancel: "Cancel",
  logoutConfirmTitle: "Are you sure you want to sign out?",
  systemSettings: "Settings",
  databaseConnectFailTip: "Connection to data source lost or not configured, search function will not work, please contact workspace admin",
  databaseConnectFailOwnerTip: "Connection to data source lost or not configured, search function will not work, please go to ",
  appearance: "Appearance",
  helpDoc: "Help document",
  licenceExpireTipAffix: "The license has expired, and the data in this workspace will be completely deleted on ",
  licenceExpireTipSuffix: ". If necessary, please contact sales for renewal",
  expireFormat: "YYYY-MM-DD",
  goWorkspacesText: "Different workspaces have different data. You can view or switch workspaces here.",
  okBtn: "Ok",
  nextBtn: "View Now",
  signOut: "Sign out",
  instructionalVideo: "Instructional video",
};
