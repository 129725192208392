import React from "react";

const SendMail = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      d="M11.3333 13.6666H4.66665C2.66665 13.6666 1.33331 12.6666 1.33331 10.3333V5.66665C1.33331 3.33331 2.66665 2.33331 4.66665 2.33331H11.3333C13.3333 2.33331 14.6666 3.33331 14.6666 5.66665V10.3333C14.6666 12.6666 13.3333 13.6666 11.3333 13.6666Z"
      fill="currentColor"
    />
    <path
      d="M7.99999 8.57998C7.43999 8.57998 6.87333 8.40665 6.44 8.05331L4.35333 6.38665C4.13999 6.21331 4.1 5.89998 4.27333 5.68665C4.44666 5.47331 4.76 5.43332 4.97333 5.60665L7.05999 7.27332C7.56666 7.67998 8.42666 7.67998 8.93332 7.27332L11.02 5.60665C11.2333 5.43332 11.5533 5.46665 11.72 5.68665C11.8933 5.89998 11.86 6.21998 11.64 6.38665L9.55333 8.05331C9.12666 8.40665 8.55999 8.57998 7.99999 8.57998Z"
      fill="currentColor"
    />
  </svg>
);

export default SendMail;
