import { Tabs as AntdTabs } from "antd";
import { TabPaneProps, TabsProps } from "antd/es/tabs";
import React from "react";

import styles from "./index.module.scss";

const Tabs: React.FC<TabsProps> & {
  TabPane: typeof AntdTabs.TabPane;
} = ({ className, type, popupClassName, ...rest }) => {
  let tabsClassName = `${styles.a} ${styles.b} ${styles.tabs}`;
  let tabsPopupClassname = `${styles.a} ${styles.b} ${styles.popup}`;
  if (type === "card") tabsClassName = `${styles.a} ${styles.b} ${styles.cardTabs}`;
  if (className) tabsClassName += ` ${className}`;
  if (popupClassName) tabsPopupClassname += ` ${popupClassName}`;
  return <AntdTabs type={type} className={tabsClassName} popupClassName={tabsPopupClassname} {...rest} />;
};

Tabs.TabPane = AntdTabs.TabPane;

export type { TabPaneProps, TabsProps };

export default Tabs;
