import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.JoinType.${id}`);
export default class JoinType {
  static INNER = "inner";

  static LEFT = "left";

  static DEFAULT = "default";

  static getText = t => {
    if (t === JoinType.INNER) return getText("innerJoin");
    if (t === JoinType.LEFT) return getText("leftJoin");
  };
}
