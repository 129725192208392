import React from "react";

const CardTitle = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      d="M14.6756 0H5.33333C1.92889 0 0 1.92889 0 5.33333V14.6667C0 18.0711 1.92889 20 5.33333 20H14.6756C18.08 20 20 18.0711 20 14.6667V5.33333C20 1.92889 18.08 0 14.6756 0Z"
      fill="currentColor"
    />
    <path
      d="M5.36866 7.36914C4.91533 7.36914 4.54199 7.74247 4.54199 8.2047V15.0758C4.54199 15.5291 4.91533 15.9025 5.36866 15.9025C5.83088 15.9025 6.20421 15.5291 6.20421 15.0758V8.2047C6.20421 7.74247 5.83088 7.36914 5.36866 7.36914Z"
      fill="currentColor"
    />
    <path
      d="M10.0354 4.08887C9.58207 4.08887 9.20874 4.4622 9.20874 4.92442V15.0755C9.20874 15.5289 9.58207 15.9022 10.0354 15.9022C10.4976 15.9022 10.871 15.5289 10.871 15.0755V4.92442C10.871 4.4622 10.4976 4.08887 10.0354 4.08887Z"
      fill="currentColor"
    />
    <path
      d="M14.6399 10.9961C14.1777 10.9961 13.8043 11.3694 13.8043 11.8316V15.0761C13.8043 15.5294 14.1777 15.9028 14.631 15.9028C15.0932 15.9028 15.4665 15.5294 15.4665 15.0761V11.8316C15.4665 11.3694 15.0932 10.9961 14.6399 10.9961Z"
      fill="currentColor"
    />
  </svg>
);

export default CardTitle;
