import { CardType, PinType } from "@/components/Constants";

export default class CardSize {
  static SMALL_SQUARE = "smallSquare";

  static SMALL_RECTANGLE = "smallRectangle";

  static LONG_RECTANGLE = "longRectangle";

  static BIG_SQUARE = "bigSquare";

  static BIG_RECTANGLE = "bigRectangle";

  static HEADING_SIZE = "headingSize";

  static cardChooseList = {
    [this.SMALL_SQUARE]: {
      key: "smallSquare",
      style: { width: "12px", height: "12px" },
      w: 3,
      h: 6,
    },
    [this.SMALL_RECTANGLE]: {
      key: "smallRectangle",
      style: { width: "24px", height: "12px" },
      w: 6,
      h: 6,
    },
    [this.LONG_RECTANGLE]: {
      key: "longRectangle",
      style: { width: "48px", height: "12px" },
      w: 12,
      h: 6,
    },
    [this.BIG_SQUARE]: {
      key: "bigSquare",
      style: { width: "24px", height: "24px" },
      w: 6,
      h: 12,
    },
    [this.BIG_RECTANGLE]: {
      key: "bigRectangle",
      style: { width: "48px", height: "24px" },
      w: 12,
      h: 10,
    },
    [this.HEADING_SIZE]: {
      key: "headingSize",
      style: { width: "48px", height: "6px" },
      w: 12,
      h: 2,
    },
  };

  static getSizeList = pin => {
    let list = [this.SMALL_SQUARE, this.SMALL_RECTANGLE, this.LONG_RECTANGLE, this.BIG_SQUARE, this.BIG_RECTANGLE];
    if (pin.type === PinType.QUILL) list = [this.SMALL_SQUARE, this.SMALL_RECTANGLE, this.LONG_RECTANGLE];
    if (pin.type === PinType.HEADING) list = [this.HEADING_SIZE];
    if (pin?.params?.cardType === CardType.ATTRIBUTION_ANALYSIS) list = [this.BIG_RECTANGLE];
    return list.map(index => this.cardChooseList[index]);
  };
}
