import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.TimeUnit.${id}`);

export default class TimeUnit {
  static SECONDS = 8;

  static MINUTES = 1;

  static HOURS = 2;

  static DAYS = 3;

  static WEEKS = 4;

  static MONTHS = 5;

  static QUARTERS = 6;

  static YEARS = 7;

  static SECONDS_ABBR = "s";

  static MINUTES_ABBR = "m";

  static HOURS_ABBR = "h";

  static WEEKS_ABBR = "w";

  static DAYS_ABBR = "d";

  static MONTHS_ABBR = "M";

  static QUARTERS_ABBR = "Q";

  static YEARS_ABBR = "y";

  static DATE_TIME_FORMAT = "yyyy-MM-DD HH:mm:ss";

  static DATE_FORMAT = "yyyy-MM-DD";

  static DATE_STR = "date";

  static WEEK_STR = "week";

  static MONTH_STR = "month";

  static QUARTER_STR = "quarter";

  static YEAR_STR = "year";

  static SECONDLY = "10000";

  static MINUTELY = "10001";

  static HOURLY = "10002";

  static DAILY = "10003";

  static WEEKLY = "10004";

  static MONTHLY = "10005";

  static QUARTERLY = "10006";

  static YEARLY = "10007";

  static choices = [
    TimeUnit.SECONDS,
    TimeUnit.MINUTES,
    TimeUnit.HOURS,
    TimeUnit.DAYS,
    TimeUnit.WEEKS,
    TimeUnit.MONTHS,
    TimeUnit.QUARTERS,
    TimeUnit.YEARS,
  ];

  static offsetChoices = [TimeUnit.WEEKS];

  static defaultChoices = [TimeUnit.HOURS, TimeUnit.DAYS, TimeUnit.WEEKS, TimeUnit.MONTHS, TimeUnit.QUARTERS, TimeUnit.YEARS];

  static keywordTaskChoices = [TimeUnit.YEARS, TimeUnit.MONTHS, TimeUnit.DAYS];

  static WEEK_DETAILS = [
    getText("Monday"),
    getText("Tuesday"),
    getText("Wednesday"),
    getText("Thursday"),
    getText("Friday"),
    getText("Saturday"),
    getText("Sunday"),
  ];

  static weeklyOffsetDataSource = () => [
    { label: getText("Tuesday"), value: 6 },
    { label: getText("Wednesday"), value: 5 },
    { label: getText("Thursday"), value: 4 },
    { label: getText("Friday"), value: 3 },
    { label: getText("Saturday"), value: 2 },
    { label: getText("Sunday"), value: 1 },
  ];

  static weekLoopDataSource = () => [
    { label: getText("Sunday"), value: 0 },
    { label: getText("Monday"), value: 1 },
    { label: getText("Tuesday"), value: 2 },
    { label: getText("Wednesday"), value: 3 },
    { label: getText("Thursday"), value: 4 },
    { label: getText("Friday"), value: 5 },
    { label: getText("Saturday"), value: 6 },
  ];

  static getWeeklyOption = () => [
    { label: getText("Monday"), value: 1 },
    { label: getText("Tuesday"), value: 2 },
    { label: getText("Wednesday"), value: 3 },
    { label: getText("Thursday"), value: 4 },
    { label: getText("Friday"), value: 5 },
    { label: getText("Saturday"), value: 6 },
    { label: getText("Sunday"), value: 7 },
  ];

  static getWeekText = unit => {
    if (unit === 0) return getText("Sunday");
    if (unit === 1) return getText("Monday");
    if (unit === 2) return getText("Tuesday");
    if (unit === 3) return getText("Wednesday");
    if (unit === 4) return getText("Thursday");
    if (unit === 5) return getText("Friday");
    if (unit === 6) return getText("Saturday");
    return "";
  };

  static getUpper = timeUnit => {
    const index = TimeUnit.choices.indexOf(timeUnit);
    // 没找到 or 本身已经是最大的时间单位，返回空
    return index === -1 || index === TimeUnit.choices.length - 1 ? [] : TimeUnit.choices.slice(index, TimeUnit.choices.length - 1);
  };

  // 根据最小时间单位获取默认时间单位的选项
  static getUpperOptions = timeUnit => {
    const index = TimeUnit.choices.indexOf(timeUnit);
    const upper = index === -1 ? [] : TimeUnit.choices.slice(index, TimeUnit.choices.length);
    return upper.map(item => ({ value: item, label: TimeUnit.getText(item) }));
  };

  static getFunc = timeUnit => {
    if (timeUnit === TimeUnit.SECONDS) return "$secondly";
    if (timeUnit === TimeUnit.MINUTES) return "$minutely";
    if (timeUnit === TimeUnit.HOURS) return "$hourly";
    if (timeUnit === TimeUnit.DAYS) return "$daily";
    if (timeUnit === TimeUnit.WEEKS) return "$weekly";
    if (timeUnit === TimeUnit.MONTHS) return "$monthly";
    if (timeUnit === TimeUnit.QUARTERS) return "$quarterly";
    if (timeUnit === TimeUnit.YEARS) return "$yearly";
    return "";
  };

  static getBaseText = timeUnit => {
    return [TimeUnit.SECONDS, TimeUnit.MINUTES, TimeUnit.MINUTES].includes(timeUnit) ? "秒" : "天";
  };

  static getAbbr = timeUnit => {
    if (timeUnit === TimeUnit.SECONDS) return this.SECONDS_ABBR;
    if (timeUnit === TimeUnit.MINUTES) return this.MINUTES_ABBR;
    if (timeUnit === TimeUnit.HOURS) return this.HOURS_ABBR;
    if (timeUnit === TimeUnit.DAYS) return this.DAYS_ABBR;
    if (timeUnit === TimeUnit.WEEKS) return this.WEEKS_ABBR;
    if (timeUnit === TimeUnit.MONTHS) return this.MONTHS_ABBR;
    if (timeUnit === TimeUnit.QUARTERS) return this.QUARTERS_ABBR;
    if (timeUnit === TimeUnit.YEARS) return this.YEARS_ABBR;
    return "";
  };

  static getText = timeUnit => {
    if (timeUnit === TimeUnit.SECONDS) return getText("second");
    if (timeUnit === TimeUnit.MINUTES) return getText("minute");
    if (timeUnit === TimeUnit.HOURS) return getText("hour");
    if (timeUnit === TimeUnit.DAYS) return getText("day");
    if (timeUnit === TimeUnit.WEEKS) return getText("week");
    if (timeUnit === TimeUnit.MONTHS) return getText("month");
    if (timeUnit === TimeUnit.QUARTERS) return getText("quarter");
    if (timeUnit === TimeUnit.YEARS) return getText("year");
    return "";
  };

  static getTextByAbbr = timeUnit => {
    if (timeUnit === TimeUnit.SECONDS_ABBR) return getText("secondAbbr");
    if (timeUnit === TimeUnit.MINUTES_ABBR) return getText("minuteAbbr");
    if (timeUnit === TimeUnit.HOURS_ABBR) return getText("hourAbbr");
    if (timeUnit === TimeUnit.DAYS_ABBR) return getText("dayAbbr");
    if (timeUnit === TimeUnit.WEEKS_ABBR) return getText("weekAbbr");
    if (timeUnit === TimeUnit.MONTHS_ABBR) return getText("monthAbbr");
    if (timeUnit === TimeUnit.QUARTERS_ABBR) return getText("quarterAbbr");
    if (timeUnit === TimeUnit.YEARS_ABBR) return getText("yearAbbr");
    return "";
  };

  static getAbbrByText = text => {
    if (text === "秒") return TimeUnit.SECONDS_ABBR;
    if (text === "分") return TimeUnit.MINUTES_ABBR;
    if (text === "时") return TimeUnit.HOURS_ABBR;
    if (text === "天") return TimeUnit.DAYS_ABBR;
    if (text === "周") return TimeUnit.WEEKS_ABBR;
    if (text === "月") return TimeUnit.MONTHS_ABBR;
    if (text === "季") return TimeUnit.QUARTERS_ABBR;
    if (text === "年") return TimeUnit.YEARS_ABBR;
    return "";
  };

  static getKeywordTaskText = timeUnit => {
    if (timeUnit === TimeUnit.DAYS) return getText("everyDay");
    if (timeUnit === TimeUnit.MONTHS) return getText("everyMonth");
    if (timeUnit === TimeUnit.YEARS) return getText("everyYear");
    return "";
  };

  static getTextFromFunc = func => {
    for (let i = 0; i < TimeUnit.choices.length; i++) {
      const tu = TimeUnit.choices[i];
      if (TimeUnit.getFunc(tu) === func) {
        return TimeUnit.getText(tu);
      }
    }
    return "";
  };

  static getTextFromAbbr = abbr => {
    for (let i = 0; i < TimeUnit.choices.length; i++) {
      const tu = TimeUnit.choices[i];
      if (TimeUnit.getAbbr(tu) === abbr) {
        return TimeUnit.getText(tu);
      }
    }
    return "";
  };

  static getTimeFuncFormOptions = func => {
    const options = {
      [TimeUnit.getFunc(TimeUnit.SECONDS)]: [TimeUnit.SECONDS, TimeUnit.MINUTES, TimeUnit.HOURS],
      [TimeUnit.getFunc(TimeUnit.MINUTES)]: [TimeUnit.MINUTES, TimeUnit.HOURS],
      [TimeUnit.getFunc(TimeUnit.HOURS)]: [TimeUnit.HOURS, TimeUnit.DAYS],
      [TimeUnit.getFunc(TimeUnit.DAYS)]: [TimeUnit.DAYS, TimeUnit.WEEKS, TimeUnit.MONTHS, TimeUnit.QUARTERS, TimeUnit.YEARS],
      [TimeUnit.getFunc(TimeUnit.WEEKS)]: [TimeUnit.WEEKS, TimeUnit.MONTHS, TimeUnit.QUARTERS, TimeUnit.YEARS],
      [TimeUnit.getFunc(TimeUnit.MONTHS)]: [TimeUnit.MONTHS, TimeUnit.QUARTERS, TimeUnit.YEARS],
      [TimeUnit.getFunc(TimeUnit.QUARTERS)]: [TimeUnit.QUARTERS, TimeUnit.YEARS],
    };
    return options[func].map(item => ({ value: TimeUnit.getFunc(item), label: TimeUnit.getText(item) }));
  };

  static timeChoices = [this.SECONDS, this.MINUTES, this.HOURS];

  static getDefaultTimeUnit = minTimeUnit => (this.timeChoices.includes(minTimeUnit) ? this.DAYS : minTimeUnit);

  static getFrequencyOption = () => [
    { value: TimeUnit.DAYS, label: getText("everyDay") },
    { value: TimeUnit.WEEKS, label: getText("everyWeek") },
    { value: TimeUnit.MONTHS, label: getText("everyMonth") },
  ];

  static getRangePicker = unit => {
    if (unit === TimeUnit.WEEKS_ABBR) return TimeUnit.WEEK_STR;
    if (unit === TimeUnit.MONTHS_ABBR) return TimeUnit.MONTH_STR;
    if (unit === TimeUnit.QUARTERS_ABBR) return TimeUnit.QUARTER_STR;
    if (unit === TimeUnit.YEARS_ABBR) return TimeUnit.YEAR_STR;
    return TimeUnit.DATE_STR;
  };

  static getTimeUnit = num => {
    if (num === TimeUnit.SECONDLY) return TimeUnit.SECONDS_ABBR;
    if (num === TimeUnit.MINUTELY) return TimeUnit.MINUTES_ABBR;
    if (num === TimeUnit.HOURLY) return TimeUnit.HOURS_ABBR;
    if (num === TimeUnit.WEEKLY) return TimeUnit.WEEKS_ABBR;
    if (num === TimeUnit.MONTHLY) return TimeUnit.MONTHS_ABBR;
    if (num === TimeUnit.QUARTERLY) return TimeUnit.QUARTERS_ABBR;
    if (num === TimeUnit.YEARLY) return TimeUnit.YEARS_ABBR;
    return TimeUnit.DAYS_ABBR;
  };
}
