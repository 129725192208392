export default class AuditEventType {
  static Login = 1; // 登录

  static Logout = 2; // 退出登录

  static ResetPassword = 3; // 重置密码

  static BindPhone = 4; // 绑定手机号

  static UpdateClient = 6; // 修改客户

  static CreateAccount = 8; // 创建用户

  static UpdateAccount = 9; // 修改用户

  static DeleteAccount = 10; // 删除用户

  static CreateProject = 11; // 创建工作区

  static UpdateProject = 12; // 修改工作区

  static DeleteProject = 13; // 删除工作区

  static CreateMember = 14; // 创建成员

  static UpdateMember = 15; // 修改成员

  static DeleteMember = 16; // 删除成员

  static CreateGroup = 17; // 创建成员组

  static UpdateGroup = 18; // 修改成员组

  static DeleteGroup = 19; // 删除成员组

  static GroupAddMember = 20; // 用户组添加用户

  static GroupRemoveMember = 21; // 用户组删除用户

  static PinboardAddCard = 25; // 报告添加卡片

  static CreatePinboardShare = 31; // 分享报告

  static CreateDateTag = 36; // 创建日期标签

  static UpdateDateTag = 37; // 修改日期标签

  static DeleteDateTag = 38; // 删除日期标签

  static ResetDateTag = 39; // 重置日期标签

  static SEARCH = 40; // 搜索问题

  static SEND_EMAIL = 41; // 发送邮箱

  static DOWNLOAD_AS_CSV = 42; // 下载CSV

  static DOWNLOAD_AS_PNG = 43; // 下载图片

  static EXPORT_PINBOARD = 44; // 报告导出

  static choices = [
    this.Login,
    this.Logout,
    this.ResetPassword,
    this.BindPhone,
    this.UpdateClient,
    this.CreateAccount,
    this.UpdateAccount,
    this.DeleteAccount,
    this.CreateProject,
    this.UpdateProject,
    this.DeleteProject,
    this.CreateMember,
    this.UpdateMember,
    this.DeleteMember,
    this.CreateGroup,
    this.UpdateGroup,
    this.DeleteGroup,
    this.GroupAddMember,
    this.GroupRemoveMember,
    this.PinboardAddCard,
    this.CreatePinboardShare,
    this.CreateDateTag,
    this.UpdateDateTag,
    this.DeleteDateTag,
    this.ResetDateTag,
    this.SEARCH,
    this.SEND_EMAIL,
    this.DOWNLOAD_AS_CSV,
    this.DOWNLOAD_AS_PNG,
    this.EXPORT_PINBOARD,
  ];

  static getText = name => {
    if (name === AuditEventType.Login) return "登录";
    if (name === AuditEventType.Logout) return "退出登录";
    if (name === AuditEventType.ResetPassword) return "重置密码";
    if (name === AuditEventType.BindPhone) return "绑定手机号";
    if (name === AuditEventType.UpdateClient) return "修改系统配置";
    if (name === AuditEventType.CreateAccount) return "创建用户";
    if (name === AuditEventType.UpdateAccount) return "修改用户";
    if (name === AuditEventType.DeleteAccount) return "删除用户";
    if (name === AuditEventType.CreateProject) return "创建工作区";
    if (name === AuditEventType.UpdateProject) return "修改工作区";
    if (name === AuditEventType.DeleteProject) return "删除工作区";
    if (name === AuditEventType.CreateMember) return "添加成员";
    if (name === AuditEventType.UpdateMember) return "修改成员";
    if (name === AuditEventType.DeleteMember) return "删除成员";
    if (name === AuditEventType.CreateGroup) return "创建成员组";
    if (name === AuditEventType.UpdateGroup) return "修改成员组";
    if (name === AuditEventType.DeleteGroup) return "删除成员组";
    if (name === AuditEventType.GroupAddMember) return "用户组添加用户";
    if (name === AuditEventType.GroupRemoveMember) return "用户组删除用户";
    if (name === AuditEventType.PinboardAddCard) return "添加卡片到报告";
    if (name === AuditEventType.CreatePinboardShare) return "分享报告";
    if (name === AuditEventType.CreateDateTag) return "创建日期标签";
    if (name === AuditEventType.UpdateDateTag) return "修改日期标签";
    if (name === AuditEventType.DeleteDateTag) return "删除日期标签";
    if (name === AuditEventType.ResetDateTag) return "重置日期标签";
    if (name === AuditEventType.SEARCH) return "搜索问题";
    if (name === AuditEventType.SEND_EMAIL) return "发送邮箱";
    if (name === AuditEventType.DOWNLOAD_AS_CSV) return "下载 CSV";
    if (name === AuditEventType.DOWNLOAD_AS_PNG) return "下载图片";
    if (name === AuditEventType.EXPORT_PINBOARD) return "报告导出";
    return "";
  };
}
