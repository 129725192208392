import React from "react";

const Delete = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.3335 7.50016C8.79373 7.50016 9.16683 7.87326 9.16683 8.3335V13.7502C9.16683 14.2104 8.79373 14.5835 8.3335 14.5835C7.87326 14.5835 7.50016 14.2104 7.50016 13.7502V8.3335C7.50016 7.87326 7.87326 7.50016 8.3335 7.50016Z"
        fill="currentColor"
      />
      <path
        d="M12.5002 8.3335C12.5002 7.87326 12.1271 7.50016 11.6668 7.50016C11.2066 7.50016 10.8335 7.87326 10.8335 8.3335V13.7502C10.8335 14.2104 11.2066 14.5835 11.6668 14.5835C12.1271 14.5835 12.5002 14.2104 12.5002 13.7502V8.3335Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.03725 0.833496C7.73292 0.833496 7.45279 0.999394 7.3065 1.26626L6.17331 3.3335H1.66683C1.20659 3.3335 0.833496 3.70659 0.833496 4.16683C0.833496 4.62707 1.20659 5.00016 1.66683 5.00016H2.91683V18.3335C2.91683 18.7937 3.28993 19.1668 3.75016 19.1668H16.2502C16.7104 19.1668 17.0835 18.7937 17.0835 18.3335V5.00016H18.3335C18.7937 5.00016 19.1668 4.62707 19.1668 4.16683C19.1668 3.70659 18.7937 3.3335 18.3335 3.3335H13.8318L12.7247 1.27249C12.5795 1.00215 12.2975 0.833496 11.9906 0.833496H8.03725ZM11.9399 3.3335L11.4923 2.50016H8.53076L8.07396 3.3335H11.9399ZM4.5835 5.00016V17.5002H15.4168V5.00016H4.5835Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Delete;
