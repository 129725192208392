import axios from "axios";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Auth, { axiosError } from "@/components/Auth";
import { AsyncTaskType, SyncKeywordTaskStatus } from "@/components/Constants";
import { getV2AsyncTaskFullUrlMA, getV2DebounceTaskMA } from "@/components/Urls";
import { isTrueArray } from "@/utils/common";

const { PENDING, STARTED } = SyncKeywordTaskStatus;
const { SYNC_KEYWORD, GENERATE_DICT, UPDATE_DATABASE_SCHEMA, UPLOAD_WORKSPACE_CONFIGURATION, GENERATE_FUZZY_DICT } = AsyncTaskType;

const unFinishTaskStatus = [PENDING, STARTED];

interface UpdateAsyncTaskValues {
  projectId: string | number;
  taskType: number;
  successCallback: () => void;
  failCallback?: (e?: EventListener) => void;
}

interface AsyncTaskContextValue {
  drawerVisible: boolean;
  setDrawerVisible: (v?: boolean) => void;
  inProgressTaskList: Array<any>;
  updateInProgressTaskList: (v?: boolean) => void;
  updateAsyncTask: (v?: UpdateAsyncTaskValues) => void;
}

const allowTaskType = [SYNC_KEYWORD, GENERATE_DICT, UPDATE_DATABASE_SCHEMA, UPLOAD_WORKSPACE_CONFIGURATION, GENERATE_FUZZY_DICT];

const AsyncTaskContext = React.createContext<AsyncTaskContextValue>({
  drawerVisible: false,
  setDrawerVisible: _.noop,
  inProgressTaskList: [],
  updateInProgressTaskList: _.noop,
  updateAsyncTask: _.noop,
} as AsyncTaskContextValue);
export default AsyncTaskContext;

// 管理员界面 Context
export const AsyncTaskProvider: React.FC = ({ children }) => {
  const signal = axios.CancelToken.source();
  const timerRef = useRef<number>();

  const [inProgressTaskList, setInProgressTaskList] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);

  // 加载进行中 & 排队中任务
  const updateInProgressTaskList = (isUpdateOperation = false) => {
    const projectId = Auth.getProjectId();
    if (!projectId) return clearTimeout(timerRef.current);

    const params = {
      status: unFinishTaskStatus.join(","),
      taskType: allowTaskType.join(","),
      offset: 0,
      limit: 6,
    };
    axios
      .get(getV2AsyncTaskFullUrlMA(projectId), { cancelToken: signal.token, params })
      .then(r => {
        const list = r.data.data || [];
        // 有变化才更新，减少不必要的子组件刷新
        setInProgressTaskList(oldList => (JSON.stringify(list) === JSON.stringify(oldList) ? oldList : list));

        // 设置下一次查询时间
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = window.setTimeout(updateInProgressTaskList, (isTrueArray(list) || isUpdateOperation ? 5 : 30) * 1000); // isUpdateOperation，是否是刚刚触发了同步任务，刚触发，下一次查询应该缩短时间
      })
      .catch(axiosError);
    return () => {
      clearTimeout(timerRef.current);
      signal.cancel();
    };
  };
  useEffect(() => updateInProgressTaskList(), []);

  // 触发异步任务同步
  const updateAsyncTask = ({ projectId, taskType, successCallback = _.noop, failCallback = axiosError }) => {
    if (!projectId) return;
    axios
      .post(getV2DebounceTaskMA(projectId), { taskType })
      .then(() => {
        successCallback();
        updateInProgressTaskList(true);
      })
      .catch(failCallback);
  };

  const values = useMemo(
    () => ({
      drawerVisible,
      setDrawerVisible,
      inProgressTaskList,
      updateInProgressTaskList,
      updateAsyncTask,
    }),
    [drawerVisible, inProgressTaskList, updateInProgressTaskList]
  );
  return <AsyncTaskContext.Provider value={values as AsyncTaskContextValue}>{children}</AsyncTaskContext.Provider>;
};
