import * as React from "react";
import { SVGProps } from "react";

const CloseLightOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      d="M10.3512 9.63268C10.5496 9.83109 10.5496 10.1528 10.3512 10.3512C10.1528 10.5496 9.83109 10.5496 9.63268 10.3512L6.00015 6.71866L2.36762 10.3512C2.1692 10.5496 1.84752 10.5496 1.6491 10.3512C1.45069 10.1528 1.45069 9.83109 1.6491 9.63268L5.28164 6.00015L1.64881 2.36732C1.4504 2.16891 1.4504 1.84722 1.64881 1.64881C1.84722 1.4504 2.16891 1.4504 2.36732 1.64881L6.00015 5.28164L9.63297 1.64881C9.83138 1.4504 10.1531 1.4504 10.3515 1.64881C10.5499 1.84722 10.5499 2.16891 10.3515 2.36732L6.71866 6.00015L10.3512 9.63268Z"
      fill="currentColor"
    />
  </svg>
);
export default CloseLightOutlined;
