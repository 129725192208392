export default {
  lineChart: "Line",
  barChart: "Column",
  pieChart: "Pie",
  sankeyChart: "Sankey",
  funnelChart: "Funnel",
  stackChart: "Stacked Column",
  percentStackChart: "Percent Stacked Column",
  scatterChart: "Scatter",
  valueScatterChart: "Value Scatter",
  heatMap: "Heatmap",
  table: "Table",
  map: "Geo Area",
  stackBarChart: "Stacked Column",
  bubbleChart: "Bubble",
  wordCloud: "Word Cloud",
  stackAreaChart: "Stacked Area",
  mixedBarLineChart: "Line Column",
  horizontalBarChart: "Bar",
  mixedStackLineChart: "Line Stacked Column",
  paretoChart: "Pareto",
  pivotTable: "Pivot Table",
  animationBar: "Animation",
  needOneDimensionOneMeasure: "At least 1 dimension and 1 measure needed for this type of chart",
  needOneDimensionTwoMeasures: "At least 1 dimension and 2 measures needed for this type of chart",
  needTwoDimensionsOneMeasure: "At least 2 dimensions and 1 measure needed for this type of chart",
  needTwoDimensionsTwoMeasures: "At least 2 dimensions and 2 measures needed for this type of chart",
  needThreeItems: "At least 1 dimension and 2 measures, or 2 dimensions and 1 measure needed for this type of chart",
  needOneAreaDimensionOneMeasure: "At least 1 geo dimension and 1 measure needed for this type of chart",
  stackAreaChartHoverText: "At least 2 dimension and 1 measure needed for this type of chart, there will be only one Y-axis",
  stackPercentChartHoverText:
    "2 or more dimensions and 1 or more measures needed, at least one measure is either all positive or all negative",
  pieLikedChartHoverText: "1 or more dimensions and 1 or more measures needed, at least one measure is either all positive or all negative",
  paretoChartHoverText: "1 or more dimensions and 1 measures needed for this type of chart",
  animationBarChartHoverText: "1 time dimension, at least 1 dimension, and at least 1 measure.",
  YOY: "YoY",
  RING: "Previous Period",
  boxPlot: "Box Plot",
  bar3D: "Column(3D)",
  histogram: "Histogram",
};
