export default {
  disableSearchAdviceLabel: "搜索建议",
  disableSearchAdviceTitle: "搜索建议",
  disableSearchAdviceHelp:
    "帮助新用户快速了解如何搜索，帮助老用户快速查询常用问题，包含“你可以试试”“热门搜索”“我常搜索”“了解排名”“了解趋势”等子模块",
  disableSearchAdviceExtra: "关闭后首页将不再展示“你可以试试”“热门搜索”等推荐模块",
  disableIsWatchedLabel: "我的观察",
  disableIsWatchedTitle: "我的观察",
  disableIsWatchedHelp: "用户可以将需要观察的内容添加到首页，从而快速了解关注的指标或报告",
  disableIsWatchedExtra: "关闭后首页将不再展示关注的卡片和报告",
  disableLatestSearchLabel: "最近搜索",
  disableLatestSearchExtra: "关闭后首页将不再展示用户的历史搜索问题",
  titleLabel: "网页标题",
  titleExtra: "浏览器标签和分享链接的网页标题，不填写将显示默认内容",
  titlePlaceholder: "智能数据搜索",
  descriptionLabel: "网页描述",
  descriptionExtra: "分享链接附带的网页描述，不填写将显示默认内容",
  descriptionPlaceholder: "智能搜索",
  enableWatermarkLabel: "水印",
  enableWatermarkExtra: "开启后会在全局界面均生成水印",
  lightHomeLogoLabel: "浅色首页LOGO",
  darkHomeLogoLabel: "深色首页LOGO",
  lightDisableCornerLogoLabel: "浅色角标LOGO",
  darkDisableCornerLogoLabel: "深色角标LOGO",
  homeLogoLabel: "首页LOGO",
  homeLogoUploadExtra: ["首页搜索框上方的完整LOGO", "推荐使用尺寸为200x100px，大小不超过500K的透明底PNG图片"],
  disableCornerLogoLabel: "角标LOGO",
  disableCornerLogoExtra: "关闭后导航栏LOGO位置将展示为“首页”",
  disableCornerLogoUploadExtra: ["导航栏左侧和页面底部的角标LOGO", "推荐使用尺寸为100x100px，大小不超过500K的透明底PNG图片"],
  cornerLogoLabel: "标签页LOGO",
  faviconLabel: "标签页LOGO",
  cornerLogoExtra: ["浏览器标签上展示的LOGO", "推荐使用尺寸为100x100px，大小不超过500K的透明底PNG图片"],
  backendLogoLabel: "后台LOGO",
  backendLogoExtra: "未上传时工作区配置、系统设置和审计日志的LOGO与首页一致",
  backendLogoUploadExtra: ["工作区配置、系统设置和审计日志的LOGO", "推荐使用尺寸为200x100px，大小不超过500K的透明底PNG图片"],
  enableVoiceLabel: "允许语音搜索",
  enableVoiceExtra: "开启后搜索问题允许语音输入",
  disableCardDownloadLabel: "数据下载",
  disableCardDownloadExtra: "关闭后无法下载卡片数据、下载PNG图片及发送邮件",
  disableCardShareLabel: "卡片分享",
  disableCardShareExtra: "关闭后无法将卡片共享给其他人",
  guideDividerTitle: "新手引导",
  disableFrontendGuideLabel: "前台新手引导",
  frontendGuideExtra: "关闭后用户初次登录将不再展示新手引导页",
  disableBackendGuideLabel: "后台新人手册",
  backendGuideExtra: "关闭后工作区设置将不再展示新人手册和教学视频",
  help: "帮助文档地址：",
  helpExtra: "自定义帮助文档，如果不填写，用户会看到默认帮助文档",
  setUp: "设置",
  name: "名称",
  themeColor: "主题色",
  operation: "操作",
  setAsDefaultBtnText: "设为默认",
  edit: "编辑",
  delete: "删除",
  openThemeColor: "自定义图表主题色开启",
  closeThemeColor: "自定义图表主题色关闭",
  updateThemeColorSuccessToast: "图表主题色更新成功",
  switchThemeColorTip: "开启后可以自定义图表的主题色",
  addThemeColor: "新增主题色",
  editThemeColor: "编辑主题色",
  requestErrorToast: "操作失败，请稍后再试",
  opened: "已开启",
  closed: "已关闭",
  personalizedFunction: "个性化功能",
  personalizedStyle: "个性化样式",
  cancel: "取消",
  closeConfirm: "确认关闭",
  searchFunction: "搜索功能",
  disableShareConfirmTitle: "所有分享链接将全部失效",
  formerDisableShareConfirmContent: "您正在关闭卡片分享功能，关闭后所有已有的分享链接都将失效。",
  latterDisableShareConfirmContent: "确认关闭卡片分享功能？",
  saveSuccessToast: "保存成功",
  confirm: "确认",
  deleteSuccessToast: "删除成功",
  appearance: "外观",
  themeColorSetting: "自定义主题色设置",
  homeDisplayModule: "首页展示模块",
  browserTag: "浏览器标签",
  warningMessage:
    "当前账号超额（即账号数量＞配额数量），系统各类功能无法正常使用，请将各类型的账号数量（包含未激活、正常、禁用和休眠状态的账号）减至配额数量",
  themeModeLabel: "主题模式",
  lightModeBtnText: "浅色模式",
  darkModeBtnText: "深色模式",
  themeChangeSuccessToast: "主题模式修改成功",
  themeModeExtra: "仅影响前台搜索时的颜色主题，工作区配置与系统设置不受影响",
};
