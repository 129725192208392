import React from "react";

const PinboardTabAll = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66683 9.16668C6.66683 8.70644 7.03993 8.33334 7.50016 8.33334H12.5002C12.9604 8.33334 13.3335 8.70644 13.3335 9.16668C13.3335 9.62691 12.9604 10 12.5002 10H7.50016C7.03993 10 6.66683 9.62691 6.66683 9.16668Z"
      fill="currentColor"
    />
    <path
      d="M7.50016 12.0833C7.03993 12.0833 6.66683 12.4564 6.66683 12.9167C6.66683 13.3769 7.03993 13.75 7.50016 13.75H10.8335C11.2937 13.75 11.6668 13.3769 11.6668 12.9167C11.6668 12.4564 11.2937 12.0833 10.8335 12.0833H7.50016Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6668 1.66663L16.6668 6.66663V16.6666C16.6668 17.5871 15.9206 18.3333 15.0002 18.3333H5.00016C4.07969 18.3333 3.3335 17.5871 3.3335 16.6666V3.33329C3.3335 2.41282 4.07969 1.66663 5.00016 1.66663H11.6668ZM5.00016 16.6666H15.0002V7.35698L10.9765 3.33329L5.00016 3.33329L5.00016 16.6666Z"
      fill="currentColor"
    />
  </svg>
);

export default PinboardTabAll;
