export default {
  custom: "Custom",
  fixed: "Fixed",
  max: "Max",
  min: "Min",
  average: "Avg",
  median: "Median",
  percentile: "Percentile",
  rank: "Rank",
  calc: "Computation",
};
