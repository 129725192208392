import React from "react";

const ProfessionalTier = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_70_13876)">
      <g clipPath="url(#clip1_70_13876)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0197 3.12814C13.1331 3.29083 13.1906 3.4713 13.1906 3.66954L13.1919 9.36388C13.1919 9.53751 13.1468 9.69884 13.0579 9.8465C12.9691 9.99552 12.8474 10.1117 12.6943 10.1951L7.47434 13.0416C7.33626 13.1209 7.1845 13.1606 7.0218 13.1606C6.85911 13.1606 6.70735 13.1209 6.56926 13.0416L1.34934 10.1951C1.19622 10.1104 1.07454 9.99415 0.985669 9.8465C0.896802 9.69747 0.851685 9.53751 0.851685 9.36388V3.66954C0.851685 3.4713 0.909106 3.29083 1.02258 3.12814C1.13606 2.96544 1.28782 2.84923 1.47512 2.7795L6.69368 0.881848C6.80305 0.8422 6.91106 0.823059 7.02043 0.823059C7.12981 0.823059 7.23782 0.8422 7.34719 0.881848L12.5671 2.7795C12.7558 2.84923 12.9062 2.96544 13.0197 3.12814ZM1.847 3.6545L7.0218 5.53712L12.1966 3.6545L7.0218 1.77189L1.847 3.6545ZM7.49622 11.9506L12.2417 9.36251V4.64708L7.49622 6.37521V11.9506ZM6.73737 6.56268L1.847 4.64708V9.36388L6.56926 11.7041L6.73737 6.56268Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_70_13876">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
      <clipPath id="clip1_70_13876">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ProfessionalTier;
