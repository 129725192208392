import React from "react";

const More = props => (
  <svg {...props} width="1em" height=".4em" viewBox="0 0 12 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 0.666504C5.73629 0.666504 5.47851 0.744703 5.25924 0.891211C5.03997 1.03772 4.86908 1.24596 4.76816 1.48959C4.66724 1.73323 4.64084 2.00132 4.69229 2.25996C4.74373 2.5186 4.87072 2.75618 5.05719 2.94265C5.24366 3.12912 5.48124 3.2561 5.73988 3.30755C5.99852 3.359 6.26661 3.33259 6.51024 3.23168C6.75388 3.13076 6.96212 2.95986 7.10863 2.7406C7.25514 2.52133 7.33333 2.26355 7.33333 1.99984C7.33333 1.64622 7.19286 1.30708 6.94281 1.05703C6.69276 0.80698 6.35362 0.666504 6 0.666504ZM1.33333 0.666504C1.06963 0.666504 0.811839 0.744703 0.592574 0.891211C0.373308 1.03772 0.202411 1.24596 0.101495 1.48959C0.000577711 1.73323 -0.0258267 2.00132 0.0256202 2.25996C0.0770672 2.5186 0.204055 2.75618 0.390525 2.94265C0.576995 3.12912 0.814572 3.2561 1.07321 3.30755C1.33185 3.359 1.59994 3.33259 1.84358 3.23168C2.08721 3.13076 2.29545 2.95986 2.44196 2.7406C2.58847 2.52133 2.66667 2.26355 2.66667 1.99984C2.66667 1.64622 2.52619 1.30708 2.27614 1.05703C2.02609 0.80698 1.68696 0.666504 1.33333 0.666504ZM10.6667 0.666504C10.403 0.666504 10.1452 0.744703 9.92591 0.891211C9.70664 1.03772 9.53574 1.24596 9.43483 1.48959C9.33391 1.73323 9.30751 2.00132 9.35895 2.25996C9.4104 2.5186 9.53739 2.75618 9.72386 2.94265C9.91033 3.12912 10.1479 3.2561 10.4065 3.30755C10.6652 3.359 10.9333 3.33259 11.1769 3.23168C11.4205 3.13076 11.6288 2.95986 11.7753 2.7406C11.9218 2.52133 12 2.26355 12 1.99984C12 1.64622 11.8595 1.30708 11.6095 1.05703C11.3594 0.80698 11.0203 0.666504 10.6667 0.666504Z"
      fill="currentColor"
      fillOpacity="0.25"
    />
  </svg>
);

export default More;
