import React from "react";

const MessageAvatarWorkspace = props => {
  const { iconBackgroundColor, style } = props;
  return (
    <svg style={style} width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill={iconBackgroundColor} />
      <path
        opacity="0.2"
        d="M22.4403 14.96L21.7869 17.7467C21.2269 20.1534 20.1203 21.1267 18.0403 20.9267C17.7069 20.9 17.3469 20.84 16.9603 20.7467L15.8403 20.48C13.0603 19.82 12.2003 18.4467 12.8536 15.66L13.5069 12.8667C13.6403 12.3 13.8003 11.8067 14.0003 11.4C14.7803 9.78669 16.1069 9.35336 18.3336 9.88003L19.4469 10.14C22.2403 10.7934 23.0936 12.1734 22.4403 14.96Z"
        fill="currentColor"
      />
      <path
        d="M18.0397 20.9266C17.6264 21.2066 17.1064 21.44 16.4731 21.6466L15.4197 21.9933C12.7731 22.8466 11.3797 22.1333 10.5197 19.4866L9.66641 16.8533C8.81307 14.2066 9.51974 12.8066 12.1664 11.9533L13.2197 11.6066C13.4931 11.52 13.7531 11.4466 13.9997 11.4C13.7997 11.8066 13.6397 12.3 13.5064 12.8666L12.8531 15.66C12.1997 18.4466 13.0597 19.82 15.8397 20.48L16.9597 20.7466C17.3464 20.84 17.7064 20.9 18.0397 20.9266Z"
        fill="currentColor"
      />
      <path
        d="M19.6599 15.0067C19.6199 15.0067 19.5799 15 19.5332 14.9934L16.2999 14.1734C16.0332 14.1067 15.8732 13.8334 15.9399 13.5667C16.0066 13.3 16.2799 13.14 16.5466 13.2067L19.7799 14.0267C20.0466 14.0934 20.2066 14.3667 20.1399 14.6334C20.0866 14.8534 19.8799 15.0067 19.6599 15.0067Z"
        fill="currentColor"
      />
      <path
        d="M17.7069 17.26C17.6669 17.26 17.6269 17.2533 17.5802 17.2467L15.6402 16.7533C15.3736 16.6867 15.2136 16.4133 15.2802 16.1467C15.3469 15.88 15.6202 15.72 15.8869 15.7867L17.8269 16.28C18.0936 16.3467 18.2536 16.62 18.1869 16.8867C18.1336 17.1133 17.9336 17.26 17.7069 17.26Z"
        fill="currentColor"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke={iconBackgroundColor} />
    </svg>
  );
};

export default MessageAvatarWorkspace;
