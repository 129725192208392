export default {
  dateTag: "日期标签",
  dateTagTip: "搜索时可以利用关键词搜索所有含此标签的日期",
  calendar: "日历视图",
  settings: "标签管理",
  /* 日历视图 */
  tagName: "标签名",
  timeRange: "已更新范围",
  numberCycles: "包含循环数",
  status: "状态",
  on: "启用",
  off: "禁用",
  operations: "操作",
  viewBtnText: "明细",
  editBtnText: "编辑",
  disableBtnText: "禁用",
  disableConfirmTitle: "确认禁用此标签？",
  enableBtnText: "启用",
  enableConfirmTitle: "确认启用此标签？",
  deleteBtnText: "移除",
  deleteConfirmTitle: "确认移除此标签及归属于它的所有已打的日期标签？",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  systemTags: "系统标签",
  customTags: "自定义标签",
  searchTagInputPlaceholder: "请输入想搜索的标签",
  addBtnText: "添加自定义标签",
  /* 标签管理 */
  resetBtnText: "重置日历",
  resetConfirmTitle: "日历视图的所有标签将被重置，是否确定重置日历？",
  selectTagPlaceholder: "选择标签",
};
