import React from "react";

const ProfessionalTier = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_70_13868)">
      <path
        d="M6.00747 6.39056H4.03758C3.94213 6.39056 3.85059 6.42848 3.78309 6.49598C3.7156 6.56347 3.67768 6.65502 3.67768 6.75047C3.67768 6.84592 3.7156 6.93746 3.78309 7.00496C3.85059 7.07245 3.94213 7.11037 4.03758 7.11037H6.00747C6.10293 7.11037 6.19447 7.07245 6.26196 7.00496C6.32946 6.93746 6.36738 6.84592 6.36738 6.75047C6.36738 6.65502 6.32946 6.56347 6.26196 6.49598C6.19447 6.42848 6.10293 6.39056 6.00747 6.39056V6.39056ZM6.00747 8.77075H4.03758C3.94213 8.77075 3.85058 8.80867 3.78309 8.87617C3.71559 8.94366 3.67767 9.03521 3.67767 9.13066C3.67767 9.22611 3.71559 9.31766 3.78309 9.38515C3.85058 9.45265 3.94213 9.49057 4.03758 9.49057H6.00747C6.10293 9.49057 6.19447 9.45265 6.26197 9.38515C6.32946 9.31766 6.36738 9.22611 6.36738 9.13066C6.36738 9.03521 6.32946 8.94366 6.26197 8.87617C6.19447 8.80867 6.10293 8.77075 6.00747 8.77075V8.77075ZM10.1272 8.77075H8.76677C8.67132 8.77075 8.57978 8.80867 8.51228 8.87617C8.44479 8.94366 8.40687 9.03521 8.40687 9.13066C8.40687 9.22611 8.44479 9.31766 8.51228 9.38515C8.57978 9.45265 8.67132 9.49057 8.76677 9.49057H10.1272C10.2227 9.49057 10.3142 9.45265 10.3817 9.38515C10.4492 9.31766 10.4871 9.22611 10.4871 9.13066C10.4871 9.03521 10.4492 8.94366 10.3817 8.87617C10.3142 8.80867 10.2227 8.77075 10.1272 8.77075V8.77075Z"
        fill="currentColor"
      />
      <path
        d="M11.7372 11.4391V7.00018C11.7373 6.93933 11.7219 6.87946 11.6925 6.82617C11.6631 6.77288 11.6207 6.72791 11.5692 6.69546L7.56708 4.1617V2.20139C7.56689 2.13855 7.55023 2.07685 7.51879 2.02243C7.48734 1.96802 7.44219 1.92279 7.38784 1.89124C7.33348 1.8597 7.27181 1.84294 7.20896 1.84263C7.14612 1.84231 7.08429 1.85847 7.02962 1.88947L2.38198 4.52881C2.32679 4.56011 2.28086 4.60545 2.24883 4.66023C2.21681 4.715 2.19984 4.77728 2.19964 4.84073V11.4391H0.999939V12.1589H12.9969V11.4391H11.7372ZM2.91946 11.4391V5.04948L6.84965 2.81805V11.4367L2.91946 11.4391ZM7.56947 11.4391V5.02069L11.0198 7.20413V11.4439L7.56947 11.4391Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_70_13868">
        <rect width="12" height="10.3174" fill="white" transform="translate(0.999939 1.84131)" />
      </clipPath>
    </defs>
  </svg>
);

export default ProfessionalTier;
