import React from "react";

const Sorter = ({ type, ...props }) => {
  const colors = {
    ascend: ["var(--datarc-color-black)", "var(--datarc-color-info)"],
    descend: ["var(--datarc-color-info)", "var(--datarc-color-black)"],
  };
  const defaultColor = ["var(--datarc-color-info)", "var(--datarc-color-info)"];
  return (
    <svg {...props} width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3353_15109)">
        <path
          d="M6.04279 3.19518C6.12286 3.0951 6.27507 3.0951 6.35514 3.19518L8.60568 6.00836C8.71044 6.13931 8.61721 6.33329 8.44951 6.33329L3.94842 6.33329C3.78072 6.33329 3.68749 6.13931 3.79225 6.00836L6.04279 3.19518Z"
          fill={colors[type]?.[0] || defaultColor[0]}
        />
      </g>
      <g clipPath="url(#clip1_3353_15109)">
        <path
          d="M6.35514 12.8051C6.27507 12.9052 6.12286 12.9052 6.04279 12.8051L3.79225 9.99193C3.68749 9.86098 3.78072 9.66699 3.94842 9.66699L8.44951 9.66699C8.61721 9.66699 8.71044 9.86098 8.60568 9.99193L6.35514 12.8051Z"
          fill={colors[type]?.[1] || defaultColor[1]}
        />
      </g>
      <defs>
        <clipPath id="clip0_3353_15109">
          <rect width="12" height="8" fill="white" transform="translate(0.199219)" />
        </clipPath>
        <clipPath id="clip1_3353_15109">
          <rect width="12" height="8" fill="white" transform="translate(0.199219 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Sorter;
