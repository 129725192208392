export default {
  second: "Second",
  minute: "Minute",
  hour: "Hour",
  day: "Day",
  week: "Week",
  month: "Month",
  quarter: "Quarter",
  year: "Year",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  secondAbbr: "Second",
  minuteAbbr: "Minute",
  hourAbbr: "Hour",
  dayAbbr: "Day",
  weekAbbr: "Week",
  monthAbbr: "Month",
  quarterAbbr: "Quarter",
  yearAbbr: "Year",
  everyYear: "By year",
  everyMonth: "By month",
  everyDay: "By day",
  everyWeek: "By week",
};
