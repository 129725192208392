import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.AxisIntervalType.${id}`);
export default class AxisIntervalType {
  static AUTO = "auto";

  static CUSTOM = "custom";

  static NONE = "none";

  static getOptions = () => [
    { label: getText("auto"), value: AxisIntervalType.AUTO },
    { label: getText("custom"), value: AxisIntervalType.CUSTOM },
    { label: getText("none"), value: AxisIntervalType.NONE },
  ];
}
