export default {
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  on: "启用",
  off: "禁用",
  enableBtnText: "启用",
  disableBtnText: "禁用",
  editBtnText: "编辑",
  deleteBtnText: "删除",
  enableSuccessToast: "启用成功",
  disableSuccessToast: "禁用成功",
  enableConfirmTitle: "确认启用此规则？",
  disableConfirmTitle: "确认禁用此规则？",
  deleteConfirmTitle: "确认删除此规则？",
  ruleName: "规则名",
  creator: "创建人",
  status: "状态",
  createdAt: "创建时间",
  updatedAt: "更新时间",
  operations: "操作",
  addBtnText: "添加规则",
  addSuccessToast: "添加成功",
  addRuleModalTitle: "添加规则",
  ruleNameErrMsg: "规则名不能为空",
  ruleNameInputPlaceholder: "请输入规则名称",
  operation: "操作",
  inputPlaceholder: "请输入关键词",
  preText: "包含",
  condition: "条件",
  output: "操作",
};
