import AdminGuide from "./AdminGuide";
import adminProject from "./adminProject";
import config from "./config";
import email from "./email";
import largeLanguageModel from "./largeLanguageModel";
import netStatus from "./netStatus";
import personalization from "./personalization";
import shortMessage from "./shortMessage";
import ssoConfig from "./ssoConfig";

export default {
  shortMessage,
  email,
  config,
  ssoConfig,
  largeLanguageModel,
  netStatus,
  personalization,
  adminProject,
  AdminGuide,
};
