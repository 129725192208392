import React from "react";

const PinboardTabShareLink = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9167 5.83333C12.9167 7.67428 11.4243 9.16667 9.58333 9.16667C7.74238 9.16667 6.25 7.67428 6.25 5.83333C6.25 3.99238 7.74238 2.5 9.58333 2.5C11.4243 2.5 12.9167 3.99238 12.9167 5.83333ZM11.25 5.83333C11.25 6.75381 10.5038 7.5 9.58333 7.5C8.66286 7.5 7.91667 6.75381 7.91667 5.83333C7.91667 4.91286 8.66286 4.16667 9.58333 4.16667C10.5038 4.16667 11.25 4.91286 11.25 5.83333ZM4.16667 17.5H12.528C11.7655 17.1359 11.1273 16.5533 10.6944 15.8333H4.16667V14.0564C4.16667 13.3035 4.62908 12.6279 5.33091 12.3555C7.01685 11.7009 8.81614 11.4498 10.5891 11.6021C10.9108 11.0011 11.3715 10.4857 11.9281 10.0988C9.52508 9.67223 7.03356 9.90658 4.72771 10.8018C3.3848 11.3232 2.5 12.6158 2.5 14.0564V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5ZM14.0338 10.8212V11.7547C16.3575 12.1012 17.3113 14.1516 17.4872 16.5762C17.4905 16.6493 17.4435 16.7147 17.3748 16.7327C17.3062 16.7506 17.2348 16.7161 17.204 16.6501C16.6302 15.3454 15.7151 14.1577 14.0338 14.1577V15.0912C14.034 15.2146 13.9629 15.3261 13.8533 15.3747C13.7436 15.4233 13.6164 15.3997 13.5301 15.3146L11.3555 13.1795C11.2962 13.1214 11.2627 13.0406 11.2627 12.9562C11.2627 12.8717 11.2962 12.791 11.3555 12.7328L13.5301 10.5978C13.6164 10.5127 13.7436 10.489 13.8533 10.5377C13.9629 10.5863 14.034 10.6978 14.0338 10.8212Z"
      fill="currentColor"
    />
  </svg>
);

export default PinboardTabShareLink;
