import React from "react";

const ArrowUpLeft = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 17 19" fill="#256EFF" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.53033 6.75C4.53033 6.33579 4.86612 6 5.28033 6H11.2907C11.705 6 12.0407 6.33579 12.0407 6.75V12.7604C12.0407 13.1746 11.705 13.5104 11.2907 13.5104C10.8765 13.5104 10.5407 13.1746 10.5407 12.7604V8.56066L5.28033 13.8211C4.98744 14.114 4.51256 14.114 4.21967 13.8211C3.92678 13.5282 3.92678 13.0533 4.21967 12.7604L9.48008 7.5H5.28033C4.86612 7.5 4.53033 7.16421 4.53033 6.75Z"
      fill="currentColor"
    />
  </svg>
);
export default ArrowUpLeft;
