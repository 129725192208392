import React from "react";

const Lock = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48 4C34.7048 4 24 14.7048 24 28V32C19.6277 32 16 35.6277 16 40V80C16 84.3723 19.6277 88 24 88H72C76.3723 88 80 84.3723 80 80V40C80 35.6277 76.3723 32 72 32V28C72 14.7048 61.2952 4 48 4ZM48 12C57.1048 12 64 18.8952 64 28V32H32V28C32 18.8952 38.8952 12 48 12ZM24 40H72V80H24V40ZM48 52C43.6 52 40 55.6 40 60C40 64.4 43.6 68 48 68C52.4 68 56 64.4 56 60C56 55.6 52.4 52 48 52Z"
      fill="currentColor"
    />
  </svg>
);

export default Lock;
