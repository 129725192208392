export default {
  uploadToDBErrorMsgCode2: "获取锁失败",
  uploadToDBErrorMsgCode3: "文件格式错误",
  uploadToDBErrorMsgCode4: "文件未找到",
  uploadToDBErrorMsgCode5: "下载数据文件失败",
  uploadToDBErrorMsgCode6: "文件编码格式错误",
  uploadToDBErrorMsgCode7: "读取文件失败",
  uploadToDBErrorMsgCode8: "未传递 data 数据",
  uploadToDBErrorMsgCode9: "未指定数据表名 afterTable",
  uploadToDBErrorMsgCode10: "表名存在特殊字符，或超过长度限制，最长63位",
  uploadToDBErrorMsgCode11: "数据表名称重复",
  uploadToDBErrorMsgCode12: "列名称存在特殊字符，或超过长度限制，最长63位",
  uploadToDBErrorMsgCode13: "数据列类型为空",
  uploadToDBErrorMsgCode14: "数据列类型错误",
  uploadToDBErrorMsgCode15: "选取 sheet 为空",
  uploadToDBErrorMsgCode16: "数据文件为空",
  uploadToDBErrorMsgCode17: "数据库连接失败",
  uploadToDBErrorMsgCode19: "数据溢出",
  uploadToDBErrorMsgCode20: "插入数据库失败",
  uploadToDBErrorMsgCodeDefault: "异常终止",
  uploadPreviewDataErrorMsgCode1: "下载文件失败",
  uploadPreviewDataErrorMsgCode2: "文件解析失败",
  uploadPreviewDataErrorMsgCode3: "上传文件包含错误内容",
  uploadPreviewDataErrorMsgCode4: "处理文件失败",
  uploadPreviewDataErrorMsgCode5: "上传文件为空",
};
