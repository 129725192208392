import React from "react";

const GlobalConfig = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4644 0.734448L10.5845 0.571045L12.8534 1.62928C13.3272 1.84633 13.7247 2.20097 13.9942 2.64705V2.56301C14.031 2.11827 13.891 1.67694 13.6047 1.33466C13.3183 0.992384 12.9086 0.776735 12.4644 0.734448Z"
        fill="currentColor"
      />
      <path
        d="M11.4213 12.652C11.4711 12.652 11.5209 12.652 11.5769 12.652C11.9993 12.651 12.406 12.4923 12.7175 12.2071C13.029 11.9218 13.2227 11.5305 13.2607 11.1098L13.6965 6.02875L10.6416 12.5836L11.4213 12.652Z"
        fill="currentColor"
      />
      <path
        d="M13.3172 4.63903C13.4797 4.23757 13.4822 3.78911 13.3241 3.38588C13.1661 2.98265 12.8595 2.65532 12.4675 2.47121L11.042 1.8067L12.6231 3.38782C12.9673 3.73153 13.2078 4.16511 13.3172 4.63903Z"
        fill="currentColor"
      />
      <path
        d="M7.41304 12.2956L6.9913 12.7174L6.72363 12.985L7.20295 13.2091L7.41148 13.3072C7.73395 13.4542 8.09412 13.4976 8.44228 13.4313C8.79044 13.3649 9.10942 13.1921 9.3552 12.9368C9.47668 12.8073 9.57651 12.6591 9.65089 12.4979L11.7891 7.92575L7.41304 12.2956Z"
        fill="currentColor"
      />
      <path
        d="M11.9665 4.04771L8.9536 1.03175C8.79672 0.874826 8.61036 0.750459 8.40525 0.665797C8.20014 0.581136 7.98032 0.53785 7.75843 0.538427C7.68865 0.538966 7.61898 0.544165 7.54989 0.55399C7.17672 0.598029 6.82921 0.766305 6.56325 1.03175L5.95943 1.63556L5.62173 1.97482L5.43499 2.16001L4.65687 2.93812L0.495535 7.09946C0.178225 7.41722 0 7.84792 0 8.29697C0 8.74603 0.178225 9.17673 0.495535 9.49449L1.77164 10.7706L3.50838 12.5027C3.51772 12.5136 3.52861 12.5213 3.53951 12.5322C3.80671 12.7852 4.14943 12.9438 4.51526 12.9835C4.57979 12.9916 4.64475 12.9957 4.70979 12.996C4.93364 12.9959 5.15527 12.9515 5.36184 12.8653C5.48977 12.8121 5.61054 12.743 5.72133 12.6598C5.75868 12.6318 5.79447 12.6023 5.82871 12.5711C5.85361 12.5494 5.88007 12.5276 5.90496 12.5027L6.06059 12.347L6.39362 12.014L11.9649 6.44274C12.282 6.12499 12.4603 5.69448 12.4606 5.24554C12.4609 4.79661 12.2832 4.36587 11.9665 4.04771ZM8.31088 2.51794C8.20838 2.62047 8.07778 2.6903 7.93559 2.7186C7.7934 2.7469 7.64601 2.7324 7.51206 2.67693C7.37812 2.62145 7.26363 2.52751 7.18308 2.40696C7.10253 2.28642 7.05953 2.1447 7.05953 1.99972C7.05953 1.85474 7.10253 1.71302 7.18308 1.59248C7.26363 1.47193 7.37812 1.37799 7.51206 1.32251C7.64601 1.26704 7.7934 1.25254 7.93559 1.28084C8.07778 1.30914 8.20838 1.37897 8.31088 1.4815C8.4483 1.61895 8.5255 1.80536 8.5255 1.99972C8.5255 2.19408 8.4483 2.38049 8.31088 2.51794Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GlobalConfig;
