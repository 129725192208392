import React from "react";

const Bulb = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4571_23191)">
      <path
        d="M10.1426 14.7143H5.85686C5.77829 14.7143 5.71401 14.7786 5.71401 14.8571V15.4286C5.71401 15.7446 5.96936 16 6.28544 16H9.71401C10.0301 16 10.2854 15.7446 10.2854 15.4286V14.8571C10.2854 14.7786 10.2211 14.7143 10.1426 14.7143ZM7.99972 0C4.76579 0 2.14258 2.62321 2.14258 5.85714C2.14258 8.025 3.32115 9.91786 5.07115 10.9304V13C5.07115 13.3161 5.32651 13.5714 5.64258 13.5714H10.3569C10.6729 13.5714 10.9283 13.3161 10.9283 13V10.9304C12.6783 9.91786 13.8569 8.025 13.8569 5.85714C13.8569 2.62321 11.2337 0 7.99972 0ZM10.2837 9.81786L9.64258 10.1893V12.2857H6.35686V10.1893L5.71579 9.81786C4.31044 9.00536 3.42829 7.50893 3.42829 5.85714C3.42829 3.33214 5.47472 1.28571 7.99972 1.28571C10.5247 1.28571 12.5711 3.33214 12.5711 5.85714C12.5711 7.50893 11.689 9.00536 10.2837 9.81786Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4571_23191">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Bulb;
