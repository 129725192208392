export default class WatermarkStatus {
  static VISITOR = 0;

  static COPYRIGHT = 1;

  static PICTURE = 2;

  static getText = t => {
    if (t === WatermarkStatus.VISITOR) return "访客水印";
    if (t === WatermarkStatus.COPYRIGHT) return "版权水印";
    if (t === WatermarkStatus.PICTURE) return "图片水印";
    return "";
  };
}
