import React from "react";

const CrossFill = prop => (
  <svg {...prop} width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="8" fill="currentColor" fillOpacity="1" />
    <path d="M10.5 5.5L5.5 10.5" stroke="black" strokeOpacity="0.65" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 5.5L10.5 10.5" stroke="black" strokeOpacity="0.65" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CrossFill;
