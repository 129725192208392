import React from "react";

const GarbageCanOpened = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H5C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4H9H15ZM18 8C18.5523 8 19 8.44772 19 9V19C19 20.6569 17.6569 22 16 22H8C6.34315 22 5 20.6569 5 19V9C5 8.44772 5.44772 8 6 8H18ZM17 10H7V19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19V10Z"
      fill="currentColor"
    />
    <rect x="9" y="2" width="6" height="2" rx="1" fill="currentColor" />
  </svg>
);
export default GarbageCanOpened;
