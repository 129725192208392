import React from "react";

const AddButton = props => (
  <svg {...props} width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41666 6.41668V1.45834H7.58333V6.41668H12.5417V7.58334H7.58333V12.5417H6.41666V7.58334H1.45833V6.41668H6.41666Z"
      fill="currentColor"
    />
  </svg>
);
export default AddButton;
